import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-save-configuration-dialog',
    templateUrl: './save-configuration-dialog.component.html',
    styleUrls: ['./save-configuration-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SaveConfigurationDialogComponent
    extends UiBaseComponent
    implements OnInit, AfterViewInit
{
    @Input()
    isOverwrite: boolean;

    @Input()
    configurationTitle: string;

    @Output()
    readonly confirm = new EventEmitter<string>();

    @ViewChild('fieldRef') fieldRef: MatInput;

    configurationTitleFormGroup: FormGroup;

    constructor(
        public readonly matDialogRef: MatDialogRef<SaveConfigurationDialogComponent>,
        public readonly cdr: ChangeDetectorRef
    ) {
        super();
        this.completeOnDestroy(this.confirm);
    }

    private initConfigurationNameFormGroup() {
        const configurationTitleForm = {
            configurationName: new FormControl(
                this.configurationTitle ? this.configurationTitle : undefined,
                Validators.required
            )
        };
        this.configurationTitleFormGroup = new FormGroup(
            configurationTitleForm
        );
    }

    ngOnInit() {
        this.initConfigurationNameFormGroup();
    }

    ngAfterViewInit() {
        this.fieldRef.focus();
        this.cdr.detectChanges();
    }

    intentSaveConfig() {
        this.confirm.emit(
            this.configurationTitleFormGroup.value.configurationName
        );
    }

    onCancel() {
        this.matDialogRef.close();
    }
}
