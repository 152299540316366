import { identity } from 'lodash-es';
import { Observable, combineLatest, map } from 'rxjs';

import { Injectable } from '@angular/core';
import { CategoryItem } from '@mhp/aml-ui-shared-services/configuration/categories';
import { I18nService } from '@mhp/ui-shared-services';

import { environment } from '../../../../environments/environment';
import {
    ExtendedUiConfigurationMetaItem,
    ExtendedUiOptionGroup
} from '../../configuration-model/configuration-interfaces';
import { ConfigurationNodeLookupService } from '../../services/configuration-node-lookup.service';
import { CategoryMapper } from './category-mapper';

export type CategorySourceItemFilter = (
    item: ExtendedUiConfigurationMetaItem,
    path: ExtendedUiConfigurationMetaItem[]
) => boolean;

export type CategoryItemModifier = (
    item: CategoryItem,
    metaItem: ExtendedUiConfigurationMetaItem
) => CategoryItem | undefined;

export const IDENTIFIER_SUMMARY =
    environment.appConfig.configuration.identifierSummary;

@Injectable({
    providedIn: 'root'
})
export class CategoryMapperService {
    private nonFilteringCategoryMapper: CategoryMapper | undefined;

    private readonly defaultAcceptEverythingFilter = () => true;

    private readonly defaultModifyNothingModifier = identity;

    constructor(
        private readonly i18nService: I18nService,
        private readonly nodeLookupService: ConfigurationNodeLookupService
    ) {}

    /**
     * Get a CategoryMapper instance that can be used to map ExtendedUiOptionGroups to CategoryItems.
     * @param sourceItemFilter An optional sourceItemFilter to filter the source items before mapping to category items.
     * @param itemFilter An optional itemFilter to filter the category items after being mapped.
     */
    getCategoryItemMapper(
        sourceItemFilter?: CategorySourceItemFilter,
        itemFilter?: CategoryItemModifier
    ) {
        if (
            !sourceItemFilter &&
            !itemFilter &&
            !this.nonFilteringCategoryMapper
        ) {
            this.nonFilteringCategoryMapper = new CategoryMapper(
                this.defaultAcceptEverythingFilter,
                this.defaultModifyNothingModifier,
                this.nodeLookupService
            );
            return this.nonFilteringCategoryMapper;
        }
        return new CategoryMapper(
            sourceItemFilter ?? this.defaultAcceptEverythingFilter,
            itemFilter ?? this.defaultModifyNothingModifier,
            this.nodeLookupService
        );
    }

    /**
     * Map a given stream of ExtendedUiOptionGroups to CategoryItems and adds
     * the implicitly available SUMMARY category.
     * @param optionGroups$ Stream emitting ExtendedUiOptionGroups
     * @param sourceItemFilter Optional sourceItemFilter to filter the source items before mapping to category items.
     * @param itemModifier Optional itemModifier to modify the category items after being mapped.
     */
    getCategoryItems$(
        optionGroups$: Observable<ExtendedUiOptionGroup[] | undefined>,
        sourceItemFilter?: CategorySourceItemFilter,
        itemModifier?: CategoryItemModifier
    ): Observable<CategoryItem[]> {
        const mapper = this.getCategoryItemMapper(
            sourceItemFilter,
            itemModifier
        );
        return combineLatest([
            optionGroups$,
            this.i18nService.getActiveLang$()
        ]).pipe(
            map(([optionGroups]) => {
                // in case no optionGroups are available, emit as completely empty
                if (!optionGroups) {
                    return [];
                }

                const summaryCategory: CategoryItem = {
                    id: IDENTIFIER_SUMMARY,
                    nameInternal: IDENTIFIER_SUMMARY,
                    label: this.i18nService.translateWithFallback(
                        'CONFIGURATOR.SUMMARY',
                        IDENTIFIER_SUMMARY
                    ),
                    isCategorySelector: true
                };
                const categoryItems = mapper.mapOptionGroups(optionGroups);
                return [...categoryItems, summaryCategory];
            })
        );
    }
}
