import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
    ROUTE_ORDER_DETAIL,
    ROUTE_ORDER_EDIT,
    ROUTE_ORDER_OVERVIEW
} from '../app-route-names';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { OrderManagmentComponent } from './order-managment.component';
import { OrderOverviewComponent } from './order-overview/order-overview.component';

const ORDERMANAGMENT_CHILDREN: Routes = [
    {
        path: '',
        children: [
            {
                path: ROUTE_ORDER_OVERVIEW,
                component: OrderOverviewComponent
            },
            {
                path: ROUTE_ORDER_DETAIL,
                component: OrderDetailComponent
            },
            {
                path: ROUTE_ORDER_EDIT,
                component: OrderEditComponent
            }
        ],
        component: OrderManagmentComponent
    },
    {
        path: '**',
        redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forChild(ORDERMANAGMENT_CHILDREN)],
    exports: [RouterModule]
})
export class OrderManagementRoutingModule {}
