import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';

import {
    ExtendedUiNestedCode,
    ExtendedUiOptionCode
} from '../../../configuration-model/configuration-interfaces';

@Component({
    selector: 'mhp-configuration-nested-code',
    templateUrl: './configuration-nested-code.component.html',
    styleUrls: ['./configuration-nested-code.component.scss']
})
export class ConfigurationNestedCodeComponent extends UiBaseComponent {
    @Input()
    nestedCode: ExtendedUiNestedCode;

    optionSelectionDisabled = input<boolean>(false);

    @Output()
    readonly intentSelect = new EventEmitter<string>();

    constructor() {
        super();
    }

    @MemoizeObservable()
    getOptions$(): Observable<ExtendedUiOptionCode[] | undefined> {
        return this.observeProperty<
            ConfigurationNestedCodeComponent,
            ExtendedUiNestedCode
        >('nestedCode', true).pipe(
            distinctUntilChanged(isEqual),
            map((nestedCode: ExtendedUiNestedCode) => nestedCode.content)
        );
    }

    intentSelectItem(itemId: string) {
        this.intentSelect.emit(itemId);
    }
}
