import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';
import { lazyShareReplay } from '@mhp/common';
import { UntilDestroy } from '@ngneat/until-destroy';

import { OptionIdWithVisibilityState } from './configuration-options-list.component';

/**
 * Service allowing to be notified of option-visibility-changes.
 * This service is intended to be used in a component-local context.
 * Provide it somewhere in the component hierarchy where you are interested in
 * the VisibilityStateEnhancedOptions provided by the ConfigurationOptionsListComponent.
 * The service-instance is then picked up by the ConfigurationOptionsListComponent which
 * registers the local stream to make it available.
 */
@UntilDestroy()
@Injectable()
export class ConfigurationOptionsListSupportService {
    private readonly optionListVisibilityStreamSubject = new BehaviorSubject<
        Observable<OptionIdWithVisibilityState[]> | undefined
    >(undefined);

    /**
     * Called by a component which is aware of providing a stream with visible options.
     * @param stream$
     */
    registerOptionListVisibilityStream$(
        stream$: Observable<OptionIdWithVisibilityState[]>
    ) {
        this.optionListVisibilityStreamSubject.next(stream$);
    }

    /**
     * Return an observable that allows to subscribe to the list of VisibilityStateEnhancedOptions
     * that are valid in the context the service is used in.
     */
    getOptionListVisibilityStream$(): Observable<
        OptionIdWithVisibilityState[] | undefined
    > {
        return this.optionListVisibilityStreamSubject.pipe(
            switchMap((stream$) => stream$ ?? of(undefined)),
            lazyShareReplay()
        );
    }
}
