import { isEmpty } from 'lodash-es';
import { combineLatest, distinctUntilChanged, map } from 'rxjs';

import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { getDerivativeStaticInfo } from '@mhp/aml-shared/derivate-mapping/derivate-mapping';
import { ApplicationStateService } from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../environments/environment';
import { ConfigurationSessionInfoService } from '../../configuration/session-info/configuration-session-info.service';
import { LocalApplicationState } from '../../state';
import { selectDealerInfo } from '../state';

/**
 * Allows to easily show or hide features depending on the possibility to order
 * the currently active product or not.
 */
@UntilDestroy()
@Directive({
    selector: '[mhpIfOrderPossible]'
})
export class IfOrderPossibleDirective implements OnInit {
    @Input()
    mhpIfOrderPossibleElse?: TemplateRef<unknown>;

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService
    ) {}

    ngOnInit() {
        this.initViewToggleLogic();
    }

    private initViewToggleLogic() {
        const isDealerBuild = environment.appConfig.dealer.dealerBuild;

        combineLatest([
            this.applicationStateService.getLocalState().pipe(selectDealerInfo),
            this.configurationSessionInfoService.getActiveProductId$()
        ])
            .pipe(
                map(([dealerInfo, productId]) => {
                    if (!productId) {
                        return false;
                    }

                    const derivativeStaticInfo =
                        getDerivativeStaticInfo(productId);
                    if (isDealerBuild) {
                        // in case forceHidePricing is set to true, ordering is not possible for dealers.
                        if (derivativeStaticInfo.forceHidePricing === true) {
                            return false;
                        }
                        // check possible dealer-whitelisting
                        if (
                            isEmpty(
                                derivativeStaticInfo.disableOrderForDealersExcept
                            )
                        ) {
                            return true;
                        }
                        if (!dealerInfo?.emailSHA256) {
                            return false;
                        }
                        return derivativeStaticInfo.disableOrderForDealersExcept?.includes(
                            dealerInfo.emailSHA256
                        );
                    }
                    // no dealer build
                    return !derivativeStaticInfo.disableOrderForPublic;
                }),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((orderingPossible) => {
                this.viewContainer.clear();
                if (orderingPossible) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    this.changeDetectorRef.markForCheck();
                } else if (this.mhpIfOrderPossibleElse) {
                    this.viewContainer.createEmbeddedView(
                        this.mhpIfOrderPossibleElse
                    );
                }
            });
    }
}
