import { Observable, OperatorFunction, firstValueFrom, switchMap } from 'rxjs';

import { translate } from '@jsverse/transloco';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { Pricing } from '@mhp-immersive-exp/contracts/src/configuration/pricing.interface';
import { INFOR_LIST_CODES } from '@mhp/aml-shared/infor/constants';
import {
    CPQPricing,
    ConfigurationResponse
} from '@mhp/aml-shared/infor/interfaces/configuration-response.interface';
import { ScreenOption } from '@mhp/aml-shared/infor/interfaces/screen-option.interface';
import { Screen } from '@mhp/aml-shared/infor/interfaces/screen.interface';
import { SelectableValue } from '@mhp/aml-shared/infor/interfaces/selectable-value.interface';
import { I18nService } from '@mhp/ui-shared-services';

import { AmlProductDataService } from '../product-data/aml-product-data-service';
import { ProductInfo } from '../product-data/aml-product-data-strategy.interface';
import {
    FormOption,
    OptionPrice,
    OrderConfiguration,
    OrderFormGroup,
    OrderPriority
} from './model/order-management.models';

const ORDER_MANAGEMENT = 'ORDER_MANAGEMENT';
const OPTION_OVERRIDES = 'OPTION_OVERRIDES';
const CONFIGURATION = 'CONFIGURATION';
const CODE = 'CODE';
const COMMON = 'COMMON';
const REGION = 'REGION';

const INFOTEXT_FIELD_KEYS = ['HBK', 'LANG', 'FPF'];

export const toOrderPriority = (response: any): OrderPriority[] =>
    response.orderPriorities.map((priorityValue: any) => ({
        value: priorityValue.orderPriority,
        label: priorityValue.description
    }));

const toFormOptionType = (type: string): FormOption['type'] => {
    switch (type) {
        case 'DropDown':
        case 'TypeableDropDown':
            return 'select';
        case 'TextArea':
        case 'TextBox':
            return 'textarea';
        case 'CheckBox':
            return 'radio';
        default:
            console.warn(type, 'is mapped to default');
            return 'title';
    }
};

export const getTranslationWithFallback = (
    key: string,
    fallback: string,
    derivative?: string,
    model?: string
): string => {
    const derivativeOverrideKey = `${ORDER_MANAGEMENT}.${OPTION_OVERRIDES}.${model}.${derivative}.${CODE}.${key}`;
    const derivativeOverrideTranslation =
        I18nService.translateWithFallbackStatic(derivativeOverrideKey, false);
    if (derivative && model && derivativeOverrideTranslation)
        return derivativeOverrideTranslation;

    const derivativeKey = `${CONFIGURATION}.${model}.${derivative}.${CODE}.${key}`;
    const derivativeTranslation = I18nService.translateWithFallbackStatic(
        derivativeKey,
        false
    );
    if (derivative && model && derivativeTranslation)
        return derivativeTranslation;

    const modelOverrideKey = `${ORDER_MANAGEMENT}.${OPTION_OVERRIDES}.${model}.${CODE}.${key}`;
    const modelOverrideTranslation = I18nService.translateWithFallbackStatic(
        modelOverrideKey,
        false
    );
    if (model && modelOverrideTranslation) return modelOverrideTranslation;

    const modelKey = `${CONFIGURATION}.${model}.${CODE}.${key}`;
    const modelTranslation = I18nService.translateWithFallbackStatic(
        modelKey,
        false
    );
    if (model && modelTranslation) return modelTranslation;

    const commonOverrideKey = `${ORDER_MANAGEMENT}.${OPTION_OVERRIDES}.${COMMON}.${key}`;
    const commonOverrideTranslation = I18nService.translateWithFallbackStatic(
        commonOverrideKey,
        false
    );
    if (commonOverrideTranslation) return commonOverrideTranslation;

    const commonKey = `${CONFIGURATION}.${COMMON}.${key}`;
    const commonTranslation = I18nService.translateWithFallbackStatic(
        commonKey,
        false
    );
    if (commonTranslation) return commonTranslation;

    const regionKey = `${COMMON}.${REGION}.${key}`;
    const regionTranslation = I18nService.translateWithFallbackStatic(
        regionKey,
        false
    );

    if (regionTranslation) return regionTranslation;

    return fallback;
};

export const toPrice = (label: string, pricing: Pricing): OptionPrice => ({
    label,
    currency: pricing.currency ?? '',
    dnp: pricing.wholeSalePrice,
    rrp: pricing.retailPrice,
    noPrice: translate('ORDER_MANAGEMENT.PRICING.NO_PRICE_AVAILABLE')
});

export const toCurrentPrice = (
    pricing: Record<string, Pricing>,
    optionName: string
): OptionPrice => {
    let currency: string | undefined;
    let dnp: number | undefined;
    let rrp: number | undefined;

    if (pricing) {
        for (const [option, price] of Object.entries(pricing)) {
            if (option === optionName) {
                currency = price.currency;
                dnp = price.wholeSalePrice;
                rrp = price.retailPrice;
            }
        }
    }

    return {
        currency: currency ?? '',
        dnp,
        rrp,
        noPrice: translate('ORDER_MANAGEMENT.PRICING.NO_PRICE_AVAILABLE')
    };
};

const toFormOption = (
    screenOption: ScreenOption,
    pricing: Record<string, Pricing>,
    derivative?: string,
    model?: string
): FormOption => {
    const options = screenOption.SelectableValues.map(
        (selectableValue: SelectableValue) => ({
            label: getTranslationWithFallback(
                selectableValue.Value,
                selectableValue.Caption,
                derivative,
                model
            ),
            value: selectableValue.Value
        })
    );

    const screenCaption = screenOption.Caption.replace(':', '').trim();
    return {
        id: screenOption.ID,
        currentValue: screenOption.Value,
        currentValuePrice: toCurrentPrice(pricing, screenOption.Name),
        currentValueCaption: screenOption.SelectableValues?.find(
            (currentValue) => currentValue.Value === screenOption.Value
        )?.Caption,
        isEnabled: screenOption.IsEnabled,
        isFocused: screenOption.IsHighlighted,
        isRequired: screenOption.IsRequired,
        showInfoText: INFOTEXT_FIELD_KEYS.includes(screenOption.Name),
        caption: screenOption.Caption,
        label: getTranslationWithFallback(
            screenOption.Name,
            screenCaption,
            derivative,
            model
        ),
        formGroupTitle: screenOption.Name,
        type: toFormOptionType(screenOption.DisplayType),
        options
    };
};

const toOrderFormGroup = (
    screen: Screen,
    pricing: Record<string, Pricing>,
    derivative?: string,
    model?: string
): OrderFormGroup => {
    const [filteredScreen] = screen.ScreenOptions;
    return {
        caption: screen.Caption,
        label: screen.ShowCaption
            ? getTranslationWithFallback(
                  screen.Caption,
                  getTranslationWithFallback(
                      screen.Caption.toUpperCase(),
                      screen.Caption,
                      derivative,
                      model
                  ),
                  derivative,
                  model
              )
            : '',
        formOption:
            filteredScreen &&
            toFormOption(filteredScreen, pricing, derivative, model)
    };
};

export const toPricingSection = (pricing: CPQPricing): OptionPrice[] => {
    const modelBasePriceLabel = translate(
        'ORDER_MANAGEMENT.PRICING.MODEL_BASE_PRICE'
    );
    const optionPriceLabel = translate('ORDER_MANAGEMENT.PRICING.OPTION_PRICE');
    const configuredPriceLabel = translate(
        'ORDER_MANAGEMENT.PRICING.CONFIGURED_PRICE'
    );
    let optionPrices = <OptionPrice[]>[
        toPrice(modelBasePriceLabel, pricing.modelBasePrice),
        toPrice(optionPriceLabel, pricing.configuredOptionsPrice)
    ];

    // check for gazGuzzlerTax
    if (pricing.gasGuzzlerTax) {
        const gazGuzzlerTaxLabel = translate(
            'ORDER_MANAGEMENT.PRICING.GAZ_GUZZLER_TAX'
        );
        optionPrices = [
            ...optionPrices,
            toPrice(gazGuzzlerTaxLabel, pricing.gasGuzzlerTax)
        ];
    }

    // add total price as last option
    optionPrices = [
        ...optionPrices,
        toPrice(configuredPriceLabel, pricing.configuredPrice)
    ];

    return optionPrices;
};

export const toOrderConfiguration =
    (
        productDataService: AmlProductDataService
    ): OperatorFunction<ConfigurationResponse, OrderConfiguration> =>
    (
        source: Observable<ConfigurationResponse>
    ): Observable<OrderConfiguration> =>
        source.pipe(
            switchMap(
                async (
                    response: ConfigurationResponse
                ): Promise<OrderConfiguration> => {
                    const matchingProduct: ProductInfo | undefined =
                        response.productId
                            ? await firstValueFrom(
                                  productDataService.getProductInfo$(
                                      response.productId
                                  )
                              )
                            : undefined;

                    const firstPageVisible = response.pages[0]?.IsVisible;
                    const screens = firstPageVisible
                        ? response.pages[0].Screens.filter(
                              ({ IsVisible, Caption }) =>
                                  IsVisible &&
                                  !Caption.startsWith(
                                      'Configuration Reference :MHP'
                                  ) &&
                                  !Caption.startsWith('Calling Application')
                          )
                        : [];
                    const { pricing } = response;
                    return {
                        sessionID: response.sessionID,
                        configurationDetailID: response.configurationDetailID,
                        isExecutionComplete: response.isExecutionComplete,
                        currency: pricing.currency ?? '',
                        modelBasePrice: toPrice(
                            translate(
                                'ORDER_MANAGEMENT.PRICING.MODEL_BASE_PRICE'
                            ),
                            pricing.modelBasePrice
                        ),
                        pricingSection: toPricingSection(pricing),
                        optionToFocus: response.optionToFocus,
                        formGroups: screens.map((screen) =>
                            toOrderFormGroup(
                                screen,
                                pricing.optionsPricing,
                                response.productId,
                                matchingProduct?.modelId
                            )
                        ),
                        orderPriority: response.orderPriority,
                        derivative: response.productId
                    };
                }
            )
        );

/**
 * Convert a given {@link }OrderConfiguration} to a ConfigModel[].
 * @param configData The OrderConfiguration to convert.
 */
export const convertToConfigModel = (
    configData: OrderConfiguration
): ConfigModel[] => {
    const configModel: ConfigModel[] = [];

    for (const formGroup of configData.formGroups) {
        if (!formGroup.formOption?.currentValue) {
            continue;
        }
        if (
            formGroup.formOption.formGroupTitle &&
            INFOR_LIST_CODES.includes(formGroup.formOption.formGroupTitle)
        ) {
            configModel.push({
                [formGroup.formOption.formGroupTitle]: {
                    [formGroup.formOption.formGroupTitle]:
                        formGroup.formOption.currentValue
                }
            });
        } else if (formGroup.formOption.caption?.includes('QColor')) {
            configModel.push({
                [formGroup.formOption.caption]: {
                    [formGroup.formOption.caption]:
                        formGroup.formOption.currentValue
                }
            });
        } else {
            configModel.push(formGroup.formOption.currentValue);
        }
    }

    return configModel;
};
