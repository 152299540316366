import { translate } from '@jsverse/transloco';

import { RegionAndLanguageSelectionInterceptor } from '../../settings/region-selector/region.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';

export const buildRegionChangeInterceptor =
    (
        productConfigurationSessionService: ProductConfigurationSessionService
    ): RegionAndLanguageSelectionInterceptor =>
    (continuation$) =>
        productConfigurationSessionService.intentDiscardConfiguration$(
            continuation$,
            translate('CONFIGURATOR.CHANGE_REGION.DIALOG_HEADER'),
            translate('CONFIGURATOR.CHANGE_REGION.DIALOG_MESSAGE')
        );
