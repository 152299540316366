import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoModule } from '@jsverse/transloco';
import { UiIconsModule, UiMatDialogModule } from '@mhp/ui-components';
import { GoogleTagManagerModule } from '@mhp/ui-shared-services';

import { StaticRendererModule } from '../static-renderer/static-renderer.module';
import { ModelOutdatedDialogComponent } from './model-outdated-dialog/model-outdated-dialog.component';

@NgModule({
    declarations: [ModelOutdatedDialogComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        TranslocoModule,
        UiMatDialogModule,
        StaticRendererModule,
        UiIconsModule,
        GoogleTagManagerModule
    ],
    exports: [ModelOutdatedDialogComponent]
})
export class ModelOutdatedModule {}
