import { isEqual, last } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    from,
    merge,
    of
} from 'rxjs';
import {
    distinctUntilChanged,
    map,
    skip,
    startWith,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import {
    LocalAudioTrack,
    LocalVideoTrack,
    RemoteAudioTrack,
    RemoteAudioTrackPublication,
    RemoteParticipant,
    RemoteVideoTrack,
    RemoteVideoTrackPublication,
    Room,
    connect,
    createLocalAudioTrack,
    createLocalVideoTrack
} from 'twilio-video';
import {
    ConnectOptions,
    CreateLocalTrackOptions
} from 'twilio-video/tsdef/types';

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { TwilioTokenData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/session-data.js';
import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiMatDialogService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    ErrorHandlerService
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AudioVideoSettingsDialogComponent } from '../common/audio-video-settings/audio-video-settings-dialog/audio-video-settings-dialog.component';
import { AudioVideoSettings } from '../common/audio-video-settings/audio-video-settings.interface';
import { Nullable } from '../common/types';
import { StreamHandlerService } from '../configuration/stream/stream-handler.service';
import {
    setRemoteParticipantMuteSetting,
    setTwilioToken
} from '../dealer/one2one/state/actions/one2one-state.actions';
import { selectOne2OneState } from '../dealer/state/selectors/dealer-state.selectors';
import { One2oneSessionInfoService } from '../one2one/session-info/one2one-session-info.service';
import { LocalApplicationState } from '../state';
import { selectEngineUiState } from '../state/selectors';
import { calculateSizeByAspectRatio } from './video-chat.helper';

@UntilDestroy()
@Injectable()
export class VideoChatService {
    readonly localVideoTrack$: Observable<Nullable<LocalVideoTrack>>;

    readonly localAudioTrack$: Observable<Nullable<LocalAudioTrack>>;

    readonly remoteVideoTrack$: Observable<Nullable<RemoteVideoTrack>>;

    readonly remoteAudioTrack$: Observable<Nullable<RemoteAudioTrack>>;

    private readonly localVideoTrackSubject = new BehaviorSubject<
        Nullable<LocalVideoTrack>
    >(null);

    private readonly localAudioTrackSubject = new BehaviorSubject<
        Nullable<LocalAudioTrack>
    >(null);

    private readonly remoteVideoTrackSubject = new BehaviorSubject<
        Nullable<RemoteVideoTrack>
    >(null);

    private remoteAudioTrackSubject = new BehaviorSubject<
        Nullable<RemoteAudioTrack>
    >(null);

    private readonly beforeUnloadSubject = new Subject<BeforeUnloadEvent>();

    // Check if current iOS version is 15.1
    private readonly isIOSv15_1 =
        navigator.userAgent.indexOf('iPhone OS 15_1') > -1;

    constructor(
        private readonly dialogService: UiMatDialogService,
        private readonly applicationStateService: ApplicationStateService<
            LocalApplicationState,
            AmlUiSharedState
        >,
        private readonly streamHandlerService: StreamHandlerService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly one2OneSessionInfoService: One2oneSessionInfoService,
        rendererFactory2: RendererFactory2
    ) {
        this.localVideoTrack$ = this.localVideoTrackSubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.localAudioTrack$ = this.localAudioTrackSubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.remoteVideoTrack$ = this.remoteVideoTrackSubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.remoteAudioTrack$ = this.remoteAudioTrackSubject
            .asObservable()
            .pipe(distinctUntilChanged());

        this.initFetchAndPersistTwilioToken$();
        this.initBeforeUnloadSubject(
            rendererFactory2.createRenderer(null, null)
        );

        this.initTwilioRoomConnection$();
    }

    /**
     * Set the dealer's audio- and video settings
     */
    setUserAudioVideoSettings$(): Observable<boolean> {
        return this.dialogService
            .open$(AudioVideoSettingsDialogComponent, {
                disableClose: true,
                width: '500px',
                maxWidth: 'calc(100% - 40px)'
            })
            .pipe(
                switchMap(({ componentInstance }) =>
                    componentInstance.confirm.pipe(map(() => true))
                ),
                take(1)
            );
    }

    @MemoizeObservable()
    getRemoteParticipantMuted$(): Observable<boolean | undefined> {
        return this.applicationStateService.getLocalState().pipe(
            selectOne2OneState,
            map((state) => state.audioVideoSettings?.remoteParticipantMuted),
            distinctUntilChanged(),
            untilDestroyed(this)
        );
    }

    @MemoizeObservable()
    getCustomerName$() {
        return this.applicationStateService.getState().pipe(
            selectEngineUiState,
            map((state) => state?.participantsInfo?.sessionName),
            distinctUntilChanged(),
            map((sessionDescription) => {
                const extractNames = sessionDescription?.split(' ') || [];
                return {
                    firstName: extractNames[0],
                    lastName: extractNames.slice(1).join(' ')
                };
            })
        );
    }

    getCustomerInitials$(): Observable<string | undefined> {
        return this.getCustomerName$().pipe(
            untilDestroyed(this),
            map(({ firstName, lastName }) => {
                if (firstName && lastName) {
                    return `${firstName.substring(0, 1)}${lastName.substring(
                        0,
                        1
                    )}`;
                }
                return undefined;
            })
        );
    }

    @MemoizeObservable()
    getDealerName$(): Observable<string> {
        return this.applicationStateService.getState().pipe(
            selectEngineUiState,
            map((state) => state?.participantsInfo?.dealerName ?? 'N/A')
        );
    }

    private cleanupRoom(room?: Room) {
        room?.disconnect();
        this.onRemoteTracksUnavailable();
        this.onLocalTracksUnavailable();
    }

    private getSessionId$(): Observable<string | undefined> {
        return this.streamHandlerService.getStreamInfo$().pipe(
            map((streamInfo) => streamInfo?.session.id),
            distinctUntilChanged()
        );
    }

    private initFetchAndPersistTwilioToken$() {
        combineLatest([
            this.one2OneSessionInfoService.isOne2OneHostSessionActive$(),
            this.one2OneSessionInfoService.isOne2OneClientSessionActive$()
        ])
            .pipe(
                map(
                    ([isHostSessionActive, isClientSessionActive]) =>
                        isHostSessionActive || isClientSessionActive
                ),
                switchMap((isOne2OneSessionActive) =>
                    isOne2OneSessionActive
                        ? this.streamHandlerService.getTwilioToken$()
                        : of(undefined)
                ),
                untilDestroyed(this)
            )
            .subscribe((twilioTokenData) => {
                this.applicationStateService.dispatch(
                    setTwilioToken({
                        token: twilioTokenData?.token
                    })
                );
            });
    }

    private initTwilioRoomConnection$() {
        const audioVideoSettings$: Observable<AudioVideoSettings | undefined> =
            this.applicationStateService.getLocalState().pipe(
                selectOne2OneState,
                map((state) => state?.audioVideoSettings),
                skip(1),
                distinctUntilChanged(isEqual),
                lazyShareReplay()
            );

        const audioSettings$: Observable<
            Pick<AudioVideoSettings, 'audio' | 'audioDeviceId'> | undefined
        > = audioVideoSettings$.pipe(
            map((audioVideoSettings) => ({
                audio: audioVideoSettings?.audio,
                audioDeviceId: audioVideoSettings?.audioDeviceId
            })),
            distinctUntilChanged(isEqual)
        );
        const videoSettings$: Observable<
            Pick<AudioVideoSettings, 'video' | 'videoDeviceId'> | undefined
        > = audioVideoSettings$.pipe(
            map((audioVideoSettings) => ({
                video: audioVideoSettings?.video,
                videoDeviceId: audioVideoSettings?.videoDeviceId
            })),
            distinctUntilChanged(isEqual)
        );

        const currentTwilioRoom$ = <Observable<Room | undefined>>combineLatest([
            this.one2OneSessionInfoService.isOne2OneHostSessionActive$(),
            this.one2OneSessionInfoService.isOne2OneClientSessionActive$(),
            this.beforeUnloadSubject.pipe(startWith(undefined))
        ]).pipe(
            map(
                ([hostSessionActive, clientSessionActive, beforeUnload]) =>
                    (hostSessionActive || clientSessionActive) && !beforeUnload
            ),
            distinctUntilChanged(),
            switchMap(
                (sessionActive): Observable<TwilioTokenData | undefined> =>
                    sessionActive
                        ? this.applicationStateService.getLocalState().pipe(
                              selectOne2OneState,
                              map(
                                  (one2oneState) =>
                                      one2oneState?.twilioTokenData
                              ),
                              distinctUntilChanged(isEqual)
                          )
                        : of(undefined)
            ),
            withLatestFrom(audioVideoSettings$),
            switchMap(
                ([twilioTokenData, audioVideoSettings]): Observable<
                    Room | undefined
                > => {
                    if (!twilioTokenData?.token) {
                        return of(undefined);
                    }

                    return this.joinRoom$(
                        twilioTokenData.token,
                        audioVideoSettings || {
                            audio: false,
                            targetAudio: false,
                            video: false,
                            targetVideo: false
                        }
                    ).pipe(
                        this.errorHandlerService.applyRetryWithHintsOnError(
                            () =>
                                translate(
                                    'ONE_2_ONE.ERRORS.FAILED_JOINING_TWILIO_ROOM'
                                ),
                            { isEligibleForRetry: (error) => true }
                        )
                    );
                }
            ),
            switchMap((room) => {
                if (!room) {
                    return of(undefined);
                }

                return new Observable((subscriber) => {
                    subscriber.next(room);
                    return () => {
                        this.cleanupRoom(room);
                    };
                });
            }),
            lazyShareReplay()
        );

        // for every room emitted, keep the rooms local audio tracks in sync with audio settings
        const currentTwilioRoomWithLocalAudioSettingsApplied$ = combineLatest([
            currentTwilioRoom$,
            audioSettings$
        ]).pipe(
            tap(async ([room, currentSettings]) => {
                if (!room) {
                    return;
                }
                if (
                    room.localParticipant.audioTracks.size === 0 &&
                    currentSettings?.audio
                ) {
                    // No audio track has been published yet, create a new one
                    const localAudioTrack = await createLocalAudioTrack(
                        this.buildAudioSettings(currentSettings)
                    );
                    await room.localParticipant.publishTrack(localAudioTrack);
                    this.bindLocalAudioTracks(room);
                } else {
                    room.localParticipant.audioTracks.forEach(
                        (trackPublication) => {
                            if (currentSettings?.audio) {
                                trackPublication.track
                                    .restart(
                                        this.buildAudioSettings(currentSettings)
                                    )
                                    .then(() => {
                                        trackPublication.track.enable();
                                    });
                            } else {
                                trackPublication.track.disable();
                                trackPublication.track.stop();
                            }
                        }
                    );
                }
            }),
            map(([room]) => room)
        );

        // for every room emitted, keep the rooms local video tracks in sync with video settings
        const currentTwilioRoomWithLocalVideoSettingsApplied$ = combineLatest([
            currentTwilioRoom$,
            videoSettings$
        ]).pipe(
            tap(async ([room, currentSettings]) => {
                if (!room) {
                    return;
                }
                if (
                    room.localParticipant.videoTracks.size === 0 &&
                    currentSettings?.video
                ) {
                    // No video track has been published yet, create a new one
                    const localVideoTrack = await createLocalVideoTrack(
                        this.buildVideoSettings(currentSettings)
                    );
                    await room.localParticipant.publishTrack(localVideoTrack);
                    this.bindLocalVideoTracks(room);
                } else {
                    room.localParticipant.videoTracks.forEach(
                        (trackPublication) => {
                            if (currentSettings?.video) {
                                trackPublication.track
                                    .restart(
                                        this.buildVideoSettings(currentSettings)
                                    )
                                    .then(() =>
                                        trackPublication.track.enable()
                                    );
                            } else {
                                trackPublication.track.disable();
                                trackPublication.track.stop();
                            }
                        }
                    );
                }
            }),
            map(([room]) => room)
        );

        const currentTwilioRoomWithLocalAudioVideoSettingsApplied$ = merge(
            currentTwilioRoomWithLocalVideoSettingsApplied$,
            currentTwilioRoomWithLocalAudioSettingsApplied$
        ).pipe(distinctUntilChanged());

        currentTwilioRoomWithLocalAudioVideoSettingsApplied$.subscribe();
    }

    private joinRoom$(
        twilioToken: string,
        initialAudioVideoSettings: AudioVideoSettings
    ): Observable<Room | undefined> {
        return from(this.joinRoom(twilioToken, initialAudioVideoSettings));
    }

    async joinRoom(
        twilioToken: string,
        initialAudioVideoSettings: AudioVideoSettings
    ) {
        const videoSettings = this.buildVideoSettings(
            initialAudioVideoSettings
        );

        const audioSettings = this.buildAudioSettings(
            initialAudioVideoSettings
        );

        const connectOptionsAudioVideoSettings = {
            audio: audioSettings ?? false,
            video: videoSettings ?? false
        };

        /**
         * In case that the current client uses iOS 15.1,
         * use a different codec to prevent a bug that causes page reloads.
         * See https://github.com/twilio/twilio-video.js/issues/1611
         */
        const connectOptions: ConnectOptions = this.isIOSv15_1
            ? {
                  ...connectOptionsAudioVideoSettings,
                  preferredVideoCodecs: ['VP8']
              }
            : connectOptionsAudioVideoSettings;

        const room = await connect(twilioToken, connectOptions);

        this.bindLocalTracks(room);
        this.initRemoteParticipants(room);

        return room;
    }

    private initRemoteParticipants(room: Room) {
        // handle remote participants
        const lastRemoteParticipant = last(
            Array.from(room.participants.values())
        );

        if (lastRemoteParticipant) {
            this.onParticipantConnected(lastRemoteParticipant);
        }
        room.on('participantConnected', (participant) =>
            this.onParticipantConnected(participant)
        );
        room.on('participantDisconnected', (participant) =>
            this.onParticipantDisconnected(participant)
        );
    }

    private bindLocalTracks(room: Room) {
        // bind local tracks
        this.bindLocalVideoTracks(room);
        this.bindLocalAudioTracks(room);
    }

    private bindLocalVideoTracks(room: Room) {
        const localVideoTrack = Array.from(
            room.localParticipant.videoTracks.values()
        )[0]?.track;

        if (localVideoTrack) {
            this.onLocalVideoTrackAvailable(localVideoTrack, true);
        } else {
            this.onLocalVideoTrackUnavailable();
        }
    }

    private bindLocalAudioTracks(room: Room) {
        const localAudioTrack = Array.from(
            room.localParticipant.audioTracks.values()
        )[0]?.track;
        if (localAudioTrack) {
            this.onLocalAudioTrackAvailable(localAudioTrack, true);
        } else {
            this.onLocalAudioTrackUnavailable();
        }
    }

    private onVideoTrackPublished(publication: RemoteVideoTrackPublication) {
        if (publication.isSubscribed && publication.track) {
            this.onRemoteVideoTrackAvailable(publication.track);
        }
        publication.on('subscribed', (track) =>
            this.onRemoteVideoTrackAvailable(track)
        );
        publication.on('unsubscribed', (track) =>
            this.onRemoteVideoTrackUnavailable()
        );
    }

    private onVideoTrackUnpublished() {
        this.onRemoteVideoTrackUnavailable();
    }

    private onAudioTrackPublished(publication: RemoteAudioTrackPublication) {
        if (publication.isSubscribed && publication.track) {
            this.onRemoteAudioTrackAvailable(publication.track, true);
        }
        publication.on('subscribed', (track) => {
            this.onRemoteAudioTrackAvailable(track, true);
        });
        publication.on('unsubscribed', (track) => {
            this.onRemoteAudioTrackUnavailable();
        });
    }

    private onAudioTrackUnpublished() {
        this.onRemoteAudioTrackUnavailable();
    }

    private onRemoteAudioTrackMuteChange(muteState: boolean) {
        this.applicationStateService.dispatch(
            setRemoteParticipantMuteSetting({
                remoteParticipantMuted: muteState
            })
        );
    }

    private onLocalVideoTrackAvailable(
        track: LocalVideoTrack,
        registerListeners = false
    ) {
        this.localVideoTrackSubject.next(track);
        if (registerListeners) {
            track.on('disabled', () => this.onLocalVideoTrackUnavailable());
            track.on('enabled', () => this.onLocalVideoTrackAvailable(track));
        }
    }

    private onLocalAudioTrackAvailable(
        track: LocalAudioTrack,
        registerListeners = false
    ) {
        this.localAudioTrackSubject.next(track);
        if (registerListeners) {
            track.on('disabled', () => this.onLocalAudioTrackUnavailable());
            track.on('enabled', () => this.onLocalAudioTrackAvailable(track));
        }
    }

    private onLocalTracksUnavailable() {
        this.onLocalVideoTrackUnavailable();
        this.onLocalAudioTrackUnavailable();
    }

    private onLocalVideoTrackUnavailable() {
        this.localVideoTrackSubject.next(null);
    }

    private onLocalAudioTrackUnavailable() {
        this.localAudioTrackSubject.next(null);
    }

    private onRemoteVideoTrackAvailable(track: RemoteVideoTrack) {
        this.remoteVideoTrackSubject.next(track);
    }

    private onRemoteAudioTrackAvailable(
        track: RemoteAudioTrack,
        registerListeners = false
    ) {
        this.remoteAudioTrackSubject.next(track);
        this.onRemoteAudioTrackMuteChange(!track.isEnabled);
        if (registerListeners) {
            track.on('enabled', () => this.onRemoteAudioTrackAvailable(track));
            track.on('disabled', () => this.onRemoteAudioTrackUnavailable());
        }
    }

    private onRemoteTracksUnavailable() {
        this.onRemoteVideoTrackUnavailable();
        this.onRemoteAudioTrackUnavailable();
    }

    private onRemoteAudioTrackUnavailable() {
        this.remoteAudioTrackSubject.next(null);
        this.onRemoteAudioTrackMuteChange(true);
    }

    private onRemoteVideoTrackUnavailable() {
        this.remoteVideoTrackSubject.next(null);
    }

    private onParticipantConnected(participant: RemoteParticipant) {
        // Listen to subscription events of already published Tracks.
        participant.videoTracks.forEach((publication) =>
            this.onVideoTrackPublished(publication)
        );
        participant.audioTracks.forEach((publication) =>
            this.onAudioTrackPublished(publication)
        );

        if (participant.videoTracks.size === 0) {
            this.onRemoteVideoTrackUnavailable();
        }

        // If no audio track is published, show mute icon to the other person
        if (participant.audioTracks.size === 0) {
            this.onRemoteAudioTrackUnavailable();
        }

        ['trackEnabled', 'trackPublished'].forEach((eventName) => {
            participant.on(eventName, (publication) => {
                if (publication.kind === 'video') {
                    this.onVideoTrackPublished(
                        <RemoteVideoTrackPublication>publication
                    );
                } else if (publication.kind === 'audio') {
                    this.onAudioTrackPublished(
                        <RemoteAudioTrackPublication>publication
                    );
                }
            });
        });

        ['trackDisabled', 'trackUnpublished'].forEach((eventName) => {
            participant.on(eventName, (publication) => {
                if (publication.kind === 'video') {
                    this.onVideoTrackUnpublished();
                } else if (publication.kind === 'audio') {
                    this.onAudioTrackUnpublished();
                }
            });
        });
    }

    private onParticipantDisconnected(participant: RemoteParticipant) {
        this.onRemoteTracksUnavailable();
    }

    private buildAudioSettings(
        audioVideoSettings?: Pick<AudioVideoSettings, 'audio' | 'audioDeviceId'>
    ): CreateLocalTrackOptions | undefined {
        return audioVideoSettings?.audio
            ? {
                  deviceId: audioVideoSettings.audioDeviceId
              }
            : undefined;
    }

    private buildVideoSettings(
        audioVideoSettings?: Pick<AudioVideoSettings, 'video' | 'videoDeviceId'>
    ): CreateLocalTrackOptions | undefined {
        const videoSize = calculateSizeByAspectRatio('width', 1280, {
            aspectWidth: 16,
            aspectHeight: 9
        });

        return audioVideoSettings?.video
            ? {
                  ...videoSize,
                  deviceId: audioVideoSettings.videoDeviceId
              }
            : undefined;
    }

    private initBeforeUnloadSubject(renderer2: Renderer2) {
        new Observable(() =>
            renderer2.listen('window', 'beforeunload', (event) => {
                this.beforeUnloadSubject.next(event);
            })
        )
            .pipe(untilDestroyed(this))
            .subscribe();
    }
}
