import { cloneDeep, flatten, isEmpty, isEqual, pick } from 'lodash-es';
import { Observable, combineLatest, of } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    switchMap,
    take
} from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { GenericOption } from '@mhp-immersive-exp/contracts/src/configuration/configuration-payload.interface';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { EngineSessionData } from '@mhp/aml-ui-shared-services';
import {
    IllegalStateError,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import { OptionalObservable, toObservable } from '@mhp/common/rxjs/rxjs-types';
import { ImageSrcset, ImageSrcsetEntry } from '@mhp/ui-components';
import {
    ProductConfigurationService,
    ProductDataService,
    SerializerService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import {
    CODE_LIGHTS_OFF,
    CODE_LIGHTS_ON
} from '../car-features/car-feature-control.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';

export type ImageOnDemandResolution =
    | 240
    | 360
    | 560
    | 960
    | 1024
    | 2048
    | 3840;

export declare type IodSerializableSessionData = Omit<
    EngineSessionData,
    'country'
>;

/**
 *
 */
export interface IodRenderingOptions {
    /**
     * Alternative aspect-ratio to be used when building IOD thumbnail URLs (e.g. 1.5 for a 3/2 aspect ratio)
     */
    alternativeAspectRatio?: number;

    /**
     * Transparent background can be used to generate an image with PNG format and transparent background.
     */
    transparentBackground?: boolean;
}

/**
 * Options to be provided for customizing the srcset-rendering content generation.
 */
export interface IodSrcsetRenderingOptions extends IodRenderingOptions {
    /**
     * Alternative IOD-resolutions that should be used when building IOD thumbnail URLs
     */
    alternativeResolutions?: ImageOnDemandResolution[];
}

/**
 * Options to be provided to adjust the result of a rendering.
 */
export interface IodRenderingAdjustments {
    dayNightState?: EnvironmentLightingProfileState; // override for the day/night state
    forceAnimationsOff?: boolean; // override to force all animations off
    overrideCamera?: 'DEF_EXT' | 'DEF_INT' | string; // override to force either the default exterior, default interior or a specific camera.
    overrideAnimation?: string; // override to force a specific animation
    overrideEnvironment?: string; // override to force a specific environment
    forceOptionsOn?: ConfigModel[]; // options that should additionally be turned on
}

/**
 * Options to be provided to adjust an existing configuration according to the
 * given requirements.
 */
interface IodConfigurationAdjustments {
    forceLightsState?: 'ON' | 'OFF'; // should lights be forced to a state
    forceAnimationsOff?: boolean; // should animations be forced off
    forceAnimationOn?: string; // should a single animation be forced on (so take its relatedOptions into account)
    forceOptionsOn?: ConfigModel[];
}

/**
 * Service responsible for providing URLs to image renderings that are based on session-information.
 */
@Injectable({
    providedIn: 'root'
})
export class StaticRendererService {
    constructor(
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly productDataService: ProductDataService,
        private readonly productConfigurationService: ProductConfigurationService,
        private readonly serializerService: SerializerService,
        private readonly httpClient: HttpClient
    ) {}

    /**
     * Get the URL to the currently valid rendering of the session data (which may be undefined in case no session-data is available)
     * @param resolution The resolution in which to get the rendering for.
     * @param options Optional. See IodRenderingOptions
     * @param renderingAdjustments Optional. See IodRenderingAdjustments
     */
    getActiveSessionRendering$(
        resolution: ImageOnDemandResolution,
        options?: IodRenderingOptions,
        renderingAdjustments?: IodRenderingAdjustments
    ) {
        return this.getRendering$(
            this.configurationSessionInfoService
                .getActiveConfigurationSessionInfo$()
                .pipe(map((sessionInfo) => sessionInfo?.engineData)),
            resolution,
            options,
            renderingAdjustments
        );
    }

    /**
     * Get the URL to the currently valid rendering of the session data (which may be undefined in case no session-data is available)
     * @param options Optional. See IodSrcsetRenderingOptions
     * @param renderingAdjustments Optional. See IodRenderingAdjustments
     */
    getActiveSessionRenderingSrcset$(
        options?: IodSrcsetRenderingOptions,
        renderingAdjustments?: IodRenderingAdjustments
    ): Observable<ImageSrcset | undefined> {
        return this.getRenderingSrcset$(
            this.configurationSessionInfoService
                .getActiveConfigurationSessionInfo$()
                .pipe(map((sessionInfo) => sessionInfo?.engineData)),
            options,
            renderingAdjustments
        );
    }

    /**
     * Get a factory-function that provides, based on the currently valid session data, an ImageSrcset that is customisable.
     * @param options Optional. See IodSrcsetRenderingOptions
     */
    getActiveSessionRenderingSrcsetFactory$(
        options?: IodSrcsetRenderingOptions
    ): Observable<
        | ((
              callback: (sessionInfo: EngineSessionData) => EngineSessionData
          ) => ImageSrcset)
        | undefined
    > {
        return this.getActiveSessionDataFactory$().pipe(
            map((factoryCallback) => {
                if (!factoryCallback) {
                    return undefined;
                }

                return (callback) => {
                    const modifiedSessionInfo = factoryCallback(callback);
                    return this.getRenderingSrcset(
                        modifiedSessionInfo,
                        options
                    );
                };
            })
        );
    }

    /**
     * Get the URL to the rendering which is valid for the session-info emitted from the stream given as input.
     * @param sessionInfo$ A stream emitting a session-info for the rendering to be based on.
     * @param resolution The resolution in which to get the rendering for.
     * @param options Optional. See IodRenderingOptions
     * @param renderingAdjustments Optional. See IodRenderingAdjustments
     */
    getRendering$(
        sessionInfo$: Observable<EngineSessionData | undefined>,
        resolution: ImageOnDemandResolution,
        options?: IodRenderingOptions,
        renderingAdjustments?: IodRenderingAdjustments
    ): Observable<string | undefined> {
        return this.getRenderingSrcset$(
            sessionInfo$,
            {
                ...options,
                alternativeResolutions: [resolution]
            },
            renderingAdjustments
        ).pipe(
            map((imageSrcSet) => imageSrcSet?.sources[0].url),
            lazyShareReplay()
        );
    }

    /**
     * Get the srceset URLs to the rendering which is valid for the session-info emitted from the stream given as input.
     * @param sessionInfo$ A stream emitting a session-info for the rendering to be based on.
     * @param options Optional. See SrcsetIodRenderingOptions
     * @param renderingAdjustments Optional. See IodRenderingAdjustments
     */
    getRenderingSrcset$(
        sessionInfo$: OptionalObservable<EngineSessionData | undefined>,
        options?: IodSrcsetRenderingOptions,
        renderingAdjustments?: IodRenderingAdjustments
    ): Observable<ImageSrcset | undefined> {
        return this.getRenderingSrcsetWithSessionData$(
            sessionInfo$,
            options,
            renderingAdjustments
        ).pipe(
            map((srcsetAndSessionData) => srcsetAndSessionData?.imageSrcset)
        );
    }

    /**
     * Get the srceset URLs to the rendering which is valid for the session-info emitted from the stream given as input.
     * @param sessionInfo$ A stream emitting a session-info for the rendering to be based on.
     * @param options Optional. See SrcsetIodRenderingOptions
     * @param renderingAdjustments Optional. See IodRenderingAdjustments
     */
    getRenderingSrcsetWithSessionData$(
        sessionInfo$: OptionalObservable<EngineSessionData | undefined>,
        options?: IodSrcsetRenderingOptions,
        renderingAdjustments?: IodRenderingAdjustments
    ): Observable<
        | {
              imageSrcset: ImageSrcset;
              sessionData: EngineSessionData;
          }
        | undefined
    > {
        const sessionInfoObservable$ = toObservable(sessionInfo$).pipe(
            distinctUntilChangedEquality()
        );

        return combineLatest({
            sessionInfo: sessionInfoObservable$,
            adjustedOptions: sessionInfoObservable$.pipe(
                distinctUntilChanged((prev, current) =>
                    isEqual(
                        pick(prev, 'country', 'config'),
                        pick(current, 'country', 'config')
                    )
                ),
                switchMap((sessionInfo) => {
                    if (!sessionInfo) {
                        return of(undefined);
                    }

                    const { country } = sessionInfo;
                    const productId = sessionInfo.config.id;
                    const configModel = sessionInfo.config.options.config;

                    let forceLightsState: 'ON' | 'OFF' | undefined;
                    if (
                        renderingAdjustments?.dayNightState ===
                        EnvironmentLightingProfileState.DAY
                    ) {
                        forceLightsState = 'OFF';
                    } else if (
                        renderingAdjustments?.dayNightState ===
                        EnvironmentLightingProfileState.NIGHT
                    ) {
                        forceLightsState = 'ON';
                    }
                    return this.getAdjustedOptions$(
                        country,
                        productId,
                        configModel,
                        {
                            forceAnimationsOff:
                                !!renderingAdjustments?.forceAnimationsOff,
                            forceAnimationOn:
                                renderingAdjustments?.overrideAnimation,
                            forceLightsState,
                            forceOptionsOn: renderingAdjustments?.forceOptionsOn
                        }
                    );
                })
            ),
            cameraOverride: sessionInfoObservable$.pipe(
                map((sessionInfo) => sessionInfo?.config.id),
                distinctUntilChanged(),
                switchMap((productId) => {
                    if (!productId) {
                        return of(undefined);
                    }

                    return renderingAdjustments?.overrideCamera &&
                        ['DEF_EXT', 'DEF_INT'].includes(
                            renderingAdjustments.overrideCamera
                        )
                        ? this.productDataService
                              .getAvailableCamerasForProduct$(productId)
                              .pipe(
                                  map((availableCameras) => {
                                      // default ext camera
                                      if (
                                          renderingAdjustments?.overrideCamera ===
                                          'DEF_EXT'
                                      ) {
                                          return availableCameras?.defaultExt;
                                      }
                                      // default int camera
                                      if (
                                          renderingAdjustments?.overrideCamera ===
                                          'DEF_INT'
                                      ) {
                                          return availableCameras?.defaultInt;
                                      }
                                      return undefined;
                                  })
                              )
                        : of(renderingAdjustments?.overrideCamera);
                })
            )
        }).pipe(
            debounceTime(0),
            map(({ sessionInfo, adjustedOptions, cameraOverride }) => {
                if (!sessionInfo) {
                    return undefined;
                }

                const configModel = sessionInfo.config.options.config;

                let animations: GenericOption[] | undefined = [
                    ...(sessionInfo.animations ?? [])
                ];

                if (
                    renderingAdjustments?.forceAnimationsOff ||
                    renderingAdjustments?.overrideAnimation
                ) {
                    animations = renderingAdjustments?.overrideAnimation
                        ? [
                              {
                                  id: renderingAdjustments.overrideAnimation,
                                  options: {}
                              }
                          ]
                        : [];
                }

                let environmentOptions = {};

                if (
                    renderingAdjustments?.dayNightState ===
                    EnvironmentLightingProfileState.DAY
                ) {
                    environmentOptions = { night: false };
                } else if (
                    renderingAdjustments?.dayNightState ===
                    EnvironmentLightingProfileState.NIGHT
                ) {
                    environmentOptions = { night: true };
                }

                const adjustedEngineSessionData: EngineSessionData = {
                    ...sessionInfo,
                    // use potentially patched configuration
                    config: {
                        ...sessionInfo.config,
                        options: {
                            config: adjustedOptions ?? configModel
                        }
                    },
                    // use potential overridden camera
                    camera: {
                        ...sessionInfo.camera,
                        id: cameraOverride ?? sessionInfo.camera.id,
                        options: {
                            ...(cameraOverride
                                ? {}
                                : sessionInfo.camera.options)
                        }
                    },
                    // use potential day/night override
                    environment: {
                        ...sessionInfo.environment,
                        ...(renderingAdjustments?.overrideEnvironment
                            ? {
                                  id: renderingAdjustments?.overrideEnvironment
                              }
                            : {}),
                        options: {
                            ...sessionInfo.environment.options,
                            ...environmentOptions
                        }
                    },
                    // use potential animations override
                    animations
                };

                // check if we are about to render a non-supported environment for IOD
                const nonSupportedIodEnvironment =
                    environment.appConfig.visualization.iodNonSupportingEnvironments.find(
                        (nonSupportingEnv) =>
                            nonSupportingEnv.id ===
                            adjustedEngineSessionData.environment.id
                    );
                if (nonSupportedIodEnvironment) {
                    // we have a non-supported environment - apply fallback for rendering
                    adjustedEngineSessionData.environment = {
                        id: nonSupportedIodEnvironment.fallbackEnvironment,
                        options: {}
                    };
                    if (nonSupportedIodEnvironment.fallbackCamera) {
                        adjustedEngineSessionData.camera = {
                            id: nonSupportedIodEnvironment.fallbackCamera,
                            options: {}
                        };
                    }
                }

                return {
                    imageSrcset: this.getRenderingSrcset(
                        adjustedEngineSessionData,
                        options
                    ),
                    sessionData: adjustedEngineSessionData
                };
            }),
            distinctUntilChangedEquality(),
            lazyShareReplay()
        );
    }

    /**
     * Get the rendering for the given SerializableSessionInfo
     * @param sessionInfo The sessionInfo for which to get the rendering-url for.
     * @param resolution The resolution in which to get the rendering for.
     * @param options Optional. See IodRenderingOptions
     */
    getRendering(
        sessionInfo: EngineSessionData,
        resolution: ImageOnDemandResolution,
        options?: IodRenderingOptions
    ) {
        let modifiedSessionData = sessionInfo;

        if (options?.alternativeAspectRatio) {
            modifiedSessionData = {
                ...sessionInfo,
                resolution: {
                    height: Math.round(
                        resolution / options.alternativeAspectRatio
                    )
                }
            };
        }
        if (options?.transparentBackground) {
            modifiedSessionData = {
                ...sessionInfo,
                transparentBackground: true
            };
        }

        const serializedSessionInfo =
            this.getSerializedIodEngineSessionData(modifiedSessionData);

        return `${environment.appConfig.imageProxy.url}/${modifiedSessionData.country}/${modifiedSessionData.config.id}/${resolution}/${serializedSessionInfo}`;
    }

    /**
     * Get the srcset URLS for renderings for the given EngineSessionData
     * @param sessionInfo The sessionInfo for which to get the rendering-url for.
     * @param options Optional. See SrcsetRenderingOptions
     */
    getRenderingSrcset(
        sessionInfo: EngineSessionData,
        options?: IodSrcsetRenderingOptions
    ): ImageSrcset {
        return {
            sources: (
                options?.alternativeResolutions ??
                environment.appConfig.imageProxy.srcsetResolutions
            ).map(
                (resolution: ImageOnDemandResolution): ImageSrcsetEntry => ({
                    url: this.getRendering(sessionInfo, resolution, options),
                    targetWidth: resolution
                })
            )
        };
    }

    /**
     * Returns an observable emitting a blob representing a zip-archive containing a list of renderings of the currently active
     * configuration, one for each available camera as provided by ProductDataService or the optional list of camera-ids provided as
     * parameters
     * @param cameraIds Optional list of camera-ids to be used for the archive being generated.
     */
    getRenderingsArchiveForActiveProduct$(
        cameraIds?: string[]
    ): Observable<Blob> {
        const availableCameras$ = cameraIds
            ? of(cameraIds)
            : this.productDataService
                  .getAvailableCameras$()
                  .pipe(
                      map((camerasPayload) =>
                          camerasPayload?.cameras.map((camera) => camera.id)
                      )
                  );

        return combineLatest([
            availableCameras$,
            this.getActiveSessionDataFactory$()
        ]).pipe(
            take(1),
            map(([cameraIdsInternal, activeSessionDataFactory]) => {
                if (!cameraIdsInternal || isEmpty(cameraIdsInternal)) {
                    throw new IllegalStateError('No cameras available');
                }
                if (!activeSessionDataFactory) {
                    throw new IllegalStateError(
                        'No active session data available'
                    );
                }

                const activeSessionData = activeSessionDataFactory(
                    (sessionData) => sessionData
                );
                const mappedConfigurations = cameraIdsInternal.map(
                    (currentCamera) =>
                        activeSessionDataFactory((sessionData) => ({
                            ...sessionData,
                            camera: {
                                id: currentCamera,
                                options: []
                            }
                        }))
                );

                return {
                    country: activeSessionData.country,
                    productId: activeSessionData.config.id,
                    resolution: <ImageOnDemandResolution>3840,
                    configurations: mappedConfigurations
                };
            }),
            switchMap((endpointInput) =>
                this.httpClient.post(
                    `${environment.appConfig.imageProxy.url}/${endpointInput.country}/${endpointInput.productId}/${endpointInput.resolution}`,
                    endpointInput.configurations,
                    {
                        responseType: 'blob'
                    }
                )
            )
        );
    }

    getSerializedIodEngineSessionData(sessionInfo: EngineSessionData): string {
        const data: IodSerializableSessionData = {
            config: sessionInfo.config,
            camera: sessionInfo.camera,
            environment: sessionInfo.environment,
            animations: sessionInfo.animations,
            resolution: sessionInfo.resolution,
            transparentBackground: sessionInfo.transparentBackground
        };

        const watermark = sessionInfo.meta?.watermark;

        if (watermark) {
            data.meta = {
                watermark
            };
        }

        return this.serializerService.serializeData(data);
    }

    getDeserializedIodEngineSessionData(
        serializedSessionData: string
    ): IodSerializableSessionData {
        return this.serializerService.deserializeData<IodSerializableSessionData>(
            serializedSessionData
        );
    }

    /**
     * Get a definition of adjusted configuration options that have optionally certain criteria fulfilled.
     *
     * @param country
     * @param productId
     * @param configModel
     * @param options Additional options to take into account, e.g.
     *                - if lights should be forced off
     *                - if animation-related options should be forced off
     *                - if an animation-related option should be forced on
     */
    private getAdjustedOptions$(
        country: string,
        productId: string,
        configModel: ConfigModel[],
        options: IodConfigurationAdjustments
    ): Observable<ConfigModel[] | undefined> {
        return this.productDataService
            .getAvailableAnimationsForProduct$(productId)
            .pipe(
                switchMap((availableAnimations) => {
                    let optionsToBeAdded: ConfigModel[] = [];
                    let optionsToBeRemoved: ConfigModel[] = [];

                    if (options?.forceOptionsOn) {
                        optionsToBeAdded.push(...options.forceOptionsOn);
                    }

                    if (options?.forceLightsState) {
                        if (options.forceLightsState === 'ON') {
                            optionsToBeAdded.push(CODE_LIGHTS_ON);
                            optionsToBeRemoved.push(CODE_LIGHTS_OFF);
                        } else {
                            optionsToBeAdded.push(CODE_LIGHTS_OFF);
                            optionsToBeRemoved.push(CODE_LIGHTS_ON);
                        }
                    }

                    if (options?.forceAnimationOn) {
                        optionsToBeAdded.push(
                            ...flatten<ConfigModel>(
                                availableAnimations.find(
                                    (animation) =>
                                        animation.id ===
                                        options.forceAnimationOn
                                )?.relatedOptions ?? []
                            )
                        );
                    }

                    optionsToBeAdded = optionsToBeAdded.filter(
                        (option): option is ConfigModel =>
                            !!option &&
                            !configModel.find((otherOption) =>
                                isEqual(option, otherOption)
                            )
                    );

                    if (
                        options?.forceAnimationsOff ||
                        options?.forceAnimationOn
                    ) {
                        optionsToBeRemoved.push(
                            ...flatten(
                                availableAnimations
                                    .filter((animation) =>
                                        options?.forceAnimationOn
                                            ? animation.id !==
                                              options?.forceAnimationOn
                                            : true
                                    )
                                    .map(
                                        (animation) =>
                                            animation.relatedOptions || []
                                    )
                            )
                        );
                    }

                    optionsToBeRemoved = optionsToBeRemoved.filter(
                        (option): option is ConfigModel =>
                            !!option &&
                            !!configModel.find((otherOption) =>
                                isEqual(option, otherOption)
                            )
                    );

                    if (
                        isEmpty(optionsToBeAdded) &&
                        isEmpty(optionsToBeRemoved)
                    ) {
                        // nothing to be done
                        return of(configModel);
                    }

                    return this.productConfigurationService.patchConfiguration$(
                        productId,
                        country,
                        configModel,
                        optionsToBeAdded,
                        optionsToBeRemoved
                    );
                })
            );
    }

    /**
     * Get a factory-function that provides, based on the currently valid session data, an updated EngineSessionData.
     */
    private getActiveSessionDataFactory$(): Observable<
        | ((
              callback: (sessionInfo: EngineSessionData) => EngineSessionData
          ) => EngineSessionData)
        | undefined
    > {
        return this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                map((activeSessionInfo) => {
                    if (!activeSessionInfo) {
                        return undefined;
                    }
                    return (callback) =>
                        callback(cloneDeep(activeSessionInfo.engineData));
                })
            );
    }
}
