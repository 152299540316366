import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiButtonModule } from '@mhp/ui-components';

import { SessionInfoDebugComponent } from './session-info-debug.component';

@NgModule({
    declarations: [SessionInfoDebugComponent],
    imports: [CommonModule, UiButtonModule, FormsModule],
    exports: [SessionInfoDebugComponent]
})
export class SessionInfoDebugModule {}
