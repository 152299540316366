<div
    class="configuration-trackpad"
    [class.configuration-trackpad--disabled]="disabled"
>
    <mhp-ui-touch-panel
        (panChange)="onPanChange($event)"
        (zoomChange)="onZoomChange($event)"
        (wheel)="onWheel($event)"
        [disabled]="disabled"
        [zoomDampening]="zoomDampening"
    >
    </mhp-ui-touch-panel>

    <div *ngIf="disabled" class="configuration-trackpad__disabled-hint">
        TOUCHPAD_INACTIVE
        <!-- TODO: i18n -->
    </div>
</div>
