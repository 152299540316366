import { A11y, Keyboard } from 'swiper';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ConfigurationAreaActionsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-area-actions';
import {
    UiButtonModule,
    UiIconButtonModule,
    UiIconsModule,
    UiImageSrcsetModule,
    UiLazyloadImageModule,
    UiSelectModule,
    UiSwiperModule,
    UiTooltipModule,
    UiTurntableModule
} from '@mhp/ui-components';
import { NgVariableModule } from '@mhp/ui-shared-services';

import { LoadingIndicatorModule } from '../../../common/loading-indicator/loading-indicator.module';
import { DialogModule } from '../../../dialog/dialog.module';
import { EnvironmentSelectionDialogComponent } from './environment-selection-dialog/environment-selection-dialog.component';
import { EnvironmentSelectionInfoComponent } from './environment-selection-info/environment-selection-info.component';
import { EnvironmentSelectionTurntableComponent } from './environment-selection-turntable/environment-selection-turntable.component';
import { EnvironmentSelectionService } from './environment-selection.service';

UiSwiperModule.registerFeatures(Keyboard, A11y);
@NgModule({
    declarations: [
        EnvironmentSelectionDialogComponent,
        EnvironmentSelectionInfoComponent,
        EnvironmentSelectionTurntableComponent
    ],
    imports: [
        CommonModule,
        DialogModule,
        UiSwiperModule,
        TranslocoModule,
        UiImageSrcsetModule,
        UiIconButtonModule,
        UiButtonModule,
        UiSelectModule,
        UiLazyloadImageModule,
        LoadingIndicatorModule,
        UiTurntableModule,
        ConfigurationAreaActionsModule,
        NgVariableModule,
        UiTooltipModule,
        UiIconsModule
    ],
    providers: [EnvironmentSelectionService]
})
export class EnvironmentSelectionModule {}
