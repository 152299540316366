import { Observable, combineLatest, merge, of, switchMap } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    map
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { CAP_CODES } from '@mhp/aml-shared/derivate-mapping/derivates';
import { DealerInfoService } from '@mhp/aml-ui-shared-services';
import {
    MemoizeObservable,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import { ErrorHandlerService, L10nService } from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { PricingService } from '../../dealer/pricing/pricing.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { FinancialServicesInputService } from './financial-services-input.service';
import {
    FinancialServiceOfferBase,
    OfferRow
} from './financial-services.interfaces';
import { FinancialServicesService } from './financial-services.service';

@Injectable()
export class FinancialServicesSessionService<
    FSO extends FinancialServiceOfferBase = FinancialServiceOfferBase
> {
    /**
     * This is a temporary placeholder for a strategy-based approach that needs to be implemented with
     * the next financial service integration for another region.
     * @private
     */
    private readonly supportedCountries = ['GB'];

    constructor(
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly financialServicesService: FinancialServicesService<FSO>,
        private readonly financialServicesInputService: FinancialServicesInputService,
        private readonly dealerInfoService: DealerInfoService,
        private readonly pricingService: PricingService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly l10nService: L10nService,
        private readonly errorHandlerService: ErrorHandlerService
    ) {}

    /**
     * Get the currently valid financial service offer for the currently valid configuration.
     * Might be undefined in case no financial service offer is available or if there is
     * no financial service provider registered for the active region.
     */
    @MemoizeObservable()
    getFinancialServiceOffer$(): Observable<FSO | undefined> {
        return combineLatest([
            // the input that's currently valid
            this.financialServicesInputService
                .getFinancialServiceOfferInput$()
                .pipe(distinctUntilChangedEquality()),
            this.configurationSessionInfoService.getActiveConfigurationSessionInfo$(),
            // the active state of the financial services options
            this.isFinancialServiceOptionAvailable$()
        ]).pipe(
            debounceTime(0),
            switchMap(
                ([
                    financialServiceOfferInput,
                    configurationSessionInfo,
                    financialServiceOptionAvailable
                ]) => {
                    if (
                        !financialServiceOfferInput ||
                        !configurationSessionInfo?.engineData?.config
                            ?.options ||
                        !financialServiceOptionAvailable
                    ) {
                        return of(undefined);
                    }

                    // on every change, first clear / invalidate the current offer
                    return merge(
                        of(undefined),
                        this.financialServicesService
                            .getFinancialServiceOffer$(
                                configurationSessionInfo.productId,
                                configurationSessionInfo.country,
                                financialServiceOfferInput,
                                configurationSessionInfo.engineData.config
                                    .options.config
                            )
                            .pipe(
                                this.errorHandlerService.applyRetry({
                                    messageProviderOnFinalError: () =>
                                        translate(
                                            'SUMMARY.FIN_SERVICES.ERROR.FAILED_CREATING_OFFER'
                                        )
                                }),
                                catchError(() => of(undefined))
                            )
                    );
                }
            ),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getFinancialServiceOfferRowRepresentation$(): Observable<
        OfferRow[] | undefined
    > {
        return this.getFinancialServiceOffer$().pipe(
            map((offer): OfferRow[] | undefined => {
                if (!offer) {
                    return undefined;
                }

                return this.financialServicesService.getFinancialServiceOfferRowRepresentation(
                    offer
                );
            }),
            lazyShareReplay()
        );
    }

    /**
     * Emit if the user is able to work on a financial service offer.
     * This depends on:
     * - Feature-flag is active
     * - We have a valid dealer-login
     * - The dealer is working with RRP prices
     * - The active region has financial service support
     */
    @MemoizeObservable()
    isFinancialServiceOptionAvailable$(): Observable<boolean> {
        if (!environment.appConfig.featureToggles.enableFinancialServices) {
            return of(false);
        }

        return combineLatest([
            this.dealerInfoService
                .getActiveDealerInfo$()
                .pipe(catchError(() => of(undefined))),
            this.pricingService.getActivePricingType$(),
            this.l10nService.getActiveCountry$(),
            this.configurationSessionInfoService.getActiveProductId$()
        ]).pipe(
            map(
                ([
                    dealerInfo,
                    activePricingType,
                    activeCountry,
                    activeProductId
                ]) =>
                    // dealer has to be registered for the same country as the active one
                    !!dealerInfo &&
                    dealerInfo.countryISO2 === activeCountry &&
                    // only available for RRP
                    activePricingType === 'RRP' &&
                    // active country has to be set
                    !!activeCountry &&
                    // active country has to be supported
                    this.supportedCountries.includes(activeCountry) &&
                    // active product id has to have a matching CAP-code
                    !!activeProductId &&
                    !!CAP_CODES[activeProductId]
            ),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }
}
