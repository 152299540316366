import { EMPTY, Observable, defer, forkJoin, interval, merge, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    finalize,
    first,
    map,
    startWith,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';

import { ComponentPortal, Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { translate } from '@jsverse/transloco';
import { ReservationData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/reservation-data.js';
import {
    LanguagePreference,
    Title
} from '@mhp/aml-shared/data-proxy/salesforce-types';
import {
    EngineSessionData,
    One2oneSessionBookingService,
    SalesforceService,
    environmentShared
} from '@mhp/aml-ui-shared-services';
import { Memoize, UserCancelledError, lazyShareReplay } from '@mhp/common';
import {
    UiMatDialogService,
    UiNavigationEntry,
    UiNotificationService
} from '@mhp/ui-components';
import {
    ApplicationStateService,
    CommonDialogsService,
    ErrorHandlerService,
    I18nService,
    UrlShortenerService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import {
    ROUTE_ORDER_EDIT,
    ROUTE_ORDER_MANAGEMENT,
    ROUTE_ORDER_OVERVIEW
} from '../../app-route-names';
import { BACKDROP_CLASS_BLURRY } from '../../common/dialog/dialog.constants';
import {
    CONFIGURATION_MENU_SIDEBAR_ENTRY_LOGOUT,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_OM,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_ORDER_EDIT,
    CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_ONE2ONE
} from '../../configuration/configuration-menu/configuration-menu.constants';
import { FinancialServicesInputService } from '../../configuration/financial-services/financial-services-input.service';
import { FinancialServicesSessionService } from '../../configuration/financial-services/financial-services-session.service';
import { ConfigurationSessionInfoService } from '../../configuration/session-info/configuration-session-info.service';
import { VisualizationMode } from '../../configuration/state/local-configuration-state.interface';
import { VisualizationModeService } from '../../configuration/visualization-mode/visualization-mode.service';
import {
    MODEL_SELECT_SIDEBAR_ENTRY_OM,
    MODEL_SELECT_SIDEBAR_ENTRY_REQUEST_ONE2ONE
} from '../../model-select/model-select/model-select.constants';
import { One2oneSessionInfoService } from '../../one2one/session-info/one2one-session-info.service';
import { OrderManagementService } from '../../order-management/order-management.service';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { VideoChatService } from '../../video-chat/video-chat.service';
import { SessionControlComponent } from '../one2one/configuration/session-control/session-control.component';
import { One2oneLocationSyncService } from '../one2one/one2one-location-sync.service';
import { CreateOne2oneSessionComponent } from '../one2one/session-management/create-one2one-session/create-one2one-session.component';
import { One2oneConfirmComponent } from '../one2one/session-management/one2one-confirm/one2one-confirm.component';
import { StartSessionComponent } from '../one2one/start-session/start-session.component';
import { setTargetOne2OneSessionData } from '../one2one/state/actions/one2one-state.actions';
import { DealerSalesforceIonComponent } from '../salesforce-ion/dealer-salesforce-ion.component';
import { selectOne2OneState } from '../state/selectors/dealer-state.selectors';
import { DealerHooks } from './dealer-hooks.interface';
import { FinancialServiceHooks } from './financial-services/financial-service-hooks.interface';
import { FinancialServiceHooksService } from './financial-services/financial-service-hooks.service';

const NAV_ENTRY_ONE2ONE = 'ONE2ONE';

/**
 * Full implementation of DealerHooks interface for dealer context.
 */
@Injectable()
export class DealerHooksService implements DealerHooks {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly sessionBookingService: One2oneSessionBookingService,
        private readonly sessionInfoService: One2oneSessionInfoService,
        private readonly one2oneLocationSyncService: One2oneLocationSyncService,
        private readonly visualizationModeService: VisualizationModeService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly commonDialogsService: CommonDialogsService,
        private readonly dialogService: UiMatDialogService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly notificationService: UiNotificationService,
        private readonly router: Router,
        private readonly msalService: MsalService,
        private readonly salesforceService: SalesforceService,
        private readonly i18nService: I18nService,
        private readonly videoChatService: VideoChatService,
        private readonly urlShortenerService: UrlShortenerService,
        private readonly orderManagementService: OrderManagementService,
        private readonly financialServicesSessionService: FinancialServicesSessionService,
        private readonly financialServicesInputService: FinancialServicesInputService
    ) {
        this.initResetOne2OneSessionDataOnStreamReset();
    }

    intentLogout$(): Observable<true> {
        return this.msalService.logout().pipe(map(() => true));
    }

    openSubmitToSalesforceOrVoeDialog$(): Observable<void> {
        const voeDialog$ = this.dialogService
            .openFullscreen$(DealerSalesforceIonComponent)
            .pipe(map(() => undefined));

        return voeDialog$;
    }

    getConfigurationMainOne2OneSessionControl$(): Observable<
        Portal<any> | undefined
    > {
        return this.sessionInfoService.isOne2OneHostSessionActive$().pipe(
            map((hostSessionActive) => {
                if (!hostSessionActive) {
                    return undefined;
                }
                return new ComponentPortal<SessionControlComponent>(
                    SessionControlComponent
                );
            }),
            lazyShareReplay()
        );
    }

    adjustSidebarItems(
        sidebarItems: UiNavigationEntry<string>[],
        context: 'MODEL-SELECT' | 'CONFIGURATION-MENU' | 'ORDER-MANAGEMENT'
    ): UiNavigationEntry<string>[] {
        const createOrder: UiNavigationEntry<string> = {
            id: CONFIGURATION_MENU_SIDEBAR_ENTRY_ORDER_EDIT,
            label: translate('ICONS.CREATE_ORDER'),
            iconId: 'mhp-ui:create_order'
        };
        const ordermanagement: UiNavigationEntry<string> = {
            id: CONFIGURATION_MENU_SIDEBAR_ENTRY_OM,
            label: translate('ICONS.ORDER_MANAGEMENT'),
            iconId: 'mhp-ui:create_order'
        };
        const logout: UiNavigationEntry<string> = {
            id: CONFIGURATION_MENU_SIDEBAR_ENTRY_LOGOUT,
            label: translate('MENU_DEALER.LOGOUT'),
            iconId: 'mhp-ui:m_logout'
        };
        const modifiedSidebarItems =
            this.replaceOne2OneSessionRequestSidebarItem(sidebarItems, context);
        if (context === 'CONFIGURATION-MENU') {
            modifiedSidebarItems.push(createOrder);
        } else if (context === 'MODEL-SELECT') {
            modifiedSidebarItems.push(ordermanagement);
            modifiedSidebarItems.push(logout);
        }
        return modifiedSidebarItems;
    }

    handleSidebarItemSelect(
        itemId: string,
        context: 'MODEL-SELECT' | 'CONFIGURATION-MENU' | 'ORDER-MANAGEMENT'
    ): boolean {
        switch (itemId) {
            case MODEL_SELECT_SIDEBAR_ENTRY_OM:
                this.openOMOrderOverview();
                return true;
            case NAV_ENTRY_ONE2ONE:
                return this.startConfiguration(context);
            case CONFIGURATION_MENU_SIDEBAR_ENTRY_ORDER_EDIT:
                this.orderManagementService
                    .openEditView$()
                    .pipe(take(1))
                    .subscribe();
                return true;
            case CONFIGURATION_MENU_SIDEBAR_ENTRY_LOGOUT:
                this.intentLogout$().subscribe();
                return true;
            default:
                return false;
        }
    }

    openOMOrderOverview() {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([
                `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_OVERVIEW}`
            ])
        );
        window.open(url, '_blank');
    }

    openOMOrderEdit() {
        this.router.navigateByUrl(
            `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_EDIT}`
        );
    }

    startConfiguration(
        context: 'MODEL-SELECT' | 'CONFIGURATION-MENU' | 'ORDER-MANAGEMENT'
    ): boolean {
        let startConfiguration$:
            | Observable<EngineSessionData | undefined>
            | undefined;

        if (context === 'MODEL-SELECT') {
            // TODO: determine active model-id in model-selection to provide start-configuration based on corresponding default-configuration
            startConfiguration$ = of(undefined);
        } else if (context === 'CONFIGURATION-MENU') {
            startConfiguration$ = this.configurationSessionInfoService
                .getActiveConfigurationSessionInfo$()
                .pipe(
                    first(),
                    map((sessionInfo) => {
                        if (!sessionInfo) {
                            return undefined;
                        }

                        return {
                            ...sessionInfo.engineData,
                            country: sessionInfo.country
                        };
                    })
                );
        }

        if (!startConfiguration$) {
            return true;
        }

        startConfiguration$.pipe(first()).subscribe((startConfiguration) => {
            this.openCreateOne2OneSessionDialog(startConfiguration);
        });
        return true;
    }

    handleDealerStartsSession$(
        route: ActivatedRouteSnapshot
    ): Observable<void> {
        return this.resolveReservationDataFromUrl$(route).pipe(
            tap((reservationData) => {
                if (reservationData) {
                    // apply the reservation data
                    this.applicationStateService.dispatch(
                        setTargetOne2OneSessionData({
                            sessionData: reservationData
                        })
                    );

                    // start stream
                    this.visualizationModeService.setTargetVisualizationMode(
                        VisualizationMode.STREAM
                    );
                }
            }),
            map(() => undefined)
        );
    }

    @Memoize()
    getFinancialServiceHooks(): FinancialServiceHooks {
        return new FinancialServiceHooksService(
            this.financialServicesSessionService,
            this.financialServicesInputService,
            this.dialogService,
            this.errorHandlerService
        );
    }

    private initResetOne2OneSessionDataOnStreamReset() {
        this.visualizationModeService
            .getTargetVisualizationMode$()
            .pipe(filter((visMode) => visMode === VisualizationMode.BASIC))
            .subscribe(() => {
                // clear session-data when target vis-mode is set back to BASIC again for whatever reason
                this.applicationStateService.dispatch(
                    setTargetOne2OneSessionData({
                        sessionData: undefined
                    })
                );
            });
    }

    private replaceOne2OneSessionRequestSidebarItem(
        sidebarItems: UiNavigationEntry<string>[],
        context: 'MODEL-SELECT' | 'CONFIGURATION-MENU' | 'ORDER-MANAGEMENT'
    ) {
        if (!environment.appConfig.featureToggles.one2oneBuyingExperience) {
            return sidebarItems;
        }

        if (context !== 'MODEL-SELECT' && context !== 'CONFIGURATION-MENU') {
            return sidebarItems;
        }

        let one2oneEntryTargetIndex = -1;

        const one2oneEntryToBeReplaced = sidebarItems.find(
            (item) =>
                item.id === MODEL_SELECT_SIDEBAR_ENTRY_REQUEST_ONE2ONE ||
                item.id === CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_ONE2ONE
        );
        if (one2oneEntryToBeReplaced) {
            one2oneEntryTargetIndex = sidebarItems.indexOf(
                <UiNavigationEntry<string>>one2oneEntryToBeReplaced
            );
        }
        const modifiedSidebarItems = sidebarItems.filter(
            (item) => item !== one2oneEntryToBeReplaced
        );

        const one2oneItem: UiNavigationEntry<string> = {
            id: NAV_ENTRY_ONE2ONE,
            iconId: 'mhp-ui:one2one',
            label: translate('MENU_DEALER.CTA_ONE2ONE')
        };

        if (one2oneEntryTargetIndex > -1) {
            modifiedSidebarItems.splice(
                one2oneEntryTargetIndex,
                0,
                one2oneItem
            );
        } else {
            modifiedSidebarItems.push(one2oneItem);
        }
        return modifiedSidebarItems;
    }

    /**
     * Check the url for the session-key parameter.
     * @param route
     * @private
     */
    private resolveReservationDataFromUrl$(
        route: ActivatedRouteSnapshot
    ): Observable<ReservationData | undefined> {
        const reservationKey =
            this.one2oneLocationSyncService.extractReservationKey(route);
        const reservationData$ = this.one2oneLocationSyncService
            .resolveReservationDataFromUrl$(route)
            .pipe(
                catchError(() => {
                    this.router.navigate(['/']);

                    return EMPTY;
                })
            );

        return reservationData$.pipe(
            switchMap((reservationData) => {
                if (!reservationData) {
                    return of(undefined);
                }

                const polledReservationData$ = interval(10000).pipe(
                    startWith(0),
                    map(() => reservationData),
                    lazyShareReplay()
                );

                const userConfirmedSessionStart$ = this.showStartSessionDialog$(
                    polledReservationData$
                ).pipe(first());

                return this.applicationStateService.getLocalState().pipe(
                    selectOne2OneState,
                    map((one2OneState) => one2OneState?.targetSessionData?.id),
                    distinctUntilChanged(),
                    switchMap((targetSessionDataId) => {
                        // session key is the same, we don't need to ask again to start session
                        if (targetSessionDataId === reservationKey) {
                            return of(undefined);
                        }
                        return this.videoChatService
                            .setUserAudioVideoSettings$()
                            .pipe(switchMap(() => userConfirmedSessionStart$));
                    }),
                    catchError((error) => {
                        if (error instanceof UserCancelledError) {
                            // user cancelled
                            this.router.navigate(['/']);
                        }
                        throw error;
                    })
                );
            })
        );
    }

    private showStartSessionDialog$(
        sessionData$: Observable<ReservationData>
    ): Observable<ReservationData> {
        return this.dialogService
            .open$(StartSessionComponent, {
                backdropClass: BACKDROP_CLASS_BLURRY,
                width: '600px'
            })
            .pipe(
                switchMap((dialogRef) => {
                    const componentRef = dialogRef.componentInstance;
                    componentRef.reservationData$ = sessionData$;

                    return merge(
                        dialogRef.afterClosed().pipe(
                            tap(() => {
                                throw new UserCancelledError(
                                    'Cancel start session'
                                );
                            })
                        ),
                        componentRef.startSession
                    );
                })
            );
    }

    private openCreateOne2OneSessionDialog(
        startConfiguration: EngineSessionData | undefined
    ) {
        this.dialogService
            .openFullscreen$(CreateOne2oneSessionComponent)
            .pipe(
                switchMap(({ componentInstance }) => {
                    const bookStreamingSession$ = defer(() => {
                        componentInstance.initializingData = {
                            startConfiguration
                        };

                        return componentInstance.createSession.pipe(
                            tap(() => {
                                componentInstance.serverCallInProgress = true;
                            }),
                            tap(() =>
                                this.notificationService.showNotification(
                                    translate(
                                        'ONE_2_ONE.NEW_SESSION.SUBMIT_IN_PROGRESS'
                                    )
                                )
                            ),
                            switchMap((sessionData) =>
                                this.sessionBookingService
                                    ?.bookSession$({
                                        participantsCount: 2,
                                        sessionStart: sessionData.sessionStart,
                                        description: sessionData.description,
                                        sessionSpecs: {
                                            [environmentShared.appConfig.stream
                                                .sessionUsageSessionClientKey]:
                                                environment.appConfig.stream
                                                    .sessionUsageSessionClientValue,
                                            [environmentShared.appConfig.stream
                                                .sessionUsageSessionTypeKey]:
                                                'one2one'
                                        }
                                    })
                                    .pipe(
                                        map((bookedSessionInfo) => ({
                                            sessionData,
                                            bookedSessionInfo
                                        })),
                                        this.errorHandlerService.applyRetryWithHintsOnError(
                                            (error) =>
                                                `${translate(
                                                    `ONE_2_ONE.NEW_SESSION.ERROR.FAILED_CREATING_SESSION`
                                                )}: ${(error as Error).message}`
                                        ),
                                        catchError(() => EMPTY),
                                        finalize(() => {
                                            componentInstance.serverCallInProgress =
                                                false;
                                        })
                                    )
                            )
                        );
                    });

                    const bookSalesforceSession$ = bookStreamingSession$.pipe(
                        tap(() => {
                            componentInstance.serverCallInProgress = true;
                        }),
                        withLatestFrom(this.i18nService.getActiveLang$()),
                        // shorten URLs
                        switchMap(
                            ([sessionDataAndBookedInfo, activeLanguage]) =>
                                forkJoin({
                                    shortenedSessionLinkCustomer:
                                        this.urlShortenerService.shortenUrl$(
                                            this.sessionInfoService.getClientUrlToSession(
                                                sessionDataAndBookedInfo.bookedSessionInfo
                                            )
                                        ),
                                    shortenedSessionLinkDealer:
                                        this.urlShortenerService.shortenUrl$(
                                            this.sessionInfoService.getDealerUrlToSession(
                                                sessionDataAndBookedInfo.bookedSessionInfo,
                                                sessionDataAndBookedInfo
                                                    .sessionData
                                                    ?.startConfiguration
                                            )
                                        )
                                }).pipe(
                                    map(
                                        ({
                                            shortenedSessionLinkCustomer,
                                            shortenedSessionLinkDealer
                                        }) => ({
                                            sessionDataAndBookedInfo,
                                            activeLanguage,
                                            shortenedSessionLinkCustomer,
                                            shortenedSessionLinkDealer
                                        })
                                    )
                                )
                        ),
                        switchMap(
                            ({
                                sessionDataAndBookedInfo,
                                activeLanguage,
                                shortenedSessionLinkCustomer,
                                shortenedSessionLinkDealer
                            }) => {
                                const { sessionData } =
                                    sessionDataAndBookedInfo;
                                return this.salesforceService
                                    .createDealerOne2OneBooking$({
                                        title: <Title>sessionData.title,
                                        firstName: sessionData.firstName,
                                        lastName: sessionData.lastName,
                                        customerEmail:
                                            sessionData.customerEmail,
                                        customerPhone:
                                            sessionData.customerPhone,
                                        countryIsoCode: sessionData.country,
                                        languagePreference: <
                                            LanguagePreference
                                        >activeLanguage,
                                        preferredDate:
                                            sessionData.sessionStart.getTime(),
                                        bookingTimeZone: sessionData.timeZone,
                                        sessionLinkCustomer:
                                            shortenedSessionLinkCustomer,
                                        sessionLinkDealer:
                                            shortenedSessionLinkDealer
                                    })
                                    .pipe(
                                        this.errorHandlerService.applyRetryWithHintsOnError(
                                            (error) =>
                                                translate(
                                                    `ONE_2_ONE.NEW_SESSION.ERROR.FAILED_CREATING_SESSION`
                                                )
                                        ),
                                        catchError(() =>
                                            // delete already created session
                                            this.sessionBookingService
                                                .deleteSession$(
                                                    sessionDataAndBookedInfo
                                                        .bookedSessionInfo.id
                                                )
                                                .pipe(
                                                    catchError(() => EMPTY),
                                                    switchMap(() => EMPTY)
                                                )
                                        ),
                                        map(() => sessionDataAndBookedInfo),
                                        finalize(() => {
                                            componentInstance.serverCallInProgress =
                                                false;
                                        })
                                    );
                            }
                        )
                    );

                    return bookSalesforceSession$.pipe(
                        switchMap((sessionDataAndBookedInfo) => {
                            if (
                                environment.appConfig.one2one
                                    .showCopySessionLinksDialog
                            ) {
                                return this.dialogService
                                    .open$(One2oneConfirmComponent)
                                    .pipe(
                                        tap((dialogRef) => {
                                            const confirmComponentInstance =
                                                dialogRef.componentInstance;
                                            confirmComponentInstance.sessionData =
                                                sessionDataAndBookedInfo.sessionData;
                                            confirmComponentInstance.reservationData =
                                                sessionDataAndBookedInfo.bookedSessionInfo;
                                        }),
                                        switchMap((dialogRef) =>
                                            dialogRef.afterClosed()
                                        ),
                                        map(() => sessionDataAndBookedInfo)
                                    );
                            }
                            return of(sessionDataAndBookedInfo);
                        }),
                        tap((sessionDataAndBookedInfo) => {
                            this.notificationService.showAutohideNotification(
                                translate(
                                    'ONE_2_ONE.CREATED_SESSION.HEADLINE',
                                    {
                                        sessionName:
                                            sessionDataAndBookedInfo.sessionData
                                                .description
                                    }
                                )
                            );
                        })
                    );
                }),
                take(1)
            )
            .subscribe();
    }
}
