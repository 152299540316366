<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    class="load-code-dialog"
    *transloco="let t"
    [title]="t('AML_CODE.LOAD.DIALOG.TITLE')"
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <p>{{ t('AML_CODE.LOAD.DIALOG.DESCRIPTION') }}</p>
        <form [formGroup]="codeFormGroup">
            <mat-form-field
                *ngFor="
                    let formControl of getFormControls();
                    let index = index;
                    trackBy: trackByIndex
                "
            >
                <input
                    #codeInput
                    matInput
                    maxlength="1"
                    [formControl]="formControl"
                    (paste)="onPaste($event)"
                    (input)="onInput(index)"
                    (focus)="onFocus(index)"
                    (keydown.backspace)="onBackspace(index)"
                    (keydown.ArrowLeft)="onArrowMove(index, 'L')"
                    (keydown.ArrowRight)="onArrowMove(index, 'R')"
                />
            </mat-form-field>
        </form>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-right>
        <button
            #loadCode
            mat-flat-button
            class="mhp-ui-button--full-width"
            [disabled]="
                !codeFormGroup.valid || (serverCallInProgress$ | async) === true
            "
            (click)="intentLoadCode()"
            [trackGA4Event]="{
                event: 'load_configuration_start'
            }"
        >
            <span>{{ t('AML_CODE.LOAD.DIALOG.CTA') }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
