import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { OverflowIndicatorModule } from '@mhp/aml-ui-shared-components/common/overflow-indicator';
import { ConfigurationElementsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import {
    UiIconButtonModule,
    UiIconsModule,
    UiTooltipModule
} from '@mhp/ui-components';
import {
    I18nModule,
    L10nModule,
    NgVariableModule,
    ProductConfigurationModule
} from '@mhp/ui-shared-services';

import { ImagesModule } from '../../../common/images/images.module';
import { DealerHelperModule } from '../../../dealer/dealer-helper/dealer-helper.module';
import { ConfigurationPricingModule } from '../../configuration-pricing/configuration-pricing.module';
import { ConfigurationCodeComponent } from './configuration-code/configuration-code.component';
import { ConfigurationCompositeItemsListComponent } from './configuration-composite-items-list/configuration-composite-items-list.component';
import { ConfigurationOptionsListComponent } from './configuration-options-list/configuration-options-list.component';
import { ConfigurationOptionsComponent } from './configuration-options.component';

@NgModule({
    declarations: [
        ConfigurationOptionsComponent,
        ConfigurationCodeComponent,
        ConfigurationOptionsListComponent,
        ConfigurationCompositeItemsListComponent
    ],
    imports: [
        CommonModule,
        MatRippleModule,
        CdkScrollableModule,

        ConfigurationElementsModule,
        ProductConfigurationModule,
        OverflowIndicatorModule,
        ImagesModule,

        UiIconButtonModule,
        UiIconsModule,
        I18nModule,
        L10nModule,
        DealerHelperModule,
        UiTooltipModule,
        ConfigurationPricingModule,
        NgVariableModule
    ],
    exports: [
        ConfigurationOptionsComponent,
        ConfigurationOptionsListComponent,
        ConfigurationCompositeItemsListComponent
    ]
})
export class ConfigurationOptionsModule {}
