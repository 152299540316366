<div class="configuration-search" *transloco="let t; read: 'CONFIGURATOR'">
    <mhp-ui-icon-button
        *transloco="let t; read: 'ICONS'"
        svgIcon="mhp-ui:search"
        [matTooltip]="t('SEARCH_ALT')"
        [label]="t('SEARCH_ALT')"
        (click)="intentToggleSearch()"
    ></mhp-ui-icon-button>
    <div
        class="configuration-search__input"
        [attr.aria-hidden]="
            (isSearchInputActive$() | async) === false ? 'true' : 'false'
        "
    >
        <input
            #searchInputElement
            type="text"
            [placeholder]="t('SEARCH.PLACEHOLDER')"
            [attr.aria-label]="t('SEARCH.PLACEHOLDER')"
            [tabIndex]="(isSearchInputActive$() | async) === false ? -1 : 0"
            [formControl]="searchInput"
            (keydown.enter)="intentTriggerSearch()"
            (keydown.esc)="intentExitSearch()"
        />
        <button
            mat-button
            class="mhp-ui-button--link-style"
            [tabIndex]="(isSearchInputActive$() | async) === false ? -1 : 0"
            (click)="intentTriggerSearch()"
        >
            {{ t('SEARCH.CTA') }}
        </button>
        <mhp-ui-icon-button
            *transloco="let t; read: 'ICONS'"
            svgIcon="mhp-ui:close"
            size="small"
            [matTooltip]="t('SEARCH_CLOSE_ALT')"
            [label]="t('SEARCH_CLOSE_ALT')"
            [tabIndex]="(isSearchInputActive$() | async) === false ? -1 : 0"
            (click)="intentExitSearch()"
        ></mhp-ui-icon-button>
    </div>
</div>
