import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiTouchPanelModule } from '@mhp/ui-components';

import { ConfigurationTrackpadComponent } from './configuration-trackpad.component';

// FIXME: Refactor so that basic engine-related functionality moves to a shared component
@NgModule({
    declarations: [ConfigurationTrackpadComponent],
    imports: [CommonModule, UiTouchPanelModule],
    exports: [ConfigurationTrackpadComponent]
})
export class ConfigurationTrackpadModule {}
