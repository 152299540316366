<mhp-ui-mat-dialog
    *transloco="let t; read: 'CONFIGURATOR.MODEL_CONFIG_UNAVAILABLE.DIALOG'"
    [title]="t('TITLE')"
    [showClose]="false"
    class="model-outdated-dialog"
>
    <mhp-ui-mat-dialog-content>
        <mhp-static-renderer
            [aspectRatio]="16 / 9"
            [renderActiveSessionData]="true"
            [renderingAdjustments]="DEFAULT_RENDERING_ADJUSTMENTS"
        ></mhp-static-renderer>
        <p>{{ t('DESCRIPTION') }}</p>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            class="mhp-ui-button--full-width"
            (click)="intentStartConfig()"
            [trackGA4Event]="{
                event: 'model_unavailable_start_config_click'
            }"
        >
            {{ t('CTA_START') }}
        </button>
        <a
            mat-button
            class="mhp-ui-button--full-width"
            href="https://www.astonmartin.com/enquiry"
            target="_blank"
            [trackGA4Event]="{
                event: 'model_unavailable_find_dealer_click'
            }"
        >
            <span>{{ t('CTA_FIND_DEALER') }}</span>
            <mhp-ui-icon
                svgIcon="mhp-ui:arrow-right"
                size="small"
            ></mhp-ui-icon>
        </a>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
