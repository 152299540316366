<ng-container *transloco="let t">
    <ng-container *ngVariable="getTracks$() | async as tracks">
        <ng-container *ngIf="tracks">
            <mhp-attach-video-track
                [videoTrack]="tracks.video"
                [audioTrack]="tracks.audio"
                [showGradient]="
                    location !== 'HEADER_BAR' ||
                    ((getRemoteParticipantMuted$() | async) === true &&
                        trackSource === 'REMOTE')
                "
            >
            </mhp-attach-video-track>
        </ng-container>
        <mhp-video-placeholder
            *ngIf="!tracks?.video && !useBackgroundMode"
            [initials]="getInitials$() | async"
            [location]="location"
        ></mhp-video-placeholder>
    </ng-container>

    <mhp-ui-icon
        class="twilio-participant-track__mute-icon-hint"
        [ngClass]="
            location === 'HEADER_BAR'
                ? 'twilio-participant-track__mute-icon-hint__header-bar'
                : ''
        "
        *ngIf="
            !useBackgroundMode &&
            trackSource === 'REMOTE' &&
            (getRemoteParticipantMuted$() | async) === true
        "
        svgIcon="mhp-ui:mute"
        [size]="location === 'HEADER_BAR' ? ICON_SIZE.XSMALL : ICON_SIZE.MEDIUM"
        [matTooltip]="t('AUDIO_VIDEO_SETTINGS.REMOTE_MUTE_HINT')"
    >
    </mhp-ui-icon>
</ng-container>
