import { defer } from 'lodash-es';
import { ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { GoogleTagManagerService, gtmGA4Track } from '@mhp/ui-shared-services';

import { environment } from '../../../../environments/environment';
import { RequestPdfFormComponent } from '../../../dealer/salesforce/request-pdf-form/request-pdf-form.component';
import { LabelHelperService } from '../../../i18n/label-helper.service';

@Component({
    selector: 'mhp-request-pdf-via-email',
    templateUrl: './request-pdf-via-email.component.html',
    styleUrls: ['./request-pdf-via-email.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestPdfViaEmailComponent
    extends UiBaseComponent
    implements AfterViewInit, OnDestroy
{
    readonly isDealerBuild = environment.appConfig.dealer.dealerBuild;

    @ViewChild(RequestPdfFormComponent)
    requestPdfFormComponent: RequestPdfFormComponent;

    salesforceFormGroup: UntypedFormGroup;

    private readonly viewChildReferencesBound = new ReplaySubject<boolean>(1);

    constructor(
        private readonly labelHelperService: LabelHelperService,
        private readonly googleTagManagerService: GoogleTagManagerService
    ) {
        super();

        this.completeOnDestroy(this.viewChildReferencesBound);
    }

    ngAfterViewInit() {
        defer(() => this.viewChildReferencesBound.next(true));
        gtmGA4Track('email_brochure_open_click');
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        gtmGA4Track('email_brochure_abandonment');
    }

    @MemoizeObservable()
    getModelLabel$() {
        return this.labelHelperService.getActiveModelAndProductName$();
    }

    @MemoizeObservable()
    canSubmit$() {
        return this.viewChildReferencesBound.pipe(
            switchMap(() => {
                const formComponentRef = this.requestPdfFormComponent;

                return formComponentRef.getServerCallInProgress$().pipe(
                    map((formSubmitInProgress) => !formSubmitInProgress),
                    lazyShareReplay()
                );
            })
        );
    }
}
