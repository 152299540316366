<div class="video-placeholder__background">
    <div
        *ngIf="initials?.length > 0"
        class="video-placeholder__circle"
        [ngClass]="location === 'HEADER_BAR' ? 'circle-small' : 'circle-large'"
    >
        <span class="video-placeholder__circle__initials">{{
            initials.substring(0, 2)
        }}</span>
    </div>
</div>
