<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    *transloco="let t"
    [title]="t('ORDER_MANAGEMENT.DETAIL_VIEW.ORDER_COPY_DIALOG.HEADLINE')"
>
    <mhp-ui-mat-dialog-content>
        <p>
            {{
                t('ORDER_MANAGEMENT.DETAIL_VIEW.ORDER_COPY_DIALOG.DESCRIPTION')
            }}
        </p>
        <mat-form-field>
            <mat-label>{{
                t('ORDER_MANAGEMENT.DETAIL_VIEW.ORDER_COPY_DIALOG.LABEL_COPIES')
            }}</mat-label>
            <input
                matInput
                [formControl]="numberOfCopiesFormControl"
                type="number"
            />
        </mat-form-field>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-button
            (click)="matDialogRef.close()"
        >
            {{ t('COMMON.CANCEL') }}
        </button>
        <button
            mat-flat-button
            (click)="intentConfirm()"
        >
            {{ t('COMMON.CONFIRM') }}
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
