import { Observable, of } from 'rxjs';

/**
 * Type that is either sync or optionally Observable in nature.
 */
export type OptionalObservable<T> = T | Observable<T>;

/**
 * Convert an OptionalObservable to an Observable.
 * @param optionalObservable
 */
export const toObservable = <T>(
    optionalObservable: OptionalObservable<T>
): Observable<T> => optionalObservable instanceof Observable
        ? optionalObservable
        : of(optionalObservable);
