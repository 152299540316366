import {
    MODEL_SELECT_SIDEBAR_ENTRY_AML_CODE,
    MODEL_SELECT_SIDEBAR_ENTRY_LOGOUT,
    MODEL_SELECT_SIDEBAR_ENTRY_OM,
    MODEL_SELECT_SIDEBAR_ENTRY_ORDER_EDIT,
    MODEL_SELECT_SIDEBAR_ENTRY_REQUEST_ONE2ONE,
    MODEL_SELECT_SIDEBAR_ENTRY_REQUEST_TESTDRIVE,
    MODEL_SELECT_SIDEBAR_ENTRY_SHARE
} from '../../model-select/model-select/model-select.constants';

export const CONFIGURATION_MENU_SIDEBAR_ENTRY_CHANGE_REGION = 'CHANGE_REGION';
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_RESET_CONFIGURATION =
    'RESET_CONFIGURATION';
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_SHARE =
    MODEL_SELECT_SIDEBAR_ENTRY_SHARE;
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_LOCATE_DEALER = 'LOCATE_DEALER';
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_TESTDRIVE =
    MODEL_SELECT_SIDEBAR_ENTRY_REQUEST_TESTDRIVE;
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_REQUEST_ONE2ONE =
    MODEL_SELECT_SIDEBAR_ENTRY_REQUEST_ONE2ONE;
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_AML_CODE =
    MODEL_SELECT_SIDEBAR_ENTRY_AML_CODE;
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_OM =
    MODEL_SELECT_SIDEBAR_ENTRY_OM;
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_ORDER_EDIT =
    MODEL_SELECT_SIDEBAR_ENTRY_ORDER_EDIT;
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_LOGOUT =
    MODEL_SELECT_SIDEBAR_ENTRY_LOGOUT;
export const CONFIGURATION_MENU_SIDEBAR_ENTRY_MAX_ITEMS_MOBILE_PORTRAIT = 6;
