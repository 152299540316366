import {
    first as arrayFirst,
    isEmpty,
    isEqual,
    isNil,
    omit,
    pick
} from 'lodash-es';
import {
    BehaviorSubject,
    EMPTY,
    MonoTypeOperatorFunction,
    Observable,
    ReplaySubject,
    Subject,
    combineLatest,
    defer,
    forkJoin,
    merge,
    of,
    race,
    throwError
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    shareReplay,
    skip,
    startWith,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';

import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { translate } from '@jsverse/transloco';
import { ConfigOption } from '@mhp-immersive-exp/contracts/src/configuration/config-option.interface';
import { OptionGroup } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { SelectedState } from '@mhp-immersive-exp/contracts/src/product/product-selection.enum';
import { AmlProjectParam } from '@mhp/aml-shared/infor/models/infor-project-param.interface';
import { PricingMap, PricingType } from '@mhp/aml-ui-shared-services';
import {
    CustomError,
    IllegalStateError,
    MemoizeObservable,
    UserCancelledError,
    connectedPublishReplay,
    deepSortObject,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import {
    AnimationState,
    ConfigurationState,
    EnvironmentState
} from '@mhp/communication-models';
import { UiNotificationService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    CommonDialogsService,
    ConfigurationConverterMode,
    ConfigurationConverterService,
    ConfigurationFilter,
    ConfigurationInfo,
    EngineControlService,
    ErrorHandlerService,
    L10nService,
    ProductConfiguration,
    ProductConfigurationService,
    gtmGA4Track,
    selectConfigurationState
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { PricingService } from '../../dealer/pricing/pricing.service';
import { selectPricingType } from '../../dealer/state/selectors/dealer-state.selectors';
import { AmlProductDataService } from '../../product-data/aml-product-data-service';
import { LocalApplicationState } from '../../state';
import {
    CODE_LIGHTS_OFF,
    CODE_LIGHTS_ON
} from '../car-features/car-feature-control.service';
import { ProductConfigurationOptions } from '../common/configuration-interfaces';
import {
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiOptionCode,
    ExtendedUiOptionGroup
} from '../configuration-model/configuration-interfaces';
import { ExtendedUiOptionGroupMapperService } from '../configuration-model/extended-ui-option-group-mapper.service';
import { CpqSessionHandlerService } from '../cpq/cpq-session-handler.service';
import { InvalidEnvironmentError, InvalidProductError } from '../errors';
import { ConfigurationFilterService } from '../search/configuration-filter.service';
import {
    setActiveModelId,
    setUpdateSessionDataInProgress
} from '../state/actions/configuration.actions';
import { selectActiveModelId } from '../state/selectors/configuration.selectors';
import {
    isContentAware,
    isExtendedUiOptionCode,
    isSelectionAware
} from './configuration-helper';
import {
    ConfigurationNodeLookupService,
    NodeWithParent
} from './configuration-node-lookup.service';
import { SelectionChangeInfo } from './node-selection-handler';
import { NodeSelectionHandlerService } from './node-selection-handler.service';

export interface ProductConfigurationSessionServiceConfig {
    skipPromoteConfigurationChangesToEngine: boolean;
}

export const PRODUCT_CONFIGURATION_SESSION_SERVICE_CONFIG_TOKEN =
    new InjectionToken<ProductConfigurationSessionServiceConfig>(
        'ProductConfigurationSessionServiceConfig'
    );

export interface ProductConfigurationSessionData {
    productId: string;
    country: string;
    configuration?: ProductConfigurationOptions;
    environmentState?: EnvironmentState | undefined;
    camera?: string | undefined;
    animations?: AnimationState[];
}

/**
 * A variant of the ProductConfigurationSessionData which allows an incomplete
 * set of settings.
 * - environmentState.state: Does not need to be explicitly defined. Will be added when starting a validating session data, taking environment-defaults into account.
 */
export type PartialProductConfigurationSessionData = Omit<
    ProductConfigurationSessionData,
    'environmentState'
> & {
    environmentState?: Omit<EnvironmentState, 'state'> & {
        state: EnvironmentLightingProfileState | undefined;
    };
};

interface RulerInput
    extends Pick<
        ProductConfigurationSessionData,
        'productId' | 'configuration' | 'country'
    > {
    configurationChangeInfo?: {
        selected?: ExtendedUiOptionCode[];
        deselected?: ExtendedUiOptionCode[];
        selectedConfigurationOptions?: ProductConfigurationOptions;
        deselectedConfigurationOptions?: ProductConfigurationOptions;
    };
    isSessionRestartIntent: boolean;
    modelId: string;
    source: 'EXTERNAL' | 'INTERNAL';
}

@Injectable()
export class ProductConfigurationSessionService {
    private readonly configurationSessionErrors$ = new Subject<CustomError>();

    /**
     * Emits ids of options that are selected (and thus need to be toggled)
     */
    private readonly selectionChangeSubject =
        new Subject<SelectionChangeInfo>();

    /**
     * Holds the currently valid set of configuration metadata including non-ui-visible items.
     */
    private readonly mappedConfigurationInfoIncludingNonVisible$: Observable<
        ConfigurationInfo<ExtendedUiOptionGroup> | undefined
    >;

    /**
     * Holds the currently valid set of configuration metadata to be rendered by the UI.
     */
    private readonly mappedConfigurationInfo$: Observable<
        ConfigurationInfo<ExtendedUiOptionGroup> | undefined
    >;

    /**
     * Holds a subset of #mappedConfigurationInfo$ namely filtered by selection state
     */
    private readonly mappedOptionGroupsSelectedItemsOnly$: Observable<
        ConfigurationInfo<ExtendedUiOptionGroup> | undefined
    >;

    private readonly pendingResolveSubject = new BehaviorSubject<boolean>(
        false
    );

    constructor(
        private applicationStateService: ApplicationStateService<LocalApplicationState>,
        private productConfigurationService: ProductConfigurationService<AmlProjectParam>,
        private readonly cpqSessionHandlerService: CpqSessionHandlerService,
        private productDataService: AmlProductDataService,
        private optionGroupMapperService: ExtendedUiOptionGroupMapperService,
        private nodeSelectionHandler: NodeSelectionHandlerService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly configurationFilterService: ConfigurationFilterService,
        private configurationConverterService: ConfigurationConverterService,
        private notificationService: UiNotificationService,
        private commonDialogsService: CommonDialogsService,
        private engineControlService: EngineControlService,
        private readonly l10nService: L10nService,
        private errorHandlerService: ErrorHandlerService,
        private readonly pricingService: PricingService,
        @Inject(PRODUCT_CONFIGURATION_SESSION_SERVICE_CONFIG_TOKEN)
        @Optional()
        private readonly config?: ProductConfigurationSessionServiceConfig
    ) {
        this.mappedConfigurationInfoIncludingNonVisible$ =
            this.initConfigurationSessionLogic();
        this.mappedConfigurationInfo$ = this.initOptionGroupsVisibleUiItemsOnly(
            this.mappedConfigurationInfoIncludingNonVisible$
        );
        this.mappedOptionGroupsSelectedItemsOnly$ =
            this.initOptionGroupsSelectedItemsOnly(
                this.mappedConfigurationInfo$
            );

        this.initUpdateModelIdFromProductIdLogic();
    }

    /**
     * Starts a new configuration for a given productId taking into account a possible
     * active configuration (thus asking to discard active config if dirty).
     *
     * @param sessionData The data to start a new session for
     * @param dialogHeaderText The text to be used for the confirmation dialog header in case current config is dirty.
     * @param dialogBodyText The text to be used for the confirmation dialog body in case current config is dirty.
     */
    startSessionForProduct$(
        sessionData: ProductConfigurationSessionData,
        dialogHeaderText?: string,
        dialogBodyText?: string
    ): Observable<void> {
        let notificationRef: MatSnackBarRef<any> | undefined;

        const startNewConfiguration$ = merge(
            this.getOptionGroupsUpdateErrors$(),
            this.updateEngineStateFromSessionData(sessionData)
        ).pipe(
            tap(
                (
                    resultOrError:
                        | CustomError
                        | ProductConfigurationSessionData
                        | undefined
                ) => {
                    if (resultOrError instanceof CustomError) {
                        notificationRef =
                            this.errorHandlerService.showErrorMessage(
                                () => resultOrError.message,
                                resultOrError
                            );
                    }
                }
            ),
            filter(
                (
                    resultOrError:
                        | CustomError
                        | ProductConfigurationSessionData
                        | undefined
                ) => !(resultOrError instanceof CustomError)
            ),
            take(1),
            tap(() => notificationRef?.dismiss())
        );

        return this.intentDiscardConfiguration$(
            startNewConfiguration$,
            dialogHeaderText,
            dialogBodyText
        ).pipe(map(() => undefined));
    }

    /**
     * Start a new configuration session for the given sessionData.
     * @param sessionData
     * @param options Optionally provide some options on how to apply session data to engine state
     *                - skipEnvironmentStateCheck: skip the check if the current environment needs enforcement of day/night state
     */
    updateEngineStateFromSessionData(
        sessionData: PartialProductConfigurationSessionData,
        options = {
            skipEnvironmentStateCheck: false
        }
    ): Observable<ProductConfigurationSessionData> {
        // mark update session data in progress
        this.applicationStateService.dispatch(
            setUpdateSessionDataInProgress({
                updateSessionDataInProgress: true
            })
        );

        return this.validateAndPopulateSessionData$(sessionData, options).pipe(
            // apply to state
            withLatestFrom(
                this.applicationStateService
                    .getState()
                    .pipe(selectConfigurationState)
            ),
            switchMap(([populatedSessionData, currentConfigurationState]) => {
                if (!populatedSessionData.configuration) {
                    throw new IllegalStateError(
                        'Expected configuration to be defined'
                    );
                }
                if (
                    !populatedSessionData.environmentState ||
                    !populatedSessionData.camera
                ) {
                    throw new IllegalStateError(
                        'Expected environment and camera to be defined'
                    );
                }

                /*
                 * Build an array of Observables that reflect the actions that need to be taken to update the engine-state.
                 * Note that this collects the Observables only but does NOT directly execute the actions (Observables are not
                 * subscribed to immediately). The collected actions are only executed once subscribed to (if required) further down.
                 */
                const requiredAdjustments: Observable<unknown>[] = [];

                const productIdChanged =
                    populatedSessionData.productId !==
                    currentConfigurationState?.productState?.id;
                const countryChanged =
                    populatedSessionData.country !==
                    currentConfigurationState?.productState?.country;
                const configurationChanged = !isEqual(
                    populatedSessionData.configuration,
                    currentConfigurationState?.productState?.configOptions
                );

                if (
                    productIdChanged ||
                    countryChanged ||
                    configurationChanged
                ) {
                    requiredAdjustments.push(
                        // update the configuration
                        this.updateConfigurationInternal$(
                            populatedSessionData.productId,
                            populatedSessionData.country,
                            populatedSessionData.configuration
                        )
                    );
                }

                if (
                    populatedSessionData.environmentState.id !==
                    currentConfigurationState?.environmentState?.id
                ) {
                    requiredAdjustments.push(
                        this.engineControlService.setActiveEnvironmentId(
                            populatedSessionData.environmentState.id
                        )
                    );
                }

                if (
                    populatedSessionData.environmentState.state !==
                    currentConfigurationState?.environmentState?.state
                ) {
                    requiredAdjustments.push(
                        this.engineControlService.setEnvironmentMode(
                            populatedSessionData.environmentState.state
                        )
                    );
                }

                if (
                    productIdChanged ||
                    populatedSessionData.camera !==
                        currentConfigurationState?.cameraState?.id
                ) {
                    requiredAdjustments.push(
                        this.engineControlService.setActiveCamera(
                            populatedSessionData.camera
                        )
                    );
                }

                const animationsToBeEnabled =
                    populatedSessionData.animations?.filter(
                        (targetAnimationState) =>
                            targetAnimationState.state === 'END' &&
                            !currentConfigurationState?.animationStates?.find(
                                (currentAnimationState) =>
                                    currentAnimationState.id ===
                                        targetAnimationState.id &&
                                    currentAnimationState.state === 'END'
                            )
                    );
                const animationsToBeDisabled =
                    currentConfigurationState?.animationStates?.filter(
                        (currentAnimationState) =>
                            currentAnimationState.state !== 'START' &&
                            !populatedSessionData?.animations?.find(
                                (targetAnimationState) =>
                                    currentAnimationState.id ===
                                        targetAnimationState.id &&
                                    targetAnimationState.state === 'END'
                            )
                    );
                if (
                    !isEmpty(animationsToBeEnabled) ||
                    !isEmpty(animationsToBeDisabled)
                ) {
                    animationsToBeEnabled?.forEach((currentAnimation) => {
                        requiredAdjustments.push(
                            this.engineControlService.setAnimationState(
                                currentAnimation.id,
                                'END'
                            )
                        );
                    });
                    animationsToBeDisabled?.forEach((currentAnimation) => {
                        requiredAdjustments.push(
                            this.engineControlService.setAnimationState(
                                currentAnimation.id,
                                'START'
                            )
                        );
                    });
                }

                // initialize result with no adjustments required
                let requiredAdjustmentsResult$: Observable<unknown> =
                    of(undefined);

                if (requiredAdjustments.length === 1) {
                    // apply a single change via its dedicated single-apply adjustment
                    [requiredAdjustmentsResult$] = requiredAdjustments;
                } else if (requiredAdjustments.length > 1) {
                    // if more than one adjustment is required, use applyapplicationstate to apply all changes at once
                    requiredAdjustmentsResult$ =
                        this.engineControlService.applyEngineControlState$({
                            productState: {
                                id: populatedSessionData.productId,
                                country: populatedSessionData.country,
                                configOptions:
                                    populatedSessionData.configuration
                            },
                            environmentState: {
                                id: populatedSessionData.environmentState.id,
                                state: populatedSessionData.environmentState
                                    .state
                            },
                            cameraState: {
                                id: populatedSessionData.camera
                            },
                            animationStates: populatedSessionData.animations
                        });
                }

                return requiredAdjustmentsResult$.pipe(
                    map(() => populatedSessionData),
                    finalize(() => {
                        // mark update session data finished
                        this.applicationStateService.dispatch(
                            setUpdateSessionDataInProgress({
                                updateSessionDataInProgress: false
                            })
                        );
                    })
                );
            })
        );
    }

    /**
     * Subscribe to an observable chain that emits the currently valid
     * OptionGroups including non-UI-visible items.
     */
    @MemoizeObservable()
    getOptionGroupsIncludingNonVisible$(): Observable<
        ExtendedUiOptionGroup[] | undefined
    > {
        return this.mappedConfigurationInfoIncludingNonVisible$.pipe(
            map((configurationInfo) => configurationInfo?.configuration)
        );
    }

    /**
     * Subscribe to an observable chain that emits the currently valid
     * ConfigurationInfo to be rendered by the UI.
     */
    @MemoizeObservable()
    getConfigurationInfo$(): Observable<
        ConfigurationInfo<ExtendedUiOptionGroup> | undefined
    > {
        return this.mappedConfigurationInfo$;
    }

    /**
     * Subscribe to an observable chain that emits the currently valid
     * OptionGroups to be rendered by the UI.
     */
    @MemoizeObservable()
    getOptionGroups$(): Observable<ExtendedUiOptionGroup[] | undefined> {
        return this.mappedConfigurationInfo$.pipe(
            map((configurationInfo) => configurationInfo?.configuration)
        );
    }

    /**
     * Subscribe to an observable chain that emits the currently valid
     * OptionGroups filtered by their selected state.
     */
    @MemoizeObservable()
    getOptionGroupsSelectedItemsOnly$(): Observable<
        ExtendedUiOptionGroup[] | undefined
    > {
        return this.mappedOptionGroupsSelectedItemsOnly$.pipe(
            map((configurationInfo) => configurationInfo?.configuration)
        );
    }

    /**
     * Returns the currently selected configuration-options.
     */
    @MemoizeObservable()
    getActiveConfigurationOptions$(): Observable<
        (string | ConfigOption)[] | undefined
    > {
        return this.productConfigurationService
            .getProductState$()
            .pipe(
                map(
                    (productState) => productState && productState.configOptions
                )
            );
    }

    /**
     * Returns the currently active product-configuration.
     */
    @MemoizeObservable()
    getActiveConfiguration$(): Observable<ProductConfiguration | undefined> {
        return merge(
            // every time a product or the underlying country changes, emit an empty product state first to clean-up previous state
            combineLatest([
                this.getActiveProductId$(),
                this.l10nService.getActiveCountry$()
            ]).pipe(
                distinctUntilChanged(),
                map(() => undefined)
            ),
            this.productConfigurationService.getProductState$()
        ).pipe(
            distinctUntilChangedEquality(),
            map((productState) => {
                if (!productState?.id || !productState?.configOptions) {
                    return undefined;
                }

                return {
                    productId: productState.id,
                    country: productState.country,
                    configuration: productState.configOptions
                };
            }),
            lazyShareReplay()
        );
    }

    /**
     * Resets the current configuration session to the state where we started the configuration from.
     * This could be:
     * - configuration was started from scratch
     */
    resetConfiguration$(): Observable<void> {
        return combineLatest([
            this.getActiveConfiguration$(),
            this.l10nService.getActiveCountry$()
        ]).pipe(
            take(1),
            switchMap(([activeConfiguration, activeCountry]) => {
                if (!activeConfiguration) {
                    // no configuration active...
                    throw new IllegalStateError(
                        'Failed resetting configuration as no configuration is currently active.'
                    );
                }
                if (!activeCountry) {
                    throw new IllegalStateError(
                        'Failed resetting configuration as no country is currently active.'
                    );
                }

                gtmGA4Track('reset_configuration_click');
                // configuration was started from scratch, so just start with the products initial state
                return this.startSessionForProduct$(
                    {
                        productId: activeConfiguration.productId,
                        country: activeCountry
                    },
                    translate('CONFIGURATOR.RESET_CONFIG.DIALOG_HEADER'),
                    translate('CONFIGURATOR.RESET_CONFIG.DIALOG_MESSAGE')
                );
            }),
            catchError((error) =>
                error instanceof UserCancelledError
                    ? of(undefined)
                    : throwError(error)
            ),
            take(1)
        );
    }

    /**
     * Make a workflows execution dependent on the current configuations dirty state.
     * In case the current configuration is dirty, ask the user if he wants to discard
     * the current changes or if he wants to continue.
     * In case the user chooses to NOT discard the changes, a UserCancelledError is thrown.
     * @param continuationObservable$ The workflow / stream that gets executed in case the
     * active configuration isn't dirty or in case the user chooses to discard changes.
     * @param dialogHeaderText The text to be used for the confirmation dialog header in case current config is dirty.
     * @param dialogBodyText The text to be used for the confirmation dialog body in case current config is dirty.
     */
    intentDiscardConfiguration$<T>(
        continuationObservable$: Observable<T>,
        dialogHeaderText?: string,
        dialogBodyText?: string
    ): Observable<T> {
        return this.getActiveConfigurationDirtyState$().pipe(
            take(1),
            switchMap((isDirty) => {
                if (!isDirty) {
                    return continuationObservable$;
                }
                // in case configuration is dirty, ask the user what to do
                return this.commonDialogsService
                    .openConfirmDialog$(
                        dialogHeaderText ??
                            translate(
                                'CONFIGURATOR.DISCARD_CHANGES.DIALOG_HEADER'
                            ),
                        dialogBodyText ??
                            translate(
                                'CONFIGURATOR.DISCARD_CHANGES.DIALOG_MESSAGE'
                            ),
                        undefined,
                        {
                            showCancel: true
                        }
                    )
                    .pipe(
                        switchMap((result) => {
                            if (result.result === 'CANCEL') {
                                // user cancelled
                                throw new UserCancelledError('User cancelled');
                            }
                            // it's okay to discard, continue
                            result.closeDialog();
                            return continuationObservable$;
                        })
                    );
            })
        );
    }

    /**
     * Emits the dirty-state of the currently active configuration.
     */
    @MemoizeObservable()
    getActiveConfigurationDirtyState$(): Observable<boolean> {
        return combineLatest([
            // stream emitting either the persisted configuration or the products default configuration
            this.getSessionsReferenceConfiguration$(),
            this.getActiveConfiguration$()
        ]).pipe(
            map(
                ([sessionsReferenceConfiguration, currentConfiguration]) =>
                    !isEqual(
                        pick(
                            sessionsReferenceConfiguration,
                            'productId',
                            'configuration'
                        ),
                        pick(currentConfiguration, 'productId', 'configuration')
                    )
            ),
            // make this stream hot so that it always provides the current dirty-state value
            connectedPublishReplay(),
            lazyShareReplay()
        );
    }

    updateNodeSelectionMultiple(
        ids: string[],
        requiresResolve?: (id: string) => boolean,
        filterAdditionalIds?: (
            optionGroups: ExtendedUiOptionGroup[]
        ) => SelectionChangeInfo
    ): Observable<void> {
        const updateNodeSelection$ = this.waitForPendingResolve$().pipe(
            switchMap(() => this.mappedConfigurationInfoIncludingNonVisible$),
            take(1),
            map((configurationInfo): SelectionChangeInfo | undefined => {
                if (!configurationInfo) {
                    return undefined;
                }

                const selectionChangeInfo: SelectionChangeInfo = {
                    toDeselect: [],
                    toSelect: [],
                    requiresResolve,
                    filterAdditionalIds
                };

                for (const id of ids) {
                    const nodeInQuestion = this.nodeLookupService.findNodeById(
                        id,
                        configurationInfo.configuration
                    )?.node;

                    if (!nodeInQuestion) {
                        throw new IllegalStateError(
                            `Option ${id} could not be found.`
                        );
                    }

                    if (!isSelectionAware(nodeInQuestion)) {
                        throw new IllegalStateError(
                            `Node ${id} is not a selectable type.`
                        );
                    }

                    if (nodeInQuestion.selected) {
                        selectionChangeInfo.toDeselect?.push(id);
                    } else {
                        selectionChangeInfo.toSelect?.push(id);
                    }
                }

                if (
                    isEmpty(selectionChangeInfo.toSelect) &&
                    isEmpty(selectionChangeInfo.toDeselect)
                ) {
                    return undefined;
                }

                return selectionChangeInfo;
            }),
            switchMap((selectionChangeInfo) => {
                if (!selectionChangeInfo) {
                    // no input changes, no need to wait for anything
                    return of(undefined);
                }

                // emit the according selection-change and continue processing it
                this.selectionChangeSubject.next(selectionChangeInfo);

                // wait for either an error or a configuration-update to be emitted before emitting
                return race(
                    this.getConfigurationInfo$().pipe(skip(1)), // skip the currently active state and wait for an updated one
                    this.getOptionGroupsUpdateErrors$()
                ).pipe(map(() => undefined));
            }),
            take(1),
            shareReplay()
        );
        updateNodeSelection$.subscribe();
        return updateNodeSelection$;
    }

    /**
     * Handle the selection of a configuration-node.
     * @param id
     * @param requiresResolve
     */
    updateNodeSelection(id: string, requiresResolve?: (id: string) => boolean) {
        return this.updateNodeSelectionMultiple([id], requiresResolve);
    }

    /**
     * Gets an observable chain that emits errors that occur while
     * updating currently active set of OptionGroups.
     */
    getOptionGroupsUpdateErrors$(): Observable<CustomError> {
        return this.configurationSessionErrors$;
    }

    /**
     * Emits whether a selection change is currently active or not.
     */
    @MemoizeObservable()
    isSelectionChangeActive$() {
        return this.pendingResolveSubject.asObservable();
    }

    /**
     * Update the configuration for the currently loaded product (if any).
     *
     * @param productId
     * @param country
     * @param productConfigurationOptions The productConfigurationOptions to be promoted to the engine.
     */
    private updateConfigurationInternal$(
        productId: string,
        country: string,
        productConfigurationOptions: ProductConfigurationOptions
    ) {
        return this.engineControlService
            .setProductConfiguration$({
                productId,
                country,
                options: productConfigurationOptions
            })
            .pipe(map(() => undefined));
    }

    private mapToSelectedConfigurationOptions(options: OptionGroup[]) {
        return deepSortObject(
            this.configurationConverterService.convertToConfigurationFormat(
                options,
                ConfigurationConverterMode.SELECTED_ONLY
            )
        );
    }

    /**
     * Setup the logic required to determine the current state of valid configuration-meta which gets communicated under the hood to the engine
     * and mapped to a usable format within the context of the UI.
     */
    private initConfigurationSessionLogic(): Observable<
        ConfigurationInfo<ExtendedUiOptionGroup> | undefined
    > {
        /*
         * This is a stream emitting the current locally adjusted configuration-meta.
         */
        const localConfigurationInfoSubject = new ReplaySubject<{
            configurationChangeInfo?: {
                selected?: ExtendedUiOptionCode[];
                deselected?: ExtendedUiOptionCode[];
                selectedConfigurationOptions?: ProductConfigurationOptions;
                deselectedConfigurationOptions?: ProductConfigurationOptions;
            };
            configurationOptions: ProductConfigurationOptions | undefined;
        }>(1);

        /*
         * When the active configuration metadata is changed, either locally or from engine-state, check if it has changed and tell the engine about an updated configuration.
         */
        const updatedConfigurationSource$: Observable<{
            source: 'EXTERNAL' | 'INTERNAL';
            productConfigurationOptions:
                | ProductConfigurationOptions
                | undefined;
            configurationChangeInfo?: {
                selected?: ExtendedUiOptionCode[];
                deselected?: ExtendedUiOptionCode[];
                selectedConfigurationOptions?: ProductConfigurationOptions;
                deselectedConfigurationOptions?: ProductConfigurationOptions;
            };
        }> = merge(
            // take the configuration in the application state into account (either updated from the engine or from ourselves when in 2D)
            this.getActiveConfiguration$().pipe(
                map((productConfiguration) => ({
                    source: <'EXTERNAL' | 'INTERNAL'>'EXTERNAL',
                    productConfigurationOptions:
                        productConfiguration?.configuration
                }))
            ),
            // take the changed local configuration into account (e.g. when a user adjusts the selection)
            localConfigurationInfoSubject.asObservable().pipe(
                map((localConfigurationInfo) => ({
                    source: <'EXTERNAL' | 'INTERNAL'>'INTERNAL',
                    productConfigurationOptions:
                        localConfigurationInfo.configurationOptions,
                    configurationChangeInfo:
                        localConfigurationInfo.configurationChangeInfo
                }))
            )
        ).pipe(
            distinctUntilChanged((a, b) =>
                isEqual(
                    a?.productConfigurationOptions,
                    b?.productConfigurationOptions
                )
            ),
            lazyShareReplay()
        );

        /*
         * Holds the ProductConfigurationOptions that were resolved last
         */
        const lastResolvedRulerInputSubject = new BehaviorSubject<
            RulerInput | undefined
        >(undefined);

        /*
         * create stream with configuration-metadata updates resulting from changes in the updated configuration source
         */
        const activeConfigurationInfo$: Observable<
            | (ConfigurationInfo<ExtendedUiOptionGroup> & {
                  source: 'EXTERNAL' | 'INTERNAL';
              })
            | undefined
        > = merge(
            // recalculate resulting ui-metadata based on changed config-input
            combineLatest([
                // let every configuration-source change trigger a relevant change
                updatedConfigurationSource$,
                // collect all other relevant input-changes debounced before proceeding
                combineLatest([
                    this.getActiveProductId$(),
                    this.getActiveModelId$(),
                    this.getActiveConfiguration$().pipe(
                        map(
                            (activeConfiguration) =>
                                activeConfiguration?.country
                        ),
                        distinctUntilChanged()
                    )
                ]).pipe(debounceTime(0))
            ]).pipe(
                map(
                    ([
                        updatedConfiguration,
                        [activeProductId, activeModelId, activeCountry]
                    ]): RulerInput | undefined => {
                        if (
                            !updatedConfiguration.productConfigurationOptions ||
                            !activeProductId ||
                            !activeModelId ||
                            !activeCountry
                        ) {
                            lastResolvedRulerInputSubject.next(undefined);
                            return undefined;
                        }

                        return {
                            productId: activeProductId,
                            modelId: activeModelId,
                            country: activeCountry,
                            configuration:
                                updatedConfiguration.productConfigurationOptions,
                            configurationChangeInfo:
                                updatedConfiguration.configurationChangeInfo,
                            isSessionRestartIntent: false,
                            source: updatedConfiguration.source
                        };
                    }
                ),
                // check if we already resolved the configuration that is emitted and if we did, do not resolve again
                withLatestFrom(lastResolvedRulerInputSubject),
                filter(([rulerInput, lastResolvedRulerInput]) => {
                    const normalizedRulerInput = deepSortObject(
                        omit(rulerInput, 'source', 'configurationChangeInfo')
                    );
                    const normalizedLastResolvedRulerInput = deepSortObject(
                        omit(
                            lastResolvedRulerInput,
                            'source',
                            'configurationChangeInfo'
                        )
                    );
                    const requiresResolve =
                        // in case there is no current rulerInput, we still continue processing as this might result in a cleared state (product-id / country did change, currently set ruler-output is no longer valid
                        !rulerInput ||
                        // if the current ruler-input equals the previous one, we don't need to hit the ruler again
                        !isEqual(
                            normalizedRulerInput,
                            normalizedLastResolvedRulerInput
                        ) ||
                        // configurationChangeInfo is required to ask the ruler for changed options
                        !!rulerInput.configurationChangeInfo;

                    return requiresResolve;
                }),
                // remove the lastResolvedRulerInput required for the check above
                map(([rulerInput]) => rulerInput),
                // notify starting resolve
                this.startResolve(),
                switchMap((rulerInput) =>
                    // take the cpq-session-handler-service into account
                    this.cpqSessionHandlerService
                        .getSessionRestartIntent$()
                        .pipe(
                            map(() => true),
                            startWith(false),
                            map((isSessionRestartIntent) => {
                                if (!rulerInput) {
                                    return undefined;
                                }
                                return {
                                    ...rulerInput,
                                    isSessionRestartIntent
                                };
                            })
                        )
                ),
                // configuration options did change, perform a fresh resolve against the ruler
                switchMap((rulerInput) => {
                    if (!rulerInput) {
                        return of(undefined);
                    }

                    let projectParam: AmlProjectParam | undefined;

                    /*
                     * Check if it's a session-restart intent. If so, we do not need
                     * to include the recently changed options but only need to
                     * re-send the current configuration to obtain a new session.
                     */
                    if (!rulerInput.isSessionRestartIntent) {
                        const firstSelectedOption = arrayFirst(
                            rulerInput.configurationChangeInfo
                                ?.selectedConfigurationOptions
                        );
                        const firstDeselectedOption = arrayFirst(
                            rulerInput.configurationChangeInfo
                                ?.deselectedConfigurationOptions
                        );

                        if (firstSelectedOption) {
                            projectParam = {
                                changedOption: {
                                    optionCode: firstSelectedOption,
                                    selectedState: SelectedState.SELECTED
                                }
                            };
                        } else if (firstDeselectedOption) {
                            projectParam = {
                                changedOption: {
                                    optionCode: firstDeselectedOption,
                                    selectedState: SelectedState.DESELECTED
                                }
                            };
                        }
                    } else {
                        // reset the session before resolving the current config
                        this.cpqSessionHandlerService.resetConfigurationSession();
                    }

                    // ask ruler to get the metadata for the updated configuration options
                    return this.getConfigurationMetadata$(
                        rulerInput,
                        projectParam
                    ).pipe(
                        tap((configurationMetadata) => {
                            // safe the updated configuration options for later checks
                            lastResolvedRulerInputSubject.next({
                                ...rulerInput,
                                configuration:
                                    this.mapToSelectedConfigurationOptions(
                                        configurationMetadata.configuration
                                    )
                            });
                        }),
                        map((configurationInfo) => ({
                            ...configurationInfo,
                            source: rulerInput.source
                        })),
                        // notify finish resolve
                        this.finishResolve(),
                        catchError((error) => {
                            this.configurationSessionErrors$.next(error);
                            return EMPTY;
                        })
                    );
                })
            )
        ).pipe(lazyShareReplay());

        /*
         * The currently valid configuration state has to be promoted to the engine, when a broadcast is required based on the trigger-observable.
         */
        if (!this.config?.skipPromoteConfigurationChangesToEngine) {
            this.initBroadcastUpdatedConfigurationLogic(
                activeConfigurationInfo$.pipe(
                    map(
                        (
                            activeConfigurationInfo
                        ): ProductConfiguration | undefined => {
                            if (
                                !activeConfigurationInfo?.configuration ||
                                // if the change is coming from external, do not broadcast it again
                                activeConfigurationInfo?.source === 'EXTERNAL'
                            ) {
                                return undefined;
                            }

                            return {
                                productId: activeConfigurationInfo.productId,
                                configuration:
                                    this.mapToSelectedConfigurationOptions(
                                        activeConfigurationInfo.configuration
                                    ),
                                country: activeConfigurationInfo.country
                            };
                        }
                    ),
                    distinctUntilChangedEquality(),
                    // do only broadcast changes in case the currently active configuration diverges from the updated configuration
                    withLatestFrom(this.getActiveConfiguration$()),
                    filter(
                        ([
                            toBeBroadcastedConfiguration,
                            currentlyActiveConfiguration
                        ]) =>
                            !isEqual(
                                toBeBroadcastedConfiguration,
                                currentlyActiveConfiguration
                            )
                    ),
                    // map back to current configuration
                    map(
                        ([toBeBroadcastedConfiguration]) =>
                            toBeBroadcastedConfiguration
                    )
                )
            );
        }

        // apply pricing-enhancement to the configuration-meta that is currently valid
        const activePricingEnhancedConfigurationInfo$ =
            this.applyPricingDataEnhancement(activeConfigurationInfo$);

        // on selection change, get the currently valid configuration-metadata and apply selection-logic to it
        this.selectionChangeSubject
            .pipe(
                withLatestFrom(
                    activePricingEnhancedConfigurationInfo$,
                    this.getActiveModelId$(),
                    this.l10nService.getActiveCountry$()
                )
            )
            .subscribe(
                async ([
                    selectionChangeInfo,
                    activeConfigurationInfo,
                    activeModelId,
                    activeCountry
                ]) => {
                    if (!activeConfigurationInfo?.configuration) {
                        throw new IllegalStateError(
                            'No active OptionGroups available.'
                        );
                    }
                    if (!activeConfigurationInfo?.productId) {
                        throw new IllegalStateError(
                            'No active productId available.'
                        );
                    }
                    if (!activeModelId) {
                        throw new IllegalStateError(
                            'No active modelId available.'
                        );
                    }
                    if (!activeCountry) {
                        throw new IllegalStateError(
                            'No active country available.'
                        );
                    }
                    try {
                        const updatedConfiguration =
                            await this.nodeSelectionHandler.handleNodeSelection(
                                selectionChangeInfo,
                                activeConfigurationInfo.configuration,
                                activeModelId,
                                activeConfigurationInfo.productId,
                                activeCountry
                            );

                        const selectedNodes = selectionChangeInfo.toSelect
                            ?.map((nodeId) =>
                                this.nodeLookupService.findNodeById(
                                    nodeId,
                                    activeConfigurationInfo.configuration
                                )
                            )
                            .filter<NodeWithParent<ExtendedUiOptionCode>>(
                                (
                                    node
                                ): node is NodeWithParent<ExtendedUiOptionCode> =>
                                    !!node && isExtendedUiOptionCode(node.node)
                            )
                            // only track as selected if the selection-intent actually resulted in the selection of the node
                            .filter((node) =>
                                updatedConfiguration.find((configModel) =>
                                    isEqual(configModel, node.node.configModel)
                                )
                            );
                        const deselectedNodes = selectionChangeInfo.toDeselect
                            ?.map((nodeId) =>
                                this.nodeLookupService.findNodeById(
                                    nodeId,
                                    activeConfigurationInfo.configuration
                                )
                            )
                            .filter<NodeWithParent<ExtendedUiOptionCode>>(
                                (
                                    node
                                ): node is NodeWithParent<ExtendedUiOptionCode> =>
                                    !!node && isExtendedUiOptionCode(node.node)
                            )
                            // only track as deselected if the deselection-intent actually resulted in the deselection of the node
                            .filter(
                                (node) =>
                                    !updatedConfiguration.find((configModel) =>
                                        isEqual(
                                            configModel,
                                            node.node.configModel
                                        )
                                    )
                            );

                        const selectedConfigurationOptions = selectedNodes?.map(
                            (selectedNode) => {
                                const reducedStructure =
                                    this.configurationFilterService.filterOptionGroupsUsingCallback(
                                        activeConfigurationInfo.configuration,
                                        (optionNode) =>
                                            optionNode.id ===
                                            selectedNode.node.id
                                    );
                                return this.configurationConverterService.convertToConfigurationFormat(
                                    reducedStructure,
                                    ConfigurationConverterMode.COMPLETE
                                )[0];
                            }
                        );
                        const deselectedConfigurationOptions =
                            deselectedNodes?.map((deselectedNode) => {
                                const reducedStructure =
                                    this.configurationFilterService.filterOptionGroupsUsingCallback(
                                        activeConfigurationInfo.configuration,
                                        (optionNode) =>
                                            optionNode.id ===
                                            deselectedNode.node.id
                                    );
                                return this.configurationConverterService.convertToConfigurationFormat(
                                    reducedStructure,
                                    ConfigurationConverterMode.COMPLETE
                                )[0];
                            });

                        selectedNodes?.forEach((toSelectNode) => {
                            if (!toSelectNode) {
                                return;
                            }

                            gtmGA4Track('variant_option_click', {
                                variant_option: toSelectNode.node.name
                            });
                            gtmGA4Track('variant_option_click_sequence');
                        });

                        localConfigurationInfoSubject.next({
                            configurationOptions: updatedConfiguration,
                            configurationChangeInfo: {
                                selected: selectedNodes?.map(
                                    (node) => node?.node
                                ),
                                deselected: deselectedNodes?.map(
                                    (node) => node?.node
                                ),
                                selectedConfigurationOptions,
                                deselectedConfigurationOptions
                            }
                        });
                    } catch (error) {
                        if (error instanceof UserCancelledError) {
                            // nothing to do
                        } else {
                            this.configurationSessionErrors$.next(error);
                        }
                    }
                }
            );

        return activePricingEnhancedConfigurationInfo$;
    }

    private waitForPendingResolve$(): Observable<void> {
        return this.pendingResolveSubject.pipe(
            filter((resolvePending) => !resolvePending),
            take(1),
            map(() => undefined)
        );
    }

    private waitForPendingResolve<T>(): MonoTypeOperatorFunction<T> {
        return (source) =>
            source.pipe(
                switchMap((value: T) =>
                    this.pendingResolveSubject.pipe(
                        filter((resolvePending) => !resolvePending),
                        map(() => value),
                        take(1)
                    )
                )
            );
    }

    private startResolve<T>(): MonoTypeOperatorFunction<T> {
        return (source) =>
            source.pipe(tap(() => this.pendingResolveSubject.next(true)));
    }

    private finishResolveSync(): void {
        if (this.pendingResolveSubject.value) {
            this.pendingResolveSubject.next(false);
        }
    }

    private finishResolve<T>(): MonoTypeOperatorFunction<T> {
        return (source) =>
            source.pipe(
                tap({
                    next: () =>
                        setTimeout(
                            () =>
                                this.pendingResolveSubject.value
                                    ? this.pendingResolveSubject.next(false)
                                    : undefined,
                            0
                        ),
                    error: () =>
                        setTimeout(
                            () =>
                                this.pendingResolveSubject.value
                                    ? this.pendingResolveSubject.next(false)
                                    : undefined,
                            0
                        )
                })
            );
    }

    private validateAndPopulateSessionData$(
        sessionData: PartialProductConfigurationSessionData,
        options = {
            // if the environment-state check (force day/night state + light-options) should be skipped
            skipEnvironmentStateCheck: false
        }
    ): Observable<ProductConfigurationSessionData> {
        return forkJoin({
            products: this.productDataService
                .getAvailableProducts$()
                .pipe(take(1)),
            productInfo: this.productDataService
                .getProductInfo$(sessionData.productId)
                .pipe(take(1)),
            environments: this.productDataService
                .getAvailableEnvironmentsForProduct$(sessionData.productId)
                .pipe(take(1)),
            defaultEnvironment: this.productDataService
                .getDefaultEnvironment$(sessionData.productId)
                .pipe(take(1)),
            cameras: this.productDataService
                .getAvailableCamerasForProduct$(sessionData.productId, true)
                .pipe(take(1)),
            animations: this.productDataService
                .getAvailableAnimationsForProduct$(sessionData.productId)
                .pipe(take(1)),
            currentConfigurationState: this.applicationStateService
                .getState()
                .pipe(selectConfigurationState, take(1))
        }).pipe(
            this.errorHandlerService.applyRetry(),
            map(
                ({
                    products,
                    productInfo,
                    environments,
                    defaultEnvironment,
                    cameras,
                    animations,
                    currentConfigurationState
                }): PartialProductConfigurationSessionData & {
                    modelId: string;
                } => {
                    const targetProductId = sessionData.productId;

                    const targetConfiguration = sessionData.configuration;
                    const targetCamera = sessionData.camera;
                    const targetEnvironment = sessionData.environmentState?.id;

                    const failoverCamera = cameras?.defaultExt;
                    const failoverEnvironment = defaultEnvironment?.id ?? arrayFirst(environments)?.id;

                    if (!productInfo) {
                        throw new IllegalStateError(
                            `No product information available for product ${sessionData.productId}`
                        );
                    }
                    if (!failoverCamera) {
                        throw new IllegalStateError(
                            'No default camera available'
                        );
                    }
                    if (!failoverEnvironment) {
                        throw new IllegalStateError(
                            'No default environment available'
                        );
                    }

                    let targetConfigurationChecked = targetConfiguration ?? [];
                    let targetCameraChecked = failoverCamera;
                    let targetEnvironmentChecked = failoverEnvironment;

                    // check if product exists
                    const matchingProduct = products.find(
                        (productId) => productId === targetProductId
                    );
                    if (!matchingProduct) {
                        throw new InvalidProductError(
                            `Product ${targetProductId} does not exist.`,
                            {
                                invalidProduct: targetProductId,
                                availableProducts: products
                            }
                        );
                    }

                    // check if we need to use a default configuration
                    // did the country change in isolation? (configuration stays the same but country is different)
                    const countryChangedInIsolation =
                        sessionData.country !==
                            currentConfigurationState?.productState?.country &&
                        isEqual(
                            sessionData.configuration,
                            currentConfigurationState?.productState
                                ?.configOptions
                        );
                    if (
                        productInfo &&
                        (!targetConfiguration || countryChangedInIsolation)
                    ) {
                        // no config set or country changed in isolation -> use defaultConfig defined for product
                        targetConfigurationChecked = productInfo.defaultConfig;
                    }

                    // check if environment exists
                    if (targetEnvironment) {
                        const matchingEnvironment = environments?.find(
                            (env) => env.id === targetEnvironment
                        );
                        if (!matchingEnvironment) {
                            this.configurationSessionErrors$.next(
                                new InvalidEnvironmentError(
                                    `Environment ${targetEnvironment} could not be found. Defaulting to ${failoverEnvironment}`,
                                    {
                                        invalidEnvironment: targetEnvironment,
                                        failoverEnvironment
                                    }
                                )
                            );
                            targetEnvironmentChecked = failoverEnvironment;
                        } else {
                            targetEnvironmentChecked = matchingEnvironment.id;
                        }
                    }

                    // check if camera exists and is valid for target environment
                    // find valid cameras for target environment
                    const validCamerasForEnvironment = cameras?.cameras.filter(
                        (camera) =>
                            !camera.meta?.relatedEnvironments ||
                            !!camera.meta.relatedEnvironments.find(
                                (relatedEnvironmentId) =>
                                    relatedEnvironmentId ===
                                    targetEnvironmentChecked
                            )
                    );

                    if (isEmpty(validCamerasForEnvironment)) {
                        throw new IllegalStateError(
                            `No valid cameras found for environment ${targetEnvironmentChecked}. Check the configured product data.`
                        );
                    }

                    if (targetCamera) {
                        const matchingCamera = validCamerasForEnvironment?.find(
                            (camera) => camera.id === targetCamera
                        );
                        if (!matchingCamera) {
                            targetCameraChecked = failoverCamera;
                        } else {
                            targetCameraChecked = targetCamera;
                        }
                    }

                    // see if targetCameraChecked is valid for target environment and default to first camera if it's not
                    targetCameraChecked =
                        validCamerasForEnvironment?.find(
                            (camera) => camera.id === targetCameraChecked
                        )?.id ?? validCamerasForEnvironment[0].id;

                    return {
                        ...sessionData,
                        modelId: productInfo.modelId,
                        configuration: isEmpty(targetConfigurationChecked)
                            ? undefined
                            : targetConfigurationChecked,
                        camera: targetCameraChecked,
                        environmentState: {
                            id: targetEnvironmentChecked,
                            state: sessionData.environmentState?.state
                        }
                    };
                }
            ),
            withLatestFrom(
                this.applicationStateService
                    .getState()
                    .pipe(selectConfigurationState)
            ),
            // possibly adjust environment-state and light data
            map(
                ([validatedSessionData, currentConfigurationState]): [
                    ProductConfigurationSessionData & { modelId: string },
                    ConfigurationState | undefined
                ] => {
                    if (
                        options.skipEnvironmentStateCheck ||
                        !validatedSessionData?.environmentState
                    ) {
                        return [
                            {
                                ...validatedSessionData,
                                environmentState:
                                    validatedSessionData?.environmentState
                                        ? {
                                              ...validatedSessionData?.environmentState,
                                              state:
                                                  validatedSessionData
                                                      ?.environmentState
                                                      ?.state ??
                                                  EnvironmentLightingProfileState.DAY
                                          }
                                        : undefined
                            },
                            currentConfigurationState
                        ];
                    }

                    let targetEnvironmentStateChecked =
                        sessionData.environmentState?.state ??
                        EnvironmentLightingProfileState.DAY;
                    if (
                        environment.appConfig.configuration.nightModeDisabledForEnvironments?.includes(
                            validatedSessionData.environmentState?.id
                        )
                    ) {
                        targetEnvironmentStateChecked =
                            EnvironmentLightingProfileState.DAY;
                    } else if (
                        // either have to force night mode for env
                        environment.appConfig.configuration.dayModeDisabledForEnvironments?.includes(
                            validatedSessionData.environmentState?.id
                        ) ||
                        // or have to default to night mode for env in case no explicit lighting state is set
                        (environment.appConfig.configuration.nightModeDefaultForEnvironments?.includes(
                            validatedSessionData.environmentState?.id
                        ) &&
                            !sessionData.environmentState?.state)
                    ) {
                        targetEnvironmentStateChecked =
                            EnvironmentLightingProfileState.NIGHT;
                    }

                    // now apply matching lights-state to match environment-state
                    // remove both light-codes first
                    let targetConfigurationChecked = (
                        validatedSessionData.configuration ?? []
                    ).filter(
                        (configCode) =>
                            configCode !== CODE_LIGHTS_ON &&
                            configCode !== CODE_LIGHTS_OFF
                    );
                    // re-add light-code related to environment-state
                    targetConfigurationChecked = [
                        ...targetConfigurationChecked,
                        targetEnvironmentStateChecked ===
                        EnvironmentLightingProfileState.DAY
                            ? CODE_LIGHTS_OFF
                            : CODE_LIGHTS_ON
                    ];

                    return [
                        {
                            ...validatedSessionData,
                            environmentState: {
                                ...validatedSessionData.environmentState,
                                state: targetEnvironmentStateChecked
                            },
                            configuration: isEmpty(targetConfigurationChecked)
                                ? undefined
                                : targetConfigurationChecked
                        },
                        currentConfigurationState
                    ];
                }
            ),
            // ask ruler to complete the given configuration
            switchMap(([validatedSessionData, currentConfigurationState]) => {
                const productIdChanged =
                    validatedSessionData.productId !==
                    currentConfigurationState?.productState?.id;
                const countryChanged =
                    validatedSessionData.country !==
                    currentConfigurationState?.productState?.country;
                const nextConfigurationOptions = deepSortObject(
                    validatedSessionData.configuration
                );
                const currentConfigurationOptions = deepSortObject(
                    currentConfigurationState?.productState?.configOptions
                );

                if (
                    productIdChanged ||
                    countryChanged ||
                    !isEqual(
                        nextConfigurationOptions,
                        currentConfigurationOptions
                    )
                ) {
                    // configuration changed, let the ruler resolve it

                    // determin selected or deselected options
                    const selectedOptions =
                        nextConfigurationOptions?.filter(
                            (option) =>
                                !currentConfigurationOptions?.find(
                                    (otherOption) =>
                                        isEqual(option, otherOption)
                                )
                        ) ?? [];
                    const deselectedOptions =
                        currentConfigurationOptions?.filter(
                            (option) =>
                                !nextConfigurationOptions?.find((otherOption) =>
                                    isEqual(option, otherOption)
                                )
                        ) ?? [];

                    /* in case productId or country did change or there has been more than one option changed,
                     * reset the CPQ-session before proceeding
                     */
                    const needsCpqConfigurationSessionReset =
                        productIdChanged ||
                        countryChanged ||
                        selectedOptions.length > 1 ||
                        deselectedOptions.length > 1;

                    if (needsCpqConfigurationSessionReset) {
                        this.cpqSessionHandlerService.resetConfigurationSession();
                    }

                    const firstAddedOption = selectedOptions[0];
                    const firstRemovedOption = deselectedOptions[0];
                    // we need to supply project parameters only in case the session has not been fully reset
                    const projectParameters =
                        !needsCpqConfigurationSessionReset &&
                        (firstAddedOption || firstRemovedOption)
                            ? {
                                  changedOption: {
                                      optionCode:
                                          firstAddedOption ??
                                          firstRemovedOption,
                                      selectedState: firstAddedOption
                                          ? SelectedState.SELECTED
                                          : SelectedState.DESELECTED
                                  }
                              }
                            : undefined;

                    return this.getConfigurationMetadata$(
                        validatedSessionData,
                        projectParameters
                    ).pipe(
                        take(1),
                        map((configurationMetadata) => ({
                            ...validatedSessionData,
                            configuration:
                                this.mapToSelectedConfigurationOptions(
                                    configurationMetadata.configuration
                                )
                        }))
                    );
                }

                return of(validatedSessionData);
            })
        );
    }

    /**
     * Based on the given ProductConfigurationSessionData, fetch and map the according
     * ConfigurationMetaData.
     * @param sessionData The session data to fetch the ConfigurationMetaData for.
     * @param projectParameters The relevant AmlProjectParam data to be included.
     */
    private getConfigurationMetadata$(
        sessionData: Pick<
            ProductConfigurationSessionData,
            'productId' | 'configuration' | 'country'
        > & {
            modelId: string;
        },
        projectParameters?: AmlProjectParam
    ) {
        return defer(() =>
            this.productConfigurationService
                .getConfigurationInfo$(
                    sessionData.productId,
                    sessionData.configuration,
                    projectParameters,
                    sessionData.country
                )
                .pipe(
                    take(1),
                    this.errorHandlerService.applyRetry(),
                    switchMap((configurationInfo) =>
                        this.optionGroupMapperService
                            .mapOptionGroups$(
                                configurationInfo,
                                sessionData.modelId
                            )
                            .pipe(
                                map((mappedOptionGroups) => ({
                                    ...configurationInfo,
                                    configuration: mappedOptionGroups
                                }))
                            )
                    )
                )
        );
    }

    /**
     * Gets the currently active modelId.
     */
    @MemoizeObservable()
    private getActiveModelId$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectActiveModelId);
    }

    /**
     * Delegates to ProductConfigurationService#getActiveProductId$()
     */
    private getActiveProductId$() {
        return this.productConfigurationService.getActiveProductId$();
    }

    private getProductDefaultConfig$(productId: string) {
        return this.productDataService
            .getProductInfo$(productId)
            .pipe(map((productInfo) => productInfo?.defaultConfig));
    }

    /**
     * In case a local change for the configuration gets emitted, broadcast the resulting updated configuration.
     */
    private initBroadcastUpdatedConfigurationLogic(
        updatedProductConfigurationOptions$: Observable<
            ProductConfiguration | undefined
        >
    ) {
        updatedProductConfigurationOptions$
            .pipe(
                switchMap((updatedProductConfiguration) => {
                    if (
                        !updatedProductConfiguration ||
                        !updatedProductConfiguration.country
                    ) {
                        return EMPTY;
                    }
                    return this.updateConfigurationInternal$(
                        updatedProductConfiguration.productId,
                        updatedProductConfiguration.country,
                        updatedProductConfiguration.configuration
                    ).pipe(
                        this.errorHandlerService.applyRetry({
                            errorCallback: (error) =>
                                this.configurationSessionErrors$.next(
                                    error as CustomError
                                )
                        }),
                        catchError((error) => EMPTY)
                    );
                })
            )
            .subscribe();
    }

    /**
     * Based on the currently active set of ExtendedUiOptionGroups we determine the
     * required pricing information and enhance the ExtendedUiOptionGroups with it.
     * @param activeConfigurationInfo$
     */
    private applyPricingDataEnhancement(
        activeConfigurationInfo$: Observable<
            ConfigurationInfo<ExtendedUiOptionGroup> | undefined
        >
    ): Observable<ConfigurationInfo<ExtendedUiOptionGroup> | undefined> {
        return combineLatest([
            activeConfigurationInfo$,
            this.applicationStateService
                .getLocalState()
                .pipe(selectPricingType),
            this.pricingService.getAlternatePricingData$().pipe(
                map((pricingData) => pricingData?.optionPrices),
                distinctUntilChanged(isEqual)
            )
        ]).pipe(
            map(([configurationInfo, pricingType, alternatePricingData]) => {
                if (!configurationInfo) {
                    return undefined;
                }

                configurationInfo.configuration.forEach((group) => {
                    this.mapPricingRecurse(
                        group,
                        pricingType,
                        alternatePricingData
                    );
                });

                return configurationInfo;
            }),
            lazyShareReplay()
        );
    }

    private initOptionGroupsVisibleUiItemsOnly(
        mappedConfigurationInfo$: Observable<
            ConfigurationInfo<ExtendedUiOptionGroup> | undefined
        >
    ) {
        return mappedConfigurationInfo$.pipe(
            map((configurationInfo) => {
                if (!configurationInfo) {
                    return undefined;
                }

                // remove non-ui relevant option groups based on the visibleUI property
                return {
                    ...configurationInfo,
                    configuration: configurationInfo.configuration.filter(
                        (optionGroup) =>
                            isNil(optionGroup.visibleUI) ||
                            optionGroup.visibleUI
                    )
                };
            })
        );
    }

    /**
     * Provides a stream emitting the selected items of the currently active
     * configuration-metadata only.
     * @param mappedConfigurationInfo
     */
    private initOptionGroupsSelectedItemsOnly(
        mappedConfigurationInfo: Observable<
            ConfigurationInfo<ExtendedUiOptionGroup> | undefined
        >
    ) {
        const configurationFilter =
            new ConfigurationFilter<ExtendedUiOptionGroup>();
        return mappedConfigurationInfo.pipe(
            map((configurationInfo) => {
                if (!configurationInfo) {
                    return undefined;
                }

                return {
                    ...configurationInfo,
                    configuration: configurationFilter.keepSelectedOnly(
                        configurationInfo.configuration
                    )
                };
            }),
            lazyShareReplay()
        );
    }

    /**
     * Returns an Observable emitting the configuration with which the session was started.
     * This may be either the products default configuration, a persisted configuration loaded
     * by its code or none at all.
     */
    private getSessionsReferenceConfiguration$(): Observable<
        ProductConfiguration | undefined
    > {
        return this.getActiveProductId$().pipe(
            switchMap((activeProductId) => {
                if (!activeProductId) {
                    return of(undefined);
                }
                return this.getProductDefaultConfig$(activeProductId).pipe(
                    this.errorHandlerService.applyRetry(),
                    catchError(() => of(undefined)),
                    map((defaultConfig) => {
                        if (!defaultConfig) {
                            return undefined;
                        }

                        return {
                            productId: activeProductId,
                            configuration: defaultConfig
                        };
                    })
                );
            })
        );
    }

    private mapPricingRecurse(
        contentAware: ExtendedUiContentAwareConfigurationMetaItem,
        pricingType: PricingType,
        alternatePricingSource?: PricingMap
    ) {
        contentAware.content?.forEach((currentChild) => {
            if (isContentAware(currentChild)) {
                this.mapPricingRecurse(
                    currentChild,
                    pricingType,
                    alternatePricingSource
                );
            }
            if (isExtendedUiOptionCode(currentChild)) {
                let effectivePricingFromOptionCode: number | undefined;

                if (pricingType === 'DNP') {
                    effectivePricingFromOptionCode =
                        currentChild.pricing?.wholeSalePrice;
                } else if (pricingType === 'RRP') {
                    effectivePricingFromOptionCode =
                        currentChild.pricing?.retailPrice;
                }

                const pricingFromAlternateSource =
                    alternatePricingSource?.[currentChild.id];

                if (isNil(pricingFromAlternateSource)) {
                    if (!isNil(currentChild.pricing)) {
                        currentChild.pricing.price =
                            effectivePricingFromOptionCode;
                    }
                } else {
                    currentChild.pricing = pricingFromAlternateSource;
                }
            }
        });
    }

    /**
     * When the active product-id changes, update the
     * corresponding model-id in application-state.
     * @private
     */
    private initUpdateModelIdFromProductIdLogic() {
        return this.getActiveProductId$()
            .pipe(
                switchMap((productId) => {
                    if (!productId) {
                        return of(undefined);
                    }
                    const modelIdResolve$ = this.productDataService
                        .getProductInfo$(productId)
                        .pipe(
                            this.errorHandlerService.applyRetry({
                                maxRetries: 10
                            }),
                            catchError((error) => {
                                this.configurationSessionErrors$.next(error);
                                return of(undefined);
                            }),
                            map((productInfo) => productInfo?.modelId)
                        );
                    return merge(of(undefined), modelIdResolve$);
                }),
                distinctUntilChanged()
            )
            .subscribe((modelId) => {
                this.applicationStateService.dispatch(
                    setActiveModelId({
                        modelId
                    })
                );
            });
    }
}
