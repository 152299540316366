import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { UiBaseComponent } from '@mhp/ui-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AmlProductDataService } from '../aml-product-data-service';

/**
 * Allows to hide user-interface controls based on the Product#isAuthorizedOnly flag.
 * Default behavior without any parametrization is to hide a view when the
 * active derivative is for authorized access only.
 */
@UntilDestroy()
@Directive({
    selector: '[mhpHideForSecretDerivative]'
})
export class HideForSecretDerivativeDirective
    extends UiBaseComponent
    implements OnInit
{
    @Input()
    mhpHideForSecretDerivative: boolean | undefined = true;

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly productDataService: AmlProductDataService
    ) {
        super();
    }

    ngOnInit() {
        this.initBindToActiveDerivative();
    }

    private initBindToActiveDerivative() {
        combineLatest([
            this.productDataService.getActiveProductInfo$().pipe(
                map((productInfo) => !!productInfo?.isAuthorizedOnly),
                distinctUntilChanged()
            ),
            this.observeProperty<
                HideForSecretDerivativeDirective,
                boolean | undefined
            >('mhpHideForSecretDerivative', true)
        ])
            .pipe(
                switchMap(
                    ([isAuthorizedOnly, doHideForSecretDerivatives]) =>
                        new Observable((subscriber) => {
                            if (
                                !doHideForSecretDerivatives ||
                                !isAuthorizedOnly
                            ) {
                                this.viewContainer.createEmbeddedView(
                                    this.templateRef
                                );
                            }
                            subscriber.next();

                            return () => {
                                this.viewContainer.clear();
                            };
                        })
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
