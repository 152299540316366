import { range, values } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { first, map, startWith, switchMap } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Dealer } from '@mhp/aml-shared/data-proxy/dealer.interface';
import { ExtendedDealer } from '@mhp/aml-ui-shared-services';
import { IllegalStateError, MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { I18nService, L10nService } from '@mhp/ui-shared-services';

import { environment } from '../../../../environments/environment';
import { getTranslatedTitlesList } from '../../../common/forms/form-constants';
import { EMAIL_REGEX } from '../../../common/validation/validation.helpers';
import { SalesforceContextService } from '../../../configuration/salesforce/salesforce-context.service';
import { LabelHelperService } from '../../../i18n/label-helper.service';
import { AmlProductDataService } from '../../../product-data/aml-product-data-service';
import { ProductInfo } from '../../../product-data/aml-product-data-strategy.interface';

export interface One2OneSessionData {
    description: string;
    country: string;
    title: string;
    firstName: string;
    lastName: string;
    customerEmail: string;
    customerPhone: string;
    sessionStart: Date;
    timeZone: string;
    productId?: string;

    dealerName: string;
    userOptInEmail: boolean;
    userOptInSMS: boolean;
    userOptInPhone: boolean;
    userOptInNewsletter: boolean;
}

@Component({
    selector: 'mhp-request-one2one-session',
    templateUrl: './request-one2one-session.component.html',
    styleUrls: ['./request-one2one-session.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestOne2oneSessionComponent
    extends UiBaseComponent
    implements OnInit
{
    @Input()
    set serverCallInProgress(value: boolean) {
        this.serverCallInProgressField = value;
        this.changeDetectorRef.detectChanges();
    }

    get serverCallInProgress() {
        return this.serverCallInProgressField;
    }

    @Input()
    modelIdOfInterest?: string;

    @Input()
    productIdOfInterest?: string;

    @Input()
    hideProductOfInterestSelection?: boolean;

    @Output()
    readonly requestSession = new EventEmitter<One2OneSessionData>();

    readonly one2oneFormGroup: UntypedFormGroup;

    readonly availableTitles = getTranslatedTitlesList();

    readonly availableTimeEntries = range(0, 1440, 30);

    readonly modelOptionNoPreference = 'MODEL_OPTION_NO_PREFERENCE';

    private serverCallInProgressField: boolean;

    readonly compareDealers = (dealerInfo1?: Dealer, dealerInfo2?: Dealer) =>
        dealerInfo1?.dealerCode === dealerInfo2?.dealerCode;

    constructor(
        private readonly l10nService: L10nService,
        private readonly i18nService: I18nService,
        private readonly salesforceContextService: SalesforceContextService,
        private readonly productDataService: AmlProductDataService,
        private readonly labelHelperService: LabelHelperService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        super();

        this.one2oneFormGroup = this.initFormGroup();

        this.completeOnDestroy(this.requestSession);
    }

    ngOnInit() {
        this.one2oneFormGroup.patchValue({
            modelId: this.modelIdOfInterest,
            productId: this.productIdOfInterest
        });

        if (!environment.production) {
            this.prefillForm();
        }
    }

    @MemoizeObservable()
    getActiveLang$(): Observable<string> {
        return this.i18nService.getActiveLang$();
    }

    @MemoizeObservable()
    getAvailableCountries$(): Observable<
        { countryISO: string; label: string }[]
    > {
        return this.salesforceContextService.getAvailableCountries$();
    }

    @MemoizeObservable()
    getAvailableDealers$(): Observable<ExtendedDealer[] | undefined> {
        const countryFormControl = this.one2oneFormGroup.get('country');
        if (!countryFormControl) {
            throw new IllegalStateError('missing country form control');
        }

        return this.salesforceContextService.getAvailableDealers$(
            countryFormControl?.valueChanges.pipe(
                startWith(countryFormControl.value)
            )
        );
    }

    @MemoizeObservable()
    getGdprMarkdown$(number: number) {
        return this.salesforceContextService.getGdprMarkdown$(number);
    }

    intentSubmit() {
        values(this.one2oneFormGroup.controls).forEach((control) =>
            control.markAllAsTouched()
        );

        if (!this.one2oneFormGroup.valid) {
            return;
        }

        const preferredDate: Date =
            this.one2oneFormGroup.get('preferredDate')?.value;
        const preferredTime = this.one2oneFormGroup.get('preferredTime')?.value;

        let sessionStart = new Date(
            new Date(
                preferredDate.getFullYear(),
                preferredDate.getMonth(),
                preferredDate.getDate()
            ).getTime() +
                preferredTime * 60 * 1000
        );

        if (this.one2oneFormGroup.get('scheduleNow')?.value === true) {
            // schedule in x minutes in the future + 1 minute safety-buffer
            const minutesInFuture =
                environment.appConfig.one2one.bookSessionNowMinutesInFuture;
            sessionStart = new Date(
                new Date().getTime() + minutesInFuture * 60 * 1000
            );
        }

        const formValue = this.one2oneFormGroup.value;

        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        const selectedDealer = <Dealer>formValue.dealer;

        const sessionData: One2OneSessionData = {
            description: `${formValue.firstName} ${formValue.lastName}`,
            country: formValue.country,
            title: formValue.title,
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            customerEmail: formValue.customerEmail,
            customerPhone: formValue.customerPhone,
            sessionStart,
            timeZone,
            dealerName: selectedDealer.name,
            productId: formValue.productId,
            userOptInEmail: !!formValue.contactOptions?.contactViaEmail,
            userOptInSMS: !!formValue.contactOptions?.contactViaSms,
            userOptInPhone: !!formValue.contactOptions?.contactViaTelephone,
            userOptInNewsletter:
                !!formValue.contactOptions?.contactViaNewsletter
        };

        this.requestSession.emit(sessionData);
    }

    formatTimeEntry(minutesIn: number) {
        const hours = Math.floor(minutesIn / 60);
        const minutes = Math.floor(minutesIn) % 60;

        return (
            [hours, minutes]
                .map((v) => (v < 10 ? `0${v}` : v))
                // .filter((v, i) => v !== '00' || i > 0)
                .join(':')
        );
    }

    @MemoizeObservable()
    getAvailableModelsForSelection$(): Observable<
        { id: string; label: string }[]
    > {
        return this.productDataService.getAvailableModels$().pipe(
            map((availableModels) =>
                availableModels.map((modelId) => ({
                    id: modelId,
                    label: this.labelHelperService.getModelName(modelId)
                }))
            )
        );
    }

    @MemoizeObservable()
    getAvailableProductsForSelection$(): Observable<
        { id: string; label: string }[] | undefined
    > {
        const modelIdFormControl = this.one2oneFormGroup.controls.modelId;
        return modelIdFormControl.valueChanges.pipe(
            startWith(<string | undefined>modelIdFormControl.value),
            switchMap((selectedModelId) => {
                if (!selectedModelId) {
                    return of(undefined);
                }
                return this.productDataService.getAvailableProductsForModel$(
                    selectedModelId
                );
            }),
            map((productInfos) => {
                if (!productInfos) {
                    return undefined;
                }
                return productInfos.map((productInfo: ProductInfo) => ({
                    id: productInfo.id,
                    label: this.labelHelperService.getDerivateName(
                        productInfo.modelId,
                        productInfo.id
                    )
                }));
            })
        );
    }

    private prefillForm() {
        this.one2oneFormGroup.patchValue({
            title: `Mr`,
            firstName: 'Test',
            lastName: `Customer ${new Date().toISOString()}`,
            customerEmail: 'test@test.com',
            customerPhone: '0221211199393'
        });
    }

    private initFormGroup() {
        const referenceStartDate = new Date();
        let minutes = Math.ceil(referenceStartDate.getMinutes() / 30) * 30;
        let hoursInMinutes = referenceStartDate.getHours() * 60;

        if (minutes === 60) {
            minutes = 0;
            hoursInMinutes += 60;
        }

        const formGroup = new UntypedFormGroup({
            // salesforce-fields
            country: new UntypedFormControl(undefined, Validators.required),
            dealer: new UntypedFormControl(undefined, Validators.required),
            title: new UntypedFormControl(undefined, Validators.required),
            firstName: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(40)
            ]),
            lastName: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(40)
            ]),
            customerEmail: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(40),
                Validators.pattern(EMAIL_REGEX)
            ]),
            customerPhone: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(15),
                Validators.pattern('[- +()0-9]+')
            ]),
            // required for both
            preferredDate: new UntypedFormControl(new Date(), [
                Validators.required
            ]),
            preferredTime: new UntypedFormControl(hoursInMinutes + minutes, [
                Validators.required
            ]),
            modelId: new UntypedFormControl(undefined),
            productId: new UntypedFormControl(undefined)
        });

        formGroup.addControl(
            // contact-options
            'contactOptions',
            new UntypedFormGroup({
                contactViaEmail: new UntypedFormControl(undefined),
                contactViaTelephone: new UntypedFormControl(undefined),
                contactViaSms: new UntypedFormControl(undefined),
                contactViaNewsletter: new UntypedFormControl(undefined)
            })
        );

        this.l10nService
            .getActiveCountry$()
            .pipe(first())
            .subscribe((country) =>
                formGroup.get('country')?.setValue(country)
            );

        const modelIdFormControl = formGroup.controls.modelId;
        const productIdFormControl = formGroup.controls.productId;

        modelIdFormControl.valueChanges.subscribe((selectedModelId) => {
            if (
                !selectedModelId ||
                selectedModelId === this.modelOptionNoPreference
            ) {
                productIdFormControl.setValue(undefined);
            }
        });

        return formGroup;
    }
}
