import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output
} from '@angular/core';
import { BeautyshotDefinition } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { AmlOptionMetadata } from '@mhp/aml-shared/configuration/aml-option-metadata';
import { ImageSrcset, UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-configuration-code-base',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class ConfigurationCodeBaseComponent extends UiBaseComponent {
    @Input()
    id?: string;

    @Input()
    subType?: 'material' | 'text' | 'image';

    // optional thumbnail-image that is used in preference before resolving an image for subType 'material' or 'image'
    @Input()
    thumbnailImage?: string | ImageSrcset | ImageSrcset[] | undefined;

    @Input()
    name?: string;

    @Input()
    subtitle?: string;

    @Input()
    code?: string;

    @Input()
    codeMeta?: AmlOptionMetadata;

    @Input()
    conflicts?: string[];

    @Input()
    hasAdditionalInfo?: boolean;

    @Input()
    forceInfoAlwaysVisible?: boolean;

    @Input()
    beautyshot?: BeautyshotDefinition;

    @Input()
    selectState: boolean;

    @Input()
    @HostBinding('class.hidden')
    visuallyHidden = false;

    @Input()
    disabled = false;

    @Input()
    readonly = false;

    @Input()
    fallbackImage: string;

    @Input()
    selectedMarkerIconId: string | undefined = 'mhp-ui:check';

    @Input()
    debug?: boolean;

    @Output()
    readonly selected = new EventEmitter<string>();

    @Output()
    readonly showInfo = new EventEmitter<string>();

    constructor() {
        super();

        this.completeOnDestroy(this.selected, this.showInfo);
    }

    setSelection() {
        if (this.disabled || this.readonly) {
            return;
        }
        this.selected.emit(this.id);
    }

    intentShowInfo() {
        if (!this.id) {
            return;
        }
        this.showInfo.next(this.id);
    }
}
