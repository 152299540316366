import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OptionalObservable, toObservable } from '@mhp/common/rxjs/rxjs-types';
import { ImageSrcset, UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-fullscreen-image',
    templateUrl: './fullscreen-image.component.html',
    styleUrls: ['./fullscreen-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FullscreenImageComponent extends UiBaseComponent {
    @Input()
    set imageSources(sources: OptionalObservable<(string | ImageSrcset)[]>) {
        this.imageSources$ = toObservable(sources);
    }

    @Input()
    imageFallbackUrl: string | undefined;

    imageSources$: Observable<(string | ImageSrcset)[]>;

    constructor() {
        super();
    }
}
