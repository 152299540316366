import { isNil, last } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    combineLatest,
    firstValueFrom
} from 'rxjs';
import { debounceTime, filter, first, map, take, tap } from 'rxjs/operators';
import Swiper from 'swiper';

import { BreakpointObserver } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    NgZone,
    OnDestroy,
    Signal
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Identifiable } from '@mhp-immersive-exp/contracts/src/generic/identifiable.interface';
import {
    IllegalStateError,
    MemoizeObservable,
    NgZoneAware,
    RunInZone,
    lazyShareReplay
} from '@mhp/common';
import { ImageSrcset, TRACK_BY_ID, UiBaseComponent } from '@mhp/ui-components';
import {
    ApplicationStateService,
    EngineControlService,
    ScreenshotSource,
    selectCameraState,
    setActiveCamera
} from '@mhp/ui-shared-services';

import { AmlBreakpoints } from '../../../common/breakpoints/AmlBreakpoints';
import { LocalApplicationState } from '../../../state/local-application-state.interface';
import { ScreenshotProviderRegistrarService } from '../../screenshot/screenshot-provider-registrar.service';
import { ScreenshotProvider } from '../../screenshot/screenshot-registry.service';
import { selectStageShrinkedState } from '../../state/selectors/configuration.selectors';
import { StaticRendererService } from '../../static-renderer/static-renderer.service';

interface CameraWithImageSource extends Identifiable {
    imageSource: ImageSrcset;
}

@Component({
    selector: 'mhp-basic-stage',
    templateUrl: './basic-stage.component.html',
    styleUrls: ['./basic-stage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ScreenshotProviderRegistrarService]
})
export class BasicStageComponent
    extends UiBaseComponent
    implements OnDestroy, AfterViewInit, ScreenshotProvider, NgZoneAware
{
    readonly trackById = TRACK_BY_ID;

    private readonly swiperInstanceSubject = new BehaviorSubject<
        Swiper | undefined
    >(undefined);

    private readonly swiperSlideCount: Signal<number | undefined>;

    private pauseReactToExternalSwiperEvents = false;

    constructor(
        private readonly staticRendererService: StaticRendererService,
        private readonly engineControlService: EngineControlService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        public readonly ngZone: NgZone,
        private readonly breakpointObserver: BreakpointObserver,
        screenshotProviderRegistrarService: ScreenshotProviderRegistrarService
    ) {
        super();

        screenshotProviderRegistrarService.register(this);

        this.swiperSlideCount = toSignal(
            this.getAvailableCamerasWithImageSources$().pipe(
                map((items) => items?.length ?? 0)
            )
        );
    }

    ngAfterViewInit() {
        this.swiperInstanceSubject
            .pipe(
                filter((swiper): swiper is Swiper => !!swiper),
                this.takeUntilDestroy(),
                take(1)
            )
            .subscribe((swiper) => {
                /* well, another swiper curiosity where the realIndex is initially reported as 1 (instead of 0).
                 * When the swiper is internally initialized, the initial slide-index is set
                 * to zero which gets internally adjusted to slide-index 1 (due to loop: true)
                 * which is tried to be resolved to a real-index by querying the slide-dom-nodes
                 * that do not exist at this point of time. Thus, the adjusted slide-index 1
                 * is used as fallback and persisted as previous index and as realIndex. After the slides are present
                 * and it would be possible to resolve the correct realIndex, the slide-to logic returns
                 * fast after checking that the index to be activated is the same as the persisted
                 * previous-index and so nothing is being changed / updated.
                 * We therefore first slide to a higher index and then revert to the 0 index.
                 */
                try {
                    this.pauseReactToExternalSwiperEvents = true;
                    swiper.slideToLoop(1, 0);
                    swiper.slideToLoop(0, 0);
                } finally {
                    this.pauseReactToExternalSwiperEvents = false;
                }
                this.initActiveBeautyshotBinding();
            });
    }

    @MemoizeObservable()
    getAvailableCamerasWithImageSources$(): Observable<
        CameraWithImageSource[] | undefined
    > {
        return combineLatest([
            this.engineControlService.getAvailableCamerasForContext$(),
            this.staticRendererService.getActiveSessionRenderingSrcsetFactory$()
        ]).pipe(
            map(([availableCameras, renderingSrcsetFactory]) => {
                if (!availableCameras || !renderingSrcsetFactory) {
                    return undefined;
                }

                return availableCameras.map((cameraId) => ({
                    id: cameraId,
                    imageSource: renderingSrcsetFactory((sessionInfo) => ({
                        ...sessionInfo,
                        camera: {
                            id: cameraId,
                            options: {}
                        }
                    }))
                }));
            }),
            lazyShareReplay()
        );
    }

    @RunInZone()
    onSlideChange() {
        if (this.pauseReactToExternalSwiperEvents) {
            return;
        }

        this.getActiveCameraWithImageSource$()
            .pipe(first())
            .subscribe((activeCameraWithImageSource) => {
                if (!activeCameraWithImageSource) {
                    return;
                }

                this.applicationStateService.dispatch(
                    setActiveCamera({
                        id: activeCameraWithImageSource.id
                    })
                );
            });
    }

    onSwiperInitialized(swiper: Swiper) {
        if (this.swiperInstanceSubject.value) {
            throw new IllegalStateError('Swiper already initialized');
        }

        this.swiperInstanceSubject.next(swiper);
    }

    async getScreenshotSource(): Promise<ScreenshotSource | undefined> {
        const screenshotSource$ = this.getActiveCameraWithImageSource$().pipe(
            take(1),
            map((activeCameraWithImageSource) => {
                if (!activeCameraWithImageSource) {
                    return undefined;
                }

                const screenshotImage = last(
                    activeCameraWithImageSource.imageSource.sources
                )?.url;

                if (!screenshotImage) {
                    return undefined;
                }

                return {
                    source: screenshotImage,
                    mimeType: 'image/jpeg'
                };
            })
        );

        return firstValueFrom(screenshotSource$);
    }

    private getActiveCameraWithImageSource$() {
        return this.getAvailableCamerasWithImageSources$().pipe(
            map((camerasWithImageSources) => {
                const activeIndex = this.swiperInstanceSubject.value?.realIndex;

                if (!camerasWithImageSources || isNil(activeIndex)) {
                    return undefined;
                }

                return camerasWithImageSources[activeIndex];
            })
        );
    }

    private initActiveBeautyshotBinding() {
        combineLatest([
            this.applicationStateService.getState().pipe(selectCameraState),
            this.getAvailableCamerasWithImageSources$()
        ])
            .pipe(
                tap(() => {
                    this.pauseReactToExternalSwiperEvents = true;
                }),
                debounceTime(0),
                map(([activeCameraId, camerasWithImageSources]) => {
                    if (!activeCameraId || !camerasWithImageSources) {
                        return -1;
                    }

                    const matchingCamera = camerasWithImageSources.find(
                        (camera) => camera.id === activeCameraId
                    );

                    if (!matchingCamera) {
                        return -1;
                    }

                    return camerasWithImageSources.indexOf(matchingCamera);
                }),
                this.takeUntilDestroy()
            )
            .subscribe((indexToActivate) => {
                this.pauseReactToExternalSwiperEvents = false;

                if (
                    indexToActivate < 0 ||
                    this.swiperInstanceSubject.value?.realIndex ===
                        indexToActivate
                ) {
                    return;
                }

                this.swiperInstanceSubject.value?.slideToLoop(indexToActivate);
            });
    }

    intentPrevSlide() {
        const swiperInstance = this.swiperInstanceSubject.value;
        if (!swiperInstance) {
            return;
        }
        swiperInstance?.slideToLoop(
            swiperInstance.realIndex <= 0
                ? (this.swiperSlideCount() ?? 0) - 1
                : swiperInstance.realIndex - 1
        );
    }

    intentNextSlide() {
        const swiperInstance = this.swiperInstanceSubject.value;
        if (!swiperInstance) {
            return;
        }
        swiperInstance?.slideToLoop(
            (this.swiperSlideCount() ?? 0) - 1 <= swiperInstance.realIndex
                ? 0
                : swiperInstance.realIndex + 1
        );
    }

    @MemoizeObservable()
    arePaginationBulletsForceHidden$(): Observable<boolean> {
        return combineLatest([
            this.breakpointObserver.observe(AmlBreakpoints.HandsetPortrait),
            this.applicationStateService
                .getLocalState()
                .pipe(selectStageShrinkedState)
        ]).pipe(
            map(
                ([isHandsetPortrait, isStageShrinked]) =>
                    isHandsetPortrait.matches && isStageShrinked
            )
        );
    }
}
