import { ClipboardService } from 'ngx-clipboard';
import { Observable, forkJoin, of, switchMap } from 'rxjs';
import { catchError, map, take, withLatestFrom } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { connectedPublishReplay, lazyShareReplay } from '@mhp/common';
import { UiNotificationService } from '@mhp/ui-components';
import {
    ErrorHandlerService,
    UrlShortenerService
} from '@mhp/ui-shared-services';

import { AmlBreakpoints } from '../../common/breakpoints/AmlBreakpoints';
import {
    ConfigurationChangedStateTrackingService,
    ConfigurationSessionChangedState
} from '../configuration-changed-state/configuration-changed-state-tracking.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { ConfigurationSocialSharingService } from '../social-sharing/configuration-social-sharing.service';

/**
 * Provides functionality to provide information about the current configuration session to the user.
 *
 * See https://mhpimmersive.atlassian.net/browse/AMIF-695
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigurationLeaveService {
    /**
     * The config-url that was previously processed. In case it's the same, do not
     * notify the user.
     * @private
     */
    private previouslyProcessedConfigurationUrl: string | undefined;

    private readonly configurationSessionChangedState$: Observable<ConfigurationSessionChangedState>;

    constructor(
        private readonly urlShortenerService: UrlShortenerService,
        private readonly configurationSocialSharingService: ConfigurationSocialSharingService,
        private readonly notificationService: UiNotificationService,
        private readonly clipboardService: ClipboardService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly configurationChangedStateTrackingService: ConfigurationChangedStateTrackingService
    ) {
        this.configurationSessionChangedState$ =
            this.configurationChangedStateTrackingService.getConfigurationSessionChangedState$();
    }

    /**
     * Needs to be called when the configuration-context is left by the user.
     * Leaving the context has to be postponed until the returned Observable
     * emits once.
     */
    onConfigurationLeave$(): Observable<undefined> {
        // resolve the current configuration link
        const currentConfigurationUrl$ = this.configurationSessionInfoService
            .getCurrentConfigurationUrl$()
            .pipe(take(1), lazyShareReplay());

        // process the current configuration link in the background
        currentConfigurationUrl$
            .pipe(
                withLatestFrom(
                    this.configurationSessionChangedState$,
                    this.breakpointObserver.observe([
                        AmlBreakpoints.HandsetPortrait,
                        AmlBreakpoints.TabletPortrait
                    ])
                ),
                switchMap(
                    ([
                        currentConfigUrl,
                        configurationSessionChangedState,
                        handsetOrTabletMatcher
                    ]) => {
                        if (
                            !currentConfigUrl ||
                            !configurationSessionChangedState?.optionsDirty ||
                            currentConfigUrl ===
                                this.previouslyProcessedConfigurationUrl
                        ) {
                            return of(undefined);
                        }

                        // notify the user
                        const notificationRef =
                            this.notificationService.showAutohideNotification(
                                translate('AML_CODE.NOTIFY.DESCRIPTION'),
                                10000,
                                translate('AML_CODE.NOTIFY.CTA_COPY_LINK'),
                                {
                                    renderActionNewline:
                                        handsetOrTabletMatcher.matches
                                }
                            );
                        return notificationRef
                            .afterDismissed()
                            .pipe(
                                map((snackbarDismissed) =>
                                    snackbarDismissed.dismissedByAction
                                        ? currentConfigUrl
                                        : undefined
                                )
                            );
                    }
                ),
                switchMap((configUrlToCopy) => {
                    if (!configUrlToCopy) {
                        return of(undefined);
                    }

                    return this.urlShortenerService
                        .generateShortCode$(configUrlToCopy)
                        .pipe(
                            switchMap((shortCode) =>
                                forkJoin({
                                    code: of(shortCode),
                                    fullLink: of(configUrlToCopy)
                                })
                            ),
                            this.errorHandlerService.applyRetry({
                                messageProviderOnFinalError: () =>
                                    translate(
                                        'AML_CODE.CREATE.ERROR.FAILED_GENERATING_CODE'
                                    )
                            })
                        );
                }),
                switchMap((resolvedCode) => {
                    if (!resolvedCode) {
                        return of(undefined);
                    }

                    const { code, fullLink } = resolvedCode;

                    this.previouslyProcessedConfigurationUrl = fullLink;

                    // copy the link to the clipboard
                    const copyResponse$ =
                        this.clipboardService.copyResponse$.pipe(
                            take(1),
                            connectedPublishReplay()
                        );

                    this.clipboardService.copy(code);

                    return copyResponse$;
                })
            )
            .subscribe((copyResponse) => {
                if (!copyResponse) {
                    return;
                }

                if (copyResponse.isSuccess) {
                    this.notificationService.showAutohideNotification(
                        translate('AML_CODE.CREATE.DIALOG.COPY_CODE_SUCCESS')
                    );
                } else {
                    this.notificationService.showError(
                        translate('AML_CODE.CREATE.ERROR.COPY_CODE')
                    );
                }
            });

        // when we got hold of the current config-link, the external caller can proceed leaving the config-context
        return currentConfigurationUrl$.pipe(
            catchError(() => of(undefined)),
            map(() => undefined)
        );
    }
}
