import { defer } from 'lodash-es';
import { ReplaySubject, take } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Optional,
    ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SalesforceConfigurationSendInterface } from '@mhp/aml-shared/data-proxy/salesforce-configuration-send.interface';
import { sfEvent } from '@mhp/aml-ui-shared-services/salesforce-tracking';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { gtmGA4Track } from '@mhp/ui-shared-services';

import { SalesforceFormComponent } from '../../../dealer/salesforce/salesforce-form/salesforce-form.component';
import { LabelHelperService } from '../../../i18n/label-helper.service';
import { SALESFORCE_EVENT_SUBMIT_CONFIGURATION_TO_DEALER } from '../../../salesforce-events/salesforce-events';
import { SalesforceContextService } from '../salesforce-context.service';

@Component({
    selector: 'mhp-submit-to-dealer',
    templateUrl: './submit-to-dealer.component.html',
    styleUrls: ['./submit-to-dealer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitToDealerComponent
    extends UiBaseComponent
    implements AfterViewInit
{
    @ViewChild(SalesforceFormComponent)
    salesforceFormComponentRef: SalesforceFormComponent;

    private readonly viewChildReferencesBound = new ReplaySubject<boolean>(1);

    constructor(
        private readonly labelHelperService: LabelHelperService,
        private readonly salesforceContextService: SalesforceContextService,
        @Optional()
        private readonly matDialog?: MatDialogRef<SubmitToDealerComponent>
    ) {
        super();

        this.completeOnDestroy(this.viewChildReferencesBound);
    }

    ngAfterViewInit() {
        defer(() => this.viewChildReferencesBound.next(true));
    }

    @MemoizeObservable()
    getModelLabel$() {
        return this.labelHelperService.getActiveModelAndProductName$();
    }

    @MemoizeObservable()
    canSubmit$() {
        return this.viewChildReferencesBound.pipe(
            switchMap(() => {
                const formComponentRef = this.salesforceFormComponentRef;

                return formComponentRef.getServerCallInProgress$().pipe(
                    map((formSubmitInProgress) => !formSubmitInProgress),
                    lazyShareReplay()
                );
            })
        );
    }

    onSubmitSuccess(payload: SalesforceConfigurationSendInterface) {
        gtmGA4Track('share_to_dealer_success');

        this.salesforceContextService
            .getSalesforceContextData$({
                includeDealerConfigurationUrl: true
            })
            .pipe(take(1))
            .subscribe((contextData) => {
                const { modelId, productId } = contextData;

                if (!modelId || !productId) {
                    return;
                }

                // SF Data Cloud: Submit Configuration to Dealer
                sfEvent({
                    interaction: {
                        ...SALESFORCE_EVENT_SUBMIT_CONFIGURATION_TO_DEALER,
                        modelCode: modelId,
                        variantCode: productId,
                        configUrl: contextData.dealerConfigurationLink ?? '',
                        dealer: payload.dealerCode ?? ''
                    },
                    user: {
                        attributes: {
                            firstName: payload.firstName,
                            lastName: payload.lastName,
                            email: payload.customerEmail,
                            phone: payload.customerPhone,
                            country: payload.countryIsoCode,
                            eventType: 'identity',
                            isAnonymous: 0
                        }
                    }
                });

                // SF Data Cloud: contactPointEmail
                sfEvent({
                    user: {
                        attributes: {
                            email: payload.customerEmail,
                            eventType: 'contactPointEmail'
                        }
                    }
                });

                // SF Data Cloud: contactPointPhone
                sfEvent({
                    user: {
                        attributes: {
                            phoneNumber: payload.customerPhone,
                            eventType: 'contactPointPhone'
                        }
                    }
                });
            });

        this.matDialog?.close('SUBMITTED');
    }

    onSubmitFailed() {
        gtmGA4Track('share_to_dealer_failed');
    }
}
