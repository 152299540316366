<ng-container *transloco="let t; read: 'SUMMARY'">
    <div #scrollToTopAnchor></div>
    <div #summarySlider class="configuration-summary__summary-slider">
        <mhp-summary-slider
            *ngIf="
                getAvailableCamerasWithComparisonImageSources$()
                    | async as sliderComparisonImages
            "
            [imageUrls]="sliderComparisonImages"
            [slidesPerView]="getSlidesPerViewForSummarySlider$() | async"
            [comparisonSliderHandleHidden]="
                (summarySliderPaginationVisibleStatus$ | async) === false
            "
            [autoplayDisabled]="
                (summarySliderAutoplayStatus$ | async) === false
            "
        ></mhp-summary-slider>
        <ng-container *ngIf="renderTurntableCta">
            <mhp-configuration-area-action-item
                *transloco="let t2; read: 'ICONS'"
                class="configuration-summary__toggle-turntable"
                svgIcon="mhp-ui:turntable"
                iconSize="large"
                [matTooltip]="t2('TURNTABLE_ALT')"
                (click)="intentToggleTurntable()"
            ></mhp-configuration-area-action-item>
        </ng-container>
    </div>
    <div class="configuration-summary__summary-main">
        <div class="configuration-summary__summary-lanes">
            <div
                class="configuration-summary__lane configuration-summary__lane--left"
            >
                <div class="configuration-summary__header">
                    <h1
                        [innerHtml]="
                            t('CONFIGURATION_SUMMARY.HEADLINE', {
                                modelname:
                                    labelHelperService.getActiveModelAndProductName$()
                                    | async
                            })
                        "
                    ></h1>
                    <div class="configuration-summary__header-description">
                        <p>
                            {{
                                labelHelperService.getActiveProductDescription$()
                                    | async
                            }}
                        </p>
                        <span
                            *mhpIfShowConfigurationPricing
                            class="configuration-summary__header-price"
                            ><ng-container
                                *ngIf="
                                    getPricingSummaryModel$()
                                        | async as pricingSummaryModel
                                "
                                >{{
                                    pricingSummaryModel
                                        | effectivePricingAwareFormat
                                }}</ng-container
                            ></span
                        >
                    </div>
                </div>

                <!-- pricing summary -->
                <ng-container *mhpIfShowConfigurationPricing>
                    <div
                        *ngIf="
                            getPricingSummaryModel$()
                                | async as pricingSummaryModel
                        "
                        class="configuration-summary__summary-block configuration-summary__summary-block--pricing"
                    >
                        <div
                            class="configuration-summary__summary-block-header"
                        >
                            <h2>{{ t('PRICING.HEADLINE') }}</h2>
                        </div>
                        <div class="configuration-summary__summary-block-body">
                            <div
                                *ngFor="
                                    let pricingEntry of pricingSummaryModel.subEntries;
                                    trackBy: trackByLabel
                                "
                                class="configuration-summary__summary-block-entry"
                                [ngClass]="{
                                    'configuration-summary__summary-block-entry--level-1': true,
                                    'configuration-summary__summary-block-entry--has-children':
                                        !!pricingEntry.children
                                }"
                            >
                                <ng-container
                                    *ngIf="
                                        !pricingEntry.children;
                                        else pricingEntryWithChildren
                                    "
                                >
                                    <div
                                        class="configuration-summary__summary-block-entry-col"
                                    >
                                        {{ pricingEntry.label }}
                                    </div>
                                    <div
                                        *mhpIfShowConfigurationPricing
                                        class="configuration-summary__summary-block-entry-col configuration-summary__summary-block-entry-col--pricing"
                                    >
                                        {{
                                            pricingEntry
                                                | effectivePricingAwareFormat
                                        }}
                                    </div>
                                </ng-container>
                                <ng-template #pricingEntryWithChildren>
                                    <mhp-ui-expansion-panel #expansionPanel>
                                        <mhp-ui-expansion-panel-header
                                            matRipple
                                        >
                                            <div
                                                class="configuration-summary__summary-block-entry-col"
                                            >
                                                {{ pricingEntry.label }}
                                            </div>
                                            <div
                                                class="configuration-summary__summary-block-header-actions"
                                            >
                                                <div
                                                    class="configuration-summary__summary-block-header-action-expanded-state"
                                                >
                                                    <mhp-ui-icon-button
                                                        *transloco="
                                                            let t;
                                                            read: 'ICONS'
                                                        "
                                                        [svgIcon]="
                                                            expansionPanel.expanded
                                                                ? 'mhp-ui:angle-up'
                                                                : 'mhp-ui:angle-down'
                                                        "
                                                        [label]="
                                                            t(
                                                                expansionPanel.expanded
                                                                    ? 'MENU_LESS_ALT'
                                                                    : 'MENU_MORE_ALT'
                                                            )
                                                        "
                                                        (click)="
                                                            expansionPanel.expanded =
                                                                !expansionPanel.expanded
                                                        "
                                                        mhpUiPreventNestedRipple
                                                    ></mhp-ui-icon-button>
                                                </div>
                                            </div>
                                            <div
                                                *mhpIfShowConfigurationPricing
                                                class="configuration-summary__summary-block-entry-col configuration-summary__summary-block-entry-col--pricing"
                                            >
                                                {{
                                                    pricingEntry
                                                        | effectivePricingAwareFormat
                                                }}
                                            </div>
                                        </mhp-ui-expansion-panel-header>
                                        <mhp-ui-expansion-panel-body>
                                            <div
                                                *ngFor="
                                                    let pricingEntryChild of pricingEntry.children;
                                                    trackBy: trackByLabel
                                                "
                                                class="configuration-summary__summary-block-entry"
                                                [ngClass]="
                                                    'configuration-summary__summary-block-entry--level-2'
                                                "
                                            >
                                                <div
                                                    class="configuration-summary__summary-block-entry-col"
                                                >
                                                    {{
                                                        pricingEntryChild.label
                                                    }}
                                                </div>
                                                <div
                                                    *mhpIfShowConfigurationPricing
                                                    class="configuration-summary__summary-block-entry-colconfiguration-summary__summary-block-entry-col--pricing"
                                                >
                                                    {{
                                                        pricingEntryChild
                                                            | effectivePricingAwareFormat
                                                    }}
                                                </div>
                                            </div>
                                        </mhp-ui-expansion-panel-body>
                                    </mhp-ui-expansion-panel>
                                </ng-template>
                            </div>
                            <div
                                class="configuration-summary__summary-block-entry"
                            >
                                <div
                                    class="configuration-summary__summary-block-entry-col"
                                >
                                    {{ getTotalPriceLabel$() | async }}
                                </div>
                                <div
                                    class="configuration-summary__summary-block-entry-col configuration-summary__summary-block-entry-col--pricing"
                                >
                                    {{
                                        pricingSummaryModel
                                            | effectivePricingAwareFormat
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- financial service summary -->
                <ng-container *mhpIfShowConfigurationPricing>
                    <div
                        *ngIf="
                            getFinancialOfferRows$()
                                | async as financialOfferRows
                        "
                        class="configuration-summary__summary-block configuration-summary__summary-block--financial-service"
                    >
                        <div
                            class="configuration-summary__summary-block-header"
                        >
                            <h2>{{ t('FIN_SERVICES.HEADLINE') }}</h2>
                        </div>
                        <div class="configuration-summary__summary-block-body">
                            <div
                                *ngFor="
                                    let offerEntry of financialOfferRows;
                                    trackBy: trackByLabel
                                "
                                class="configuration-summary__summary-block-entry"
                                [ngClass]="{
                                    'configuration-summary__summary-block-entry--level-1': true,
                                    'configuration-summary__summary-block-entry--has-children': false
                                }"
                            >
                                <div
                                    class="configuration-summary__summary-block-entry-col"
                                >
                                    {{ offerEntry.label }}
                                </div>
                                <div
                                    class="configuration-summary__summary-block-entry-col configuration-summary__summary-block-entry-col--pricing"
                                >
                                    {{ offerEntry.value }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- configuration-options w/ split in configured and standard-equipment -->
                <mat-tab-group
                    mat-stretch-tabs="false"
                    mat-align-tabs="left"
                    (selectedIndexChange)="
                        onSelectedOptionsVsStandardFeaturesTabChanged($event)
                    "
                >
                    <mat-tab
                        [label]="
                            t('CONFIGURATION_SUMMARY.CONFIGURATION_OPTIONS')
                        "
                    >
                        <ng-template matTabContent>
                            <!-- Toggle to change pricing-display -->
                            <mat-slide-toggle
                                *ngIf="
                                    isFinancialServiceOfferAvailable$() | async
                                "
                                class="configuration-summary__fs_toggle-rates"
                                color="primary"
                                labelPosition="before"
                                [checked]="financialServicesShowMonthlyRate"
                                (toggleChange)="
                                    intentToggleFinancialServicesRateDisplay()
                                "
                            >
                                Monthly rates
                            </mat-slide-toggle>

                            <!-- Options listed as summary -->
                            <ng-container
                                *ngTemplateOutlet="
                                    summaryModelTree;
                                    context: {
                                        summaryGroups:
                                            getConfigurationOptionsSummaryGroups$()
                                            | async,
                                        showPrice: true,
                                        showMonthlyRate:
                                            financialServicesShowMonthlyRate
                                    }
                                "
                            ></ng-container>

                            <!-- total price -->
                            <ng-container *mhpIfShowConfigurationPricing>
                                <div
                                    *ngIf="
                                        getPricingSummaryModel$()
                                            | async as pricingSummaryModel
                                    "
                                    class="configuration-summary__total-price"
                                >
                                    <span>{{
                                        t(
                                            pricingSummaryModel.pricingType ===
                                                'DNP'
                                                ? 'PRICING.TOTAL_PRICE_DNP'
                                                : 'PRICING.TOTAL_PRICE_RRP'
                                        )
                                    }}</span>
                                    <span>{{
                                        pricingSummaryModel
                                            | effectivePricingAwareFormat
                                    }}</span>
                                </div>
                            </ng-container>
                        </ng-template>
                    </mat-tab>
                    <mat-tab
                        [label]="t('CONFIGURATION_SUMMARY.STANDARD_EQUIPMENT')"
                    >
                        <ng-template matTabContent>
                            <ng-container
                                *ngTemplateOutlet="
                                    summaryModelTree;
                                    context: {
                                        summaryGroups:
                                            getStandardFeaturesSummaryGroups$()
                                            | async,
                                        showPrice: false
                                    }
                                "
                            ></ng-container>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>

                <ng-template
                    #summaryModelTree
                    let-summaryGroups="summaryGroups"
                    let-showPrice="showPrice"
                    let-showMonthlyRate="showMonthlyRate"
                >
                    <!-- TOP-LEVEL GROUPS -->
                    <div
                        *ngIf="summaryGroups"
                        class="configuration-summary__top-level-groups"
                    >
                        <ng-container
                            *ngFor="
                                let topLevelSummaryGroup of summaryGroups;
                                trackBy: trackById
                            "
                        >
                            <!-- render the collapsible top-level-element -->
                            <div
                                class="configuration-summary__summary-block configuration-summary__summary-block--top-level-group"
                            >
                                <mhp-ui-expansion-panel
                                    #expansionPanel
                                    [ngClass]="{
                                        'configuration-summary__summary-block--no-header':
                                            !topLevelSummaryGroup.name
                                    }"
                                >
                                    <mhp-ui-expansion-panel-header matRipple>
                                        <div
                                            class="configuration-summary__summary-block-header"
                                        >
                                            <h2>
                                                {{ topLevelSummaryGroup.name }}
                                            </h2>
                                        </div>
                                        <ng-container
                                            *mhpIfShowConfigurationPricing
                                        >
                                            <div
                                                *ngIf="showPrice"
                                                class="configuration-summary__summary-block-entry-col configuration-summary__summary-block-entry-col--pricing"
                                            >
                                                {{
                                                    getPricingSummarySubEntryForId(
                                                        topLevelSummaryGroup.id
                                                    )
                                                        | async
                                                        | effectivePricingAwareFormat
                                                }}
                                            </div>
                                        </ng-container>
                                        <div
                                            class="configuration-summary__summary-block-header-action-expanded-state"
                                        >
                                            <mhp-ui-icon-button
                                                *transloco="
                                                    let t;
                                                    read: 'ICONS'
                                                "
                                                [svgIcon]="
                                                    expansionPanel.expanded
                                                        ? 'mhp-ui:angle-up'
                                                        : 'mhp-ui:angle-down'
                                                "
                                                [label]="
                                                    t(
                                                        expansionPanel.expanded
                                                            ? 'MENU_LESS_ALT'
                                                            : 'MENU_MORE_ALT'
                                                    )
                                                "
                                                (click)="
                                                    expansionPanel.expanded =
                                                        !expansionPanel.expanded
                                                "
                                                mhpUiPreventNestedRipple
                                            ></mhp-ui-icon-button>
                                        </div>
                                    </mhp-ui-expansion-panel-header>
                                    <mhp-ui-expansion-panel-body>
                                        <div
                                            class="configuration-summary__summary-block-body"
                                        >
                                            <ng-container
                                                *ngTemplateOutlet="
                                                    summaryEntry;
                                                    context: {
                                                        level: 1,
                                                        summaryGroup:
                                                            topLevelSummaryGroup,
                                                        showPrice: showPrice,
                                                        showMonthlyRate:
                                                            showMonthlyRate
                                                    }
                                                "
                                            ></ng-container>
                                        </div>
                                    </mhp-ui-expansion-panel-body>
                                </mhp-ui-expansion-panel>
                            </div>
                        </ng-container>
                    </div>

                    <!-- SUMMARY-ROW -->
                    <ng-template
                        #summaryEntry
                        let-level="level"
                        let-summaryGroup="summaryGroup"
                        let-showPrice="showPrice"
                        let-showMonthlyRate="showMonthlyRate"
                    >
                        <ng-container
                            *ngFor="
                                let summaryGroupChild of summaryGroup.content;
                                trackBy: trackById
                            "
                        >
                            <div
                                class="configuration-summary__summary-block-entry"
                                [ngClass]="[
                                    'configuration-summary__summary-block-entry--level-' +
                                        level
                                ]"
                            >
                                <div
                                    class="configuration-summary__summary-block-entry-left"
                                >
                                    <div
                                        class="configuration-summary__summary-block-entry-col configuration-summary__summary-block-entry-col--group"
                                    >
                                        {{ summaryGroupChild.name }}
                                        <span
                                            *ngIf="
                                                summaryGroupChild.node?.meta
                                                    ?.hasAdditionalFittingCost
                                            "
                                            >&nbsp;*</span
                                        >
                                    </div>

                                    <!-- Distinguish between single-sub-entry and multiple -->
                                    <ng-container
                                        *ngIf="
                                            summaryGroupChild.content
                                                ?.length === 1
                                        "
                                    >
                                        <div
                                            class="configuration-summary__summary-block-entry-col configuration-summary__summary-block-entry-col--option"
                                            *transloco="let t"
                                        >
                                            <mhp-ui-icon
                                                *ngIf="
                                                    summaryGroupChild.content[0]
                                                        ?.node?.meta?.qOption
                                                "
                                                class="option-label-q-option"
                                                svgIcon="mhp-ui:q-option"
                                                size="medium"
                                                [matTooltip]="
                                                    t('ICONS.Q_OPTION_ALT')
                                                "
                                            ></mhp-ui-icon>
                                            <span>
                                                {{
                                                    summaryGroupChild.content[0]
                                                        .name
                                                }}
                                            </span>
                                            <span
                                                *ngIf="
                                                    summaryGroupChild.content[0]
                                                        ?.node?.meta
                                                        ?.hasAdditionalFittingCost
                                                "
                                                >&nbsp;*</span
                                            >
                                        </div>
                                    </ng-container>
                                </div>
                                <ng-container
                                    *ngIf="
                                        !summaryGroupChild.content ||
                                        summaryGroupChild.content.length === 1
                                    "
                                >
                                    <ng-container
                                        *ngTemplateOutlet="
                                            infoButton;
                                            context: {
                                                description:
                                                    summaryGroupChild.content
                                                        ? summaryGroupChild
                                                              .content[0]
                                                              .description
                                                        : summaryGroupChild.description,
                                                node: summaryGroupChild.content
                                                    ? summaryGroupChild
                                                          .content[0].node
                                                    : summaryGroupChild.node
                                            }
                                        "
                                    ></ng-container>
                                    <ng-container
                                        *mhpIfShowConfigurationPricing
                                    >
                                        <div
                                            *ngIf="showPrice"
                                            class="configuration-summary__summary-block-entry-col configuration-summary__summary-block-entry-col--pricing"
                                        >
                                            <ng-container
                                                *ngIf="!showMonthlyRate"
                                            >
                                                {{
                                                    (summaryGroupChild.content
                                                        ? summaryGroupChild
                                                              .content[0]
                                                        : summaryGroupChild
                                                    )
                                                        | effectivePricingAwareFormat
                                                }}
                                            </ng-container>
                                            <ng-container
                                                *ngIf="showMonthlyRate"
                                            >
                                                {{
                                                    (summaryGroupChild.content
                                                        ? summaryGroupChild
                                                              .content[0]
                                                              .monthlyRate
                                                        : summaryGroupChild.monthlyRate
                                                    ) | translocoCurrency
                                                }}
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                            <ng-container
                                *ngIf="summaryGroupChild.content?.length > 1"
                            >
                                <ng-container
                                    *ngTemplateOutlet="
                                        summaryEntry;
                                        context: {
                                            level: level + 1,
                                            summaryGroup: summaryGroupChild,
                                            showPrice: showPrice,
                                            showMonthlyRate: showMonthlyRate
                                        }
                                    "
                                >
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-template>
                </ng-template>

                <!-- INFO-BUTTON -->
                <ng-template
                    #infoButton
                    let-node="node"
                    let-description="description"
                >
                    <mhp-ui-icon-button
                        *transloco="let t; read: 'ICONS'"
                        class="configuration-summary__summary-block-entry-info"
                        [ngClass]="{ disabled: IS_DEALER_BUILD === true }"
                        [label]="
                            t('INFO_ON_ALT', {
                                topic: node.nameTranslated
                            })
                        "
                        [svgIcon]="'mhp-ui:info'"
                        size="large"
                        [class.configuration-summary__summary-block-entry-info--invisible]="
                            !description
                        "
                        (click)="intentShowInfoForOption(node)"
                    ></mhp-ui-icon-button>
                </ng-template>

                <!-- additional options -->
                <div
                    *ngIf="
                        getSummaryCollections$() | async as summaryCollections
                    "
                    class="configuration-summary__additional-options"
                >
                    <div
                        *mhpDealerFeature
                        class="configuration-summary__additional-options-introduction"
                    >
                        <h3
                            class="configuration-summary__additional-options-introduction-header"
                        >
                            {{ t('SERVICE_PLAN_HINT.HEADLINE') }}
                        </h3>

                        <p
                            class="configuration-summary__additional-options-introduction-description"
                            [innerHTML]="t('SERVICE_PLAN_HINT.DESCRIPTION')"
                        ></p>
                    </div>

                    <ng-container
                        *ngFor="
                            let summaryCollection of summaryCollections;
                            trackBy: trackById
                        "
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                summaryScopeOption;
                                context: {
                                    collection: summaryCollection
                                }
                            "
                        ></ng-container>
                    </ng-container>
                </div>

                <ng-template #summaryScopeOption let-collection="collection">
                    <div class="additional-option-block">
                        <h2 class="additional-option-block__header">
                            {{ collection.nameTranslated }}
                        </h2>
                        <div class="additional-option-block__body">
                            <div class="additional-option-block__options">
                                <div
                                    *ngFor="let option of collection.content"
                                    class="additional-option-block__option"
                                >
                                    <mat-checkbox
                                        color="primary"
                                        labelPosition="after"
                                        [value]="option.id"
                                        [checked]="option.selected"
                                        [disabled]="
                                            (IS_DEALER_BUILD &&
                                            option.meta?.notOrderable) || updatingOptionSelection()
                                        "
                                        (change)="
                                            $event.source.checked =
                                                !$event.source.checked
                                        "
                                        (click)="intentSelectOption(option.id)"
                                        (keyup.enter)="
                                            intentSelectOption(option.id)
                                        "
                                        >{{
                                            option.nameTranslated
                                        }}</mat-checkbox
                                    >
                                    <div class="additional-option-block__info">
                                        <ng-container
                                            *ngTemplateOutlet="
                                                infoButton;
                                                context: {
                                                    description:
                                                        option.descriptionTranslated,
                                                    node: option
                                                }
                                            "
                                        ></ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>

            <!-- Lane 2 -->
            <div
                *ngIf="showRightLane"
                class="configuration-summary__lane configuration-summary__lane--right"
            >
                <!-- submit to voe / salesforce -->
                <div
                    class="configuration-summary__cta-block"
                    *transloco="
                        let t2;
                        read: IS_DEALER_BUILD
                            ? 'SUMMARY.SUBMIT_DEALER'
                            : 'SUMMARY.SUBMIT_CUSTOMER'
                    "
                >
                    <ng-container
                        *mhpIfOrderPossible="
                            let i;
                            else: IS_DEALER_BUILD ? noSubmitPossible : undefined
                        "
                    >
                        <h2 class="configuration-summary__cta-block-header">
                            {{ t2('HEADLINE') }}
                        </h2>
                        <p class="configuration-summary__cta-block-description">
                            {{ t2('DESCRIPTION') }}
                        </p>
                        <button
                            mat-flat-button
                            class="configuration-summary__cta-block-button mhp-ui-button--full-width mhp-ui-button--highlight"
                            (click)="intentSubmitToSalesforceOrVoe()"
                        >
                            <span>{{ t2('SUBMIT') }}</span>
                        </button>
                    </ng-container>
                    <ng-template #noSubmitPossible>
                        <h2 class="configuration-summary__cta-block-header">
                            {{ t2('HEADLINE') }}
                        </h2>
                        <p class="configuration-summary__cta-block-description">
                            {{ t2('DESCRIPTION_NO_ORDER_POSSIBLE') }}
                        </p>
                    </ng-template>
                </div>
                <!-- book testdrive -->

                <!-- configuration-summary-pdf -->
                <div
                    class="configuration-summary__cta-block"
                    *transloco="let t2; read: 'SUMMARY.CONFIGURATION_SUMMARY'"
                >
                    <h2
                        class="configuration-summary__cta-block-header"
                        [innerHTML]="
                            t2('HEADLINE', {
                                modelname:
                                    labelHelperService.getActiveModelAndProductName$()
                                    | async
                            })
                        "
                    ></h2>
                    <ng-container *mhpHideForSecretDerivative>
                        <ng-container
                            *ngIf="
                                (isBrochureAccessPossible$() | async) === true
                            "
                        >
                            <p
                                class="configuration-summary__cta-block-description"
                            >
                                {{ t2('DESCRIPTION') }}
                            </p>
                            <button
                                mat-flat-button
                                [disabled]="
                                    (serverCallInProgress$ | async) === true
                                "
                                class="configuration-summary__cta-block-button mhp-ui-button--full-width mhp-ui-button--fade"
                                (click)="intentRequestPdfAsEmail()"
                            >
                                <span>{{ t2('SEND_EMAIL') }}</span>
                            </button>
                            <button
                                mat-flat-button
                                [disabled]="
                                    (serverCallInProgress$ | async) === true
                                "
                                class="configuration-summary__cta-block-button mhp-ui-button--full-width mhp-ui-button--fade"
                                (click)="intentDownloadPdf()"
                            >
                                <span>{{ t2('DOWNLOAD') }}</span>
                            </button>
                        </ng-container>
                    </ng-container>
                    <button
                        mat-flat-button
                        [disabled]="(serverCallInProgress$ | async) === true"
                        class="configuration-summary__cta-block-button mhp-ui-button--full-width mhp-ui-button--fade"
                        (click)="intentShowConfigurationCode()"
                    >
                        <span>{{ t2('CTA_SHOW_CONFIGURATION_CODE') }}</span>
                    </button>
                    <span
                        class="configuration-summary__cta-block-recaptcha-text"
                        *transloco="let t"
                        [innerHTML]="
                            t('COMMON.RECAPTCHA.TOS') | markdown | async
                        "
                    ></span>

                    <ng-container *ngIf="IS_DEALER_BUILD">
                        <ng-container *mhpHideForSecretDerivative>
                            <h2 class="configuration-summary__cta-block-header">
                                {{ t2('BEAUTY_SHOTS') }}
                            </h2>

                            <button
                                mat-flat-button
                                [disabled]="
                                    (serverCallInProgress$ | async) === true
                                "
                                class="configuration-summary__cta-block-button mhp-ui-button--full-width mhp-ui-button--fade"
                                (click)="intentDownloadBeautyShots()"
                            >
                                <span>{{
                                    t2('CTA_DOWNLOAD_BEAUTY_SHOTS')
                                }}</span>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>

                <!-- share -->
                <div
                    *mhpHideForSecretDerivative
                    class="configuration-summary__cta-block configuration-summary__cta-block--secondary"
                >
                    <h2
                        class="configuration-summary__cta-block-header"
                        [innerHTML]="
                            t('SHARE_CONFIGURATION', {
                                modelname:
                                    labelHelperService.getActiveModelAndProductName$()
                                    | async
                            })
                        "
                    ></h2>
                    <mhp-social-sharing-bar
                        (share)="intentShare($event)"
                    ></mhp-social-sharing-bar>
                </div>

                <ng-container
                    *ngIf="getConfigurationUrl$() | async as configurationUrl"
                >
                    <div
                        *ngxClipboardIfSupported
                        class="configuration-summary__cta-block configuration-summary__cta-block--secondary"
                    >
                        <button
                            class="mhp-ui-button--full-width mhp-ui-button--fade"
                            mat-flat-button
                            [disabled]="(serverCallInProgress$ | async) === true"
                            (click)="intentCopyToClipboard()"
                        >
                            <span>{{ t('YOUR_CONFIGURATION') }}</span>
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <mhp-footer
        [regionAndLanguageSelectionInterceptor]="
            regionAndLanguageSelectorInterceptor
        "
        [regionAndLanguageSelectionOptions]="regionAndLanguageSelectionOptions"
        [scrollToTopAnchor]="scrollToTopAnchor"
    ></mhp-footer>
</ng-container>
