import { isNil } from 'lodash-es';
import {
    EMPTY,
    Observable,
    combineLatest,
    firstValueFrom,
    map,
    of,
    startWith,
    switchMap,
    take,
    tap
} from 'rxjs';
import { catchError, filter } from 'rxjs/operators';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewContainerRef
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { Opportunity } from '@mhp/aml-shared/data-proxy/salesforce-opportunity-handling.interface';
import { getModelCodeForDerivateCode } from '@mhp/aml-shared/derivate-mapping/derivate-mapping';
import {
    DealerInfoService,
    PricingType,
    SessionUrlService
} from '@mhp/aml-ui-shared-services';
import { MemoizeObservable, NotFoundError, lazyShareReplay } from '@mhp/common';
import {
    UiBaseComponent,
    UiMatDialogService,
    UiNavigationEntry,
    UiNotificationService
} from '@mhp/ui-components';
import {
    ApplicationStateService,
    CommonDialogsService,
    DialogButtonType,
    ErrorHandlerService,
    I18nService,
    L10nService,
    downloadFile
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../environments/environment';
import {
    ROUTE_MODEL_SELECT,
    ROUTE_ORDER_EDIT,
    ROUTE_ORDER_MANAGEMENT,
    ROUTE_ORDER_OVERVIEW
} from '../../app-route-names';
import { setActiveLoadingState } from '../../common/loading-indicator/state';
import { CONFIGURATION_MENU_SIDEBAR_ENTRY_MAX_ITEMS_MOBILE_PORTRAIT } from '../../configuration/configuration-menu/configuration-menu.constants';
import { CpqSessionHandlerService } from '../../configuration/cpq/cpq-session-handler.service';
import {
    DEALER_HOOKS_TOKEN,
    DealerHooks,
    DealerInfo,
    selectDealerInfo
} from '../../dealer';
import { PricingService } from '../../dealer/pricing/pricing.service';
import { AmlProductDataService } from '../../product-data/aml-product-data-service';
import { LocalApplicationState } from '../../state';
import {
    OrderNewVehicleError,
    UpdateOrderError
} from '../errors/order-management.errors';
import {
    FormGroupChange,
    OrderConfiguration,
    OrderFormGroup,
    OrderPriority
} from '../model/order-management.models';
import { OrderManagementService } from '../order-management.service';
import {
    convertToConfigModel,
    toOrderConfiguration
} from '../order-management.service.helper';
import { CopyConfigurationDialogComponent } from './copy-configuration-dialog/copy-configuration-dialog.component';

const NAVBAR_ITEM_PRICING_ID = 'Pricing';
const NAVBAR_ITEM_PRINT_ID = 'Print';
const NAVBAR_ITEM_EXPORT_ID = 'Export';

const FIELDS_TO_VERIFY = ['LANG', 'HBK', 'FPF'];

// mock price
const PRICE_DETAILS = [
    { label: 'Model Price', netValue: '0.00', grossValue: '0.00' },
    { label: 'Environment Price', netValue: '0.00', grossValue: '0.00' },
    { label: 'Option Price', netValue: '0.00', grossValue: '0.00' }
];

const RETAIL_ORDER_PRIORITIES = ['100'];

const CPQ_COUNTRY_FIELD_IDENTIFIER = 'Country';

const QUERY_PARAM_EDIT = 'edit';
const QUERY_PARAM_ORDER_ID = 'orderid';

@UntilDestroy()
@Component({
    selector: 'mhp-order-edit',
    templateUrl: './order-edit.component.html',
    styleUrls: ['./order-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderEditComponent
    extends UiBaseComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input()
    formId: string;

    orderFormGroup: FormGroup;

    configData?: OrderConfiguration;

    configurationDetailID: string | undefined;

    isOrderable: boolean;

    forceOrderDisabled: { from: Date; to: Date } | undefined;

    targetShowPricingDNP: boolean;

    targetShowPricingRRP: boolean;

    countryCode: string;

    currencyType: string;

    modelCodeList: string[];

    orderPriorityList: OrderPriority[];

    opportunityList: Opportunity[];

    showLoader = false;

    showOpportunity = false;

    readonly priceDetails = PRICE_DETAILS;

    private readonly sidebarItemPricing = <UiNavigationEntry<string>>{
        id: NAVBAR_ITEM_PRICING_ID,
        label: translate('ICONS.PRICING'),
        iconId: 'mhp-ui:pricing'
    };

    private readonly sidebarItemPrint = <UiNavigationEntry<string>>{
        id: NAVBAR_ITEM_PRINT_ID,
        label: translate('ICONS.PRINT'),
        iconId: 'mhp-ui:print'
    };

    private readonly sidebarItemExport = <UiNavigationEntry<string>>{
        id: NAVBAR_ITEM_EXPORT_ID,
        label: translate('ICONS.EXPORT_CSV'),
        iconId: 'mhp-ui:download'
    };

    constructor(
        private readonly i18nService: I18nService,
        private readonly l10nService: L10nService,
        private readonly orderManagementService: OrderManagementService,
        private readonly dealerInfoService: DealerInfoService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly cdr: ChangeDetectorRef,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly uiNotificationService: UiNotificationService,
        private readonly dialogService: UiMatDialogService,
        private readonly commonDialogsService: CommonDialogsService,
        private readonly breakpointObserver: BreakpointObserver,
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly cpqSessionHandlerService: CpqSessionHandlerService,
        private readonly location: Location,
        private readonly pricingService: PricingService,
        private readonly productDataService: AmlProductDataService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly sessionUrlService: SessionUrlService
    ) {
        super();

        this.unsubscribeOnDestroy(
            this.getCPQModelCodes$().subscribe((modelCodes) => {
                this.modelCodeList = modelCodes;
                this.cdr.markForCheck();
            }),
            this.getOrderPriority$().subscribe((priorities) => {
                this.orderPriorityList = priorities;
                this.cdr.markForCheck();
            }),
            this.getOpportunityList$().subscribe((opportunities) => {
                this.opportunityList = opportunities;
                this.cdr.markForCheck();
            }),
            this.getCountryCode$()
                .pipe()
                .subscribe((code) => {
                    this.countryCode = code;
                    this.cdr.markForCheck();
                })
        );

        this.initSubmitOrderTemporaryUnavailable();
        this.initLoadingStateBindings();
    }

    ngOnInit() {
        this.initializeOrderEdit();
    }

    ngAfterViewInit(): void {
        this.isEditable$()
            .pipe(take(1))
            .subscribe((isEditbale) => this.toggleFormControls(isEditbale));
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.restartConfigurationSession();
        this.orderManagementService.resetExistingConfigDetailId();
    }

    @MemoizeObservable()
    getCountryCode$(): Observable<string> {
        return this.l10nService.getActiveCountry$().pipe(
            filter((activeCountry): activeCountry is string => !!activeCountry),
            map((activeCountry) => activeCountry)
        );
    }

    checkForSalesOrderId(): void {
        const orderIdParam =
            this.activatedRoute.snapshot.queryParamMap.get(
                QUERY_PARAM_ORDER_ID
            );
        const editMode =
            this.activatedRoute.snapshot.queryParamMap.get(QUERY_PARAM_EDIT) !==
            'false';

        if (orderIdParam) {
            this.showLoader = true;

            combineLatest([
                this.orderManagementService.getExistingSalesOrderConfig$(
                    orderIdParam,
                    editMode
                ),
                this.isEditable$()
            ])
                .pipe(take(1))
                .subscribe({
                    next: ([config, isEditable]) => {
                        this.setConfigAndCreateForm(config, true);
                        const model = getModelCodeForDerivateCode(
                            config.derivative ?? ''
                        );
                        this.orderFormGroup.controls.modelCode.setValue(model);
                        this.orderFormGroup.controls.orderPriority.setValue(
                            config.orderPriority
                        );
                        this.toggleFormControls(isEditable);
                        this.showLoader = false;
                        this.cdr.markForCheck();
                    },
                    error: () => {
                        this.showLoader = false;
                        this.cdr.markForCheck();
                        this.navigateToNewOrder();
                    }
                });
        }
    }

    createOrderFormGroup(forceNew = false): void {
        const existingModelCode =
            this.orderManagementService.getExistingModelCode();
        const existingOpportunityId =
            this.orderManagementService.getExistingOpportunityId();
        const existingConfiguration =
            this.orderManagementService.getExisitingConfig();
        if (existingConfiguration) {
            this.configData = existingConfiguration;
        }
        if (!this.orderFormGroup || forceNew) {
            const initialForm = {
                // TODO set default value if order is existing
                opportunityId: new FormControl<string>(
                    existingOpportunityId ?? '',
                    Validators.required
                ),
                orderPriority: new FormControl(undefined, Validators.required),
                modelCode: new FormControl(
                    existingModelCode,
                    Validators.required
                )
            };

            // in case the opportunity-id is actively changed within the form, clear it application-wide
            initialForm.opportunityId.valueChanges
                .pipe(
                    filter(
                        (formOpportunityId) =>
                            !!existingOpportunityId &&
                            !!formOpportunityId &&
                            formOpportunityId !== existingOpportunityId
                    ),
                    this.takeUntilDestroy()
                )
                .subscribe(() =>
                    this.orderManagementService.resetExistingOpportunityId()
                );

            this.orderFormGroup = new FormGroup(initialForm);
        } else if (this.orderFormGroup && this.configData) {
            this.setDynamicOrderFormGroup$();
        }

        if (this.configData && existingConfiguration) {
            this.setDynamicOrderFormGroup$();
            this.orderManagementService.resetExistingConfig();
            this.orderManagementService.resetExistingModelCode();
        }
    }

    setDynamicOrderFormGroup$() {
        this.configData?.formGroups.forEach((formGroup) => {
            if (formGroup.formOption) {
                const formGroupLabel = formGroup.formOption.formGroupTitle;
                const { currentValue } = formGroup.formOption;
                const isDisabled = !formGroup.formOption.isEnabled;
                const { isRequired } = formGroup.formOption;
                const formGroupOptions = formGroup.formOption.options;
                if (currentValue) {
                    this.orderFormGroup.setControl(
                        formGroupLabel,
                        new FormControl(
                            {
                                value: currentValue,
                                disabled: isDisabled
                            },
                            isRequired ? Validators.required : undefined
                        )
                    );
                } else if (formGroupOptions.length === 1) {
                    this.orderFormGroup.setControl(
                        formGroupLabel,
                        new FormControl(
                            {
                                value: isRequired
                                    ? formGroupOptions[0].value
                                    : '',
                                disabled: isDisabled
                            },
                            isRequired ? Validators.required : undefined
                        )
                    );
                } else {
                    this.orderFormGroup.setControl(
                        formGroupLabel,
                        new FormControl(
                            {
                                value: '',
                                disabled: isDisabled
                            },
                            isRequired ? Validators.required : undefined
                        )
                    );
                }
                if (
                    formGroup.formOption.id ===
                        this.configData?.optionToFocus &&
                    !this.configData.isExecutionComplete
                ) {
                    this.configData.optionNameToFocus = formGroupLabel;
                    this.cdr.markForCheck();
                }
            }
        });
    }

    @MemoizeObservable()
    isEditable$(): Observable<boolean> {
        return this.activatedRoute.queryParamMap.pipe(
            map((paramMap: ParamMap) => {
                const editParam = paramMap.get(QUERY_PARAM_EDIT);
                const orderIdParam = paramMap.get(QUERY_PARAM_ORDER_ID);
                return editParam === 'true' || (!editParam && !orderIdParam);
            }),
            this.takeUntilDestroy()
        );
    }

    /**
     * An order is amendable if
     * - we are working on an existing order-id
     */
    @MemoizeObservable()
    isAmendable$(): Observable<boolean> {
        return this.getActiveOrderId$().pipe(map((orderId) => !!orderId));
    }

    @MemoizeObservable()
    getCPQModelCodes$(): Observable<string[]> {
        return this.orderManagementService.getCPQModelCodes$();
    }

    @MemoizeObservable()
    getOrderPriority$(): Observable<OrderPriority[]> {
        return this.orderManagementService.getOrderPriority$();
    }

    @MemoizeObservable()
    getActiveOrderId$(): Observable<string | undefined> {
        return this.activatedRoute.queryParamMap.pipe(
            map(
                (queryParams) =>
                    queryParams.get(QUERY_PARAM_ORDER_ID) ?? undefined
            )
        );
    }

    @MemoizeObservable()
    getDealerInformation$(): Observable<DealerInfo | undefined> {
        // the first time, we request dealer-information, we ensure we get fresh data here
        this.dealerInfoService.invalidateActiveDealerInfo();

        return this.applicationStateService
            .getLocalState()
            .pipe(selectDealerInfo);
    }

    @MemoizeObservable()
    getOpportunityList$(): Observable<Opportunity[]> {
        return this.orderManagementService.getOpportunityList$();
    }

    onModelChange(): void {
        const { modelCode } = this.orderFormGroup.value;
        this.handleServerCallInProgress(
            this.orderManagementService.postStartConfiguration(
                modelCode,
                this.countryCode
            )
        ).subscribe((response) => {
            this.setConfigAndCreateForm(response, true);
        });
    }

    onPriorityChange(orderPriority: string): void {
        const isRetail = RETAIL_ORDER_PRIORITIES.includes(orderPriority);
        const opportunityId =
            this.orderFormGroup.value.opportunityId ??
            this.orderManagementService.getExistingOpportunityId();
        if (isRetail) {
            this.showOpportunity = true;
            this.orderFormGroup.controls.opportunityId.markAsTouched();
            this.orderFormGroup.controls.opportunityId.setValue(opportunityId);

            if (opportunityId) {
                this.onOpportunityChange(opportunityId);
            }
        } else {
            this.showOpportunity = false;
            this.orderFormGroup.controls.opportunityId.setValue(undefined);
        }
        this.isOrderable = this.canConfirmOrder();
    }

    onOpportunityChange(opportunityId: string): void {
        this.isOrderable = this.canConfirmOrder();
        if (this.orderFormGroup.controls.modelCode.value) return;

        this.handleServerCallInProgress(
            this.orderManagementService
                .getOpportunityDetails$(opportunityId)
                .pipe(take(1))
        ).subscribe((opportunity: any) => {
            const productId = opportunity.products?.[0]?.code;
            if (productId) {
                const cpqModelCode = getModelCodeForDerivateCode(productId);
                this.orderFormGroup.controls.modelCode.setValue(cpqModelCode);
                this.onModelChange();
            }
        });
    }

    onConfigurationChange({ id, label }: FormGroupChange): void {
        if (!this.configData) {
            return;
        }
        const accessoriesAndSummaryOptionCodes =
            this.getOptionCodesForAccessoriesAndSummaryCategories(
                this.configData.formGroups
            );
        const selectedAccessoriesAndSummaryValues: string[] = Object.entries(
            this.orderFormGroup.value
        )
            .filter(
                ([key, value]) =>
                    accessoriesAndSummaryOptionCodes.has(key) && !!value
            )
            .map(([, value]) => value as string);

        const { modelCode } = this.orderFormGroup.value;
        const formValue = this.orderFormGroup.value[label];

        this.handleServerCallInProgress(
            this.orderManagementService.postConfigure$(
                modelCode,
                this.configData.sessionID,
                id,
                formValue,
                selectedAccessoriesAndSummaryValues
            )
        )
            .pipe(
                catchError((error: UpdateOrderError) => {
                    this.uiNotificationService.showError(
                        error.message,
                        undefined,
                        {
                            duration: 5000
                        }
                    );
                    if (error.details) {
                        // error-response contains updated configuration info
                        return of(error.details).pipe(
                            toOrderConfiguration(this.productDataService)
                        );
                    }
                    return EMPTY;
                })
            )
            .subscribe((response) => {
                this.setConfigAndCreateForm(response);
            });
    }

    goBackToPrevious() {
        this.location.back();
    }

    /**
     * Navigate back to the visual configurator.
     * Try to map the current configuration and route the user to the configuration-view or, if data is missing, route back
     * to model-selection.
     */
    navigateToVisualConfigurator() {
        if (this.configData && this.configData.derivative) {
            const currentConfigModel = convertToConfigModel(this.configData);

            const { derivative } = this.configData;
            const countryCode = this.countryCode;

            this.productDataService
                .getProductInfo$(derivative, countryCode)
                .pipe(
                    take(1),
                    switchMap((productInfo) => {
                        if (!productInfo) {
                            throw new NotFoundError(
                                `No Product found for derivative ${derivative}`
                            );
                        }

                        const sessionState = {
                            country: countryCode,
                            config: {
                                id: derivative,
                                options: {
                                    config: currentConfigModel
                                }
                            }
                        };

                        const configurationRouteSegments =
                            this.sessionUrlService.buildUrlCommands(
                                sessionState
                            );
                        return this.router.navigate(configurationRouteSegments);
                    }),
                    catchError(() => {
                        return this.router.navigateByUrl(
                            `INT/${ROUTE_MODEL_SELECT}`
                        );
                    })
                )
                .subscribe();
        } else {
            this.router.navigateByUrl(`INT/${ROUTE_MODEL_SELECT}`);
        }
    }

    navigateToOMOverview() {
        this.router.navigateByUrl(
            `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_OVERVIEW}`
        );
    }

    navigateToNewOrder() {
        this.router.navigateByUrl(
            `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_EDIT}`
        );
    }

    /**
     * Update an existing order with the current configuration.
     */
    intentAmendExistingOrder() {
        this.intentSubmitOrder({
            updateExistingOrder: true
        });
    }

    /**
     * Save the current configuration as new order.
     */
    intentSubmitOrder(options?: {
        // should the user be asked for how many copies should be created?
        askForCopyCount?: boolean;
        // should an existing orderId be passed to the backend to update the according order?
        updateExistingOrder?: boolean;
    }) {
        if (!this.configData) {
            return;
        }

        let copyCountWorkflow$: Observable<number | undefined> = of(1);
        if (options?.askForCopyCount) {
            copyCountWorkflow$ = this.dialogService
                .open$(CopyConfigurationDialogComponent)
                .pipe(
                    switchMap((dialogRef) => dialogRef.afterClosed()),
                    take(1)
                );
        }

        const formGroupValue = this.orderFormGroup.value;
        const { modelCode } = formGroupValue;
        const { opportunityId } = formGroupValue;
        const { orderPriority } = formGroupValue;
        const { countryCode } = this;
        const sessionId = this.configData.sessionID;
        const configurationDetailId = this.configurationDetailID;
        const orderId = options?.updateExistingOrder
            ? this.activatedRoute.snapshot.queryParamMap.get(
                  QUERY_PARAM_ORDER_ID
              ) ?? undefined
            : undefined;
        const accessoriesAndSummarySelections: string[] =
            this.pickAccessoriesAndSummary(this.configData.formGroups);
        let waitForSubmitNotificationRef: MatSnackBarRef<unknown>;

        copyCountWorkflow$
            .pipe(
                switchMap((numberOfCopies) => {
                    if (isNil(numberOfCopies)) {
                        // user has canceled the number-of-copies-selection
                        return EMPTY;
                    }

                    this.isOrderable = false;
                    this.cdr.markForCheck();

                    waitForSubmitNotificationRef =
                        this.uiNotificationService.showNotification(
                            translate(
                                'ORDER_MANAGEMENT.NOTIFICATION.SUBMIT_ORDER'
                            )
                        );

                    return this.orderManagementService.createNewOrder(
                        sessionId,
                        configurationDetailId,
                        modelCode,
                        accessoriesAndSummarySelections,
                        opportunityId,
                        orderPriority,
                        countryCode,
                        orderId,
                        numberOfCopies
                    );
                }),
                // handle success case
                switchMap(() => {
                    // dismiss notification
                    waitForSubmitNotificationRef?.dismiss();
                    // open save-order-follow-up-dialog to ask user on how we should proceed
                    return this.commonDialogsService.openAdvancedConfirmDialog$(
                        translate(
                            'ORDER_MANAGEMENT.DETAIL_VIEW.ORDER_FOLLOWUP_DIALOG.HEADLINE'
                        ),
                        translate(
                            'ORDER_MANAGEMENT.DETAIL_VIEW.ORDER_FOLLOWUP_DIALOG.DESCRIPTION'
                        ),
                        [
                            {
                                id: 'FINISH',
                                type: DialogButtonType.SECONDARY,
                                label: translate(
                                    'ORDER_MANAGEMENT.DETAIL_VIEW.ORDER_FOLLOWUP_DIALOG.CTA_FINISH'
                                )
                            },
                            {
                                id: 'COPY',
                                type: DialogButtonType.PRIMARY,
                                label: translate(
                                    'ORDER_MANAGEMENT.DETAIL_VIEW.ORDER_FOLLOWUP_DIALOG.CTA_COPY_ORDER'
                                )
                            },
                            {
                                id: 'NEW',
                                type: DialogButtonType.PRIMARY,
                                label: translate(
                                    'ORDER_MANAGEMENT.DETAIL_VIEW.ORDER_FOLLOWUP_DIALOG.CTA_NEW_ORDER'
                                )
                            }
                        ],
                        {
                            dialogOptions: {
                                width: '900px'
                            }
                        }
                    );
                }),
                take(1),
                map((result) => result.result as 'CANCEL' | 'COPY' | 'NEW'),
                switchMap(async (followUpAction) => {
                    // no have a look on how to proceed
                    if (followUpAction === 'CANCEL') {
                        // go back to the configurator
                        this.intentCancel();
                    } else if (followUpAction === 'COPY') {
                        // continue working with the current configuration as-is to be able to save it again
                        try {
                            this.orderFormGroup.disable();
                            const orderConfiguration = await firstValueFrom(
                                this.orderManagementService.postStartConfiguration(
                                    modelCode,
                                    countryCode,
                                    accessoriesAndSummarySelections,
                                    configurationDetailId
                                )
                            );
                            this.setConfigAndCreateForm(
                                orderConfiguration,
                                true
                            );
                        } finally {
                            this.orderFormGroup.enable();
                        }
                    } else {
                        // start with a new configuration
                        this.orderManagementService.resetConfigurationState();
                        // reset local configData
                        this.configData = undefined;
                        this.initializeOrderEdit(true);
                        this.router.navigateByUrl(
                            `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_EDIT}`,
                            {
                                onSameUrlNavigation: 'reload'
                            }
                        );
                    }

                    return of(undefined);
                }),
                // handle error-case
                catchError(async (err: OrderNewVehicleError) => {
                    console.error(err);
                    if (err.message.includes('orderPriority')) {
                        this.uiNotificationService.showError(
                            translate(
                                'ORDER_MANAGEMENT.NOTIFICATION.MISSING_ORDER_PRIORITY'
                            )
                        );
                    } else if (err.details) {
                        this.uiNotificationService.showError(
                            `${err.message}. Please try again.`
                        );
                    }

                    if (err.details) {
                        // use payload provided in error-response to re-initialize form-model
                        const orderConfiguration = await firstValueFrom(
                            of(err.details).pipe(
                                toOrderConfiguration(this.productDataService)
                            )
                        );

                        this.setConfigAndCreateForm(orderConfiguration, true);
                    }

                    this.isOrderable = true;
                    this.cdr.markForCheck();

                    throw err;
                })
            )
            .subscribe(() => {
                this.cdr.markForCheck();
            });
    }

    intentCancel() {
        this.router.navigateByUrl(`/INT/model-select`);
    }

    @MemoizeObservable()
    getSidebarItems$(): Observable<UiNavigationEntry<string>[]> {
        return combineLatest([
            this.getAllSidebarItemsAdjusted$(),
            this.breakpointObserver.observe([Breakpoints.HandsetPortrait])
        ]).pipe(
            map(([allSidebarItems, breakpointState]) =>
                allSidebarItems
                    /** On mobile portrait breakpoint, show a maximum no of 6 icons */
                    .filter((item, index) =>
                        breakpointState.matches
                            ? index <
                              CONFIGURATION_MENU_SIDEBAR_ENTRY_MAX_ITEMS_MOBILE_PORTRAIT
                            : true
                    )
            )
        );
    }

    @MemoizeObservable()
    private getAllSidebarItemsAdjusted$(): Observable<
        UiNavigationEntry<string>[]
    > {
        return combineLatest([
            this.i18nService.getActiveLang$(),
            this.breakpointObserver.observe([Breakpoints.HandsetPortrait])
        ]).pipe(
            map(() => {
                const sidebarItems = [
                    this.sidebarItemPricing,
                    this.sidebarItemPrint,
                    this.sidebarItemExport
                ].filter((item): item is UiNavigationEntry<string> => !!item);

                return this.dealerHooksService.adjustSidebarItems(
                    sidebarItems,
                    'ORDER-MANAGEMENT'
                );
            }),
            lazyShareReplay()
        );
    }

    intentSidebarItemSelect(itemId: string): void {
        if (
            this.dealerHooksService.handleSidebarItemSelect(
                itemId,
                'ORDER-MANAGEMENT'
            )
        ) {
            return;
        }

        switch (itemId) {
            case NAVBAR_ITEM_PRICING_ID:
                this.setPricingOptions();
                break;
            case NAVBAR_ITEM_PRINT_ID:
                window.print();
                break;
            case NAVBAR_ITEM_EXPORT_ID:
                this.intentExportOrder();
                break;
            default:
                break;
        }
    }

    setPricingOptions() {
        if (!this.configData) {
            return;
        }

        this.orderManagementService
            .openPricingDialog$(
                this.targetShowPricingDNP,
                this.targetShowPricingRRP,
                this.orderFormGroup.value[CPQ_COUNTRY_FIELD_IDENTIFIER] ??
                    this.countryCode,
                this.configData.derivative,
                this.viewContainerRef
            )
            .pipe(
                take(1),
                switchMap((componentinstance) => {
                    this.targetShowPricingDNP =
                        componentinstance.showPricingDNP;
                    this.targetShowPricingRRP =
                        componentinstance.showPricingRRP;
                    this.cdr.markForCheck();
                    return '';
                }),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    @MemoizeObservable()
    getAvailablePricingTypes$(): Observable<PricingType[]> {
        return this.orderFormGroup.valueChanges.pipe(
            startWith(this.orderFormGroup.value),
            map(
                (formGroupValue) =>
                    formGroupValue[CPQ_COUNTRY_FIELD_IDENTIFIER] ??
                    this.countryCode
            ),
            switchMap((referenceCountry) =>
                this.pricingService.getAvailablePricingTypesUsingReferenceCountry$(
                    this.configData?.derivative,
                    referenceCountry
                )
            ),
            lazyShareReplay()
        );
    }

    private openFormFieldDialog(): void {
        if (!this.configData) {
            return;
        }

        const dialogConfigData = {
            ...this.configData,
            formGroups: this.configData.formGroups.filter(
                (value) =>
                    value.formOption &&
                    FIELDS_TO_VERIFY.includes(value.formOption.formGroupTitle)
            )
        };

        this.orderManagementService
            .openFormFieldDialog$(
                dialogConfigData,
                this.orderFormGroup,
                this.viewContainerRef
            )
            .pipe(
                take(1),
                tap((result) => {
                    Object.keys(result).forEach((label) => {
                        this.orderFormGroup.controls[label].setValue(
                            result[label].value
                        );
                        this.onConfigurationChange({
                            id: result[label].id,
                            label
                        });
                    });
                })
            )
            .subscribe();
    }

    private setConfigAndCreateForm(
        config: OrderConfiguration,
        updateConfigurationDetailId = false
    ): void {
        this.configData = config;
        if (updateConfigurationDetailId) {
            this.configurationDetailID =
                config.configurationDetailID || 'empty';
        }
        this.isOrderable = this.canConfirmOrder();
        this.createOrderFormGroup();
        this.cdr.markForCheck();
    }

    private toggleFormControls(isEditable: boolean): void {
        Object.entries(this.orderFormGroup.controls).forEach(
            ([formControlId, formControl]) => {
                const isDisabled =
                    !isEditable ||
                    !!this.configData?.formGroups?.find(
                        (formGroup) =>
                            formGroup.formOption?.formGroupTitle ===
                                formControlId &&
                            !formGroup.formOption?.isEnabled
                    );

                if (isDisabled) {
                    formControl.disable();
                } else {
                    formControl.enable();
                }
            }
        );
    }

    private resetExistingSession(): void {
        this.cpqSessionHandlerService.resetConfigurationSession();
    }

    private restartConfigurationSession() {
        this.cpqSessionHandlerService.restartCurrentConfigurationSession();
    }

    private canConfirmOrder(): boolean {
        const opportunity = this.showOpportunity
            ? !!this.orderFormGroup.value.opportunityId
            : true;
        return !!(
            this.configData?.isExecutionComplete &&
            this.orderFormGroup.value.orderPriority &&
            opportunity
        );
    }

    private getOptionCodesForCategories(
        orderFormGroups: OrderFormGroup[],
        categoryNames: Set<string>
    ): Set<string> {
        let currentContext = '';
        const optionCodes = orderFormGroups
            .map((orderFormGroup) => {
                if (!orderFormGroup.formOption) {
                    currentContext = orderFormGroup.caption;
                } else if (categoryNames.has(currentContext)) {
                    return orderFormGroup.formOption.formGroupTitle;
                }
                return undefined;
            })
            .filter((optionCode): optionCode is string => !!optionCode);

        return new Set<string>(optionCodes);
    }

    private getOptionCodesForAccessoriesAndSummaryCategories(
        orderFormGroups: OrderFormGroup[]
    ) {
        return this.getOptionCodesForCategories(
            orderFormGroups,
            new Set<string>([
                environment.appConfig.configuration.identifierAccessories,
                environment.appConfig.configuration.identifierSummary
            ])
        );
    }

    private pickAccessoriesAndSummary(
        orderFormGroups: OrderFormGroup[]
    ): string[] {
        const relevantOptionCodes =
            this.getOptionCodesForAccessoriesAndSummaryCategories(
                orderFormGroups
            );

        return orderFormGroups
            .filter(
                (orderFormGroup) =>
                    !!orderFormGroup.formOption?.currentValue &&
                    orderFormGroup.formOption?.formGroupTitle &&
                    relevantOptionCodes.has(
                        orderFormGroup.formOption?.formGroupTitle
                    )
            )
            .map((orderFormGroup) => orderFormGroup?.formOption?.currentValue)
            .filter((value): value is string => !!value);
    }

    private initializeOrderEdit(forceNewOrderFormGroup = false) {
        const openDialog = !!this.orderManagementService.getExisitingConfig();
        this.createOrderFormGroup(forceNewOrderFormGroup);
        this.checkForSalesOrderId();
        this.isOrderable = this.canConfirmOrder();

        this.configurationDetailID =
            this.orderManagementService.getExistingConfigDetailsId();

        // reset session if existing
        this.resetExistingSession();

        // open dialog if necessary
        if (openDialog) {
            this.openFormFieldDialog();
        }
    }

    /**
     * Initialize logic to display hint in when submit-order becomes temporarily unavailable.
     * @see environment.appConfig.dealer.orderManagementDowntimes
     * @private
     */
    private initSubmitOrderTemporaryUnavailable() {
        const temporaryUnavailableTimeSpans: { from: string; to: string }[] =
            environment.appConfig.dealer.orderManagementDowntimes;
        if (!temporaryUnavailableTimeSpans) {
            return;
        }

        temporaryUnavailableTimeSpans.forEach((currentTimeSpan) => {
            this.disableSubmitOrderForTimespan(currentTimeSpan);
        });
    }

    private disableSubmitOrderForTimespan(timespan: {
        from: string;
        to: string;
    }) {
        const timestampNow = Date.now();
        const timeSpanStartTimestamp = Date.parse(timespan.from);
        const timeSpanEndTimestamp = Date.parse(timespan.to);

        if (
            Number.isNaN(timeSpanStartTimestamp) ||
            Number.isNaN(timeSpanEndTimestamp)
        ) {
            console.warn(
                `Skipping defined timespan as either start or end cannot be parsed as number`,
                timespan
            );
            return;
        }

        if (timeSpanStartTimestamp >= timeSpanEndTimestamp) {
            console.warn(
                `Skipping defined timespan as start is after end`,
                timespan
            );
            return;
        }

        if (timestampNow >= timeSpanEndTimestamp) {
            // can skip this one as it's already in the past
            return;
        }

        // check if current time is in-between start and end
        if (
            (timestampNow >= timeSpanStartTimestamp &&
                timestampNow < timeSpanEndTimestamp) ||
            timeSpanStartTimestamp > timestampNow
        ) {
            // the timespan is currently active or in the near future
            const timeLeftUntilTrigger = Math.max(
                timeSpanStartTimestamp - timestampNow,
                0
            );
            const timeLeftUntilTriggerEnd = Math.max(
                timeSpanEndTimestamp - timestampNow,
                0
            );

            const timeoutStartRef = setTimeout(() => {
                // force-disable is active now
                this.forceOrderDisabled = {
                    from: new Date(timeSpanStartTimestamp),
                    to: new Date(timeSpanEndTimestamp)
                };
                this.cdr.markForCheck();
            }, timeLeftUntilTrigger);
            const timeoutEndRef = setTimeout(() => {
                // force-disable is inactive now
                this.forceOrderDisabled = undefined;
                this.cdr.markForCheck();
            }, timeLeftUntilTriggerEnd);

            this.destroy$.subscribe(() => {
                clearTimeout(timeoutStartRef);
                clearTimeout(timeoutEndRef);
            });
        }
    }

    /**
     * Initialize logic to bind local serverCallInProgress stream to application loading state..
     * @private
     */
    private initLoadingStateBindings() {
        this.serverCallInProgress$
            .pipe(untilDestroyed(this))
            .subscribe((inProgress) => {
                this.applicationStateService.dispatch(
                    setActiveLoadingState({
                        loading: inProgress,
                        statusText: translate(
                            'ORDER_MANAGEMENT.DETAIL_VIEW.UPDATING_CONFIG'
                        ),
                        showLoadingSpinnerWhenLoading: true
                    })
                );
            });
    }

    /**
     * Trigger an order-export as CSV which contains all selected options with "Option Code, Option Marketing Name".
     * @private
     */
    private intentExportOrder() {
        if (!this.configData) {
            return;
        }
        const collectedOptions: {
            name: string;
            value: unknown;
        }[] = [];
        for (const formGroup of this.configData.formGroups) {
            if (!formGroup.formOption?.currentValue) {
                continue;
            }
            collectedOptions.push({
                name: formGroup.formOption?.currentValueCaption
                    ? `${formGroup.formOption?.caption?.trim()}${formGroup.formOption?.caption?.trim()?.endsWith(':') ? '' : ':'} ${formGroup.formOption?.currentValueCaption.trim()}`
                    : '',
                value: formGroup.formOption?.currentValue
            });
        }

        const csvContent = collectedOptions
            .map(
                (currentOption) =>
                    [currentOption.value, currentOption.name]
                        .map((v: string) => v.replaceAll('"', '""')) // escape double quotes
                        .map((v) => `"${v}"`) // quote it
                        .join(',') // comma-separated
            )
            .join('\n');

        const blob = new Blob([csvContent], {
            type: 'text/csv;charset=utf-8;'
        });

        downloadFile(
            blob,
            `${new Date().toISOString().substring(0, 19)} - Order Export.csv`
        );
    }
}
