import deepLock from 'deep-lock';
import {
    EMPTY,
    Observable,
    catchError,
    map,
    merge,
    skip,
    switchMap,
    tap
} from 'rxjs';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { MemoizeObservable, distinctUntilChangedEquality } from '@mhp/common';
import { ImageSrcset } from '@mhp/ui-components';
import { ApplicationStateService } from '@mhp/ui-shared-services';
import { JSONSchema, StorageMap } from '@ngx-pwa/local-storage';

import { environment } from '../../../environments/environment';
import { LocalApplicationState } from '../../state';
import { InfoLayerService } from '../info-layer/info-layer.service';
import {
    EnvironmentInfoShownState,
    setEnvironmentInfoShown,
    updateEnvironmentInfoShown
} from '../state';

const environmentThumbnailSizes = [1024, 2048];

const ENVIRONMENTS_WITH_INFO = deepLock([
    environment.appConfig.configuration.heritageEnvironmentId,
    environment.appConfig.configuration.silverstoneEnvironmentId
]);

const STORAGE_KEY_ENVIRONMENT_INFO = 'AML_ENVIRONMENT_INFO_SHOWN';

const environmentInfoShownSchema: JSONSchema = {
    type: 'array',
    items: {
        type: 'object',
        properties: { id: { type: 'string' }, timestamp: { type: 'number' } }
    }
};

/**
 * Service providing support for showing an environment information-layer
 * if available for a given environment.
 */
@Injectable()
export class EnvironmentInfoService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly infoLayerService: InfoLayerService,
        private readonly storageMap: StorageMap
    ) {
        this.initEnvironmentInfoActivationTracking();
    }

    /**
     * Returns a list of environment-ids for which information is available.
     */
    getEnvironmentsWithInfo(): readonly string[] {
        return ENVIRONMENTS_WITH_INFO;
    }

    /**
     * Open the info-layer for a given environment-id.
     * @param environmentId The environment to show information for.
     */
    showInfoLayerForEnvironment$(environmentId: string): Observable<void> {
        if (!ENVIRONMENTS_WITH_INFO.includes(environmentId)) {
            return EMPTY;
        }

        const environmentInfoImageSrcset: ImageSrcset = {
            sources: environmentThumbnailSizes.map((size) => ({
                url: `assets/images/environment-info/${environmentId.toUpperCase()}_${size}.jpg`,
                targetWidth: size
            }))
        };

        return this.infoLayerService
            .showInfoLayer$({
                thumbnailUrls: [environmentInfoImageSrcset],
                label: translate(
                    `CONFIGURATOR.ENV_INFO.${environmentId.toUpperCase()}.TITLE`
                ),
                description: translate(
                    `CONFIGURATOR.ENV_INFO.${environmentId.toUpperCase()}.DESCRIPTION`
                ),
                itemType: 'image'
            })
            .pipe(
                tap(() =>
                    this.applicationStateService.dispatch(
                        updateEnvironmentInfoShown({
                            id: environmentId,
                            timestamp: Date.now()
                        })
                    )
                ),
                map(() => undefined)
            );
    }

    hasEnvironmentInfoBeenShownFor$(
        environmentId: string
    ): Observable<boolean> {
        return this.watchPersistedEnvironmentInfoShownState$().pipe(
            map((state) => {
                const matchingEnvironmentInfo = state.find(
                    (environmentInfoShown) =>
                        environmentInfoShown.id === environmentId
                );
                return !!(
                    matchingEnvironmentInfo &&
                    Date.now() <=
                        matchingEnvironmentInfo.timestamp +
                            environment.appConfig.configuration
                                .skipShowEnvironmentInfoDuration
                );
            })
        );
    }

    private initEnvironmentInfoActivationTracking() {
        merge(
            this.applicationStateService.getLocalState().pipe(
                map((state) => state?.configuration?.environmentInfoShown),
                distinctUntilChangedEquality(),
                skip(1)
            ),
            this.watchPersistedEnvironmentInfoShownState$()
        )
            .pipe(
                distinctUntilChangedEquality(),
                switchMap((environmentInfoShownState) => {
                    this.applicationStateService.dispatch(
                        setEnvironmentInfoShown({
                            state: environmentInfoShownState
                        })
                    );

                    return this.storageMap
                        .set(
                            STORAGE_KEY_ENVIRONMENT_INFO,
                            environmentInfoShownState
                        )
                        .pipe(
                            catchError((error) => {
                                console.error(
                                    'Failed persisting environment-info state',
                                    error
                                );
                                return EMPTY;
                            })
                        );
                })
            )
            .subscribe();
    }

    @MemoizeObservable()
    private watchPersistedEnvironmentInfoShownState$() {
        return this.storageMap
            .watch<EnvironmentInfoShownState[]>(
                STORAGE_KEY_ENVIRONMENT_INFO,
                environmentInfoShownSchema
            )
            .pipe(
                map(
                    (persistedState): EnvironmentInfoShownState[] =>
                        persistedState ?? []
                ),
                distinctUntilChangedEquality()
            );
    }
}
