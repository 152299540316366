import { Observable, from, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { IllegalStateError, NotImplementedError } from '@mhp/common';
import { EnvironmentState } from '@mhp/communication-models';
import {
    ApplicationStateService,
    CinematicCaptureInfo,
    EngineControlState,
    EngineControlStrategy,
    ProductConfigurationInfo,
    ScreenshotSource,
    selectCameraState,
    selectEnvironmentState,
    selectProductState,
    setActiveCamera,
    setActiveEngineConfiguration,
    setAnimationState,
    setEngineConfigurationState,
    setEnvironmentState
} from '@mhp/ui-shared-services';

import { LocalApplicationState } from '../../../state';
import { ScreenshotRegistryService } from '../../screenshot/screenshot-registry.service';

@Injectable({
    providedIn: 'root'
})
export class EngineControlLocalStrategy implements EngineControlStrategy {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly screenshotRegistryService: ScreenshotRegistryService
    ) {}

    applyEngineControlState$(
        engineControlState: EngineControlState
    ): Observable<void> {
        this.applicationStateService.dispatch(
            setEngineConfigurationState({
                state: engineControlState
            })
        );

        return of(undefined);
    }

    setProductConfiguration$(
        configurationInfo: ProductConfigurationInfo
    ): Observable<ProductConfigurationInfo> {
        // resolve/validate options using the ruler, then apply them to the state

        // TODO: Resolve / validate options

        this.applicationStateService.dispatch(
            setActiveEngineConfiguration({
                ...configurationInfo
            })
        );

        return this.applicationStateService.getState().pipe(
            selectProductState,
            map((productState) => {
                if (!productState) {
                    throw new IllegalStateError(
                        `Failed applying product configuration`,
                        configurationInfo
                    );
                }
                return {
                    productId: productState.id,
                    options: productState.configOptions
                };
            }),
            take(1)
        );
    }

    setActiveCamera$(cameraId: string): Observable<string> {
        this.applicationStateService.dispatch(
            setActiveCamera({
                id: cameraId
            })
        );

        return this.applicationStateService.getState().pipe(
            selectCameraState,
            map((cameraIdLocal) => {
                if (!cameraIdLocal) {
                    throw new IllegalStateError('Failed setting active camera');
                }
                return cameraIdLocal;
            }),
            take(1)
        );
    }

    setActiveEnvironmentId$(
        id: string,
        environmentState?: EnvironmentLightingProfileState
    ): Observable<string> {
        return this.setEnvironmentStateInternal$(id, environmentState).pipe(
            map((newEnvironmentState) => {
                if (!newEnvironmentState?.id) {
                    throw new IllegalStateError(
                        `Failed setting active environment to ${id}`
                    );
                }
                return newEnvironmentState.id;
            })
        );
    }

    setEnvironmentState$(
        state: EnvironmentLightingProfileState
    ): Observable<EnvironmentLightingProfileState | undefined> {
        return this.setEnvironmentStateInternal$(undefined, state).pipe(
            map((environmentState) => environmentState?.state)
        );
    }

    takeScreenshot$(): Observable<ScreenshotSource> {
        return from(this.screenshotRegistryService.getScreenshotSource()).pipe(
            map((screenshotInfo) => {
                if (!screenshotInfo) {
                    throw new IllegalStateError('Failed taking screenshot');
                }
                return screenshotInfo;
            })
        );
    }

    setAnimationState$(
        animationId: string,
        direction: 'START' | 'END'
    ): Observable<string> {
        this.applicationStateService.dispatch(
            setAnimationState({
                id: animationId,
                state: direction
            })
        );

        return of(animationId);
    }

    setCinematicState$(
        cinematicId: string,
        state: 'ACTIVE' | 'INACTIVE'
    ): Observable<string> {
        throw new NotImplementedError();
    }

    setContextOptionValue$(id: string, value: string): Observable<void> {
        throw new NotImplementedError();
    }

    setHighlightState$(
        highlightId: string,
        state: 'ACTIVE' | 'INACTIVE'
    ): Observable<string> {
        throw new NotImplementedError();
    }

    stopCinematic$(): Observable<void> {
        throw new NotImplementedError();
    }

    captureCinematic$(cinematicId: string): Observable<CinematicCaptureInfo> {
        throw new NotImplementedError();
    }

    stopHighlight$(): Observable<void> {
        throw new NotImplementedError();
    }

    private setEnvironmentStateInternal$(
        id?: string,
        dayNightState?: EnvironmentLightingProfileState
    ): Observable<EnvironmentState | undefined> {
        return this.applicationStateService.getState().pipe(
            selectEnvironmentState,
            take(1),
            map((environmentState) => {
                const checkedId = id || environmentState?.id;
                if (!checkedId) {
                    throw new IllegalStateError(
                        'Failed setting environment state as no active environment is set'
                    );
                }

                return {
                    id: checkedId,
                    state:
                        <EnvironmentLightingProfileState>dayNightState ||
                        environmentState?.state ||
                        EnvironmentLightingProfileState.DAY
                };
            }),
            tap((newEnvironmentState) => {
                this.applicationStateService.dispatch(
                    setEnvironmentState(newEnvironmentState)
                );
            }),
            switchMap(() =>
                this.applicationStateService
                    .getState()
                    .pipe(selectEnvironmentState)
            ),
            take(1)
        );
    }
}
