<mhp-dialog-header class="header" [hideTopGradient]="true"></mhp-dialog-header>
<mhp-dialog-body [noPadding]="true">
    <ng-container *transloco="let t">
        <ng-container
            *ngIf="
                getAvailableInteriorEnvironmentGroupModels$()
                    | async as availableInteriorEnvironmentGroupModels
            "
        >
            <ng-container
                *ngIf="
                    getSelectedEnvironments$() | async as selectedEnvironments
                "
            >
                <ng-container
                    *ngIf="getActiveEnvironment$() | async as activeEnvironment"
                >
                    <ng-container
                        *ngIf="isMobilePortrait$() | async; else desktop"
                    >
                        <div class="environment-select-mobile">
                            <div class="environment-select-mobile__content">
                                <div
                                    class="environment-select-mobile__scroll-container"
                                >
                                    <div
                                        class="environment-select-mobile__header"
                                    >
                                        <h2>
                                            {{
                                                t(
                                                    'INTERIOR_ENV_SELECTION.COMMON.HEADLINE_01'
                                                )
                                            }}
                                        </h2>
                                        <h1>
                                            {{
                                                t(
                                                    'INTERIOR_ENV_SELECTION.COMMON.HEADLINE_02'
                                                )
                                            }}
                                        </h1>
                                    </div>
                                    <swiper
                                        class="environment-select-mobile__swiper"
                                        [spaceBetween]="15"
                                        [centeredSlides]="true"
                                        [slidesPerView]="1.3"
                                        [resizeObserver]="true"
                                        [resistanceRatio]="0.1"
                                        [followFinger]="true"
                                        [loop]="
                                            availableInteriorEnvironmentGroupModels.length >
                                            1
                                        "
                                        [loopAdditionalSlides]="
                                            availableInteriorEnvironmentGroupModels.length
                                        "
                                        (swiper)="onSwiperInitialized($event)"
                                        (slideChange)="
                                            onSwiperSlideChanged(
                                                availableInteriorEnvironmentGroupModels,
                                                selectedEnvironments
                                            )
                                        "
                                    >
                                        <ng-template
                                            swiperSlide
                                            *ngFor="
                                                let environmentGroupModel of availableInteriorEnvironmentGroupModels;
                                                trackBy: trackById;
                                                let groupIndex = index
                                            "
                                        >
                                            <div
                                                class="slide"
                                                (click)="
                                                    swiper?.realIndex !==
                                                        groupIndex &&
                                                        goToSlide(groupIndex)
                                                "
                                            >
                                                <div class="environment-mobile">
                                                    <mhp-ui-lazyload-image
                                                        class="environment-mobile__image"
                                                        imageFit="cover"
                                                        [imageUrl]="
                                                            getActiveEnvironmentGroupImageSrcset$(
                                                                environmentGroupModel
                                                            ) | async
                                                        "
                                                        [imageFallbackUrl]="
                                                            amlImagesService.getModelSpecificFallbackImageUrl$()
                                                                | async
                                                        "
                                                        [willResize]="true"
                                                        [title]="
                                                            t(
                                                                'INTERIOR_ENV_SELECTION.COMMON.ALT_IMAGE_TEXT'
                                                            )
                                                        "
                                                    ></mhp-ui-lazyload-image>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </swiper>

                                    <div class="environment-mobile__content">
                                        <div
                                            class="pagination pagination--mobile"
                                            *ngIf="
                                                availableInteriorEnvironmentGroupModels.length >
                                                1
                                            "
                                        >
                                            <div
                                                class="pagination__container"
                                                *ngVariable="
                                                    activeIndex$
                                                        | async as activeIndex
                                                "
                                            >
                                                <div
                                                    class="pagination__dot"
                                                    [ngClass]="{
                                                        'pagination__dot--active':
                                                            activeIndex === i
                                                    }"
                                                    *ngFor="
                                                        let group of availableInteriorEnvironmentGroupModels;
                                                        trackBy: trackById;
                                                        let i = index
                                                    "
                                                    (click)="goToSlide(i)"
                                                ></div>
                                                <div
                                                    class="pagination__indicator"
                                                    [style.--offset]="
                                                        28 * activeIndex +
                                                        5 +
                                                        'px'
                                                    "
                                                ></div>
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngFor="
                                                let environmentGroupModel of availableInteriorEnvironmentGroupModels;
                                                trackBy: trackById;
                                                let groupIndex = index
                                            "
                                        >
                                            <div
                                                class="environment-mobile__description"
                                                *ngIf="
                                                    (activeIndex$ | async) ===
                                                    groupIndex
                                                "
                                                @fade
                                            >
                                                <div
                                                    class="environment-mobile__info"
                                                >
                                                    <h2
                                                        class="environment-mobile__info-heading"
                                                    >
                                                        {{
                                                            environmentGroupModel.label
                                                        }}
                                                    </h2>
                                                    <mat-form-field
                                                        *ngIf="
                                                            environmentGroupModel
                                                                .environments
                                                                .length > 1
                                                        "
                                                        class="environment-mobile__selection"
                                                    >
                                                        <mat-label>{{
                                                            t(
                                                                'INTERIOR_ENV_SELECTION.COMMON.ALT_SCROLL_INDICATORS'
                                                            )
                                                        }}</mat-label>
                                                        <mat-select
                                                            [value]="
                                                                activeEnvironment
                                                            "
                                                            [compareWith]="
                                                                identifiableComparator
                                                            "
                                                            (selectionChange)="
                                                                setActiveEnvironment(
                                                                    environmentGroupModel,
                                                                    $event.value
                                                                )
                                                            "
                                                        >
                                                            <mat-option
                                                                *ngFor="
                                                                    let environment of environmentGroupModel.environments;
                                                                    trackBy: trackById
                                                                "
                                                                [value]="
                                                                    environment
                                                                "
                                                            >
                                                                {{
                                                                    environment.label
                                                                }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <p
                                                        class="environment-mobile__info-text"
                                                    >
                                                        {{
                                                            environmentGroupModel.descriptionShort
                                                        }}
                                                    </p>
                                                    <p
                                                        class="environment-mobile__info-text"
                                                        [innerHtml]="
                                                            replaceAll(
                                                                environmentGroupModel.descriptionLong,
                                                                '\n',
                                                                '<br />'
                                                            )
                                                        "
                                                    ></p>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                            <button
                                class="environment-select-mobile__button"
                                mat-flat-button
                                *ngIf="!data?.readonly"
                                (click)="
                                    configureEnvironment(activeEnvironment)
                                "
                            >
                                <span>{{
                                    t(
                                        'INTERIOR_ENV_SELECTION.COMMON.CTA_START_CUSTOMISATION'
                                    )
                                }}</span>
                            </button>
                        </div>
                    </ng-container>
                    <ng-template #desktop>
                        <div class="environment-select-desktop">
                            <div class="environment-select-desktop__header">
                                <h2>
                                    {{
                                        t(
                                            'INTERIOR_ENV_SELECTION.COMMON.HEADLINE_01'
                                        )
                                    }}
                                </h2>
                                <h1>
                                    {{
                                        t(
                                            'INTERIOR_ENV_SELECTION.COMMON.HEADLINE_02'
                                        )
                                    }}
                                </h1>
                            </div>
                            <div class="environment-select-desktop__content">
                                <swiper
                                    class="environment-select-desktop__swiper"
                                    [spaceBetween]="40"
                                    [centeredSlides]="true"
                                    [slidesPerView]="1.5"
                                    [keyboard]="{
                                        enabled: true
                                    }"
                                    [resistanceRatio]="0.1"
                                    [resizeObserver]="true"
                                    [followFinger]="true"
                                    [loop]="
                                        availableInteriorEnvironmentGroupModels.length >
                                        1
                                    "
                                    [loopAdditionalSlides]="
                                        availableInteriorEnvironmentGroupModels.length
                                    "
                                    [grabCursor]="true"
                                    (swiper)="onSwiperInitialized($event)"
                                    (slideChange)="
                                        onSwiperSlideChanged(
                                            availableInteriorEnvironmentGroupModels,
                                            selectedEnvironments
                                        )
                                    "
                                >
                                    <ng-template
                                        swiperSlide
                                        *ngFor="
                                            let environmentGroupModel of availableInteriorEnvironmentGroupModels;
                                            trackBy: trackById;
                                            let groupIndex = index
                                        "
                                    >
                                        <div
                                            class="slide"
                                            (click)="
                                                swiper?.realIndex !==
                                                    groupIndex &&
                                                    goToSlide(groupIndex)
                                            "
                                        >
                                            <div class="environment-desktop">
                                                <mhp-ui-lazyload-image
                                                    class="environment-desktop__image"
                                                    imageFit="cover"
                                                    [imageUrl]="
                                                        getActiveEnvironmentGroupImageSrcset$(
                                                            environmentGroupModel
                                                        ) | async
                                                    "
                                                    [imageFallbackUrl]="
                                                        amlImagesService.getModelSpecificFallbackImageUrl$()
                                                            | async
                                                    "
                                                    [willResize]="true"
                                                    [title]="
                                                        t(
                                                            'INTERIOR_ENV_SELECTION.COMMON.ALT_IMAGE_TEXT'
                                                        )
                                                    "
                                                ></mhp-ui-lazyload-image>
                                                <div
                                                    class="environment-desktop__content"
                                                    *ngIf="
                                                        swiper?.realIndex ===
                                                        groupIndex
                                                    "
                                                    @fade
                                                >
                                                    <div
                                                        class="environment-desktop__description"
                                                    >
                                                        <div
                                                            class="environment-desktop__scroll"
                                                        >
                                                            <div
                                                                class="environment-desktop__info"
                                                            >
                                                                <h2
                                                                    class="environment-desktop__info-heading"
                                                                >
                                                                    {{
                                                                        environmentGroupModel.label
                                                                    }}
                                                                </h2>
                                                                <p
                                                                    class="environment-desktop__info-text"
                                                                >
                                                                    {{
                                                                        environmentGroupModel.descriptionShort
                                                                    }}
                                                                </p>
                                                                <a
                                                                    class="environment-desktop__info-link"
                                                                    href
                                                                    (click)="
                                                                        intentShowEnvironmentInfoLayer(
                                                                            environmentGroupModel,
                                                                            activeEnvironment
                                                                        )
                                                                    "
                                                                >
                                                                    {{
                                                                        t(
                                                                            'INTERIOR_ENV_SELECTION.COMMON.CTA_SEE_DETAILS'
                                                                        )
                                                                    }}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="environment-desktop__actions"
                                                    >
                                                        <div
                                                            class="environment-desktop__options"
                                                        >
                                                            <div
                                                                class="environment-desktop__option"
                                                                tabindex="0"
                                                                [ngClass]="{
                                                                    'environment-desktop__option--active':
                                                                        environment
                                                                            .optionCode
                                                                            ?.code ===
                                                                        selectedEnvironments[
                                                                            environmentGroupModel
                                                                                .id
                                                                        ]
                                                                }"
                                                                *ngFor="
                                                                    let environment of environmentGroupModel.environments;
                                                                    trackBy: trackById;
                                                                    let envIndex = index
                                                                "
                                                                (click)="
                                                                    setActiveEnvironment(
                                                                        environmentGroupModel,
                                                                        environment
                                                                    )
                                                                "
                                                                (keyup.enter)="
                                                                    setActiveEnvironment(
                                                                        environmentGroupModel,
                                                                        environment
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    environment.label
                                                                }}
                                                            </div>
                                                        </div>
                                                        <button
                                                            class="environment-desktop__button"
                                                            mat-flat-button
                                                            *ngIf="
                                                                !data?.readonly
                                                            "
                                                            (click)="
                                                                configureEnvironment(
                                                                    activeEnvironment
                                                                )
                                                            "
                                                        >
                                                            <span>{{
                                                                t(
                                                                    'INTERIOR_ENV_SELECTION.COMMON.CTA_START_CUSTOMISATION'
                                                                )
                                                            }}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                <mhp-configuration-area-action-item
                                                    class="environment-desktop__turntable-button"
                                                    svgIcon="mhp-ui:180"
                                                    iconSize="large"
                                                    [matTooltip]="
                                                        t(
                                                            'ICONS.TURNTABLE_180_ALT'
                                                        )
                                                    "
                                                    (itemSelect)="
                                                        intentShowEnvironmentTurntable(
                                                            activeEnvironment
                                                        )
                                                    "
                                                    *ngIf="
                                                        swiper?.realIndex ===
                                                        groupIndex
                                                    "
                                                    @fade
                                                ></mhp-configuration-area-action-item>
                                            </div>
                                        </div>
                                    </ng-template>
                                </swiper>
                                <div
                                    class="pagination pagination--desktop"
                                    *ngIf="
                                        availableInteriorEnvironmentGroupModels.length >
                                        1
                                    "
                                >
                                    <div
                                        class="pagination__container"
                                        *ngVariable="
                                            activeIndex$ | async as activeIndex
                                        "
                                    >
                                        <div
                                            class="pagination__dot"
                                            [ngClass]="{
                                                'pagination__dot--active':
                                                    activeIndex === i
                                            }"
                                            *ngFor="
                                                let group of availableInteriorEnvironmentGroupModels;
                                                trackBy: trackById;
                                                let i = index
                                            "
                                            (click)="goToSlide(i)"
                                        ></div>
                                        <div
                                            class="pagination__indicator"
                                            [style.--offset]="
                                                28 * activeIndex + 5 + 'px'
                                            "
                                        ></div>
                                    </div>
                                </div>
                                <div
                                    class="environment-select-desktop__arrow-navigation"
                                    *ngIf="
                                        availableInteriorEnvironmentGroupModels.length >
                                        1
                                    "
                                >
                                    <div
                                        class="environment-select-desktop__arrow-navigation--left"
                                    >
                                        <mhp-ui-icon-button
                                            svgIcon="mhp-ui:angle-left"
                                            size="large"
                                            [matTooltip]="
                                                t(
                                                    'ICONS.PREVIOUS_INTERIOR_ENVIRONMENT_ALT'
                                                )
                                            "
                                            [label]="
                                                t(
                                                    'ICONS.PREVIOUS_INTERIOR_ENVIRONMENT_ALT'
                                                )
                                            "
                                            (click)="intentPrevSlide()"
                                        ></mhp-ui-icon-button>
                                    </div>
                                    <div
                                        class="environment-select-desktop__arrow-navigation--right"
                                    >
                                        <mhp-ui-icon-button
                                            svgIcon="mhp-ui:angle-right"
                                            size="large"
                                            [matTooltip]="
                                                t(
                                                    'ICONS.NEXT_INTERIOR_ENVIRONMENT_ALT'
                                                )
                                            "
                                            [label]="
                                                t(
                                                    'ICONS.NEXT_INTERIOR_ENVIRONMENT_ALT'
                                                )
                                            "
                                            (click)="intentNextSlide()"
                                        ></mhp-ui-icon-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</mhp-dialog-body>
