import {
    EMPTY,
    Observable,
    combineLatest,
    map,
    of,
    switchMap,
    take
} from 'rxjs';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { MemoizeObservable } from '@mhp/common';
import { UiMatDialogService } from '@mhp/ui-components';
import {
    ErrorHandlerService,
    RequestReceiverType,
    SocketIOService,
    gtmGA4Track
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { BACKDROP_CLASS_BLURRY } from '../../common/dialog/dialog.constants';
import { VrDocsDialogComponent } from './vr-docs-dialog/vr-docs-dialog.component';
import { VrInfoService } from './vr-info.service';

const SOCKET_IO_REQUEST_SET_VR_MODE = 'setvrmode';
const SOCKET_IO_REQUEST_RESET_HMD_ORIENTATION_AND_POSITION =
    'resethmdorientationandposition';

/**
 * Service providing functionality around VR integration.
 * Note: Needs to be scoped in a setup where socket-communication via SocketIOService is
 * available.
 */
@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class VrService {
    constructor(
        private readonly socketIoService: SocketIOService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly dialogService: UiMatDialogService,
        private readonly vrInfoService: VrInfoService
    ) {}

    /**
     * Emits if VR is currently available or not.
     */
    @MemoizeObservable()
    isVrAvailable$(): Observable<boolean> {
        return this.vrInfoService.isVrAvailable$();
    }

    /**
     * Emits if VR is currently active or not.
     */
    @MemoizeObservable()
    isVrActive$(): Observable<boolean> {
        return this.vrInfoService.isVrActive$();
    }

    /**
     * Calibrates the headset. Noop in case VR isn't currently active.
     */
    calibrateHeadset$(): Observable<void> {
        return this.isVrActive$().pipe(
            take(1),
            switchMap((isVrActive) => {
                if (!isVrActive) {
                    return EMPTY;
                }
                return this.socketIoService
                    .request<object, void>(
                        SOCKET_IO_REQUEST_RESET_HMD_ORIENTATION_AND_POSITION,
                        {}
                    )
                    .pipe(
                        this.errorHandlerService.applyRetry({
                            endpointType: RequestReceiverType.ENGINE,
                            messageProviderOnFinalError: () =>
                                translate(
                                    'BRAND_STORE.ERRORS.FAILED_CALIBRATING_HEADSET'
                                )
                        })
                    );
            }),
            switchMap(() => EMPTY)
        );
    }

    /**
     * Toggles the VR active state. Noop in case VR isn't currently active.
     * @param forceState In case the state should be forced.
     */
    toggleVrActiveState$(forceState?: boolean): Observable<boolean> {
        return combineLatest([this.isVrAvailable$(), this.isVrActive$()]).pipe(
            take(1),
            switchMap(([isVrAvailable, isVrActive]) => {
                const targetState = forceState ?? !isVrActive;

                if (!isVrAvailable || targetState === isVrActive) {
                    return of(isVrActive);
                }

                return this.socketIoService
                    .request<
                        {
                            enabled: boolean;
                        },
                        void
                    >(SOCKET_IO_REQUEST_SET_VR_MODE, {
                        enabled: targetState
                    })
                    .pipe(
                        this.errorHandlerService.applyRetry({
                            endpointType: RequestReceiverType.ENGINE,
                            messageProviderOnFinalError: () =>
                                translate(
                                    'BRAND_STORE.ERRORS.FAILED_TOGGLING_VR'
                                )
                        }),
                        map(() => targetState)
                    );
            })
        );
    }

    /**
     * Open the vr-documentation layer.
     */
    intentOpenVrDocsDialog$() {
        gtmGA4Track('vr_docs_click');
        return this.dialogService
            .open$(VrDocsDialogComponent, {
                backdropClass: BACKDROP_CLASS_BLURRY,
                maxWidth: 1000
            })
            .pipe(untilDestroyed(this));
    }
}
