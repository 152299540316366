import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconButtonModule, UiMatDialogModule } from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../dialog/dialog.module';
import { HeaderBarModule } from '../../header-bar/header-bar.module';
import { DisplayVideoTracksModule } from '../display-video-tracks.module';
import { VideoChatDialogComponent } from './video-chat-dialog.component';

@NgModule({
    declarations: [VideoChatDialogComponent],
    imports: [
        CommonModule,

        I18nModule,

        UiIconButtonModule,
        UiMatDialogModule,

        HeaderBarModule,
        DialogModule,
        DisplayVideoTracksModule
    ],
    exports: [VideoChatDialogComponent]
})
export class VideoChatDialogModule {}
