import { translate } from '@jsverse/transloco';
import { Title } from '@mhp/aml-shared/data-proxy/salesforce-types';

const FORM_AVAILABLE_TITLES: Title[] = [
    'Mr',
    'Ms',
    'Mrs',
    'MX',
    'Dr',
    'Prof',
    'HRH',
    'Sheikh'
];

/**
 * Returns a translated & filtered list of available titles as key/value objects for forms.
 */
export const getTranslatedTitlesList = () =>
    FORM_AVAILABLE_TITLES.map((title) => ({
        key: title,
        value: translate(`FORM.TITLE.${title.toUpperCase()}`)
    })).filter(
        (entry, index, self) =>
            index ===
            self.findIndex((selfEntry) => entry.value === selfEntry.value)
    );
