/**
 * Defines the list of countries that require to show fridge labels.
 */
export const FRIDGE_LABEL_COUNTRIES = [
    'DE',
    'CH',
    'AT',
    'BE',
    'DK',
    'FR',
    'GR',
    'HU',
    'IT',
    'LT',
    'LU',
    'LV',
    'MC',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'ES',
    'SE',
    'UA'
];
export const FRIDGE_LABEL_COUNTRIES_SET: Set<string> = new Set<string>(
    FRIDGE_LABEL_COUNTRIES
);
