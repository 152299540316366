import { EMPTY, of } from 'rxjs';
import {
    catchError,
    finalize,
    map,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';

import { Injectable, Optional } from '@angular/core';
import { translate } from '@jsverse/transloco';
import {
    LanguagePreference,
    Title
} from '@mhp/aml-shared/data-proxy/salesforce-types';
import { SalesforceService } from '@mhp/aml-ui-shared-services';
import { UiMatDialogService, UiNotificationService } from '@mhp/ui-components';
import {
    ErrorHandlerService,
    I18nService,
    RecaptchaError,
    UrlShortenerService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { ROUTE_DEALER_COMPLETE_ONE2ONE_REQUEST } from '../../app-route-names';
import {
    LeadContextData,
    SalesforceContextService
} from '../../configuration/salesforce/salesforce-context.service';
import {
    One2OneSessionData,
    RequestOne2oneSessionComponent
} from './request-one2one-session/request-one2one-session.component';

export interface RequestOne2OneSessionOptions {
    modelIdOfInterest?: string;
    productIdOfInterest?: string;
    hideProductOfInterestSelection?: boolean;
}

@Injectable()
export class RequestOne2oneSessionService {
    constructor(
        private readonly dialogService: UiMatDialogService,
        private readonly i18nService: I18nService,
        private readonly salesforceService: SalesforceService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly notificationService: UiNotificationService,
        private readonly urlShortenerService: UrlShortenerService,
        @Optional()
        private readonly salesforceContextService?: SalesforceContextService
    ) {}

    /**
     * Open the dialog to request a one2one session.
     */
    intentRequestOne2OneSession$(options?: RequestOne2OneSessionOptions) {
        return this.dialogService
            .openFullscreen$(RequestOne2oneSessionComponent, {
                // componentFactoryResolver: this.componentFactoryResolver
            })
            .pipe(
                switchMap(({ componentInstance }) => {
                    componentInstance.modelIdOfInterest =
                        options?.modelIdOfInterest;
                    componentInstance.productIdOfInterest =
                        options?.productIdOfInterest;
                    componentInstance.hideProductOfInterestSelection =
                        options?.hideProductOfInterestSelection;

                    return componentInstance.requestSession
                        .pipe(
                            tap(() => {
                                componentInstance.serverCallInProgress = true;
                            }),
                            tap(() =>
                                this.notificationService.showNotification(
                                    translate(
                                        'ONE_2_ONE.REQUEST_SESSION.SUBMIT_IN_PROGRESS'
                                    )
                                )
                            )
                        )
                        .pipe(
                            withLatestFrom(
                                this.salesforceContextService?.getSalesforceContextData$(
                                    {
                                        includeDealerConfigurationUrl: true
                                    }
                                ) ?? of(undefined)
                            ),
                            // shorten booking-request-link
                            switchMap(([sessionData, salesforceContextData]) =>
                                this.urlShortenerService
                                    .shortenUrl$(
                                        this.getBookingRequestLink(
                                            sessionData.country,
                                            sessionData.sessionStart,
                                            salesforceContextData?.configurationEncoded
                                        )
                                    )
                                    .pipe(
                                        map(
                                            (shortenedBookingRequestLink) =>
                                                <
                                                    [
                                                        One2OneSessionData,
                                                        LeadContextData,
                                                        string
                                                    ]
                                                >[
                                                    sessionData,
                                                    salesforceContextData,
                                                    shortenedBookingRequestLink
                                                ]
                                        )
                                    )
                            ),
                            switchMap(
                                ([
                                    sessionData,
                                    salesforceContextData,
                                    shortenedBookingRequestLink
                                ]) =>
                                    this.salesforceService
                                        .createCustomerOne2OneBooking$({
                                            title: <Title>sessionData.title,
                                            firstName: sessionData.firstName,
                                            lastName: sessionData.lastName,
                                            customerEmail:
                                                sessionData.customerEmail,
                                            customerPhone:
                                                sessionData.customerPhone,
                                            countryIsoCode: sessionData.country,
                                            languagePreference: <
                                                LanguagePreference
                                            >salesforceContextData.languagePreference,
                                            preferredDate:
                                                sessionData.sessionStart.getTime(),
                                            bookingTimeZone:
                                                sessionData.timeZone,
                                            consentDateTime:
                                                new Date().getTime(),
                                            dealerName: sessionData.dealerName,
                                            userOptInEmail:
                                                sessionData.userOptInEmail,
                                            userOptInSMS:
                                                sessionData.userOptInSMS,
                                            userOptInPhone:
                                                sessionData.userOptInPhone,
                                            userOptInNewsletter:
                                                sessionData.userOptInNewsletter,
                                            referralWebForm:
                                                salesforceContextData.utmData?.getAsUrlEncodedString(
                                                    255
                                                ),
                                            configurationEncoded:
                                                salesforceContextData.configurationEncoded,
                                            configurationLink:
                                                salesforceContextData.dealerConfigurationLink,
                                            derivate:
                                                sessionData.productId ||
                                                salesforceContextData.productId,
                                            transmission:
                                                salesforceContextData.transmission,
                                            bookingRequestLink:
                                                shortenedBookingRequestLink
                                        })
                                        .pipe(
                                            this.errorHandlerService.applyRetryWithHintsOnError(
                                                (error) =>
                                                    error instanceof
                                                    RecaptchaError
                                                        ? translate(
                                                              'COMMON.ERROR.RECAPTCHA_CHECK_FAILED'
                                                          )
                                                        : translate(
                                                              `ONE_2_ONE.REQUEST_SESSION.ERROR.FAILED_REQUESTING_SESSION`
                                                          )
                                            ),
                                            finalize(() => {
                                                componentInstance.serverCallInProgress =
                                                    false;
                                            }),
                                            catchError(() => EMPTY),
                                            tap(() =>
                                                this.notificationService.showAutohideNotification(
                                                    translate(
                                                        'ONE_2_ONE.REQUEST_SESSION.SUBMIT_SUCCESS'
                                                    )
                                                )
                                            )
                                        )
                            )
                        );
                }),
                take(1)
            );
    }

    /**
     * Build the link that the dealer can open to confirm the requested session.
     * @param country The country
     * @param sessionStart The preferred session start
     * @param configurationEncoded
     * @private
     */
    private getBookingRequestLink(
        country: string,
        sessionStart: Date,
        configurationEncoded: string | undefined
    ): string {
        const bookingRequestLink = new URL(
            `${environment.appConfig.baseUrls.dealerBaseUrl}/${country}/${ROUTE_DEALER_COMPLETE_ONE2ONE_REQUEST}`
        );
        bookingRequestLink.searchParams.append(
            environment.appConfig.one2one
                .confirmSessionPreferredDateQueryParamName,
            sessionStart.getTime().toString(10)
        );
        if (configurationEncoded) {
            bookingRequestLink.searchParams.append(
                environment.appConfig.one2one
                    .confirmSessionConfigurationQueryParamName,
                configurationEncoded
            );
        }
        return bookingRequestLink.toString();
    }
}
