import { Observable, of, switchMap, take } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { translate } from '@jsverse/transloco';
import { Dealer } from '@mhp/aml-shared/data-proxy/dealer.interface';
import { DealerInfoService } from '@mhp/aml-ui-shared-services';
import { sfEvent } from '@mhp/aml-ui-shared-services/salesforce-tracking';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent, UiNotificationService } from '@mhp/ui-components';
import { downloadFile, gtmGA4Track } from '@mhp/ui-shared-services';

import { environment } from '../../../../environments/environment';
import { getTranslatedTitlesList } from '../../../common/forms/form-constants';
import { PricingService } from '../../../dealer/pricing/pricing.service';
import { LabelHelperService } from '../../../i18n/label-helper.service';
import { SALESFORCE_EVENT_CONFIGURATOR_DOWNLOAD_BROCHURE } from '../../../salesforce-events/salesforce-events';
import { SalesforceContextService } from '../../salesforce/salesforce-context.service';
import {
    ConfigurationSummaryService,
    PdfCustomizationOptions
} from '../configuration-summary.service';

@Component({
    selector: 'mhp-request-pdf',
    templateUrl: './request-pdf.component.html',
    styleUrls: ['./request-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestPdfComponent extends UiBaseComponent implements OnInit {
    readonly availableTitles = getTranslatedTitlesList();

    requestPdfFormGroup: UntypedFormGroup;

    @Input()
    dealerMode: boolean;

    readonly compareDealers = (dealerInfo1?: Dealer, dealerInfo2?: Dealer) =>
        dealerInfo1?.dealerCode === dealerInfo2?.dealerCode;

    constructor(
        private readonly notificationService: UiNotificationService,
        private readonly summaryService: ConfigurationSummaryService,
        private readonly labelHelperService: LabelHelperService,
        private readonly dealerInfoService: DealerInfoService,
        private readonly dialogRef: MatDialogRef<RequestPdfComponent>,
        private readonly pricingService: PricingService,
        private readonly salesforceContextService: SalesforceContextService
    ) {
        super();
    }

    ngOnInit() {
        this.requestPdfFormGroup = this.initPdfFormGroup();
    }

    intentDownload() {
        const notificationRef = this.notificationService.showNotification(
            translate('SUMMARY.CONFIGURATION_SUMMARY.GENERATING_PDF')
        );

        const requestPdfFormValue = this.requestPdfFormGroup.value;
        const pdfCustomizationOptions: PdfCustomizationOptions = {
            title: requestPdfFormValue.title,
            firstName: requestPdfFormValue.firstName,
            lastName: requestPdfFormValue.lastName,
            description: requestPdfFormValue.description,
            comment: requestPdfFormValue.comment,
            includePricing: requestPdfFormValue.includePdfPricing
        };

        const downloadPdf$ = this.dealerInfoService.getActiveDealerInfo$().pipe(
            catchError(() => of(undefined)),
            take(1),
            map((activeDealerInfo) => activeDealerInfo?.dealerId),
            switchMap((dealerId) => {
                if (dealerId) {
                    pdfCustomizationOptions.dealerId = `${dealerId}`;
                }

                return this.summaryService.downloadPdf$(
                    pdfCustomizationOptions
                );
            })
        );

        this.handleServerCallInProgress(downloadPdf$)
            .pipe(finalize(() => notificationRef.dismiss()))
            .subscribe(
                ([modelId, productId, blob]) => {
                    downloadFile(
                        blob,
                        `Your-${this.labelHelperService.getProductName(
                            modelId,
                            productId
                        )}.pdf`
                    );

                    gtmGA4Track('download_personalised_brochure_success');

                    this.salesforceContextService
                        .getSalesforceContextData$({
                            includeDealerConfigurationUrl: true
                        })
                        .pipe(take(1))
                        .subscribe((contextData) => {
                            // SF Data Cloud: Configurator Email Personalised Brochure
                            sfEvent({
                                interaction: {
                                    ...SALESFORCE_EVENT_CONFIGURATOR_DOWNLOAD_BROCHURE,
                                    modelCode: modelId,
                                    variantCode: productId,
                                    configUrl:
                                        contextData.dealerConfigurationLink ??
                                        ''
                                },
                                user: {
                                    attributes: {
                                        firstName:
                                            pdfCustomizationOptions.firstName ??
                                            '',
                                        lastName:
                                            pdfCustomizationOptions.lastName ??
                                            '',
                                        eventType: 'identity',
                                        isAnonymous: 0
                                    }
                                }
                            });
                        });

                    this.dialogRef.close();
                },
                () => gtmGA4Track('download_personalised_brochure_failed')
            );
    }

    isDownloadInProgress$(): Observable<boolean> {
        return this.serverCallInProgress$;
    }

    @MemoizeObservable()
    isIncludePricingAvailable$() {
        if (!environment.appConfig.featureToggles.enableIncludePricingInPdf) {
            return of(false);
        }
        return this.pricingService.isRRPAvailableForDealer$();
    }

    intentCloseDialog() {
        gtmGA4Track('download_personalised_brochure_abandonment');
        this.dialogRef.close();
    }

    private initPdfFormGroup(): UntypedFormGroup {
        const dealerFormControls: { [key: string]: AbstractControl } = this
            .dealerMode
            ? {
                  dealer: new UntypedFormControl(undefined),
                  description: new UntypedFormControl(undefined, [
                      Validators.maxLength(50)
                  ]),
                  includePdfPricing: new UntypedFormControl(false),
                  comment: new UntypedFormControl(undefined, [
                      Validators.maxLength(2000)
                  ])
              }
            : {};

        return new UntypedFormGroup({
            ...dealerFormControls,
            title: new UntypedFormControl(undefined),
            firstName: new UntypedFormControl(undefined, [
                Validators.maxLength(50)
            ]),
            lastName: new UntypedFormControl(undefined, [
                Validators.maxLength(50)
            ])
        });
    }
}
