<div
    class="overflow-indicator overflow-indicator--prev"
    [ngClass]="{
        'overflow-indicator--visible':
            (showOverflowIndicator$('left') | async) === true
    }"
    (click)="intentShowPage('prev')"
>
    <ng-container *ngTemplateOutlet="prevTemplate"></ng-container>
</div>
<div
    class="overflow-indicator overflow-indicator--next"
    [ngClass]="{
        'overflow-indicator--visible':
            (showOverflowIndicator$('right') | async) === true
    }"
    (click)="intentShowPage('next')"
>
    <ng-container *ngTemplateOutlet="nextTemplate"></ng-container>
</div>
