<div class="config-link-editor" *transloco="let t">
    <div class="config-link-editor__rendering-info">
        <mhp-static-renderer
            *ngIf="
                getConfiguratorSelectorRendererInput$()
                    | async as rendererInput;
                else noValidInput
            "
            [rendererInput]="rendererInput"
            [aspectRatio]="16 / 9"
        >
        </mhp-static-renderer>
        <ng-template #noValidInput>
            <p *ngIf="configurationUrlFormControl.value; else emptyInput">
                {{ t('ONE_2_ONE.NEW_SESSION.ERROR.INVALID_CONFIG_URL') }}
            </p>
            <ng-template #emptyInput>
                <p>
                    {{ t('ONE_2_ONE.NEW_SESSION.SELECT_CONFIG_DESCRIPTION') }}
                </p>
            </ng-template>
        </ng-template>
    </div>
    <mat-form-field class="mhp-ui-form-field--full-width">
        <mat-label>
            {{ t('ONE_2_ONE.NEW_SESSION.SELECT_CONFIG_LABEL') }}
        </mat-label>
        <input
            matInput
            [formControl]="configurationUrlFormControl"
            [placeholder]="t('ONE_2_ONE.NEW_SESSION.SELECT_CONFIG_PLACEHOLDER')"
            (blur)="onTouched()"
        />
    </mat-form-field>
</div>
