import { identity, values } from 'lodash-es';
import { EMPTY, Observable, defer, filter, takeUntil, tap } from 'rxjs';
import { map, startWith, switchMap, take } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { translate } from '@jsverse/transloco';
import { APAC_COUNTRIES } from '@mhp/aml-shared/country-mapping/territories';
import { Dealer } from '@mhp/aml-shared/data-proxy/dealer.interface';
import { SalesforceConfigurationSendInterface } from '@mhp/aml-shared/data-proxy/salesforce-configuration-send.interface';
import { ExtendedDealer, SalesforceService } from '@mhp/aml-ui-shared-services';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import { UiBaseComponent, UiNotificationService } from '@mhp/ui-components';
import {
    ErrorHandlerService,
    I18nService,
    L10nService,
    RecaptchaError,
    gtmGA4Track
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../../environments/environment';
import { getTranslatedTitlesList } from '../../../common/forms/form-constants';
import { EMAIL_REGEX } from '../../../common/validation/validation.helpers';
import { SalesforceContextService } from '../../../configuration/salesforce/salesforce-context.service';
import { LabelHelperService } from '../../../i18n/label-helper.service';

const IS_DEALER_BUILD = environment.appConfig.dealer.dealerBuild;

@UntilDestroy()
@Component({
    selector: 'mhp-salesforce-form',
    templateUrl: './salesforce-form.component.html',
    styleUrls: ['./salesforce-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesforceFormComponent extends UiBaseComponent implements OnInit {
    @Input()
    formId: string;

    @Input()
    fixedCountry?: string;

    @Input()
    requireDealerSelect = true;

    @Input()
    requiresConsent = true;

    @Output()
    readonly submitSuccess = new EventEmitter<SalesforceConfigurationSendInterface>();

    @Output()
    readonly submitFailed = new EventEmitter<unknown>();

    leadFormGroup: UntypedFormGroup;

    readonly availableTitles = getTranslatedTitlesList();

    protected readonly NULL_VALUE = {};

    readonly compareDealers = (dealerInfo1?: Dealer, dealerInfo2?: Dealer) =>
        dealerInfo1?.dealerCode === dealerInfo2?.dealerCode;

    constructor(
        private readonly l10nService: L10nService,
        private readonly i18nService: I18nService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly labelHelperService: LabelHelperService,
        private readonly salesforceService: SalesforceService,
        private readonly salesforceContextService: SalesforceContextService,
        private readonly notificationService: UiNotificationService
    ) {
        super();

        this.completeOnDestroy(this.submitSuccess, this.submitFailed);
    }

    ngOnInit() {
        this.leadFormGroup = new UntypedFormGroup({
            country: new UntypedFormControl(
                this.NULL_VALUE,
                Validators.required
            ),
            dealer: new UntypedFormControl(
                this.NULL_VALUE,
                this.requireDealerSelect ? Validators.required : undefined
            ),
            title: new UntypedFormControl(undefined),
            firstName: new UntypedFormControl(undefined, [
                Validators.maxLength(40)
            ]),
            lastName: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(80)
            ]),
            customerEmail: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(80),
                Validators.pattern(EMAIL_REGEX)
            ]),
            customerPhone: new UntypedFormControl(undefined, [
                Validators.maxLength(15),
                Validators.pattern('[- +()0-9]+')
            ])
        });

        if (this.requiresConsent) {
            this.leadFormGroup.addControl(
                // contact-options
                'contactOptions',
                new UntypedFormGroup({
                    contactViaEmail: new UntypedFormControl(undefined),
                    contactViaTelephone: new UntypedFormControl(undefined),
                    contactViaSms: new UntypedFormControl(undefined),
                    contactViaNewsletter: new UntypedFormControl(undefined)
                })
            );
        }

        // reset selected dealer once country changes
        this.leadFormGroup
            .get('country')
            ?.valueChanges.pipe(untilDestroyed(this))
            .subscribe((selectedCountry) => {
                this.leadFormGroup.get('dealer')?.setValue(undefined);
            });

        if (!this.fixedCountry) {
            const dealerPreselectionWorkflow$ = this.salesforceContextService
                .getPreselectedDealer$()
                .pipe(
                    take(1),
                    switchMap((preselectedDealer) => {
                        // if there is a dealer to be preselected, do it
                        if (preselectedDealer) {
                            this.leadFormGroup
                                .get('country')
                                ?.setValue(
                                    preselectedDealer.billingCountryCode
                                );
                            this.leadFormGroup
                                .get('dealer')
                                ?.setValue(preselectedDealer);
                            return EMPTY;
                        }
                        // preselect currently active country
                        return this.l10nService.getActiveCountry$().pipe(
                            take(1),
                            // when fixedCountry is set later-on, do not override with country
                            takeUntil(
                                this.observeProperty<
                                    SalesforceFormComponent,
                                    'string'
                                >('fixedCountry', true).pipe(filter(identity))
                            ),
                            tap((country) =>
                                this.leadFormGroup
                                    .get('country')
                                    ?.setValue(country)
                            )
                        );
                    }),
                    untilDestroyed(this)
                );

            this.handleServerCallInProgress(
                dealerPreselectionWorkflow$
            ).subscribe();
        } else {
            this.leadFormGroup.get('country')?.setValue(this.fixedCountry);
        }

        // customer-email required unless from china or APAC region
        this.leadFormGroup
            .get('country')
            ?.valueChanges.pipe(
                startWith(this.leadFormGroup.get('country')?.value),
                untilDestroyed(this)
            )
            .subscribe((selectedCountry) => {
                if (!selectedCountry || selectedCountry === this.NULL_VALUE) {
                    return;
                }
                if (
                    APAC_COUNTRIES.includes(selectedCountry) ||
                    selectedCountry === 'CN'
                ) {
                    this.leadFormGroup
                        .get('customerEmail')
                        ?.removeValidators(Validators.required);
                } else {
                    this.leadFormGroup
                        .get('customerEmail')
                        ?.addValidators(Validators.required);
                }
                this.leadFormGroup
                    .get('customerEmail')
                    ?.updateValueAndValidity();
            });
    }

    @MemoizeObservable()
    getAvailableCountries$(): Observable<
        { countryISO: string; label: string }[]
    > {
        return this.salesforceContextService.getAvailableCountries$();
    }

    @MemoizeObservable()
    getAvailableDealers$(): Observable<ExtendedDealer[] | undefined> {
        const countryFormControl = this.leadFormGroup.get('country');
        if (!countryFormControl) {
            throw new IllegalStateError('missing country form control');
        }

        return this.salesforceContextService.getAvailableDealers$(
            countryFormControl?.valueChanges.pipe(
                startWith(countryFormControl.value),
                filter((value) => value !== this.NULL_VALUE)
            )
        );
    }

    @MemoizeObservable()
    getModelLabel$() {
        return this.labelHelperService.getActiveModelAndProductName$();
    }

    @MemoizeObservable()
    getActiveLang$(): Observable<string> {
        return this.i18nService.getActiveLang$();
    }

    getServerCallInProgress$() {
        return this.serverCallInProgress$;
    }

    @MemoizeObservable()
    canSubmit$() {
        return this.serverCallInProgress$.pipe(
            map((formSubmitInProgress) => !formSubmitInProgress),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getGdprMarkdown$(number: number) {
        return this.salesforceContextService.getGdprMarkdown$(number);
    }

    intentSubmit() {
        gtmGA4Track('share_to_dealer_submit');

        if (!this.leadFormGroup.valid) {
            values(this.leadFormGroup.controls).forEach((control) =>
                control.markAllAsTouched()
            );
            return;
        }

        this.submitToBackend();
    }

    private submitToBackend() {
        const retryableSubmission$ = defer(() =>
            this.salesforceContextService.getSalesforceContextData$({includeDealerConfigurationUrl: true}).pipe(
                take(1),
                switchMap((salesforceContextData) => {
                    if (!salesforceContextData.productId) {
                        throw new IllegalStateError('Missing productId');
                    }
                    if (!salesforceContextData.dealerConfigurationLink) {
                        throw new IllegalStateError(
                            'Missing configuration URL'
                        );
                    }
                    if (!salesforceContextData.configurationEncoded) {
                        throw new IllegalStateError(
                            'Missing encoded configuration'
                        );
                    }
                    if (!salesforceContextData.transmission) {
                        throw new IllegalStateError(
                            'Missing transmission value'
                        );
                    }

                    const formValue = this.leadFormGroup.value;

                    const payload: SalesforceConfigurationSendInterface = {
                        dealerName: (<Dealer>formValue.dealer)?.name,
                        dealerCode: (<Dealer>formValue.dealer)?.dealerCode,
                        title: formValue.title,
                        firstName: formValue.firstName,
                        lastName: formValue.lastName,
                        customerEmail: formValue.customerEmail,
                        customerPhone: formValue.customerPhone,
                        countryIsoCode: formValue.country,
                        languagePreference: <any>(
                            salesforceContextData.languagePreference
                        ),
                        consentDateTime: new Date().getTime(),
                        userOptInEmail:
                            !!formValue.contactOptions?.contactViaEmail,
                        userOptInSMS: !!formValue.contactOptions?.contactViaSms,
                        userOptInPhone:
                            !!formValue.contactOptions?.contactViaTelephone,
                        userOptInNewsletter:
                            !!formValue.contactOptions?.contactViaNewsletter,
                        configurationLink:
                            salesforceContextData.dealerConfigurationLink,
                        configurationEncoded:
                            salesforceContextData.configurationEncoded,
                        derivate: salesforceContextData.productId,
                        // color options
                        exteriorColor: salesforceContextData?.exteriorColor,
                        interiorColorPrimary:
                            salesforceContextData?.interiorColor1,
                        interiorColorSecondary:
                            salesforceContextData?.interiorColor2,
                        transmission: salesforceContextData.transmission,
                        // UTM data
                        referralWebForm:
                            salesforceContextData.utmData?.getAsUrlEncodedString(
                                255
                            ),
                        emailRequest: false
                    };

                    return this.salesforceService.submitToDealer(payload).pipe(map(() => payload));
                })
            )
        );

        const { keySubmitStart, keySubmitSuccess, keySubmitFailed } = {
            keySubmitStart: IS_DEALER_BUILD
                ? 'SUBMIT_TO_SYNERGY.FORM.SUBMIT_START'
                : 'SUMBIT_TO_DEALER.GENERAL.SUBMIT_START',
            keySubmitSuccess: IS_DEALER_BUILD
                ? 'SUBMIT_TO_SYNERGY.FORM.SUBMIT_SUCCESS'
                : 'SUMBIT_TO_DEALER.GENERAL.SUBMIT_SUCCESS',
            keySubmitFailed: IS_DEALER_BUILD
                ? 'SUBMIT_TO_SYNERGY.ERRORS.SUBMIT_FAILED'
                : 'SUMBIT_TO_DEALER.GENERAL.ERRORS.SUBMIT_FAILED'
        };

        this.notificationService.showNotification(translate(keySubmitStart));

        this.handleServerCallInProgress(
            retryableSubmission$.pipe(
                this.errorHandlerService.applyRetry({
                    messageProviderOnFinalError: (error) => {
                        if (error instanceof RecaptchaError) {
                            return translate(
                                'COMMON.ERROR.RECAPTCHA_CHECK_FAILED'
                            );
                        }
                        console.error(
                            'Failed submitting to CRM backend',
                            error
                        );
                        return translate(keySubmitFailed);
                    }
                })
            )
        ).subscribe(
            {
                next: (payload) => {
                    this.notificationService.showAutohideNotification(
                        translate(keySubmitSuccess)
                    );

                    this.submitSuccess.emit(payload);
                },
                error: (error) => this.submitFailed.emit(error)
            }
        );
    }
}
