import { last, range } from 'lodash-es';
import { Observable, firstValueFrom } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MemoizeObservable } from '@mhp/common';
import { ImageSrcset, UiBaseComponent } from '@mhp/ui-components';
import { L10nService, ScreenshotSource } from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { ScreenshotRegistryService } from '../screenshot/screenshot-registry.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { StaticRendererService } from '../static-renderer/static-renderer.service';

@Component({
    selector: 'mhp-turntable-dialog',
    templateUrl: './turntable-dialog.component.html',
    styleUrls: ['./turntable-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TurntableDialogComponent extends UiBaseComponent {
    percentLoaded = 0;

    private activeStep = 0;

    constructor(
        private readonly dialogRef: MatDialogRef<TurntableDialogComponent>,
        private readonly staticRendererService: StaticRendererService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly screenshotRegistryService: ScreenshotRegistryService,
        private readonly l10nService: L10nService,
        private readonly httpClient: HttpClient,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {
        super();

        this.initScreenshotRegistryLogic();
    }

    intentActionClose() {
        this.dialogRef.close();
    }

    onActiveStepChange(activeStep: number) {
        this.activeStep = activeStep;
    }

    onPreloadStatusChange(percentLoaded: number) {
        this.percentLoaded = percentLoaded;
        this.changeDetectorRef.detectChanges();
    }

    @MemoizeObservable()
    getTurntableImages$(): Observable<ImageSrcset[]> {
        return this.staticRendererService
            .getActiveSessionRenderingSrcsetFactory$()
            .pipe(
                map((srcsetFactory) => {
                    if (!srcsetFactory) {
                        return [];
                    }

                    // for all degrees
                    return range(
                        0,
                        360,
                        environment.appConfig.visualization.turntable.degreeStep
                    ).map(
                        (degree): ImageSrcset =>
                            srcsetFactory((sessionInfo) => ({
                                ...sessionInfo,
                                camera: {
                                    id: environment.appConfig.visualization
                                        .turntable.camera,
                                    options: {
                                        degree
                                    }
                                }
                            }))
                    );
                })
            );
    }

    private initScreenshotRegistryLogic() {
        const onDestroyCallback =
            this.screenshotRegistryService.addScreenshotProvider({
                getScreenshotSource: () => {
                    const screenshotSource: Observable<
                        ScreenshotSource | undefined
                    > = this.getTurntableImages$().pipe(
                        first(),
                        map(
                            (images) =>
                                last(images[this.activeStep].sources)?.url
                        ),
                        map((imageUrl) => {
                            if (!imageUrl) {
                                return undefined;
                            }
                            return {
                                source: imageUrl,
                                mimeType: 'image/jpeg'
                            };
                        })
                    );
                    return firstValueFrom(screenshotSource);
                }
            });
        this.destroy$.subscribe(() => onDestroyCallback());
    }
}
