import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DialogModule } from '../../dialog/dialog.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { VideoPlaybackComponent } from './video-playback.component';

@NgModule({
    declarations: [VideoPlaybackComponent],
    imports: [CommonModule, DialogModule, SpinnerModule],
    exports: [VideoPlaybackComponent]
})
export class VideoPlaybackModule {}
