import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Output,
    computed,
    effect,
    input
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslocoDirective } from '@jsverse/transloco';
import { CategoryItem } from '@mhp/aml-ui-shared-services';
import { UiIconButtonModule } from '@mhp/ui-components';

/**
 * Component rendering a select-component allowing the selection
 * of one of the input category-item's children.
 * Additionally take possible descriptions on these children into account to
 * render an info-button.
 */
@Component({
    selector: 'mhp-configuration-category-select',
    standalone: true,
    imports: [
        CommonModule,
        MatFormField,
        MatOption,
        MatSelect,
        TranslocoDirective,
        UiIconButtonModule,
        ReactiveFormsModule,
        MatTooltip
    ],
    templateUrl: './configuration-category-select.component.html',
    styleUrl: './configuration-category-select.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationCategorySelectComponent {
    protected readonly categorySelectFormControl = new FormControl<
        CategoryItem | undefined
    >(undefined);

    protected readonly categoryItemComparator = (
        item1: CategoryItem,
        item2: CategoryItem
    ) => item1?.id === item2?.id;

    protected readonly childCategoryHasDescription = computed<boolean>(
        () => !!this.categoryItem().children?.some((child) => child.description)
    );

    categoryItem = input.required<CategoryItem>();
    formFieldClass = input<
        | string
        | string[]
        | Set<string>
        | {
              [klass: string]: unknown;
          }
        | null
        | undefined
    >();

    readonly disabled = input<boolean>();

    @Output()
    readonly selectCategory = new EventEmitter<CategoryItem>();

    /**
     * Showing additional information about a certain category-item is requested.
     */
    @Output()
    readonly showInfo = new EventEmitter<CategoryItem>();

    constructor() {
        effect(() => {
            this.categorySelectFormControl.setValue(
                this.getSelectedChild(this.categoryItem())
            );
        });

        this.categorySelectFormControl.valueChanges
            .pipe(takeUntilDestroyed())
            .subscribe(this.selectCategory);
    }

    intentShowInfo(categoryItem: CategoryItem) {
        this.showInfo.emit(categoryItem);
    }

    private getSelectedChild(categoryItem: CategoryItem) {
        return categoryItem.children?.find((child) => child.selected);
    }
}
