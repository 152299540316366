import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconButtonModule, UiTooltipModule } from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { SocialSharingBarComponent } from './social-sharing-bar/social-sharing-bar.component';

@NgModule({
    declarations: [SocialSharingBarComponent],
    imports: [CommonModule, UiIconButtonModule, I18nModule, UiTooltipModule],
    exports: [SocialSharingBarComponent, SocialSharingBarComponent]
})
export class SocialSharingModule {}
