import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
    input
} from '@angular/core';
import { BeautyshotDefinition } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import {
    MemoizeObservable,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import { TRACK_BY_ID, UiBaseComponent } from '@mhp/ui-components';

import { DEALER_HOOKS_TOKEN, DealerHooks } from '../../../dealer';
import { ExtendedUiOptionCode } from '../../configuration-model/configuration-interfaces';
import { SearchService } from '../../search/search.service';
import { CameraStateHandler } from '../helpers/camera/camera-state-handler';
import { CameraStateHandlerFactoryService } from '../helpers/camera/camera-state-handler-factory.service';

@Component({
    selector: 'mhp-configuration-options',
    templateUrl: './configuration-options.component.html',
    styleUrls: ['./configuration-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationOptionsComponent extends UiBaseComponent {
    readonly trackById = TRACK_BY_ID;

    /**
     * The options to be rendered
     */
    @Input()
    options: ExtendedUiOptionCode[];

    @Input()
    beautyshot: BeautyshotDefinition;

    @Input()
    selectedOption?: ExtendedUiOptionCode;

    optionSelectionDisabled = input(false);

    @Output()
    readonly optionSelected = new EventEmitter<string>();

    @Output()
    readonly infoRequested = new EventEmitter<ExtendedUiOptionCode>();

    readonly cameraStateHandler: CameraStateHandler;

    readonly optionHighlightSubject = new BehaviorSubject<string | undefined>(
        undefined
    );

    constructor(
        cameraStateHandlerFactoryService: CameraStateHandlerFactoryService,
        protected readonly breakpointObserver: BreakpointObserver,
        protected readonly searchService: SearchService,
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks
    ) {
        super();

        this.completeOnDestroy(
            this.optionSelected,
            this.optionHighlightSubject,
            this.infoRequested
        );

        this.cameraStateHandler =
            cameraStateHandlerFactoryService.newCameraStateHandler(this);
    }

    onOptionCodeSelected(code: string) {
        this.optionSelected.next(code);
    }

    onOptionCodeHighlighted(optionId: string | undefined) {
        this.optionHighlightSubject.next(optionId);
    }

    @MemoizeObservable()
    getSelectedOrHighlightedOption$(): Observable<
        ExtendedUiOptionCode | undefined
    > {
        return combineLatest([
            this.observeProperty<
                ConfigurationOptionsComponent,
                ExtendedUiOptionCode[]
            >('options', true),
            this.observeProperty<
                ConfigurationOptionsComponent,
                ExtendedUiOptionCode | undefined
            >('selectedOption', true),
            this.optionHighlightSubject.pipe(
                // delay reversal to no highlighted option by a certain amount
                switchMap((highlightedOptionId) =>
                    highlightedOptionId
                        ? of(highlightedOptionId)
                        : of(highlightedOptionId).pipe(delay(300))
                )
            )
        ]).pipe(
            map(
                ([
                    availableOptions,
                    selectedOverrideOption,
                    highlightedOptionId
                ]) => {
                    let foundOption = selectedOverrideOption;
                    if (highlightedOptionId) {
                        foundOption = availableOptions.find(
                            (option) => option.id === highlightedOptionId
                        );
                    }

                    if (foundOption) {
                        return foundOption;
                    }
                    return availableOptions.find((option) => option.selected);
                }
            ),
            distinctUntilChangedEquality(),
            lazyShareReplay()
        );
    }

    intentShowInfo(selectedOption: ExtendedUiOptionCode) {
        this.infoRequested.emit(selectedOption);
    }

    @MemoizeObservable()
    getSelectedOrHighlightedOptionForDisplay$() {
        return this.getSelectedOrHighlightedOption$().pipe(
            map((option): ExtendedUiOptionCode | undefined => {
                if (!option) {
                    return undefined;
                }

                return {
                    ...option,
                    selected: false,
                    active: true
                };
            })
        );
    }

    /**
     * Returns the selected option label taking a possible active search term into account.
     */
    @MemoizeObservable()
    getSelectedOptionLabel$() {
        return this.getSelectedOrHighlightedOption$().pipe(
            switchMap((option) => {
                if (!option) {
                    return of(undefined);
                }

                return this.searchService.getLabelWithSearchTermHighlighted$(
                    option.nameTranslated
                );
            })
        );
    }

    @MemoizeObservable()
    getFinancialServiceMonthlyRate$(): Observable<number | undefined> {
        return combineLatest([
            this.dealerHooksService
                .getFinancialServiceHooks()
                .getFinancialServicesOfferInfo$()
                .pipe(map((info) => info.offer)),
            this.observeProperty<
                ConfigurationOptionsComponent,
                ExtendedUiOptionCode
            >('selectedOption', true)
        ]).pipe(
            map(([financialServicesOffer, selectedOption]) => {
                if (!financialServicesOffer || !selectedOption) {
                    return undefined;
                }
                return financialServicesOffer.options?.get(
                    selectedOption.codeDotNotation
                );
            })
        );
    }
}
