<div class="summary-slider">
    <swiper
        [slidesPerView]="imageUrls[0]?.neutralEnvImage ? 1 : slidesPerView"
        [spaceBetween]="0"
        [grabCursor]="true"
        [loop]="true"
        [followFinger]="true"
        [loopAdditionalSlides]="imageUrls.length"
        [autoplay]="{
            delay: autoplayDelay,
            pauseOnMouseEnter: true,
            disableOnInteraction: true
        }"
        [keyboard]="{
            enabled: true,
            onlyInViewport: true
        }"
        [resizeObserver]="true"
        [touchStartPreventDefault]="false"
        (swiper)="onSwiperInit($event)"
        (sliderFirstMove)="handleSwiperUserInteraction()"
        (mouseenter)="handleSwiperHover(true)"
        (mouseleave)="handleSwiperHover(false)"
    >
        <ng-template
            *ngFor="let imageComparisonUrl of imageUrls; trackBy: trackBy"
            swiperSlide
        >
            <ng-container
                *ngIf="imageComparisonUrl?.neutralEnvImage; else normalContent"
            >
                <div class="summary-slider__background-image-container">
                    <mhp-ui-lazyload-image
                        class="summary-slider__background-image"
                        [imageUrls]="imageComparisonUrl.currentEnvImage"
                        [willResize]="true"
                    ></mhp-ui-lazyload-image>
                </div>

                <mhp-ui-img-comparison-slider
                    *transloco="let t; read: 'ICONS'"
                    [imageCurrentUrl]="imageComparisonUrl.currentEnvImage"
                    [imageNeutralUrl]="imageComparisonUrl.neutralEnvImage"
                    [fixedAspectRatio]="
                        (useComparisonImageFixedRatio$() | async) === true
                            ? 16 / 8
                            : 0
                    "
                    [handleHidden]="comparisonSliderHandleHidden"
                    [dragIconLabel]="t('COMPARE_DRAG_ALT')"
                    [stopEventPropagationOnHandleInteraction]="true"
                    (dragHandlerInteracted)="handleSwiperHover($event)"
                ></mhp-ui-img-comparison-slider>
            </ng-container>

            <ng-template #normalContent>
                <mhp-ui-lazyload-image
                    [imageUrl]="imageComparisonUrl.currentEnvImage"
                    [willResize]="true"
                    [aspectRatio]="16 / 9"
                ></mhp-ui-lazyload-image>
            </ng-template>
        </ng-template>
    </swiper>
    <ng-container *transloco="let t">
        <mhp-ui-icon-button
            class="summary-slider__swiper-nav summary-slider__swiper-nav--prev"
            svgIcon="mhp-ui:angle-left"
            size="medium"
            [matTooltip]="t('ICONS.PREVIOUS_CAMERA_ALT')"
            [label]="t('ICONS.PREVIOUS_CAMERA_ALT')"
            (click)="intentPrevSlide()"
        ></mhp-ui-icon-button>
        <mhp-ui-icon-button
            class="summary-slider__swiper-nav summary-slider__swiper-nav--next"
            svgIcon="mhp-ui:angle-right"
            size="medium"
            [matTooltip]="t('ICONS.NEXT_CAMERA_ALT')"
            [label]="t('ICONS.NEXT_CAMERA_ALT')"
            (click)="intentNextSlide()"
        ></mhp-ui-icon-button>
    </ng-container>
</div>
