import { Observable, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { Component } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import {
    ApplicationStateService,
    selectEngineConnectionState
} from '@mhp/ui-shared-services';

import { LocalApplicationState } from '../../../state';
import { VisualizationMode } from '../../state';
import { VisualizationModeService } from '../visualization-mode.service';

@Component({
    selector: 'mhp-visualization-mode-debug',
    templateUrl: './visualization-mode-debug.component.html',
    styleUrls: ['./visualization-mode-debug.component.scss']
})
export class VisualizationModeDebugComponent extends UiBaseComponent {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly visualizationModeService: VisualizationModeService
    ) {
        super();
    }

    switchMode() {
        this.visualizationModeService
            .getTargetVisualizationMode$()
            .pipe(first())
            .subscribe((currentVisualizationMode) => {
                this.visualizationModeService.setTargetVisualizationMode(
                    currentVisualizationMode === VisualizationMode.BASIC
                        ? VisualizationMode.STREAM
                        : VisualizationMode.BASIC
                );
            });
    }

    getActiveMode$() {
        return this.visualizationModeService.getActiveVisualizationMode$();
    }

    getTargetMode$() {
        return this.visualizationModeService.getTargetVisualizationMode$();
    }

    @MemoizeObservable()
    getSwitchingModeStatus$(): Observable<string> {
        return combineLatest([
            this.getActiveMode$(),
            this.getTargetMode$(),
            this.applicationStateService
                .getLocalSharedState()
                .pipe(selectEngineConnectionState)
        ]).pipe(
            map(([activeVisMode, targetVisMode, engineConnectionState]) => {
                if (!activeVisMode && !targetVisMode) {
                    return `Determining visualization mode to be loaded.`;
                }
                if (activeVisMode === targetVisMode) {
                    return `Successfully loaded ${activeVisMode}`;
                }
                return `Switching over to ${targetVisMode}. Waiting for engine to become available.`;
            })
        );
    }
}
