<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    #dialog
    [title]="t('CONFIGURATOR.FEATURES.CINEMATICS.SHARE_TITLE')"
    *transloco="let t"
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <p>
            {{ t('CONFIGURATOR.FEATURES.CINEMATICS.SHARE_DESCRIPTION') }}
        </p>
        <div
            *ngIf="(isVideoDownloadAvailable$() | async) === false"
            class="cinematic-share-dialog__generating-hint"
        >
            <div class="cinematic-share-dialog__progress">
                <mat-progress-spinner
                    [color]="'primary'"
                    [mode]="'determinate'"
                    [value]="(getDownloadProgress$() | async) * 100"
                    diameter="25"
                    strokeWidth="2"
                    class="cinematic-share-dialog__progress-spinner"
                    aria-hidden="true"
                ></mat-progress-spinner>
                <div class="cinematic-share-dialog__progress-background"></div>
            </div>
            {{ t('CONFIGURATOR.FEATURES.CINEMATICS.SHARE_PREPARATION') }}
        </div>
        <mhp-social-sharing-bar
            [disabled]="(isVideoDownloadAvailable$() | async) === false"
            (share)="intentShare($event)"
        ></mhp-social-sharing-bar>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-left>
        <button
            mat-button
            (click)="dialog.close()"
            [trackGA4Event]="{
                event: 'cinematic_share_cancel'
            }"
        >
            {{ t('COMMON.CANCEL') }}
        </button>
    </mhp-ui-mat-dialog-actions-left>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            [disabled]="(isVideoDownloadAvailable$() | async) === false"
            (click)="intentDownload()"
        >
            <span>{{
                t('CONFIGURATOR.FEATURES.CINEMATICS.CTA_DOWNLOAD')
            }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
