import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogButtonType } from '@mhp/ui-shared-services';

@Component({
    selector: 'mhp-editions-dialog',
    templateUrl: './editions-dialog.component.html',
    styleUrls: ['./editions-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditionsDialogComponent {
    @Input()
    editionLabel?: string;

    constructor(
        protected readonly matDialogRef: MatDialogRef<EditionsDialogComponent>
    ) {}

    protected readonly DialogButtonType = DialogButtonType;

    intentConfirm() {
        this.matDialogRef.close(true);
    }
}
