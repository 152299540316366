export function getPrivacyUrl(country: string | undefined): string {
    switch (country) {
        case 'GB':
            return 'https://www.astonmartin.com/en-gb/legal/privacy';
        case 'AU':
            return 'https://www.astonmartin.com/en-au/legal/privacy';
        case 'DE':
            return 'https://www.astonmartin.com/de/legal/privacy';
        case 'US':
            return 'https://www.astonmartin.com/en-us/legal/privacy';
        case 'CN':
            return 'https://www.astonmartin.com/zh-cn/legal/privacy';
        case 'JP':
            return 'https://www.astonmartin.com/ja/legal/privacy';
        case 'FR':
            return 'https://www.astonmartin.com/fr/legal/privacy';
        default:
            return 'https://www.astonmartin.com/en/legal/privacy';
    }
}

export function getTermsAndConditionUrl(country: string | undefined): string {
    switch (country) {
        case 'GB':
            return 'https://www.astonmartin.com/en-gb/legal/terms-and-conditions';
        case 'AU':
            return 'https://www.astonmartin.com/en-au/legal/terms-and-conditions';
        case 'DE':
            return 'https://www.astonmartin.com/de/legal/terms-and-conditions';
        case 'US':
            return 'https://www.astonmartin.com/en-us/legal/terms-and-conditions';
        case 'CN':
            return 'https://www.astonmartin.com/zh-cn/legal/terms-and-conditions';
        case 'JP':
            return 'https://www.astonmartin.com/ja/legal/terms-and-conditions';
        case 'FR':
            return 'https://www.astonmartin.com/fr/legal/terms-and-conditions';
        default:
            return 'https://www.astonmartin.com/en/legal/terms-and-conditions';
    }
}
