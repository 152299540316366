<div
    class="session-control"
    *transloco="let t; read: 'ONE_2_ONE.ACTIVE_SESSION'"
    [ngClass]="{
        'session-control--stage-minimized':
            (isStageMinimized$() | async) === true
    }"
>
    <mhp-configuration-area-action-group>
        <mhp-configuration-area-action-item
            class="session-control__action-toggle-pricing"
            [matTooltip]="t('TOGGLE_CLIENT_PRICING_VISIBILITY_ALT')"
            [buttonText]="l10nService.getCurrencySymbol()"
            [active]="(isPricingMirrored$() | async) === true"
            (itemSelect)="intentToggleClientPricing()"
            *ngIf="(canTogglePricing$() | async) === true"
        >
        </mhp-configuration-area-action-item>

        <mhp-configuration-area-action-item
            class="session-control__action-stop"
            svgIcon="mhp-ui:stop"
            [matTooltip]="t('CTA_STOP_SESSION')"
            (itemSelect)="intentStopSession()"
        >
        </mhp-configuration-area-action-item>
    </mhp-configuration-area-action-group>
    <mhp-video-header-container
        [renderVideoElements]="doRenderVideoElements$() | async"
    ></mhp-video-header-container>
</div>
