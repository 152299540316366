<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-dialog-body [noPadding]="true">
    <mhp-ui-turntable
        class="turntable-dialog__turntable"
        [ngClass]="{
            'turntable-dialog__turntable--complete': percentLoaded >= 1
        }"
        [loop]="false"
        [images]="data.interiorImageSrcsets"
        [startingStep]="data.interiorImageSrcsets.length / 2"
        (activeStep)="onActiveStepChange($event)"
        (preloadStatus)="onPreloadStatusChange($event)"
    ></mhp-ui-turntable>
    <mhp-loading-indicator
        [loading]="percentLoaded < 1"
        [progress]="percentLoaded"
    ></mhp-loading-indicator>
</mhp-dialog-body>
