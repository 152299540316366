import { combineLatest } from 'rxjs';
import { debounceTime, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    AmlUiSharedState,
    One2OneProductData
} from '@mhp/aml-ui-shared-services';
import {
    ApplicationStateService,
    UiSharedStateService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { One2oneSessionInfoService } from '../../one2one/session-info/one2one-session-info.service';
import { AmlProductDataService } from '../../product-data/aml-product-data-service';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import {
    selectDealerInfo,
    selectOne2OneState
} from '../state/selectors/dealer-state.selectors';

/**
 * Service responsible to sync several state-slices from state visible to dealer only
 * over to the shared ui state, so it's accessible to the client, too.
 */
@Injectable()
export class One2oneStateSyncService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<
            LocalApplicationState,
            AmlUiSharedState
        >,
        private readonly uiSharedStateService: UiSharedStateService<AmlUiSharedState>,
        private readonly one2oneSessionInfoService: One2oneSessionInfoService,
        private readonly productDataService: AmlProductDataService
    ) {
        this.initSyncParticipantsInfoToSharedState();
        this.initSyncProductDataToSharedState();
    }

    /**
     * Sync session-participants info over to shared ui state.
     * @private
     */
    private initSyncParticipantsInfoToSharedState() {
        if (!environment.appConfig.dealer.dealerBuild) {
            return;
        }

        combineLatest([
            this.applicationStateService.getLocalState().pipe(selectDealerInfo),
            this.applicationStateService.getLocalState().pipe(
                selectOne2OneState,
                map((state) => state?.targetSessionData)
            ),
            this.one2oneSessionInfoService.isOne2OneHostSessionActive$()
        ])
            .pipe(debounceTime(0))
            .subscribe(
                ([dealerInfo, targetSessionData, isHostSessionActive]) => {
                    if (!isHostSessionActive) {
                        return;
                    }

                    this.uiSharedStateService
                        .updateUiState((uiState) => {
                            uiState.participantsInfo = {
                                ...uiState.participantsInfo,
                                dealerName: dealerInfo?.knownAs ?? 'N/A',
                                sessionName: targetSessionData?.description
                            };
                            return uiState;
                        })
                        .subscribe();
                }
            );
    }

    private initSyncProductDataToSharedState() {
        if (!environment.appConfig.dealer.dealerBuild) {
            return;
        }

        combineLatest([
            this.productDataService.getActiveProductInfo$(),
            this.productDataService.getAvailableAnimations$(),
            this.productDataService.getAvailableCameras$(),
            this.productDataService.getAvailableCinematics$(),
            this.productDataService.getAvailableEnvironments$(),
            this.productDataService.getAvailableHighlights$()
        ])
            .pipe(
                debounceTime(0),
                map(
                    ([
                        productInfo,
                        animations,
                        cameras,
                        cinematics,
                        environments,
                        highlights
                    ]): One2OneProductData => ({
                        isAuthorizedOnly: !!productInfo?.isAuthorizedOnly,
                        modelId: productInfo?.modelId ?? '',
                        animations: animations ?? [],
                        cameras: cameras ?? {
                            cameras: [],
                            defaultExt: '',
                            defaultInt: ''
                        },
                        cinematics: cinematics ?? [],
                        environments: environments ?? [],
                        highlights: highlights ?? []
                    })
                ),
                withLatestFrom(
                    this.one2oneSessionInfoService.isOne2OneHostSessionActive$()
                )
            )
            .subscribe(([productData, hostSessionActive]) => {
                if (!hostSessionActive) {
                    return;
                }

                this.uiSharedStateService
                    .updateUiState((uiState) => ({
                        ...uiState,
                        productData
                    }))
                    .subscribe();
            });
    }
}
