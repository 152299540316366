import { distinctUntilChanged, map } from 'rxjs';

import {
    Directive,
    EmbeddedViewRef,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { DealerInfoService } from '@mhp/aml-ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * Allows to easily show or hide features depending on the
 * confidential status of the current dealer.
 */
@UntilDestroy()
@Directive({
    selector: '[mhpConfidentialDealerFeature]'
})
export class ConfidentialDealerFeatureDirective implements OnInit {
    private embeddedView?: EmbeddedViewRef<unknown>;

    @Input()
    mhpConfidentialDealerFeature?: {
        requireInternal?: boolean;
    };

    constructor(
        private readonly templateRef: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
        private readonly dealerInformationService: DealerInfoService
    ) {}

    ngOnInit() {
        this.dealerInformationService
            .getActiveDealerInfo$()
            .pipe(
                map((dealerInfo) => [
                    !!dealerInfo?.isConfidential,
                    !!dealerInfo?.isInternal
                ]),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe(([isConfidential, isInternal]) => {
                this.updateView(isConfidential, isInternal);
            });
    }

    private updateView(isConfidential: boolean, isInternal: boolean) {
        if (
            isConfidential &&
            (!this.mhpConfidentialDealerFeature?.requireInternal || isInternal)
        ) {
            if (this.embeddedView) {
                // no need to render again
                return;
            }
            this.embeddedView = this.viewContainer.createEmbeddedView(
                this.templateRef
            );
        } else {
            this.embeddedView = undefined;
            this.viewContainer.clear();
        }
    }
}
