import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UiBaseComponent } from '@mhp/ui-components';

import {
    ROUTE_MODEL_SELECT,
    ROUTE_ORDER_MANAGEMENT,
    ROUTE_ORDER_OVERVIEW
} from '../../app-route-names';

@Component({
    selector: 'mhp-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailComponent extends UiBaseComponent {
    constructor(private readonly router: Router) {
        super();
    }

    navigateToMainPage() {
        this.router.navigateByUrl(`INT/${ROUTE_MODEL_SELECT}`);
    }

    navigateToOMOverview() {
        this.router.navigateByUrl(
            `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_OVERVIEW}`
        );
    }

    openOrderMangementView() {
        this.router.navigateByUrl(
            `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_OVERVIEW}`
        );
    }
}
