import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { UiIconButtonModule, UiTurntableModule } from '@mhp/ui-components';

import { LoadingIndicatorModule } from '../../common/loading-indicator/loading-indicator.module';
import { DialogModule } from '../../dialog/dialog.module';
import { HeaderBarModule } from '../../header-bar/header-bar.module';
import { TurntableDialogComponent } from './turntable-dialog.component';

@NgModule({
    declarations: [TurntableDialogComponent],
    imports: [
        CommonModule,
        HttpClientModule,

        UiIconButtonModule,
        UiTurntableModule,

        HeaderBarModule,
        DialogModule,
        LoadingIndicatorModule
    ],
    exports: [TurntableDialogComponent]
})
export class TurntableDialogModule {}
