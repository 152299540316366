import { Directive, ElementRef } from '@angular/core';

import { UiPreventPropagationBase } from './ui-prevent-propagation-base';

/**
 * Stops propagation of mousedown and touchstart events for the element
 * it is applied to.
 */
@Directive({
    selector: '[mhpUiStopClickPropagation]'
})
export class UiStopClickPropagationDirective extends UiPreventPropagationBase {
    constructor(elementRef: ElementRef<Element>) {
        super(elementRef, ['click']);
    }
}
