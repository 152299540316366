import { ClipboardModule } from 'ngx-clipboard';
import { Pagination } from 'swiper';

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { ConfigurationAreaActionsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-area-actions';
import {
    UiButtonModule,
    UiCheckboxModule,
    UiExpansionPanelModule,
    UiIconButtonModule,
    UiIconsModule,
    UiImgComparisonSliderModule,
    UiLazyloadImageModule,
    UiPreventNestedRippleModule,
    UiSlideToggleModule,
    UiStopClickPropagationModule,
    UiSwiperModule,
    UiTabsModule,
    UiTooltipModule
} from '@mhp/ui-components';
import {
    GoogleTagManagerModule,
    I18nModule,
    L10nModule,
    MarkdownModule
} from '@mhp/ui-shared-services';

import { FooterModule } from '../../common/footer/footer.module';
import { SocialSharingModule } from '../../common/social-sharing/social-sharing.module';
import { DealerHelperModule } from '../../dealer/dealer-helper/dealer-helper.module';
import { UiProductDataModule } from '../../product-data/ui-product-data.module';
import { FridgeLabelModule } from '../configuration-area/fridge-label/fridge-label.module';
import { ConfigurationPricingModule } from '../configuration-pricing/configuration-pricing.module';
import { SalesforceDialogsModule } from '../salesforce/salesforce-dialogs.module';
import { StaticRendererModule } from '../static-renderer/static-renderer.module';
import { TurntableDialogModule } from '../turntable-dialog/turntable-dialog.module';
import { ConfigurationSummaryService } from './configuration-summary.service';
import { ConfigurationSummaryComponent } from './configuration-summary/configuration-summary.component';
import { SummaryImageProviderService } from './configuration-summary/summary-image-provider.service';
import { RequestPdfModule } from './request-pdf/request-pdf.module';
import { SummarySliderComponent } from './summary-slider/summary-slider.component';

UiSwiperModule.registerFeatures(Pagination);

@NgModule({
    declarations: [ConfigurationSummaryComponent, SummarySliderComponent],
    imports: [
        CommonModule,
        ClipboardModule,
        I18nModule,
        L10nModule,
        UiIconButtonModule,
        UiIconsModule,
        UiCheckboxModule,
        UiTabsModule,
        UiButtonModule,
        UiTooltipModule,
        UiLazyloadImageModule,
        UiSwiperModule,
        UiExpansionPanelModule,
        UiStopClickPropagationModule,
        UiPreventNestedRippleModule,
        UiImgComparisonSliderModule,
        MatRippleModule,
        StaticRendererModule,
        TurntableDialogModule,
        SalesforceDialogsModule,
        DealerHelperModule,
        FooterModule,
        FridgeLabelModule,
        GoogleTagManagerModule,
        ConfigurationPricingModule,
        ConfigurationAreaActionsModule,
        UiProductDataModule,
        MarkdownModule,
        SocialSharingModule,
        RequestPdfModule,
        UiSlideToggleModule
    ],
    exports: [ConfigurationSummaryComponent]
})
export class ConfigurationSummaryModule {
    static forRoot(): ModuleWithProviders<ConfigurationSummaryModule> {
        return {
            providers: [
                ConfigurationSummaryService,
                SummaryImageProviderService
            ],
            ngModule: ConfigurationSummaryModule
        };
    }
}
