import { EMPTY, catchError, from } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from '@mhp/ui-shared-services';
import { UntilDestroy } from '@ngneat/until-destroy';

import { ConfigurationSubmitService } from '../configuration-submit/configuration-submit.service';
import { VisualizationMode } from '../state';
import { VisualizationModeService } from '../visualization-mode/visualization-mode.service';

export const DEEPLINK_SUBMIT_TO_DEALER = 'submit_to_dealer';

/**
 * Service to be provided locally on a component level.
 * To trigger the deeplink handling, call #handleDeeplink in components #ngOnInit() callback.
 */
@UntilDestroy()
@Injectable()
export class ConfigurationMainDeeplinkService {
    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly configurationSubmitService: ConfigurationSubmitService,
        private readonly visualizationModeService: VisualizationModeService
    ) {}

    /**
     * Checks the URL for a 'deeplink' search-param and handles the appropriate case.
     * Supported values:
     * - submit_to_dealer: Directly opens the "Submit to Dealer" dialog
     */
    handleDeeplink(
        options = {
            skipClearSearchParam: false
        }
    ) {
        const { deeplink } = this.activatedRoute.snapshot.queryParams;

        if (!deeplink) {
            return;
        }

        // remove query-param from route
        if (!options?.skipClearSearchParam) {
            from(
                this.router.navigate([], {
                    queryParams: {
                        deeplink: null
                    },
                    queryParamsHandling: 'merge',
                    relativeTo: this.activatedRoute,
                    replaceUrl: true
                })
            )
                .pipe(
                    this.errorHandlerService.applyRetry(),
                    catchError(() => EMPTY)
                )
                .subscribe();
        }

        switch (deeplink) {
            case DEEPLINK_SUBMIT_TO_DEALER:
                // force visualization mode to IOD
                this.visualizationModeService.setTargetVisualizationMode(
                    VisualizationMode.BASIC
                );
                // open the submit to dealer dialog
                this.configurationSubmitService.intentSubmitConfiguration();
                break;
            default:
                break;
        }
    }
}
