import { defaultsDeepCustom } from './environment-helper';
import { defaultDealerEnvironmentConfig } from './environment.dealer.default';
import { environmentDevelopmentAdjusts } from './environment.development';

const AML_CLIENT_DEALER_BASE_URL =
    'https://aml-infor-dealer-4q6zszk7ge7yhcs.api-services.astonmartin.com';

export const environmentDealerDevelopmentAdjusts = {
    appConfig: {
        dealer: {
            redirectUrl: AML_CLIENT_DEALER_BASE_URL
        },
        featureToggles: {
            enableIncludePricingInPdf: true
        }
    }
};
export const environment = defaultsDeepCustom(
    {},
    environmentDealerDevelopmentAdjusts,
    environmentDevelopmentAdjusts,
    defaultDealerEnvironmentConfig
);
