import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    EngineControlModule,
    ErrorHandlerModule,
    FeatureAvailabilityModule,
    UiSharedStateModule
} from '@mhp/ui-shared-services';

import { AnimationControlService } from './animation-control/animation-control.service';
import { ConfigurationSessionImageAssetService } from './assets/configuration-session-image-asset.service';
import { CameraControlService } from './camera-control/camera-control.service';
import { CarFeatureControlService } from './car-features/car-feature-control.service';
import { ConfigurationCompositeItemService } from './configuration-area/configuration-elements/configuration-composite-item/configuration-composite-item.service';
import { EditionsService } from './configuration-area/editions/editions.service';
import {
    ENVIRONMENT_SELECTION_DIALOG_SERVICE_CONFIG_TOKEN,
    EnvironmentSelectionDialogService,
    EnvironmentSelectionDialogServiceConfig
} from './configuration-area/environment-selection/environment-selection-dialog.service';
import { ConfigurationMenuModule } from './configuration-menu/configuration-menu.module';
import { ConfigurationSummaryModule } from './configuration-summary/configuration-summary.module';
import { EnvironmentControlService } from './environment-control/environment-control.service';
import { EnvironmentInfoService } from './environment-info/environment-info.service';
import { InfoLayerModule } from './info-layer/info-layer.module';
import { SalesforceContextService } from './salesforce/salesforce-context.service';
import { ProductConfigurationSessionService } from './services/product-configuration-session.service';
import { VrModule } from './vr/vr.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,

        InfoLayerModule.forRoot({
            pushActiveItemToUiSharedState: true
        }),
        ConfigurationSummaryModule.forRoot(),
        ConfigurationMenuModule,
        VrModule,

        // UI SHARED SERVICES
        ErrorHandlerModule,
        EngineControlModule.forRoot(),
        FeatureAvailabilityModule.forRoot(),
        UiSharedStateModule.forRoot()
    ],
    providers: [
        CameraControlService,
        AnimationControlService,
        EnvironmentControlService,
        EnvironmentInfoService,
        CarFeatureControlService,

        ProductConfigurationSessionService,
        ConfigurationSessionImageAssetService,
        ConfigurationCompositeItemService,
        SalesforceContextService,
        EditionsService,

        // ENVIRONMENT-SELECTION-DIALOG
        EnvironmentSelectionDialogService,
        {
            provide: ENVIRONMENT_SELECTION_DIALOG_SERVICE_CONFIG_TOKEN,
            useValue: <EnvironmentSelectionDialogServiceConfig>{
                pushStateToUiSharedState: true
            }
        }
    ]
})
export class ConfigurationModule {}
