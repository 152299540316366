import { isString } from 'lodash-es';
import { filter } from 'rxjs/operators';

import { CommonModule, DOCUMENT } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    ApplicationStateService,
    ENGINE_CONNECTION_STATE_SERVICE_CONFIG_TOKEN,
    ENGINE_CONTROL_SOCKET_STRATEGY_CONFIG_TOKEN,
    EngineConnectionStateModule,
    EngineConnectionStateServiceConfig,
    EngineControlSocketStrategy,
    EngineControlSocketStrategyConfig,
    EngineStatePatchModule,
    SOCKET_IO_SERVICE_CONFIG_TOKEN,
    SocketIOModule,
    SocketIOServiceConfig,
    StreamingModule,
    UiSharedStateSocketStrategy,
    selectEngineHostUrl,
    selectStreamingState
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { ConfigurationTrackpadModule } from '../configuration-trackpad/configuration-trackpad.module';
import { StreamHandlerService } from './stream-handler.service';
import { StreamStageComponent } from './stream-stage/stream-stage.component';

@NgModule({
    declarations: [StreamStageComponent],
    imports: [
        // THIRDPARTY
        CommonModule,

        // COMPONENTS
        ConfigurationTrackpadModule,

        // PLATFORM
        // import SocketIOModule to have a distinct instance of the SocketIOService available with a config that binds it to available streams
        SocketIOModule.forRoot(),
        EngineStatePatchModule.forRoot(),
        EngineConnectionStateModule.forRoot(),
        StreamingModule.forRoot()
    ],
    exports: [StreamStageComponent],
    providers: [
        StreamHandlerService,

        // STRATEGIES
        EngineControlSocketStrategy,
        UiSharedStateSocketStrategy,

        // TOKENS
        {
            provide: SOCKET_IO_SERVICE_CONFIG_TOKEN,
            useFactory: (applicationStateService: ApplicationStateService) => {
                const config: SocketIOServiceConfig = {
                    sockJsUrl: applicationStateService
                        .getLocalSharedState()
                        .pipe(selectStreamingState, selectEngineHostUrl)
                };
                return config;
            },
            deps: [ApplicationStateService]
        },
        {
            provide: ENGINE_CONTROL_SOCKET_STRATEGY_CONFIG_TOKEN,
            useFactory: (
                applicationStateService: ApplicationStateService,
                document: Document
            ) => {
                const config: EngineControlSocketStrategyConfig = {
                    engineHttpServerUrl: applicationStateService
                        .getLocalSharedState()
                        .pipe(
                            selectStreamingState,
                            selectEngineHostUrl,
                            filter((url): url is string => !!url)
                        ),
                    cinematicDownloadOrigin: isString(
                        environment.appConfig.visualization
                            .cinematicDownloadOriginOverride
                    )
                        ? environment.appConfig.visualization
                              .cinematicDownloadOriginOverride
                        : document.location.origin,
                    cinematicDownloadTimeout: 5 * 60 * 1000
                };
                return config;
            },
            deps: [ApplicationStateService, DOCUMENT]
        },
        {
            provide: ENGINE_CONNECTION_STATE_SERVICE_CONFIG_TOKEN,
            useValue: <EngineConnectionStateServiceConfig>{
                applicationHeartbeatInterval:
                    environment.appConfig.engineHeartbeatInterval,
                applicationHeartbeatClient: 'tablet-ui' // FIXME: Adjust to project-specific identifier
            }
        }
    ]
})
export class StreamModule {}
