import { defer as deferLodash } from 'lodash-es';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { L10nService } from '@mhp/ui-shared-services';

import { ROUTE_ORDER_MANAGEMENT } from '../../app-route-names';
import { ConfigurationSessionInfoService } from '../../configuration/session-info/configuration-session-info.service';
import { DEFAULT_RENDERING_ADJUSTMENTS } from '../../configuration/static-renderer/static-renderer-adjustments.constants';
import { LabelHelperService } from '../../i18n/label-helper.service';
import { OrderManagementService } from '../../order-management/order-management.service';
import { SalesforceFormComponent } from '../salesforce/salesforce-form/salesforce-form.component';

declare type SalesforceVoeFormId = 'dealer-voe-form' | 'dealer-salesforce-form';

@Component({
    selector: 'mhp-dealer-salesforce-ion',
    templateUrl: './dealer-salesforce-ion.component.html',
    styleUrls: ['./dealer-salesforce-ion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerSalesforceIonComponent
    extends UiBaseComponent
    implements AfterViewInit
{
    @ViewChild(SalesforceFormComponent)
    salesforceFormComponentRef?: SalesforceFormComponent;

    readonly DEFAULT_RENDERING_ADJUSTMENTS = DEFAULT_RENDERING_ADJUSTMENTS;

    private readonly activeTabSubject = new BehaviorSubject<number>(1);

    private readonly viewChildReferencesBound = new BehaviorSubject<boolean>(
        false
    );

    readonly activeTabIndex$ = this.activeTabSubject.asObservable();

    readonly ROUTE_ORDER_MANAGEMENT = ROUTE_ORDER_MANAGEMENT;

    constructor(
        private readonly l10nService: L10nService,
        private readonly labelHelperService: LabelHelperService,
        private readonly orderManagementService: OrderManagementService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService
    ) {
        super();

        this.completeOnDestroy(
            this.activeTabSubject,
            this.viewChildReferencesBound
        );
    }

    ngAfterViewInit() {
        deferLodash(() => this.viewChildReferencesBound.next(true));
    }

    @MemoizeObservable()
    getModelLabel$() {
        return this.labelHelperService.getActiveModelAndProductName$();
    }

    @MemoizeObservable()
    getActiveProductId$(): Observable<string | undefined> {
        return this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                map((sessionInfo): string | undefined => {
                    if (!sessionInfo) {
                        return '';
                    }
                    return sessionInfo.productId;
                })
            );
    }

    @MemoizeObservable()
    getActiveCountry$() {
        return this.l10nService.getActiveCountry$();
    }

    @MemoizeObservable()
    getActiveFormId$(): Observable<SalesforceVoeFormId | undefined> {
        return combineLatest([
            this.activeTabSubject,
            this.viewChildReferencesBound
        ]).pipe(
            map(([index, referencesBound]) => {
                if (!referencesBound) {
                    return undefined;
                }
                const string =
                    index === 0 ? 'dealer-salesforce-form' : 'dealer-voe-form';
                return string;
            })
        );
    }

    @MemoizeObservable()
    canSubmit$(): Observable<boolean> {
        return this.getActiveFormId$().pipe(
            switchMap((formId) => {
                if (!formId) {
                    return of([false, false]);
                }

                let formComponentRef;
                let formGroup;

                if (formId === 'dealer-salesforce-form') {
                    formComponentRef = this.salesforceFormComponentRef;
                    formGroup = formComponentRef?.salesforceFormGroup;
                }

                if (!formComponentRef || !formGroup) {
                    return of([false, false]);
                }

                return combineLatest([
                    of(true),
                    formComponentRef.getServerCallInProgress$()
                ]);
            }),
            map(
                ([formAvailable, formSubmitInProgress]) =>
                    formAvailable && !formSubmitInProgress
            ),
            lazyShareReplay()
        );
    }

    onSelectedTabIndexChange(index: number) {
        this.activeTabSubject.next(index);
    }

    openOrderManagementEditView$() {
        this.handleServerCallInProgress(
            this.orderManagementService
                .openEditView$()
                .pipe(take(1), this.takeUntilDestroy())
        ).subscribe();
    }

    saveConfiguration() {
        this.orderManagementService
            .openSaveConfigurationDialog$(this.viewContainerRef)
            .pipe(take(1), this.takeUntilDestroy())
            .subscribe();
    }
}
