import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Output
} from '@angular/core';

@Component({
    selector: 'mhp-load-model-year-dialog',
    templateUrl: './load-model-year-dialog.component.html',
    styleUrls: ['./load-model-year-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadModelYearDialogComponent {
    @Output()
    action = new EventEmitter<'RESET' | 'UPGRADE'>();

    intentLoadCode(action: 'RESET' | 'UPGRADE') {
        this.action.emit(action);
    }
}
