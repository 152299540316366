import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { SocialSharingService } from '@mhp/aml-ui-shared-services/social-sharing';
import { IllegalStateError, MemoizeObservable } from '@mhp/common';
import {
    ErrorHandlerService,
    UrlShortenerService
} from '@mhp/ui-shared-services';

import { LabelHelperService } from '../../i18n/label-helper.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationSocialSharingService {
    constructor(
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly urlShortenerService: UrlShortenerService,
        private readonly socialSharingService: SocialSharingService,
        private readonly labelHelperService: LabelHelperService
    ) {}

    /**
     * Share the currently active configuration to the provided social service.
     * @param socialServiceId
     * @param config
     */
    shareCurrentConfiguration$(
        socialServiceId: string,
        config?: {
            urlModifier?: (url) => string;
            overrideTitle?: string;
            overrideDescription?: string;
        }
    ): Observable<void> {
        return combineLatest([
            this.getShortenedConfigurationUrl$(config?.urlModifier),
            config?.overrideTitle
                ? of(config.overrideTitle)
                : this.labelHelperService.getActiveModelAndProductName$().pipe(
                      map((productName) =>
                          translate<string>(
                              'SOCIAL_SHARING.CONFIGURATOR.HEADLINE',
                              {
                                  productName
                              }
                          )
                      )
                  )
        ]).pipe(
            take(1),
            switchMap(([url, title]) =>
                this.socialSharingService.intentShareToSocial(
                    socialServiceId,
                    url,
                    title,
                    config?.overrideDescription
                        ? config.overrideDescription
                        : translate('SOCIAL_SHARING.CONFIGURATOR.DESCRIPTION')
                )
            ),
            this.errorHandlerService.applyRetry()
        );
    }

    @MemoizeObservable()
    getShortenedConfigurationUrl$(urlModifier?: (url: string) => string) {
        return this.configurationSessionInfoService
            .getCurrentConfigurationUrl$()
            .pipe(
                switchMap((configUrl) => {
                    if (!configUrl) {
                        throw new IllegalStateError(
                            'Missing configuration URL'
                        );
                    }
                    return this.urlShortenerService
                        .shortenUrl$(
                            urlModifier ? urlModifier(configUrl) : configUrl
                        )
                        .pipe(
                            this.errorHandlerService.applyRetry({
                                messageProviderOnFinalError: () =>
                                    translate(
                                        'SUMMARY.CONFIGURATION_SUMMARY.ERROR.FAILED_GENERATING_URL'
                                    )
                            })
                        );
                })
            );
    }

    @MemoizeObservable()
    getNormalizedShortenedConfigurationUrl$(
        urlModifier?: (url: string) => string
    ) {
        return this.configurationSessionInfoService
            .getNormalizedCurrentConfigurationUrl$()
            .pipe(
                switchMap((configUrl) => {
                    if (!configUrl) {
                        throw new IllegalStateError(
                            'Missing configuration URL'
                        );
                    }
                    return this.urlShortenerService
                        .shortenUrl$(
                            urlModifier ? urlModifier(configUrl) : configUrl
                        )
                        .pipe(
                            this.errorHandlerService.applyRetry({
                                messageProviderOnFinalError: () =>
                                    translate(
                                        'SUMMARY.CONFIGURATION_SUMMARY.ERROR.FAILED_GENERATING_URL'
                                    )
                            })
                        );
                })
            );
    }
}
