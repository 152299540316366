<div class="order-management__table-entry" *transloco="let t">
    <div
        class="order-management__table-form-group-title-left"
        [ngClass]="{
            'order-management__table-form-group-title-left--dialog': isDialog
        }"
    >
        {{ formOption.label }}{{ formOption.isRequired ? '&nbsp;*' : '' }}
        <span
            class="order-management__info-text"
            *ngIf="formOption.showInfoText"
            >{{ t('ORDER_MANAGEMENT.NOTIFICATION.VERIFY_TEXT') }}
        </span>
    </div>
    <div
        class="order-management__table-form-group-description-left"
        [ngClass]="{
            'order-management__table-form-group-description-left--dialog':
                isDialog
        }"
    >
        <form
            class="aml-form__field-group"
            [formGroup]="orderFormGroup"
            [attr.id]="formId"
        >
            <mat-form-field
                class="mhp-ui-form-field--inherit"
                *ngIf="formOption && formOption?.type === 'textarea'"
                floatLabel="always"
            >
                <input
                    matInput
                    #fieldRef
                    type="text"
                    autocomplete="off"
                    [id]="formOption.formGroupTitle"
                    [formControlName]="formOption.formGroupTitle"
                    [required]="formOption.isRequired"
                    (blur)="
                        onSelectionChange(
                            formOption.id,
                            formOption.formGroupTitle
                        )
                    "
                />
            </mat-form-field>
            <mat-form-field
                class="mhp-ui-form-field--inherit"
                *ngIf="
                    formOption && ['select', 'radio'].includes(formOption?.type)
                "
                floatLabel="always"
            >
                <mat-select
                    #fieldRef
                    [id]="formOption.formGroupTitle"
                    [required]="formOption.isRequired"
                    [formControlName]="formOption.formGroupTitle"
                    (selectionChange)="
                        onSelectionChange(
                            formOption.id,
                            formOption.formGroupTitle
                        )
                    "
                >
                    <mat-option
                        *ngIf="
                            !formOption.isRequired &&
                            formOption.type === 'select'
                        "
                        [value]="''"
                    >
                        {{
                            t('ORDER_MANAGEMENT.LABEL.NO_ITEM_VALUE', {
                                noItem: formOption.label
                            })
                        }}
                    </mat-option>
                    <mat-option
                        *ngFor="let option of formOption.options"
                        [value]="option.value"
                        >{{ option.label }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </form>
    </div>
    <div
        class="order-management__table-pricing-col-right"
        *ngIf="showPricingDNP"
    >
        {{
            (formOption.currentValuePrice.dnp
                | translocoCurrency: undefined:undefined:currency) ||
                formOption.currentValuePrice.noPrice
        }}
    </div>
    <div
        class="order-management__table-pricing-col-right"
        *ngIf="showPricingRRP"
    >
        {{
            (formOption.currentValuePrice.rrp
                | translocoCurrency: undefined:undefined:currency) ||
                formOption.currentValuePrice.noPrice
        }}
    </div>
</div>
