<div class="search-term__indicator-search-term">
    <span>{{ searchTerm }}</span>
    <mhp-ui-icon-button
        *transloco="let t; read: 'ICONS'"
        [matTooltip]="t('CLOSE_ALT')"
        [label]="t('CLOSE_ALT')"
        svgIcon="mhp-ui:close"
        size="small"
        (click)="intentExitSearch()"
    ></mhp-ui-icon-button>
</div>
