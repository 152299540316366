<div class="audio-video-settings-dialog__container">
    <div class="audio-video-settings-dialog__container__control">
        <form
            class="audio-video-settings-dialog__form"
            [formGroup]="audioVideoSettingsFormGroup"
            *transloco="
                let tAudioVideoSettingsDialog;
                read: 'AUDIO_VIDEO_SETTINGS.DIALOG'
            "
        >
            <mat-slide-toggle
                color="primary"
                formControlName="video"
                [labelPosition]="slideToggleLabelPosition"
                class="audio-video-settings-dialog__container__control__slide-toggle"
                >{{
                    tAudioVideoSettingsDialog('VIDEO_CONTROL_TOGGLE')
                }}</mat-slide-toggle
            >
            <mat-slide-toggle
                color="primary"
                formControlName="audio"
                [labelPosition]="slideToggleLabelPosition"
                class="audio-video-settings-dialog__container__control__slide-toggle"
                >{{
                    tAudioVideoSettingsDialog('AUDIO_CONTROL_TOGGLE')
                }}</mat-slide-toggle
            >
        </form>
    </div>
</div>
