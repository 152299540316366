import { Directive, HostListener, Input } from '@angular/core';

import { gtmGA4Track } from './google-tag-manager.service';
import { TrackingProperties } from './tracking-properties.interface';

/**
 * Track an GA4 event on click.
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[trackGA4Event]'
})
export class TrackGA4EventDirective {
    @Input()
    trackGA4Event: {
        event: string;
    } & TrackingProperties;

    @Input()
    trackBlur = false;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    @HostListener('click')
    @HostListener('keyup.enter')
    trackEventListener() {
        if (!this.trackBlur) {
            const { event, ...properties } = this.trackGA4Event;
            gtmGA4Track(event, properties);
        }
    }

    @HostListener('blur')
    trackBlurListener() {
        if (this.trackBlur) {
            const { event, ...properties } = this.trackGA4Event;
            gtmGA4Track(event, properties);
        }
    }
}
