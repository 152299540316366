import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-video-playback',
    templateUrl: './video-playback.component.html',
    styleUrls: ['./video-playback.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlaybackComponent extends UiBaseComponent implements OnInit {
    @ViewChild('video', {
        static: true
    })
    videoElementRef: ElementRef<HTMLVideoElement>;

    @Input()
    videoUrl: string;

    @Output()
    readonly videoError = new EventEmitter<ErrorEvent>();

    videoReadyToShow: boolean;

    constructor(
        private readonly matDialogRef: MatDialogRef<VideoPlaybackComponent>
    ) {
        super();

        this.completeOnDestroy(this.videoError);
    }

    ngOnInit() {
        this.videoElementRef.nativeElement.play();
    }

    onError(error: ErrorEvent) {
        this.videoError.next(error);
    }

    onFinished() {
        this.matDialogRef.close();
    }

    onEnded() {
        this.matDialogRef.close();
    }

    togglePlayback() {
        const videoElement = this.videoElementRef.nativeElement;
        if (videoElement.controls) {
            return;
        }
        if (!videoElement.ended && !videoElement.paused) {
            videoElement.pause();
        } else {
            videoElement.play();
        }
    }

    onLoadedMetadata() {
        this.videoReadyToShow = true;
    }
}
