import { zip } from 'lodash-es';
import { Observable, ReplaySubject, combineLatest, switchMap } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Optional,
    Output,
    QueryList,
    ViewChildren,
    input
} from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { TRACK_BY_ID, UiBaseComponent } from '@mhp/ui-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ExtendedUiOptionCode } from '../../../configuration-model/configuration-interfaces';
import { ConfigurationCodeComponent } from '../configuration-code/configuration-code.component';
import { ConfigurationOptionsListSupportService } from './configuration-options-list-support.service';

export interface OptionIdWithVisibilityState {
    id: string;
    visibilityState: boolean;
}

@UntilDestroy()
@Component({
    selector: 'mhp-configuration-options-list',
    templateUrl: './configuration-options-list.component.html',
    styleUrls: ['./configuration-options-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationOptionsListComponent extends UiBaseComponent {
    readonly trackById = TRACK_BY_ID;

    @ViewChildren('configurationCode')
    set configurationCodeElementRefs(
        queryList: QueryList<ConfigurationCodeComponent>
    ) {
        this.configurationCodeElementRefsSubject.next(queryList);
    }

    /**
     * The options to be rendered
     */
    @Input()
    options: ExtendedUiOptionCode[];

    @Input()
    useOverflowIndicator = true;

    @Input()
    readonly = false;

    optionSelectionDisabled = input(false);

    @Output()
    readonly optionSelected = new EventEmitter<string>();

    @Output()
    readonly optionHighlighted = new EventEmitter<
        ExtendedUiOptionCode | undefined
    >();

    @Output()
    readonly infoRequested = new EventEmitter<ExtendedUiOptionCode>();

    @Output()
    get optionsRenderingVisibilityStates(): Observable<
        OptionIdWithVisibilityState[]
    > {
        return this.getOptionListVisibilityStream$();
    }

    private readonly configurationCodeElementRefsSubject = new ReplaySubject<
        QueryList<ConfigurationCodeComponent>
    >(1);

    constructor(
        @Optional()
        private readonly configurationOptionsListSupportService?: ConfigurationOptionsListSupportService
    ) {
        super();

        this.configurationOptionsListSupportService?.registerOptionListVisibilityStream$(
            this.getOptionListVisibilityStream$()
        );

        this.completeOnDestroy(
            this.optionSelected,
            this.optionHighlighted,
            this.infoRequested
        );
    }

    onOptionCodeSelected(code: string) {
        this.optionSelected.next(code);
    }

    onOptionCodeHighlighted(
        optionCode: ExtendedUiOptionCode,
        highlightState: boolean
    ) {
        this.optionHighlighted.next(highlightState ? optionCode : undefined);
    }

    onInfoRequested(optionCode: ExtendedUiOptionCode) {
        this.infoRequested.next(optionCode);
    }

    @MemoizeObservable()
    private getOptionListVisibilityStream$() {
        return this.configurationCodeElementRefsSubject.pipe(
            switchMap((queryList) =>
                queryList.changes.pipe(startWith(queryList.toArray()))
            ),
            distinctUntilChanged(),
            switchMap(
                (configurationCodeRefs) =>
                    <Observable<OptionIdWithVisibilityState[]>>combineLatest(
                        configurationCodeRefs.map(
                            (configurationCodeRef) =>
                                configurationCodeRef.renderingVisibilityState
                        )
                    ).pipe(
                        map((visibilityStates: boolean[]) =>
                            zip(
                                configurationCodeRefs.map((ref) => ref.data),
                                visibilityStates
                            )
                        ),
                        map(
                            (
                                zippedElements: [
                                    ExtendedUiOptionCode,
                                    boolean
                                ][]
                            ): OptionIdWithVisibilityState[] =>
                                zippedElements.map((zippedElement) => ({
                                    id: zippedElement[0]?.id,
                                    visibilityState: zippedElement[1]
                                }))
                        )
                    )
            ),
            untilDestroyed(this),
            lazyShareReplay()
        );
    }
}
