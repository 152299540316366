<h2 class="engine-settings__title">
    {{ title }}
</h2>

<div class="engine-settings__items-wrapper">
    <mhp-overflow-indicator
        [prevTemplate]="prevItems"
        [nextTemplate]="nextItems"
        [scrollableElementRef]="engineSettingsScrollable"
    ></mhp-overflow-indicator>
    <ng-template #prevItems>
        <button
            class="engine-settings__overflow-indicator engine-settings__overflow-indicator--left"
            aria-label="Show previous items"
            matRipple
        >
            <mhp-ui-icon
                style="transform: rotateY(180deg)"
                svgIcon="mhp-ui:angle-right"
            ></mhp-ui-icon>
        </button>
    </ng-template>
    <ng-template #nextItems>
        <button
            class="engine-settings__overflow-indicator engine-settings__overflow-indicator--right"
            aria-label="Show next items"
            matRipple
        >
            <mhp-ui-icon svgIcon="mhp-ui:angle-right"></mhp-ui-icon>
        </button>
    </ng-template>

    <div class="engine-settings__items" cdkScrollable #engineSettingsScrollable>
        <mhp-ui-grid-list-item
            *ngFor="let item of items; trackBy: trackById"
            #listItem
            [imageUrl]="item.imageUrl"
            [imageFallbackUrl]="imageFallbackUrl"
            [disabled]="!!item.disabled"
            [imageAspectRatio]="item.imageAspectRatio"
            [selected]="!!item.selected"
            [title]="item.title"
            [iconId]="item.iconId"
            [willResize]="true"
            (intentSelect)="intentSelectItem(item.id)"
            color="primary"
        >
            <mhp-ui-grid-list-item__extension-bottom
                *ngIf="item.hasInfoContent"
            >
                <mhp-ui-icon-button
                    *transloco="let t; read: 'ICONS'"
                    class="engine-settings__info-cta"
                    svgIcon="mhp-ui:info2"
                    [size]="BUTTON_SIZE.XSMALL"
                    [label]="
                        t('INFO_ON_ALT', {
                            topic: item.title
                        })
                    "
                    (click)="intentShowInfo(item.id, $event)"
                ></mhp-ui-icon-button>
            </mhp-ui-grid-list-item__extension-bottom>
        </mhp-ui-grid-list-item>
    </div>
</div>
