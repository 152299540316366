<ng-container *transloco="let t">
    <mhp-header-bar>
        <ng-container header-bar_area-left>
            <div>
                <mhp-ui-icon-button
                    svgIcon="mhp-ui:back_2"
                    color="black"
                    size="large"
                    (click)="openOrderMangementView()"
                ></mhp-ui-icon-button>
            </div>
        </ng-container>
        <ng-container header-bar_area-right>
            <mhp-ui-icon-button
                class="order-management__table-list-menu-right"
                [matMenuTriggerFor]="beforeMenu"
                svgIcon="mhp-ui:menu"
            ></mhp-ui-icon-button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="navigateToOMOverview()">
                    <mhp-ui-icon-button
                        svgIcon="mhp-ui:back_2"
                    ></mhp-ui-icon-button>
                    {{ t('ORDER_MANAGEMENT.NAVIGATION.ORDER_OVERVIEW') }}
                </button>
                <button mat-menu-item (click)="navigateToMainPage()">
                    <mhp-ui-icon-button
                        svgIcon="mhp-ui:back_2"
                    ></mhp-ui-icon-button>
                    {{ t('ORDER_MANAGEMENT.NAVIGATION.MODEL_SELECT') }}
                </button>
            </mat-menu>
        </ng-container>
    </mhp-header-bar>
    <div class="order-management__success-msg">
        <h2>{{ t('ORDER_MANAGEMENT.NOTIFICATION.SUCCESS') }}</h2>
        <p>{{ t('ORDER_MANAGEMENT.NOTIFICATION.SUBMIT_SUCCESS') }}</p>
    </div>
</ng-container>
