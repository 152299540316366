import {
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    Input,
    ViewContainerRef
} from '@angular/core';
import { UiBaseComponent, UiMatDialogService } from '@mhp/ui-components';

import { VideoChatDialogComponent } from '../video-chat-dialog/video-chat-dialog.component';

@Component({
    selector: 'mhp-video-header-container',
    templateUrl: './video-header-container.component.html',
    styleUrls: ['./video-header-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoHeaderContainerComponent extends UiBaseComponent {
    @Input()
    renderVideoElements = true;

    constructor(
        private readonly dialogService: UiMatDialogService,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        super();
    }

    intentOpenVideoChatDialog() {
        this.dialogService
            .openFullscreen$(VideoChatDialogComponent, {
                disableClose: false,
                viewContainerRef: this.viewContainerRef,
                componentFactoryResolver: this.componentFactoryResolver
            })
            .pipe(this.takeUntilDestroy())
            .subscribe();
    }
}
