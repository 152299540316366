<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    [title]="'Customer Configuration Session created'"
    [showClose]="false"
    *transloco="let t; read: 'ONE_2_ONE.CREATED_SESSION'"
>
    <mhp-ui-mat-dialog-content>
        <p>
            {{
                t('HEADLINE', {
                    sessionName: sessionData.description
                })
            }}
        </p>
        <p>
            <button
                class="mhp-ui-button--full-width"
                mat-button
                ngxClipboard
                [cbContent]="dealerLink"
                (cbOnSuccess)="onCopyDealerLinkSuccess()"
            >
                <span>Copy your Dealer-Session link</span>
                <mhp-ui-icon
                    svgIcon="mhp-ui:copy_to_cb"
                    size="small"
                ></mhp-ui-icon>
            </button>
        </p>
        <p>
            <button
                class="mhp-ui-button--full-width"
                mat-button
                ngxClipboard
                [cbContent]="customerLink"
                (cbOnSuccess)="onCopyCustomerLinkSuccess()"
            >
                <span>Copy the Customer-Session link</span>
                <mhp-ui-icon
                    svgIcon="mhp-ui:copy_to_cb"
                    size="small"
                ></mhp-ui-icon>
            </button>
        </p>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            matDialogClose
            *transloco="let t; read: 'COMMON'"
        >
            <span>{{ t('CONFIRM') }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
