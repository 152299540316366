import { isEmpty, uniqBy } from 'lodash-es';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { debounceTime, filter, first, map, switchMap } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    input
} from '@angular/core';
import { ConfigurationCompositeItemModel } from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import { sumUpEffectivePricingAwarePricings } from '@mhp/aml-ui-shared-components/pricing';
import {
    CategoryItem,
    EffectivePricingAware
} from '@mhp/aml-ui-shared-services';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { TRACK_BY_ID, UiBaseComponent } from '@mhp/ui-components';
import { ErrorHandlerService, I18nService } from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiOptionCode,
    TypeAware
} from '../../../configuration-model/configuration-interfaces';
import { InfoLayerService } from '../../../info-layer/info-layer.service';
import { SearchService } from '../../../search/search.service';
import {
    isExtendedUiOptionCode,
    isExtendedUiOptionGroup
} from '../../../services/configuration-helper';
import { ConfigurationCompositeItemService } from '../../configuration-elements/configuration-composite-item/configuration-composite-item.service';
import { CategoryMapper } from '../../helpers/category-mapper';
import { CategoryMapperService } from '../../helpers/category-mapper.service';
import { DisplayedOptionsService } from '../../helpers/displayed-options.service';

export enum RenderType {
    REGULAR = 'REGULAR',
    COMPOSITE_ITEM = 'COMPOSITE_ITEM'
}

@UntilDestroy()
@Component({
    selector: 'mhp-configuration-group-first-level',
    templateUrl: './configuration-group-first-level.component.html',
    styleUrls: ['./configuration-group-first-level.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationGroupFirstLevelComponent extends UiBaseComponent {
    readonly trackById = TRACK_BY_ID;

    readonly RENDER_TYPE = RenderType;

    @Input()
    contentAware: ExtendedUiContentAwareConfigurationMetaItem;

    @Input()
    specialSelect?: CategoryItem;

    @Input()
    expanded: boolean;

    @Input()
    renderType = RenderType.REGULAR;

    @Input()
    scrollIntoView$: Observable<string>;

    optionSelectionDisabled = input<boolean>(false);

    @Output()
    readonly expandedChange = new EventEmitter<boolean>();

    @Output()
    readonly intentSelect = new EventEmitter<string>();

    @Output()
    readonly focusOnStuck = new EventEmitter<void>();

    @ViewChild('panelBody', {
        read: ElementRef
    })
    panelBodyRef: ElementRef;

    private categoryMapper: CategoryMapper;

    constructor(
        private readonly displayedOptionsService: DisplayedOptionsService,
        private readonly i18nService: I18nService,
        private readonly infoLayerService: InfoLayerService,
        private readonly configurationCompositeItemService: ConfigurationCompositeItemService,
        private readonly searchService: SearchService,
        private readonly errorHandlerService: ErrorHandlerService,
        categoryMapperService: CategoryMapperService
    ) {
        super();

        this.categoryMapper = categoryMapperService.getCategoryItemMapper();

        this.initScrollIntoViewBinding();

        this.completeOnDestroy(this.expandedChange, this.intentSelect);
    }

    intentToggleExpandedState() {
        this.expandedChange.emit(!this.expanded);
    }

    onGroupHeaderClick() {
        this.intentToggleExpandedState();
    }

    intentSelectItem(itemId: string) {
        this.intentSelect.emit(itemId);
    }

    intentShowInfo(option?: ExtendedUiOptionCode) {
        (option ? of(option) : this.getSingleOptionSelection$())
            .pipe(
                first(),
                switchMap((selectedOption) => {
                    if (!selectedOption) {
                        return EMPTY;
                    }
                    return this.infoLayerService.showInfoLayerForOptionCode$(
                        selectedOption
                    );
                }),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    /**
     * Show additional info for the category-item in question.
     * @param categoryItem
     */
    intentShowInfoForCategoryItem(categoryItem: CategoryItem) {
        if (!categoryItem.description) {
            return;
        }

        this.infoLayerService
            .showInfoLayer$({
                description: categoryItem.description,
                itemType: 'text',
                label: categoryItem.label,
                thumbnailUrls: []
            })
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    @MemoizeObservable()
    getConfigurationCompositeItemModels$(): Observable<
        ConfigurationCompositeItemModel[] | undefined
    > {
        return this.configurationCompositeItemService.mapToConfigurationCompositeItemModels$(
            this.getContentAware$().pipe(
                map((contentAware) =>
                    isExtendedUiOptionGroup(contentAware)
                        ? contentAware
                        : undefined
                )
            )
        );
    }

    @MemoizeObservable()
    getSingleOptionSelection$() {
        return combineLatest([
            this.getContentAware$(),
            this.getAllOptionsInHierarchy$()
        ]).pipe(
            map(([contentAware, allOptions]) => {
                if (
                    contentAware.type === 'Collection' ||
                    (<TypeAware[]>contentAware.content).every(
                        (item) => item.type === 'Collection'
                    )
                ) {
                    return allOptions?.find((option) => option.selected);
                }
                return undefined;
            })
        );
    }

    @MemoizeObservable()
    singleOptionSelectionHasInfo$() {
        return this.getSingleOptionSelection$().pipe(
            map((option) => !!option?.descriptionTranslated)
        );
    }

    @MemoizeObservable()
    getContentAware$(): Observable<ExtendedUiContentAwareConfigurationMetaItem> {
        return this.observeProperty<
            ConfigurationGroupFirstLevelComponent,
            ExtendedUiContentAwareConfigurationMetaItem
        >('contentAware', true);
    }

    @MemoizeObservable()
    showOptionCount$() {
        return this.isSearchActive$();
    }

    @MemoizeObservable()
    isSearchActive$() {
        return this.searchService.getActiveSearchTerm$().pipe(
            map((searchTerm) => !!searchTerm),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getOptionCountText$() {
        return this.getContentAware$().pipe(
            switchMap((group) =>
                this.displayedOptionsService
                    .getOptionsContainedInBranch$(of(undefined), of([group]))
                    .pipe(map((options) => options.length))
            ),
            switchMap((count) => {
                if (count === 1) {
                    return this.i18nService.selectTranslate$(
                        'CONFIGURATOR.SEARCH.RESULT_COUNT_SINGULAR',
                        {
                            count
                        }
                    );
                }
                return this.i18nService.selectTranslate$(
                    'CONFIGURATOR.SEARCH.RESULT_COUNT_PLURAL',
                    {
                        count
                    }
                );
            })
        );
    }

    @MemoizeObservable()
    getSelectionText$() {
        return this.getSelectedItems$().pipe(
            switchMap((selectedItems) => {
                if (!selectedItems || isEmpty(selectedItems)) {
                    return this.i18nService.selectTranslate$(
                        'CONFIGURATOR.CONFIGURATION_BAR.NO_SELECTION'
                    );
                }

                if (selectedItems.length === 1) {
                    return of(selectedItems[0].nameTranslated);
                }
                return this.i18nService.selectTranslate$(
                    `CONFIGURATOR.CONFIGURATION_BAR.COUNT_SELECTED`,
                    {
                        itemCount: selectedItems.length
                    }
                );
            })
        );
    }

    @MemoizeObservable()
    isSingleSelectionQOption$() {
        return this.getSelectedItems$().pipe(
            map((selectedItems) => {
                if (
                    !selectedItems ||
                    isEmpty(selectedItems) ||
                    selectedItems.length > 1
                ) {
                    return false;
                }

                return selectedItems[0].meta?.qOption;
            })
        );
    }

    @MemoizeObservable()
    getGroupPricing$(): Observable<EffectivePricingAware | undefined> {
        return this.getAllOptionsInHierarchy$().pipe(
            // keep selected ones
            map((options) => options?.filter((option) => option.selected)),
            map((options) => {
                if (!options) {
                    return undefined;
                }
                return sumUpEffectivePricingAwarePricings(
                    options,
                    this.errorHandlerService
                );
            })
        );
    }

    @MemoizeObservable()
    getChildOptions$(): Observable<ExtendedUiOptionCode[] | undefined> {
        return this.getContentAware$().pipe(
            map((group) =>
                (<any[]>group.content).filter(
                    (item): item is ExtendedUiOptionCode =>
                        isExtendedUiOptionCode(item)
                )
            ),
            map((options) => (isEmpty(options) ? undefined : options))
        );
    }

    getSelectedSpecialSelectItemId$() {
        return this.observeProperty<
            ConfigurationGroupFirstLevelComponent,
            CategoryItem | undefined
        >('specialSelect', true).pipe(
            map(
                (specialSelectItem) =>
                    specialSelectItem?.children?.find((item) => item.selected)
                        ?.id
            )
        );
    }

    hasChangeableSelectionInHierarchy(
        contentAware: ExtendedUiContentAwareConfigurationMetaItem
    ) {
        return this.categoryMapper.hasChangeableSelectionInHierarchy(
            contentAware
        );
    }

    onGroupHeaderStuck(stuck: boolean) {
        if (!stuck) {
            return;
        }
        this.focusOnStuck.emit();
    }

    @MemoizeObservable()
    getAllOptionsInHierarchy$(): Observable<
        ExtendedUiOptionCode[] | undefined
    > {
        return this.displayedOptionsService
            .getOptionsContainedInBranch$(
                this.getActiveGroupId$(),
                this.getContentAware$().pipe(map((group) => [group]))
            )
            .pipe(lazyShareReplay());
    }

    @MemoizeObservable()
    private getSelectedItems$(): Observable<ExtendedUiOptionCode[]> {
        return this.getAllOptionsInHierarchy$().pipe(
            map((options) => options?.filter((option) => option.selected)),
            // remove duplicates which could be caused by options appearing in multiple groups
            map((options) => uniqBy(options, 'code')),
            lazyShareReplay()
        );
    }

    private getActiveGroupId$() {
        return this.getContentAware$().pipe(map((group) => group.id));
    }

    private initScrollIntoViewBinding() {
        this.observeProperty<
            ConfigurationGroupFirstLevelComponent,
            Observable<string>
        >('scrollIntoView$', false)
            .pipe(
                switchMap((scrollIntoView$) => {
                    if (!scrollIntoView$) {
                        return EMPTY;
                    }
                    return scrollIntoView$.pipe(
                        filter((itemId) => itemId === this.contentAware?.id),
                        map(() => undefined)
                    );
                }),
                debounceTime(225), // sorry, didn't have time to figure out how to listen to animation-end event, thus using the default animation-timing of expansion-panels
                this.takeUntilDestroy()
            )
            .subscribe(() => {
                this.panelBodyRef.nativeElement.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                });
            });
    }

    /**
     * Test if the given CategoryItem has at least one child with a description set
     * @param categoryItem
     */
    childHasDescription(categoryItem: CategoryItem): boolean {
        return !!categoryItem.children?.some((item) => !!item.description);
    }
}
