<mat-form-field
    class="mhp-ui-form-field--no-placeholder mhp-ui-form-field--no-bottom-spacing mhp-ui-form-field--no-underline"
    [ngClass]="formFieldClass()"
>
    <mat-select
        [formControl]="categorySelectFormControl"
        [compareWith]="categoryItemComparator"
        [disabled]="disabled()"
        [panelClass]="{
            'configuration-category-select__select-panel': true,
            'configuration-category-select__select-panel--has-descriptions':
                childCategoryHasDescription()
        }"
        [panelWidth]="null"
    >
        @for (categorySelectChild of categoryItem()?.children; track
        categorySelectChild.id) {
        <mat-option [value]="categorySelectChild"
            ><div class="configuration-category-select__label">
                <span>{{ categorySelectChild.label }}</span>
                @if (categorySelectChild.description) {
                <mhp-ui-icon-button
                    *transloco="let t"
                    class="configuration-bar__tab-group-label-icon"
                    svgIcon="mhp-ui:info"
                    [matTooltip]="
                        t('ICONS.INFO_ON_ALT', {
                            topic: categorySelectChild.label
                        })
                    "
                    [label]="
                        t('ICONS.INFO_ON_ALT', {
                            topic: categorySelectChild.label
                        })
                    "
                    (click)="
                        $event.stopPropagation();
                        intentShowInfo(categorySelectChild)
                    "
                >
                </mhp-ui-icon-button>
                }
            </div>
        </mat-option>
        }
    </mat-select>
</mat-form-field>
