import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    UiButtonModule,
    UiIconsModule,
    UiTooltipModule
} from '@mhp/ui-components';

import { SidebarPanelComponent } from './sidebar-panel.component';

@NgModule({
    declarations: [SidebarPanelComponent],
    exports: [SidebarPanelComponent],
    imports: [CommonModule, UiButtonModule, UiIconsModule, UiTooltipModule]
})
export class SidebarPanelModule {}
