import { Inject, NgModule } from '@angular/core';
import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import {
    ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN,
    EngineControlStrategy,
    UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN,
    UiSharedStateLocalStrategy,
    UiSharedStateStrategy,
    UpdatableStrategyProvider
} from '@mhp/ui-shared-services';

import { BasicModule } from '../configuration/basic/basic.module';
import { EngineControlLocalStrategy } from '../configuration/basic/engine/engine-control-local-strategy';
import { ConfigurationMainModule } from '../configuration/configuration-main/configuration-main.module';
import { ConfigurationSessionLocalPersistenceModule } from '../configuration/configuration-session-local-persistence/configuration-session-local-persistence.module';
import { ConfigurationStreamModule } from '../configuration/configuration-stream/configuration-stream.module';
import { ConfigurationModule } from '../configuration/configuration.module';
import { WebConfiguratorRoutingModule } from './web-configurator-routing.module';

/**
 * This is the module that should be loaded for the regular web-configurator.
 *
 * Behaviors included are:
 * - Default ConfigurationModule which includes the ConfigurationMain component that is rendering the default configuration-view
 *   for the regular web-configurator (public and dealer).
 * - ConfigurationStreamModule is included to allow handling of stream-activation logic
 * - BasicModule is included to allow rendering IOD images
 */
@NgModule({
    declarations: [],
    imports: [
        ConfigurationModule,
        ConfigurationMainModule,
        ConfigurationSessionLocalPersistenceModule,

        // routing for the web-configurator
        WebConfiguratorRoutingModule,

        // Modules required for stream and iod rendering
        ConfigurationStreamModule,
        BasicModule
    ],
    providers: []
})
export class WebConfiguratorModule {
    constructor(
        engineControlLocal: EngineControlLocalStrategy,
        uiSharedStateLocalStrategy: UiSharedStateLocalStrategy<AmlUiSharedState>,
        @Inject(ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN)
        engineControlStrategyProvider: UpdatableStrategyProvider<EngineControlStrategy>,
        @Inject(UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN)
        uiSharedStateStrategyProvider: UpdatableStrategyProvider<
            UiSharedStateStrategy<AmlUiSharedState>
        >
    ) {
        // set default local strategies for engine-control and shared-state strategy-providers
        engineControlStrategyProvider.setStrategy(engineControlLocal, {
            type: 'BASIC'
        });
        uiSharedStateStrategyProvider.setStrategy(uiSharedStateLocalStrategy, {
            type: 'BASIC'
        });
    }
}
