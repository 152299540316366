import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OptionPrice } from '../model/order-management.models';

@Component({
    selector: 'mhp-pricing-section',
    templateUrl: './pricing-section.component.html',
    styleUrls: ['./pricing-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingSectionComponent {
    @Input()
    price: OptionPrice;

    @Input()
    showPricingDNP: boolean;

    @Input()
    showPricingRRP: boolean;

    @Input()
    currency: string;
}
