import { isNil } from 'lodash-es';

import {
    ExtendedUiBeautyshotAwareConfigurationMetaItem,
    ExtendedUiCodeAwareConfigurationMetaItem,
    ExtendedUiConfigurationMetaItem,
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiNestedCode,
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection,
    ExtendedUiOptionGroup,
    ExtendedUiOptionList,
    ExtendedUiSelectionAwareConfigurationMetaItem
} from '../configuration-model/configuration-interfaces';

export const isCodeAware = (
    content: ExtendedUiConfigurationMetaItem
): content is ExtendedUiCodeAwareConfigurationMetaItem => !!(<any>content).code;

export const isContentAware = (
    content: ExtendedUiConfigurationMetaItem
): content is ExtendedUiContentAwareConfigurationMetaItem =>
    !!(<any>content).content;

export const isSelectionAware = (
    content: ExtendedUiConfigurationMetaItem
): content is ExtendedUiSelectionAwareConfigurationMetaItem =>
    !isNil((<any>content).selected);

export const isBeautyshotAware = (
    content: ExtendedUiConfigurationMetaItem
): content is ExtendedUiBeautyshotAwareConfigurationMetaItem =>
    !isNil((<any>content).beautyshot);

export const isExtendedUiOptionGroup = (
    content: ExtendedUiConfigurationMetaItem
): content is ExtendedUiOptionGroup => content.type === 'Group';

export const isExtendedUiOptionCode = (
    content: ExtendedUiConfigurationMetaItem
): content is ExtendedUiOptionCode => content.type === 'Code';

export const isExtendedUiOptionList = (
    content: ExtendedUiConfigurationMetaItem
): content is ExtendedUiOptionList => content.type === 'List';

export const isExtendedUiOptionCollection = (
    content: ExtendedUiConfigurationMetaItem
): content is ExtendedUiOptionCollection => content.type === 'Collection';

export const isExtendedUiNestedCode = (
    content: ExtendedUiConfigurationMetaItem
): content is ExtendedUiNestedCode => content.type === 'NestedCode';

export const trackByConfigurationContent = (
    index: number,
    content: ExtendedUiConfigurationMetaItem
) => {
    if (isCodeAware(content)) {
        return content.code;
    }
    return index;
};
