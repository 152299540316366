<mhp-fullscreen-dialog [wrapInDialogBody]="true">
    <ng-container
        *transloco="let tSubmitDealer; read: 'SUMBIT_TO_DEALER.GENERAL'"
    >
        <div class="submit-to-dealer__form">
            <div
                class="submit-to-dealer__lane-left"
                *transloco="let tForm; read: 'FORM'"
            >
                <div
                    class="submit-to-dealer__current-configuration-container--small"
                >
                    <ng-container
                        *ngTemplateOutlet="currentConfiguration"
                    ></ng-container>
                </div>

                <h2>{{ tSubmitDealer('HEADLINE') }}</h2>

                <mhp-salesforce-form
                    formId="customer-salesforce-form"
                    (submitSuccess)="onSubmitSuccess($event)"
                    (submitFailed)="onSubmitFailed()"
                ></mhp-salesforce-form>
            </div>
            <div class="submit-to-dealer__lane-right">
                <div
                    class="submit-to-dealer__current-configuration-container--large"
                >
                    <ng-container
                        *ngTemplateOutlet="currentConfiguration"
                    ></ng-container>
                </div>

                <section class="submit-to-dealer__next-steps">
                    <h3>{{ tSubmitDealer('DESCRIPTION') }}</h3>
                    <p [innerHTML]="tSubmitDealer('EMAIL_NOTIFICATION')"></p>
                    <div class="submit-to-dealer__actions">
                        <button
                            mat-flat-button
                            class="mhp-ui-button--block mhp-ui-button--full-width mhp-ui-button--highlight"
                            [attr.form]="'customer-salesforce-form'"
                            [disabled]="(canSubmit$() | async) === false"
                        >
                            <span>{{ tSubmitDealer('CTA_SUBMIT') }}</span>
                        </button>
                    </div>
                </section>
            </div>
        </div>

        <ng-template #currentConfiguration>
            <section class="submit-to-dealer__current-configuration">
                <div
                    class="submit-to-dealer__current-configuration-description"
                    *transloco="let t"
                >
                    <h3
                        [innerHTML]="
                            t('SUMMARY.CONFIGURATION_SUMMARY.HEADLINE', {
                                modelname: getModelLabel$() | async
                            })
                        "
                    ></h3>
                </div>
                <mhp-static-renderer
                    [renderActiveSessionData]="true"
                    [aspectRatio]="3 / 2"
                ></mhp-static-renderer>
            </section>
        </ng-template>
    </ng-container>
</mhp-fullscreen-dialog>
