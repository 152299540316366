import { last } from 'lodash-es';
import { Observable, ReplaySubject, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    map,
    switchMap
} from 'rxjs/operators';

import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl
} from '@angular/forms';
import {
    EngineSessionData,
    SessionUrlService
} from '@mhp/aml-ui-shared-services';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import {
    SerializerService,
    UrlShortenerService
} from '@mhp/ui-shared-services';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'mhp-config-link-editor',
    templateUrl: './config-link-editor.component.html',
    styleUrls: ['./config-link-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line no-use-before-define
            useExisting: forwardRef(() => ConfigLinkEditorComponent),
            multi: true
        }
    ]
})
export class ConfigLinkEditorComponent
    extends UiBaseComponent
    implements ControlValueAccessor
{
    private onChange?: (value: EngineSessionData | undefined) => void;

    private valueSubject = new ReplaySubject<EngineSessionData | undefined>(1);

    disabled: boolean;

    readonly configurationUrlFormControl: UntypedFormControl;

    onTouched?: () => void;

    constructor(
        private readonly serializerService: SerializerService,
        private readonly sessionUrlService: SessionUrlService,
        private readonly urlShortenerService: UrlShortenerService
    ) {
        super();

        this.configurationUrlFormControl = new UntypedFormControl(undefined);

        this.configurationUrlFormControl.valueChanges
            .pipe(
                distinctUntilChanged(),
                switchMap((urlCandiate) =>
                    this.tryParseConfigurationUrlCandidate$(urlCandiate)
                )
            )
            .subscribe((configurationSessionInfo) => {
                if (!this.onChange) {
                    return;
                }
                this.onChange(configurationSessionInfo);
                this.valueSubject.next(configurationSessionInfo);
            });

        this.valueSubject.subscribe((value) => {
            const configurationUrl = this.buildConfigurationUrl(value);
            this.configurationUrlFormControl.setErrors(null);

            if (!configurationUrl) {
                if (this.configurationUrlFormControl.value) {
                    this.configurationUrlFormControl.setErrors({
                        invalidConfigUrl: true
                    });
                }
            } else {
                this.configurationUrlFormControl.setValue(configurationUrl);
            }
        });

        this.completeOnDestroy(this.valueSubject);
    }

    registerOnChange(fn: (value: EngineSessionData | undefined) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.configurationUrlFormControl.disable();
        } else {
            this.configurationUrlFormControl.enable();
        }
    }

    writeValue(obj: any): void {
        this.valueSubject.next(obj);
    }

    @MemoizeObservable()
    getConfiguratorSelectorRendererInput$(): Observable<
        EngineSessionData | undefined
    > {
        return this.valueSubject;
    }

    private tryParseConfigurationUrlCandidate$(
        urlCandidate: string | undefined
    ): Observable<EngineSessionData | undefined> {
        return of(urlCandidate).pipe(
            switchMap((urlCandidateLocal) => {
                if (!urlCandidateLocal) {
                    return of(undefined);
                }

                const parsedURL = new URL(urlCandidateLocal);
                const { pathname } = parsedURL;
                const pathnameSegments = pathname.split('/');

                const lastPathnameSegment = last(pathnameSegments);
                if (lastPathnameSegment?.length === 10) {
                    // assume shortened url
                    return this.urlShortenerService.resolveShortCode$(
                        lastPathnameSegment
                    );
                }
                return of(urlCandidateLocal);
            }),
            map((resolvedUrlCandidate) => {
                if (!resolvedUrlCandidate) {
                    return undefined;
                }

                const parsedURL = new URL(resolvedUrlCandidate);
                const { pathname } = parsedURL;
                const pathnameSegments = pathname.split('/');

                const country = pathnameSegments[1];
                const serializedConfig = pathnameSegments[4];

                const serializableSessionInfo: EngineSessionData =
                    this.serializerService.deserializeData(
                        decodeURIComponent(serializedConfig)
                    );

                return {
                    ...serializableSessionInfo,
                    country
                };
            }),
            catchError(() => of(undefined))
        );
    }

    private buildConfigurationUrl(value: EngineSessionData | undefined) {
        if (!value) {
            return undefined;
        }

        return `${
            environment.appConfig.baseUrls.dealerBaseUrl
        }${this.sessionUrlService.buildConfigurationPathname(value)}`;
    }
}
