import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    Output
} from '@angular/core';
import { EffectivePricingAware } from '@mhp/aml-ui-shared-services';
import { Memoize, MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { ApplicationStateService, L10nService } from '@mhp/ui-shared-services';

import { environment } from '../../../../environments/environment';
import { ROUTE_MODEL_SELECT } from '../../../app-route-names';
import { DEALER_HOOKS_TOKEN, DealerHooks } from '../../../dealer';
import { LabelHelperService } from '../../../i18n/label-helper.service';
import { LocalApplicationState } from '../../../state/local-application-state.interface';
import { ConfigurationSummaryService } from '../../configuration-summary/configuration-summary.service';
import { ConfigurationSessionInfoService } from '../../session-info/configuration-session-info.service';
import { setFocusTargetNode } from '../../state/actions/configuration.actions';
import { selectActiveModelId } from '../../state/selectors/configuration.selectors';

const IDENTIFIER_SUMMARY =
    environment.appConfig.configuration.identifierSummary;

@Component({
    selector: 'mhp-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent extends UiBaseComponent {
    @Input()
    renderReadonly = false;

    @Input()
    useTextShadow = true;

    @Output()
    readonly linkSelected = new EventEmitter<void>();

    constructor(
        private readonly labelHelperService: LabelHelperService,
        private readonly configurationSummaryService: ConfigurationSummaryService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks,
        private readonly l10nService: L10nService
    ) {
        super();

        this.completeOnDestroy(this.linkSelected);
    }

    @MemoizeObservable()
    getActiveModelId$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectActiveModelId);
    }

    @MemoizeObservable()
    getActiveProductId$() {
        return this.configurationSessionInfoService.getActiveProductId$();
    }

    @MemoizeObservable()
    getProductLabel$() {
        return this.labelHelperService.getActiveModelAndProductName$();
    }

    @MemoizeObservable()
    showPricingInfo$() {
        return of(true);
    }

    @MemoizeObservable()
    getTotalPrice$(): Observable<EffectivePricingAware | undefined> {
        return this.configurationSummaryService
            .getPricingSummaryModel$()
            .pipe(lazyShareReplay());
    }

    @MemoizeObservable()
    getFinancialOfferShortInfo$(): Observable<string | undefined> {
        return this.dealerHooksService
            .getFinancialServiceHooks()
            .getFinancialServicesOfferInfo$()
            .pipe(
                map((offerInfo) => {
                    if (!offerInfo.offer) {
                        return undefined;
                    }
                    // TODO: Translate
                    return `${this.l10nService.formatCurrency(
                        offerInfo.offer.monthlyRate
                    )} / month`;
                }),
                lazyShareReplay()
            );
    }

    intentGoToSummary() {
        this.linkSelected.emit();
        this.applicationStateService.dispatch(
            setFocusTargetNode({
                id: IDENTIFIER_SUMMARY
            })
        );
    }

    onActionClick() {
        this.linkSelected.emit();
    }

    @MemoizeObservable()
    getDerivateSelectionRouterCommands$(): Observable<string[]> {
        return combineLatest([
            this.getActiveModelId$(),
            this.getActiveProductId$()
        ]).pipe(
            map(([modelId, productId]) => {
                if (!modelId || !productId) {
                    return ['/', 'INT', ROUTE_MODEL_SELECT];
                }
                return <string[]>[
                    '/',
                    'INT',
                    ROUTE_MODEL_SELECT,
                    modelId,
                    productId
                ];
            })
        );
    }

    @Memoize()
    getModelSelectionRouterCommands() {
        return ['/', 'INT', ROUTE_MODEL_SELECT];
    }
}
