import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonModule } from '@mhp/ui-components';

import { VisualizationModeDebugComponent } from './visualization-mode-debug/visualization-mode-debug.component';

@NgModule({
    declarations: [VisualizationModeDebugComponent],
    exports: [VisualizationModeDebugComponent],
    imports: [CommonModule, UiButtonModule]
})
export class VisualizationModeModule {}
