import { Injectable } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import {
    CommonDialogsService,
    ConfigurationConverterMode,
    ConfigurationConverterService,
    ProductConfigurationService
} from '@mhp/ui-shared-services';

import { ExtendedUiOptionGroup } from '../configuration-model/configuration-interfaces';
import { ExtendedUiOptionGroupMapperService } from '../configuration-model/extended-ui-option-group-mapper.service';
import { ConfigurationNodeLookupService } from './configuration-node-lookup.service';
import {
    NodeSelectionHandler,
    SelectionChangeInfo
} from './node-selection-handler';

/**
 * Handles the selection of coniguration-nodes and applies the relevant logic.
 */
@Injectable()
export class NodeSelectionHandlerService {
    constructor(
        private readonly nodeLookup: ConfigurationNodeLookupService,
        private readonly commonDialogsService: CommonDialogsService,
        private readonly productConfigurationService: ProductConfigurationService,
        private readonly optionGroupMapperService: ExtendedUiOptionGroupMapperService,
        private readonly configurationConverterService: ConfigurationConverterService
    ) {}

    /**
     * Applies a selection of a given node to the given OptionGroup structure.
     *
     * @param selectionChangeInfo The changes to be applied.
     * @param optionGroups The option groups to be processed.
     * @param modelId The active modelId
     * @param productId The active productId
     * @param country The active country
     * @return The processed optionGroups
     */
    async handleNodeSelection(
        selectionChangeInfo: SelectionChangeInfo,
        optionGroups: ExtendedUiOptionGroup[],
        modelId: string,
        productId: string,
        country: string
    ): Promise<ConfigModel[]> {
        const nodeSelectionHandler = new NodeSelectionHandler(
            optionGroups,
            modelId,
            productId,
            country,
            this.nodeLookup,
            this.commonDialogsService,
            this.productConfigurationService,
            this.optionGroupMapperService,
            this.configurationConverterService
        );
        await nodeSelectionHandler.handleNodeSelection(selectionChangeInfo);
        return this.configurationConverterService.convertToConfigurationFormat(
            nodeSelectionHandler.getOptionGroups(),
            ConfigurationConverterMode.SELECTED_ONLY
        );
    }
}
