<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    class="show-code-dialog"
    *transloco="let t"
    [title]="t('AML_CODE.CREATE.DIALOG.TITLE')"
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <p>
            {{
                t(
                    isDealerBuild
                        ? 'AML_CODE.CREATE.DIALOG.DESCRIPTION_DEALER'
                        : 'AML_CODE.CREATE.DIALOG.DESCRIPTION'
                )
            }}
        </p>
        <form [formGroup]="codeFormGroup">
            <mat-form-field
                *ngFor="
                    let formControl of getFormControls();
                    let index = index;
                    trackBy: trackByIndex
                "
            >
                <input matInput maxlength="1" [formControl]="formControl" />
            </mat-form-field>
        </form>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-left>
        <button
            mat-button
            class="mhp-ui-button--full-width"
            [cdkCopyToClipboard]="shortUrl"
            (cdkCopyToClipboardCopied)="onShortUrlCopied($event)"
        >
            {{ t('AML_CODE.CREATE.DIALOG.CTA_COPY_URL') }}
        </button>
    </mhp-ui-mat-dialog-actions-left>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            class="mhp-ui-button--full-width"
            [cdkCopyToClipboard]="code"
            (cdkCopyToClipboardCopied)="onCodeCopied($event)"
        >
            <span>{{ t('AML_CODE.CREATE.DIALOG.CTA_COPY_CODE') }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
