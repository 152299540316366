import isBoolean from 'lodash-es/isBoolean';

import { Environment } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { AMLEnvironmentMeta } from '@mhp/aml-shared/product-data/aml-environment-meta.interface';

export const isBrandStoreExclusiveEnvironment = (
    environment: Environment<AMLEnvironmentMeta>
): boolean =>
    environment.meta?.brandStore === true &&
    environment.meta?.configurator === false;

export const isBrandStoreRelevantEnvironment = (
    environment: Environment<AMLEnvironmentMeta>
): boolean => {
    // in case brandStore is explicitly set, use it
    const matchesExplicitBrandStoreContext = isBoolean(
        environment?.meta?.brandStore
    )
        ? environment?.meta?.brandStore
        : undefined;

    if (isBoolean(matchesExplicitBrandStoreContext)) {
        return matchesExplicitBrandStoreContext;
    }

    // no explicit include/exclude for brandStore, take a look at the regular configurator-flag
    return environment?.meta?.configurator !== false;
};
