<div
    class="menu-toc"
    [ngClass]="{
        'menu-toc__expanded': (getActiveRootItemModel$() | async) !== undefined
        && (getActiveRootItemModel$() | async)?.children !== undefined
    }"
>
    <div
        class="menu-toc__root-items"
        [class.menu-toc__root-items--hovered]="
            rootItemsHovered ||
            (getActiveRootItemModel$() | async) !== undefined
        "
        (mouseenter)="subItemsHovered = false"
        (mouseleave)="subItemsHovered = false"
    >
        <ul>
            <li
                *ngFor="
                    let menuTocItem of getMenuTocModel$() | async;
                    let index = index
                "
                class="menu-toc__root-item"
                [class.menu-toc__root-item--active]="menuTocItem.active"
                (mouseenter)="intentActivateTocItem(menuTocItem)"
                (click)="intentSelectTocItem(menuTocItem)"
                (keyup.enter)="intentSelectTocItem(menuTocItem)"
                mat-ripple
            >
                <span>{{ menuTocItem.label }}</span>
                <mhp-ui-icon
                    *ngIf="menuTocItem.children"
                    [svgIcon]="'mhp-ui:angle-right'"
                    [size]="IconSize.SMALL"
                ></mhp-ui-icon>
            </li>
        </ul>
        <ng-content select="[mhp-menu-toc-extension-content]"></ng-content>
    </div>
    <ng-container *ngIf="getActiveRootItemModel$() | async as activeSubItem">
        <div
            @slideIn
            *ngIf="activeSubItem.children"
            class="menu-toc__sub-items"
            [class.menu-toc__sub-items--hovered]="subItemsHovered"
            (mouseenter)="subItemsHovered = true"
            (mouseleave)="onSubItemsLeft(); subItemsHovered = false"
        >
            <div>
                <ul @fadeInOut *ngFor="let dummy of [activeSubItem]">
                    <li
                        *ngFor="let subCategory of activeSubItem.children"
                        class="menu-toc__sub-item"
                    >
                        <a
                            mat-button
                            class="mhp-ui-button--no-drop-shadow"
                            (click)="intentSelectTocItem(subCategory)"
                            >{{ subCategory.label }}</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>
</div>
