import { ClipboardModule } from 'ngx-clipboard';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    UiButtonModule,
    UiCheckboxModule,
    UiDatepickerModule,
    UiFormFieldModule,
    UiIconsModule,
    UiInputModule,
    UiMatDialogModule,
    UiSelectModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { StaticRendererModule } from '../../../configuration/static-renderer/static-renderer.module';
import { DialogModule } from '../../../dialog/dialog.module';
import { SessionManagementModule as CommonSessionManagementModule } from '../../../one2one/session-management/session-management.module';
import { CreateOne2oneSessionComponent } from './create-one2one-session/create-one2one-session.component';
import { One2oneConfirmComponent } from './one2one-confirm/one2one-confirm.component';

@NgModule({
    declarations: [CreateOne2oneSessionComponent, One2oneConfirmComponent],
    imports: [
        CommonModule,
        DialogModule,
        I18nModule,
        ReactiveFormsModule,
        UiFormFieldModule,
        UiSelectModule,
        UiInputModule,
        UiButtonModule,
        UiDatepickerModule,
        StaticRendererModule,
        UiMatDialogModule,
        ClipboardModule,
        UiIconsModule,
        UiCheckboxModule,
        CommonSessionManagementModule
    ],
    exports: [CreateOne2oneSessionComponent]
})
export class SessionManagementModule {}
