import { Observable, of, throwError } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    pairwise,
    startWith,
    switchMap,
    take,
    timeout
} from 'rxjs/operators';

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    MsalBroadcastService,
    MsalInterceptor,
    MsalService
} from '@azure/msal-angular';
import { AuthError, InteractionStatus } from '@azure/msal-browser';
import { IllegalStateError, connectedPublishReplay } from '@mhp/common';

/**
 * Idea is to register a placeholder noop-http-interceptor in the app-root module
 * and populate this one with the msal-interceptor created in a lazily loaded dealer-module.
 * That way we will not eagerly depend on the dealer-module but be able to add tokens
 * to requests anyway.
 */
@Injectable()
export class DealerMsalInterceptor implements HttpInterceptor {
    private readonly canProceedInterception$: Observable<boolean>;

    constructor(
        private readonly msalInterceptor: MsalInterceptor,
        private readonly msalService: MsalService,
        private readonly msalBroadcastService: MsalBroadcastService
    ) {
        this.initListenToMultipleMatchingTokensErrorEvent();

        /*
         * Normally, we would wait before starting an interception
         * that the InteractionStatus has settled to None. But this is not always the
         * case:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3961
         */
        this.canProceedInterception$ =
            this.msalBroadcastService.inProgress$.pipe(
                distinctUntilChanged(),
                startWith(undefined),
                pairwise(),
                map(
                    ([interactionStatusPrev, interactionStatusCurrent]) =>
                        interactionStatusCurrent === InteractionStatus.None ||
                        (interactionStatusPrev === InteractionStatus.None &&
                            interactionStatusCurrent ===
                                InteractionStatus.HandleRedirect)
                ),
                connectedPublishReplay()
            );
    }

    /**
     * Intercept a HttpRequest by either delegating interception to
     * a delegate interceptor or simply passing through to the next handler.
     * @param request
     * @param next
     */
    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (!this.msalInterceptor) {
            return next.handle(request);
        }
        return this.canProceedInterception$.pipe(
            filter((canProceed) => canProceed),
            timeout(5000),
            catchError((error) => {
                throw new IllegalStateError(
                    'We cannot proceed intercepting a request as the current interaction-status is not settling after having waited 5s. Please investigate.',
                    error
                );
            }),
            take(1),
            switchMap(() => this.msalInterceptor.intercept(request, next)),
            catchError((error) => this.handleError(error))
        );
    }

    /**
     * Take a look at the given error and decide what to do with it.
     * In case it's an AuthError with errorCode 'multiple_matching_tokens' we're
     * performing a logout to clear session-data.
     * Otherwise we just re-throw the error.
     *
     * @param error Some kind of error
     * @private
     */
    private handleError(error: unknown): Observable<never> {
        let logoutWorkflow$: Observable<void> = of(undefined);
        if (
            error instanceof AuthError &&
            error.errorCode === 'multiple_matching_tokens'
        ) {
            // we perform a logout to cleanup all cached tokens
            logoutWorkflow$ = this.msalService.logout();
        }
        return logoutWorkflow$.pipe(switchMap(() => throwError(() => error)));
    }

    private initListenToMultipleMatchingTokensErrorEvent() {
        this.msalBroadcastService.msalSubject$
            .pipe(
                map((event) => event.error),
                filter(
                    (error): error is AuthError => error instanceof AuthError
                ),
                switchMap((error) =>
                    this.handleError(error).pipe(
                        catchError(() => of(undefined))
                    )
                )
            )
            .subscribe();
    }
}
