import { cloneDeep } from 'lodash-es';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';

import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import {
    ConfigurationCompositeItemModel,
    ConfigurationCompositeItemOptionModel
} from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import { EngineSessionData } from '@mhp/aml-ui-shared-services';
import { IllegalStateError } from '@mhp/common';
import { ImageSrcset } from '@mhp/ui-components';
import {
    StaticAssetService,
    isOptionCode,
    isOptionCollection
} from '@mhp/ui-shared-services';

import { environment } from '../../../../../environments/environment';
import { ConfigurationSessionImageAssetService } from '../../../assets/configuration-session-image-asset.service';
import {
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection,
    ExtendedUiOptionGroup
} from '../../../configuration-model/configuration-interfaces';
import { StaticRendererService } from '../../../static-renderer/static-renderer.service';

export class ConfigurationCompositeItemMapper {
    constructor(
        private readonly sessionInfo: EngineSessionData,
        private readonly staticRendererService: StaticRendererService,
        private readonly staticAssetService: StaticAssetService,
        private readonly configurationSessionImageAssetService: ConfigurationSessionImageAssetService
    ) {}

    mapContentAware(optionGroup: ExtendedUiOptionGroup | undefined) {
        if (!optionGroup) {
            return undefined;
        }

        return this.mapGroup(optionGroup);
    }

    private mapGroup(
        group: ExtendedUiOptionGroup
    ): ConfigurationCompositeItemModel[] {
        return group.content.map((currentItem) => {
            if (isOptionCollection(currentItem)) {
                return this.mapOptionCollection(currentItem);
            }
            if (isOptionCode(currentItem)) {
                return this.mapOptionCode(currentItem);
            }
            throw new IllegalStateError(
                `Unexpected composite item type while mapping: ${currentItem.type}. Expected either Collection or Option`
            );
        });
    }

    private mapOptionCode(
        code: ExtendedUiOptionCode
    ): ConfigurationCompositeItemModel {
        const thumbnailUrl$ = this.getOptionCodeThumbnail$(code);
        if (!thumbnailUrl$) {
            throw new IllegalStateError(
                'Expecting a fallback thumbnail in every case'
            );
        }

        return {
            id: code.id,
            disabled: !code.active,
            label: code.nameTranslated,
            description: code.descriptionTranslated,
            selected: code.selected,
            pricing: code.pricing,
            thumbnailUrls: combineLatest([thumbnailUrl$]).pipe(
                map((thumbnails) =>
                    thumbnails.filter(
                        (thumbnail): thumbnail is string | ImageSrcset =>
                            !!thumbnail
                    )
                )
            ),
            meta: cloneDeep(code.meta)
        };
    }

    private mapOptionCollection(
        collection: ExtendedUiOptionCollection
    ): ConfigurationCompositeItemModel {
        const childCodes = this.getOptionCollectionCodes(collection);

        const collectionThumbnail$ = this.getCollectionThumbnail$(collection);

        if (!collectionThumbnail$) {
            throw new IllegalStateError(
                'Expecting a thumbnail for collection with no omit options provided',
                collection
            );
        }

        const collectionFallbackThumbnail$ = this.getCollectionThumbnail$(
            collection,
            undefined,
            true
        );

        const pricingReference = childCodes.find((code) => code.selected);
        return {
            id: collection.id,
            disabled: childCodes.every((code) => !code.active),
            label: collection.nameTranslated,
            description: collection.descriptionTranslated,
            pricing: pricingReference?.pricing,
            thumbnailUrls: combineLatest([
                collectionThumbnail$,
                collectionFallbackThumbnail$
            ]).pipe(
                map((thumbnails) =>
                    thumbnails.filter(
                        (thumbnail): thumbnail is string | ImageSrcset =>
                            !!thumbnail
                    )
                )
            ),
            options: childCodes.map((code) =>
                this.mapChildOptionCode(code, collection)
            )
        };
    }

    private mapChildOptionCode(
        code: ExtendedUiOptionCode,
        parentCollection: ExtendedUiOptionCollection
    ): ConfigurationCompositeItemOptionModel {
        const thumbnailUrl$: Observable<string | ImageSrcset | undefined> =
            this.getOptionCodeThumbnail$(
                code,
                parentCollection.beautyshot?.cameraId
            ).pipe(
                switchMap((thumbnails) => {
                    if (thumbnails) {
                        return of(thumbnails);
                    }
                    return this.getCollectionThumbnail$(parentCollection, code);
                })
            );

        const thumbnailCollectionFallback$ = this.getCollectionThumbnail$(
            parentCollection,
            code,
            true
        );

        return {
            id: code.id,
            disabled: !code.active,
            label: code.nameTranslated,
            description: code.descriptionTranslated,
            selected: code.selected,
            pricing: code.pricing,
            thumbnailUrls: combineLatest([
                thumbnailUrl$,
                thumbnailCollectionFallback$
            ]).pipe(
                map((thumbnails) =>
                    thumbnails.filter(
                        (thumbnail): thumbnail is string | ImageSrcset =>
                            !!thumbnail
                    )
                )
            ),
            meta: cloneDeep(code.meta)
        };
    }

    private getOptionCollectionCodes(
        collection: ExtendedUiOptionCollection
    ): ExtendedUiOptionCode[] {
        return collection.content.map((item) => {
            if (!isOptionCode(item)) {
                throw new IllegalStateError(
                    `Unexpected composite item type while mapping: ${item.type}. Expected Option`
                );
            }
            return item;
        });
    }

    private getCollectionThumbnail$(
        collection: ExtendedUiOptionCollection,
        preferenceCode?: ExtendedUiOptionCode,
        omitSelectedChildThumbnail?: boolean
    ): Observable<string | ImageSrcset | undefined> {
        const childCodes = this.getOptionCollectionCodes(collection);
        const selectedChildCode = childCodes.find(
            (childCode) => childCode.selected
        );

        let collectionThumbnail$:
            | Observable<string | ImageSrcset | undefined>
            | undefined;

        // if we have a child-code that is selected and has an explicit thumbnail-assignment (either via #image or #beautyshot property), give it priority
        if (!omitSelectedChildThumbnail && selectedChildCode) {
            collectionThumbnail$ = this.getOptionCodeThumbnail$(
                selectedChildCode,
                collection?.beautyshot?.cameraId
            );
        }

        if (!collectionThumbnail$) {
            if (collection.image) {
                // collection has an image set
                const collectionThumbnailDirectUrl =
                    this.staticAssetService.getCollectionImageUrl(
                        collection.image,
                        this.sessionInfo.config.id
                    );

                collectionThumbnail$ = of(
                    this.getImageSrcsetForStaticThumbnail(
                        collectionThumbnailDirectUrl
                    )
                );
            } else if (collection.beautyshot) {
                const referenceCodeForBeautyshot:
                    | ExtendedUiOptionCode
                    | undefined =
                    preferenceCode ??
                    childCodes.find((childCode) => childCode.selected);

                // get rendering for selected option or collections beautyshot if available
                collectionThumbnail$ = referenceCodeForBeautyshot
                    ? this.getOptionCodeThumbnail$(
                          referenceCodeForBeautyshot,
                          collection.beautyshot.cameraId
                      )
                    : this.staticRendererService.getActiveSessionRenderingSrcset$(
                          {
                              alternativeAspectRatio: 16 / 9
                          },
                          {
                              overrideEnvironment: environment.appConfig
                                  .brandStore.brandStoreBuild
                                  ? environment.appConfig.assets
                                        .optionIodThumbnailsDefaultEnvironment
                                  : this.sessionInfo.environment.id,
                              overrideCamera: collection.beautyshot.cameraId
                          }
                      );
            }
        }

        return collectionThumbnail$ ?? of(undefined);
    }

    private getOptionCodeThumbnail$(
        code: ExtendedUiOptionCode,
        overrideCamera?: string
    ): Observable<string | ImageSrcset | undefined> {
        if (code.image) {
            // code has an image
            const optionThumbnailDirectUrl =
                this.staticAssetService.getOptionThumbnailImageUrl(
                    code.image,
                    this.sessionInfo.config.id
                );

            return of(
                this.getImageSrcsetForStaticThumbnail(optionThumbnailDirectUrl)
            );
        }

        return this.configurationSessionImageAssetService.getOptionThumbnailImageSrc$(
            code,
            {
                alternativeAspectRatio: 16 / 9
            },
            {
                overrideEnvironment: environment.appConfig.brandStore
                    .brandStoreBuild
                    ? environment.appConfig.assets
                          .optionIodThumbnailsDefaultEnvironment
                    : this.sessionInfo.environment.id,
                dayNightState: this.sessionInfo.environment?.options?.night
                    ? EnvironmentLightingProfileState.NIGHT
                    : EnvironmentLightingProfileState.DAY,
                overrideCamera
            }
        );
    }

    private getImageSrcsetForStaticThumbnail(
        thumbnailDirectUrl: string
    ): string | ImageSrcset {
        // code has an image
        const optionThumbnailSrcsetSizes =
            environment.appConfig.assets
                .personalisationAndAccessoriesSrcsetSizes;

        const [match, basePath, filenameBase, filenameExtension] =
            /(.*)\/(.*)\.(.*)/.exec(thumbnailDirectUrl) || [];

        let optionThumbnail;

        if (!match) {
            console.warn(
                `Failed extracting path-info to build image-srcset from thumbnail path ${thumbnailDirectUrl}`
            );
            optionThumbnail = thumbnailDirectUrl;
        } else {
            optionThumbnail =
                this.staticAssetService.buildImageSrcsetUsingFilenameInfo(
                    basePath,
                    filenameBase,
                    filenameExtension,
                    optionThumbnailSrcsetSizes
                );
        }
        return optionThumbnail;
    }
}
