import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    input
} from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { TRACK_BY_ID, UiBaseComponent } from '@mhp/ui-components';

import {
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection
} from '../../../configuration-model/configuration-interfaces';
import { isExtendedUiOptionCode } from '../../../services/configuration-helper';

@Component({
    selector: 'mhp-configuration-collection',
    templateUrl: './configuration-collection.component.html',
    styleUrls: ['./configuration-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationCollectionComponent extends UiBaseComponent {
    readonly trackById = TRACK_BY_ID;

    @Input()
    collection: ExtendedUiOptionCollection;

    optionSelectionDisabled = input<boolean>(false);

    @Output()
    readonly intentSelect = new EventEmitter<string>();

    @Output()
    readonly showInfo = new EventEmitter<ExtendedUiOptionCode>();

    constructor() {
        super();

        this.completeOnDestroy(this.intentSelect, this.showInfo);
    }

    @MemoizeObservable()
    getOptions$(): Observable<ExtendedUiOptionCode[] | undefined> {
        return this.observeProperty<
            ConfigurationCollectionComponent,
            ExtendedUiOptionCollection
        >('collection', true).pipe(
            distinctUntilChanged(isEqual),
            map((collection) =>
                collection.content.filter(
                    (item): item is ExtendedUiOptionCode =>
                        isExtendedUiOptionCode(item)
                )
            ),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getSelectedOption$(): Observable<ExtendedUiOptionCode | undefined> {
        return this.getOptions$().pipe(
            map((options) => options?.find((option) => option.selected))
        );
    }

    intentSelectItem(itemId: string) {
        this.intentSelect.emit(itemId);
    }

    intentShowInfo(option: ExtendedUiOptionCode) {
        this.showInfo.emit(option);
    }
}
