<ng-container *ngIf="!iconOnly; else icon">
    <mhp-ui-icon-button
        *ngIf="svgIcon; else button"
        [svgIcon]="svgIcon"
        [size]="iconSize"
        [matTooltip]="tooltip"
        [label]="tooltip"
        [disabled]="disabled"
        (click)="intentItemSelect()"
    >
    </mhp-ui-icon-button>
</ng-container>
<ng-template #icon>
    <mhp-ui-icon
        [svgIcon]="svgIcon"
        [size]="iconSize"
        [matTooltip]="tooltip"
    ></mhp-ui-icon>
</ng-template>
<ng-template #button>
    <button
        mat-button
        [matTooltip]="tooltip"
        [attr.aria-label]="tooltip"
        [disabled]="disabled"
        (click)="intentItemSelect()"
    >
        {{ buttonText }}
    </button>
</ng-template>
