import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IconSize } from '@mhp/ui-components';

import { DEFAULT_RENDERING_ADJUSTMENTS } from '../../static-renderer/static-renderer-adjustments.constants';

@Component({
    selector: 'mhp-model-outdated-dialog',
    templateUrl: './model-outdated-dialog.component.html',
    styleUrls: ['./model-outdated-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelOutdatedDialogComponent {
    protected readonly IconSize = IconSize;

    protected readonly DEFAULT_RENDERING_ADJUSTMENTS =
        DEFAULT_RENDERING_ADJUSTMENTS;

    constructor(private readonly dialogRef: MatDialogRef<unknown>) {}

    intentStartConfig() {
        this.dialogRef.close();
    }
}
