<ng-container *transloco="let t">
    <div
        *ngIf="collection.nameTranslated"
        class="configuration-collection__header"
    >
        <div class="configuration-collection__title">
            <span>
                {{ collection.nameTranslated }}
            </span>
            <span *ngIf="getSelectedOption$() | async as selectedOption">
                <mhp-ui-icon
                    *ngIf="
                        selectedOption.meta &&
                        selectedOption.meta.qOption === true
                    "
                    svgIcon="mhp-ui:q-option"
                    size="small"
                    [matTooltip]="t('ICONS.Q_OPTION_ALT')"
                ></mhp-ui-icon>
                {{ selectedOption.nameTranslated }}</span
            >
        </div>
        <ng-container *ngIf="getSelectedOption$() | async as selectedOption">
            <mhp-ui-icon-button
                *ngIf="selectedOption.descriptionTranslated"
                [label]="
                    t('ICONS.INFO_ON_ALT', {
                        topic: selectedOption.nameTranslated
                    })
                "
                class="configuration-collection__info-icon"
                svgIcon="mhp-ui:info"
                size="large"
                (click)="intentShowInfo(selectedOption)"
            ></mhp-ui-icon-button>
            <div
                *mhpIfShowConfigurationPricing
                class="configuration-collection__header-price"
            >
                {{ selectedOption | effectivePricingAwareFormat }}
            </div>
        </ng-container>
    </div>
    <div
        class="configuration-collection__content"
        [ngClass]="{
            'configuration-collection__content--no-header':
                !collection.nameTranslated
        }"
    >
        <ng-container
            *ngFor="let contentItem of collection.content; trackBy: trackById"
        >
            <ng-container *ngIf="contentItem.type === 'Group'">
                <mhp-configuration-group-sublevel
                    [group]="contentItem"
                    (intentSelect)="intentSelectItem($event)"
                    (showInfo)="intentShowInfo($event)"
                ></mhp-configuration-group-sublevel>
            </ng-container>

            <ng-container *ngIf="contentItem.type === 'Collection'">
                <mhp-configuration-collection
                    [collection]="contentItem"
                    (intentSelect)="intentSelectItem($event)"
                    (showInfo)="intentShowInfo($event)"
                ></mhp-configuration-collection>
            </ng-container>

            <ng-container *ngIf="contentItem.type === 'List'">
                <div>List</div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="getOptions$() | async as options">
            <mhp-configuration-options-list
                [options]="options"
                [useOverflowIndicator]="false"
                [optionSelectionDisabled]="optionSelectionDisabled()"
                (optionSelected)="intentSelectItem($event)"
                (infoRequested)="intentShowInfo($event)"
            ></mhp-configuration-options-list>
        </ng-container>
    </div>
</ng-container>
