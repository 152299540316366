import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { MatDialogConfig } from '@angular/material/dialog';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiMatDialogService } from '@mhp/ui-components';
import { ErrorHandlerService } from '@mhp/ui-shared-services';

import { BACKDROP_CLASS_BLURRY } from '../../../common/dialog/dialog.constants';
import { FinancialServicesInputService } from '../../../configuration/financial-services/financial-services-input.service';
import { FinancialServicesSessionService } from '../../../configuration/financial-services/financial-services-session.service';
import { OfferRow } from '../../../configuration/financial-services/financial-services.interfaces';
import { SettingsDialogComponent } from '../../../configuration/financial-services/settings-dialog/settings-dialog.component';
import { FinancialServiceHooks } from './financial-service-hooks.interface';

export class FinancialServiceHooksService implements FinancialServiceHooks {
    constructor(
        private readonly financialServicesSessionService: FinancialServicesSessionService,
        private readonly financialServicesInputService: FinancialServicesInputService,
        private readonly dialogService: UiMatDialogService,
        private readonly errorHandlerService: ErrorHandlerService
    ) {}

    isFinancialServiceOptionAvailable$() {
        return this.financialServicesSessionService.isFinancialServiceOptionAvailable$();
    }

    showFinancialServicesOptions$(
        context: Pick<MatDialogConfig, 'viewContainerRef'>
    ) {
        return this.dialogService
            .open$(SettingsDialogComponent, {
                ...context,
                minWidth: 800,
                backdropClass: BACKDROP_CLASS_BLURRY
            })
            .pipe(map(() => undefined));
    }

    @MemoizeObservable()
    getFinancialServicesOfferInfo$() {
        return combineLatest({
            offerInput:
                this.financialServicesInputService.getFinancialServiceOfferInput$(),
            offer: this.financialServicesSessionService.getFinancialServiceOffer$()
        }).pipe(lazyShareReplay());
    }

    @MemoizeObservable()
    getFinancialServicesOfferRows$(): Observable<OfferRow[] | undefined> {
        return this.financialServicesSessionService
            .getFinancialServiceOfferRowRepresentation$()
            .pipe(lazyShareReplay());
    }
}
