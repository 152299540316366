import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    UiButtonModule,
    UiCheckboxModule,
    UiFormFieldModule,
    UiIconsModule,
    UiInputModule,
    UiMatDialogModule,
    UiSelectModule
} from '@mhp/ui-components';
import { GoogleTagManagerModule, I18nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../../dialog/dialog.module';
import { RequestPdfComponent } from './request-pdf.component';

@NgModule({
    declarations: [RequestPdfComponent],
    imports: [
        CommonModule,
        UiMatDialogModule,
        I18nModule,
        GoogleTagManagerModule,
        UiSelectModule,
        UiButtonModule,
        ReactiveFormsModule,
        UiInputModule,
        UiFormFieldModule,
        UiCheckboxModule,
        DialogModule,
        UiIconsModule
    ]
})
export class RequestPdfModule {}
