import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonModule, UiIconsModule } from '@mhp/ui-components';
import { GoogleTagManagerModule, I18nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../../dialog/dialog.module';
import { LoadModelYearDialogComponent } from './load-model-year-dialog.component';

@NgModule({
    declarations: [LoadModelYearDialogComponent],
    imports: [
        CommonModule,
        DialogModule,
        I18nModule,
        GoogleTagManagerModule,
        UiButtonModule,
        UiIconsModule
    ],
    exports: [LoadModelYearDialogComponent]
})
export class LoadModelYearDialogModule {}
