import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';

import { environment } from '../../../environments/environment';

/**
 * Allows to easily show or hide features depending on the
 * dealerBuild environment flag.
 */
@Directive({
    selector: '[mhpDealerFeature]'
})
export class DealerFeatureDirective implements OnInit {
    @Input()
    mhpDealerFeature = true;

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef
    ) {}

    ngOnInit() {
        this.updateView();
    }

    private updateView() {
        if (
            this.mhpDealerFeature === environment.appConfig.dealer.dealerBuild
        ) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
