import isBoolean from 'lodash-es/isBoolean';
import { Observable, of, take, throwError } from 'rxjs';
import {
    catchError,
    finalize,
    map,
    switchMap,
    tap,
    withLatestFrom
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import { translate } from '@jsverse/transloco';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { resolveCurrentModelYearProductId } from '@mhp/aml-shared/derivate-mapping/derivate-mapping';
import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import { IllegalStateError, NotFoundError } from '@mhp/common';
import { UiMatDialogService, UiNotificationService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    ConfigurationResolveMissingAuthorizationError,
    ErrorHandlerService,
    L10nService,
    ProductDataService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { ROUTE_CONFIGURATION } from '../../app-route-names';
import { BACKDROP_CLASS_BLURRY } from '../../common/dialog/dialog.constants';
import {
    clearActiveLoadingState,
    setActiveLoadingState
} from '../../common/loading-indicator/state/actions/loading-state.actions';
import { REGION_GLOBAL } from '../../settings/region-selector/region-constants';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { LoadModelYearDialogComponent } from '../configuration-area/load-model-year-dialog/load-model-year-dialog.component';
import { ConfigurationLeaveService } from '../configuration-leave/configuration-leave.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import {
    ConfigurationSessionInfoService,
    PartialLocationSessionData
} from '../session-info/configuration-session-info.service';
import { SessionUrlStateUpdateState } from '../session-info/session-url-state-update-state.interface';

@Injectable()
export class ConfigurationResolver {
    constructor(
        protected readonly router: Router,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly productDataService: ProductDataService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly applicationStateService: ApplicationStateService<
            LocalApplicationState,
            AmlUiSharedState
        >,
        private readonly notificationService: UiNotificationService,
        protected readonly dialogService: UiMatDialogService,
        private readonly l10nService: L10nService,
        private readonly configurationLeaveService: ConfigurationLeaveService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        const { productId } = route.params;

        return this.productDataService.getAvailableProducts$().pipe(
            this.errorHandlerService.applyRetry(),
            take(1),
            switchMap((availableProducts) => {
                if (
                    availableProducts.find(
                        (currentProductId) => currentProductId === productId
                    )
                ) {
                    // product exists in product-data, we can activate the route
                    return of(true);
                }

                // product cannot be found in product-data, try to elevate to a follow-up model-year
                const productIdChecked =
                    resolveCurrentModelYearProductId(productId);

                if (productIdChecked === productId) {
                    // if product-id stays the same, we simply continue activating the route
                    return of(true);
                }

                // so, the product-id requested could not be found in product-data but could be elevated to a follow-up model-year.
                return this.dialogService
                    .open$(LoadModelYearDialogComponent, {
                        maxWidth: 500,
                        backdropClass: BACKDROP_CLASS_BLURRY,
                        disableClose: true
                    })
                    .pipe(
                        switchMap((dialogRef) => {
                            const { componentInstance } = dialogRef;
                            return componentInstance.action;
                        }),
                        map((userAction) => {
                            if (userAction === 'RESET') {
                                const resetUrl = `/INT/${ROUTE_CONFIGURATION}/${productIdChecked}`;
                                return this.router.parseUrl(resetUrl);
                            }
                            const replacedUrl = state.url.replace(
                                productId,
                                productIdChecked
                            );
                            return this.router.parseUrl(replacedUrl);
                        })
                    );
            }),
            // either failed to elevate to a follow-up model-year or could not load product-data. Activate the route and delegate error-handling to the resolve step
            catchError(() => of(true))
        );
    }

    canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean> {
        if (
            !environment.appConfig.featureToggles
                .enableAutoProvideConfigCodeOnLeaveConfiguration ||
            environment.appConfig.dealer.dealerBuild
        ) {
            // only check for public configurator and if feature is enabled
            return of(true);
        }

        if (currentState.url === nextState?.url) {
            return of(true);
        }
        // we are in the process of navigating to a INT link which will be redirected to the active region
        if ((nextState?.url.indexOf(`/${REGION_GLOBAL}/`) ?? 0) > -1) {
            return of(true);
        }
        // we are in the process of a configuration-update
        if ((nextState?.url.indexOf(`/${ROUTE_CONFIGURATION}/`) ?? 0) > -1) {
            return of(true);
        }
        // trigger configuration-leave logic
        return this.configurationLeaveService
            .onConfigurationLeave$()
            .pipe(map(() => true));
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<void> {
        const navigationState = this.router.getCurrentNavigation()?.extras
            .state as SessionUrlStateUpdateState | undefined;
        const routeSessionInfo = this.getSessionInfoFromRoute(route);

        const showLayerTimeout = setTimeout(() => {
            this.applicationStateService.dispatch(
                setActiveLoadingState({
                    loading: true,
                    showLoadingSpinnerWhenLoading: true
                })
            );
        }, 300);

        return this.startSessionWithRouteSessionInfo$(
            routeSessionInfo.productId,
            routeSessionInfo.sessionInfo,
            navigationState
        ).pipe(
            finalize(() => {
                // cancel timer to show layer
                clearTimeout(showLayerTimeout);

                this.applicationStateService.dispatch(
                    clearActiveLoadingState()
                );
            })
        );
    }

    private startSessionWithRouteSessionInfo$(
        productId: string,
        sessionInfo?: PartialLocationSessionData,
        options?: { internalStateUpdate: boolean } | undefined
    ): Observable<void> {
        return this.checkProductIdExistence$(productId).pipe(
            // product-id is valid, apply the data from session-info to our session
            withLatestFrom(this.l10nService.getActiveCountry$()),
            switchMap(([noUse, activeCountry]) => {
                if (!activeCountry) {
                    throw new IllegalStateError(
                        'Country must not be undefined'
                    );
                }

                // determine lighting state. Might be undefined.
                let environmentLightingState:
                    | EnvironmentLightingProfileState
                    | undefined;
                if (isBoolean(sessionInfo?.environment?.options.night)) {
                    environmentLightingState = sessionInfo.environment.options
                        .night
                        ? EnvironmentLightingProfileState.NIGHT
                        : EnvironmentLightingProfileState.DAY;
                }

                return this.productConfigurationSessionService
                    .updateEngineStateFromSessionData(
                        {
                            productId,
                            country: activeCountry,
                            configuration: sessionInfo?.config?.options.config,
                            environmentState: sessionInfo?.environment
                                ? {
                                      id: sessionInfo.environment.id,
                                      state: environmentLightingState
                                  }
                                : undefined,
                            camera: sessionInfo?.camera?.id,
                            animations: sessionInfo?.animations?.map(
                                (animation) => ({
                                    id: animation.id,
                                    state: 'END'
                                })
                            )
                        },
                        {
                            skipEnvironmentStateCheck:
                                options?.internalStateUpdate ?? false
                        }
                    )
                    .pipe(
                        catchError((error) => {
                            let userMessage = translate<string>(
                                'CONFIGURATOR.ERRORS.UPDATE_METADATA'
                            );

                            if (
                                error instanceof
                                ConfigurationResolveMissingAuthorizationError
                            ) {
                                userMessage = translate<string>(
                                    'CONFIGURATOR.ERRORS.UPDATE_METADATA_MISSING_AUTHORIZATION'
                                );
                            }

                            this.errorHandlerService.showErrorMessage(
                                () => userMessage,
                                error
                            );

                            this.navigateToModelSelection();

                            return throwError(error);
                        })
                    );
            }),
            map(() => undefined)
        );
    }

    private checkProductIdExistence$(productId: string): Observable<void> {
        return this.productDataService.getAvailableProducts$().pipe(
            take(1),
            this.errorHandlerService.applyRetry(),
            tap((availableProducts) => {
                if (
                    !availableProducts.find(
                        (currentProductId) => currentProductId === productId
                    )
                ) {
                    throw new NotFoundError(
                        `Model ${productId} could not be found`
                    );
                }
            }),
            catchError((error) => {
                if (error instanceof NotFoundError) {
                    this.errorHandlerService.showErrorMessage(
                        () =>
                            translate('CONFIGURATOR.ERRORS.MODEL_NOT_FOUND', {
                                modelCode: productId
                            }),
                        error
                    );
                } else {
                    this.errorHandlerService.showErrorMessage(
                        () => translate('CONFIGURATOR.ERRORS.UPDATE_METADATA'),
                        error
                    );
                }
                this.navigateToModelSelection();
                return throwError(error);
            }),
            map(() => undefined)
        );
    }

    private getSessionInfoFromRoute(route: ActivatedRouteSnapshot) {
        let { productId } = route.params;
        let { serializedSessionInfo } = route.params;

        if (!productId) {
            // no productId provided - use default
            productId = environment.appConfig.defaultProductId;
            // reset any given existing configuration as it is not clear if it'll match the productId
            serializedSessionInfo = undefined;
        }

        try {
            const deserializedSessionInfo = serializedSessionInfo
                ? this.deserializeSessionInfo(serializedSessionInfo)
                : undefined;

            return {
                productId,
                sessionInfo: deserializedSessionInfo
            };
        } catch (error) {
            this.errorHandlerService.showErrorMessage(
                () =>
                    translate(
                        'CONFIGURATOR.ERRORS.INVALID_SERIALIZED_SESSION_INFO'
                    ),
                error
            );
            this.navigateToModelSelection();
            throw error;
        }
    }

    private deserializeSessionInfo(
        serializedSessionInfo: string
    ): PartialLocationSessionData {
        return this.configurationSessionInfoService.getDeserializedPartialLocationEngineSessionData(
            serializedSessionInfo
        );
    }

    private navigateToModelSelection() {
        return this.router.navigate(['/'], {
            queryParamsHandling: 'merge'
        });
    }
}
