import { isNumber } from 'lodash-es';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import {
    ChangeDetectorRef,
    Directive,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IfShowPricingSupportService } from '../../common/pricing/if-show-pricing-support.service';
import { ConfigurationSummaryService } from '../configuration-summary/configuration-summary.service';

/**
 * Allows to easily show or hide pricing-related template-parts based on whether
 * pricing data is available or not.
 *
 * This takes into account the actual pricing-data instead of only the semantics if pricing "should" be visible
 * as IfShowPricingDirective is doing.
 *
 * As this directive depends on configuration-related services, it has been created with this additional configuration-dependent semantic in place.
 *
 * @see IfShowPricingDirective
 */
@UntilDestroy()
@Directive({
    selector: '[mhpIfShowConfigurationPricing]'
})
export class IfShowConfigurationPricingDirective implements OnInit {
    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly ifShowPricingSupportService: IfShowPricingSupportService,
        private readonly configurationSummaryService: ConfigurationSummaryService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.initViewToggleLogic();
    }

    private initViewToggleLogic() {
        combineLatest([
            this.ifShowPricingSupportService.isPricingVisible$(),
            this.configurationSummaryService.getPricingSummaryModel$()
        ])
            .pipe(
                map(
                    ([pricingVisible, pricingSummaryModel]) =>
                        pricingVisible &&
                        isNumber(pricingSummaryModel?.pricing?.price)
                ),
                distinctUntilChanged(),
                untilDestroyed(this)
            )
            .subscribe((pricingVisible) => {
                if (pricingVisible) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    this.changeDetectorRef.markForCheck();
                } else {
                    this.viewContainer.clear();
                }
            });
    }
}
