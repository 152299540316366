export interface SalesforceEventNameSpec {
    name: string;
    eventType: string;
    configurationEventType: string;
}

export const SALESFORCE_EVENT_START_MODEL_CONFIGURATION: SalesforceEventNameSpec =
    {
        name: 'Configurator Engagement',
        eventType: 'ConfiguratorEngagement',
        configurationEventType: 'Start Model Configuration'
    };

export const SALESFORCE_EVENT_CONFIGURATOR_GET_LINK: SalesforceEventNameSpec = {
    name: 'Configurator Engagement',
    eventType: 'ConfiguratorEngagement',
    configurationEventType: 'Configurator Get Link'
};

export const SALESFORCE_EVENT_CONFIGURATOR_GET_LINK_FROM_MODAL: SalesforceEventNameSpec =
    {
        name: 'Configurator Engagement',
        eventType: 'ConfiguratorEngagement',
        configurationEventType:
            'Configurator Link from Configuration Code Modal'
    };

export const SALESFORCE_EVENT_CONFIGURATOR_CODE: SalesforceEventNameSpec = {
    name: 'Configurator Engagement',
    eventType: 'ConfiguratorEngagement',
    configurationEventType: 'Configuration Code'
};

export const SALESFORCE_EVENT_CONFIGURATOR_DOWNLOAD_BROCHURE: SalesforceEventNameSpec =
    {
        name: 'Configurator Engagement',
        eventType: 'ConfiguratorEngagement',
        configurationEventType: 'Configurator Download Personalised Brochure'
    };

export const SALESFORCE_EVENT_CONFIGURATOR_EMAIL_BROCHURE: SalesforceEventNameSpec =
    {
        name: 'Configurator Engagement',
        eventType: 'ConfiguratorEngagement',
        configurationEventType: 'Configurator Email Personalised Brochure'
    };

export const SALESFORCE_EVENT_SUBMIT_CONFIGURATION_TO_DEALER: SalesforceEventNameSpec =
    {
        name: 'Configurator Engagement',
        eventType: 'ConfiguratorEngagement',
        configurationEventType: 'Submit Configuration to Dealer'
    };
