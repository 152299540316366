import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
    UiButtonModule,
    UiIconsModule,
    UiInputModule,
    UiLazyloadImageModule,
    UiSelectModule
} from '@mhp/ui-components';
import { I18nModule, L10nModule } from '@mhp/ui-shared-services';

import { LoadingIndicatorModule } from '../../common/loading-indicator/loading-indicator.module';
import { SpinnerModule } from '../../common/spinner/spinner.module';
import { DialogModule } from '../../dialog/dialog.module';
import { FinancialServicesInputService } from './financial-services-input.service';
import { FinancialServicesSessionService } from './financial-services-session.service';
import { FinancialServicesService } from './financial-services.service';
import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';

@NgModule({
    declarations: [SettingsDialogComponent],
    imports: [
        CommonModule,
        DialogModule,
        I18nModule,
        ReactiveFormsModule,
        UiInputModule,
        UiSelectModule,
        UiButtonModule,
        UiIconsModule,
        MatIconModule,
        UiLazyloadImageModule,
        LoadingIndicatorModule,
        SpinnerModule,
        L10nModule
    ]
})
export class FinancialServicesModule {
    static forRoot(): ModuleWithProviders<FinancialServicesModule> {
        return {
            ngModule: FinancialServicesModule,
            providers: [
                FinancialServicesService,
                FinancialServicesInputService,
                FinancialServicesSessionService
            ]
        };
    }
}
