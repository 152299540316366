import { isEqual, pick } from 'lodash-es';
import { distinctUntilChanged, map } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IllegalStateError } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { ApplicationStateService } from '@mhp/ui-shared-services';

import { AudioVideoSettings } from '../../common/audio-video-settings/audio-video-settings.interface';
import { selectOne2OneState } from '../../dealer';
import {
    setTargetAudioSetting,
    setTargetVideoSetting
} from '../../dealer/one2one/state/actions/one2one-state.actions';
import { LocalApplicationState } from '../../state';

@Component({
    selector: 'mhp-audio-video-settings-form-control',
    templateUrl: './audio-video-settings-form-control.component.html',
    styleUrls: ['./audio-video-settings-form-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioVideoSettingsFormControlComponent extends UiBaseComponent {
    @Output() audioChange = new EventEmitter<boolean>();

    @Output() videoChange = new EventEmitter<boolean>();

    readonly audioVideoSettingsFormGroup: UntypedFormGroup;

    readonly slideToggleLabelPosition = 'before';

    get audioFormControl() {
        return this.audioVideoSettingsFormGroup.get('audio');
    }

    get videoFormControl() {
        return this.audioVideoSettingsFormGroup.get('video');
    }

    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>
    ) {
        super();

        this.audioVideoSettingsFormGroup = this.initForm();

        this.initSyncStateAndToggles();
        this.listenToDeviceUpdates();
        this.listenToAudioControlUpdates();
        this.listenToVideoControlUpdates();
    }

    private initForm() {
        return new UntypedFormGroup({
            video: new UntypedFormControl(false),
            audio: new UntypedFormControl(false)
        });
    }

    private listenToVideoControlUpdates() {
        if (!this.videoFormControl) {
            throw new IllegalStateError('Missing video form control');
        }

        this.videoFormControl.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                this.videoChange.next(value);
                this.applicationStateService.dispatch(
                    setTargetVideoSetting({
                        targetVideo: this.videoFormControl?.value
                    })
                );
            });
    }

    private listenToAudioControlUpdates() {
        if (!this.audioFormControl) {
            throw new IllegalStateError('Missing audio form control');
        }

        this.audioFormControl.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                this.audioChange.next(value);
                this.applicationStateService.dispatch(
                    setTargetAudioSetting({
                        targetAudio: this.audioFormControl?.value
                    })
                );
            });
    }

    private initSyncStateAndToggles() {
        this.applicationStateService
            .getLocalState()
            .pipe(
                selectOne2OneState,
                map((state) => state.audioVideoSettings),
                map(
                    (audioVideoSettings) =>
                        pick(audioVideoSettings, 'audio', 'video') as Pick<
                            AudioVideoSettings,
                            'audio' | 'video'
                        >
                ),
                distinctUntilChanged(isEqual),
                this.takeUntilDestroy()
            )
            .subscribe(
                (
                    audioVideoSettings: Pick<
                        AudioVideoSettings,
                        'audio' | 'video'
                    >
                ) => {
                    this.videoFormControl?.setValue(audioVideoSettings.video, {
                        emitEvent: false
                    });
                    this.audioFormControl?.setValue(audioVideoSettings.audio, {
                        emitEvent: false
                    });
                }
            );
    }

    private listenToDeviceUpdates() {
        this.applicationStateService
            .getLocalState()
            .pipe(
                selectOne2OneState,
                map((state) => state.audioVideoSettings),
                distinctUntilChanged((a, b) =>
                    isEqual(
                        pick(a, 'audioDeviceId', 'videoDeviceId'),
                        pick(b, 'audioDeviceId', 'videoDeviceId')
                    )
                ),
                this.takeUntilDestroy()
            )
            .subscribe((audioVideoSettings: AudioVideoSettings) => {
                const { audioDeviceId, videoDeviceId } = audioVideoSettings;

                if (videoDeviceId) {
                    this.videoFormControl?.enable({ emitEvent: false });
                } else {
                    this.videoFormControl?.disable({ emitEvent: false });
                }

                if (audioDeviceId) {
                    this.audioFormControl?.enable({ emitEvent: false });
                } else {
                    this.audioFormControl?.disable({ emitEvent: false });
                }
            });
    }
}
