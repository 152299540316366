import { first as _first, isEqual } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import {
    ButtonSize,
    TRACK_BY_ID,
    UiBaseComponent,
    UiGridListItem,
    UiGridListItemComponent
} from '@mhp/ui-components';
import { StaticAssetService } from '@mhp/ui-shared-services';

export interface EngineSettingsItem extends UiGridListItem {
    hasInfoContent?: boolean;
}

@Component({
    selector: 'mhp-engine-settings',
    templateUrl: './engine-settings.component.html',
    styleUrls: ['./engine-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EngineSettingsComponent extends UiBaseComponent {
    readonly imageFallbackUrl : string;
    readonly trackById = TRACK_BY_ID;

    readonly BUTTON_SIZE = ButtonSize;

    @Input()
    items: EngineSettingsItem[];

    @Input()
    title: string;

    @Output()
    readonly selectItem = new EventEmitter<string>();

    @Output()
    readonly showInfo = new EventEmitter<string>();

    @Output()
    readonly doClose = new EventEmitter<void>();

    @ViewChildren('listItem', {
        read: UiGridListItemComponent
    })
    listItems: QueryList<UiGridListItemComponent>;

    constructor(
        private readonly staticAssetsService: StaticAssetService,
        private readonly elementRef: ElementRef
    ) {
        super();

        this.completeOnDestroy(this.selectItem, this.doClose, this.showInfo);
        this.initFocusSelectedItemOnItemsChange();
        this.imageFallbackUrl = this.staticAssetsService.getPlaceholderImage()
    }

    intentSelectItem(itemId: string) {
        this.selectItem.emit(itemId);
    }

    @HostListener('document:keydown.escape')
    intentClose() {
        this.doClose.emit();
    }

    intentShowInfo(id: string, event: MouseEvent | KeyboardEvent) {
        event.stopPropagation();

        this.showInfo.emit(id);
    }

    private focusSelectedItem(options: ScrollIntoViewOptions) {
        const selectedItem = _first(
            this.listItems.filter((item) => item.selected === true)
        );
        selectedItem?.scrollIntoView(options);
    }

    private initFocusSelectedItemOnItemsChange() {
        combineLatest([
            this.isVisible$(),
            this.observeProperty<EngineSettingsComponent, EngineSettingsItem[]>(
                'items',
                false
            )
        ])
            .pipe(
                distinctUntilChanged(isEqual),
                filter(([isVisible]) => isVisible),
                debounceTime(300),
                this.takeUntilDestroy()
            )
            .subscribe(() => {
                this.focusSelectedItem({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'nearest'
                });
            });
    }

    @MemoizeObservable()
    private isVisible$(): Observable<boolean> {
        const options = {
            threshold: 0.99
        };

        return new Observable<boolean>((subscriber) => {
            const intersectionObserver = new IntersectionObserver((entries) => {
                for (const entry of entries) {
                    subscriber.next(entry.isIntersecting);
                }
            }, options);

            intersectionObserver.observe(this.elementRef.nativeElement);

            return () => {
                intersectionObserver.disconnect();
            };
        }).pipe(lazyShareReplay());
    }
}
