<mhp-ui-mat-dialog
    class="save-configuration-dialog"
    *transloco="let t"
    [title]="
        isOverwrite
            ? t('ORDER_MANAGEMENT.SAVE_CONFIG_DIALOG.HEADLINE_OVERWRITE')
            : t('ORDER_MANAGEMENT.SAVE_CONFIG_DIALOG.HEADLINE_SAVE')
    "
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <form [formGroup]="configurationTitleFormGroup">
            <mat-form-field class="mhp-ui-form-field--full-width">
                <mat-label>{{
                    t('ORDER_MANAGEMENT.SAVE_CONFIG_DIALOG.NAME')
                }}</mat-label>
                <input
                    matInput
                    #fieldRef="matInput"
                    formControlName="configurationName"
                    type="text"
                    [required]="true"
                    [value]="this.configurationTitle"
                    autocomplete="off"
                />
            </mat-form-field>
        </form>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-button
            class="mhp-ui-button--full-width"
            (click)="onCancel()"
        >
            {{ t('COMMON.CANCEL') }}
        </button>
        <button
            mat-flat-button
            class="mhp-ui-button--full-width"
            (click)="intentSaveConfig()"
            [disabled]="!configurationTitleFormGroup.valid"
        >
            <span>{{
                this.isOverwrite
                    ? t('ORDER_MANAGEMENT.SAVE_CONFIG_DIALOG.OVERWRITE')
                    : t('ORDER_MANAGEMENT.SAVE_CONFIG_DIALOG.SAVE')
            }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
