import { Observable, catchError, combineLatest, iif, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { getPrivacyUrl } from '@mhp/aml-shared/country-mapping/legal-links';
import { PricingTypeEnum } from '@mhp/aml-shared/data-proxy/pricing-type.enum';
import {
    DealerInfoService,
    ExtendedDealer,
    LeadDetails,
    SalesforceService
} from '@mhp/aml-ui-shared-services';
import {
    MemoizeObservable,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import {
    ApplicationStateService,
    ErrorHandlerService,
    I18nService,
    L10nService,
    UrlShortenerService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { PricingService } from '../../dealer/pricing/pricing.service';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { ExtendedUiOptionGroup } from '../configuration-model/configuration-interfaces';
import { isExtendedUiOptionCode } from '../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { StaticRendererService } from '../static-renderer/static-renderer.service';
import { setActiveSalesforceTransactionKey } from './state/actions/salesforce-state.actions';
import { selectSalesforceTransactionKey } from './state/selectors/salesforce-state.selectors';
import { UtmParametersWrapper } from './utm-parameters/utm-parameters-wrapper';
import {
    UTM_PARAMETERS_SOURCE,
    UtmParametersService
} from './utm-parameters/utm-parameters.service';

export interface LeadContextData {
    // possibly active lead id. May not be available when calling from route-guard
    salesforceTransactionKey?: string;
    languagePreference: string;
    gdprFormReference: string;
    // active configuration. May be available or not, depending on the active app-area
    configurationEncoded?: string;
    dealerConfigurationLink?: string;
    // config short code
    configCode?: string;
    // active modelId. May be available or not, depending on the active app-area
    modelId?: string;
    // active productId. May be available or not, depending on the active app-area
    productId?: string;
    // configuration-details
    transmission?: string;
    exteriorColor?: string;
    interiorColor1?: string;
    interiorColor2?: string;
    // UTM parameter data
    utmData?: UtmParametersWrapper;
}

@Injectable()
export class SalesforceContextService {
    constructor(
        private readonly salesforceService: SalesforceService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly i18nService: I18nService,
        private readonly l10nService: L10nService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly pricingService: PricingService,
        private readonly urlShortenerService: UrlShortenerService,
        private readonly dealerInfoService: DealerInfoService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly staticRendererService: StaticRendererService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly configurationNodeLookupService: ConfigurationNodeLookupService,
        private readonly utmParametersService: UtmParametersService
    ) {
        this.initSyncLeadTransactionKeyToAppState();
    }

    /**
     * Get customer details for an active transactionId
     * @param transactionId Optional transactionId that overrides the currently active one obtained via #getSalesforceContextData$()
     */
    getLeadDetails$(
        transactionId?: string
    ): Observable<LeadDetails | undefined> {
        return (
            transactionId
                ? of(transactionId)
                : this.getSalesforceContextData$().pipe(
                      map((contextData) => contextData.salesforceTransactionKey)
                  )
        ).pipe(
            switchMap((transactionKey) => {
                if (!transactionKey) {
                    return of(undefined);
                }
                return this.salesforceService.getLeadDetails$(transactionKey);
            })
        );
    }

    /**
     * Returns a set of salesforce-related context data combined from different sources.
     */
    getSalesforceContextData$(options?: {
        includeDealerConfigurationUrl?: boolean;
        includeShortCode?: boolean;
    }): Observable<LeadContextData> {
        return combineLatest([
            this.i18nService.getActiveLang$(),
            this.configurationSessionInfoService.getActiveModelId$(),
            this.configurationSessionInfoService.getActiveProductId$(),
            this.getSerializedConfigurationData$(),
            iif(
                () => !!options?.includeDealerConfigurationUrl,
                this.configurationSessionInfoService
                    .getCurrentConfigurationDealerUrl$()
                    .pipe(
                        switchMap((configUrl) => {
                            if (!configUrl) {
                                return of(undefined);
                            }
                            return this.urlShortenerService.shortenUrl$(
                                configUrl
                            );
                        })
                    ),
                of(undefined)
            ),
            iif(
                () => !!options?.includeShortCode,
                this.configurationSessionInfoService
                    .getCurrentConfigurationUrl$()
                    .pipe(
                        switchMap((configUrl) => {
                            if (!configUrl) {
                                return of(undefined);
                            }
                            return this.urlShortenerService
                                .generateShortCode$(configUrl)
                                .pipe(catchError(() => of(undefined)));
                        })
                    ),
                of(undefined)
            ),
            this.applicationStateService
                .getLocalState()
                .pipe(selectSalesforceTransactionKey),
            this.productConfigurationSessionService.getOptionGroups$(),
            this.utmParametersService.getUtmParameters$()
        ]).pipe(
            distinctUntilChangedEquality(),
            map(
                ([
                    activeLang,
                    modelId,
                    productId,
                    encodedConfiguration,
                    shortenedDealerConfigurationUrl,
                    shortCode,
                    salesforceTransactionKey,
                    optionGroups,
                    utmParameters
                ]) => {
                    // determine fields which need to be derived from the current config
                    let exteriorColor: string | undefined;
                    let interiorColor1: string | undefined;
                    let interiorColor2: string | undefined;

                    if (optionGroups) {
                        exteriorColor = this.getSelectedOptionNode(
                            optionGroups,
                            [
                                environment.appConfig.configuration
                                    .identifierExterior,
                                environment.appConfig.configuration
                                    .identifierPaint
                            ]
                        )?.node?.nameTranslated;
                        interiorColor1 = this.getSelectedOptionNode(
                            optionGroups,
                            [
                                environment.appConfig.configuration
                                    .identifierInterior,
                                environment.appConfig.configuration
                                    .identifierInteriorPrimary
                            ]
                        )?.node?.nameTranslated;
                        interiorColor2 = this.getSelectedOptionNode(
                            optionGroups,
                            [
                                environment.appConfig.configuration
                                    .identifierInterior,
                                environment.appConfig.configuration
                                    .identifierInteriorSecondary
                            ]
                        )?.node?.nameTranslated;
                    }

                    return {
                        salesforceTransactionKey,
                        languagePreference: activeLang,
                        configurationEncoded: encodedConfiguration,
                        dealerConfigurationLink:
                            shortenedDealerConfigurationUrl,
                        gdprFormReference:
                            environment.appConfig.crm.salesforce
                                .gdprFormReference,
                        modelId,
                        productId,
                        transmission: productId ? 'Automatic' : undefined,
                        exteriorColor,
                        interiorColor1,
                        interiorColor2,
                        utmData: utmParameters
                    };
                }
            )
        );
    }

    /**
     * Depending on a given user choice, determine the pricing-type that is to be used when submitting to salesforce.
     * @param userSelectionToIncludePrices$
     */
    getPricingTypeToBeUsed$(
        userSelectionToIncludePrices$: Observable<boolean>
    ): Observable<PricingTypeEnum> {
        return userSelectionToIncludePrices$.pipe(
            switchMap((userSelectionToIncludePrices) => {
                if (!userSelectionToIncludePrices) {
                    return of(PricingTypeEnum.NONE);
                }
                return combineLatest([
                    this.pricingService.getActivePricingType$(),
                    this.pricingService.isRRPAvailableForDealer$()
                ]).pipe(
                    map(
                        ([
                            activePricingType,
                            isRRPAvailable
                        ]): PricingTypeEnum => {
                            if (activePricingType !== 'HIDE') {
                                return activePricingType === 'DNP'
                                    ? PricingTypeEnum.WHOLESALE
                                    : PricingTypeEnum.RETAIL;
                            }
                            // if prices are currently hidden, use rrp if available, dnp otherwise
                            return isRRPAvailable
                                ? PricingTypeEnum.RETAIL
                                : PricingTypeEnum.WHOLESALE;
                        }
                    )
                );
            })
        );
    }

    @MemoizeObservable()
    getGdprMarkdown$(number: number) {
        return this.l10nService.getActiveCountry$().pipe(
            map((activeCountry) => {
                let markdownRaw = translate<string>(
                    `SUMBIT_TO_DEALER.GENERAL.GDPR_${number}`
                );
                const countrySpecificPrivacyPolicyUrl =
                    getPrivacyUrl(activeCountry);
                // FIXME: When adjusted translations are available, this workaround can be removed
                const basePrivacyLink =
                    'https://www.astonmartin.com/legal/privacy';
                if (markdownRaw.indexOf(basePrivacyLink)) {
                    markdownRaw = markdownRaw.replace(
                        basePrivacyLink,
                        countrySpecificPrivacyPolicyUrl
                    );
                } else {
                    ['Privacy Policy', 'Datenschutzrichtlinie'].forEach(
                        (privacyLabel) => {
                            markdownRaw = markdownRaw.replace(
                                privacyLabel,
                                `<a href="${countrySpecificPrivacyPolicyUrl}" target="_blank">${privacyLabel}</a>`
                            );
                        }
                    );
                }

                return markdownRaw;
            }),
            lazyShareReplay()
        );
    }

    /**
     * Delegates to DealerInfoService but retries possible errors and shows notifications to the user.
     */
    @MemoizeObservable()
    getAvailableCountries$(): Observable<
        { countryISO: string; label: string }[]
    > {
        return this.dealerInfoService
            .getCountriesWhereDealersAreAvailable$()
            .pipe(
                this.errorHandlerService.applyRetryWithHintsOnError(() =>
                    translate('FORM.ERROR.FAILED_FETCHING_DEALERS')
                )
            );
    }

    /**
     * Delegates to DealerInfoService but retries possible errors and shows notifications to the user.
     */
    getAvailableDealers$(
        targetCountry$: Observable<string | undefined>
    ): Observable<ExtendedDealer[] | undefined> {
        return this.dealerInfoService
            .getDealersRelevantForDisplayForCountry$(targetCountry$)
            .pipe(
                this.errorHandlerService.applyRetryWithHintsOnError(() =>
                    translate('FORM.ERROR.FAILED_FETCHING_DEALERS')
                )
            );
    }

    /**
     * Returns a possibly preselected dealer based on the utmSource.
     * See https://dev.azure.com/AstonMartinLagonda/Digital/_workitems/edit/56742
     */
    getPreselectedDealer$(): Observable<ExtendedDealer | undefined> {
        return this.utmParametersService.getUtmParameters$().pipe(
            switchMap((utmParamters) => {
                const utmSource =
                    utmParamters.getParameters()[UTM_PARAMETERS_SOURCE];
                if (!utmSource) {
                    return of(undefined);
                }

                // check if the utmSource can be matched against an available dealer
                return this.dealerInfoService
                    .getDealersRelevantForDisplay$({
                        dealerCode: utmSource
                    })
                    .pipe(map((dealers) => (dealers ? dealers[0] : undefined)));
            })
        );
    }

    extractTransactionKeyFromParams(params: Params) {
        return params[
            environment.appConfig.crm.salesforce.transactionKeyQueryParamName
        ];
    }

    private getSerializedConfigurationData$() {
        return this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                map((sessionInfo) =>
                    sessionInfo
                        ? this.staticRendererService.getSerializedIodEngineSessionData(
                              sessionInfo.engineData
                          )
                        : undefined
                )
            );
    }

    private initSyncLeadTransactionKeyToAppState() {
        this.activatedRoute.queryParams
            .pipe(
                map((queryParams) =>
                    this.extractTransactionKeyFromParams(queryParams)
                )
            )
            .subscribe((transactionKeyFromParams) => {
                if (!transactionKeyFromParams) {
                    return;
                }
                this.applicationStateService.dispatch(
                    setActiveSalesforceTransactionKey({
                        transactionKey: transactionKeyFromParams
                    })
                );
            });
    }

    /**
     * Get the selected OptionCode inside the given parent-hierarchy.
     * @param optionGroups The optionGroups to search in.
     * @param requiredParentNames The parent-names that are needed in the hierarchy.
     * @private
     */
    private getSelectedOptionNode(
        optionGroups: ExtendedUiOptionGroup[],
        requiredParentNames: string[]
    ) {
        return this.configurationNodeLookupService.findNode((node, parents) => {
            if (!isExtendedUiOptionCode(node) || !node.selected) {
                return false;
            }
            const parentNames = parents?.map((parent) => parent.name);
            const parentsLength = parentNames?.filter((parentName) =>
                requiredParentNames.includes(parentName ?? '')
            )?.length;
            return (
                !!parentsLength && parentsLength >= requiredParentNames.length
            );
        }, optionGroups);
    }
}
