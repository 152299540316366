<ng-container *transloco="let t; read: 'CONFIGURATOR'">
    <div class="configuration-bar-compact__navigation-top">
        <div
            class="configuration-bar-compact__navigation-top-search"
            [ngClass]="{
                'configuration-bar-compact__navigation-top-search--active':
                    (isSearchInputActive$() | async) === true
            }"
        >
            <mhp-search [searchState]="searchState"></mhp-search>
        </div>
        <div
            class="configuration-bar-compact__navigation-top-label"
            [ngClass]="{
                'configuration-bar-compact__navigation-top-label--hidden':
                    (isSearchInputActive$() | async) === true
            }"
        >
            <span>{{ (getActiveTopLevelItem$() | async)?.label }}</span>
            <!-- SEARCH RESULTS -->
            <span
                class="configuration-bar-compact__navigation-top-search-count"
                *ngIf="
                    (isDisplayingSearchResults$() | async) === true &&
                    (getActiveTopLevelSearchResultCount$() | async) !==
                        undefined
                "
                >&nbsp;({{
                    getActiveTopLevelSearchResultCount$() | async
                }})</span
            >
            <!-- EDITIONS INFO -->
            <ng-container *transloco="let t; read: 'ICONS'">
                <mhp-ui-icon-button
                    *ngIf="
                        (getActiveTopLevelItem$() | async)?.nameInternal ===
                            CATEGORY_EDITIONS &&
                        (isEditionActive$() | async) === true
                    "
                    svgIcon="mhp-ui:info"
                    [matTooltip]="
                        t('INFO_ON_ALT', {
                            topic: (getActiveTopLevelItem$() | async)?.label
                        })
                    "
                    [label]="
                        t('INFO_ON_ALT', {
                            topic: (getActiveTopLevelItem$() | async)?.label
                        })
                    "
                    [trackGA4Event]="{
                        event: 'editions_open_info_dialog_click'
                    }"
                    (mousedown)="
                        $event.stopPropagation();
                        $event.stopImmediatePropagation()
                    "
                    (click)="
                        $event.stopPropagation();
                        $event.stopImmediatePropagation();
                        intentShowEditionsInfo()
                    "
                >
                </mhp-ui-icon-button>
            </ng-container>
        </div>
        <div
            class="configuration-bar-compact__navigation-stepper"
            [ngClass]="{
                'configuration-bar-compact__navigation-stepper--hidden':
                    (isSearchInputActive$() | async) === true
            }"
            *transloco="let t; read: 'ICONS'"
        >
            <mhp-ui-icon-button
                svgIcon="mhp-ui:angle-left"
                class="configuration-bar-compact__navigation-stepper-action configuration-bar-compact__navigation-stepper-action--prev"
                [disabled]="(hasPrevTopLevelItem$() | async) === false"
                [matTooltip]="t('PREVIOUS_CATEGORY_ALT')"
                [label]="t('PREVIOUS_CATEGORY_ALT')"
                (click)="intentGoToPrev()"
            ></mhp-ui-icon-button>
            <mhp-ui-icon-button
                svgIcon="mhp-ui:angle-right"
                class="configuration-bar-compact__navigation-stepper-action configuration-bar-compact__navigation-stepper-action--next"
                [disabled]="(hasNextTopLevelItem$() | async) === false"
                [matTooltip]="t('NEXT_CATEGORY_ALT')"
                [label]="t('NEXT_CATEGORY_ALT')"
                (click)="intentGoToNext()"
            ></mhp-ui-icon-button>
        </div>
    </div>

    <!-- SEARCH-TERM-INDICATOR -->
    <div
        @toggle
        *ngIf="isDisplayingSearchResults$() | async"
        class="configuration-bar-compact__search-term-indicator"
    >
        <span>{{
            t('SEARCH.RESULT_COUNT_FOR', {
                count: getTotalSearchResultCount$() | async
            })
        }}</span>

        <mhp-search-term
            [searchTerm]="getActiveSearchTerm$() | async"
            (clearSearch)="intentExitSearch()"
        >
        </mhp-search-term>
    </div>

    <!-- CONFIGURATION-AREA -->
    <div
        #configurationAreaContainer
        class="configuration-bar-compact__configuration-area-container"
    >
        <ng-container
            *ngIf="getActiveTopLevelItem$() | async as activeTopLevelItem"
        >
            <ng-container
                *ngIf="
                    activeTopLevelItem.id === IDENTIFIER_SUMMARY;
                    else firstLevelGroups
                "
            >
                <mhp-configuration-summary
                    (optionSelected)="intentSelectItem($event)"
                ></mhp-configuration-summary>
            </ng-container>

            <ng-template #firstLevelGroups>
                <div
                    *ngIf="
                        getInteriorEnvironmentSelector$()
                            | async as environmentSelector
                    "
                    class="configuration-bar-compact__configuration-area-container-special-select"
                    [ngClass]="{
                        'configuration-bar-compact__configuration-area-container-special-select--single-option-parent':
                            environmentSelector.hasChangeableSelectionInHierarchy ===
                            false
                    }"
                >
                    <div>
                        <span>{{ environmentSelector.label }}</span>
                        <ng-container *transloco="let t; read: 'ICONS'">
                            <mhp-ui-icon-button
                                *ngIf="
                                    (isInteriorEnvironmentSelectionEnabled$()
                                        | async) === true
                                "
                                [matTooltip]="
                                    t('INFO_ON_ALT', {
                                        topic: environmentSelector.label
                                    })
                                "
                                [label]="
                                    t('INFO_ON_ALT', {
                                        topic: environmentSelector.label
                                    })
                                "
                                svgIcon="mhp-ui:info"
                                size="large"
                                (click)="intentShowEnvironmentLayer()"
                            ></mhp-ui-icon-button>
                        </ng-container>
                    </div>
                    <div>
                        <mat-form-field
                            class="mhp-ui-form-field--no-placeholder mhp-ui-form-field--no-bottom-spacing mhp-ui-form-field--no-underline mhp-ui-form-field--full-width"
                        >
                            <mat-select
                                [value]="
                                    getInteriorEnvironmentSelectedId$() | async
                                "
                                [disabled]="updatingOptionSelection()"
                                (selectionChange)="
                                    intentSelectItem($event.value)
                                "
                                [panelWidth]="null"
                            >
                                <mat-option
                                    *ngFor="
                                        let environmentEntry of environmentSelector.children;
                                        trackBy: trackById
                                    "
                                    [value]="environmentEntry.id"
                                    >{{ environmentEntry.label }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div
                    class="configuration-bar-compact__configuration-area-container-groups-container"
                >
                    <div
                        class="configuration-bar-compact__configuration-area-container-groups"
                    >
                        <ng-container
                            *ngFor="
                                let contentAware of getFirstLevelContentAwares$()
                                    | async;
                                trackBy: trackById
                            "
                        >
                            <mhp-configuration-group-first-level
                                [contentAware]="contentAware"
                                [expanded]="
                                    (getExpansionState$() | async).has(
                                        contentAware.id
                                    )
                                "
                                [renderType]="
                                    getTopLevelCategoryRenderType$() | async
                                "
                                [scrollIntoView$]="getScrollIntoViewEmitter$()"
                                [specialSelect]="
                                    hasInteriorStitchingSelector(contentAware)
                                        ? (getInteriorStitchingSelector$()
                                          | async)
                                        : undefined
                                "
                                [optionSelectionDisabled]="updatingOptionSelection()"
                                (expandedChange)="
                                    intentExpandGroup(contentAware, $event)
                                "
                                (intentSelect)="intentSelectItem($event)"
                                (focusOnStuck)="onItemHeaderStuck(contentAware)"
                            ></mhp-configuration-group-first-level
                        ></ng-container>
                    </div>
                    <div class="configuration-bar-compact__quicknav-bottom">
                        <button
                            *ngIf="
                                getNextTopLevelItemExcludingSummary$()
                                    | async as nextTopLevelCategory
                            "
                            mat-flat-button
                            (click)="intentGoToNext()"
                        >
                            <span>{{ nextTopLevelCategory.label }}</span>
                        </button>
                        <button
                            class="mhp-ui-button--prominent"
                            mat-flat-button
                            (click)="intentGoToSummary()"
                        >
                            <mhp-ui-icon
                                svgIcon="mhp-ui:summary"
                                size="small"
                            ></mhp-ui-icon>
                            <span>{{
                                (getSummaryTopLevelItem$() | async)?.label
                            }}</span>
                        </button>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</ng-container>
