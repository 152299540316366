<form
    class="salesforce-form__form aml-form__form"
    [ngClass]="'aml-form__form--' + (getActiveLang$() | async)"
    [formGroup]="leadFormGroup"
    (ngSubmit)="intentSubmit()"
    [attr.id]="formId"
    *transloco="let tForm; read: 'FORM'"
>
    <section
        class="aml-form__form-block aml-form__form-block--dealer-select"
        *ngIf="requireDealerSelect || !fixedCountry"
    >
        <mat-form-field
            class="mhp-ui-form-field--full-width"
            *ngIf="!fixedCountry"
        >
            <mat-label>{{ tForm('COUNTRY_LABEL') }}</mat-label>
            <mat-select
                formControlName="country"
                [trackGA4Event]="{
                    event: 'form_values_enter',
                    country: '1'
                }"
                [trackBlur]="true"
            >
                @if (leadFormGroup.get('country').value === NULL_VALUE &&
                (serverCallInProgress$ | async) === true) {
                <mat-option [value]="NULL_VALUE" *transloco="let t">
                    {{ t('COMMON.LOADING') }}
                </mat-option>
                }
                <mat-option
                    *ngFor="
                        let countryWithLabel of getAvailableCountries$() | async
                    "
                    [value]="countryWithLabel.countryISO"
                >
                    {{ countryWithLabel.label }}</mat-option
                >
            </mat-select>
        </mat-form-field>

        <mat-form-field
            class="mhp-ui-form-field--full-width"
            *ngIf="requireDealerSelect"
        >
            <mat-label>{{ tForm('DEALER_LABEL') }}</mat-label>
            <mat-select
                formControlName="dealer"
                [compareWith]="compareDealers"
                [trackGA4Event]="{
                    event: 'form_values_enter',
                    dealer: '1'
                }"
                [trackBlur]="true"
            >
                @if (leadFormGroup.get('dealer').value === NULL_VALUE &&
                (serverCallInProgress$ | async) === true) {
                <mat-option [value]="NULL_VALUE" *transloco="let t">
                    {{ t('COMMON.LOADING') }}
                </mat-option>
                }
                <mat-option
                    *ngFor="let dealer of getAvailableDealers$() | async"
                    [value]="dealer"
                    >{{ dealer.knownAsTranslated }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </section>

    <section class="aml-form__form-block aml-form__form-block--personal-info">
        <mat-form-field class="mhp-ui-form-field--full-width">
            <mat-label>{{ tForm('TITLE_LABEL') }}</mat-label>
            <mat-select
                formControlName="title"
                [trackGA4Event]="{
                    event: 'form_values_enter',
                    title: '1'
                }"
                [trackBlur]="true"
            >
                <mat-option [value]="null"></mat-option>
                <mat-option
                    *ngFor="let title of availableTitles"
                    [value]="title.key"
                    >{{ title.value }}</mat-option
                >
            </mat-select>
        </mat-form-field>

        <div class="aml-form__field-group mhp-ui-form-field--full-width">
            <mat-form-field
                class="mhp-ui-form-field--full-width aml-form__form-field--aside"
            >
                <mat-label>{{ tForm('FIRSTNAME_LABEL') }}</mat-label>
                <input
                    matInput
                    formControlName="firstName"
                    type="text"
                    [trackGA4Event]="{
                        event: 'form_values_enter',
                        first_name: '1'
                    }"
                    [trackBlur]="true"
                />
            </mat-form-field>

            <mat-form-field
                class="mhp-ui-form-field--full-width aml-form__form-field--aside aml-form__form-field--lastname"
            >
                <mat-label>{{ tForm('LASTNAME_LABEL') }}</mat-label>
                <input
                    matInput
                    formControlName="lastName"
                    type="text"
                    [trackGA4Event]="{
                        event: 'form_values_enter',
                        last_name: '1'
                    }"
                    [trackBlur]="true"
                />
            </mat-form-field>
        </div>

        <mat-form-field
            class="mhp-ui-form-field--full-width aml-form__form-field--aside"
        >
            <mat-label>{{ tForm('EMAIL_LABEL') }}</mat-label>
            <input
                matInput
                formControlName="customerEmail"
                type="email"
                [trackGA4Event]="{
                    event: 'form_values_enter',
                    email: '1'
                }"
                [trackBlur]="true"
            />
        </mat-form-field>

        <mat-form-field
            class="mhp-ui-form-field--full-width aml-form__form-field--aside"
        >
            <mat-label>{{ tForm('PHONE_LABEL') }}</mat-label>
            <input
                matInput
                formControlName="customerPhone"
                type="tel"
                [trackGA4Event]="{
                    event: 'form_values_enter',
                    phone: '1'
                }"
                [trackBlur]="true"
            />
        </mat-form-field>
    </section>

    <section
        *ngIf="requiresConsent"
        class="aml-form__form-block salesforce-form__consent-info"
    >
        <ng-container *transloco="let t">
            <div
                [innerHTML]="getGdprMarkdown$(1) | async | markdown | async"
            ></div>
            <div class="salesforce-form__consent-info-block">
                <h3>{{ tForm('CONSENT_LABEL') }}</h3>

                <div
                    class="salesforce-form__contact-options"
                    formGroupName="contactOptions"
                >
                    <mat-checkbox
                        color="primary"
                        formControlName="contactViaEmail"
                        [trackGA4Event]="{
                            event: 'contact_options_select',
                            optin_email: '1'
                        }"
                        >{{ tForm('EMAIL_LABEL_2') }}</mat-checkbox
                    >

                    <mat-checkbox
                        color="primary"
                        formControlName="contactViaTelephone"
                        [trackGA4Event]="{
                            event: 'contact_options_select',
                            optin_phone: '1'
                        }"
                        >{{ tForm('PHONE_LABEL_2') }}</mat-checkbox
                    >

                    <mat-checkbox
                        color="primary"
                        formControlName="contactViaSms"
                        [trackGA4Event]="{
                            event: 'contact_options_select',
                            optin_sms: '1'
                        }"
                        >{{ tForm('SMS_LABEL') }}</mat-checkbox
                    >

                    <mat-checkbox
                        color="primary"
                        formControlName="contactViaNewsletter"
                        [trackGA4Event]="{
                            event: 'contact_options_select',
                            optin_newsletter: '1'
                        }"
                        >{{ tForm('NEWSLETTER_LABEL') }}</mat-checkbox
                    >
                </div>
            </div>
            <div
                [innerHTML]="getGdprMarkdown$(2) | async | markdown | async"
            ></div>
        </ng-container>
    </section>

    <section
        class="aml-form__form-block salesforce-form__actions"
        *transloco="let tSubmitDealer; read: 'SUMMARY.SUBMIT_DEALER'"
    >
        <button
            mat-flat-button
            class="mhp-ui-button--block mhp-ui-button mhp-ui-button--highlight"
            [disabled]="(canSubmit$() | async) === false"
            *transloco="
                let tSubmitToSalesforce;
                read: 'SUMMARY.SUBMIT_CUSTOMER'
            "
        >
            <span>{{
                formId === 'customer-salesforce-form'
                    ? tSubmitDealer('SUBMIT')
                    : tSubmitToSalesforce('SUBMIT')
            }}</span>
        </button>
    </section>
</form>
