<ng-container *transloco="let t">
    <mhp-loading-indicator [loading]="this.showLoader"></mhp-loading-indicator>
    <mhp-header-bar>
        <ng-container header-bar_area-left>
            <div>
                <mhp-ui-icon-button
                    svgIcon="mhp-ui:back_2"
                    color="black"
                    size="large"
                    (click)="goBackToPrevious()"
                ></mhp-ui-icon-button>
            </div>
        </ng-container>
        <ng-container header-bar_area-right>
            <mhp-dealer-header-info></mhp-dealer-header-info>
            <mhp-ui-icon-button
                class="order-management__table-list-menu-right"
                [matMenuTriggerFor]="beforeMenu"
                svgIcon="mhp-ui:menu"
            ></mhp-ui-icon-button>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="navigateToOMOverview()">
                    <mhp-ui-icon-button
                        svgIcon="mhp-ui:back_2"
                    ></mhp-ui-icon-button>
                    {{ t('ORDER_MANAGEMENT.NAVIGATION.ORDER_OVERVIEW') }}
                </button>
                <button mat-menu-item (click)="navigateToVisualConfigurator()">
                    <mhp-ui-icon-button
                        svgIcon="mhp-ui:back_2"
                    ></mhp-ui-icon-button>
                    {{ t('ORDER_MANAGEMENT.NAVIGATION.MODEL_SELECT') }}
                </button>
            </mat-menu>
        </ng-container>
    </mhp-header-bar>
    <form
        class="aml-form__form order-management__content"
        [formGroup]="orderFormGroup"
        (ngSubmit)="intentSubmitOrder()"
        [attr.id]="formId"
    >
        <p
            *ngIf="forceOrderDisabled"
            class="order-management__warning"
            [innerHTML]="
                'Ordering is unavailable from <strong>' +
                forceOrderDisabled.from.toLocaleString() +
                '</strong> until <strong>' +
                forceOrderDisabled.to.toLocaleString() +
                '</strong> due to scheduled maintenance.'
            "
        ></p>
        <h3>{{ t('ORDER_MANAGEMENT.DETAIL_VIEW.DEALER_DETAILS') }}</h3>
        <ng-container
            *ngIf="
                getDealerInformation$() | async as dealerInformation;
                else loadingData
            "
        >
            <section>
                <div class="order-management__table-entry">
                    <div class="order-management__table-priority-title-left">
                        {{ t('ORDER_MANAGEMENT.DETAIL_VIEW.DEALER_NAME') }}
                    </div>
                    <div
                        class="order-management__table-priority-description-left"
                    >
                        {{ dealerInformation.knownAs }}
                    </div>
                </div>
            </section>
            <section>
                <div class="order-management__table-entry">
                    <div class="order-management__table-priority-title-left">
                        {{ t('ORDER_MANAGEMENT.DETAIL_VIEW.ACCOUNT_NUMBER') }}
                    </div>
                    <div
                        class="order-management__table-priority-description-left"
                    >
                        {{ dealerInformation.accountNumber }}
                    </div>
                </div>
            </section>
        </ng-container>

        <h3>{{ t('ORDER_MANAGEMENT.DETAIL_VIEW.HEADLINE') }}</h3>
        <section>
            <div class="order-management__table-entry">
                <div class="order-management__table-priority-title-left">
                    {{
                        t('ORDER_MANAGEMENT.DETAIL_VIEW.ORDER_PRIORITY')
                    }}&nbsp;*
                </div>
                <div class="order-management__table-priority-description-left">
                    <mat-form-field
                        class="mhp-ui-form-field--inherit"
                        floatLabel="always"
                    >
                        <mat-select
                            required
                            formControlName="orderPriority"
                            (selectionChange)="onPriorityChange($event.value)"
                        >
                            <mat-option
                                disabled
                                *ngIf="!this.orderPriorityList"
                            >
                                <ng-container [ngTemplateOutlet]="loadingData">
                                </ng-container>
                            </mat-option>
                            <ng-container *ngIf="this.orderPriorityList">
                                <mat-option
                                    *ngFor="let priority of orderPriorityList"
                                    [value]="priority.value"
                                    >{{ priority.label }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </section>
        <section>
            <div
                class="order-management__table-entry"
                [ngClass]="{
                    'order-management__table-entry--hidden': !showOpportunity
                }"
            >
                <div class="order-management__table-priority-title-left">
                    {{
                        t('ORDER_MANAGEMENT.DETAIL_VIEW.OPPORTUNITY_ID')
                    }}&nbsp;*
                </div>
                <div class="order-management__table-priority-description-left">
                    <mat-form-field
                        class="mhp-ui-form-field--inherit"
                        floatLabel="always"
                    >
                        <mat-select
                            required
                            formControlName="opportunityId"
                            (selectionChange)="
                                onOpportunityChange($event.value)
                            "
                        >
                            <mat-option
                                disabled
                                *ngIf="this.opportunityList === undefined"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="loadingData"
                                ></ng-container>
                            </mat-option>
                            <ng-container
                                *ngIf="
                                    this.opportunityList &&
                                    this.opportunityList.length > 0
                                "
                            >
                                <mat-option
                                    *ngFor="let opportunity of opportunityList"
                                    [value]="opportunity.id"
                                    >{{ opportunity.id }}
                                </mat-option>
                            </ng-container>
                            <ng-container
                                *ngIf="
                                    this.opportunityList &&
                                    this.opportunityList.length === 0
                                "
                            >
                                <mat-option disabled
                                    >{{
                                        t(
                                            'ORDER_MANAGEMENT.DETAIL_VIEW.NO_OPPORTUNITIES_FOUND'
                                        )
                                    }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </section>
        <section>
            <mhp-section-title
                [showDescription]="true"
                [dataAvailable]="!!configData"
                [showPricingDNP]="
                    targetShowPricingDNP &&
                    (getAvailablePricingTypes$() | async).includes('DNP')
                "
                [showPricingRRP]="
                    targetShowPricingRRP &&
                    (getAvailablePricingTypes$() | async).includes('RRP')
                "
            ></mhp-section-title>
            <mat-divider class="order-management__divider-edit"></mat-divider>
            <div class="order-management__table-entry">
                <div class="order-management__table-form-group-title-left">
                    {{
                        t('ORDER_MANAGEMENT.OVERVIEW.TABLE_HEADER_MODEL')
                    }}&nbsp;*
                </div>
                <div
                    class="order-management__table-form-group-description-left"
                >
                    <mat-form-field
                        class="mhp-ui-form-field--inherit"
                        floatLabel="always"
                    >
                        <mat-select
                            required
                            formControlName="modelCode"
                            (selectionChange)="onModelChange()"
                        >
                            <mat-option disabled *ngIf="!this.modelCodeList">
                                <ng-container
                                    [ngTemplateOutlet]="loadingData"
                                ></ng-container>
                            </mat-option>
                            <ng-container *ngIf="this.modelCodeList">
                                <mat-option
                                    *ngFor="let modelCode of modelCodeList"
                                    [value]="modelCode"
                                    >{{ modelCode }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    class="order-management__table-pricing-col-right"
                    *ngIf="
                        configData &&
                        targetShowPricingDNP &&
                        (getAvailablePricingTypes$() | async).includes('DNP')
                    "
                >
                    {{
                        (configData.modelBasePrice.dnp
                            | translocoCurrency
                                : undefined
                                : undefined
                                : configData.currency) ||
                            configData.modelBasePrice.noPrice
                    }}
                </div>
                <div
                    class="order-management__table-pricing-col-right"
                    *ngIf="
                        configData &&
                        targetShowPricingRRP &&
                        (getAvailablePricingTypes$() | async).includes('RRP')
                    "
                >
                    {{
                        (configData.modelBasePrice.rrp
                            | translocoCurrency
                                : undefined
                                : undefined
                                : configData.currency) ||
                            configData.modelBasePrice.noPrice
                    }}
                </div>
            </div>
        </section>
        <section *ngIf="configData">
            <mhp-order-configuration-form
                [orderFormConfig]="configData"
                [orderFormGroup]="orderFormGroup"
                (selectionChange)="onConfigurationChange($event)"
                [showPricingDNP]="
                    targetShowPricingDNP &&
                    (getAvailablePricingTypes$() | async).includes('DNP')
                "
                [showPricingRRP]="
                    targetShowPricingRRP &&
                    (getAvailablePricingTypes$() | async).includes('RRP')
                "
                [currency]="configData.currency"
            ></mhp-order-configuration-form>
            <div *ngIf="targetShowPricingDNP || targetShowPricingRRP">
                <div class="pricing"><mat-divider></mat-divider></div>
                <h3>{{ t('ORDER_MANAGEMENT.PRICING.HEADLINE') }}</h3>
                <mhp-section-title
                    [showDescription]="false"
                    [dataAvailable]="!!configData"
                    [showPricingDNP]="
                        targetShowPricingDNP &&
                        (getAvailablePricingTypes$() | async).includes('DNP')
                    "
                    [showPricingRRP]="
                        targetShowPricingRRP &&
                        (getAvailablePricingTypes$() | async).includes('RRP')
                    "
                ></mhp-section-title>
                <mat-divider></mat-divider>
                <div
                    *ngFor="
                        let priceDetail of configData.pricingSection;
                        let index = index
                    "
                >
                    <mhp-pricing-section
                        [price]="priceDetail"
                        [showPricingDNP]="
                            targetShowPricingDNP &&
                            (getAvailablePricingTypes$() | async).includes(
                                'DNP'
                            )
                        "
                        [showPricingRRP]="
                            targetShowPricingRRP &&
                            (getAvailablePricingTypes$() | async).includes(
                                'RRP'
                            )
                        "
                        [currency]="configData.currency"
                    ></mhp-pricing-section>
                </div>
            </div>
        </section>
    </form>
</ng-container>
<ng-container *ngIf="isEditable$() | async">
    <footer *transloco="let t" class="footer">
        <button mat-button (click)="intentCancel()">
            {{ t('COMMON.CANCEL') }}
        </button>
        <button
            *ngIf="(isAmendable$() | async) === true"
            mat-flat-button
            class="mhp-ui-button"
            (click)="intentAmendExistingOrder()"
            [disabled]="!isOrderable || forceOrderDisabled"
        >
            <span>{{ t('ORDER_MANAGEMENT.DETAIL_VIEW.CTA_AMEND_ORDER') }}</span>
        </button>
        <button
            mat-flat-button
            class="mhp-ui-button"
            (click)="intentSubmitOrder()"
            [disabled]="!isOrderable || forceOrderDisabled"
        >
            <span>{{
                t('ORDER_MANAGEMENT.DETAIL_VIEW.CTA_SUBMIT_ORDER')
            }}</span>
        </button>
        <!-- In case it's a confidential AND internal dealer, allow to copy the order x times -->
        <button
            *mhpConfidentialDealerFeature="{
                requireInternal: true
            }"
            mat-flat-button
            class="mhp-ui-button"
            (click)="
                intentSubmitOrder({
                    askForCopyCount: true
                })
            "
            [disabled]="!isOrderable || forceOrderDisabled"
        >
            <span>{{
                t('ORDER_MANAGEMENT.DETAIL_VIEW.CTA_SUBMIT_ORDER_MULTIPLE')
            }}</span>
        </button>
    </footer>
</ng-container>

<div class="order-management__sidebar">
    <mhp-sidebar-panel
        [items]="getSidebarItems$() | async"
        (itemSelect)="intentSidebarItemSelect($event)"
    ></mhp-sidebar-panel>
</div>

<!-- While waiting for the dropdown data to load show a single, disabled option with a loading spinner -->
<ng-template #loadingData>
    <div class="order-management__dropdown-placeholder" *transloco="let t">
        <span>{{ t('ORDER_MANAGEMENT.DETAIL_VIEW.LOADING') }}</span>
        <mat-spinner class="spinner" diameter="20"></mat-spinner>
    </div>
</ng-template>
