<mhp-fullscreen-dialog [wrapInDialogBody]="true">
    <ng-container
        *transloco="let tSubmitDealer; read: 'SUMBIT_TO_DEALER.GENERAL'"
    >
        <div
            class="request-pdf-via-email__form"
            *transloco="let tSendEmail; read: 'SUMBIT_TO_DEALER.EMAIL'"
        >
            <div
                class="request-pdf-via-email__lane-left"
                *transloco="let tForm; read: 'FORM'"
            >
                <div
                    class="request-pdf-via-email__current-configuration-container--small"
                >
                    <ng-container
                        *ngTemplateOutlet="currentConfiguration"
                    ></ng-container>
                </div>

                <h2>{{ tSendEmail('HEADLINE') }}</h2>

                <mhp-request-pdf-form
                    formId="customer-request-pdf-via-mail"
                    [includePricing]="isDealerBuild"
                    [requiresConsent]="!isDealerBuild"
                ></mhp-request-pdf-form>
            </div>
            <div class="request-pdf-via-email__lane-right">
                <div
                    class="request-pdf-via-email__current-configuration-container--large"
                >
                    <ng-container
                        *ngTemplateOutlet="currentConfiguration"
                    ></ng-container>
                </div>

                <section class="request-pdf-via-email__next-steps">
                    <div class="request-pdf-via-email__actions">
                        <button
                            mat-flat-button
                            type="submit"
                            class="mhp-ui-button--block mhp-ui-button--full-width mhp-ui-button--highlight"
                            [attr.form]="'customer-request-pdf-via-mail'"
                            [disabled]="(canSubmit$() | async) === false"
                        >
                            <span>{{ tSendEmail('CTA_SUBMIT') }}</span>
                        </button>
                    </div>
                </section>
            </div>
        </div>

        <ng-template #currentConfiguration>
            <section class="request-pdf-via-email__current-configuration">
                <div
                    class="request-pdf-via-email__current-configuration-description"
                    *transloco="let t"
                >
                    <h3
                        [innerHTML]="
                            t('SUMMARY.CONFIGURATION_SUMMARY.HEADLINE', {
                                modelname: getModelLabel$() | async
                            })
                        "
                    ></h3>
                </div>
                <mhp-static-renderer
                    [renderActiveSessionData]="true"
                    [aspectRatio]="3 / 2"
                ></mhp-static-renderer>
            </section>
        </ng-template>
    </ng-container>
</mhp-fullscreen-dialog>
