<div
    [@menu]="getAnimationState$() | async"
    class="configuration-menu__container"
    cdkTrapFocus
    *transloco="let t"
>
    <mhp-ui-icon-button
        class="configuration-menu__action-close"
        (click)="intentClose()"
        svgIcon="mhp-ui:close"
        [matTooltip]="t('ICONS.CLOSE_ALT')"
        [label]="t('ICONS.CLOSE_ALT')"
    ></mhp-ui-icon-button>

    <mhp-ui-icon-button
        *ngIf="(isBackButtonDisplayedSubject | async) === true"
        class="configuration-menu__action-back"
        size="large"
        svgIcon="mhp-ui:arrow-right"
        [matTooltip]="t('ICONS.BACK_ALT')"
        [label]="t('ICONS.BACK_ALT')"
        (click)="intentGoBack()"
    ></mhp-ui-icon-button>
    <div
        [@menuContent]="getAnimationState$() | async"
        (@menuContent.done)="onMenuContentAnimationDone($event)"
        class="configuration-menu__menu-content"
        *transloco="let t"
    >
        <div class="configuration-menu__menu-toc">
            <mhp-menu-toc
                #menuTocElement
                [isFullScreenLayout]="isFullScreenLayout$() | async"
                [@.disabled]="isFullScreenLayout$() | async"
                (selectCategory)="intentSelectCategory($event)"
                (displayBackButton)="setBackButtonState(true)"
            >
                <ng-container mhp-menu-toc-extension-content>
                    <ng-container *ngIf="!alternativeMenuContentsTemplate">
                        <div
                            class="configuration-menu__settings"
                            *transloco="let tMenuDealer; read: 'MENU_DEALER'"
                        >
                            <div class="configuration-menu__settings-entries">
                                <!-- RESET CONFIGURATION -->
                                <button
                                    mat-button
                                    class="mhp-ui-button mhp-ui-button--left-align mhp-ui-button--no-drop-shadow"
                                    (click)="
                                        intentActionSelect(
                                            ACTION_RESET_CONFIGURATION.id
                                        )
                                    "
                                >
                                    <span>{{
                                        ACTION_RESET_CONFIGURATION.label
                                    }}</span>
                                </button>
                                <!-- STREAM TOGGLE -->
                                <div
                                    *ngIf="
                                        (isPublicStreamSettingVisible$()
                                            | async) === true
                                    "
                                    class="configuration-menu__settings-entry"
                                >
                                    <ng-container
                                        *ngTemplateOutlet="toggleStreamMode"
                                    ></ng-container>
                                </div>
                                <!-- PUBLIC TEST DRIVE -->
                                <div
                                    class="configuration-menu__settings-entry configuration-menu__settings-entry"
                                    *mhpDealerFeature="false"
                                >
                                    <button
                                        mat-button
                                        class="mhp-ui-button mhp-ui-button--left-align mhp-ui-button--no-drop-shadow"
                                        (click)="
                                            intentActionSelect(
                                                ACTION_REQUEST_TESTDRIVE.id
                                            )
                                        "
                                    >
                                        <span>{{
                                            ACTION_REQUEST_TESTDRIVE.label
                                        }}</span>
                                    </button>
                                </div>
                                <!-- DEALER VISIT ASTONMARTIN.COM -->
                                <button
                                    mat-button
                                    *mhpDealerFeature
                                    class="mhp-ui-button mhp-ui-button--left-align mhp-ui-button--no-drop-shadow"
                                    (click)="
                                        intentActionSelect(ACTION_AML_HOME.id)
                                    "
                                >
                                    <span>{{ ACTION_AML_HOME.label }}</span>
                                </button>
                                <!-- DEALER PRICING TYPE SELECT -->
                                <div
                                    class="configuration-menu__settings-entry"
                                    *mhpDealerFeature
                                >
                                    <div
                                        class="configuration-menu__pricing-type-select-container"
                                    >
                                        <div class="configuration-menu__select">
                                            <label for="pricing-type-select">{{
                                                tMenuDealer('PRICING')
                                            }}</label>
                                            <mat-form-field
                                                class="configuration-menu__pricing-type-select mhp-ui-form-field--no-bottom-spacing mhp-ui-form-field--no-placeholder"
                                            >
                                                <mat-select
                                                    id="pricing-type-select"
                                                    [value]="
                                                        getPricingType$()
                                                            | async
                                                    "
                                                    (selectionChange)="
                                                        intentChangePricingType(
                                                            $event.value
                                                        )
                                                    "
                                                >
                                                    <mat-option
                                                        *ngFor="
                                                            let pricingType of getAvailablePricingTypes$()
                                                                | async
                                                        "
                                                        [value]="pricingType"
                                                        >{{
                                                            tMenuDealer(
                                                                'PRICING_TYPE.' +
                                                                    pricingType
                                                            )
                                                        }}</mat-option
                                                    >
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <ng-container
                                            *ngIf="
                                                dealerInfoService.getActiveDealerInfo$()
                                                    | async as activeDealerInfo
                                            "
                                        >
                                            <div
                                                *ngIf="
                                                    !activeDealerInfo.isInternal &&
                                                    activeDealerInfo.countryISO2
                                                "
                                                class="configuration-menu__settings-entry-hint"
                                            >
                                                {{
                                                    tMenuDealer(
                                                        'PRICING_COUNTRY_HINT',
                                                        {
                                                            country: t(
                                                                'COMMON.REGION.' +
                                                                    activeDealerInfo.countryISO2
                                                            )
                                                        }
                                                    )
                                                }}
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container *mhpDealerFeature>
                                    <!-- DEALER LOGOUT -->
                                    <div
                                        class="configuration-menu__settings-entry configuration-menu__settings-entry--dealer-logout"
                                        *mhpDealerFeature
                                    >
                                        <button
                                            mat-button
                                            class="mhp-ui-button mhp-ui-button--left-align mhp-ui-button--no-drop-shadow"
                                            (click)="intentDealerLogout()"
                                        >
                                            <span>{{
                                                tMenuDealer('LOGOUT')
                                            }}</span>
                                        </button>
                                    </div>
                                    <div
                                        class="configuration-menu__settings-entry-hint configuration-menu__settings-entry-hint--login-info"
                                        [matTooltip]="uiDealerInfo()?.accountId"
                                    >
                                        {{ uiDealerInfo()?.email }}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="alternativeMenuContentsTemplate">
                        <ng-container
                            *ngTemplateOutlet="alternativeMenuContentsTemplate"
                        ></ng-container>
                    </ng-container>
                </ng-container>
            </mhp-menu-toc>
        </div>
    </div>

    <div class="configuration-menu__menu-cta">
        <!-- CONFIGURATION CODE-->
        <button
            mat-flat-button
            class="mhp-ui-button--full-width"
            (click)="intentActionSelect(ACTION_ONCFIGURATION_CODE.id)"
        >
            <span>{{ t('MENU_CUSTOMER.CTA_CONFIGURATION') }}</span>
        </button>
        <!-- DEALER ORDER MANAGEMENT -->
        <button
            mat-flat-button
            *mhpDealerFeature
            class="mhp-ui-button--full-width mhp-ui-button--fade"
            (click)="openOMOrderOverview()"
        >
            <span>{{ t('MENU_DEALER.ORDER_MANAGEMENT') }}</span>
        </button>
        <!-- PUBLIC ASTONMARTIN.COM -->
        <button
            mat-flat-button
            *mhpDealerFeature="false"
            class="mhp-ui-button--full-width mhp-ui-button--fade"
            (click)="intentActionSelect(ACTION_AML_HOME.id)"
        >
            <span>{{ ACTION_AML_HOME.label }}</span>
        </button>
        <!-- CHANGE REGION -->
        <button
            mat-button
            class="mhp-ui-button region-button mhp-ui-button--full-width mhp-ui-button--no-drop-shadow"
            (click)="intentActionSelect(ACTION_CHANGE_REGION.id)"
        >
            <div class="btn-sub-section">
                <mhp-ui-icon
                    [svgIcon]="ACTION_CHANGE_REGION.iconId"
                    size="medium"
                ></mhp-ui-icon>
                <span>{{ ACTION_CHANGE_REGION.label }}</span>
            </div>
        </button>
    </div>

    <ng-template #toggleStreamMode>
        <mat-slide-toggle
            *transloco="let t; read: 'MENU_CUSTOMER'"
            color="primary"
            labelPosition="before"
            [checked]="(isStreamTargetVisualizationMode$() | async) === true"
            [disabled]="(isStreamToggleDisabled$() | async) === true"
            (change)="intentToggleTargetVizualisationMode($event)"
            >{{ t('TOGGLE_3D_STREAM') }}</mat-slide-toggle
        >
    </ng-template>
</div>
