import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    UiButtonModule,
    UiIconsModule,
    UiLazyloadImageModule
} from '@mhp/ui-components';
import { GoogleTagManagerModule, I18nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../dialog/dialog.module';
import { ConfigurationSessionTrackingService } from './configuration-session-tracking.service';
import { RestoreLocalConfigurationSessionDialogComponent } from './restore-local-configuration-session-dialog/restore-local-configuration-session-dialog.component';

@NgModule({
    declarations: [RestoreLocalConfigurationSessionDialogComponent],
    imports: [
        CommonModule,
        DialogModule,
        I18nModule,
        UiButtonModule,
        GoogleTagManagerModule,
        UiIconsModule,
        UiLazyloadImageModule
    ]
})
export class ConfigurationSessionLocalPersistenceModule {
    constructor(
        // EAGER INIT
        private readonly configurationSessionTrackingService: ConfigurationSessionTrackingService
    ) {}
}
