<div *ngFor="let formGroup of orderFormConfig.formGroups">
    <ng-container *transloco="let t">
        <h3 *ngIf="formGroup.label">{{ t(formGroup.label) }}</h3>
        <div *ngIf="formGroup.label">
            <mhp-section-title
                [showDescription]="true"
                [dataAvailable]="true"
                [showPricingDNP]="showPricingDNP"
                [showPricingRRP]="showPricingRRP"
            ></mhp-section-title>
            <mat-divider class="order-management__divider-edit"></mat-divider>
        </div>
    </ng-container>

    <div *ngIf="formGroup.formOption">
        <mhp-order-configuration-form-field
            [formOption]="formGroup.formOption"
            [orderFormGroup]="orderFormGroup"
            [optionNameToFocus]="orderFormConfig.optionNameToFocus"
            [showPricingDNP]="showPricingDNP"
            [showPricingRRP]="showPricingRRP"
            (selectionChange)="onSelectionChange($event)"
            [currency]="currency"
        ></mhp-order-configuration-form-field>
    </div>
</div>
