<div
    *ngFor="let item of items; trackBy: trackById"
    class="sidebar-panel__nav-entry"
>
    <button
        class="sidebar-panel__nav-entry-link"
        mat-button
        [disabled]="item.disabled"
        (click)="intentSelectItem(item)"
    >
        <mhp-ui-icon
            [svgIcon]="item.iconId || item.id"
            [matTooltip]="item.label"
            [matTooltipDisabled]="isTooltipDisabled$() | async"
            size="medium"
        ></mhp-ui-icon>
        <span>{{ item.label }}</span>
    </button>
</div>
