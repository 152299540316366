import { isNil, isNumber, isString } from 'lodash-es';

import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import {
    NumberFormatOptions,
    TranslocoCurrencyPipe,
    TranslocoLocaleService
} from '@jsverse/transloco-locale';
import { EffectivePricingAware } from '@mhp/aml-ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * Price formatting pipe taking the currency-symbol in EffectivePricingAware#pricing into account
 * instead of relying on the "default" currency valid for the active locale.
 */
@UntilDestroy()
@Pipe({
    name: 'effectivePricingAwareFormat',
    pure: false
})
export class EffectivePricingAwareFormatPipe
    extends TranslocoCurrencyPipe
    implements PipeTransform
{
    constructor(
        private readonly translocoLocaleService: TranslocoLocaleService,
        protected override readonly cdr: ChangeDetectorRef
    ) {
        super();
        this.translocoLocaleService.localeChanges$
            .pipe(untilDestroyed(this))
            .subscribe(() => cdr.markForCheck());
    }

    /**
     * To mimic underlying transloco defaults, default values have been chosen
     * accordingly.
     * See https://github.com/ngneat/transloco/blob/master/libs/transloco-locale/src/lib/pipes/transloco-currency.pipe.ts for reference.
     * @param value
     * @param display
     * @param numberFormatOptions
     */
    override transform(
        value: EffectivePricingAware | string | number | undefined,
        display: 'code' | 'symbol' | 'name' = 'symbol',
        numberFormatOptions: NumberFormatOptions = {}
    ): string {
        if (isString(value) || isNumber(value)) {
            return super.transform(value, display, numberFormatOptions);
        }

        const effectivePrice = value?.pricing?.price;
        const effectiveCurrency = value?.pricing?.currency;

        if (isNil(effectivePrice)) {
            return '';
        }
        if (!effectiveCurrency) {
            // no currency defined, use default transform
            return super.transform(
                effectivePrice,
                display,
                numberFormatOptions
            );
        }
        return super.transform(
            effectivePrice,
            display,
            numberFormatOptions,
            effectiveCurrency
        );
    }
}
