import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    UiButtonModule,
    UiCheckboxModule,
    UiInputModule,
    UiSelectModule
} from '@mhp/ui-components';
import {
    GoogleTagManagerModule,
    I18nModule,
    MarkdownModule
} from '@mhp/ui-shared-services';

import { RequestPdfFormComponent } from './request-pdf-form/request-pdf-form.component';
import { SalesforceFormComponent } from './salesforce-form/salesforce-form.component';

@NgModule({
    declarations: [SalesforceFormComponent, RequestPdfFormComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        UiSelectModule,
        I18nModule,
        UiInputModule,
        UiCheckboxModule,
        MarkdownModule,
        GoogleTagManagerModule,
        UiButtonModule
    ],
    exports: [
        SalesforceFormComponent,
        RequestPdfFormComponent,
        ReactiveFormsModule
    ]
})
export class SalesforceModule {}
