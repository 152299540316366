import { Observable, map, of, switchMap, take, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { ApplicationNavigationArea } from '@mhp/aml-ui-shared-services';
import { lazyShareReplay } from '@mhp/common';
import { UiMatDialogService } from '@mhp/ui-components';
import { ApplicationStateService, gtmGA4Track } from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../../environments/environment';
import { NavigationStateService } from '../../../navigation/navigation-state.service';
import { LocalApplicationState } from '../../../state';
import {
    ExtendedUiOptionCode,
    ExtendedUiOptionGroup
} from '../../configuration-model/configuration-interfaces';
import { isExtendedUiOptionCode } from '../../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../services/product-configuration-session.service';
import { setEditionsInfoShown } from '../../state';
import { EditionsDialogComponent } from './editions-dialog/editions-dialog.component';

const CATEGORY_EDITIONS =
    environment.appConfig.configuration.identifierRootLevelEditions;

interface ShowEditionsOptions {
    // if true, the dialog will be forced to be shown even if the user has already seen it during the current configuration session.
    forceShowDialog?: boolean;
}

/**
 * Service to handle Editions-related logic such as
 * - show the editions-info-dialog to the user
 * - determine whether an edition-selection is active or not
 */
@UntilDestroy()
@Injectable()
export class EditionsService {
    constructor(
        private readonly dialogService: UiMatDialogService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly navigationStateService: NavigationStateService
    ) {
        this.initResetEditionsShownStateLogic();
    }

    /**
     * Show the editions-info-dialog to the user.
     * @param targetEditionOption The edition-option to show the info for.
     *                            Providing this parameter implies that a selection
     *                            of the given node should take place in case the
     *                            user agrees to.
     * @param options Optional Additional options to be control behavior.
     */
    intentShowEditionsInfo$(
        targetEditionOption?: ExtendedUiOptionCode,
        options: ShowEditionsOptions = {
            forceShowDialog: false
        }
    ) {
        return this.applicationStateService.getLocalState().pipe(
            map((state) => !!state.configuration?.editionsInfoShown),
            take(1),
            switchMap((editionsInfoShow) => {
                if (editionsInfoShow && !options.forceShowDialog) {
                    return of(true);
                }
                return this.dialogService
                    .open$(EditionsDialogComponent, {
                        backdropClass: 'mhp-ui-modal-backdrop--blurry',
                        width: '500px'
                    })
                    .pipe(
                        tap(() => gtmGA4Track('editions_open_info_dialog')),
                        tap((dialogRef) => {
                            if (targetEditionOption) {
                                dialogRef.componentInstance.editionLabel =
                                    targetEditionOption.nameTranslated;
                            }
                        }),
                        // update the edition-info-shown state
                        tap(() =>
                            this.applicationStateService.dispatch(
                                setEditionsInfoShown({
                                    state: true
                                })
                            )
                        ),
                        switchMap((dialogRef) => dialogRef.afterClosed())
                    );
            }),
            switchMap((result) => {
                if (result && targetEditionOption) {
                    // user confirmed the option choice
                    return this.productConfigurationSessionService.updateNodeSelection(
                        targetEditionOption.id
                    );
                }
                return of(undefined);
            }),
            untilDestroyed(this)
        );
    }

    /**
     * Determine whether the given ID resolves to an edition-node.
     * @param id The ID the check
     * @param optionGroups The OptionGroups to search in.
     */
    isEditionNode(id: string, optionGroups: ExtendedUiOptionGroup[]) {
        return !!this.getEditionNode(id, optionGroups);
    }

    /**
     * Based on a given ID, tries to find an edition node.
     * @param id The ID to search for.
     * @param optionGroups The OptionGroups to search in.
     */
    getEditionNode(id: string, optionGroups: ExtendedUiOptionGroup[]) {
        return this.findEditionsNode(optionGroups, (node) => node.id === id);
    }

    /**
     * Based on a given ID, tries to find an edition node that is not the "No Pack" edition.
     * @param id The ID to search for.
     * @param optionGroups The OptionGroups to search in.
     */
    getEditionNodeOtherThanNoPack(
        id: string,
        optionGroups: ExtendedUiOptionGroup[]
    ) {
        return this.findEditionsNode(
            optionGroups,
            (node) => node.id === id && node.name !== 'No Pack'
        );
    }

    /**
     * Emits the currently selected editions node (if any)
     */
    getSelectedEditionsNode$(): Observable<ExtendedUiOptionCode | undefined> {
        return this.productConfigurationSessionService.getOptionGroups$().pipe(
            map((groups) =>
                this.findEditionsNode(
                    groups,
                    (node) => node.selected && node.name !== 'No Pack'
                )
            ),
            lazyShareReplay()
        );
    }

    /**
     * Emit if an edition is currently selected or not.
     */
    isEditionActive$(): Observable<boolean> {
        return this.getSelectedEditionsNode$().pipe(map((node) => !!node));
    }

    private findEditionsNode(
        groups: ExtendedUiOptionGroup[] | undefined,
        finder: (editionsOptionCode: ExtendedUiOptionCode) => boolean
    ): ExtendedUiOptionCode | undefined {
        if (!groups) {
            return undefined;
        }
        return this.nodeLookupService.findNode<ExtendedUiOptionCode>(
            (node, parents) =>
                parents?.[0]?.name === CATEGORY_EDITIONS &&
                parents?.[1]?.name === CATEGORY_EDITIONS &&
                isExtendedUiOptionCode(node) &&
                finder(node),
            groups ?? []
        )?.node;
    }

    /**
     * Once leaving the configuration-area, clear the editions-info-shown state.
     * @private
     */
    private initResetEditionsShownStateLogic() {
        this.navigationStateService
            .getActiveApplicationNavigationArea$()
            .pipe(untilDestroyed(this))
            .subscribe((navigationArea) => {
                if (
                    navigationArea !== ApplicationNavigationArea.CONFIGURATION
                ) {
                    this.applicationStateService.dispatch(
                        setEditionsInfoShown({
                            state: false
                        })
                    );
                }
            });
    }
}
