import {
    BehaviorSubject,
    EMPTY,
    Observable,
    combineLatest,
    of,
    throwError
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    first,
    map,
    startWith,
    switchMap,
    take,
    tap
} from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { translate } from '@jsverse/transloco';
import {
    AmlUiSharedState,
    One2OneSessionState,
    One2oneSessionBookingService,
    PricingData,
    PricingMap,
    SalesforceService
} from '@mhp/aml-ui-shared-services';
import { IllegalStateError, MemoizeObservable } from '@mhp/common';
import { UiBaseComponent, UiNotificationService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    CommonDialogsService,
    ErrorHandlerService,
    L10nService,
    UiSharedStateService,
    UrlShortenerService
} from '@mhp/ui-shared-services';

import { AmlBreakpoints } from '../../../../common/breakpoints/AmlBreakpoints';
import { DisplayedOptionsService } from '../../../../configuration/configuration-area/helpers/displayed-options.service';
import {
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection
} from '../../../../configuration/configuration-model/configuration-interfaces';
import { SalesforceContextService } from '../../../../configuration/salesforce/salesforce-context.service';
import {
    isExtendedUiOptionCode,
    isExtendedUiOptionCollection
} from '../../../../configuration/services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../../../configuration/services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../../../configuration/services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../../../../configuration/session-info/configuration-session-info.service';
import { selectStageMinimizedState } from '../../../../configuration/state/selectors/configuration.selectors';
import { AmlProductDataService } from '../../../../product-data/aml-product-data-service';
import { LocalApplicationState } from '../../../../state/local-application-state.interface';
import { PricingService } from '../../../pricing/pricing.service';
import { selectOne2OneState } from '../../../state/selectors/dealer-state.selectors';
import { setTargetOne2OneSessionData } from '../../state/actions/one2one-state.actions';

@Component({
    selector: 'mhp-session-control',
    templateUrl: './session-control.component.html',
    styleUrls: ['./session-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionControlComponent extends UiBaseComponent {
    private readonly clientPricingSyncSubject = new BehaviorSubject<boolean>(
        false
    );

    constructor(
        private readonly sessionBookingService: One2oneSessionBookingService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly configurationNodeLookupService: ConfigurationNodeLookupService,
        private readonly uiSharedStateService: UiSharedStateService<AmlUiSharedState>,
        private readonly applicationStateService: ApplicationStateService<
            LocalApplicationState,
            AmlUiSharedState
        >,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly notificationService: UiNotificationService,
        private readonly commonDialogsService: CommonDialogsService,
        private readonly pricingService: PricingService,
        private readonly productDataService: AmlProductDataService,
        private readonly salesforceService: SalesforceService,
        private readonly salesforceContextService: SalesforceContextService,
        public readonly l10nService: L10nService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly urlShortenerService: UrlShortenerService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly displayedOptionsService: DisplayedOptionsService,
        private readonly breakpointObserver: BreakpointObserver
    ) {
        super();

        this.initInitialActivePricingSyncState();
        this.initPricingSyncLogic();

        this.completeOnDestroy(this.clientPricingSyncSubject);
    }

    intentStopSession() {
        this.commonDialogsService
            .openConfirmDialog$(
                translate('ONE_2_ONE.ACTIVE_SESSION.DIALOG_STOP.HEADLINE'),
                translate('ONE_2_ONE.ACTIVE_SESSION.DIALOG_STOP.DESCRIPTION'),
                undefined,
                {
                    showCancel: true
                }
            )
            .pipe(
                this.takeUntilDestroy(),
                switchMap((dialogResult) => {
                    if (dialogResult.result !== 'OK') {
                        return EMPTY;
                    }
                    return combineLatest([
                        this.getActiveReservationId$(),
                        this.salesforceContextService.getSalesforceContextData$(
                            {
                                includeDealerConfigurationUrl: true
                            }
                        ),
                        this.l10nService.getActiveCountry$(),
                        this.getActiveExteriorColor$()
                    ]).pipe(
                        first(),
                        tap(() =>
                            this.notificationService.showNotification(
                                translate(
                                    'ONE_2_ONE.ACTIVE_SESSION.DIALOG_STOP.NOTIFICATION_STOPPING'
                                )
                            )
                        ),
                        switchMap(
                            ([
                                activeReservationId,
                                salesforceContextData,
                                activeCountry,
                                activeExteriorColor
                            ]) => {
                                if (
                                    !salesforceContextData.salesforceTransactionKey
                                ) {
                                    throw new IllegalStateError(
                                        'Missing leadId'
                                    );
                                }
                                if (!activeCountry) {
                                    throw new IllegalStateError(
                                        'Missing active country'
                                    );
                                }
                                if (
                                    !salesforceContextData.dealerConfigurationLink
                                ) {
                                    throw new IllegalStateError(
                                        'Missing dealerConfigurationLink'
                                    );
                                }
                                if (
                                    !salesforceContextData.configurationEncoded
                                ) {
                                    throw new IllegalStateError(
                                        'Missing configurationEncoded'
                                    );
                                }
                                return this.salesforceService
                                    .completeOne2OneSession$({
                                        transactionKey:
                                            salesforceContextData.salesforceTransactionKey,
                                        countryIsoCode: activeCountry,
                                        languagePreference: <any>(
                                            salesforceContextData.languagePreference
                                        ),
                                        configurationLink:
                                            salesforceContextData.dealerConfigurationLink,
                                        configurationEncoded:
                                            salesforceContextData.configurationEncoded,
                                        derivate:
                                            salesforceContextData.productId,
                                        exteriorColor:
                                            activeExteriorColor?.name,
                                        transmission:
                                            salesforceContextData.transmission
                                    })
                                    .pipe(
                                        this.errorHandlerService.applyRetry({
                                            messageProviderOnFinalError: (
                                                error
                                            ) =>
                                                translate(
                                                    'ONE_2_ONE.ERRORS.FAILED_SUBMITTING_DATA_DEALER'
                                                )
                                        }),
                                        map(() => activeReservationId)
                                    );
                            }
                        ),
                        switchMap((reservationId) =>
                            this.updateOne2OneSessionStateToClosed$().pipe(
                                map(() => reservationId)
                            )
                        ),
                        switchMap((reservationId) => {
                            if (!reservationId) {
                                return of(undefined);
                            }
                            return this.sessionBookingService
                                .deleteSession$(reservationId)
                                .pipe(
                                    catchError((error) => {
                                        // FIXME: this should be reported as a ReservationNotFoundError instead
                                        if (
                                            error.message ===
                                            'error getting reservation'
                                        ) {
                                            return of(reservationId);
                                        }
                                        return throwError(error);
                                    }),
                                    this.errorHandlerService.applyRetryWithHintsOnError(
                                        (error) =>
                                            translate(
                                                'ONE_2_ONE.ERRORS.FAILED_STOPPING_SESSION'
                                            )
                                    )
                                );
                        }),
                        // reset the session-state to unknown before quitting the session
                        switchMap(() =>
                            this.uiSharedStateService
                                .updateUiState((uiState) => ({
                                    ...uiState,
                                    sessionState: One2OneSessionState.UNKNOWN
                                }))
                                .pipe(
                                    this.errorHandlerService.applyRetry(),
                                    catchError((error) => {
                                        console.warn(
                                            'Failed applying local ui-state to remote',
                                            error
                                        );
                                        return of(undefined);
                                    })
                                )
                        ),
                        tap(() => {
                            // end one2one session
                            this.applicationStateService.dispatch(
                                setTargetOne2OneSessionData({
                                    sessionData: undefined
                                })
                            );
                        }),
                        tap(() =>
                            this.notificationService.showAutohideNotification(
                                translate(
                                    'ONE_2_ONE.ACTIVE_SESSION.DIALOG_STOP.NOTIFICATION_STOPPED'
                                )
                            )
                        )
                    );
                }),
                take(1)
            )
            .subscribe();
    }

    intentToggleClientPricing() {
        this.clientPricingSyncSubject.next(
            !this.clientPricingSyncSubject.value
        );
    }

    @MemoizeObservable()
    getActiveSessionDescription$(): Observable<string> {
        return this.getActiveReservationId$().pipe(
            switchMap((reservationId) => {
                if (!reservationId) {
                    return EMPTY;
                }
                return this.sessionBookingService
                    .getSession$(reservationId)
                    .pipe(
                        this.errorHandlerService.applyRetryWithHintsOnError(
                            (error) =>
                                translate<string>(
                                    'ONE_2_ONE.ERRORS.FAILED_LOADING_RESERVATION_INFO'
                                )
                        ),
                        map((reservationData) => reservationData.description),
                        catchError(() => {
                            const fallback = translate<string>(
                                'ONE_2_ONE.ACTIVE_SESSION.NO_SESSION_INFO'
                            );
                            return of(fallback);
                        })
                    );
            }),
            startWith(
                translate<string>(
                    'ONE_2_ONE.ACTIVE_SESSION.FETCHING_SESSION_INFO'
                )
            )
        );
    }

    @MemoizeObservable()
    isStageMinimized$(): Observable<boolean> {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectStageMinimizedState);
    }

    @MemoizeObservable()
    isPricingMirrored$(): Observable<boolean> {
        return this.applicationStateService
            .getEngineState()
            .pipe(map((state) => !!state.uiState?.pricingData));
    }

    @MemoizeObservable()
    canTogglePricing$(): Observable<boolean> {
        return this.pricingService.isRRPAvailableForDealer$();
    }

    @MemoizeObservable()
    doRenderVideoElements$(): Observable<boolean> {
        return this.breakpointObserver
            .observe(AmlBreakpoints.HandsetPortrait)
            .pipe(map((state) => !state.matches));
    }

    private getActiveReservationId$() {
        return this.applicationStateService.getLocalState().pipe(
            selectOne2OneState,
            map((state) => state?.targetSessionData?.id),
            distinctUntilChanged()
        );
    }

    private initInitialActivePricingSyncState() {
        this.applicationStateService
            .getEngineState()
            .pipe(
                first(),
                map((state) => !!state.uiState?.pricingData)
            )
            .subscribe((isSynced) =>
                this.clientPricingSyncSubject.next(isSynced)
            );
    }

    private initPricingSyncLogic() {
        combineLatest([
            this.clientPricingSyncSubject,
            this.pricingService.isRRPAvailableForDealer$(),
            this.productDataService.getActiveProductInfo$()
        ])
            .pipe(
                distinctUntilChanged(),
                this.takeUntilDestroy(),
                switchMap(([shouldSync, isRRPAvailable, activeProductInfo]) => {
                    if (!shouldSync || !isRRPAvailable || !activeProductInfo) {
                        return of(undefined);
                    }

                    return this.productConfigurationSessionService
                        .getOptionGroups$()
                        .pipe(
                            this.takeUntilDestroy(),
                            map((optionGroups): PricingData | undefined => {
                                if (!optionGroups) {
                                    return undefined;
                                }
                                const optionPrices =
                                    this.configurationNodeLookupService
                                        .collectNodes(
                                            (
                                                node
                                            ): node is ExtendedUiOptionCode =>
                                                isExtendedUiOptionCode(node),
                                            optionGroups
                                        )
                                        .reduce(
                                            (
                                                pricingMap: PricingMap,
                                                currentNode: ExtendedUiOptionCode
                                            ) => {
                                                const retailPrice =
                                                    currentNode.pricing
                                                        ?.retailPrice;
                                                if (
                                                    currentNode.pricing &&
                                                    retailPrice
                                                ) {
                                                    pricingMap[currentNode.id] =
                                                        {
                                                            currency:
                                                                currentNode
                                                                    .pricing
                                                                    .currency,
                                                            price: retailPrice
                                                        };
                                                }
                                                return pricingMap;
                                            },
                                            {}
                                        );

                                return {
                                    pricingType: 'RRP',
                                    baseModelPrice: activeProductInfo.pricing
                                        ? {
                                              price:
                                                  activeProductInfo.pricing
                                                      ?.retailPrice || 0,
                                              currency:
                                                  activeProductInfo.pricing
                                                      .currency
                                          }
                                        : undefined,
                                    optionPrices
                                };
                            })
                        );
                }),
                switchMap((pricingData) =>
                    this.uiSharedStateService
                        .updateUiState((uiState) => {
                            uiState.pricingData = pricingData;
                            return uiState;
                        })
                        .pipe(
                            this.errorHandlerService.applyRetryWithHintsOnError(
                                () =>
                                    translate(
                                        'CONFIGURATOR.ERRORS.BROADCAST_SHARED_STATE'
                                    ),
                                {
                                    isEligibleForRetry: () => true
                                }
                            ),
                            catchError(() => EMPTY)
                        )
                )
            )
            .subscribe();
    }

    private getActiveExteriorColor$(): Observable<
        ExtendedUiOptionCode | undefined
    > {
        return this.productConfigurationSessionService.getOptionGroups$().pipe(
            map((optionGroups) => {
                if (!optionGroups) {
                    return undefined;
                }

                const branchId = this.nodeLookupService.findNode(
                    (node): node is ExtendedUiOptionCollection =>
                        isExtendedUiOptionCollection(node) &&
                        node.name === 'PAINT',
                    optionGroups
                )?.node.id;
                if (!branchId) {
                    return undefined;
                }

                return this.displayedOptionsService
                    .getOptionsContainedInBranch(branchId, optionGroups)
                    ?.find((option) => option.selected);
            })
        );
    }

    private updateOne2OneSessionStateToClosed$() {
        return this.uiSharedStateService
            .updateUiState((uiState) => ({
                ...uiState,
                sessionState: One2OneSessionState.CLOSED
            }))
            .pipe(
                this.errorHandlerService.applyRetry({
                    maxRetries: 3
                }),
                catchError((error) => {
                    console.warn(
                        'Failed applying local ui-state to remote',
                        error
                    );
                    return of(undefined);
                })
            );
    }
}
