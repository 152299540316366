<ng-container>
    <div *ngIf="list.nameTranslated" class="configuration-list__title">
        {{ list.nameTranslated }}
    </div>
    <div class="configuration-list__content">
        <ng-container *ngIf="getOptions$() | async as options">
            <mhp-configuration-options-list
                [options]="options"
                [useOverflowIndicator]="false"
                [optionSelectionDisabled]="optionSelectionDisabled()"
                (optionSelected)="intentSelectItem($event)"
            ></mhp-configuration-options-list>
        </ng-container>
    </div>
</ng-container>
