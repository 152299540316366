<div class="order-management__table-entry">
    <div class="order-management__table-pricing-title-left">
        {{ price.label }}
    </div>
    <div *ngIf="showPricingDNP" class="order-management__table-pricing-right">
        {{ price.dnp ?? 0 | translocoCurrency: undefined:undefined:currency }}
    </div>
    <div *ngIf="showPricingRRP" class="order-management__table-pricing-right">
        {{ price.rrp ?? 0 | translocoCurrency: undefined:undefined:currency }}
    </div>
</div>
