import { isString, last } from 'lodash-es';
import { Observable, combineLatest, firstValueFrom, of, switchMap } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { EngineSessionData } from '@mhp/aml-ui-shared-services';
import { lazyShareReplay } from '@mhp/common';
import { ImageSrcset, UiBaseComponent } from '@mhp/ui-components';
import { ScreenshotSource } from '@mhp/ui-shared-services';

import { ScreenshotProvider } from '../screenshot/screenshot-registry.service';
import {
    IodRenderingAdjustments,
    IodSrcsetRenderingOptions,
    StaticRendererService
} from './static-renderer.service';

@Component({
    selector: 'mhp-static-renderer',
    templateUrl: './static-renderer.component.html',
    styleUrls: ['./static-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StaticRendererComponent
    extends UiBaseComponent
    implements ScreenshotProvider, OnInit
{
    @Input()
    renderActiveSessionData?: boolean;

    @Input()
    rendererInput?: EngineSessionData;

    @Input()
    aspectRatio = 0;

    @Input()
    renderingAdjustments?: IodRenderingAdjustments;

    @Input()
    iodRenderingOptions?: IodSrcsetRenderingOptions;

    @Input()
    imageFit: 'cover' | 'contain' = 'cover';

    rendererImageSrc$: Observable<string | ImageSrcset | undefined>;

    constructor(private readonly basicRendererService: StaticRendererService) {
        super();
    }

    ngOnInit() {
        let rendererImageSrc$: Observable<string | ImageSrcset | undefined>;

        const renderingAdjustments$ = this.observeProperty<
            StaticRendererComponent,
            IodRenderingAdjustments | undefined
        >('renderingAdjustments', true);

        const iodRenderingOptions$ = this.observeProperty<
            StaticRendererComponent,
            IodSrcsetRenderingOptions | undefined
        >('iodRenderingOptions', true);

        if (!this.renderActiveSessionData) {
            rendererImageSrc$ = combineLatest([
                this.observeProperty<
                    StaticRendererComponent,
                    EngineSessionData | undefined
                >('rendererInput'),
                renderingAdjustments$,
                iodRenderingOptions$
            ]).pipe(
                switchMap(
                    ([
                        rendererInput,
                        renderingAdjustments,
                        iodRenderingOptions
                    ]) => {
                        if (!rendererInput) {
                            return of(undefined);
                        }
                        return this.basicRendererService.getRenderingSrcset$(
                            rendererInput,
                            iodRenderingOptions,
                            this.renderingAdjustments
                        );
                    }
                )
            );
        } else {
            rendererImageSrc$ = combineLatest([
                renderingAdjustments$,
                iodRenderingOptions$
            ]).pipe(
                switchMap(([renderingAdjustments, iodRenderingOptions]) =>
                    this.basicRendererService.getActiveSessionRenderingSrcset$(
                        iodRenderingOptions,
                        renderingAdjustments
                    )
                )
            );
        }

        this.rendererImageSrc$ = rendererImageSrc$.pipe(
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    async getScreenshotSource(): Promise<ScreenshotSource | undefined> {
        const screenshotSource$ = this.rendererImageSrc$.pipe(
            take(1),
            map((imageSource): ScreenshotSource | undefined => {
                if (!imageSource) {
                    return undefined;
                }

                const screenshotImage = isString(imageSource)
                    ? imageSource
                    : last(imageSource.sources)?.url;

                if (!screenshotImage) {
                    return undefined;
                }

                return {
                    source: screenshotImage,
                    mimeType: 'image/jpeg'
                };
            })
        );
        return firstValueFrom(screenshotSource$);
    }
}
