import { range, values } from 'lodash-es';
import { take } from 'rxjs';

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { translate } from '@jsverse/transloco';
import { sfEvent } from '@mhp/aml-ui-shared-services/salesforce-tracking';
import { Memoize } from '@mhp/common';
import { UiNotificationService } from '@mhp/ui-components';
import { gtmGA4Track } from '@mhp/ui-shared-services';

import { environment } from '../../../../environments/environment';
import {
    SALESFORCE_EVENT_CONFIGURATOR_CODE,
    SALESFORCE_EVENT_CONFIGURATOR_GET_LINK_FROM_MODAL
} from '../../../salesforce-events/salesforce-events';
import { SalesforceContextService } from '../../salesforce/salesforce-context.service';
import { CODE_LENGTH } from '../load-code-dialog/load-code-dialog.component';

@Component({
    selector: 'mhp-show-code-dialog',
    templateUrl: './show-code-dialog.component.html',
    styleUrls: ['./show-code-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowCodeDialogComponent implements OnInit {
    @Input()
    code: string;

    @Input()
    shortUrl: string;

    readonly isDealerBuild = environment.appConfig.dealer.dealerBuild;

    readonly codeFormGroup: UntypedFormGroup;

    private static readonly fieldNameFactory = (index) => `codePos_${index}`;

    readonly trackByIndex = (index) => index;

    constructor(
        private readonly notificationService: UiNotificationService,
        private readonly salesforceContextService: SalesforceContextService
    ) {
        this.codeFormGroup = this.initCodeFormGroup();
    }

    ngOnInit() {
        this.initFormControls();
    }

    @Memoize()
    getFormControls() {
        return values(this.codeFormGroup.controls);
    }

    onCodeCopied(success: boolean) {
        if (success) {
            this.notificationService.showAutohideNotification(
                translate('AML_CODE.CREATE.DIALOG.COPY_CODE_SUCCESS')
            );
        } else {
            this.notificationService.showError(
                translate('AML_CODE.CREATE.ERROR.COPY_CODE')
            );
        }

        this.salesforceContextService
            .getSalesforceContextData$({
                includeDealerConfigurationUrl: true
            })
            .pipe(take(1))
            .subscribe((contextData) => {
                // SF Data Cloud: Configurator Get Code
                sfEvent({
                    interaction: {
                        ...SALESFORCE_EVENT_CONFIGURATOR_CODE,
                        modelCode: contextData.modelId ?? '',
                        variantCode: contextData.productId ?? '',
                        configUrl: contextData.dealerConfigurationLink ?? ''
                    }
                });
            });

        gtmGA4Track('copy_configuration_code_click');
    }

    onShortUrlCopied(success: boolean) {
        if (success) {
            this.notificationService.showAutohideNotification(
                translate('AML_CODE.CREATE.DIALOG.COPY_URL_SUCCESS')
            );
        } else {
            this.notificationService.showError(
                translate('AML_CODE.CREATE.ERROR.COPY_URL')
            );
        }

        this.salesforceContextService
            .getSalesforceContextData$({
                includeDealerConfigurationUrl: true
            })
            .pipe(take(1))
            .subscribe((contextData) => {
                // SF Data Cloud: Configurator Get Link
                sfEvent({
                    interaction: {
                        ...SALESFORCE_EVENT_CONFIGURATOR_GET_LINK_FROM_MODAL,
                        modelCode: contextData.modelId ?? '',
                        variantCode: contextData.productId ?? '',
                        configUrl: contextData.dealerConfigurationLink ?? ''
                    }
                });
            });

        gtmGA4Track('copy_configuration_link_click');
    }

    private initCodeFormGroup() {
        return new UntypedFormGroup(
            range(0, CODE_LENGTH).reduce((formGroup, index) => {
                formGroup[`codePos_${index}`] = new UntypedFormControl({
                    value: undefined,
                    disabled: true
                });
                return formGroup;
            }, {})
        );
    }

    private initFormControls() {
        this.code
            .split('')
            .forEach((codeChar, index) =>
                this.codeFormGroup.controls[
                    ShowCodeDialogComponent.fieldNameFactory(index)
                ].setValue(codeChar)
            );
    }
}
