import { Directive, ElementRef } from '@angular/core';

import { UiPreventPropagationBase } from '../ui-stop-click-propagation/ui-prevent-propagation-base';

/**
 * Directive to be applied to elements that have a ripple and are
 * nested inside an element that has a ripple effect itself.
 * Stops propagation of mousedown and touchstart events for the element
 * it is applied to.
 * @deprecated Since @angular/material@15 event handling for ripples has been changed to passive events so we're no longer able to prevent
 * their bubbling. As of now, no generic solution for this is in place. See https://github.com/angular/components/issues/26656
 */
@Directive({
    selector: '[mhpUiPreventNestedRipple]'
})
export class UiPreventNestedRippleDirective extends UiPreventPropagationBase {
    constructor(elementRef: ElementRef<Element>) {
        super(elementRef, ['mousedown', 'touchstart']);
    }
}
