<ng-container *transloco="let t; read: 'MENU_CUSTOMER'">
    <ul class="breadcrumb">
        <li
            *ngIf="!renderReadonly"
            class="breadcrumb__entry breadcrumb__entry--model-select"
            [class.breadcrumb__entry--no-text-shadow]="!useTextShadow"
        >
            <a
                [routerLink]="getModelSelectionRouterCommands()"
                [trackGA4Event]="{
                    event: 'change_model_click'
                }"
                (click)="onActionClick()"
                mat-button
                queryParamsHandling="merge"
                class="breadcrumb__action-model-select mhp-ui-button--compensate-padding mhp-ui-button--inherit-font-styles mhp-ui-button--no-drop-shadow"
                >{{ t('CTA_CHANGE_MODEL') }}</a
            >
        </li>
        <li
            class="breadcrumb__entry"
            [class.breadcrumb__entry--no-text-shadow]="!useTextShadow"
        >
            <a
                *ngIf="!renderReadonly; else derivateReadonly"
                [routerLink]="getDerivateSelectionRouterCommands$() | async"
                (click)="onActionClick()"
                mat-button
                queryParamsHandling="merge"
                class="breadcrumb__action-derivate-select mhp-ui-button--compensate-padding mhp-ui-button--inherit-font-styles mhp-ui-button--no-drop-shadow"
            >
                <span [innerHtml]="(getProductLabel$() | async) || ''"></span>
            </a>
            <ng-template #derivateReadonly>
                <span
                    class="breadcrumb__action-derivate-select"
                    [innerHtml]="(getProductLabel$() | async) || ''"
                >
                </span>
            </ng-template>
        </li>
        <li
            class="breadcrumb__entry breadcrumb__entry--pricing"
            [class.breadcrumb__entry--no-text-shadow]="!useTextShadow"
            *mhpIfShowConfigurationPricing
        >
            <button
                *ngIf="!renderReadonly; else summaryReadonly"
                mat-button
                class="breadcrumb__action-summary mhp-ui-button--compensate-padding mhp-ui-button--inherit-font-styles mhp-ui-button--no-drop-shadow"
                (click)="intentGoToSummary()"
            >
                <span class="breadcrumb__action-summary-total-price">{{
                    getTotalPrice$() | async | effectivePricingAwareFormat
                }}</span>
                <span
                    *ngIf="
                        getFinancialOfferShortInfo$()
                            | async as financialOfferShortInfo
                    "
                    class="breadcrumb__action-summary-fs-rate-price"
                    >&nbsp;({{ financialOfferShortInfo }})
                </span>
            </button>
            <ng-template #summaryReadonly>
                <span class="breadcrumb__action-summary">{{
                    getTotalPrice$() | async | effectivePricingAwareFormat
                }}</span>
            </ng-template>
        </li>
    </ul>
</ng-container>
