<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-dialog-body [noPadding]="true">
    <div class="fridge-label">
        <mhp-ui-lazyload-image
            class="fridge-label__image"
            imageFit="contain"
            [imageUrl]="data.fridgeLabelUrl"
            alt=""
        ></mhp-ui-lazyload-image>
    </div>
</mhp-dialog-body>
