<form
    class="request-pdf-form__form aml-form__form"
    [ngClass]="'aml-form__form--' + (getActiveLang$() | async)"
    [formGroup]="leadFormGroup"
    (ngSubmit)="intentSubmit()"
    [attr.id]="formId"
    *transloco="let tForm; read: 'FORM'"
>
    <section class="aml-form__form-block aml-form__form-block--personal-info">
        <mat-form-field class="mhp-ui-form-field--full-width">
            <mat-label>{{ tForm('TITLE_LABEL') }}</mat-label>
            <mat-select
                formControlName="title"
                [trackGA4Event]="{
                    event: 'form_values_enter',
                    title: '1'
                }"
                [trackBlur]="true"
            >
                <mat-option [value]="null"></mat-option>
                <mat-option
                    *ngFor="let title of availableTitles"
                    [value]="title.key"
                    >{{ title.value }}</mat-option
                >
            </mat-select>
        </mat-form-field>

        <div class="aml-form__field-group mhp-ui-form-field--full-width">
            <mat-form-field
                class="mhp-ui-form-field--full-width aml-form__form-field--aside"
            >
                <mat-label>{{ tForm('FIRSTNAME_LABEL') }}</mat-label>
                <input
                    matInput
                    formControlName="firstName"
                    type="text"
                    [trackGA4Event]="{
                        event: 'form_values_enter',
                        first_name: '1'
                    }"
                    [trackBlur]="true"
                />
            </mat-form-field>

            <mat-form-field
                class="mhp-ui-form-field--full-width aml-form__form-field--aside aml-form__form-field--lastname"
            >
                <mat-label>{{ tForm('LASTNAME_LABEL') }}</mat-label>
                <input
                    matInput
                    formControlName="lastName"
                    type="text"
                    [trackGA4Event]="{
                        event: 'form_values_enter',
                        last_name: '1'
                    }"
                    [trackBlur]="true"
                />
            </mat-form-field>
        </div>

        <mat-form-field
            class="mhp-ui-form-field--full-width aml-form__form-field--aside"
        >
            <mat-label>{{ tForm('EMAIL_LABEL') }}</mat-label>
            <input
                matInput
                formControlName="customerEmail"
                type="email"
                [trackGA4Event]="{
                    event: 'form_values_enter',
                    email: '1'
                }"
                [trackBlur]="true"
            />
        </mat-form-field>

        <mat-form-field
            class="mhp-ui-form-field--full-width aml-form__form-field--aside"
        >
            <mat-label>{{ tForm('PHONE_LABEL') }}</mat-label>
            <input
                matInput
                formControlName="customerPhone"
                type="tel"
                [trackGA4Event]="{
                    event: 'form_values_enter',
                    phone: '1'
                }"
                [trackBlur]="true"
            />
        </mat-form-field>
    </section>

    <section
        *ngIf="includePricing"
        class="aml-form__form-block aml-form__form-block--pricing"
    >
        <div class="aml-form__field-group mhp-ui-form-field--full-width">
            <mat-checkbox color="primary" formControlName="includePdfPricing">{{
                tForm('INCLUDE_PRICING_IN_PDF')
            }}</mat-checkbox>
        </div>
    </section>

    <section
        *ngIf="requiresConsent"
        class="aml-form__form-block request-pdf-form__consent-info"
    >
        <ng-container *transloco="let t">
            <div
                [innerHTML]="getGdprMarkdown$(1) | async | markdown | async"
            ></div>
            <div class="request-pdf-form__consent-info-block">
                <h3>{{ tForm('CONSENT_LABEL') }}</h3>

                <div
                    class="request-pdf-form__contact-options"
                    formGroupName="contactOptions"
                >
                    <mat-checkbox
                        color="primary"
                        formControlName="contactViaEmail"
                        [trackGA4Event]="{
                            event: 'contact_options_select',
                            optin_email: '1'
                        }"
                        >{{ tForm('EMAIL_LABEL_2') }}</mat-checkbox
                    >

                    <mat-checkbox
                        color="primary"
                        formControlName="contactViaTelephone"
                        [trackGA4Event]="{
                            event: 'contact_options_select',
                            optin_phone: '1'
                        }"
                        >{{ tForm('PHONE_LABEL_2') }}</mat-checkbox
                    >

                    <mat-checkbox
                        color="primary"
                        formControlName="contactViaSms"
                        [trackGA4Event]="{
                            event: 'contact_options_select',
                            optin_sms: '1'
                        }"
                        >{{ tForm('SMS_LABEL') }}</mat-checkbox
                    >

                    <mat-checkbox
                        color="primary"
                        formControlName="contactViaNewsletter"
                        [trackGA4Event]="{
                            event: 'contact_options_select',
                            optin_newsletter: '1'
                        }"
                        >{{ tForm('NEWSLETTER_LABEL') }}</mat-checkbox
                    >
                </div>
            </div>
            <div
                [innerHTML]="getGdprMarkdown$(2) | async | markdown | async"
            ></div>
        </ng-container>
    </section>

    <section>
        <div class="request-pdf-form__actions">
            <button
                mat-flat-button
                type="submit"
                class="mhp-ui-button--block mhp-ui-button--highlight"
                [disabled]="(canSubmit$() | async) === false"
            >
                <span>{{ tForm('EMAIL_LABEL') }}</span>
            </button>
        </div>
    </section>
</form>
