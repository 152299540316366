import { isEqual } from 'lodash-es';
import { MonoTypeOperatorFunction, Observable, from, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import {
    LocalAudioTrack,
    LocalVideoTrack,
    createLocalAudioTrack,
    createLocalVideoTrack
} from 'twilio-video';
import { CreateLocalTrackOptions } from 'twilio-video/tsdef/types';

import { Injectable } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { ApplicationStateService } from '@mhp/ui-shared-services';
import { UntilDestroy } from '@ngneat/until-destroy';

import { selectOne2OneState } from '../../dealer';
import { LocalApplicationState } from '../../state';
import { calculateSizeByAspectRatio } from '../video-chat.helper';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class LocalTracksService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>
    ) {}

    @MemoizeObservable()
    getLocalVideoTrack$(): Observable<LocalVideoTrack | undefined> {
        return this.applicationStateService.getLocalState().pipe(
            selectOne2OneState,
            map((state) => ({
                video: state.audioVideoSettings.video,
                videoDeviceId: state.audioVideoSettings.videoDeviceId
            })),
            distinctUntilChanged(isEqual),
            switchMap((videoSettings) => {
                if (!videoSettings.video || !videoSettings.videoDeviceId) {
                    return of(undefined);
                }
                const videoSize = calculateSizeByAspectRatio('width', 1280, {
                    aspectWidth: 16,
                    aspectHeight: 9
                });
                const videoOptions: CreateLocalTrackOptions = {
                    ...videoSize,
                    deviceId: videoSettings.videoDeviceId
                };
                return from(createLocalVideoTrack(videoOptions));
            }),
            this.stopPrevious<LocalVideoTrack>(),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getLocalAudioTrack$(): Observable<LocalAudioTrack | undefined> {
        return this.applicationStateService.getLocalState().pipe(
            selectOne2OneState,
            map((state) => ({
                audio: state.audioVideoSettings.audio,
                audioDeviceID: state.audioVideoSettings.audioDeviceId
            })),
            distinctUntilChanged(isEqual),
            switchMap((audioSettings) => {
                if (!audioSettings.audio || !audioSettings.audioDeviceID) {
                    return of(undefined);
                }
                const audioOptions = { deviceId: audioSettings.audioDeviceId };
                return from(createLocalAudioTrack(audioOptions));
            }),
            this.stopPrevious<LocalAudioTrack>(),
            lazyShareReplay()
        );
    }

    private stopPrevious<
        T extends LocalVideoTrack | LocalAudioTrack
    >(): MonoTypeOperatorFunction<T | undefined> {
        return (observable) =>
            observable.pipe(
                switchMap(
                    (track) =>
                        // stop last emitted track upon unsubscription of last subscriber
                        new Observable<T>((subscriber) => {
                            subscriber.next(track);

                            return () => {
                                track?.stop();
                            };
                        })
                )
            );
    }
}
