import { Observable, Subject } from 'rxjs';
import {
    filter,
    map,
    switchMap,
    take,
    takeUntil,
    timeout
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { TwilioTokenData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/session-data.js';
import { StreamInfo } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/stream-info.js';
import { environmentShared } from '@mhp/aml-ui-shared-services';
import { deepClone } from '@mhp/common';
import {
    ApplicationStateService,
    StreamSessionInfo,
    StreamSessionOptions,
    StreamStatus,
    StreamingService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { setActiveStreamSessionInfo } from '../state/actions/configuration.actions';

const DEFAULT_ADHOC_SESSION_SPECS = {
    [environmentShared.appConfig.stream.sessionUsageSessionClientKey]:
        environment.appConfig.stream.sessionUsageSessionClientValue,
    [environmentShared.appConfig.stream.sessionUsageSessionTypeKey]: 'adhoc'
};

/**
 * Handler to control the StreamingService.
 */
@Injectable()
export class StreamHandlerService {
    private readonly stopStreamSubject = new Subject<void>();

    constructor(
        private readonly streamingService: StreamingService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>
    ) {
        this.initUpdateStreamSessionInfoLogic();
    }

    getTwilioToken$(): Observable<TwilioTokenData | undefined> {
        return this.streamingService.getTwilioToken$();
    }

    /**
     * Begin streaming using the given options.
     * Note that only one stream may be active at a time, so trying to call this
     * method when a stream is already open will result in an error being emitted.
     * @param streamOptions The StreamSessionOptions to be used when connecting.
     */
    startStream$(streamOptions: StreamSessionOptions): Observable<StreamInfo> {
        return this.streamingService.getStreamStatus$().pipe(
            filter((status) => status.status === StreamStatus.DISCONNECTED),
            timeout(5000),
            take(1),
            switchMap(() => {
                let sessionSpecs = {
                    ...streamOptions?.sessionSpecs
                };

                if (!streamOptions.sessionCode) {
                    // only add the tracking-session-specs in case we're not jumping on an existing session
                    sessionSpecs = {
                        ...sessionSpecs,
                        // override the session-usage information using the value from the current environment-config
                        ...deepClone(DEFAULT_ADHOC_SESSION_SPECS)
                    };
                }

                return this.streamingService.startStream$({
                    ...streamOptions,
                    sessionSpecs
                });
            }),
            takeUntil(this.stopStreamSubject)
        );
    }

    /**
     * Stop streaming.
     */
    stopStream() {
        this.stopStreamSubject.next();
    }

    /**
     * Get a stream emitting the currently valid StreamInfo or undefined if
     * no StreamInfo is available.
     * This stream never completes and does not emit any errors. To get hold
     * of errors, see #getStreamErrors()
     */
    getStreamInfo$(): Observable<StreamInfo | undefined> {
        return this.streamingService
            .getStreamStatus$()
            .pipe(map((streamInfo) => streamInfo.streamInfo));
    }

    /**
     * Get a stream emitting errors that happen while streaming.
     * * @see StreamingService.getStreamErrors$
     */
    getStreamErrors$() {
        return this.streamingService.getStreamErrors$();
    }

    /**
     * Get the current status of the streaming service.
     * @see StreamingService.getStreamStatus$
     */
    getStreamStatus$() {
        return this.streamingService.getStreamStatus$();
    }

    /**
     * Check the quota for the streaming environment.
     */
    checkQuota$(): Observable<boolean> {
        return this.streamingService.checkQuota$({
            participants: 1,
            immediate: true,
            sessionSpecs: deepClone(DEFAULT_ADHOC_SESSION_SPECS)
        });
    }

    private initUpdateStreamSessionInfoLogic() {
        this.getStreamInfo$()
            .pipe(
                map((streamInfo): StreamSessionInfo | undefined => {
                    if (!streamInfo) {
                        return undefined;
                    }
                    return {
                        ip: streamInfo.ip,
                        session: streamInfo.session
                    };
                })
            )
            .subscribe((streamSessionInfo) => {
                this.applicationStateService.dispatch(
                    setActiveStreamSessionInfo({
                        streamSessionInfo
                    })
                );
            });
    }
}
