import { Observable, of, switchMap } from 'rxjs';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Optional,
    Output
} from '@angular/core';
import {
    SocialServiceModel,
    SocialSharingService
} from '@mhp/aml-ui-shared-services/social-sharing';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { L10nService } from '@mhp/ui-shared-services';

/**
 * Renders a line of social sharing buttons either directly provided as input
 * or a default-set based on the current region.
 * Takes into account special requirements for iOS / iPadOS:
 * These get rendered the default web-sharing icon only if WebSharing-API is additionally supported.
 */
@Component({
    selector: 'mhp-social-sharing-bar',
    templateUrl: './social-sharing-bar.component.html',
    styleUrls: ['./social-sharing-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialSharingBarComponent extends UiBaseComponent {
    @Input()
    disabled = false;

    @Input()
    socialServices?: SocialServiceModel[];

    @Output()
    readonly share = new EventEmitter<string>();

    constructor(
        private readonly l10nService: L10nService,
        @Optional() private readonly socialSharingService?: SocialSharingService
    ) {
        super();

        this.completeOnDestroy(this.share);
    }

    @MemoizeObservable()
    getSocialServices$(): Observable<Readonly<SocialServiceModel[]>> {
        return this.observeProperty<
            SocialSharingBarComponent,
            SocialServiceModel[] | undefined
        >('socialServices', true).pipe(
            switchMap((socialServices) => {
                if (socialServices) {
                    return of(socialServices);
                }
                if (!this.socialSharingService) {
                    return of([]);
                }
                return this.socialSharingService.getSupportedServices$(
                    this.l10nService.getActiveCountry$()
                );
            })
        );
    }

    intentShare(socialService: SocialServiceModel) {
        this.share.emit(socialService.id);
    }
}
