import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalModule,
    MsalService
} from '@azure/msal-angular';
import {
    IPublicClientApplication,
    PublicClientApplication
} from '@azure/msal-browser';

import { environment } from '../../environments/environment';
import { DealerAuthGuard } from './dealer-auth-guard';
import { DealerMsalInterceptor } from './interceptor/dealer-msal.interceptor';
import { MSAL_CONFIGURATION } from './msal/msal-config';

// see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-angular-v2-samples/angular11-b2c-sample/src/app/app.module.ts for reference

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(MSAL_CONFIGURATION);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    environment.appConfig.dealer.protectedResources.forEach((route) => {
        protectedResourceMap.set(
            route,
            // add the clientId here to get the accessToken populated instead of the idToken only. See https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2315#issuecomment-773407358 for reference.
            ['openid', environment.appConfig.dealer.clientId]
        );
    });

    return {
        interactionType: environment.appConfig.dealer.interactionType,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: environment.appConfig.dealer.interactionType,
        authRequest: {
            scopes: ['openid', environment.appConfig.dealer.clientId]
        }
    };
}

/**
 * This module imports and configures the underlying MSAL module for usage in AML context.
 */
@NgModule({
    declarations: [],
    imports: [CommonModule, MsalModule],
    exports: [MsalModule],
    providers: [
        DealerAuthGuard,
        DealerMsalInterceptor,

        // MSAL providers
        MsalService,
        MsalBroadcastService,
        MsalGuard,
        MsalInterceptor,
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: DealerMsalInterceptor,
            multi: true
        }
    ]
})
export class DealerAuthModule {}
