<mhp-overflow-indicator
    *ngIf="useOverflowIndicator"
    [prevTemplate]="prevItems"
    [nextTemplate]="nextItems"
    [scrollableElementRef]="configurationOptionsScrollable"
    [pageWidthCorrection]="80"
></mhp-overflow-indicator>
<ng-template #prevItems>
    <button
        class="configuration-options-list__overflow-indicator configuration-options-list__overflow-indicator--left"
        aria-label="Show previous options"
        matRipple
    >
        <mhp-ui-icon svgIcon="mhp-ui:angle-left"></mhp-ui-icon>
    </button>
</ng-template>
<ng-template #nextItems>
    <button
        class="configuration-options-list__overflow-indicator configuration-options-list__overflow-indicator--right"
        aria-label="Show next options"
        matRipple
    >
        <mhp-ui-icon svgIcon="mhp-ui:angle-right"></mhp-ui-icon>
    </button>
</ng-template>

<div
    class="configuration-options-list__available-options"
    cdkScrollable
    #configurationOptionsScrollable
>
    <mhp-configuration-code
        #configurationCode
        *ngFor="let optionCode of options; trackBy: trackById"
        [data]="optionCode"
        [readonly]="readonly"
        [disabled]="optionSelectionDisabled()"
        (selected)="onOptionCodeSelected($event)"
        (highlighted)="onOptionCodeHighlighted(optionCode, $event)"
        (infoRequested)="onInfoRequested(optionCode)"
    ></mhp-configuration-code>
</div>
