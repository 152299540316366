import { EMPTY, Observable, race } from 'rxjs';
import { catchError, delay, map, switchMap, take, tap } from 'rxjs/operators';

import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    ComponentFactoryResolver,
    Injectable,
    ViewContainerRef
} from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { IllegalStateError } from '@mhp/common';
import { UiMatDialogService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    ErrorHandlerService,
    UrlShortenerService,
    gtmGA4Track
} from '@mhp/ui-shared-services';

import { BACKDROP_CLASS_BLURRY } from '../../common/dialog/dialog.constants';
import { LocalApplicationState } from '../../state';
import { setNavigationToSavedConfigurationActive } from '../state/actions/configuration.actions';
import { LoadCodeDialogComponent } from './load-code-dialog/load-code-dialog.component';
import { ShowCodeDialogComponent } from './show-code-dialog/show-code-dialog.component';

const DIALOG_DEFAULTS = Object.freeze({
    backdropClass: BACKDROP_CLASS_BLURRY,
    width: '600px'
});

@Injectable({
    providedIn: 'root'
})
export class AmlCodeService {
    constructor(
        private readonly dialogService: UiMatDialogService,
        private readonly urlShortenerService: UrlShortenerService,
        private readonly router: Router,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly location: Location,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>
    ) {}

    /**
     * Open a dialog to allow the user to input his AML code and to load the resulting URL.
     * @param viewContainerRef The viewContainerRef to hook the dialog to.
     */
    openLoadAmlCodeDialog$(viewContainerRef: ViewContainerRef) {
        return this.dialogService
            .open$(LoadCodeDialogComponent, {
                ...DIALOG_DEFAULTS,
                viewContainerRef
            })
            .pipe(
                switchMap(
                    (
                        dialogRef
                    ): Observable<[LoadCodeDialogComponent, string]> =>
                        race(
                            dialogRef.beforeClosed().pipe(
                                tap(() =>
                                    gtmGA4Track('load_configuration_cancel')
                                ),
                                switchMap(() => EMPTY)
                            ),
                            dialogRef.componentInstance.confirm.pipe(
                                map(
                                    (
                                        code
                                    ): [LoadCodeDialogComponent, string] => [
                                        dialogRef.componentInstance,
                                        code
                                    ]
                                )
                            )
                        )
                ),
                switchMap(([componentInstance, code]) =>
                    componentInstance.handleServerCallInProgress(
                        this.urlShortenerService.resolveShortCode$(code).pipe(
                            this.errorHandlerService.applyRetry({
                                messageProviderOnFinalError: (error) => {
                                    gtmGA4Track('load_configuration_failed');
                                    if (
                                        (error instanceof HttpErrorResponse &&
                                            error.status === 404) ||
                                        error instanceof IllegalStateError
                                    ) {
                                        return translate(
                                            'AML_CODE.LOAD.ERROR.NOT_VALID'
                                        );
                                    }
                                    return translate(
                                        'AML_CODE.LOAD.ERROR.FAILED_RESOLVING_CODE'
                                    );
                                }
                            }),
                            catchError(() => EMPTY)
                        )
                    )
                ),
                take(1),
                delay(0),
                tap(() => {
                    // Persist that the navigation to a configuration using a configuration code is ongoing
                    this.applicationStateService.dispatch(
                        setNavigationToSavedConfigurationActive({
                            navigationToSavedConfigurationActive: true
                        })
                    );
                }),
                switchMap((resolvedUrlString) => {
                    gtmGA4Track('load_configuration_success');
                    const resolvedUrl = new URL(resolvedUrlString);
                    return this.router.navigateByUrl(
                        `${resolvedUrl.pathname}${resolvedUrl.search}${resolvedUrl.hash}`
                    );
                })
            );
    }

    openShowAmlCodeDialog$(
        code: string,
        shortUrl: string,
        viewContainerRef: ViewContainerRef,
        componentFactoryResolver: ComponentFactoryResolver
    ) {
        return this.dialogService
            .open$(ShowCodeDialogComponent, {
                ...DIALOG_DEFAULTS,
                viewContainerRef,
                componentFactoryResolver
            })
            .pipe(
                switchMap(({ componentInstance }) => {
                    componentInstance.code = code;
                    componentInstance.shortUrl = shortUrl;
                    return EMPTY;
                })
            );
    }
}
