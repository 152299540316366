import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiLazyloadImageModule } from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { StaticRendererComponent } from './static-renderer.component';

@NgModule({
    declarations: [StaticRendererComponent],
    imports: [CommonModule, UiLazyloadImageModule, I18nModule],
    exports: [StaticRendererComponent]
})
export class StaticRendererModule {}
