<mhp-ui-sticky-header-expansion-panel
    (stickyChange)="onGroupHeaderStuck($event)"
>
    <mhp-ui-expansion-panel
        class="configuration-group-first-level"
        [headerHeight]="60"
        [expanded]="expanded"
        #expansionPanel
    >
        <mhp-ui-expansion-panel-header
            mat-ripple
            (click)="onGroupHeaderClick()"
            mhpUiStopClickPropagation
        >
            <div class="configuration-group-first-level__header">
                <div class="configuration-group-first-level__header-section-1">
                    <span
                        class="configuration-group-first-level__header-title"
                        [ngClass]="{
                            'configuration-group-first-level__header-title--single-option-parent':
                                hasChangeableSelectionInHierarchy(
                                    contentAware
                                ) === false
                        }"
                    >
                        {{ contentAware?.nameTranslated }}
                    </span>
                    <span
                        class="configuration-group-first-level__selection-info"
                        *transloco="let t"
                    >
                        <ng-container
                            *ngIf="
                                (showOptionCount$() | async) === false;
                                else optionCountText
                            "
                        >
                            <mhp-ui-icon
                                *ngIf="
                                    (isSingleSelectionQOption$() | async) ===
                                    true
                                "
                                svgIcon="mhp-ui:q-option"
                                size="small"
                                [matTooltip]="t('ICONS.Q_OPTION_ALT')"
                            ></mhp-ui-icon>
                            {{ getSelectionText$() | async }}
                        </ng-container>
                        <ng-template #optionCountText>
                            {{ getOptionCountText$() | async }}
                        </ng-template>
                    </span>
                </div>
                <div class="configuration-group-first-level__header-section-2">
                    <ng-container
                        *ngIf="(showOptionCount$() | async) === false"
                    >
                        <mhp-ui-icon-button
                            class="configuration-group-first-level__header-info-icon"
                            [class.configuration-group-first-level__header-info-icon--hidden]="
                                (singleOptionSelectionHasInfo$() | async) ===
                                false
                            "
                            svgIcon="mhp-ui:info"
                            size="large"
                            mhpUiStopClickPropagation
                            mhpUiPreventNestedRipple
                            (click)="intentShowInfo()"
                        ></mhp-ui-icon-button>
                        <span
                            *mhpIfShowConfigurationPricing
                            class="configuration-group-first-level__pricing-info"
                            >{{
                                getGroupPricing$()
                                    | async
                                    | effectivePricingAwareFormat
                            }}</span
                        >
                    </ng-container>
                </div>
                <div class="configuration-group-first-level__header-actions">
                    <div
                        class="configuration-group-first-level__header-action-expanded-state"
                    >
                        <mhp-ui-icon-button
                            [svgIcon]="
                                expansionPanel.expanded
                                    ? 'mhp-ui:angle-up'
                                    : 'mhp-ui:angle-down'
                            "
                            (click)="intentToggleExpandedState()"
                            mhpUiStopClickPropagation
                            mhpUiPreventNestedRipple
                        ></mhp-ui-icon-button>
                    </div>
                </div>
            </div>
        </mhp-ui-expansion-panel-header>
        <mhp-ui-expansion-panel-body>
            <div
                class="configuration-group-first-level__scroll-target"
                #panelBody
            ></div>
            <ng-container *ngIf="expanded">
                <!-- Default render type -->
                <div
                    *ngIf="
                        renderType === RENDER_TYPE.REGULAR;
                        else personalisationAndAccessories
                    "
                    class="configuration-group-first-level__regular-items-container"
                >
                    <ng-container *ngIf="specialSelect">
                        <div
                            class="configuration-group-first-level__regular-items-container-special-select"
                            [ngClass]="{
                                'configuration-group-first-level__regular-items-container-special-select--single-option-parent':
                                    hasChangeableSelectionInHierarchy(
                                        contentAware
                                    ) === false
                            }"
                        >
                            <span>{{ specialSelect.label }}</span>
                            <div>
                                <mhp-configuration-category-select
                                    [categoryItem]="specialSelect"
                                    [formFieldClass]="
                                        'mhp-ui-form-field--full-width'
                                    "
                                    [disabled]="optionSelectionDisabled()"
                                    (selectCategory)="
                                        intentSelectItem($event.id)
                                    "
                                    (showInfo)="
                                        intentShowInfoForCategoryItem($event)
                                    "
                                >
                                </mhp-configuration-category-select>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="
                            (isSearchActive$() | async) === false;
                            else flatOptionsWhenSearchActive
                        "
                    >
                        <ng-container
                            *ngFor="
                                let contentItem of contentAware.content;
                                trackBy: trackById
                            "
                        >
                            <ng-container *ngIf="contentItem.type === 'Group'">
                                <mhp-configuration-group-sublevel
                                    [group]="contentItem"
                                    [optionSelectionDisabled]="optionSelectionDisabled()"
                                    (intentSelect)="intentSelectItem($event)"
                                    (showInfo)="intentShowInfo($event)"
                                ></mhp-configuration-group-sublevel>
                            </ng-container>

                            <ng-container
                                *ngIf="contentItem.type === 'Collection'"
                            >
                                <mhp-configuration-collection
                                    [collection]="contentItem"
                                    [optionSelectionDisabled]="optionSelectionDisabled()"
                                    (intentSelect)="intentSelectItem($event)"
                                    (showInfo)="intentShowInfo($event)"
                                ></mhp-configuration-collection>
                            </ng-container>

                            <ng-container *ngIf="contentItem.type === 'List'">
                                <mhp-configuration-list
                                    [list]="contentItem"
                                    [optionSelectionDisabled]="optionSelectionDisabled()"
                                    (intentSelect)="intentSelectItem($event)"
                                ></mhp-configuration-list>
                            </ng-container>

                            <ng-container
                                *ngIf="contentItem.type === 'NestedCode'"
                            >
                                <mhp-configuration-nested-code
                                    [nestedCode]="contentItem"
                                    [optionSelectionDisabled]="optionSelectionDisabled()"
                                    (intentSelect)="intentSelectItem($event)"
                                ></mhp-configuration-nested-code>
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *ngIf="getChildOptions$() | async as options"
                        >
                            <mhp-configuration-options-list
                                [options]="options"
                                [useOverflowIndicator]="false"
                                [optionSelectionDisabled]="optionSelectionDisabled()"
                                (optionSelected)="intentSelectItem($event)"
                                (infoRequested)="intentShowInfo($event)"
                            ></mhp-configuration-options-list>
                        </ng-container>
                    </ng-container>
                    <ng-template #flatOptionsWhenSearchActive>
                        <mhp-configuration-options-list
                            [options]="getAllOptionsInHierarchy$() | async"
                            [useOverflowIndicator]="false"
                            [optionSelectionDisabled]="optionSelectionDisabled()"
                            (optionSelected)="intentSelectItem($event)"
                            (infoRequested)="intentShowInfo($event)"
                        ></mhp-configuration-options-list>
                    </ng-template>
                </div>

                <!-- Personalisation and accessories render type -->
                <ng-template #personalisationAndAccessories>
                    <mhp-configuration-composite-items-list
                        [configurationCompositeItems]="
                            getConfigurationCompositeItemModels$() | async
                        "
                        [optionSelectionDisabled]="optionSelectionDisabled()"
                        (selected)="intentSelectItem($event)"
                    ></mhp-configuration-composite-items-list>
                </ng-template>
            </ng-container>
        </mhp-ui-expansion-panel-body>
    </mhp-ui-expansion-panel>
</mhp-ui-sticky-header-expansion-panel>
