import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UiBaseComponent } from '@mhp/ui-components';

import { VideoChatLocation } from '../video-chat-location.interface';

@Component({
    selector: 'mhp-video-placeholder',
    templateUrl: './video-placeholder.component.html',
    styleUrls: ['./video-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlaceholderComponent extends UiBaseComponent {
    @Input() initials?: string;

    @Input() location?: VideoChatLocation;

    constructor() {
        super();
    }
}
