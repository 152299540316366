import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { UiHotspotsModule, UiLazyloadImageModule } from '@mhp/ui-components';
import { MarkdownModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../dialog/dialog.module';
import { StaticRendererModule } from '../static-renderer/static-renderer.module';
import { VrCamerasViewComponent } from './vr-cameras-view/vr-cameras-view.component';
import { VrDocsDialogComponent } from './vr-docs-dialog/vr-docs-dialog.component';

@NgModule({
    declarations: [VrCamerasViewComponent, VrDocsDialogComponent],
    exports: [VrCamerasViewComponent, VrDocsDialogComponent],
    imports: [
        CommonModule,
        StaticRendererModule,
        UiHotspotsModule,
        MatRippleModule,
        UiLazyloadImageModule,
        TranslocoDirective,
        MarkdownModule,
        DialogModule,
        MatButton
    ]
})
export class VrModule {}
