import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    ViewChild
} from '@angular/core';
import {
    ButtonSize,
    IconSize,
    UiGridListItemComponent
} from '@mhp/ui-components';

import { ConfigurationCodeBaseComponent } from '../configuration-code-base/configuration-code-base.component';

const RATIO_SPLITTER = 8 / 16;
export const RATIO_OPTION_THUMBNAIL = 3 / 2;

@Component({
    selector: 'mhp-configuration-code-layout',
    templateUrl: './configuration-code-layout.component.html',
    styleUrls: ['./configuration-code-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationCodeLayoutComponent extends ConfigurationCodeBaseComponent {
    readonly ICON_SIZE = IconSize;
    readonly BUTTON_SIZE = ButtonSize;

    readonly ratioSplitter = RATIO_SPLITTER;
    readonly ratioThumbnail = RATIO_OPTION_THUMBNAIL;

    @ViewChild(UiGridListItemComponent, { static: false })
    gridListComponent?: UiGridListItemComponent;

    constructor(private readonly elementRef: ElementRef) {
        super();
    }

    focus() {
        this.gridListComponent?.focus();
    }

    /**
     * Determine if the documents activeElement is contained within this component instances sub-tree.
     */
    hasFocus() {
        return document.activeElement
            ? this.elementRef.nativeElement.contains(document.activeElement)
            : false;
    }
}
