<textarea
    #configuration
    [ngModel]="getConfigFromUrl$() | async | json"
></textarea>
<button
    mat-flat-button
    color="primary"
    (click)="applyConfig(configuration.value)"
>
    Apply configuration
</button>
