import deepLock from 'deep-lock';

import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';

import { IodRenderingAdjustments } from './static-renderer.service';

/**
 * Commonly used default-rendering-adjustments to provide a default-view
 * on the configuration using:
 * - default-exterior camera
 * - always day
 * - all animations forced off
 */
export const DEFAULT_RENDERING_ADJUSTMENTS: IodRenderingAdjustments = deepLock({
    overrideCamera: 'DEF_EXT',
    dayNightState: EnvironmentLightingProfileState.DAY,
    forceAnimationsOff: true
});
