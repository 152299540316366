import { Observable, of, switchMap } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { translate } from '@jsverse/transloco';
import { SocialSharingService } from '@mhp/aml-ui-shared-services/social-sharing';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiMatDialogService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    CinematicCaptureInfo,
    EngineControlService,
    ErrorHandlerService,
    gtmGA4Track
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../../environments/environment';
import { LocalApplicationState } from '../../../state';
import { ConfigurationSocialSharingService } from '../../social-sharing/configuration-social-sharing.service';
import { CinematicDownloadService } from '../cinematic-download.service';

@UntilDestroy()
@Component({
    selector: 'mhp-cinematic-share-dialog',
    templateUrl: './cinematic-share-dialog.component.html',
    styleUrls: ['./cinematic-share-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CinematicShareDialogComponent {
    private readonly videoCaptureResult$: Observable<
        CinematicCaptureInfo | undefined
    >;

    constructor(
        private readonly matDialogService: UiMatDialogService,
        private readonly socialSharingService: SocialSharingService,
        private readonly configurationSocialSharingService: ConfigurationSocialSharingService,
        private readonly matDialogRef: MatDialogRef<CinematicShareDialogComponent>,
        private readonly engineControlService: EngineControlService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly cinematicDownloadService: CinematicDownloadService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>
    ) {
        this.videoCaptureResult$ = this.initStartVideoCapture$();
    }

    intentShare(socialServiceId: string) {
        this.videoCaptureResult$
            .pipe(
                switchMap((captureInfo) => {
                    if (!captureInfo) {
                        return of(undefined);
                    }
                    return this.configurationSocialSharingService.shareCurrentConfiguration$(
                        socialServiceId,
                        {
                            urlModifier: (inputUrl) => {
                                const modifiedUrl = new URL(inputUrl);
                                modifiedUrl.searchParams.set(
                                    'videoId',
                                    captureInfo.fileId
                                );
                                return modifiedUrl.toString();
                            }
                        }
                    );
                }),
                take(1),
                untilDestroyed(this)
            )
            .subscribe({
                complete: () => {
                    gtmGA4Track('cinematic_share_success');
                },
                error: (error) => {
                    this.errorHandlerService.showErrorMessage(
                        () =>
                            translate(
                                'SOCIAL_SHARING.ERROR.URL_SHARING_FAILED'
                            ),
                        error
                    );
                    gtmGA4Track('cinematic_share_failed');
                }
            });
    }

    intentDownload() {
        gtmGA4Track('cinematic_download_start');

        this.videoCaptureResult$
            .pipe(
                switchMap((captureInfo) => {
                    if (!captureInfo) {
                        return of(undefined);
                    }
                    return this.cinematicDownloadService.downloadCinematic$(
                        captureInfo
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe(
                () => {
                    this.matDialogRef.close();
                },
                () => gtmGA4Track('cinematic_download_failed')
            );
    }

    @MemoizeObservable()
    isVideoDownloadAvailable$(): Observable<boolean> {
        return this.videoCaptureResult$.pipe(
            map((captureInfo) => !!captureInfo)
        );
    }

    @MemoizeObservable()
    getDownloadProgress$(): Observable<number> {
        return this.applicationStateService
            .getEngineState()
            .pipe(
                map((state) =>
                    Math.max(
                        state.configurationState?.cinematicState?.progress || 0,
                        0.05
                    )
                )
            );
    }

    private initStartVideoCapture$() {
        return this.engineControlService
            .captureCinematic$(
                environment.appConfig.visualization.defaultCinematic
            )
            .pipe(
                take(1),
                startWith(undefined),
                untilDestroyed(this),
                this.errorHandlerService.applyRetry({
                    messageProviderOnFinalError: () =>
                        translate(
                            'CONFIGURATOR.FEATURES.CINEMATICS.ERRORS.CAPTURE_CINEMATIC'
                        )
                }),
                lazyShareReplay()
            );
    }
}
