import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    UiButtonModule,
    UiIconsModule,
    UiMatDialogModule
} from '@mhp/ui-components';
import { I18nModule, L10nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../../dialog/dialog.module';
import { StartSessionComponent } from './start-session.component';

@NgModule({
    declarations: [StartSessionComponent],
    imports: [
        CommonModule,
        DialogModule,
        UiMatDialogModule,
        UiButtonModule,
        I18nModule,
        L10nModule,
        UiIconsModule
    ]
})
export class StartSessionModule {}
