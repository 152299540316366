import { MarkdownModule } from 'ngx-markdown';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiButtonModule, UiMatDialogModule } from '@mhp/ui-components';
import { GoogleTagManagerModule, I18nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../../dialog/dialog.module';
import { EditionsDialogComponent } from './editions-dialog/editions-dialog.component';

@NgModule({
    declarations: [EditionsDialogComponent],
    imports: [
        CommonModule,

        UiMatDialogModule,
        UiButtonModule,

        I18nModule,
        MarkdownModule.forChild(),
        GoogleTagManagerModule,
        DialogModule
    ],
    exports: [EditionsDialogComponent]
})
export class EditionsModule {}
