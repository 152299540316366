import { EMPTY, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../environments/environment';
import { ConfigurationChangedStateTrackingService } from '../configuration-changed-state/configuration-changed-state-tracking.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { ConfigurationSessionLocalPersistenceService } from './configuration-session-local-persistence.service';

/**
 * Service that tracks the currently active configuration and persists it to
 * local storage.
 * @see ConfigurationSessionLocalPersistenceService
 */
@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class ConfigurationSessionTrackingService {
    constructor(
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly configurationSessionLocalPersistenceService: ConfigurationSessionLocalPersistenceService,
        private readonly configurationChangedStateTrackingService: ConfigurationChangedStateTrackingService,
        private readonly errorHandlerService: ErrorHandlerService
    ) {
        if (!environment.appConfig.featureToggles.enableResumeConfiguration) {
            return;
        }
        this.initPersistConfigurationState();
    }

    /**
     * Init logic to track and persist the current session info to localstorage.
     * Do only persist the configuration in case the user actively touched options or environment-settings
     * while starting the session.
     * @private
     */
    private initPersistConfigurationState() {
        return this.configurationChangedStateTrackingService
            .getConfigurationSessionChangedState$()
            .pipe(
                switchMap((changedState) => {
                    if (
                        !changedState.optionsTouched &&
                        !changedState.environmentTouched
                    ) {
                        // only update persisted state in case the user changed the configuration or the env
                        return of(undefined);
                    }

                    // at least options or environment have been touched
                    return this.configurationSessionInfoService
                        .getActiveConfigurationSessionInfo$()
                        .pipe(
                            switchMap((sessionInfo) => {
                                if (!sessionInfo) {
                                    return of(undefined);
                                }
                                return this.configurationSessionLocalPersistenceService
                                    .persistSession$(sessionInfo.engineData)
                                    .pipe(
                                        this.errorHandlerService.applyRetry(),
                                        catchError(() => EMPTY)
                                    );
                            }),
                            untilDestroyed(this)
                        );
                })
            )
            .subscribe();
    }
}
