<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    *transloco="let t; read: 'CONFIGURATOR.EDITIONS.DIALOG'"
    [showClose]="false"
    [title]="
        editionLabel ? t('TITLE_SPECIFIC', { label: editionLabel }) : t('TITLE')
    "
>
    <mhp-ui-mat-dialog-content>
        <div [innerHTML]="t('DESCRIPTION') | markdown | async"></div>
    </mhp-ui-mat-dialog-content>

    <mhp-ui-mat-dialog-actions-left *ngIf="editionLabel">
        <button
            *transloco="let t"
            mat-button
            (click)="matDialogRef.close()"
            [trackGA4Event]="{
                event: 'editions_cancel_selection_click'
            }"
        >
            {{ t('COMMON.CANCEL') }}
        </button>
    </mhp-ui-mat-dialog-actions-left>
    <mhp-ui-mat-dialog-actions-right *ngIf="editionLabel">
        <button
            mat-flat-button
            (click)="intentConfirm()"
            [trackGA4Event]="{
                event: 'editions_confirm_selection_click'
            }"
        >
            {{ t('CTA_CONFIRM') }}
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
