import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    UiButtonModule,
    UiCheckboxModule,
    UiIconsModule,
    UiInputModule,
    UiSelectModule
} from '@mhp/ui-components';
import { GoogleTagManagerModule, I18nModule } from '@mhp/ui-shared-services';

import { SalesforceModule } from '../../dealer/salesforce/salesforce.module';
import { DialogModule } from '../../dialog/dialog.module';
import { StaticRendererModule } from '../static-renderer/static-renderer.module';
import { RequestPdfViaEmailComponent } from './request-pdf-via-email/request-pdf-via-email.component';
import { SubmitToDealerComponent } from './submit-to-dealer/submit-to-dealer.component';

@NgModule({
    declarations: [SubmitToDealerComponent, RequestPdfViaEmailComponent],
    imports: [
        UiButtonModule,
        CommonModule,
        ReactiveFormsModule,
        UiSelectModule,
        I18nModule,
        UiInputModule,
        UiCheckboxModule,
        StaticRendererModule,
        DialogModule,
        SalesforceModule,
        GoogleTagManagerModule,
        UiIconsModule
    ],
    exports: [
        SubmitToDealerComponent,
        RequestPdfViaEmailComponent,
        ReactiveFormsModule,
        UiSelectModule
    ]
})
export class SalesforceDialogsModule {}
