import { isEmpty } from 'lodash-es';

import {
    CategoryItem,
    CategoryItemRenderHint
} from '@mhp/aml-ui-shared-services';
import {
    isOptionCollection,
    isOptionGroup,
    isOptionList
} from '@mhp/ui-shared-services';

import {
    ExtendedUiConfigurationMetaItem,
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection,
    ExtendedUiOptionGroup,
    ExtendedUiOptionList
} from '../../configuration-model/configuration-interfaces';
import {
    isContentAware,
    isExtendedUiNestedCode,
    isExtendedUiOptionCode,
    isExtendedUiOptionCollection,
    isExtendedUiOptionList
} from '../../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../services/configuration-node-lookup.service';
import {
    CategoryItemModifier,
    CategorySourceItemFilter
} from './category-mapper.service';

export class CategoryMapper {
    constructor(
        private readonly sourceItemFilter: CategorySourceItemFilter,
        private readonly itemModifier: CategoryItemModifier,
        private readonly nodeLookupService: ConfigurationNodeLookupService
    ) {}

    mapOptionGroups(
        optionGroups: ExtendedUiOptionGroup[] | undefined
    ): CategoryItem[] {
        if (!optionGroups) {
            return [];
        }
        return optionGroups
            .map((optionGroup): CategoryItem | undefined => {
                if (!this.sourceItemFilter(optionGroup, [])) {
                    return undefined;
                }
                return this.itemModifier(
                    this.mapOptionGroup(optionGroup, []),
                    optionGroup
                );
            })
            .filter((item): item is CategoryItem => !!item);
    }

    /**
     * Get the maximum amount of options available down in the hierarchy.
     * @param item The content aware item to get the max option count for.
     */
    hasChangeableSelectionInHierarchy(
        item: ExtendedUiContentAwareConfigurationMetaItem
    ): boolean {
        if (isExtendedUiOptionCollection(item)) {
            // check if this collection is mandatory
            if (item.mandatory) {
                // mandatory collection. See if we find more than one option down the hierarchy to determine if it can be altered
                return (
                    this.nodeLookupService.collectNodes(
                        (node) => isExtendedUiOptionCode(node),
                        [item]
                    ).length > 1
                );
            }
            // in case the collection is not mandatory, it can be changed
            return true;
        }

        // check if it's a list or a nested code
        if (isExtendedUiOptionList(item) || isExtendedUiNestedCode(item)) {
            // check if we find more than one option down the hierarchy to determine if it can be altered.
            return (
                this.nodeLookupService.collectNodes(
                    (node) => isExtendedUiOptionCode(node),
                    [item]
                ).length > 1
            );
        }

        // take a look up the hierarchy. If there is a mandatory collection, check if it has more than 1 option to choose from
        const mandatoryCollectionParent = [...item.hierarchyPath]
            .reverse()
            .find(
                (parent): parent is ExtendedUiOptionCollection =>
                    isExtendedUiOptionCollection(parent) && parent.mandatory
            );

        if (mandatoryCollectionParent) {
            const optionNodesInMandatoryCollection =
                this.nodeLookupService.collectNodes(
                    (node) => isExtendedUiOptionCode(node),
                    [mandatoryCollectionParent]
                );
            return optionNodesInMandatoryCollection.length > 1;
        }

        // no mandatory parent, so dive further down the hierarchy
        for (const child of item.content) {
            if (isContentAware(child)) {
                if (this.hasChangeableSelectionInHierarchy(child)) {
                    return true;
                }
            } else if (isExtendedUiOptionCode(child)) {
                return true;
            }
        }
        return false;
    }

    private mapOptionGroup(
        optionGroup: ExtendedUiOptionGroup,
        path: ExtendedUiConfigurationMetaItem[],
        categoryItemHierarchy: CategoryItem[] = []
    ): CategoryItem {
        const pathLocal = [...path, optionGroup];

        const mappedCategoryItem: CategoryItem = {
            id: optionGroup.id,
            nameInternal: optionGroup.name,
            label: optionGroup.nameTranslated,
            hierarchyPath: categoryItemHierarchy,
            isCategorySelector: true,
            hasChangeableSelectionInHierarchy:
                this.hasChangeableSelectionInHierarchy(optionGroup)
        };

        const localCategoryItemHierarchy = [
            ...categoryItemHierarchy,
            mappedCategoryItem
        ];

        const children = !optionGroup.content
            ? undefined
            : optionGroup.content
                  .map((contentItem) => {
                      if (!this.sourceItemFilter(contentItem, pathLocal)) {
                          return undefined;
                      }
                      let mappedItem: CategoryItem | undefined;
                      if (isOptionGroup(contentItem)) {
                          mappedItem = this.mapOptionGroup(
                              contentItem,
                              pathLocal,
                              localCategoryItemHierarchy
                          );
                      } else if (isOptionCollection(contentItem)) {
                          mappedItem = this.mapOptionCollection(
                              contentItem,
                              pathLocal,
                              localCategoryItemHierarchy
                          );
                      } else if (isOptionList(contentItem)) {
                          mappedItem = this.mapOptionList(
                              contentItem,
                              pathLocal,
                              localCategoryItemHierarchy
                          );
                      }
                      return mappedItem
                          ? this.itemModifier(mappedItem, contentItem)
                          : mappedItem;
                  })
                  .filter((item): item is CategoryItem => !!item);

        mappedCategoryItem.children = isEmpty(children) ? undefined : children;

        return mappedCategoryItem;
    }

    private mapOptionCollection(
        optionCollection: ExtendedUiOptionCollection,
        path: ExtendedUiConfigurationMetaItem[],
        categoryItemHierarchy: CategoryItem[] = []
    ): CategoryItem {
        const pathLocal = [...path, optionCollection];

        const mappedCategoryItem: CategoryItem = {
            id: optionCollection.id,
            nameInternal: optionCollection.name ?? '',
            label: optionCollection.nameTranslated,
            hierarchyPath: categoryItemHierarchy,
            isCategorySelector: true,
            hasChangeableSelectionInHierarchy:
                this.hasChangeableSelectionInHierarchy(optionCollection)
        };

        const localCategoryItemHierarchy = [
            ...categoryItemHierarchy,
            mappedCategoryItem
        ];

        if (optionCollection.content) {
            const mappedChildrenInfo = this.mapCollectionChildren(
                optionCollection.content,
                pathLocal,
                localCategoryItemHierarchy
            );
            const children = mappedChildrenInfo.items;

            mappedCategoryItem.children = isEmpty(children)
                ? undefined
                : children;
            mappedCategoryItem.renderHint = mappedChildrenInfo.renderHint;
            mappedCategoryItem.isCategorySelector =
                mappedChildrenInfo.renderHint === 'flat';
        }

        return mappedCategoryItem;
    }

    private mapCollectionChildren(
        children: (
            | ExtendedUiOptionCode
            | ExtendedUiOptionList
            | ExtendedUiOptionCollection
        )[],
        pathLocal: ExtendedUiConfigurationMetaItem[],
        categoryItemHierarchy: CategoryItem[] = []
    ): {
        items: CategoryItem[];
        renderHint: CategoryItemRenderHint;
    } {
        const filteredChildren = children.filter((child) =>
            this.sourceItemFilter(child, pathLocal)
        );

        // check if children are OptionCodes only and all of type text
        const areAllOptionCodeWithTypeText =
            !isEmpty(filteredChildren) &&
            filteredChildren.every(
                (child) =>
                    isExtendedUiOptionCode(child) && child.subType === 'text'
            );

        let mappedCategoryItems: CategoryItem[];
        let renderHint: CategoryItemRenderHint = 'flat';

        if (areAllOptionCodeWithTypeText) {
            renderHint = 'dropdown';
            mappedCategoryItems = filteredChildren
                .map((child: ExtendedUiOptionCode) =>
                    this.itemModifier(
                        {
                            id: child.id,
                            nameInternal: child.name ?? '',
                            label: child.nameTranslated,
                            description: child.descriptionTranslated,
                            hierarchyPath: categoryItemHierarchy,
                            disabled: child.active,
                            selected: child.selected,
                            isCategorySelector: false
                        },
                        child
                    )
                )
                .filter((item): item is CategoryItem => !!item);
        } else {
            mappedCategoryItems = filteredChildren
                .map((child) => {
                    if (isOptionList(child)) {
                        return this.itemModifier(
                            this.mapOptionList(
                                child,
                                pathLocal,
                                categoryItemHierarchy
                            ),
                            child
                        );
                    }
                    if (isOptionCollection(child)) {
                        return this.itemModifier(
                            this.mapOptionCollection(
                                child,
                                pathLocal,
                                categoryItemHierarchy
                            ),
                            child
                        );
                    }
                    return undefined;
                })
                .filter((item): item is CategoryItem => !!item);
        }

        return {
            items: mappedCategoryItems,
            renderHint
        };
    }

    private mapOptionList(
        optionList: ExtendedUiOptionList,
        path: ExtendedUiConfigurationMetaItem[],
        categoryItemHierarchy: CategoryItem[] = []
    ): CategoryItem {
        const pathLocal = [...path, optionList];

        const mappedCategoryItem: CategoryItem = {
            id: optionList.id,
            nameInternal: optionList.name,
            label: optionList.nameTranslated,
            hierarchyPath: categoryItemHierarchy,
            isCategorySelector: true,
            hasChangeableSelectionInHierarchy:
                this.hasChangeableSelectionInHierarchy(optionList)
        };

        const localCategoryItemHierarchy = [
            ...categoryItemHierarchy,
            mappedCategoryItem
        ];

        const children = !optionList.content
            ? undefined
            : optionList.content
                  .map((contentItem): CategoryItem | undefined => {
                      // only apply sourceItemFilter in case we won't do the fallback to the first child later on
                      if (
                          optionList.content.length > 1 &&
                          !this.sourceItemFilter(contentItem, pathLocal)
                      ) {
                          return undefined;
                      }

                      return this.itemModifier(
                          {
                              id: contentItem.id,
                              nameInternal: contentItem.name,
                              label: contentItem.nameTranslated,
                              hierarchyPath: localCategoryItemHierarchy,
                              isCategorySelector: true,
                              hasChangeableSelectionInHierarchy:
                                  this.hasChangeableSelectionInHierarchy(
                                      contentItem
                                  )
                          },
                          contentItem
                      );
                  })
                  .filter((item): item is CategoryItem => !!item);

        if (children?.length === 1) {
            // drop the list-node in-between and report only the first nested-code as category here
            return children[0];
        }

        mappedCategoryItem.children = isEmpty(children) ? undefined : children;

        return mappedCategoryItem;
    }
}
