import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'mhp-fridge-label-dialog',
    templateUrl: './fridge-label-dialog.component.html',
    styleUrls: ['./fridge-label-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FridgeLabelDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { fridgeLabelUrl: string }
    ) {}
}
