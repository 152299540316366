import { switchMap, take, tap } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    Signal,
    input
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    ConfigurationCompositeItemModel,
    ConfigurationCompositeItemOptionModel
} from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import { MemoizeObservable } from '@mhp/common';
import {
    TRACK_BY_ID,
    UiBaseComponent,
    UiMatDialogService
} from '@mhp/ui-components';
import { gtmGA4Track } from '@mhp/ui-shared-services';

import { FullscreenImageComponent } from '../../../../common/images/fullscreen-image/fullscreen-image.component';
import { DebugService } from '../../../../debug/debug.service';
import { InfoLayerService } from '../../../info-layer/info-layer.service';
import { ConfigurationNodeLookupService } from '../../../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../../services/product-configuration-session.service';
import { ConfigurationCompositeItemService } from '../../configuration-elements/configuration-composite-item/configuration-composite-item.service';

@Component({
    selector: 'mhp-configuration-composite-items-list',
    templateUrl: './configuration-composite-items-list.component.html',
    styleUrls: ['./configuration-composite-items-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationCompositeItemsListComponent extends UiBaseComponent {
    readonly trackById = TRACK_BY_ID;

    @Input()
    configurationCompositeItems: ConfigurationCompositeItemModel[];

    optionSelectionDisabled = input(false);

    @Output()
    readonly selected = new EventEmitter<string>();

    useDebug: Signal<boolean>;

    constructor(
        private readonly configurationCompositeItemService: ConfigurationCompositeItemService,
        private readonly infoLayerService: InfoLayerService,
        private readonly dialogService: UiMatDialogService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly configurationNodeLookupService: ConfigurationNodeLookupService,
        private readonly debugService: DebugService
    ) {
        super();

        this.useDebug = toSignal(this.debugService.getDebugState$(), {
            initialValue: false
        });

        this.completeOnDestroy(this.selected);
    }

    @MemoizeObservable()
    getCompositeItemFallbackImageUrl$() {
        return this.configurationCompositeItemService.getCompositeItemFallbackImageUrl$();
    }

    intentSelectOption(id: string) {
        this.selected.next(id);
    }

    intentShowInfo(compositeItemModel: ConfigurationCompositeItemOptionModel) {
        return this.getCompositeItemFallbackImageUrl$()
            .pipe(
                take(1),
                switchMap((fallbackImageUrl) =>
                    this.infoLayerService.showInfoLayer$({
                        label: compositeItemModel.label,
                        description: compositeItemModel.description || '',
                        thumbnailUrls: compositeItemModel.thumbnailUrls,
                        thumbnailFallbackUrl: fallbackImageUrl,
                        itemType: 'image',
                        forceImageFit: 'cover',
                        isQOption: !!compositeItemModel.meta?.qOption,
                        hasAdditionalFittingCost:
                            !!compositeItemModel.meta?.hasAdditionalFittingCost
                    })
                )
            )
            .pipe(this.takeUntilDestroy())
            .subscribe();
    }

    intentEnlargeThumbnail(itemModel: ConfigurationCompositeItemModel) {
        // FIXME: This might get irrelevant as node-path related work has been done in the infor-context already..
        this.productConfigurationSessionService
            .getOptionGroups$()
            .pipe(take(1))
            .subscribe((groups) => {
                if (!groups) {
                    return;
                }
                const namePath = this.configurationNodeLookupService
                    .getNodePathToNode(
                        (node) => node.id === itemModel.id,
                        groups
                    )
                    ?.reverse()
                    ?.map((node) => node.name);
                if (namePath) {
                    gtmGA4Track('show_fullscreen_image');
                }
            });

        return this.configurationCompositeItemService
            .getCompositeItemFallbackImageUrl$()
            .pipe(
                take(1),
                switchMap((fallbackImage) =>
                    this.dialogService
                        .openFullscreen$(FullscreenImageComponent)
                        .pipe(
                            tap((dialogRef) => {
                                const fullscreenImageComponent =
                                    dialogRef.componentInstance;
                                fullscreenImageComponent.imageSources =
                                    itemModel.thumbnailUrls;
                                fullscreenImageComponent.imageFallbackUrl =
                                    fallbackImage;
                            })
                        )
                ),
                this.takeUntilDestroy()
            )
            .subscribe();
    }
}
