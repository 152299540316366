import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormFieldModule, UiInputModule } from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { StaticRendererModule } from '../../configuration/static-renderer/static-renderer.module';
import { ConfigLinkEditorComponent } from './config-link-editor/config-link-editor.component';

@NgModule({
    declarations: [ConfigLinkEditorComponent],
    imports: [
        CommonModule,
        StaticRendererModule,
        UiFormFieldModule,
        UiInputModule,
        ReactiveFormsModule,
        I18nModule
    ],
    exports: [ConfigLinkEditorComponent]
})
export class SessionManagementModule {}
