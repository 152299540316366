<div
    class="configuration-main-layout"
    *transloco="let t; read: 'CONFIGURATOR'"
    [ngClass]="{
        'configuration-main-layout--stage-expanded':
            (isStageShrinked$() | async) === false
    }"
>
    <div class="configuration-main-layout__header-container">
        <ng-content
            select="[mhp-configuration-main-layout__header]"
        ></ng-content>
    </div>

    <div
        class="configuration-main-layout__stage"
        [ngClass]="{
            'configuration-main-layout__stage--minimized':
                (isStageMinimized$() | async) === true
        }"
    >
        <div #stagePlaceholder>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 9"
                class="configuration-main-layout__stage-placeholder"
            ></svg>
        </div>

        <ng-content
            select="[mhp-configuration-main-layout__action-bar]"
        ></ng-content>

        <div class="configuration-main-layout__stage-outlet">
            <ng-content
                select="[mhp-configuration-main-layout__stage-outlet]"
            ></ng-content>
        </div>

        <ng-content
            select="[mhp-configuration-main-layout__stage-other]"
        ></ng-content>
    </div>

    <div
        @toggleInnerStageEngineSettings
        *ngIf="showInnerStageSettings"
        class="configuration-main-layout__engine-settings-container configuration-main-layout__engine-settings-container--inner-stage"
    >
        <ng-content
            select="[mhp-configuration-main-layout__engine-settings-inner-stage]"
        ></ng-content>
    </div>
    <div
        class="configuration-main-layout__configuration-bar"
        [ngClass]="{
            'configuration-main-layout__configuration-bar--raised':
                renderRaisedConfigurationBar
        }"
    >
        <ng-content
            select="[mhp-configuration-main-layout__configuration-bar]"
        ></ng-content>
    </div>
</div>
<mhp-footer
    *ngIf="showFooter"
    [regionAndLanguageSelectionInterceptor]="
        regionAndLanguageSelectorInterceptor
    "
    [regionAndLanguageSelectionOptions]="regionAndLanguageSelectionOptions"
></mhp-footer>
