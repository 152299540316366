import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';

import {
    FormGroupChange,
    FormOption
} from '../../../model/order-management.models';

@Component({
    selector: 'mhp-order-configuration-form-field',
    templateUrl: './order-configuration-form-field.component.html',
    styleUrls: ['./order-configuration-form-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderConfigurationFormFieldComponent implements AfterViewInit {
    @Input()
    formOption: FormOption;

    @Input()
    orderFormGroup: FormGroup;

    @Input()
    optionNameToFocus: string;

    @Input()
    showPricingDNP: boolean;

    @Input()
    showPricingRRP: boolean;

    @Input()
    currency: string;

    @Input()
    isDialog?: boolean;

    @Output() selectionChange = new EventEmitter<FormGroupChange>();

    @ViewChild('fieldRef') fieldRef: MatSelect | MatInput;

    constructor(private readonly cdr: ChangeDetectorRef) {}

    ngAfterViewInit() {
        if (this.fieldRef.id && this.fieldRef.id === this.optionNameToFocus) {
            this.fieldRef.focus();
            this.cdr.detectChanges();
        }
    }

    onSelectionChange(id: string, label: string) {
        this.selectionChange.emit({ id, label });
    }
}
