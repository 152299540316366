import { flatten, isEmpty } from 'lodash-es';
import { Observable, Subject, combineLatest, concatMap, of } from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import {
    EngineActionInterceptor,
    EngineControlService,
    ErrorHandlerService,
    GoogleTagManagerService,
    ProductDataService,
    gtmGA4Track
} from '@mhp/ui-shared-services';

import { ExtendedUiOptionCode } from '../configuration-model/configuration-interfaces';
import { ConfigurationNodeLookupService } from '../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';

@Injectable()
export class AnimationControlService {
    constructor(
        private readonly productDataService: ProductDataService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly engineControlService: EngineControlService,
        private readonly googleTagManagerService: GoogleTagManagerService,
        private readonly errorHandlerService: ErrorHandlerService
    ) {
        this.initAnimationToggleProcessing();
    }

    // animation-changes are processed one-by-one using a subject as a queue
    private readonly toggleAnimationQueueSubject = new Subject<string>();

    /**
     * Toggle a given animation on or off, depending on its current state.
     * @param animationId
     */
    toggleAnimation(animationId: string) {
        this.toggleAnimationQueueSubject.next(animationId);
    }

    /**
     * Initialize processing of animation-toggle-requests submitted to the #toggleAnimationQueueSubject.
     * Requests are processed one by one using concatMap to fix concurrent changes in the underlying options
     * when animation-toggle-requests happen in a high frequency.
     *
     * See https://mhpimmersive.atlassian.net/browse/AMR-382 for reference
     * @private
     */
    private initAnimationToggleProcessing() {
        this.toggleAnimationQueueSubject
            .pipe(
                // process one by one
                concatMap((animationId) => {
                    const relatedOptions$: Observable<
                        ExtendedUiOptionCode[] | void
                    > = combineLatest([
                        this.productDataService
                            .getAvailableAnimations$()
                            .pipe(
                                map(
                                    (animations) =>
                                        animations.filter(
                                            (animation) =>
                                                animation.id === animationId
                                        )[0]
                                )
                            ),
                        this.productConfigurationSessionService.getOptionGroupsIncludingNonVisible$()
                    ]).pipe(
                        take(1),
                        map(([animationInfo, optionGroups]) => {
                            if (
                                !animationInfo?.relatedOptions ||
                                !optionGroups
                            ) {
                                return undefined;
                            }
                            return this.nodeLookupService.findNodesByConfigModelEntries(
                                animationInfo.relatedOptions,
                                optionGroups
                            );
                        })
                    );

                    const relatedOptionsEnabledByOtherAnimations$: Observable<
                        ExtendedUiOptionCode[] | void
                    > = combineLatest([
                        // get other animations
                        this.productDataService
                            .getAvailableAnimations$()
                            .pipe(
                                map((animations) =>
                                    animations.filter(
                                        (animation) =>
                                            animation.id !== animationId
                                    )
                                )
                            ),
                        this.engineControlService.getAnimationStates$(),
                        this.productConfigurationSessionService.getOptionGroupsIncludingNonVisible$()
                    ]).pipe(
                        take(1),
                        map(
                            ([
                                animationInfos,
                                animationStates,
                                optionGroups
                            ]) => {
                                if (!animationInfos || !optionGroups) {
                                    return undefined;
                                }

                                const activeAnimationsWithRelatedOptions =
                                    animationInfos.filter(
                                        (animation) =>
                                            !isEmpty(
                                                animation.relatedOptions
                                            ) &&
                                            animationStates.find(
                                                (state) =>
                                                    state.id === animation.id &&
                                                    state.state === 'END'
                                            )
                                    );

                                return this.nodeLookupService.findNodesByConfigModelEntries(
                                    flatten(
                                        activeAnimationsWithRelatedOptions.map(
                                            (animation) =>
                                                animation.relatedOptions as ConfigModel[]
                                        )
                                    ),
                                    optionGroups
                                );
                            }
                        )
                    );

                    const animationInterceptor: EngineActionInterceptor<{
                        id: string;
                        direction: 'START' | 'END';
                    }> = {
                        beforeAction: (context) => {
                            if (context?.direction !== 'END') {
                                return undefined;
                            }
                            // animation transitions to active-state, check if we need to activate options first
                            return relatedOptions$.pipe(
                                switchMap((relatedOptions) => {
                                    if (!relatedOptions) {
                                        return of(undefined);
                                    }

                                    return this.productConfigurationSessionService.updateNodeSelectionMultiple(
                                        relatedOptions
                                            .filter((node) => !node.selected)
                                            .map((node) => node.id)
                                    );
                                })
                            );
                        },
                        afterAction: (context) => {
                            if (context?.direction !== 'START') {
                                return undefined;
                            }
                            // animation transitioned to inactive-state, check if we need to deactivate options now
                            return relatedOptions$.pipe(
                                withLatestFrom(
                                    relatedOptionsEnabledByOtherAnimations$
                                ),
                                switchMap(
                                    ([
                                        relatedOptions,
                                        relatedOptionsEnabledByOtherAnimations
                                    ]) => {
                                        if (!relatedOptions) {
                                            return of(undefined);
                                        }

                                        const relatedOptionsNotDependentOnOtherAnimations =
                                            relatedOptions.filter(
                                                (option) =>
                                                    !relatedOptionsEnabledByOtherAnimations?.find(
                                                        (otherOption) =>
                                                            otherOption.id ===
                                                            option.id
                                                    )
                                            );

                                        return this.productConfigurationSessionService.updateNodeSelectionMultiple(
                                            relatedOptionsNotDependentOnOtherAnimations
                                                .filter((node) => node.selected)
                                                .map((node) => node.id)
                                        );
                                    }
                                )
                            );
                        }
                    };

                    return this.engineControlService
                        .toggleAnimationState(animationId, animationInterceptor)
                        .pipe(
                            this.errorHandlerService.applyRetryWithHintsOnError(
                                () =>
                                    translate(
                                        'CONFIGURATOR.FEATURES.ANIMATIONS.ERRORS.SET_ANIMATION'
                                    )
                            ),
                            tap((resultingState) => {
                                gtmGA4Track('animation_click', {
                                    active_animation: animationId
                                });
                            }),
                            take(1)
                        );
                })
            )
            .subscribe();
    }
}
