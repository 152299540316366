import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigurationAreaActionsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-area-actions';
import { I18nModule } from '@mhp/ui-shared-services';

import { DisplayVideoTracksModule } from '../display-video-tracks.module';
import { VideoChatDialogModule } from '../video-chat-dialog/video-chat-dialog.module';
import { VideoHeaderContainerComponent } from './video-header-container.component';

@NgModule({
    declarations: [VideoHeaderContainerComponent],
    imports: [
        CommonModule,
        DisplayVideoTracksModule,
        I18nModule,
        ConfigurationAreaActionsModule,
        VideoChatDialogModule
    ],
    exports: [VideoHeaderContainerComponent]
})
export class VideoHeaderContainerModule {}
