import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import {
    TRACK_BY_ID,
    UiBaseComponent,
    UiNavigationEntry
} from '@mhp/ui-components';

@Component({
    selector: 'mhp-sidebar-panel',
    templateUrl: './sidebar-panel.component.html',
    styleUrls: ['./sidebar-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarPanelComponent extends UiBaseComponent {
    readonly trackById = TRACK_BY_ID;

    @Input()
    items: UiNavigationEntry<string>[];

    @Output()
    readonly itemSelect = new EventEmitter<string>();

    constructor(private readonly breakpointObserver: BreakpointObserver) {
        super();

        this.completeOnDestroy(this.itemSelect);
    }

    intentSelectItem(item: UiNavigationEntry<string>) {
        this.itemSelect.emit(item.id);
    }

    @MemoizeObservable()
    isTooltipDisabled$(): Observable<boolean> {
        return this.breakpointObserver
            .observe([Breakpoints.Tablet, Breakpoints.Web])
            .pipe(map((state) => state.matches));
    }
}
