<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<div
    class="info-layer"
    [ngClass]="{
        'info-layer--text-view': item.itemType === 'text',
        'info-layer--image-view': item.itemType === 'image',
        'info-layer--splitter-view': item.itemType === 'material'
    }"
    *transloco="let t; read: 'COMMON'"
>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 5 2"
        class="info-layer__dialog-placeholder info-layer__dialog-placeholder--regular info-layer__dialog-placeholder--5-2"
    ></svg>
    <div class="info-layer__thumbnail-container">
        <ng-container *ngIf="item.itemType === 'image'; else material">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 3 2"
                class="info-layer__thumbnail-placeholder info-layer__thumbnail-placeholder--regular info-layer__thumbnail-placeholder--3-2"
            ></svg>
        </ng-container>
        <ng-template #material>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 7 16"
                class="info-layer__thumbnail-placeholder info-layer__thumbnail-placeholder--splitter info-layer__thumbnail-placeholder--7-16"
            ></svg>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 7"
                class="info-layer__thumbnail-placeholder info-layer__thumbnail-placeholder--splitter info-layer__thumbnail-placeholder--16-7"
            ></svg>
        </ng-template>
        <mhp-ui-lazyload-image
            #image
            [imageFit]="getImageFit$() | async"
            [imageUrls]="thumbnailUrls$ | async"
            [imageFallbackUrl]="
                (thumbnailFallbackUrl$ | async) || image.imageFallbackUrl
            "
            [title]="t('ALT_RENDERING_OF', { renderedItem: item.label })"
            [aspectRatio]="0"
            [willResize]="true"
        >
        </mhp-ui-lazyload-image>
    </div>
    <div class="info-layer__description" *transloco="let t">
        <h2 class="info-layer__header">
            <mhp-ui-icon
                *ngIf="item.isQOption"
                size="xlarge"
                svgIcon="mhp-ui:q-option"
                [matTooltip]="t('ICONS.Q_OPTION_ALT')"
            ></mhp-ui-icon
            >{{ item.label }}
        </h2>
        <p class="info-layer__text" [innerHTML]="item.description"></p>
        <span *ngIf="item.hasAdditionalFittingCost"
            >*&nbsp;{{ t('CONFIGURATOR.INFO_LAYER.ADD_FITTING_COST') }}</span
        >
    </div>
</div>
