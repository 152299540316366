import { CustomError } from '@mhp/common';

/**
 * Error thrown in case an environment is not valid, e.g. does not exist.
 */
export class InvalidEnvironmentError extends CustomError {
    constructor(message: string, details?: unknown) {
        super(message, details);
    }
}
