import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { environment } from '../../environments/environment';
import { ConfigurationMainComponent } from '../configuration/configuration-main/configuration-main.component';
import { ConfigurationResolver } from '../configuration/configuration-main/configuration-resolver';
import { configurationRouteMatcher } from './web-configurator-route-matcher';

export const WEB_CONFIGURATOR_ROUTES: Route[] = [
    {
        matcher: configurationRouteMatcher,
        resolve: { sessionData: ConfigurationResolver },
        canActivate: [ConfigurationResolver],
        canDeactivate: [ConfigurationResolver],
        component: ConfigurationMainComponent
    }
];

/*
 * When having a dealer-build, we load the ConfigurationModule eagerly and CONFIGURATION_ROUTES as children, for a regular build, we lazily load the ConfigurationModule
 */
const LOCAL_CONFIGURATION_ROUTES: Routes = environment.appConfig.dealer
    .dealerBuild
    ? []
    : WEB_CONFIGURATOR_ROUTES;

@NgModule({
    imports: [RouterModule.forChild(LOCAL_CONFIGURATION_ROUTES)],
    exports: [RouterModule]
})
export class WebConfiguratorRoutingModule {}
