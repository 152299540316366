import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { ConfigurationAreaActionsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-area-actions';
import { UiIconButtonModule, UiMatDialogModule } from '@mhp/ui-components';
import { GoogleTagManagerModule } from '@mhp/ui-shared-services';

import { FooterComponent } from './footer.component';

@NgModule({
    declarations: [FooterComponent],
    imports: [
        CommonModule,
        TranslocoModule,
        UiMatDialogModule,
        UiIconButtonModule,
        ConfigurationAreaActionsModule,
        GoogleTagManagerModule
    ],
    exports: [FooterComponent]
})
export class FooterModule {}
