import { A11yModule } from '@angular/cdk/a11y';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigurationAreaActionsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-area-actions';
import {
    UiButtonModule,
    UiGridListModule,
    UiIconButtonModule,
    UiIconsModule,
    UiMenuModule,
    UiStopClickPropagationModule,
    UiTooltipModule
} from '@mhp/ui-components';
import {
    GoogleTagManagerModule,
    I18nModule,
    L10nModule
} from '@mhp/ui-shared-services';

import { VideoPlaybackModule } from '../../common/video-playback/video-playback.module';
import { DealerHelperModule } from '../../dealer/dealer-helper/dealer-helper.module';
import { HeaderBarModule } from '../../header-bar/header-bar.module';
import { UiProductDataModule } from '../../product-data/ui-product-data.module';
import { AmlCodeModule } from '../aml-code/aml-code.module';
import { BasicModule } from '../basic/basic.module';
import { CinematicModule } from '../cinematic/cinematic.module';
import { ConfigurationAreaModule } from '../configuration-area/configuration-area.module';
import { ConfigurationBarCompactModule } from '../configuration-area/configuration-bar-compact/configuration-bar-compact.module';
import { FridgeLabelModule } from '../configuration-area/fridge-label/fridge-label.module';
import { ConfigurationMainLayoutModule } from '../configuration-main-layout/configuration-main-layout.module';
import { BreadcrumbModule } from '../header/breadcrumb/breadcrumb.module';
import { ModelOutdatedModule } from '../model-outdated/model-outdated.module';
import { NodeSelectionHandlerService } from '../services/node-selection-handler.service';
import { SessionInfoDebugModule } from '../session-info/session-info-debug/session-info-debug.module';
import { StreamModule } from '../stream/stream.module';
import { VisualizationModeModule } from '../visualization-mode/visualization-mode.module';
import { ConfigurationMainComponent } from './configuration-main.component';
import { ConfigurationResolver } from './configuration-resolver';

@NgModule({
    declarations: [ConfigurationMainComponent],
    imports: [
        // THIRDPARTY
        CommonModule,
        RouterModule,
        A11yModule,
        PortalModule,

        // PLATFORM
        UiButtonModule,
        UiIconButtonModule,
        UiIconsModule,
        UiGridListModule,
        UiStopClickPropagationModule,
        UiTooltipModule,
        I18nModule,
        L10nModule,
        GoogleTagManagerModule,

        // LOCAL
        SessionInfoDebugModule,
        ConfigurationAreaModule,
        VisualizationModeModule,
        UiMenuModule,
        ConfigurationBarCompactModule,
        HeaderBarModule,
        BreadcrumbModule,
        ConfigurationMainLayoutModule,
        ConfigurationAreaActionsModule,
        FridgeLabelModule,
        AmlCodeModule,
        BasicModule,
        StreamModule,
        DealerHelperModule,
        UiProductDataModule,
        CinematicModule,
        VideoPlaybackModule,
        ModelOutdatedModule
    ],
    providers: [NodeSelectionHandlerService, ConfigurationResolver],
    exports: [ConfigurationMainComponent]
})
export class ConfigurationMainModule {}
