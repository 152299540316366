@if (dealerInfo()) {
    <div class="dealer-info">
        <div>
            <span class="dealer-info__email"
                >{{ dealerInfo()?.email }} //
                {{ dealerInfo()?.accountNumber }}</span
            >
        </div>
        <div>
            <span class="dealer-info__dealership">{{
                dealerInfo()?.knownAs
            }}</span>
        </div>
    </div>
}
