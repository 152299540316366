<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    #dialog
    class="fo-settings-dialog"
    *transloco="let t; read: 'FIN_SERVICES.OFFER_DIALOG'"
    [title]="
        t('TITLE', {
            modelName:
                (labelHelperService.getActiveModelAndProductName$() | async)
        })
    "
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <div class="fo-settings-dialog__container">
            <div class="fo-settings-dialog__container_left">
                <div class="fo-settings-dialog__rendering">
                    <mhp-ui-lazyload-image
                        [imageUrl]="getConfigurationRendering$() | async"
                        [aspectRatio]="3 / 2"
                        [willResize]="true"
                        imageFit="cover"
                    ></mhp-ui-lazyload-image>
                </div>

                <form *ngIf="foFormGroup" [formGroup]="foFormGroup">
                    <mat-form-field>
                        <mat-select
                            formControlName="typeOfFinance"
                            [placeholder]="t('FIELD_FINANCE_TYPE')"
                        >
                            <mat-option [value]="TYPE_OF_FINANCE_NONE">{{
                                t('SELECTION_NONE')
                            }}</mat-option>
                            <mat-option
                                [value]="
                                    FinancingType.PERSONAL_CONTRACT_PURCHASE
                                "
                                >{{ t('SELECTION_PCP') }}</mat-option
                            >
                            <mat-option [value]="FinancingType.HIRE_PURCHASE">{{
                                t('SELECTION_HP')
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select
                            formControlName="termInMonths"
                            [placeholder]="t('FIELD_DURATION')"
                        >
                            <mat-option
                                *ngFor="
                                    let termInMonths of getTermValues$() | async
                                "
                                [value]="termInMonths"
                                >{{ termInMonths }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        *ngIf="
                            foFormGroup?.controls.typeOfFinance.value ===
                            FinancingType.PERSONAL_CONTRACT_PURCHASE
                        "
                    >
                        <mat-select
                            formControlName="mileage"
                            [placeholder]="t('FIELD_ANNUAL_MILEAGE')"
                        >
                            <mat-option
                                *ngFor="
                                    let milesValue of getMileageValues$()
                                        | async
                                "
                                [value]="milesValue"
                                >{{ milesValue | translocoDecimal }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        (click)="intentChangeRateCalculation('MONTHLY_RATE')"
                    >
                        <span matPrefix
                            >{{
                                l10nService.getCurrencySymbol$() | async
                            }}&nbsp;</span
                        >
                        <mat-label>{{ t('FIELD_MONTHLY_PAYMENT') }}</mat-label>
                        <input
                            #monthlyRateInput
                            matInput
                            formControlName="monthlyRate"
                            type="number"
                        />
                        <button
                            matSuffix
                            mat-icon-button
                            *ngIf="foFormGroup.controls.monthlyRate.enabled"
                        >
                            <mat-icon svgIcon="mhp-ui:lock"></mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field
                        (click)="intentChangeRateCalculation('CASH_DEPOSIT')"
                    >
                        <span matPrefix
                            >{{
                                l10nService.getCurrencySymbol$() | async
                            }}&nbsp;</span
                        >
                        <mat-label>{{ t('FIELD_DEPOSIT') }}</mat-label>
                        <input
                            #cashDepositInput
                            matInput
                            formControlName="cashDeposit"
                            type="number"
                        />
                        <button
                            matSuffix
                            mat-icon-button
                            *ngIf="foFormGroup.controls.cashDeposit.enabled"
                        >
                            <mat-icon svgIcon="mhp-ui:lock"></mat-icon>
                        </button>
                    </mat-form-field>
                </form>
            </div>
            <div class="fo-settings-dialog__container_right">
                <div
                    *ngIf="getOfferRows$() | async; else loading"
                    class="fo-settings-dialog__offer-details"
                >
                    <ng-container
                        *ngFor="let offerRow of getOfferRows$() | async"
                    >
                        <div class="fo-settings-dialog__offer-details-label">
                            {{ offerRow.label }}
                        </div>
                        <div class="fo-settings-dialog__offer-details-value">
                            {{ offerRow.value }}
                        </div>
                        <div
                            *ngIf="offerRow.endOfSection"
                            class="fo-settings-dialog__offer-details-separator"
                        ></div>
                    </ng-container>
                </div>
                <ng-template #loading>
                    <div class="fo-settings-dialog__offer-details-loading">
                        <mhp-loading-spinner
                            *ngIf="
                                foFormGroup.controls.typeOfFinance.value !==
                                'NONE'
                            "
                            theme="LIGHT"
                        ></mhp-loading-spinner>
                        <span
                            *ngIf="
                                foFormGroup.controls.typeOfFinance.value ===
                                'NONE'
                            "
                        >
                            {{ t('MSG_NO_OFFER_ACTIVE') }}
                        </span>
                    </div>
                </ng-template>
            </div>
        </div>
    </mhp-ui-mat-dialog-content>
    <ng-container *transloco="let t; read: 'COMMON'">
        <mhp-ui-mat-dialog-actions-left>
            <button
                mat-button
                (click)="dialog.close()"
            >
                <span>{{ t('CANCEL') }}</span>
            </button>
        </mhp-ui-mat-dialog-actions-left>
        <mhp-ui-mat-dialog-actions-right>
            <button
                mat-flat-button
                [disabled]="!foFormGroup?.valid"
                (click)="intentApplySettings()"
            >
                <span>{{ t('CONFIRM') }}</span>
            </button>
        </mhp-ui-mat-dialog-actions-right>
    </ng-container>
</mhp-ui-mat-dialog>
