import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    UiIconsModule,
    UiLazyloadImageModule,
    UiTooltipModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../dialog/dialog.module';
import { StaticRendererModule } from '../static-renderer/static-renderer.module';
import { InfoLayerComponent } from './info-layer.component';
import {
    INFO_LAYER_SERVICE_CONFIG_TOKEN,
    InfoLayerService,
    InfoLayerServiceConfig
} from './info-layer.service';

@NgModule({
    declarations: [InfoLayerComponent],
    imports: [
        CommonModule,
        StaticRendererModule,
        I18nModule,
        UiLazyloadImageModule,
        DialogModule,
        UiIconsModule,
        UiTooltipModule
    ]
})
export class InfoLayerModule {
    static forRoot(
        config?: InfoLayerServiceConfig
    ): ModuleWithProviders<InfoLayerModule> {
        return {
            ngModule: InfoLayerModule,
            providers: [
                InfoLayerService,
                ...(config
                    ? [
                          {
                              provide: INFO_LAYER_SERVICE_CONFIG_TOKEN,
                              useValue: config
                          }
                      ]
                    : [])
            ]
        };
    }
}
