<div class="basic-stage" *transloco="let t">
    <swiper
        #swiper
        *ngIf="
            getAvailableCamerasWithImageSources$()
                | async as availableCamerasWithImageSources
        "
        class="basic-stage__swiper"
        [slidesPerView]="1"
        [spaceBetween]="0"
        [loop]="true"
        [effect]="'fade'"
        [fadeEffect]="{ crossFade: true }"
        [speed]="500"
        [resizeObserver]="true"
        [resistanceRatio]="0.1"
        [followFinger]="true"
        [grabCursor]="true"
        [mousewheel]="{
            sensitivity: 0.7
        }"
        [pagination]="{ el: pagination, clickable: true }"
        (swiper)="onSwiperInitialized($event)"
        (slideChange)="onSlideChange()"
    >
        <ng-container
            *ngFor="
                let cameraWithSource of availableCamerasWithImageSources;
                index as index;
                trackBy: trackById
            "
        >
            <ng-template swiperSlide>
                <mhp-ui-lazyload-image
                    [imageUrl]="cameraWithSource.imageSource"
                    [willResize]="true"
                    [forceStopImageReload]="
                        swiper.swiperRef?.realIndex !== index
                    "
                    [clearPreviousImageOnSourceChange]="
                        swiper.swiperRef?.realIndex !== index
                    "
                    [title]="
                        t('COMMON.ALT_RENDERING_OF', { renderedItem: 'model' })
                    "
                ></mhp-ui-lazyload-image>
            </ng-template>
        </ng-container>
        <div
            #pagination
            slot="container-end"
            class="swiper-pagination basic-stage__swiper-bullets"
            [ngClass]="{
                'basic-stage__swiper-bullets--force-hidden':
                    (arePaginationBulletsForceHidden$() | async) === true
            }"
        ></div>
    </swiper>

    <mhp-ui-icon-button
        class="basic-stage__swiper-nav basic-stage__swiper-nav--prev"
        svgIcon="mhp-ui:angle-left"
        size="medium"
        [matTooltip]="t('ICONS.PREVIOUS_CAMERA_ALT')"
        [label]="t('ICONS.PREVIOUS_CAMERA_ALT')"
        (click)="intentPrevSlide()"
    ></mhp-ui-icon-button>
    <mhp-ui-icon-button
        class="basic-stage__swiper-nav basic-stage__swiper-nav--next"
        svgIcon="mhp-ui:angle-right"
        size="medium"
        [matTooltip]="t('ICONS.NEXT_CAMERA_ALT')"
        [label]="t('ICONS.NEXT_CAMERA_ALT')"
        (click)="intentNextSlide()"
    ></mhp-ui-icon-button>
</div>
