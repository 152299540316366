import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    UiButtonModule,
    UiIconsModule,
    UiInputModule
} from '@mhp/ui-components';
import { GoogleTagManagerModule, I18nModule } from '@mhp/ui-shared-services';

import { DialogModule } from '../../dialog/dialog.module';
import { LoadCodeDialogComponent } from './load-code-dialog/load-code-dialog.component';
import { ShowCodeDialogComponent } from './show-code-dialog/show-code-dialog.component';

@NgModule({
    declarations: [LoadCodeDialogComponent, ShowCodeDialogComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        UiInputModule,
        UiButtonModule,

        DialogModule,
        I18nModule,
        ClipboardModule,
        GoogleTagManagerModule,
        UiIconsModule
    ],
    exports: [LoadCodeDialogComponent, ShowCodeDialogComponent]
})
export class AmlCodeModule {}
