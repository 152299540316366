import { Observable } from 'rxjs';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    Output
} from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { IconSize, UiBaseComponent } from '@mhp/ui-components';

import { DeviceService } from '../../../video-chat/device/device.service';

export interface One2OneAudioVideoSettings {
    audio: boolean;
    video: boolean;
}

@Component({
    selector: 'mhp-audio-video-settings-dialog',
    templateUrl: './audio-video-settings-dialog.component.html',
    styleUrls: ['./audio-video-settings-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioVideoSettingsDialogComponent
    extends UiBaseComponent
    implements OnInit
{
    readonly ICON_SIZE = IconSize;

    audio = true;

    video = true;

    @Output()
    readonly confirm = new EventEmitter<One2OneAudioVideoSettings>();

    constructor(private readonly deviceService: DeviceService) {
        super();

        this.completeOnDestroy(this.confirm);
    }

    ngOnInit() {
        /* TODO: Not sure if this is the correct place to start the device checks (and stop them upon destroy). I think it'll work for now but we should put it in a central place
         * so that possible device-changes are picked up and applied to active streams as well
         */
        this.deviceService
            .startDeviceChecks$()
            .pipe(this.takeUntilDestroy())
            .subscribe();
    }

    @MemoizeObservable()
    showDeviceError$() {
        return this.deviceService.hasPermissionError$();
    }

    intentConfirm() {
        this.confirm.next({
            video: this.video,
            audio: this.audio
        });
    }

    handleServerCallInProgress<T>(source$: Observable<T>) {
        return super.handleServerCallInProgress(source$);
    }
}
