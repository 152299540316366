import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigurationAreaActionsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-area-actions';
import {
    UiButtonModule,
    UiIconsModule,
    UiProgressSpinnerModule
} from '@mhp/ui-components';
import { GoogleTagManagerModule, I18nModule } from '@mhp/ui-shared-services';

import { LoadingIndicatorModule } from '../../common/loading-indicator/loading-indicator.module';
import { SocialSharingModule } from '../../common/social-sharing/social-sharing.module';
import { SpinnerModule } from '../../common/spinner/spinner.module';
import { DialogModule } from '../../dialog/dialog.module';
import { StreamModule } from '../stream/stream.module';
import { CinematicDialogComponent } from './cinematic-dialog/cinematic-dialog.component';
import { CinematicDownloadService } from './cinematic-download.service';
import { CinematicShareDialogComponent } from './cinematic-share-dialog/cinematic-share-dialog.component';

@NgModule({
    declarations: [CinematicDialogComponent, CinematicShareDialogComponent],
    imports: [
        CommonModule,
        DialogModule,
        StreamModule,
        UiButtonModule,
        I18nModule,
        LoadingIndicatorModule,
        A11yModule,
        ConfigurationAreaActionsModule,
        SocialSharingModule,
        SpinnerModule,
        UiProgressSpinnerModule,
        UiIconsModule,
        GoogleTagManagerModule
    ],
    exports: [CinematicDialogComponent, CinematicShareDialogComponent],
    providers: [CinematicDownloadService]
})
export class CinematicModule {}
