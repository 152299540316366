<mhp-fullscreen-dialog [wrapInDialogBody]="true">
    <ng-container
        *transloco="let tCreateOne2One; read: 'ONE_2_ONE.NEW_SESSION'"
    >
        <form
            class="create-one2one-session__form"
            [ngClass]="
                'create-one2one-session__form--' + (getActiveLang$() | async)
            "
            [formGroup]="one2oneFormGroup"
            (ngSubmit)="intentSubmit()"
            *transloco="let tForm; read: 'FORM'"
        >
            <h2>{{ tCreateOne2One('HEADLINE') }}</h2>
            <p>{{ tCreateOne2One('DESCRIPTION') }}</p>

            <section
                class="create-one2one-session__form-block create-one2one-session__form-block--session-info"
            >
                <div
                    class="create-one2one-session__field-group mhp-ui-form-field--full-width"
                >
                    <mat-form-field>
                        <mat-label>{{ tForm('TITLE_LABEL') }}</mat-label>
                        <mat-select formControlName="title">
                            <mat-option
                                *ngFor="let title of availableTitles"
                                [value]="title.key"
                                >{{ title.value }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="create-one2one-session__field-group">
                    <mat-form-field
                        class="mhp-ui-form-field--full-width create-one2one-session__form-field--aside"
                    >
                        <mat-label>{{ tForm('FIRSTNAME_LABEL') }}</mat-label>
                        <input
                            matInput
                            formControlName="firstName"
                            type="text"
                        />
                    </mat-form-field>

                    <mat-form-field
                        class="mhp-ui-form-field--full-width create-one2one-session__form-field--aside create-one2one-session__form-field--lastname"
                    >
                        <mat-label>{{ tForm('LASTNAME_LABEL') }}</mat-label>
                        <input
                            matInput
                            formControlName="lastName"
                            type="text"
                        />
                    </mat-form-field>
                </div>

                <mat-form-field
                    class="mhp-ui-form-field--full-width create-one2one-session__form-field--aside"
                >
                    <mat-label>{{ tForm('EMAIL_LABEL') }}</mat-label>
                    <input
                        matInput
                        formControlName="customerEmail"
                        type="email"
                    />
                </mat-form-field>

                <mat-form-field
                    class="mhp-ui-form-field--full-width create-one2one-session__form-field--aside"
                >
                    <mat-label>{{ tForm('PHONE_LABEL') }}</mat-label>
                    <input
                        matInput
                        formControlName="customerPhone"
                        type="tel"
                    />
                </mat-form-field>

                <div
                    *ngIf="!one2oneFormGroup.controls.scheduleNow.value"
                    class="create-one2one-session__field-group"
                >
                    <mat-form-field
                        class="mhp-ui-form-field--full-width create-one2one-session__form-field--aside"
                    >
                        <mat-label>{{
                            tCreateOne2One('DATE_LABEL')
                        }}</mat-label>
                        <input
                            matInput
                            formControlName="preferredDate"
                            [matDatepicker]="picker"
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="picker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field
                        class="create-one2one-session__form-field--aside"
                    >
                        <mat-label>{{
                            tCreateOne2One('TIME_LABEL')
                        }}</mat-label>
                        <mat-select formControlName="preferredTime">
                            <mat-option
                                *ngFor="
                                    let availableTimeEntry of availableTimeEntries
                                "
                                [value]="availableTimeEntry"
                                >{{
                                    formatTimeEntry(availableTimeEntry)
                                }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="create-one2one-session__field-group">
                    <mat-checkbox
                        color="primary"
                        formControlName="scheduleNow"
                        >{{ tCreateOne2One('START_NOW') }}</mat-checkbox
                    >
                </div>
                <!-- configuration-selector -->
                <div class="create-one2one-session__field-group">
                    <mhp-config-link-editor
                        formControlName="startConfiguration"
                    ></mhp-config-link-editor>
                </div>
            </section>
            <section class="create-one2one-session__form-block">
                <div class="aml-form__actions">
                    <button
                        mat-flat-button
                        class="mhp-ui-button--block mhp-ui-button--full-width mhp-ui-button--highlight"
                        type="submit"
                        [disabled]="serverCallInProgress"
                    >
                        <span>{{ tCreateOne2One('CTA_CREATE_SESSION') }}</span>
                    </button>
                </div>
            </section>
        </form>
    </ng-container>
</mhp-fullscreen-dialog>
