import { first } from 'lodash-es';
import { combineLatest, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { DealerInfoService } from '@mhp/aml-ui-shared-services';
import {
    ApplicationStateService,
    ErrorHandlerService
} from '@mhp/ui-shared-services';
import { Action } from '@ngrx/store';

import { ConfigurationSessionInfoService } from '../configuration/session-info/configuration-session-info.service';
import { AmlProductDataService } from '../product-data/aml-product-data-service';
import { RegionService } from '../settings/region-selector/region.service';
import { LocalApplicationState } from '../state';
import { PricingService } from './pricing/pricing.service';
import { resetDealerInfo, setDealerInfo, setPricingType } from './state';

/**
 * Handles binding dealer-related state to local application state.
 */
@Injectable()
export class DealerStateHandlerService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly msalService: MsalService,
        private readonly msalBroadcastService: MsalBroadcastService,
        private readonly regionService: RegionService,
        private readonly pricingService: PricingService,
        private readonly dealerInfoService: DealerInfoService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly productDataService: AmlProductDataService
    ) {
        this.initBindDealerInfo();
        this.initHandlePricingAvailability();
        this.initBindDealerWatermark();
    }

    private initBindDealerInfo() {
        combineLatest([
            this.msalBroadcastService.inProgress$.pipe(
                filter(
                    (interactionStatus) =>
                        interactionStatus === InteractionStatus.None
                ),
                map(() => first(this.msalService.instance.getAllAccounts())),
                switchMap(
                    async (
                        account
                    ): Promise<
                        | (AccountInfo & {
                              email: string;
                              emailSHA256?: string;
                          })
                        | undefined
                    > => {
                        if (!account) {
                            return undefined;
                        }
                        const accountEmail: string =
                            first((<any>account.idTokenClaims)?.emails) ||
                            'N/A';

                        let emailSHA256: string | undefined;
                        try {
                            emailSHA256 = await this.hashSHA256(
                                accountEmail.toLowerCase()
                            );
                        } catch (error) {
                            console.error('Failed hashing email', error);
                        }

                        return {
                            ...account,
                            email: accountEmail,
                            emailSHA256
                        };
                    }
                )
            ),
            this.dealerInfoService.getActiveDealerInfo$().pipe(
                this.errorHandlerService.applyRetry(),
                catchError(() => of(undefined))
            )
        ]).subscribe(([account, dealerInfo]) => {
            let actionToDispatch: Action;
            if (!account) {
                actionToDispatch = resetDealerInfo();
            } else {
                actionToDispatch = setDealerInfo({
                    dealerInfo: {
                        name: account.name || 'N/A',
                        username: account.username,
                        email: account.email,
                        emailSHA256: account.emailSHA256,
                        knownAs: dealerInfo?.knownAs,
                        countryISO2: dealerInfo?.countryISO2,
                        accountNumber: dealerInfo?.accountNumber
                    }
                });
            }
            this.applicationStateService.dispatch(actionToDispatch);
        });
    }

    private initHandlePricingAvailability() {
        combineLatest([
            this.pricingService.getAvailablePricingTypes$(),
            this.pricingService.getActivePricingType$()
        ])
            .pipe(
                map(([availablePricingTypes, activePricingType]) => {
                    if (availablePricingTypes.includes(activePricingType)) {
                        return activePricingType;
                    }
                    if (availablePricingTypes.includes('DNP')) {
                        return 'DNP';
                    }
                    return availablePricingTypes[0];
                })
            )
            .subscribe((pricingType) => {
                if (!pricingType) {
                    return;
                }

                this.applicationStateService.dispatch(
                    setPricingType({
                        pricingType,
                        settingSource: 'SYSTEM'
                    })
                );
            });
    }

    private initBindDealerWatermark() {
        const dealerWatermark$ = combineLatest([
            this.dealerInfoService.getActiveDealerInfo$().pipe(
                this.errorHandlerService.applyRetry(),
                catchError(() => of(undefined)),
                map((activeDealerInfo) => activeDealerInfo?.knownAs)
            ),
            this.productDataService
                .getActiveProductInfo$()
                .pipe(
                    map(
                        (activeProductInfo) =>
                            activeProductInfo?.isAuthorizedOnly
                    )
                )
        ]).pipe(
            map(([knownAs, isAuthorizedOnly]) =>
                isAuthorizedOnly ? knownAs : undefined
            ),
            startWith(undefined),
            distinctUntilChanged()
        );

        this.configurationSessionInfoService.registerWatermarkSource(
            dealerWatermark$
        );
    }

    /**
     * See https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest for reference.
     * @param input The string to be hashed
     * @private
     */
    private async hashSHA256(input: string) {
        const uint8Array = new TextEncoder().encode(input);
        const hashBuffer = await crypto.subtle.digest('SHA-256', uint8Array);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray
            .map((bytes) => bytes.toString(16).padStart(2, '0'))
            .join('');
    }
}
