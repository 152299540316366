import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UiBaseComponent } from '@mhp/ui-components';

import {
    DialogResult,
    FormGroupChange,
    OrderConfiguration
} from '../model/order-management.models';

@Component({
    selector: 'mhp-form-field-dialog',
    templateUrl: './form-field-dialog.component.html',
    styleUrls: ['./form-field-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldDialogComponent extends UiBaseComponent {
    @Input()
    configData: OrderConfiguration;

    @Input()
    orderFormGroup: FormGroup;

    @Output()
    readonly confirm = new EventEmitter<DialogResult>();

    private result: DialogResult = {};

    constructor(
        public readonly matDialogRef: MatDialogRef<FormFieldDialogComponent>
    ) {
        super();
        this.completeOnDestroy(this.confirm);
    }

    onSelectionChange(formGroupChange: FormGroupChange): void {
        this.result[formGroupChange.label] = {
            id: formGroupChange.id,
            value: this.orderFormGroup.value[formGroupChange.label]
        };
    }

    intentConfirm(): void {
        this.confirm.emit(this.result);
    }

    onCancel(): void {
        this.matDialogRef.close();
    }
}
