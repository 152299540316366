<mhp-fullscreen-dialog [hideTopGradient]="true" [wrapInDialogBody]="false">
    <mhp-stream-stage></mhp-stream-stage>
    <ng-container *transloco="let t">
        <div class="cinematic-dialog__actions">
            <mhp-configuration-area-action-group>
                <mhp-configuration-area-action-item
                    *ngIf="isDownloadCinematicSupported()"
                    class="cinematic-dialog__share-cta"
                    iconSize="large"
                    svgIcon="mhp-ui:share"
                    [disabled]="
                        (featureAvailabilityService.canUseCinematics$()
                            | async) === false
                    "
                    [tooltip]="t('CONFIGURATOR.FEATURES.CINEMATICS.SHARE_ALT')"
                    (itemSelect)="intentShare()"
                >
                </mhp-configuration-area-action-item>
            </mhp-configuration-area-action-group>
        </div>
    </ng-container>
</mhp-fullscreen-dialog>
