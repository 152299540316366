import { Observable, of, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    Signal,
    input
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
    MemoizeObservable,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DebugService } from '../../../../debug/debug.service';
import { ConfigurationSessionImageAssetService } from '../../../assets/configuration-session-image-asset.service';
import { ExtendedUiOptionCode } from '../../../configuration-model/configuration-interfaces';

export interface RenderingVisibilityStateAware {
    renderingVisibilityState: Observable<boolean>;
}

@UntilDestroy()
@Component({
    selector: 'mhp-configuration-code',
    templateUrl: './configuration-code.component.html',
    styleUrls: ['./configuration-code.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[class.configuration-code--disabled]': 'disabled()'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationCodeComponent
    extends UiBaseComponent
    implements RenderingVisibilityStateAware
{
    @Input()
    data: ExtendedUiOptionCode;

    @Input()
    forceInfoAlwaysVisible: boolean;

    @Input()
    readonly: boolean;

    disabled = input(false);

    @Input()
    visibilityRatioReferenceParent?: ElementRef<HTMLElement>;

    @Output()
    readonly selected = new EventEmitter<string>();

    @Output()
    readonly highlighted = new EventEmitter<boolean>();

    @Output()
    readonly infoRequested = new EventEmitter<string>();

    @Output()
    get renderingVisibilityState(): Observable<boolean> {
        return this.observeProperty<
            ConfigurationCodeComponent,
            ElementRef | undefined
        >('visibilityRatioReferenceParent', true).pipe(
            switchMap(
                (referenceParent) =>
                    new Observable<boolean>((observer) => {
                        const intersectionObserver = new IntersectionObserver(
                            (entries: IntersectionObserverEntry[]) => {
                                entries.forEach((entry) => {
                                    observer.next(
                                        entry.isIntersecting &&
                                            entry.intersectionRatio > 0
                                    );
                                });
                            },
                            {
                                root: referenceParent?.nativeElement,
                                threshold: [0, 1]
                            }
                        );

                        intersectionObserver.observe(
                            this.elementRef?.nativeElement
                        );

                        return () => {
                            intersectionObserver.disconnect();
                        };
                    })
            ),
            startWith(false),
            debounceTime(0),
            distinctUntilChanged(),
            untilDestroyed(this)
        );
    }

    @HostBinding('class.hidden')
    get visuallyHidden(): boolean {
        return !this.data || this.data.visuallyHidden;
    }

    @HostBinding('class.configuration-code--image')
    get isImageType(): boolean {
        return this.data?.subType === 'image';
    }

    @HostBinding('class.configuration-code--material')
    get isMaterialType(): boolean {
        return this.data?.subType === 'material';
    }

    @HostBinding('class.configuration-code--text')
    get isTextType(): boolean {
        return this.data?.subType === 'text';
    }

    useDebug: Signal<boolean>;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly amlImageAssetService: ConfigurationSessionImageAssetService,
        private readonly debugService: DebugService
    ) {
        super();

        this.useDebug = toSignal(this.debugService.getDebugState$(), {
            initialValue: false
        });

        this.completeOnDestroy(
            this.selected,
            this.highlighted,
            this.infoRequested
        );
    }

    onNodeSelected(id: string) {
        this.selected.emit(id);
    }

    onHighlighted(status: boolean) {
        // emit highlight-changes only for non-touch devices
        if (this.breakpointObserver.isMatched('(hover: hover)')) {
            this.highlighted.emit(status);
        }
    }

    onShowInfo(id: string) {
        this.infoRequested.next(id);
    }

    @MemoizeObservable()
    getThumbnailImage$() {
        return this.observeProperty<
            ConfigurationCodeComponent,
            ExtendedUiOptionCode | undefined
        >('data', true).pipe(
            distinctUntilChangedEquality(),
            switchMap((data) =>
                data
                    ? this.amlImageAssetService.getOptionThumbnailImageSrc$(
                          data
                      )
                    : of(undefined)
            ),
            lazyShareReplay()
        );
    }
}
