import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';

import { LocalTracksService } from '../../video-chat/local-tracks/local-tracks.service';

@Component({
    selector: 'mhp-local-participant-track',
    templateUrl: './local-participant-track.component.html',
    styleUrls: ['local-participant-track.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalParticipantTrackComponent extends UiBaseComponent {
    constructor(private readonly localTracksService: LocalTracksService) {
        super();
    }

    @MemoizeObservable()
    getLocalTracks$(): Observable<{
        audio: LocalAudioTrack | undefined;
        video: LocalVideoTrack | undefined;
    }> {
        return combineLatest([
            this.localTracksService.getLocalAudioTrack$(),
            this.localTracksService.getLocalVideoTrack$()
        ]).pipe(
            map(([audioTrack, videoTrack]) => ({
                audio: audioTrack,
                video: videoTrack
            }))
        );
    }
}
