<ng-container *transloco="let t; read: 'COMMON'">
    <mhp-ui-lazyload-image
        class="static-renderer__image"
        [imageUrl]="rendererImageSrc$ | async"
        [aspectRatio]="aspectRatio"
        [imageFit]="imageFit"
        [willResize]="true"
        [title]="t('ALT_RENDERING_OF', { renderedItem: 'model' })"
    >
    </mhp-ui-lazyload-image>
</ng-container>
