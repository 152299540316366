import { Observable, combineLatest, interval } from 'rxjs';
import { first, map, startWith, withLatestFrom } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ReservationData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/reservation-data.js';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'mhp-start-session',
    templateUrl: './start-session.component.html',
    styleUrls: ['./start-session.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartSessionComponent extends UiBaseComponent implements OnInit {
    @Input()
    reservationData$: Observable<ReservationData>;

    @Output()
    readonly startSession = new EventEmitter<ReservationData>();

    startDate$: Observable<Date>;

    joinDate$: Observable<Date>;

    constructor() {
        super();

        this.completeOnDestroy(this.startSession);
    }

    ngOnInit() {
        this.joinDate$ = this.reservationData$.pipe(
            map((reservationData) => new Date(reservationData.startTime)),
            lazyShareReplay()
        );
        this.startDate$ = this.joinDate$.pipe(
            map(
                (joinDate) =>
                    new Date(
                        joinDate.getTime() +
                            environment.appConfig.one2one
                                .startSessionMinutesDiff *
                                60 *
                                1000
                    )
            ),
            lazyShareReplay()
        );
    }

    intentStartSession() {
        this.reservationData$
            .pipe(first(), this.takeUntilDestroy())
            .subscribe((reservationData) =>
                this.startSession.next(reservationData)
            );
    }

    @MemoizeObservable()
    getMinutesUntilStart$() {
        return interval(1000).pipe(
            startWith(0),
            withLatestFrom(this.joinDate$),
            map(([counter, startDate]) => {
                const now = new Date();
                const start = startDate;

                const diffMillis = start.getTime() - now.getTime();
                return Math.ceil(diffMillis / 1000 / 60);
            }),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    canStart$() {
        return combineLatest([
            this.reservationData$,
            this.getMinutesUntilStart$()
        ]).pipe(
            map(
                ([reservationData, secondsUntilStart]) =>
                    reservationData && secondsUntilStart <= 0
            ),
            startWith(false)
        );
    }

    @MemoizeObservable()
    hasSession$() {
        return this.reservationData$.pipe(
            map((reservationData) => !!reservationData.sessionId)
        );
    }
}
