import { Observable, switchMap } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    CinematicCaptureInfo,
    EngineControlService,
    downloadFile,
    gtmGA4Track
} from '@mhp/ui-shared-services';

import { LabelHelperService } from '../../i18n/label-helper.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';

@Injectable()
export class CinematicDownloadService {
    constructor(
        private readonly engineControlService: EngineControlService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly labelHelperService: LabelHelperService
    ) {}

    /**
     * Request a cinematic download.
     * Unsubscribing from the stream cancels the encoding process.
     *
     * @param cinematicId Cinematic ID to be captured
     *
     * @return Observable emitting void when download has been initiated.
     */
    requestDownloadCinematic$(
        cinematicId: string
    ): Observable<CinematicCaptureInfo> {
        return this.engineControlService
            .captureCinematic$(cinematicId)
            .pipe(
                switchMap((captureInfo) => this.downloadCinematic$(captureInfo))
            );
    }

    /**
     * Initiates a download for a captured cinematic.
     * @param captureInfo The info about the captured cinematic.
     */
    downloadCinematic$(
        captureInfo: CinematicCaptureInfo
    ): Observable<CinematicCaptureInfo> {
        return this.labelHelperService.getActiveModelAndProductName$().pipe(
            take(1),
            tap((derivativeName) => {
                downloadFile(
                    captureInfo.url,
                    `Your-${derivativeName ?? 'Cinematic'}.mp4`
                );

                gtmGA4Track('cinematic_download_success');
            }),
            map(() => captureInfo)
        );
    }
}
