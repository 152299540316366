import { Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Animation } from '@mhp-immersive-exp/contracts/src';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import {
    Environment,
    EnvironmentLightingProfileState
} from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { AmlOptionMetadata } from '@mhp/aml-shared/configuration/aml-option-metadata';
import { RATIO_OPTION_THUMBNAIL } from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import { ImageSrcset } from '@mhp/ui-components';
import {
    ProductConfigurationService,
    ProductDataService,
    StaticAssetService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { CODE_ROOF_CLOSED } from '../car-features/car-feature-control.service';
import { ExtendedUiOptionCode } from '../configuration-model/configuration-interfaces';
import { isExtendedUiOptionCode } from '../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import {
    IodRenderingAdjustments,
    IodRenderingOptions,
    IodSrcsetRenderingOptions,
    StaticRendererService
} from '../static-renderer/static-renderer.service';

interface OptionThumbnailInfo {
    productId: string;
    optionCode: string;
    thumbnailPrefix: string;
}

@Injectable()
export class ConfigurationSessionImageAssetService {
    constructor(
        private readonly staticAssetService: StaticAssetService,
        private readonly staticRendererService: StaticRendererService,
        private readonly productDataService: ProductDataService,
        private readonly productConfigurationService: ProductConfigurationService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService
    ) {}

    /**
     * Return an Observable emitting the image-thumbnail that's valid taken into account context parameters like
     * - productId
     * - currentConfiguration (context-dependent thumbnails)
     * @param optionCode The optionCode to create the matching Observable-stream for.
     * @param options Optional iod rendering options
     * @param iodRenderingAdjustments Optional rendering adjustments
     */
    getOptionThumbnailImageSrc$(
        optionCode: ExtendedUiOptionCode,
        options?: IodSrcsetRenderingOptions,
        iodRenderingAdjustments?: Pick<
            IodRenderingAdjustments,
            'dayNightState' | 'overrideEnvironment' | 'overrideCamera'
        >
    ): Observable<ImageSrcset | string | undefined> {
        const { code, subType, image, meta } = optionCode;
        if (image) {
            return this.getExplicitOptionImageThumbnailImage$(image);
        }
        if (subType === 'material') {
            return this.getSplitterUrl$(code, meta);
        }
        if (subType === 'image' || iodRenderingAdjustments?.overrideCamera) {
            return this.getOptionThumbnail$(
                optionCode,
                options,
                iodRenderingAdjustments
            );
        }
        return of(undefined);
    }

    private getSplitterUrl$(
        code: string,
        codeMeta?: AmlOptionMetadata
    ): Observable<string | ImageSrcset | undefined> {
        return this.getThumbnailInfo$(code, codeMeta).pipe(
            map((thumbnailInfo) => {
                if (!thumbnailInfo) {
                    return undefined;
                }
                return this.staticAssetService.getOptionMaterialSplitterSrcsetWithCustomFilenameBase(
                    () =>
                        `${thumbnailInfo.thumbnailPrefix}${thumbnailInfo.optionCode}`
                );
            })
        );
    }

    /**
     * Emits the ImageSrcset required for usage in context for rendering an option thumbnail.
     * @param code The code for which to build the thumbnail url.
     * @param options Optional iod rendering options
     * @param iodRenderingAdjustments Optional rendering adjustments
     */
    private getOptionThumbnail$(
        code: ExtendedUiOptionCode,
        options?: IodSrcsetRenderingOptions,
        iodRenderingAdjustments?: Pick<
            IodRenderingAdjustments,
            'dayNightState' | 'overrideEnvironment' | 'overrideCamera'
        >
    ): Observable<ImageSrcset | undefined> {
        const { meta, beautyshot } = code;

        if (
            environment.appConfig.featureToggles.renderOptionIodThumbnails &&
            (beautyshot?.cameraId || iodRenderingAdjustments?.overrideCamera)
        ) {
            // provide an IOD based thumbnail

            // check if a group that has 'adviseRoofClosed' set is part of the parent-chain of the given option
            const requiresRoofClosed$: Observable<boolean> =
                this.productConfigurationSessionService.getOptionGroups$().pipe(
                    map((optionGroups) => {
                        if (!optionGroups) {
                            return undefined;
                        }
                        const pathToOptionCode = this.nodeLookupService
                            .getNodePathToNode(
                                (node) =>
                                    isExtendedUiOptionCode(node) &&
                                    node.id === code.id,
                                optionGroups
                            )
                            ?.map((node) => node.id);

                        return pathToOptionCode
                            ? this.nodeLookupService.collectNodes(
                                  (node) =>
                                      pathToOptionCode?.indexOf(node.id) > -1,
                                  optionGroups
                              )
                            : undefined;
                    }),
                    map((collectedGroups) => {
                        if (!collectedGroups) {
                            return false;
                        }
                        return !!collectedGroups?.find(
                            (group) => group.meta?.adviseRoofClosed === true
                        );
                    }),
                    distinctUntilChanged()
                );

            return requiresRoofClosed$.pipe(
                switchMap((requiresRoofClosed) => {
                    const forceOptionsOn: ConfigModel[] = [code.configModel];

                    if (requiresRoofClosed) {
                        forceOptionsOn.push(CODE_ROOF_CLOSED);
                    }

                    return this.getAdjustedSessionRenderingSrcset$(
                        {
                            alternativeResolutions:
                                options?.alternativeResolutions ??
                                environment.appConfig.assets
                                    .previewThumbnailSizes,
                            alternativeAspectRatio:
                                options?.alternativeAspectRatio ??
                                RATIO_OPTION_THUMBNAIL
                        },
                        {
                            forceAnimationsOff: true,
                            dayNightState:
                                iodRenderingAdjustments?.dayNightState ??
                                EnvironmentLightingProfileState.DAY,
                            overrideCamera:
                                iodRenderingAdjustments?.overrideCamera ??
                                beautyshot?.cameraId,
                            overrideEnvironment:
                                iodRenderingAdjustments?.overrideEnvironment ??
                                environment.appConfig.assets
                                    .optionIodThumbnailsDefaultEnvironment,
                            forceOptionsOn
                        }
                    );
                })
            );
        }

        // provide a static thumbnail
        return this.getThumbnailInfo$(code.code, meta).pipe(
            map((thumbnailInfo) => {
                if (!thumbnailInfo) {
                    return undefined;
                }

                return this.staticAssetService.getOptionThumbnailSrcsetWithCustomFilenameBase(
                    thumbnailInfo.productId,
                    () =>
                        `${thumbnailInfo.thumbnailPrefix}${thumbnailInfo.optionCode}`
                );
            })
        );
    }

    /**
     * Get a stream emitting the session-based rendering for the given animation.
     * @param animation The animation to build the thumbnail for
     * @param options Optional rendering options
     * @param renderingAdjustments Optional rendering adjustments.
     */
    getAnimationThumbnail$(
        animation: Animation,
        options?: IodRenderingOptions,
        renderingAdjustments?: Pick<
            IodRenderingAdjustments,
            'overrideEnvironment'
        >
    ): Observable<ImageSrcset | undefined> {
        return this.getAdjustedSessionRenderingSrcset$(
            {
                alternativeResolutions:
                    environment.appConfig.assets.previewThumbnailSizes,
                ...options
            },
            {
                ...renderingAdjustments,
                forceAnimationsOff: true,
                overrideAnimation: animation.id,
                dayNightState: EnvironmentLightingProfileState.DAY,
                overrideCamera: animation.thumbCam
            }
        );
    }

    /**
     * Get a stream emitting the session-based rendering for the given environment.
     * @param environmentMeta The environment to build the thumbnail for
     * @param options Optional rendering options
     */
    getEnvironmentThumbnail$(
        environmentMeta: Environment,
        options?: IodRenderingOptions
    ): Observable<ImageSrcset | undefined> {
        return this.getAdjustedSessionRenderingSrcset$(
            {
                alternativeResolutions:
                    environment.appConfig.assets.previewThumbnailSizes,
                ...options
            },
            {
                forceAnimationsOff: true,
                dayNightState: EnvironmentLightingProfileState.DAY,
                overrideEnvironment: environmentMeta.id,
                overrideCamera:
                    environmentMeta.thumbCam ??
                    environment.appConfig.visualization
                        .defaultEnvironmentThumbnailCamera
            }
        );
    }

    /**
     * Get a stream emitting the session-based rendering for the given camera.
     * @param cameraId The camera to build the thumbnail for
     * @param options Optional rendering options
     */
    getCameraThumbnail$(
        cameraId: string,
        options?: IodRenderingOptions
    ): Observable<ImageSrcset | undefined> {
        return this.getAdjustedSessionRenderingSrcset$(
            {
                alternativeResolutions:
                    environment.appConfig.assets.previewThumbnailSizes,
                ...options
            },
            {
                forceAnimationsOff: true,
                dayNightState: EnvironmentLightingProfileState.DAY,
                overrideCamera: cameraId
            }
        );
    }

    private getExplicitOptionImageThumbnailImage$(
        image: string
    ): Observable<string | undefined> {
        return this.productConfigurationSessionService
            .getConfigurationInfo$()
            .pipe(
                map((info) => info?.productId),
                map((productId) =>
                    productId
                        ? this.staticAssetService.getOptionThumbnailImageUrl(
                              image,
                              productId
                          )
                        : undefined
                )
            );
    }

    private getAdjustedSessionRenderingSrcset$(
        iodRenderingOptions: IodSrcsetRenderingOptions,
        iodRenderingAdjustments: IodRenderingAdjustments
    ) {
        return this.staticRendererService.getActiveSessionRenderingSrcset$(
            iodRenderingOptions,
            iodRenderingAdjustments
        );
    }

    /**
     * Returns information for the thumbnail-rendering-context of a given optionCode.
     * @param optionCode
     * @param optionMeta
     */
    private getThumbnailInfo$(
        optionCode: string,
        optionMeta?: AmlOptionMetadata
    ): Observable<OptionThumbnailInfo | undefined> {
        return this.productConfigurationSessionService
            .getConfigurationInfo$()
            .pipe(
                map((activeConfiguration): OptionThumbnailInfo | undefined => {
                    if (!activeConfiguration) {
                        return undefined;
                    }

                    const { productId } = activeConfiguration;

                    if (!optionMeta?.thumbnailContextOptions) {
                        if (!optionMeta?.thumbnailPrefix) {
                            return {
                                productId,
                                optionCode,
                                thumbnailPrefix: ''
                            };
                        }

                        return {
                            productId,
                            optionCode,
                            thumbnailPrefix: optionMeta.thumbnailPrefix
                        };
                    }

                    // check context options
                    const thumbnailPrefix =
                        optionMeta.thumbnailContextOptions.find(
                            (contextOption) =>
                                this.nodeLookupService.findNode(
                                    (node) =>
                                        isExtendedUiOptionCode(node) &&
                                        node.code ===
                                            contextOption.optionCode &&
                                        node.selected,
                                    activeConfiguration.configuration
                                )
                        )?.thumbnailPrefix;

                    return {
                        productId,
                        optionCode,
                        thumbnailPrefix:
                            thumbnailPrefix || optionMeta.thumbnailPrefix || ''
                    };
                })
            );
    }
}
