import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiNotificationModule } from '@mhp/ui-components';

import { FinancialServicesModule } from '../configuration/financial-services/financial-services.module';
import { OrderManagementModule } from '../order-management/order-management.module';
import { VideoChatService } from '../video-chat/video-chat.service';
import { DealerAuthModule } from './dealer-auth.module';
import { DEALER_HOOKS_TOKEN } from './dealer-hooks/dealer-hooks.interface';
import { DealerHooksService } from './dealer-hooks/dealer-hooks.service';
import { DealerStateHandlerService } from './dealer-state-handler.service';
import { DealerStatusNotificationService } from './dealer-status-notification.service';
import { NavigationSyncService } from './navigation/navigation-sync.service';
import { One2oneSessionBookingService } from './one2one/one2one-session-booking.service';
import { One2oneStateSyncService } from './one2one/one2one-state-sync.service';
import { One2oneModule } from './one2one/one2one.module';
import { PricingService } from './pricing/pricing.service';
import { DealerSalesforceIonModule } from './salesforce-ion/dealer-salesforce-ion.module';
import { SalesforceModule } from './salesforce/salesforce.module';

/**
 * This is the full-blown dealer module being used for dealer ui-builds.
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        DealerAuthModule,
        SalesforceModule,
        DealerSalesforceIonModule,
        One2oneModule,
        UiNotificationModule,
        OrderManagementModule,
        FinancialServicesModule.forRoot()
    ],
    providers: [
        DealerStateHandlerService,
        DealerStatusNotificationService,
        {
            provide: DEALER_HOOKS_TOKEN,
            useClass: DealerHooksService
        },
        One2oneSessionBookingService,
        One2oneStateSyncService,
        VideoChatService
    ]
})
export class DealerModule {
    constructor(
        // eager init
        private readonly dealerStateHandlerService: DealerStateHandlerService,
        private readonly dealerStatusNotificationService: DealerStatusNotificationService,
        private readonly pricingService: PricingService,
        private readonly navigationSyncService: NavigationSyncService,
        private readonly one2oneStateSyncService: One2oneStateSyncService
    ) {}
}
