<ng-container *ngVariable="getLocalTracks$() | async as localTracks">
    <ng-container *ngIf="localTracks">
        <mhp-attach-video-track
            [videoTrack]="localTracks.video"
            [audioTrack]="localTracks.audio"
            [showGradient]="true"
        ></mhp-attach-video-track>
    </ng-container>
    <mhp-video-placeholder *ngIf="!localTracks?.video"></mhp-video-placeholder>
</ng-container>
