import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    UiButtonModule,
    UiIconsModule,
    UiTabsModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { LoadingIndicatorModule } from '../../common/loading-indicator/loading-indicator.module';
import { StaticRendererModule } from '../../configuration/static-renderer/static-renderer.module';
import { DialogModule } from '../../dialog/dialog.module';
import { SalesforceModule } from '../salesforce/salesforce.module';
import { DealerSalesforceIonComponent } from './dealer-salesforce-ion.component';

@NgModule({
    declarations: [DealerSalesforceIonComponent],
    imports: [
        CommonModule,
        I18nModule,
        StaticRendererModule,
        UiButtonModule,
        DialogModule,
        UiTabsModule,
        SalesforceModule,
        LoadingIndicatorModule,
        UiIconsModule
    ],
    exports: [DealerSalesforceIonComponent]
})
export class DealerSalesforceIonModule {}
