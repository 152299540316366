import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { OverflowIndicatorModule } from '@mhp/aml-ui-shared-components/common/overflow-indicator';
import { ConfigurationElementsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import { EngineSettingsModule } from '@mhp/aml-ui-shared-components/configuration/engine-settings';
import { ExpandableCategoryBarModule } from '@mhp/aml-ui-shared-components/configuration/expandable-category-bar';
import {
    UiButtonModule,
    UiGridListModule,
    UiIconButtonModule,
    UiIconsModule,
    UiPreventNestedRippleModule,
    UiSelectModule,
    UiTabsModule,
    UiTooltipModule
} from '@mhp/ui-components';
import {
    GoogleTagManagerModule,
    I18nModule,
    ProductConfigurationModule
} from '@mhp/ui-shared-services';

import { FooterModule } from '../../common/footer/footer.module';
import { LoadingIndicatorModule } from '../../common/loading-indicator/loading-indicator.module';
import { SearchModule } from '../../common/search/search.module';
import { ConfigurationSummaryModule } from '../configuration-summary/configuration-summary.module';
import { ConfigurationBarComponent } from './configuration-bar/configuration-bar.component';
import { ConfigurationOptionsModule } from './configuration-options/configuration-options.module';
import { EditionsModule } from './editions/editions.module';
import { EnvironmentSelectionModule } from './environment-selection/environment-selection.module';
import { CameraStateHandlerFactoryService } from './helpers/camera/camera-state-handler-factory.service';
import { LoadModelYearDialogModule } from './load-model-year-dialog/load-model-year-dialog.module';

@NgModule({
    declarations: [ConfigurationBarComponent],
    exports: [ConfigurationBarComponent, EngineSettingsModule],
    imports: [
        CommonModule,
        CdkScrollableModule,

        ConfigurationOptionsModule,
        OverflowIndicatorModule,
        EnvironmentSelectionModule,
        EditionsModule,

        UiTabsModule,
        UiButtonModule,
        UiIconButtonModule,
        UiGridListModule,
        UiIconsModule,

        I18nModule,
        ProductConfigurationModule,
        RouterModule,
        SearchModule,
        ConfigurationElementsModule,
        ConfigurationSummaryModule,
        UiSelectModule,
        LoadingIndicatorModule,
        FooterModule,
        LoadModelYearDialogModule,
        ExpandableCategoryBarModule,
        MatRippleModule,
        EngineSettingsModule,
        UiPreventNestedRippleModule,
        GoogleTagManagerModule,
        UiTooltipModule
    ],
    providers: [CameraStateHandlerFactoryService]
})
export class ConfigurationAreaModule {}
