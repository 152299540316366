import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';

import {
    ExtendedUiNestedCode,
    ExtendedUiOptionCode,
    ExtendedUiOptionList
} from '../../../configuration-model/configuration-interfaces';

@Component({
    selector: 'mhp-configuration-list',
    templateUrl: './configuration-list.component.html',
    styleUrls: ['./configuration-list.component.scss']
})
export class ConfigurationListComponent extends UiBaseComponent {
    @Input()
    list: ExtendedUiOptionList;

    optionSelectionDisabled = input<boolean>(false);

    @Output()
    readonly intentSelect = new EventEmitter<string>();

    constructor() {
        super();
    }

    @MemoizeObservable()
    getOptions$(): Observable<ExtendedUiOptionCode[] | undefined> {
        return this.observeProperty<
            ConfigurationListComponent,
            ExtendedUiOptionList
        >('list', true).pipe(
            distinctUntilChanged(isEqual),
            map((list) => {
                const nestedOptions = list.content.reduce(
                    (allOptions, currentNestedCode: ExtendedUiNestedCode) => {
                        const nestedCodeOptions = currentNestedCode.content;
                        return [...allOptions, ...nestedCodeOptions];
                    },
                    []
                );
                return nestedOptions;
            })
        );
    }

    intentSelectItem(itemId: string) {
        this.intentSelect.emit(itemId);
    }
}
