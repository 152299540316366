<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    class="load-model-year-dialog"
    *transloco="let t"
    [title]="t('CONFIGURATOR.MODEL_YEAR_CONFLICT.DIALOG.TITLE')"
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <p>{{ t('CONFIGURATOR.MODEL_YEAR_CONFLICT.DIALOG.DESCRIPTION') }}</p>
    </mhp-ui-mat-dialog-content>

    <mhp-ui-mat-dialog-actions-left>
        <button
            mat-button
            class="mhp-ui-button--full-width"
            (click)="intentLoadCode('RESET')"
            [trackGA4Event]="{
                event: 'old_model_year_start_new_configuration'
            }"
        >
            <span>{{
                t('CONFIGURATOR.MODEL_YEAR_CONFLICT.DIALOG.CTA_DISCARD')
            }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-left>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            class="mhp-ui-button--full-width"
            (click)="intentLoadCode('UPGRADE')"
            [trackGA4Event]="{
                event: 'old_model_year_apply_config_to_new_model_year'
            }"
        >
            {{ t('CONFIGURATOR.MODEL_YEAR_CONFLICT.DIALOG.CTA_APPLY_TO_NEW') }}
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
