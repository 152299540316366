import { EMPTY, Observable, asyncScheduler, combineLatest } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    switchMap,
    tap,
    throttleTime
} from 'rxjs/operators';

import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    TemplateRef
} from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-overflow-indicator',
    templateUrl: './overflow-indicator.component.html',
    styleUrls: ['./overflow-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverflowIndicatorComponent extends UiBaseComponent {
    @Input()
    prevTemplate: TemplateRef<unknown>;

    @Input()
    nextTemplate: TemplateRef<unknown>;

    @Input()
    scrollableElementRef: HTMLElement;

    @Input()
    pageWidthCorrection = 0;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly scrollDispatcher: ScrollDispatcher
    ) {
        super();
    }

    @MemoizeObservable()
    showOverflowIndicator$(from: 'left' | 'right'): Observable<boolean> {
        const relatedScrollable = this.getRelatedScrollable();
        return combineLatest([
            this.scrollDispatcher
                .ancestorScrolled(this.scrollableElementRef)
                .pipe(
                    startWith(relatedScrollable),
                    filter(
                        (cdkScrollable): cdkScrollable is CdkScrollable =>
                            !!cdkScrollable
                    )
                ),
            this.getResizeChange$().pipe(startWith(void 0)),
            this.getContentChanges$().pipe(startWith(void 0))
        ]).pipe(
            throttleTime(20, asyncScheduler, {
                trailing: true
            }),
            map(([cdkScrollable]) =>
                cdkScrollable ? cdkScrollable.measureScrollOffset(from) : 0
            ),
            startWith(relatedScrollable?.measureScrollOffset(from) || 0),
            map(
                (offset) =>
                    (from === 'left'
                        ? Math.round(offset)
                        : Math.floor(offset)) >= 1
            ),
            distinctUntilChanged(),
            tap(() => {
                // force CD after value has been emitted to the view
                setTimeout(() => {
                    this.changeDetectorRef.detectChanges();
                });
            })
        );
    }

    intentShowPage(direction: 'prev' | 'next') {
        const relatedScrollable = this.getRelatedScrollable();
        if (!relatedScrollable) {
            console.warn('Missing scrollable reference');
            return;
        }

        let offsetLeft = relatedScrollable.measureScrollOffset('left');
        const relatedScrollableHtmlElement =
            relatedScrollable.getElementRef().nativeElement;
        const pageWidth = relatedScrollableHtmlElement.clientWidth;
        if (direction === 'prev') {
            offsetLeft -= pageWidth - this.pageWidthCorrection;
        } else {
            offsetLeft += pageWidth - this.pageWidthCorrection;
        }

        relatedScrollableHtmlElement.scroll({
            left: offsetLeft,
            behavior: 'smooth'
        });
    }

    private getRelatedScrollable() {
        return this.scrollDispatcher
            .getAncestorScrollContainers(this.scrollableElementRef)
            .find(
                (scrollable) =>
                    scrollable.getElementRef().nativeElement ===
                    this.scrollableElementRef
            );
    }

    @MemoizeObservable()
    private getContentChanges$(): Observable<void> {
        return this.observeProperty<OverflowIndicatorComponent, HTMLElement>(
            'scrollableElementRef'
        ).pipe(
            switchMap((htmlElement) => {
                return new Observable<void>((subscriber) => {
                    const mutationObserver = new MutationObserver(() => {
                        subscriber.next(void 0);
                    });

                    mutationObserver.observe(htmlElement, {
                        childList: true
                    });

                    return () => {
                        mutationObserver.disconnect();
                    };
                });
            }),
            catchError((error) => {
                console.error(
                    'Failed initializing MutationObserver for overflow-indicator. This may result in indicators not being visible in the beginning.',
                    error
                );
                return EMPTY;
            }),
            this.takeUntilDestroy(),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    private getResizeChange$(): Observable<void> {
        return this.observeProperty<OverflowIndicatorComponent, HTMLElement>(
            'scrollableElementRef'
        ).pipe(
            switchMap((htmlElement) => {
                return new Observable<void>((subscriber) => {
                    const resizeObserver = new ResizeObserver(() => {
                        subscriber.next(void 0);
                    });

                    resizeObserver.observe(htmlElement);

                    return () => {
                        resizeObserver.unobserve(htmlElement);
                    };
                });
            }),
            catchError((error) => {
                console.error(
                    'Failed initializing ResizeObserver for overflow-indicator. This may result in indicators not being visible in the beginning.',
                    error
                );
                return EMPTY;
            }),
            this.takeUntilDestroy(),
            lazyShareReplay()
        );
    }
}
