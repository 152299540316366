import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'mhp-copy-configuration-dialog',
    templateUrl: './copy-configuration-dialog.component.html',
    styleUrls: ['./copy-configuration-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyConfigurationDialogComponent {
    protected readonly numberOfCopiesFormControl = new FormControl(1, {
        nonNullable: true,
        validators: [Validators.required, Validators.min(1), Validators.max(10)]
    });

    constructor(
        protected readonly matDialogRef: MatDialogRef<CopyConfigurationDialogComponent>
    ) {}

    intentConfirm() {
        if (!this.numberOfCopiesFormControl.valid) {
            return;
        }

        this.matDialogRef.close(this.numberOfCopiesFormControl.value);
    }
}
