import { first, flatten, isString, last } from 'lodash-es';

import { Injectable } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';

import {
    ExtendedUiConfigurationMetaItem,
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiOptionCode
} from '../configuration-model/configuration-interfaces';
import {
    isCodeAware,
    isContentAware,
    isExtendedUiNestedCode,
    isExtendedUiOptionCode
} from './configuration-helper';

export interface NodeWithParent<
    T extends ExtendedUiConfigurationMetaItem = ExtendedUiConfigurationMetaItem
> {
    node: T;
    parent: ExtendedUiContentAwareConfigurationMetaItem | undefined;
}

type TypedConfigurationNodeFinder<T extends ExtendedUiConfigurationMetaItem> = (
    node: ExtendedUiConfigurationMetaItem,
    parents: readonly ExtendedUiContentAwareConfigurationMetaItem[] | undefined
) => node is T;

/**
 * Type-Definition for both a typed callback function and an untyped callback function.
 */
type ConfigurationNodeFinder<T extends ExtendedUiConfigurationMetaItem> =
    | TypedConfigurationNodeFinder<T>
    | ((
          node: ExtendedUiConfigurationMetaItem,
          parents:
              | readonly ExtendedUiContentAwareConfigurationMetaItem[]
              | undefined
      ) => boolean);

/**
 * Provides the ability to lookup configuration-nodes by their ID
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigurationNodeLookupService {
    /**
     * Find a node using its ID.
     * @param id The nodes ID.
     * @param contentAwares The option groups to search in.
     */
    findNodeById(
        id: string,
        contentAwares: ExtendedUiContentAwareConfigurationMetaItem[]
    ): NodeWithParent | undefined {
        return this.findNode(
            (node): node is ExtendedUiConfigurationMetaItem => node.id === id,
            contentAwares
        );
    }

    /**
     * Find a node using a custom finder.
     * @param finder The function used to select a node
     * @param contentAwares The option groups to search in.
     */
    findNode<
        T extends ExtendedUiConfigurationMetaItem = ExtendedUiConfigurationMetaItem
    >(
        finder: ConfigurationNodeFinder<T>,
        contentAwares: ExtendedUiContentAwareConfigurationMetaItem[]
    ): NodeWithParent<T> | undefined {
        for (const contentAware of contentAwares) {
            const foundNode = this.findNodeInContent(
                finder as TypedConfigurationNodeFinder<T>,
                contentAware
            );
            if (foundNode) {
                return foundNode;
            }
        }

        return undefined;
    }

    /**
     * Collect all nodes for which the matcher returns true.
     * @param finder Matcher to use
     * @param contentAwares The content to search in.
     */
    collectNodes<
        T extends ExtendedUiConfigurationMetaItem = ExtendedUiConfigurationMetaItem
    >(
        finder: ConfigurationNodeFinder<T>,
        contentAwares: ExtendedUiContentAwareConfigurationMetaItem[]
    ): T[] {
        const matchedNodes: T[] = [];
        for (const contentAware of contentAwares) {
            matchedNodes.push(
                ...this.collectNodesInContent(
                    finder as TypedConfigurationNodeFinder<T>,
                    undefined,
                    contentAware
                )
            );
        }
        return matchedNodes;
    }

    /**
     * Collect all nodes for which the matcher returns true.
     * @param matcher Matcher to use
     * @param contentAwares The content to search in.
     */
    collectNodesWithParent<
        T extends ExtendedUiConfigurationMetaItem = ExtendedUiConfigurationMetaItem
    >(
        matcher: ConfigurationNodeFinder<T>,
        contentAwares: ExtendedUiContentAwareConfigurationMetaItem[]
    ): NodeWithParent<T>[] {
        const matchedNodes: NodeWithParent<T>[] = [];
        for (const contentAware of contentAwares) {
            for (const node of this.collectNodesInContentWithParent(
                matcher as TypedConfigurationNodeFinder<T>,
                undefined,
                contentAware,
                undefined
            )) {
                matchedNodes.push(node);
            }
        }
        return matchedNodes;
    }

    /**
     * Finds the path to a node identified by finder in order [node, ...., root-node]
     * @param finder Callback to determine the target node
     * @param contentAwares The starting node to look into
     */
    getNodePathToNode(
        finder: (node: ExtendedUiConfigurationMetaItem) => boolean,
        contentAwares: ExtendedUiContentAwareConfigurationMetaItem[] | undefined
    ) {
        if (!contentAwares) {
            return undefined;
        }

        for (const contentAware of contentAwares) {
            const path = [];

            const foundPath = this.getNodePathInContent(
                finder,
                contentAware,
                path
            );
            if (foundPath) {
                return foundPath;
            }
        }

        return undefined;
    }

    /**
     * Find nodes by passing in entries from the ConfigModel.
     * @param configModelEntries
     * @param contentAwares
     */
    findNodesByConfigModelEntries(
        configModelEntries: readonly ConfigModel[],
        contentAwares: ExtendedUiContentAwareConfigurationMetaItem[]
    ): ExtendedUiOptionCode[] {
        const foundNodes: ExtendedUiOptionCode[] = [];

        for (const configModelEntry of configModelEntries) {
            if (isString(configModelEntry)) {
                // find by option-code
                const foundNode = this.findNode(
                    (node, parents): node is ExtendedUiOptionCode => {
                        const directParent = last(parents);
                        return (
                            isExtendedUiOptionCode(node) &&
                            (!directParent ||
                                !isExtendedUiNestedCode(directParent)) &&
                            node.code === configModelEntry
                        );
                    },
                    contentAwares
                );
                if (foundNode) {
                    foundNodes.push(foundNode.node);
                }
            } else {
                // find by nested-code
                const codePathCandidates: string[] = flatten(
                    Object.entries(configModelEntry).map(
                        (currentListToNested) => {
                            const currentListCode = currentListToNested[0];
                            return Object.entries(currentListToNested[1]).map(
                                (currentNestedToOption) =>
                                    `${currentListCode}.${currentNestedToOption[0]}.${currentNestedToOption[1]}`
                            );
                        }
                    )
                );
                const currentFoundNodes = this.collectNodes(
                    (node, parents): node is ExtendedUiOptionCode => {
                        const directParent = last(parents);
                        if (
                            !isExtendedUiOptionCode(node) ||
                            (directParent &&
                                !isExtendedUiNestedCode(directParent))
                        ) {
                            return false;
                        }
                        const codeAwareParentPath = parents
                            ?.map((parent) =>
                                isCodeAware(parent) ? parent.code : undefined
                            )
                            .filter((parent) => !!parent)
                            .join('.');
                        const pathToNode = codeAwareParentPath
                            ? `${codeAwareParentPath}.${node.code}`
                            : node.code;
                        return codePathCandidates.includes(pathToNode);
                    },
                    contentAwares
                );
                foundNodes.push(...currentFoundNodes);
            }
        }
        return foundNodes;
    }

    private findNodeInContent<T extends ExtendedUiConfigurationMetaItem>(
        finder: TypedConfigurationNodeFinder<T>,
        item: ExtendedUiContentAwareConfigurationMetaItem
    ) {
        return first(
            this.collectNodesInContentWithParent(
                finder,
                undefined,
                item,
                undefined,
                1
            )
        );
    }

    private collectNodesInContent<T extends ExtendedUiConfigurationMetaItem>(
        finder: TypedConfigurationNodeFinder<T>,
        parents: ExtendedUiContentAwareConfigurationMetaItem[] | undefined,
        item: ExtendedUiConfigurationMetaItem,
        maxMatchCount = Number.POSITIVE_INFINITY
    ) {
        return this.collectNodesInContentWithParent(
            finder,
            parents,
            item,
            undefined,
            maxMatchCount
        ).map((node) => node.node);
    }

    private collectNodesInContentWithParent<
        T extends ExtendedUiConfigurationMetaItem
    >(
        matcher: TypedConfigurationNodeFinder<T>,
        parents:
            | readonly ExtendedUiContentAwareConfigurationMetaItem[]
            | undefined,
        item: ExtendedUiConfigurationMetaItem,
        parent: ExtendedUiContentAwareConfigurationMetaItem | undefined,
        maxMatchCount = Number.POSITIVE_INFINITY
    ): NodeWithParent<T>[] {
        let parentsNow = parents;
        if (parent) {
            parentsNow = Object.freeze([...(parentsNow || []), parent]);
        }
        const matchedItems: NodeWithParent<T>[] = [];
        if (matcher(item, parentsNow)) {
            matchedItems.push({ node: item, parent });
        }
        if (matchedItems.length >= maxMatchCount) {
            return matchedItems;
        }
        if (isContentAware(item)) {
            for (const content of item.content) {
                matchedItems.push(
                    ...this.collectNodesInContentWithParent(
                        matcher,
                        parentsNow,
                        content,
                        item,
                        maxMatchCount
                    )
                );
                if (matchedItems.length >= maxMatchCount) {
                    return matchedItems;
                }
            }
        }

        return matchedItems;
    }

    private getNodePathInContent(
        finder: (node: ExtendedUiConfigurationMetaItem) => boolean,
        item: ExtendedUiConfigurationMetaItem,
        path: ExtendedUiConfigurationMetaItem[]
    ) {
        if (finder(item)) {
            path.push(item);
            return path;
        }
        if (isContentAware(item)) {
            let foundInBranch = false;
            (item.content as ExtendedUiConfigurationMetaItem[]).find(
                (content) => {
                    foundInBranch = !!this.getNodePathInContent(
                        finder,
                        content,
                        path
                    );
                    return foundInBranch;
                }
            );
            if (foundInBranch) {
                path.push(item);
                return path;
            }
        }

        return undefined;
    }
}
