import deepLock from 'deep-lock';
import { isEqual } from 'lodash-es';
import { Observable, of, switchMap } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    shareReplay,
    startWith,
    take
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationNavigationArea } from '@mhp/aml-ui-shared-services';
import {
    MemoizeObservable,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';

import { NavigationStateService } from '../../navigation/navigation-state.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';

export interface ConfigurationSessionDirtyState {
    optionsDirty: boolean;
    animationsDirty: boolean;
    cameraDirty: boolean;
    environmentDirty: boolean;
    // anything dirty?
    dirty: boolean;
}

/**
 * tells if the state has been dirty in the past (e.g. user changed from
 * option A to B and back to A results in the state being non-dirty but hasBeenDirty would be true)
 */
export interface ConfigurationSessionTouchedState {
    optionsTouched: boolean;
    animationsTouched: boolean;
    cameraTouched: boolean;
    environmentTouched: boolean;
    // anything touched?
    touched: boolean;
}

export interface ConfigurationSessionChangedState
    extends ConfigurationSessionDirtyState,
        ConfigurationSessionTouchedState {}

const NOT_DIRTY: ConfigurationSessionDirtyState = deepLock({
    optionsDirty: false,
    animationsDirty: false,
    cameraDirty: false,
    environmentDirty: false,
    dirty: false
});

const NOT_TOUCHED: ConfigurationSessionTouchedState = deepLock({
    optionsTouched: false,
    animationsTouched: false,
    cameraTouched: false,
    environmentTouched: false,
    touched: false
});

const NO_CHANGES: ConfigurationSessionChangedState = deepLock({
    ...NOT_DIRTY,
    ...NOT_TOUCHED
});

/**
 * Provides information about the dirty-state of the current configuration session.
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigurationChangedStateTrackingService {
    constructor(
        private readonly navigationStateService: NavigationStateService,
        private readonly router: Router,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService
    ) {}

    /**
     * Determine which parts of the current configuration-session (config, env cameras, ..) has been actively changed by the user after
     * having started it.
     * - once a user changes a setting back to the initial setting, the according part will be reflected as "unchanged" again
     * - once a user leaves the configuration route, the result will be cleaned-up and states will be all "unchanged"
     * - tracking the dirty-state starts after entering the configuration-area and waits for the initial comparison-state until all
     *   initial "auto-adjustments" have been done.
     *   These could be things like options that only get initially resolved by the ruler or settings that are triggered by context-actions
     *   like rendering with roof-opened depending on the active section.
     */
    @MemoizeObservable()
    getConfigurationSessionChangedState$(): Observable<ConfigurationSessionChangedState> {
        // emits whether the configuration-route is active or not
        const configurationRouteActive$ = this.navigationStateService
            .getActiveApplicationNavigationArea$()
            .pipe(
                map((area) => area === ApplicationNavigationArea.CONFIGURATION),
                distinctUntilChanged()
            );

        return configurationRouteActive$.pipe(
            switchMap((configurationRouteActive) => {
                // if not active, we signal "no changes"
                if (!configurationRouteActive) {
                    return of(NO_CHANGES);
                }

                /* for the initial configuration-info, which serves as reference to compare to,
                 * we'll wait until the possible initial resolves and config-updates
                 * have been applied.
                 */
                const initialConfigurationSessionInfo$ =
                    this.configurationSessionInfoService
                        .getActiveConfigurationSessionInfo$()
                        .pipe(
                            // wait until session-info changes have initially settled
                            debounceTime(1000),
                            take(1)
                        );

                return initialConfigurationSessionInfo$.pipe(
                    switchMap((initialConfigurationSessionInfo) => {
                        const touchedState: ConfigurationSessionTouchedState = {
                            ...NOT_TOUCHED
                        };

                        // compare the initial session info with the most-current one
                        return this.configurationSessionInfoService
                            .getActiveConfigurationSessionInfo$()
                            .pipe(
                                map(
                                    (
                                        currentConfigurationSessionInfo
                                    ): ConfigurationSessionDirtyState => {
                                        if (!currentConfigurationSessionInfo) {
                                            return {
                                                ...touchedState,
                                                ...NOT_DIRTY
                                            };
                                        }
                                        const partialDirtyState = <
                                            Omit<
                                                ConfigurationSessionDirtyState,
                                                'dirty'
                                            >
                                        >{
                                            optionsDirty: !isEqual(
                                                initialConfigurationSessionInfo
                                                    ?.engineData?.config,
                                                currentConfigurationSessionInfo
                                                    ?.engineData?.config
                                            ),
                                            animationsDirty: !isEqual(
                                                initialConfigurationSessionInfo
                                                    ?.engineData?.animations,
                                                currentConfigurationSessionInfo
                                                    ?.engineData?.animations
                                            ),
                                            cameraDirty: !isEqual(
                                                initialConfigurationSessionInfo
                                                    ?.engineData?.camera,
                                                currentConfigurationSessionInfo
                                                    ?.engineData?.camera
                                            ),
                                            environmentDirty: !isEqual(
                                                initialConfigurationSessionInfo
                                                    ?.engineData?.environment,
                                                currentConfigurationSessionInfo
                                                    ?.engineData?.environment
                                            )
                                        };

                                        return {
                                            ...partialDirtyState,
                                            dirty:
                                                !partialDirtyState.optionsDirty ||
                                                !partialDirtyState.animationsDirty ||
                                                !partialDirtyState.cameraDirty ||
                                                !partialDirtyState.environmentDirty
                                        };
                                    }
                                ),
                                distinctUntilChangedEquality(),
                                map(
                                    (
                                        dirtyState
                                    ): ConfigurationSessionChangedState => {
                                        // update touchedState
                                        touchedState.optionsTouched =
                                            touchedState.optionsTouched ||
                                            dirtyState.optionsDirty;
                                        touchedState.animationsTouched =
                                            touchedState.animationsTouched ||
                                            dirtyState.animationsDirty;
                                        touchedState.cameraTouched =
                                            touchedState.cameraTouched ||
                                            dirtyState.cameraDirty;
                                        touchedState.environmentTouched =
                                            touchedState.environmentTouched ||
                                            dirtyState.environmentDirty;
                                        touchedState.touched =
                                            touchedState.touched ||
                                            touchedState.optionsTouched ||
                                            touchedState.animationsTouched ||
                                            touchedState.cameraTouched ||
                                            touchedState.environmentTouched;

                                        return {
                                            ...dirtyState,
                                            ...touchedState
                                        };
                                    }
                                )
                            );
                    }),
                    // we initially start with NO_CHANGES until comparison-data is available
                    startWith(NO_CHANGES)
                );
            }),
            distinctUntilChangedEquality(),
            shareReplay(1)
        );
    }

    /**
     * Emits if configuration-settings (options, env, cameras, ...) are dirty compared to session-start.
     * @see getConfigurationSessionChangedState$()
     */
    @MemoizeObservable()
    getConfigurationSessionDirtyState$(): Observable<boolean> {
        return this.getConfigurationSessionChangedState$().pipe(
            map((changes) => changes.dirty),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    /**
     * Emits if a user did actively change configuration-settings (options, env, cameras, ...) while session-start.
     * @see getConfigurationSessionChangedState$()
     */
    @MemoizeObservable()
    getConfigurationSessionTouchedState$(): Observable<boolean> {
        return this.getConfigurationSessionChangedState$().pipe(
            map((changes) => changes.touched),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }
}
