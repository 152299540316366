import { distinctUntilChanged, filter, first } from 'rxjs';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { ApplicationStateService, gtmGA4Track } from '@mhp/ui-shared-services';

import { LocalApplicationState } from '../../state/local-application-state.interface';
import { SearchState } from './models/search.model';

@Component({
    selector: 'mhp-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent
    extends UiBaseComponent
    implements AfterViewInit, OnInit
{
    searchInput: UntypedFormControl;

    @Input() searchState: SearchState;

    @ViewChild('searchInputElement', {
        static: false,
        read: ElementRef
    })
    searchInputRef?: ElementRef;

    @Output()
    readonly intentSearch = new EventEmitter<void>();

    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>
    ) {
        super();
    }

    ngOnInit() {
        this.searchInput = this.createSearchFormControl();
    }

    ngAfterViewInit() {
        this.initSearchInputFocusBinding();
    }

    @MemoizeObservable()
    isSearchInputActive$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(
                this.searchState.selectSearchInputActive,
                distinctUntilChanged(),
                lazyShareReplay()
            );
    }

    @MemoizeObservable()
    getActiveSearchTerm$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(this.searchState.selectActiveConfigurationSearchTerm);
    }

    intentToggleSearch() {
        this.applicationStateService
            .getLocalState()
            .pipe(this.searchState.selectSearchInputActive, first())
            .subscribe((searchActive) => {
                this.applicationStateService.dispatch(
                    this.searchState.setSearchInputActive({
                        searchInputActive: !searchActive
                    })
                );
            });
    }

    intentTriggerSearch() {
        this.applicationStateService.dispatch(
            this.searchState.setSearchInputActive({
                searchInputActive: false
            })
        );

        const searchTerm = this.searchInput.value;
        this.applicationStateService.dispatch(
            this.searchState.setActiveSearchTerm({
                searchTerm
            })
        );

        gtmGA4Track('search_terms', { search_submitted: searchTerm });
        this.intentSearch.next();
    }

    intentExitSearch() {
        this.applicationStateService.dispatch(
            this.searchState.exitSearchState()
        );
    }

    private createSearchFormControl() {
        const searchInput = new UntypedFormControl(
            undefined,
            Validators.required
        );
        this.applicationStateService
            .getLocalState()
            .pipe(
                this.searchState.selectActiveConfigurationSearchTerm,
                this.takeUntilDestroy()
            )
            .subscribe((searchTerm) => {
                searchInput.setValue(searchTerm);
            });
        return searchInput;
    }

    private initSearchInputFocusBinding() {
        this.isSearchInputActive$()
            .pipe(
                this.takeUntilDestroy(),
                filter((isActive) => !!isActive)
            )
            .subscribe(() => {
                this.searchInputRef?.nativeElement.focus();
            });
    }
}
