import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PricingFormatModule } from '@mhp/aml-ui-shared-components/pricing';

import { IfShowPricingDirective } from './if-show-pricing.directive';

@NgModule({
    declarations: [IfShowPricingDirective],
    exports: [IfShowPricingDirective, PricingFormatModule],
    imports: [CommonModule, PricingFormatModule],
    providers: []
})
export class PricingModule {}
