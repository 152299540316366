import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import {
    UiGlobalApplicationState,
    enhanceSelector
} from '@mhp/ui-shared-services';

import { LocalApplicationState } from '../local-application-state.interface';

export const selectEngineUiState = enhanceSelector<
    UiGlobalApplicationState<LocalApplicationState, AmlUiSharedState>,
    AmlUiSharedState | undefined
>(pipe(map((state) => state?.engine?.uiState)), {
    distinctUntilChanged: true
});
