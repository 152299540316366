import { isEmpty, isNil } from 'lodash-es';

import { translate } from '@jsverse/transloco';
import { EffectivePricingAware } from '@mhp/aml-ui-shared-services';
import { IllegalStateError } from '@mhp/common';
import { ErrorHandlerService } from '@mhp/ui-shared-services';

/**
 * Sums-up the given option-prices.
 * @param options A list of options.
 * @param errorHandlerService ErrorHandlerService to optionally provide user-notifications in case an invalid state is detected.
 */
export const sumUpEffectivePricingAwarePricings = (
    options: EffectivePricingAware[],
    errorHandlerService: ErrorHandlerService
): EffectivePricingAware | undefined => {
    return options.reduce(
        (
            reducedPrice: EffectivePricingAware | undefined,
            currentOption
        ): EffectivePricingAware | undefined => {
            if (
                isNil(currentOption.pricing) ||
                isEmpty(currentOption.pricing) ||
                (isNil(currentOption.pricing.price) &&
                    isNil(currentOption.pricing.transferPrice) &&
                    isNil(currentOption.pricing.retailPrice) &&
                    isNil(currentOption.pricing.wholeSalePrice))
            ) {
                return reducedPrice;
            }

            const totalEffectivePrice = isNil(currentOption.pricing.price)
                ? reducedPrice?.pricing?.price
                : (reducedPrice?.pricing?.price ?? 0) +
                  currentOption.pricing.price;
            const totalRetailPrice = isNil(currentOption.pricing.retailPrice)
                ? reducedPrice?.pricing?.retailPrice
                : (reducedPrice?.pricing?.retailPrice ?? 0) +
                  currentOption.pricing.retailPrice;
            const totalWholesalePrice = isNil(
                currentOption.pricing.wholeSalePrice
            )
                ? reducedPrice?.pricing?.wholeSalePrice
                : (reducedPrice?.pricing?.wholeSalePrice ?? 0) +
                  currentOption.pricing.wholeSalePrice;
            const totalTransferPrice = isNil(
                currentOption.pricing.transferPrice
            )
                ? reducedPrice?.pricing?.transferPrice
                : (reducedPrice?.pricing?.transferPrice ?? 0) +
                  currentOption.pricing.transferPrice;

            const currentOptionsCurrency = currentOption.pricing?.currency;
            const currentCommonCurrency = reducedPrice?.pricing?.currency;

            if (
                currentCommonCurrency &&
                currentCommonCurrency !== currentOptionsCurrency
            ) {
                const incosistentPricingError = new IllegalStateError(
                    `Inconsistent currencies provided for options. Got ${currentCommonCurrency} vs. ${currentOptionsCurrency} (in context of option ${JSON.stringify(
                        currentOption
                    )})`
                );
                errorHandlerService.showErrorMessage(
                    () =>
                        translate(
                            'SUMMARY.PRICING.ERROR.INCONSISTENT_CURRENCIES'
                        ),
                    incosistentPricingError
                );
                throw incosistentPricingError;
            }

            return {
                pricing: {
                    currency: currentOptionsCurrency,
                    price: totalEffectivePrice,
                    retailPrice: totalRetailPrice,
                    wholeSalePrice: totalWholesalePrice,
                    transferPrice: totalTransferPrice
                }
            };
        },
        undefined
    );
};
