import { EMPTY, filter, iif, of, switchMap, take, tap } from 'rxjs';
import { pairwise } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { DealerInfoService } from '@mhp/aml-ui-shared-services';
import { UiNotificationService } from '@mhp/ui-components';
import { ApplicationStateService } from '@mhp/ui-shared-services';

import { environment } from '../../environments/environment';
import { LocalApplicationState } from '../state';
import { selectDealerInfo } from './state';

/**
 * Service that handles showing notifications about the dealers login-status.
 */
@Injectable()
export class DealerStatusNotificationService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly dealerInfoService: DealerInfoService,
        private readonly uiNotificationService: UiNotificationService
    ) {
        this.initDealerLoginNotification();
    }

    private initDealerLoginNotification() {
        const loginNotification$ = iif(
            () => environment.appConfig.dealer.skipLoginStatusNotifications,
            of(undefined),
            this.applicationStateService.getLocalState().pipe(
                selectDealerInfo,
                pairwise(),
                filter(
                    ([prevDealerInfo, curDealerInfo]) =>
                        !!prevDealerInfo || !!curDealerInfo
                ),
                switchMap(([prevDealerInfo, curDealerInfo]) => {
                    if (!prevDealerInfo && curDealerInfo) {
                        return this.uiNotificationService
                            .showAutohideNotification(
                                `Successfully logged in as ${curDealerInfo.name} / ${curDealerInfo.username}`
                            )
                            .afterDismissed();
                    }
                    if (prevDealerInfo && !curDealerInfo) {
                        return this.uiNotificationService
                            .showAutohideNotification(
                                `Logged out as ${prevDealerInfo.name} / ${prevDealerInfo.username}`
                            )
                            .afterDismissed();
                    }
                    return EMPTY;
                })
            )
        );

        return loginNotification$
            .pipe(
                switchMap(() =>
                    // In case there is no user attached to the dealer, show an according message once.
                    this.dealerInfoService.getActiveDealerInfo$().pipe(
                        take(1),
                        tap({
                            error: (error) => {
                                if (
                                    error instanceof HttpErrorResponse &&
                                    error.status === 401
                                ) {
                                    this.uiNotificationService.showError(
                                        translate(
                                            'CONFIGURATOR.ERRORS.NO_DEALER_USER_ATTACHED'
                                        )
                                    );
                                }
                            }
                        })
                    )
                )
            )
            .subscribe();
    }
}
