import { first as _first, cloneDeep, isEmpty } from 'lodash-es';
import { first, firstValueFrom, map, switchMap } from 'rxjs';

import { translate } from '@jsverse/transloco';
import { IllegalStateError, UserCancelledError } from '@mhp/common';
import {
    CommonDialogsService,
    ConfigurationConverterMode,
    ConfigurationConverterService,
    ProductConfigurationService
} from '@mhp/ui-shared-services';

import {
    ExtendedUiConfigurationMetaItem,
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiNestedCode,
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection,
    ExtendedUiOptionGroup,
    ExtendedUiOptionList
} from '../configuration-model/configuration-interfaces';
import { ExtendedUiOptionGroupMapperService } from '../configuration-model/extended-ui-option-group-mapper.service';
import {
    isExtendedUiNestedCode,
    isExtendedUiOptionCode,
    isExtendedUiOptionCollection,
    isExtendedUiOptionGroup,
    isExtendedUiOptionList
} from './configuration-helper';
import { ConfigurationNodeLookupService } from './configuration-node-lookup.service';

export interface SelectionChangeInfo {
    toSelect?: string[];
    toDeselect?: string[];
    requiresResolve?: (id: string) => boolean;
    filterAdditionalIds?: (
        optionsGroups: ExtendedUiOptionGroup[]
    ) => SelectionChangeInfo;
}

interface SelectionChangeInfoInternal {
    toSelect?: string[];
    toDeselect?: string[];
    requiresResolve?: (id: string) => boolean;
    filterAdditionalIds?: (
        optionsGroups: ExtendedUiOptionGroup[]
    ) => SelectionChangeInfo;

    isInternal: boolean;
}

export class NodeSelectionHandler {
    private optionGroups: ExtendedUiOptionGroup[];

    private requiresResolve?: (id: string) => boolean;

    private filterAdditionalIds?: (
        optionsGroups: ExtendedUiOptionGroup[]
    ) => SelectionChangeInfo;

    constructor(
        optionGroups: ExtendedUiOptionGroup[],
        private readonly modelId: string,
        private readonly productId: string,
        private readonly country: string,
        private readonly nodeLookup: ConfigurationNodeLookupService,
        private readonly commonDialogsService: CommonDialogsService,
        private readonly productConfigurationService: ProductConfigurationService,
        private readonly optionGroupMapperService: ExtendedUiOptionGroupMapperService,
        private readonly configurationConverterService: ConfigurationConverterService
    ) {
        this.optionGroups = cloneDeep(optionGroups);
    }

    getOptionGroups() {
        return cloneDeep(this.optionGroups);
    }

    async handleNodeSelection(
        selectionChangeInfo: SelectionChangeInfo
    ): Promise<ExtendedUiOptionGroup[]> {
        this.requiresResolve = selectionChangeInfo.requiresResolve;
        this.filterAdditionalIds = selectionChangeInfo.filterAdditionalIds;

        await this.handleNodeSelectionInternal({
            ...selectionChangeInfo,
            isInternal: false
        });

        return this.optionGroups;
    }

    async handleNodeSelectionInternal(
        selectionChangeInfo: SelectionChangeInfoInternal
    ) {
        for (const toDeselect of selectionChangeInfo.toDeselect || []) {
            await this.handleNodeIdSelectionChangeInternal(
                toDeselect,
                false,
                selectionChangeInfo.isInternal
            );
        }
        for (const toSelect of selectionChangeInfo.toSelect || []) {
            await this.handleNodeIdSelectionChangeInternal(
                toSelect,
                true,
                selectionChangeInfo.isInternal
            );
        }
    }

    private async handleNodeIdSelectionChangeInternal(
        id: string,
        targetSelectState: boolean,
        isInternalRequest: boolean
    ): Promise<void> {
        const foundNodeAndParent = this.nodeLookup.findNodeById(
            id,
            this.optionGroups
        );

        if (!foundNodeAndParent) {
            return;
        }

        const foundNode = foundNodeAndParent.node;
        const foundParent = foundNodeAndParent.parent;

        await this.handleNodeSelectionChangeInternal(
            foundNode,
            foundParent,
            targetSelectState,
            isInternalRequest
        );
    }

    private async handleNodeSelectionChangeInternal(
        node: ExtendedUiConfigurationMetaItem,
        nodeParent: ExtendedUiContentAwareConfigurationMetaItem | undefined,
        targetSelectState: boolean,
        isInternalRequest: boolean
    ): Promise<void> {
        if (isExtendedUiOptionCode(node) && nodeParent) {
            await this.handleOptionCodeSelectionChange(
                node,
                nodeParent,
                targetSelectState,
                isInternalRequest
            );
        } else if (isExtendedUiOptionList(node) && nodeParent) {
            await this.handleOptionListSelectionChange(
                node,
                nodeParent,
                targetSelectState,
                isInternalRequest
            );
        } else if (isExtendedUiNestedCode(node) && nodeParent) {
            await this.handleNestedCodeSelectionChange(
                node,
                nodeParent,
                targetSelectState,
                isInternalRequest
            );
        } else if (isExtendedUiOptionCollection(node)) {
            await this.handleOptionCollectionSelectionChange(
                node,
                targetSelectState,
                isInternalRequest
            );
        }
    }

    private async handleOptionCodeSelectionChange(
        optionCode: ExtendedUiOptionCode,
        parent: ExtendedUiContentAwareConfigurationMetaItem,
        targetSelectState: boolean,
        isInternalRequest: boolean
    ): Promise<void> {
        const optionsPreviousSelectedState = optionCode.selected;

        let selectionHandled = false;
        let selectionChangeInfo: SelectionChangeInfoInternal = {
            isInternal: true
        };

        if (targetSelectState === optionsPreviousSelectedState) {
            return undefined;
        }

        // SELECTION-CHANGE INSIDE COLLECTION
        if (isExtendedUiOptionCollection(parent)) {
            const grandParent = this.nodeLookup.findNodeById(
                parent.id,
                this.optionGroups
            )?.parent;
            this.clearSelectionsInCollectionBranch(parent);
            if (
                !parent.mandatory &&
                (!grandParent ||
                    !isExtendedUiOptionCollection(grandParent) ||
                    !grandParent.mandatory)
            ) {
                optionCode.selected = targetSelectState;
                parent.selected = targetSelectState;
            } else {
                // mandatory collection always has to be selected
                parent.selected = true;
                optionCode.selected = true;

                if (!targetSelectState) {
                    if (isInternalRequest) {
                        // if it's an internal request, we try to resolve a deselection of a collection item
                        optionCode.selected = targetSelectState;
                        // need to select a sibling..
                        const siblingBaseFinder = (
                            item
                        ): item is ExtendedUiOptionCode =>
                            item !== optionCode && isExtendedUiOptionCode(item);
                        // first try to find a sibling with no conflicts
                        let siblingNode = parent.content.find(
                            (item): item is ExtendedUiOptionCode =>
                                siblingBaseFinder(item) &&
                                isEmpty(item.conflicts)
                        );
                        if (!siblingNode) {
                            // take siblings with conflicts into account, too
                            siblingNode =
                                parent.content.find(siblingBaseFinder);
                        }
                        if (!siblingNode) {
                            throw new IllegalStateError(
                                `Cannot deselect ${optionCode.code} as no sibling in parent collection can be selected instead.`
                            );
                        }

                        selectionChangeInfo = {
                            toSelect: [siblingNode.id],
                            isInternal: true
                        };

                        selectionHandled = true;
                        await this.handleNodeSelectionInternal(
                            selectionChangeInfo
                        );
                    }
                }
            }
        }
        // SELECTION-CHANGE INSIDE NESTED-CODE
        else if (isExtendedUiNestedCode(parent)) {
            const parentList = this.nodeLookup.findNodeById(
                parent.id,
                this.optionGroups
            )?.parent;
            if (parentList) {
                await this.handleNestedCodeSelectionChange(
                    parent,
                    parentList,
                    // force selection to true no matter what the target selection state is
                    true,
                    isInternalRequest
                );
            }
            const siblings = parent.content;
            siblings.forEach((e) => {
                e.selected = false;
            });
            // force selection to true no matter what the target selection state is
            optionCode.selected = true;
        }
        // SELECTION-CHANGE INSIDE GROUP
        else if (isExtendedUiOptionGroup(parent)) {
            optionCode.selected = targetSelectState;
        }

        if (selectionHandled) {
            return undefined;
        }

        if (targetSelectState) {
            let updatedOptionCode: ExtendedUiOptionCode = optionCode;
            const toSelect: string[] = [];
            if (this.requiresResolve && this.requiresResolve(optionCode.id)) {
                this.optionGroups = await this.getNextConfigMeta();

                if (this.filterAdditionalIds) {
                    const sChangeInfo = this.filterAdditionalIds(
                        this.optionGroups
                    );
                    if (sChangeInfo.toSelect) {
                        toSelect.push(...sChangeInfo.toSelect);
                    }
                }

                const updatedOptionCodeLocal = this.nodeLookup.findNodeById(
                    optionCode.id,
                    this.optionGroups
                )?.node;

                if (!updatedOptionCodeLocal) {
                    throw new IllegalStateError(
                        `Node that was previously contained in metadata (${optionCode.id} / ${optionCode.code}) could no longer be found.`
                    );
                }
                if (!isExtendedUiOptionCode(updatedOptionCodeLocal)) {
                    throw new IllegalStateError(
                        `Node (${optionCode.id} / ${optionCode.code}) has unexpected node type: ${updatedOptionCodeLocal.type}`
                    );
                }
                updatedOptionCode = updatedOptionCodeLocal;
            }

            selectionChangeInfo =
                await this.waitForUserDecisionWhenSelectingNode(
                    optionCode,
                    updatedOptionCode,
                    {
                        isInternal: true
                    }
                );
            selectionChangeInfo.toSelect = [
                ...(selectionChangeInfo.toSelect
                    ? selectionChangeInfo.toSelect
                    : []),
                ...toSelect
            ];
        }

        return this.handleNodeSelectionInternal(selectionChangeInfo);
    }

    private async handleOptionListSelectionChange(
        optionList: ExtendedUiOptionList,
        parent: ExtendedUiContentAwareConfigurationMetaItem,
        targetSelectState: boolean,
        isInternalRequest: boolean
    ): Promise<void> {
        if (isExtendedUiOptionCollection(parent)) {
            this.clearSelectionsInCollectionBranch(parent);
            parent.selected = true;
            optionList.selected = true;
        } else if (isExtendedUiOptionGroup(parent)) {
            optionList.selected = targetSelectState;
        }
    }

    private async handleNestedCodeSelectionChange(
        nestedCode: ExtendedUiNestedCode,
        parent: ExtendedUiContentAwareConfigurationMetaItem,
        targetSelectState: boolean,
        isInternalRequest: boolean
    ): Promise<void> {
        // we simply propagate the selection up to the parent list
        await this.handleNodeIdSelectionChangeInternal(
            parent.id,
            targetSelectState,
            isInternalRequest
        );
    }

    private async handleOptionCollectionSelectionChange(
        optionCollection: ExtendedUiOptionCollection,
        targetSelectState: boolean,
        isInternalRequest: boolean
    ): Promise<void> {
        this.clearSelectionsInCollectionBranch(optionCollection);

        if (optionCollection.mandatory) {
            optionCollection.selected = true;
            return;
        }

        optionCollection.selected = targetSelectState;
        if (!optionCollection.selected) {
            return;
        }

        if (!optionCollection.content.some((content) => content.selected)) {
            // select first child
            const firstChild = _first(optionCollection.content);
            if (firstChild) {
                await this.handleNodeSelectionChangeInternal(
                    firstChild,
                    optionCollection,
                    true,
                    isInternalRequest
                );
            }
        }
    }

    /**
     * Walk up the tree starting from the given node until a node is reached that is not a collection. Start from there deselecting all
     * child-nodes.
     * @param optionCollection The collection from which to start searching upwards the tree.
     * @param optionGroups All root-groups.
     * @private
     */
    private clearSelectionsInCollectionBranch(
        optionCollection: ExtendedUiOptionCollection
    ) {
        const nodeParent = this.nodeLookup.findNodeById(
            optionCollection.id,
            this.optionGroups
        )?.parent;
        if (!nodeParent || !isExtendedUiOptionCollection(nodeParent)) {
            // clean all selection below current optionCollection
            this.clearSelectionsInCollectionDescending(optionCollection);
        } else {
            this.clearSelectionsInCollectionBranch(nodeParent);
        }
    }

    private clearSelectionsInCollectionDescending(
        optionCollection: ExtendedUiOptionCollection
    ) {
        if (!optionCollection.mandatory) {
            optionCollection.selected = false;
        }
        optionCollection.content.forEach((item) => {
            if (isExtendedUiOptionCollection(item)) {
                this.clearSelectionsInCollectionDescending(item);
            } else if (
                isExtendedUiOptionCode(item) ||
                isExtendedUiOptionList(item)
            ) {
                item.selected = false;
            }
        });
    }

    private async waitForUserDecisionWhenSelectingNode(
        currentNode: ExtendedUiOptionCode,
        nextNode: ExtendedUiOptionCode,
        selectionChangeInfo: SelectionChangeInfoInternal
    ): Promise<SelectionChangeInfoInternal> {
        const adaptedSelectionChangeInfo = cloneDeep(selectionChangeInfo);
        let conflictText = '';

        const conflictingOptionCodes = this.getConflictingOptionCodes(nextNode);

        if (!conflictingOptionCodes || isEmpty(conflictingOptionCodes)) {
            return selectionChangeInfo;
        }

        const selectedNodes = conflictingOptionCodes.filter(
            (node) => node.selected
        );
        const nonSelectedNodes = conflictingOptionCodes.filter(
            (node) => !node.selected
        );

        if (!isEmpty(selectedNodes)) {
            // disabled case: the current node is not active and should be selected
            adaptedSelectionChangeInfo.toDeselect = [
                ...(selectionChangeInfo.toDeselect || []),
                ...selectedNodes.map((node) => node.id)
            ];
            conflictText = translate(
                'CONFIGURATOR.CONFLICTS.SELECT_ITEM_WILL_REMOVE',
                {
                    optionToSelect: currentNode.nameTranslated,
                    conflictingOptions: conflictingOptionCodes
                        .map((node) => node.nameTranslated)
                        .join(', ')
                }
            );
        }
        if (!isEmpty(nonSelectedNodes)) {
            // force case: the current node is active but when having selected it, it declares conflicts
            adaptedSelectionChangeInfo.toSelect = [
                ...(selectionChangeInfo.toSelect || []),
                ...nonSelectedNodes.map((node) => node.id)
            ];
            conflictText = translate(
                'CONFIGURATOR.CONFLICTS.SELECT_ITEM_WILL_ADD',
                {
                    optionToSelect: currentNode.nameTranslated,
                    conflictingOptions: nonSelectedNodes
                        .map((node) => node.nameTranslated)
                        .join(', ')
                }
            );
        }

        return firstValueFrom(
            this.commonDialogsService
                .openConfirmDialog$(
                    translate('CONFIGURATOR.CONFLICTS.DIALOG_HEADER'),
                    conflictText,
                    undefined,
                    {
                        showCancel: true
                    }
                )
                .pipe(
                    map((result) => {
                        if (result.result === 'CANCEL') {
                            // user cancelled
                            throw new UserCancelledError(
                                'User cancelled operation'
                            );
                        }
                        // it's okay to discard, continue
                        result.closeDialog();

                        return adaptedSelectionChangeInfo;
                    })
                )
        );
    }

    private getConflictingOptionCodes(optionCode: ExtendedUiOptionCode) {
        return optionCode.conflicts
            ?.map(
                (conflictingCode) =>
                    this.nodeLookup.findNode(
                        (item): item is ExtendedUiOptionCode =>
                            isExtendedUiOptionCode(item) &&
                            item.code === conflictingCode,
                        this.optionGroups
                    )?.node
            )
            .filter((node): node is ExtendedUiOptionCode => !!node);
    }

    private async getNextConfigMeta(): Promise<ExtendedUiOptionGroup[]> {
        const configModel =
            this.configurationConverterService.convertToConfigurationFormat(
                this.optionGroups,
                ConfigurationConverterMode.SELECTED_ONLY
            );

        return firstValueFrom(
            this.productConfigurationService
                .getConfigurationInfo$(
                    this.productId,
                    configModel,
                    undefined,
                    this.country
                )
                .pipe(
                    switchMap((unmappedConfigurationInfo) =>
                        this.optionGroupMapperService.mapOptionGroups$(
                            unmappedConfigurationInfo,
                            this.modelId
                        )
                    ),
                    first()
                )
        );
    }
}
