<mhp-configuration-main-layout
    [renderRaisedConfigurationBar]="renderRaisedConfigurationBar"
    [showInnerStageSettings]="
        (getActiveInnerStageNavigationArea$() | async) !== undefined
    "
    [showFooter]="configurationMainSupportService.showFooter$() | async"
    *transloco="let t; read: 'CONFIGURATOR'"
>
    <!-- CONTENT FOR mhp-configuration-main-layout__header -->
    <mhp-header-bar
        mhp-configuration-main-layout__header
        [hideLogo]="
            !renderRaisedConfigurationBar ||
            (getConfigurationBarDisplayMode$() | async) !== 'LARGE'
        "
    >
        <ng-container header-bar_area-left>
            <div class="header__action-menu">
                <mhp-ui-icon-button
                    *transloco="let t; read: 'COMMON.HEADER'"
                    svgIcon="mhp-ui:menu"
                    [matTooltip]="t('MENU_ALT')"
                    [label]="t('MENU_ALT')"
                    (click)="intentToggleMenu()"
                ></mhp-ui-icon-button>
            </div>
            <mhp-breadcrumb
                [useTextShadow]="!renderRaisedConfigurationBar"
            ></mhp-breadcrumb>
        </ng-container>
        <ng-container header-bar_area-right>
            <ng-container *mhpIfOrderPossible>
                <ng-container
                    *ngIf="(isSubmitToDealerVisible$() | async) === true"
                >
                    <a
                        mat-flat-button
                        class="header__cta-submit-to-dealer mhp-ui-button--fade"
                        [ngClass]="{
                            'mhp-ui-button--inherit-font-styles':
                                (getConfigurationBarDisplayMode$() | async) ===
                                'COMPACT',
                            'mhp-ui-button--link-style':
                                renderRaisedConfigurationBar &&
                                (getConfigurationBarDisplayMode$() | async) !==
                                    'COMPACT',
                            'header__cta-submit-to-dealer--raised-config-bar':
                                renderRaisedConfigurationBar
                        }"
                        (click)="intentSubmitToDealer()"
                        *transloco="let t2"
                        ><span>{{
                            t2(
                                (isHandsetPortrait$() | async) === true
                                    ? 'SUMMARY.SUBMIT_CUSTOMER.SUBMIT'
                                    : 'SUMMARY.SUBMIT_CUSTOMER.SUBMIT_LONG'
                            )
                        }}</span>
                    </a>
                </ng-container>
            </ng-container>
            <ng-template
                [cdkPortalOutlet]="
                    getDealerHookOne2OneSessionControl$() | async
                "
            ></ng-template>
        </ng-container>
    </mhp-header-bar>

    <!-- CONTENT FOR mhp-configuration-main-layout__action-bar -->
    <ng-container mhp-configuration-main-layout__action-bar>
        <div
            class="configuration-main__action-groups-container configuration-main__action-groups-container--available-handset-portrait"
            *transloco="let t2; read: 'ICONS'"
        >
            <mhp-configuration-area-action-group
                class="configuration-main__action-group--rotate-device-hint"
                *transloco="let t2"
            >
                <mhp-configuration-area-action-item
                    [iconOnly]="true"
                    svgIcon="mhp-ui:rotate-device"
                    [tooltip]="t2('COMMON.ROTATION_HINT')"
                >
                </mhp-configuration-area-action-item>
            </mhp-configuration-area-action-group>
        </div>

        <div
            class="configuration-main__action-groups-container configuration-main__action-groups-container--right"
            *transloco="let t2; read: 'ICONS'"
        >
            <mhp-configuration-area-action-group
                class="configuration-main__action-group configuration-main__action-group--engine-settings"
            >
                <!-- financial services -->
                <ng-container
                    *ngIf="
                        (isFinancialServiceOptionAvailable$() | async) &&
                        (menuIsExtended$ | async)
                    "
                >
                    <mhp-configuration-area-action-item
                        *mhpDealerFeature
                        svgIcon="mhp-ui:calc"
                        [tooltip]="t2('FINANCIAL_SERVICES_ALT')"
                        [active]="false"
                        (itemSelect)="intentShowFinancialServiceOptions()"
                    >
                    </mhp-configuration-area-action-item>
                </ng-container>

                <ng-container
                    *ngFor="let stageAction of getStageActions$() | async"
                >
                    <ng-container [ngSwitch]="stageAction">
                        <!-- 360º view -->
                        <ng-container *ngSwitchCase="STAGE_ACTIONS.TURNTABLE">
                            <mhp-configuration-area-action-item
                                svgIcon="mhp-ui:turntable"
                                [tooltip]="t2('TURNTABLE_ALT')"
                                (itemSelect)="intentToggleTurntable()"
                            >
                            </mhp-configuration-area-action-item>
                        </ng-container>

                        <!-- day/night switch -->
                        <ng-container
                            *ngSwitchCase="STAGE_ACTIONS.DAY_NIGHT_MODE"
                        >
                            <ng-container
                                *ngIf="
                                    getActiveDayNightState$()
                                        | async as dayNightState
                                "
                            >
                                <mhp-configuration-area-action-item
                                    [svgIcon]="
                                        dayNightState ===
                                        EnvironmentLightingProfileState.DAY
                                            ? 'mhp-ui:nightmode'
                                            : 'mhp-ui:day'
                                    "
                                    [tooltip]="
                                        t2(
                                            dayNightState ===
                                                EnvironmentLightingProfileState.DAY
                                                ? 'NIGHTMODE_ALT'
                                                : 'DAYMODE_ALT'
                                        )
                                    "
                                    (itemSelect)="intentToggleDayNightState()"
                                >
                                </mhp-configuration-area-action-item>
                            </ng-container>
                        </ng-container>

                        <!-- environment toggle-->
                        <ng-container
                            *ngSwitchCase="STAGE_ACTIONS.ENVIRONMENTS"
                        >
                            <mhp-configuration-area-action-item
                                svgIcon="mhp-ui:environment"
                                [tooltip]="t2('ENVIRONMENT_ALT')"
                                [active]="
                                    (getActiveConfigurationNavigationArea$()
                                        | async) === 'environments'
                                "
                                (itemSelect)="
                                    intentToggleActiveConfigurationNavigationArea(
                                        'environments'
                                    )
                                "
                            >
                            </mhp-configuration-area-action-item>
                        </ng-container>

                        <!-- beauty shots -->
                        <ng-container
                            *ngSwitchCase="STAGE_ACTIONS.BEAUTY_SHOTS"
                        >
                            <mhp-configuration-area-action-item
                                svgIcon="mhp-ui:cameras"
                                [tooltip]="t2('BEAUTYSHOTS_ALT')"
                                [active]="
                                    (getActiveConfigurationNavigationArea$()
                                        | async) === 'cameras'
                                "
                                (itemSelect)="
                                    intentToggleActiveConfigurationNavigationArea(
                                        'cameras'
                                    )
                                "
                            >
                            </mhp-configuration-area-action-item>
                        </ng-container>

                        <!-- reset view -->
                        <ng-container
                            *ngSwitchCase="STAGE_ACTIONS.RESET_CAMERA"
                        >
                            <mhp-configuration-area-action-item
                                svgIcon="mhp-ui:update"
                                [tooltip]="t2('RESET_CAMERA_ALT')"
                                (itemSelect)="intentResetCamera()"
                            >
                            </mhp-configuration-area-action-item>
                        </ng-container>

                        <!-- screenshot -->
                        <ng-container *ngSwitchCase="STAGE_ACTIONS.SCREENSHOT">
                            <mhp-configuration-area-action-item
                                *mhpHideForSecretDerivative
                                svgIcon="mhp-ui:custom-shot"
                                [tooltip]="t2('SCREENSHOT_ALT')"
                                (itemSelect)="takeScreenshot()"
                            >
                            </mhp-configuration-area-action-item>
                        </ng-container>

                        <!-- animations -->
                        <ng-container *ngSwitchCase="STAGE_ACTIONS.ANIMATIONS">
                            <mhp-configuration-area-action-item
                                svgIcon="mhp-ui:animations"
                                [tooltip]="t2('ANIMATIONS_ALT')"
                                [active]="
                                    (getActiveConfigurationNavigationArea$()
                                        | async) === 'animations'
                                "
                                (itemSelect)="
                                    intentToggleActiveConfigurationNavigationArea(
                                        'animations'
                                    )
                                "
                            >
                            </mhp-configuration-area-action-item>
                        </ng-container>

                        <!-- cinematic switch -->
                        <ng-container *ngSwitchCase="STAGE_ACTIONS.CINEMATIC">
                            <mhp-configuration-area-action-item
                                svgIcon="mhp-ui:cinematics"
                                [tooltip]="t2('CINEMATICS_ALT')"
                                (itemSelect)="intentStartCinematic()"
                            >
                            </mhp-configuration-area-action-item>
                        </ng-container>

                        <!-- roof toggle -->
                        <ng-container *ngSwitchCase="STAGE_ACTIONS.ROOF_TOGGLE">
                            <mhp-configuration-area-action-item
                                *ngIf="
                                    carFeatureControlService.getRoofState$()
                                        | async as roofState
                                "
                                [svgIcon]="
                                    roofState === 'OPEN'
                                        ? 'mhp-ui:car_01'
                                        : 'mhp-ui:car_02'
                                "
                                iconSize="xlarge"
                                [tooltip]="
                                    t2(
                                        roofState === 'OPEN'
                                            ? 'ROOF_CLOSE_ALT'
                                            : 'ROOF_OPEN_ALT'
                                    )
                                "
                                (itemSelect)="intentToggleRoofState()"
                            >
                            </mhp-configuration-area-action-item>
                        </ng-container>

                        <!-- roof-blind toggle -->
                        <ng-container *ngSwitchCase="STAGE_ACTIONS.ROOF_BLIND">
                            <mhp-configuration-area-action-item
                                *ngIf="
                                    getActiveRoofBlindState$()
                                        | async as roofBlindState
                                "
                                [svgIcon]="
                                    roofBlindState === 'OPEN'
                                        ? 'mhp-ui:roof_blind_open'
                                        : 'mhp-ui:roof_blind_close'
                                "
                                iconSize="xlarge"
                                [tooltip]="
                                    t2(
                                        roofBlindState === 'OPEN'
                                            ? 'ROOF_BLIND_CLOSE_ALT'
                                            : 'ROOF_BLIND_OPEN_ALT'
                                    )
                                "
                                (itemSelect)="intentToggleRoofBlindState()"
                            >
                            </mhp-configuration-area-action-item>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <!-- collapse menu -->
                <mhp-configuration-area-action-item
                    class="engine-settings__bullets"
                    [ngClass]="{
                        'engine-settings__bullets--expanded':
                            (menuIsExtended$ | async) === true
                    }"
                    svgIcon="mhp-ui:more"
                    [tooltip]="
                        t2(
                            (menuIsExtended$ | async)
                                ? 'MENU_LESS_ALT'
                                : 'MENU_MORE_ALT'
                        )
                    "
                    (itemSelect)="intentToggleActionsMenu()"
                >
                </mhp-configuration-area-action-item>
            </mhp-configuration-area-action-group>

            <mhp-configuration-area-action-group
                *ngIf="(isPortraitOrientation$() | async) === false"
                class="configuration-main__action-group configuration-main__action-group--other-actions"
            >
                <mhp-configuration-area-action-item
                    class="configuration-main__action-group-item--available-large"
                    [svgIcon]="
                        (isStageShrinked$() | async) === false
                            ? 'mhp-ui:angle-up'
                            : 'mhp-ui:angle-down'
                    "
                    [tooltip]="
                        t2(
                            (isStageShrinked$() | async) === false
                                ? 'SHOW_MENU_ALT'
                                : 'HIDE_MENU_ALT'
                        )
                    "
                    (itemSelect)="intentToggleExpandState()"
                >
                </mhp-configuration-area-action-item>
            </mhp-configuration-area-action-group>
        </div>
    </ng-container>

    <!-- CONTENT FOR mhp-configuration-main-layout__stage-outlet -->
    <ng-container
        mhp-configuration-main-layout__stage-outlet
        *ngIf="getActiveVisualizationMode$() | async as activeVisualizationMode"
    >
        <mhp-basic-stage
            *ngIf="activeVisualizationMode === VISUALIZATION_MODE.BASIC"
        >
        </mhp-basic-stage>
        <mhp-stream-stage
            *ngIf="activeVisualizationMode === VISUALIZATION_MODE.STREAM"
        ></mhp-stream-stage>
    </ng-container>

    <!-- CONTENT FOR mhp-configuration-main-layout__stage-other -->
    <ng-container
        mhp-configuration-main-layout__stage-other
        *ngIf="(isDebug$() | async) === true"
    >
        <mhp-session-serializer-debug></mhp-session-serializer-debug>
        <mhp-visualization-mode-debug></mhp-visualization-mode-debug>
    </ng-container>

    <!-- CONTENT FOR mhp-configuration-main-layout__engine-settings-inner-stage -->
    <ng-container mhp-configuration-main-layout__engine-settings-inner-stage>
        <ng-container
            *ngIf="
                (isHandsetPortrait$() | async) === false &&
                !renderRaisedConfigurationBar
            "
        >
            <ng-container
                *ngIf="
                    getActiveInnerStageNavigationArea$()
                        | async as activeNavigationArea
                "
            >
                <mhp-engine-settings
                    @deferRemoval
                    [@.disabled]="
                        (disableDeferredRemovalAnimation$() | async) === true
                    "
                    *ngIf="activeNavigationArea === 'cameras'"
                    [title]="t('FEATURES.CAMERAS.SECTION')"
                    [items]="getCameras$() | async"
                    (selectItem)="intentSelectCamera($event)"
                ></mhp-engine-settings>
                <mhp-engine-settings
                    @deferRemoval
                    [@.disabled]="
                        (disableDeferredRemovalAnimation$() | async) === true
                    "
                    *ngIf="activeNavigationArea === 'animations'"
                    [title]="t('FEATURES.ANIMATIONS.SECTION')"
                    [items]="getAnimations$() | async"
                    (selectItem)="intentToggleAnimation($event)"
                ></mhp-engine-settings>
                <mhp-engine-settings
                    @deferRemoval
                    [@.disabled]="
                        (disableDeferredRemovalAnimation$() | async) === true
                    "
                    *ngIf="activeNavigationArea === 'environments'"
                    [title]="t('FEATURES.ENVIRONMENTS.SECTION')"
                    [items]="getEnvironments$() | async"
                    (selectItem)="intentSelectEnvironments($event)"
                    (showInfo)="intentShowEnvironmentInfo($event)"
                ></mhp-engine-settings>
            </ng-container>
        </ng-container>
    </ng-container>

    <!-- CONTENT FOR mhp-configuration-main-layout__configuration-bar -->
    <ng-container
        mhp-configuration-main-layout__configuration-bar
        [ngSwitch]="getConfigurationBarDisplayMode$() | async"
    >
        <mhp-configuration-bar
            *ngSwitchCase="'LARGE'"
            [revealNestedView]="
                (getActiveConfigurationNavigationArea$() | async) !== undefined
            "
            (revealNestedViewChange)="onRevealNestedViewChange($event)"
            (selectedTopLevelAreaChange)="onSelectedAreaChange($event)"
            (selectedCategoryPathChange)="onSelectedCategoryPathChange($event)"
            (toggleMenu)="intentToggleMenu()"
        >
            <div
                configurationBarNestedView
                class="configuration-main__engine-settings-container"
            >
                <mhp-engine-settings
                    @deferRemoval
                    [@.disabled]="
                        (disableDeferredRemovalAnimation$() | async) === true
                    "
                    *ngIf="
                        (getActiveConfigurationNavigationArea$() | async) ===
                        'cameras'
                    "
                    [title]="t('FEATURES.CAMERAS.SECTION')"
                    [items]="getCameras$() | async"
                    (selectItem)="intentSelectCamera($event)"
                    (doClose)="
                        intentToggleActiveConfigurationNavigationArea('cameras')
                    "
                ></mhp-engine-settings>
                <mhp-engine-settings
                    @deferRemoval
                    [@.disabled]="
                        (disableDeferredRemovalAnimation$() | async) === true
                    "
                    *ngIf="
                        (getActiveConfigurationNavigationArea$() | async) ===
                        'animations'
                    "
                    [title]="t('FEATURES.ANIMATIONS.SECTION')"
                    [items]="getAnimations$() | async"
                    (selectItem)="intentToggleAnimation($event)"
                    (doClose)="
                        intentToggleActiveConfigurationNavigationArea(
                            'animations'
                        )
                    "
                ></mhp-engine-settings>
                <mhp-engine-settings
                    @deferRemoval
                    [@.disabled]="
                        (disableDeferredRemovalAnimation$() | async) === true
                    "
                    *ngIf="
                        (getActiveConfigurationNavigationArea$() | async) ===
                        'environments'
                    "
                    [title]="t('FEATURES.ENVIRONMENTS.SECTION')"
                    [items]="getEnvironments$() | async"
                    (selectItem)="intentSelectEnvironments($event)"
                    (doClose)="
                        intentToggleActiveConfigurationNavigationArea(
                            'environments'
                        )
                    "
                    (showInfo)="intentShowEnvironmentInfo($event)"
                ></mhp-engine-settings>
            </div>
        </mhp-configuration-bar>

        <mhp-configuration-bar-compact
            *ngSwitchCase="'COMPACT'"
            (selectedCategoryPathChange)="onSelectedCategoryPathChange($event)"
            (selectedTopLevelAreaChange)="onSelectedAreaChange($event)"
        ></mhp-configuration-bar-compact>
    </ng-container>
</mhp-configuration-main-layout>
