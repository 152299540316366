import { Observable, combineLatest, iif, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AMLCameraMeta } from '@mhp/aml-shared/product-data/aml-camera-meta.interface';
import { AMLEnvironmentMeta } from '@mhp/aml-shared/product-data/aml-environment-meta.interface';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { ImageSrcset } from '@mhp/ui-components';
import { ProductDataService } from '@mhp/ui-shared-services';
import { EngineStateService } from '@mhp/ui-shared-services/engine/engine-state.service';

import { environment } from '../../../../environments/environment';
import { AmlGetAvailableCamerasOptions } from '../../../product-data/aml-product-data-strategy-impl';
import { StaticRendererService } from '../../static-renderer/static-renderer.service';
import { SummaryImageProvider } from './configuration-summary.component';

export interface ImgComparisonElement {
    neutralEnvImage: ImageSrcset | undefined;
    currentEnvImage: ImageSrcset | undefined;
}

/**
 * Service providing the images to be displayed in the summary.
 */
@Injectable()
export class SummaryImageProviderService implements SummaryImageProvider {
    constructor(
        protected readonly staticRendererService: StaticRendererService,
        protected readonly productDataService: ProductDataService<
            AMLCameraMeta,
            AMLEnvironmentMeta
        >,
        protected readonly engineStateService: EngineStateService
    ) {}

    @MemoizeObservable()
    getSummaryImages$(): Observable<ImageSrcset[] | undefined> {
        return this.getSummaryImagesInternal$().pipe(
            map((imageInfos) =>
                imageInfos?.map((imageInfo) => imageInfo.imageSrcset)
            )
        );
    }

    @MemoizeObservable()
    getSummaryComparisonImages$(): Observable<
        ImgComparisonElement[] | undefined
    > {
        return this.getSummaryComparisonImagesInternal$();
    }

    protected getSummaryImagesInternal$(
        environmentIdOverride?: string
    ): Observable<
        | {
              cameraId: string;
              imageSrcset: ImageSrcset;
          }[]
        | undefined
    > {
        return combineLatest([
            this.productDataService.getAvailableCameras$(<
                AmlGetAvailableCamerasOptions
            >{
                overrideTargetEnvironment: environmentIdOverride
            }),
            this.staticRendererService.getActiveSessionRenderingSrcsetFactory$()
        ]).pipe(
            map(([availableCameras, renderingSrcsetFactory]) => {
                if (!availableCameras || !renderingSrcsetFactory) {
                    return undefined;
                }

                return availableCameras.cameras.map((camera) => ({
                    cameraId: camera.id,
                    imageSrcset: renderingSrcsetFactory((sessionInfo) => ({
                        ...sessionInfo,
                        camera: {
                            id: camera.id,
                            options: {}
                        },
                        environment: {
                            ...sessionInfo.environment,
                            ...(environmentIdOverride
                                ? {
                                      id: environmentIdOverride
                                  }
                                : {})
                        }
                    }))
                }));
            }),
            lazyShareReplay()
        );
    }

    protected getSummaryComparisonImagesInternal$(
        targetEnvironmentId?: string
    ) {
        return (
            targetEnvironmentId
                ? of(targetEnvironmentId)
                : this.engineStateService.getActiveEnvironmentId$()
        ).pipe(
            switchMap((activeEnvironmentId) =>
                combineLatest([
                    // regular images
                    this.getSummaryImagesInternal$(activeEnvironmentId),
                    // comparison environment images
                    iif(
                        () =>
                            environment.appConfig.configuration
                                .imgComparisonEnvironmentId ===
                            activeEnvironmentId,
                        of(undefined),
                        this.getSummaryImagesInternal$(
                            environment.appConfig.configuration
                                .imgComparisonEnvironmentId
                        )
                    )
                ])
            ),
            map(
                ([
                    currentEnvironmentImageInfos,
                    comparisonEnvironmentImageInfos
                ]) => {
                    if (!currentEnvironmentImageInfos) {
                        return undefined;
                    }

                    let currentEnvironmentImageInfosWithMatchingComparisonEnvironmentImageInfos =
                        currentEnvironmentImageInfos;
                    if (comparisonEnvironmentImageInfos) {
                        // if we have comparison images available, keep only the images where we have an identical camera in the comparison-env as well
                        currentEnvironmentImageInfosWithMatchingComparisonEnvironmentImageInfos =
                            currentEnvironmentImageInfos.filter(
                                (currentEnvImageInfo) =>
                                    comparisonEnvironmentImageInfos?.find(
                                        (currentComparisonEnvironmentInfo) =>
                                            currentEnvImageInfo.cameraId ===
                                            currentComparisonEnvironmentInfo.cameraId
                                    )
                            );
                    }
                    return currentEnvironmentImageInfosWithMatchingComparisonEnvironmentImageInfos.map(
                        (currentRegularImageInfo): ImgComparisonElement => ({
                            currentEnvImage:
                                currentRegularImageInfo.imageSrcset,
                            neutralEnvImage:
                                comparisonEnvironmentImageInfos?.find(
                                    (currentComparisonEnvironmentImageInfo) =>
                                        currentRegularImageInfo.cameraId ===
                                        currentComparisonEnvironmentImageInfo.cameraId
                                )?.imageSrcset
                        })
                    );
                }
            )
        );
    }
}
