<div
    class="configuration-code-layout"
    [ngClass]="{
        'configuration-code-layout--selected': selectState,
        'configuration-code-layout--disabled': disabled,
        'configuration-code-layout--readonly': readonly,
        'configuration-code-layout--text': subType === 'text',
        'configuration-code-layout--image': subType === 'image',
        'configuration-code-layout--material': subType === 'material'
    }"
>
    <div
        *ngIf="subType === 'image'"
        class="configuration-code-layout__thumbnail"
    >
        <mhp-ui-grid-list-item
            [selected]="selectState"
            [disabled]="disabled"
            [readonly]="readonly"
            (intentSelect)="setSelection()"
            [imageUrl]="thumbnailImage"
            [imageAspectRatio]="ratioThumbnail"
            imageFit="cover"
            [matTooltip]="(debug ? code ?? '' : undefined) ?? ''"
        ></mhp-ui-grid-list-item>
    </div>

    <div
        *ngIf="subType === 'material'"
        class="configuration-code-layout__thumbnail"
    >
        <mhp-ui-grid-list-item
            [selected]="selectState"
            [disabled]="disabled"
            [readonly]="readonly"
            (intentSelect)="setSelection()"
            [imageUrl]="thumbnailImage"
            [imageAspectRatio]="ratioSplitter"
            imageFit="cover"
            [matTooltip]="(debug ? code ?? '' : undefined) ?? ''"
        ></mhp-ui-grid-list-item>
    </div>

    <div
        *ngIf="subType === 'text'"
        class="configuration-code-layout__text"
        (click)="setSelection()"
        matRipple
        [matTooltip]="(debug ? code ?? '' : undefined) ?? ''"
    >
        <span>{{ name }}</span>
        <mhp-ui-selected-marker
            [selected]="selectState"
            [markerSize]="ICON_SIZE.XXSMALL"
            color="primary"
        ></mhp-ui-selected-marker>
    </div>

    <ng-container *transloco="let t; read: 'ICONS'">
        <mhp-ui-icon-button
            *ngIf="hasAdditionalInfo"
            class="configuration-code-layout__info-button"
            [ngClass]="{
                'configuration-code-layout__info-button--info-always-visible':
                    forceInfoAlwaysVisible
            }"
            svgIcon="mhp-ui:info2"
            [size]="BUTTON_SIZE.XSMALL"
            [label]="
                t('INFO_ON_ALT', {
                    topic: name
                })
            "
            (click)="intentShowInfo()"
        ></mhp-ui-icon-button>
    </ng-container>
</div>
