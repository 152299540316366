import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ReservationData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/reservation-data.js';
import {
    AmlUiSharedState,
    EngineSessionData,
    One2oneSessionUrlService,
    SessionUrlService
} from '@mhp/aml-ui-shared-services';
import { ApplicationStateService } from '@mhp/ui-shared-services';

import { ConfigurationSessionInfoService } from '../../configuration/session-info/configuration-session-info.service';
import { selectOne2OneState } from '../../dealer/state/selectors/dealer-state.selectors';
import { LocalApplicationState } from '../../state/local-application-state.interface';

/**
 * Service to access session info in the one2one-context.
 */
@Injectable({
    providedIn: 'root'
})
export class One2oneSessionInfoService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<
            LocalApplicationState,
            AmlUiSharedState
        >,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly sessionUrlService: SessionUrlService,
        private readonly one2oneSessionUrlService: One2oneSessionUrlService
    ) {}

    /**
     * Emits whether one2one session data is available or not.
     */
    isOne2OneHostSessionActive$(): Observable<boolean> {
        return this.applicationStateService.getLocalState().pipe(
            selectOne2OneState,
            map((state) => !!state?.targetSessionData),
            distinctUntilChanged()
        );
    }

    /**
     * Emits whether one2one client session code is available or not.
     */
    isOne2OneClientSessionActive$(): Observable<boolean> {
        return this.applicationStateService.getLocalState().pipe(
            selectOne2OneState,
            map((state) => !!state?.clientSessionCode),
            distinctUntilChanged()
        );
    }

    /**
     * Returns the url used for the client to connect to a given session.
     * @param sessionData
     */
    getClientUrlToSession(sessionData: ReservationData) {
        return this.one2oneSessionUrlService.getClientUrlToSession(sessionData);
    }

    /**
     * Returns the url used for the dealer to connect to a given session.
     * @param sessionData
     * @param configurationInfo Optional starting configuration
     */
    getDealerUrlToSession(
        sessionData: ReservationData,
        configurationInfo?: EngineSessionData
    ) {
        return this.one2oneSessionUrlService.getDealerUrlToSession(
            sessionData,
            configurationInfo
        );
    }
}
