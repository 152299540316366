<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    #dialog
    *transloco="let t"
    [title]="t('BRAND_STORE.VR.DOCS.DIALOG.TITLE')"
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <markdown [src]="'assets/vr/vr-docs_en.md'">
        </markdown>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            (click)="dialog.close()"
            (keydown.enter)="dialog.close()"
        >
            <span>{{ t('ICONS.CLOSE_ALT') }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
