import { flattenDeep, uniqBy } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { isOptionCode } from '@mhp/ui-shared-services';

import {
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiOptionCode
} from '../../configuration-model/configuration-interfaces';
import { isContentAware } from '../../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../services/configuration-node-lookup.service';

export interface SearchOptions {
    skipAllOnEmptyBranchId: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class DisplayedOptionsService {
    constructor(
        private readonly configurationNodeLookupService: ConfigurationNodeLookupService
    ) {}

    /**
     * Emit the options that are contained below a given branch-id in the config-hierarchy.
     * @param branchId$
     * @param rootContentAwares$
     */
    getOptionsContainedInBranch$(
        branchId$: Observable<string | undefined>,
        rootContentAwares$: Observable<
            ExtendedUiContentAwareConfigurationMetaItem[] | undefined
        >,
        searchOptions?: SearchOptions
    ): Observable<ExtendedUiOptionCode[]> {
        return combineLatest([branchId$, rootContentAwares$]).pipe(
            map(([id, contentAwares]) =>
                this.getOptionsContainedInBranch(
                    id,
                    contentAwares,
                    searchOptions
                )
            )
        );
    }

    /**
     * Get the options that are contained below a given branch-id in the config-hierarchy.
     * @param branchId
     * @param rootContentAwares
     * @param searchOptions Optional search options
     */
    getOptionsContainedInBranch(
        branchId: string | undefined,
        rootContentAwares:
            | ExtendedUiContentAwareConfigurationMetaItem[]
            | undefined,
        searchOptions?: SearchOptions
    ): ExtendedUiOptionCode[] {
        if (!rootContentAwares) {
            return [];
        }

        if (branchId) {
            // limit to node
            const foundNode = this.configurationNodeLookupService.findNodeById(
                branchId,
                rootContentAwares
            )?.node;
            if (!foundNode) {
                return [];
            }

            if (!isContentAware(foundNode)) {
                return [];
            }

            return this.reduceContent(foundNode);
        }

        if (searchOptions?.skipAllOnEmptyBranchId) {
            return [];
        }

        return rootContentAwares.reduce(
            (foundNodes, currentContentAware) => [
                ...foundNodes,
                ...this.reduceContent(currentContentAware)
            ],
            []
        );
    }

    /**
     * Get the selected options in the given sub-branch.
     * @param branchId$
     * @param rootContentAwares$,
     * @param searchOptions Optional search options
     */
    getSelectedOptionsInBranch$(
        branchId$: Observable<string | undefined>,
        rootContentAwares$: Observable<
            ExtendedUiContentAwareConfigurationMetaItem[] | undefined
        >,
        searchOptions?: SearchOptions
    ): Observable<ExtendedUiOptionCode[]> {
        return this.getOptionsContainedInBranch$(
            branchId$,
            rootContentAwares$,
            searchOptions
        ).pipe(
            map((options) => options?.filter((option) => option.selected)),
            map((selectedOptions) =>
                uniqBy(selectedOptions, (option) => option.code)
            )
        );
    }

    /**
     * Get the count of selected options in the given sub-branch.
     * @param branchId$
     * @param rootContentAwares$
     */
    getSelectedOptionCountInBranch$(
        branchId$: Observable<string | undefined>,
        rootContentAwares$: Observable<
            ExtendedUiContentAwareConfigurationMetaItem[] | undefined
        >
    ): Observable<number> {
        return this.getSelectedOptionsInBranch$(
            branchId$,
            rootContentAwares$
        ).pipe(
            map((options) => options?.length || 0),
            distinctUntilChanged()
        );
    }

    private reduceContent(
        contentAware: ExtendedUiContentAwareConfigurationMetaItem
    ): ExtendedUiOptionCode[] {
        return flattenDeep(
            (<any[]>contentAware.content).reduce(
                (collectedOptions, currentItem) => {
                    if (isContentAware(currentItem)) {
                        collectedOptions.push(this.reduceContent(currentItem));
                    }
                    if (isOptionCode(currentItem)) {
                        collectedOptions.push(currentItem);
                    }
                    return collectedOptions;
                },
                []
            )
        );
    }
}
