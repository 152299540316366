import {
    RemoteAudioTrack,
    RemoteParticipant,
    RemoteTrack,
    RemoteVideoTrack
} from 'twilio-video';

import { Nullable } from '../common/types';
import { VideoSize } from './video-chat.interface';

/**
 * @param {RemoteTrack | null} track Track from remote participant
 * @returns {boolean} If the track exists and is attachable or not
 */
export const trackExistsAndIsAttachable = (
    track?: Nullable<RemoteTrack>
): track is RemoteAudioTrack | RemoteVideoTrack =>
    !!track &&
    ((track as RemoteAudioTrack).attach !== undefined ||
        (track as RemoteVideoTrack).attach !== undefined);

export const extractTracksFromRemoteParticipant = (
    participant: RemoteParticipant
): Nullable<RemoteTrack>[] => {
    console.log(participant);
    return [...participant.tracks.values()].map(
        (publication) => publication.track
    );
};

export const isVideoTrackFromRemoteSubscribed = (
    participant: RemoteParticipant
): boolean | null =>
    Array.from(participant.videoTracks.values()).map(
        (publication) => publication.isSubscribed
    )[0];

export const extractVideoTrackFromRemoteParticipant = (
    participant: RemoteParticipant
) => {
    const videoTrack: Nullable<RemoteVideoTrack> = Array.from(
        participant.videoTracks.values()
    ).map((publication) => publication.track)[0];

    return videoTrack;
};

interface AspectRatio {
    aspectWidth: number;
    aspectHeight: number;
}

export const calculateSizeByAspectRatio = (
    basedOn: 'width' | 'height',
    value: number,
    aspectRatio: AspectRatio
): VideoSize => {
    let calculatedWidth = value;
    let calculatedHeight = value;

    if (basedOn === 'width') {
        calculatedHeight =
            (value / aspectRatio.aspectWidth) * aspectRatio.aspectHeight;
    }
    if (basedOn === 'height') {
        calculatedWidth =
            (value / aspectRatio.aspectHeight) * aspectRatio.aspectWidth;
    }

    return {
        width: calculatedWidth,
        height: calculatedHeight
    };
};
