import {
    BrowserCacheLocation,
    Configuration,
    LogLevel
} from '@azure/msal-browser';

import { environment } from '../../../environments/environment';

const loggerCallback = (logLevel: LogLevel, message: string) => {
    console.log(message);
};

export const MSAL_CONFIGURATION: Configuration = Object.freeze({
    auth: {
        clientId: environment.appConfig.dealer.clientId,
        authority: environment.appConfig.dealer.authorities.signIn,
        redirectUri: environment.appConfig.dealer.redirectUrl,
        postLogoutRedirectUri: environment.appConfig.dealer.redirectUrl,
        knownAuthorities: [environment.appConfig.dealer.authorityDomain]
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        temporaryCacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false, // set to true for IE 11
        secureCookies: true
    },
    system: {
        loggerOptions: {
            loggerCallback,
            logLevel: LogLevel.Warning,
            piiLoggingEnabled: false
        }
    }
});
