// see ./environment.modules.ts
import { Route } from '@angular/router';

import {
    ROUTE_CONFIGURATION,
    ROUTE_DEALER_COMPLETE_ONE2ONE_REQUEST,
    ROUTE_ORDER_MANAGEMENT
} from '../app/app-route-names';
import { DealerModule } from '../app/dealer/dealer.module';
import { RegionSelectorGuard } from '../app/settings/region-selector/region-selector-guard';
import { WEB_CONFIGURATOR_ROUTES } from '../app/web-configurator/web-configurator-routing.module';
import { WebConfiguratorModule } from '../app/web-configurator/web-configurator.module';

export const SCOPE_DEPENDENT_MODULES = [WebConfiguratorModule, DealerModule];

const ROUTE_DEFINITION_DEALER_CONFIGURATION = {
    path: ROUTE_CONFIGURATION,
    canActivate: [RegionSelectorGuard],
    /*
     * When having a dealer-build, we load the ConfigurationModule eagerly and CONFIGURATION_ROUTES as children, for a regular build, we lazily load the ConfigurationModule
     */
    children: WEB_CONFIGURATOR_ROUTES,

    data: {
        animation: 'CONFIGURATION'
    }
};

export const ROUTE_DEFINITION_DEALER_COMPLETE_ONE2ONE_REQUEST = {
    path: ROUTE_DEALER_COMPLETE_ONE2ONE_REQUEST,
    canActivate: [RegionSelectorGuard],
    loadChildren: () =>
        import(
            '../app/dealer/one2one/session-confirmation/session-confirmation.module'
        ).then((m) => m.SessionConfirmationModule),
    data: {
        animation: ROUTE_DEALER_COMPLETE_ONE2ONE_REQUEST
    }
};

export const ROUTE_DEFINITION_DEALER_ORDER_MANAGEMENT = {
    path: ROUTE_ORDER_MANAGEMENT,
    canActivate: [RegionSelectorGuard],
    loadChildren: () =>
        import('../app/order-management/order-management.module').then(
            (m) => m.OrderManagementModule
        )
};

/**
 * Defines the routes that should be registered as region-children for the dealer-context.
 */
export const SCOPE_DEPENDENT_REGION_CHILD_ROUTES: Route[] = [
    ROUTE_DEFINITION_DEALER_CONFIGURATION,
    ROUTE_DEFINITION_DEALER_COMPLETE_ONE2ONE_REQUEST,
    ROUTE_DEFINITION_DEALER_ORDER_MANAGEMENT
];
