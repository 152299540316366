<span class="notification__message" [innerHtml]="message"></span>
<mhp-ui-icon-button
    *ngIf="showClose"
    class="notification__action-close"
    [svgIcon]="closeIconId"
    size="small"
    (click)="intentClose($event)"
></mhp-ui-icon-button>
<div
    class="notification__progress-indicator"
    [style.width.%]="(progress$ | async) * 100"
></div>
