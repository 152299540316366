import { filter } from 'rxjs/operators';
import { A11y, EffectFade, Mousewheel, Pagination } from 'swiper';

import { CommonModule } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import {
    UiIconButtonModule,
    UiLazyloadImageModule,
    UiSwiperModule,
    UiTooltipModule,
    UiTurntableModule
} from '@mhp/ui-components';
import {
    ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN,
    EngineControlStrategy,
    I18nModule,
    UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN,
    UiSharedStateLocalStrategy,
    UiSharedStateStrategy,
    UpdatableStrategyProvider
} from '@mhp/ui-shared-services';

import { VisualizationMode } from '../state';
import { StaticRendererModule } from '../static-renderer/static-renderer.module';
import { VisualizationModeService } from '../visualization-mode/visualization-mode.service';
import { BasicStageComponent } from './basic-stage/basic-stage.component';
import { EngineControlLocalStrategy } from './engine/engine-control-local-strategy';

UiSwiperModule.registerFeatures(EffectFade, Pagination, Mousewheel, A11y);

@NgModule({
    declarations: [BasicStageComponent],
    imports: [
        CommonModule,
        UiTurntableModule,
        StaticRendererModule,
        UiSwiperModule,
        UiLazyloadImageModule,
        UiIconButtonModule,
        I18nModule,
        UiTooltipModule
    ],
    exports: [BasicStageComponent],
    providers: []
})
export class BasicModule {
    constructor(
        private readonly visualizationModeService: VisualizationModeService,
        private readonly engineControlLocalStrategy: EngineControlLocalStrategy,
        @Inject(ENGINE_CONTROL_STRATEGY_PROVIDER_TOKEN)
        private readonly engineControlStrategyProvider: UpdatableStrategyProvider<EngineControlStrategy>,
        @Inject(UI_SHARED_STATE_STRATEGY_PROVIDER_TOKEN)
        private readonly uiSharedStateStrategyProvider: UpdatableStrategyProvider<
            UiSharedStateStrategy<AmlUiSharedState>
        >,
        private readonly uiSharedStateLocalStrategy: UiSharedStateLocalStrategy<AmlUiSharedState>
    ) {
        this.visualizationModeService
            .getTargetVisualizationMode$()
            .pipe(
                filter(
                    (
                        targetVisualizationMode
                    ): targetVisualizationMode is VisualizationMode.BASIC =>
                        targetVisualizationMode === VisualizationMode.BASIC
                )
            )
            .subscribe(() => {
                this.visualizationModeService.setActiveVisualizationMode(
                    VisualizationMode.BASIC
                );
            });

        this.visualizationModeService
            .getActiveVisualizationMode$()
            .pipe(
                filter(
                    (
                        activeVisualizationMode
                    ): activeVisualizationMode is VisualizationMode.BASIC =>
                        activeVisualizationMode === VisualizationMode.BASIC
                )
            )
            .subscribe((targetVisualizationMode) => {
                this.updateToBasicLogic();
            });
    }

    private updateToBasicLogic() {
        this.engineControlStrategyProvider.setStrategy(
            this.engineControlLocalStrategy,
            {
                type: 'BASIC'
            }
        );

        this.uiSharedStateStrategyProvider.setStrategy(
            this.uiSharedStateLocalStrategy,
            {
                type: 'BASIC'
            }
        );
    }
}
