import { range, values } from 'lodash-es';
import { Observable } from 'rxjs';
import { first, startWith } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { EngineSessionData } from '@mhp/aml-ui-shared-services';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { I18nService, L10nService } from '@mhp/ui-shared-services';

import { environment } from '../../../../../environments/environment';
import { getTranslatedTitlesList } from '../../../../common/forms/form-constants';
import { EMAIL_REGEX } from '../../../../common/validation/validation.helpers';

export interface DealerCreateOne2OneSessionData {
    description: string;
    country: string;
    title: string;
    firstName: string;
    lastName: string;
    customerEmail: string;
    customerPhone: string;
    sessionStart: Date;
    timeZone: string;
    startConfiguration: EngineSessionData | undefined;
}

export interface StartConfiguration {
    sessionInfo: EngineSessionData;
}

@Component({
    selector: 'mhp-create-one2one-session',
    templateUrl: './create-one2one-session.component.html',
    styleUrls: ['./create-one2one-session.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateOne2oneSessionComponent
    extends UiBaseComponent
    implements OnInit
{
    @Input()
    serverCallInProgress: boolean;

    @Input()
    initializingData?: Partial<DealerCreateOne2OneSessionData>;

    @Output()
    readonly createSession = new EventEmitter<DealerCreateOne2OneSessionData>();

    readonly one2oneFormGroup: UntypedFormGroup;

    readonly availableTitles = getTranslatedTitlesList();

    readonly availableTimeEntries = range(0, 1440, 30);

    constructor(
        private readonly l10nService: L10nService,
        private readonly i18nService: I18nService
    ) {
        super();

        this.one2oneFormGroup = this.initFormGroup();

        this.completeOnDestroy(this.createSession);
    }

    ngOnInit() {
        let { initializingData } = this;

        if (!environment.production) {
            initializingData = this.patchInitializingData(initializingData);
        }

        if (initializingData) {
            this.initializeFormValues(initializingData);
        }
    }

    @MemoizeObservable()
    getActiveLang$(): Observable<string> {
        return this.i18nService.getActiveLang$();
    }

    intentSubmit() {
        values(this.one2oneFormGroup.controls).forEach((control) =>
            control.markAllAsTouched()
        );

        if (!this.one2oneFormGroup.valid) {
            return;
        }

        const preferredDate: Date =
            this.one2oneFormGroup.get('preferredDate')?.value;
        const preferredTime = this.one2oneFormGroup.get('preferredTime')?.value;

        let sessionStart = new Date(
            new Date(
                preferredDate.getFullYear(),
                preferredDate.getMonth(),
                preferredDate.getDate()
            ).getTime() +
                preferredTime * 60 * 1000
        );

        if (this.one2oneFormGroup.get('scheduleNow')?.value === true) {
            // schedule in x minutes in the future + 1 minute safety-buffer
            const minutesInFuture =
                environment.appConfig.one2one.bookSessionNowMinutesInFuture;
            sessionStart = new Date(
                new Date().getTime() + minutesInFuture * 60 * 1000
            );
        }

        const formValue = this.one2oneFormGroup.value;

        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        const sessionData: DealerCreateOne2OneSessionData = {
            description: `${formValue.firstName} ${formValue.lastName}`,
            country: formValue.country,
            title: formValue.title,
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            customerEmail: formValue.customerEmail,
            customerPhone: formValue.customerPhone,
            sessionStart,
            timeZone,
            startConfiguration: formValue.startConfiguration
        };
        this.createSession.emit(sessionData);
    }

    formatTimeEntry(minutesIn: number) {
        const hours = Math.floor(minutesIn / 60);
        const minutes = Math.floor(minutesIn) % 60;

        return (
            [hours, minutes]
                .map((v) => (v < 10 ? `0${v}` : v))
                // .filter((v, i) => v !== '00' || i > 0)
                .join(':')
        );
    }

    private patchInitializingData(
        initializingData?: Partial<DealerCreateOne2OneSessionData>
    ): Partial<DealerCreateOne2OneSessionData> {
        return {
            title: `Mr`,
            firstName: 'Test',
            lastName: `Customer ${new Date().toISOString()}`,
            customerEmail: 'test@email.com',
            customerPhone: '0221211199393',
            ...initializingData
        };
    }

    private initFormGroup() {
        const preselectedDate = new Date();

        let preselectedMinutes =
            Math.ceil(preselectedDate.getMinutes() / 30) * 30;
        let preselectedHoursInMinutes = preselectedDate.getHours() * 60;

        if (preselectedMinutes === 60) {
            preselectedMinutes = 0;
            preselectedHoursInMinutes += 60;
        }

        const formGroup = new UntypedFormGroup({
            // salesforce-fields
            country: new UntypedFormControl(undefined, Validators.required),
            title: new UntypedFormControl(undefined, Validators.required),
            firstName: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(40)
            ]),
            lastName: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(40)
            ]),
            customerEmail: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(40),
                Validators.pattern(EMAIL_REGEX)
            ]),
            customerPhone: new UntypedFormControl(undefined, [
                Validators.required,
                Validators.maxLength(15),
                Validators.pattern('[- +()0-9]+')
            ]),
            // required for both
            preferredDate: new UntypedFormControl(new Date(), [
                Validators.required
            ]),
            preferredTime: new UntypedFormControl(
                preselectedHoursInMinutes + preselectedMinutes,
                [Validators.required]
            ),
            // predefined configuration
            startConfiguration: new UntypedFormControl(undefined),

            // used internally
            scheduleNow: new UntypedFormControl(false)
        });

        this.l10nService
            .getActiveCountry$()
            .pipe(first())
            .subscribe((country) =>
                formGroup.get('country')?.setValue(country)
            );

        formGroup
            .get('scheduleNow')
            ?.valueChanges.pipe(startWith(formGroup.get('scheduleNow')?.value))
            .subscribe((doScheduleNow) => {
                ['preferredDate', 'preferredTime'].forEach((formControl) =>
                    doScheduleNow
                        ? formGroup.get(formControl)?.disable()
                        : formGroup.get(formControl)?.enable()
                );
            });

        return formGroup;
    }

    private initializeFormValues(
        initializingData: Partial<DealerCreateOne2OneSessionData>
    ) {
        this.one2oneFormGroup.patchValue({
            title: initializingData.title,
            firstName: initializingData.firstName,
            lastName: initializingData.lastName,
            customerEmail: initializingData.customerEmail,
            customerPhone: initializingData.customerPhone,
            startConfiguration: initializingData.startConfiguration
        });

        if (initializingData.sessionStart) {
            this.one2oneFormGroup.patchValue({
                preferredDate: new Date(
                    initializingData.sessionStart.getFullYear(),
                    initializingData.sessionStart.getMonth(),
                    initializingData.sessionStart.getDate()
                ),
                preferredTime:
                    initializingData.sessionStart.getHours() * 60 +
                    initializingData.sessionStart.getMinutes()
            });
        }
    }
}
