/* eslint-disable max-classes-per-file */
import isBoolean from 'lodash-es/isBoolean';

import { ConfigurationResponse } from '@mhp/aml-shared/infor/interfaces/configuration-response.interface';
import { CustomError } from '@mhp/common';

/**
 * Base class for order-errors.
 */
export class OrderError extends CustomError<ConfigurationResponse> {
    /**
     * Test the given payload if it fulfills the ConfigurationResponse contract.
     * @param payload The payload to be checked.
     */
    public static isConfigurationResponse(
        payload?: unknown
    ): payload is ConfigurationResponse {
        if (!payload) {
            return false;
        }
        const configResponseCandidate = payload as ConfigurationResponse;
        return (
            !!configResponseCandidate.pages &&
            !!configResponseCandidate.sessionID &&
            isBoolean(configResponseCandidate.isExecutionComplete)
        );
    }

    /**
     * Test if the given payload has an error-message.
     * @param payload The payload to be checked.
     */
    public static hasErrorMessage(
        payload?: unknown
    ): payload is { errorMessage: string } {
        if (!payload) {
            return false;
        }
        return !!(payload as { errorMessage: string }).errorMessage;
    }

    constructor(
        message: string,
        details?: ConfigurationResponse,
        public readonly cause?: unknown
    ) {
        super(message, details);
    }
}

/**
 * Error thrown in case an order could not be created.
 */
export class OrderNewVehicleError extends OrderError {
    constructor(
        message: string,
        details?: ConfigurationResponse,
        cause?: unknown
    ) {
        super(message, details, cause);
    }
}

/**
 * Error thrown in case an order could not be updated.
 */
export class UpdateOrderError extends OrderError {
    constructor(
        message: string,
        details?: ConfigurationResponse,
        cause?: unknown
    ) {
        super(message, details, cause);
    }
}
