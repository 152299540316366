import { isString, isUndefined } from 'lodash-es';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    startWith,
    switchMap,
    take
} from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getCountryISOCodeByCountryName } from '@mhp/aml-shared/country-mapping/country-mapping';
import { FRIDGE_LABEL_COUNTRIES_SET } from '@mhp/aml-shared/country-mapping/fridge-label-countries';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import { UiMatDialogService } from '@mhp/ui-components';
import {
    ApplicationStateService,
    ErrorHandlerService,
    I18nService,
    L10nService
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../environments/environment';
import { FridgeLabelDialogComponent } from '../configuration/configuration-area/fridge-label/fridge-label-dialog/fridge-label-dialog.component';

interface DisclaimerEntry {
    [id: string]: string | DisclaimerEntry;
}

/**
 * Disclaimer service provides access to localized versions of disclaimer texts.
 */
@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class DisclaimerService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly applicationStateService: ApplicationStateService,
        private readonly i18nService: I18nService,
        private readonly l10nService: L10nService,
        private readonly dialogService: UiMatDialogService,
        private readonly errorHandlerService: ErrorHandlerService
    ) {}

    @MemoizeObservable()
    getDisclaimer$(key: string): Observable<string | null> {
        return this.getDisclaimerInternal$().pipe(
            map((disclaimer) => {
                if (!disclaimer) {
                    return null;
                }
                try {
                    const subKeys = key.split('.');
                    let value = disclaimer[subKeys.shift() as string];
                    for (const subKey of subKeys) {
                        value = value[subKey];
                        if (!value) {
                            return null;
                        }
                    }
                    return value as string;
                } catch (ex) {
                    return null;
                }
            }),
            distinctUntilChanged(),
            untilDestroyed(this)
        );
    }

    /**
     * Show the fridge-label dialog for the current productId and country to the user.
     * In case the current country does not provide fridge-labels, the returned observable immediately completes.
     * @param productId The active product id either as static string or Observable.
     */
    showFridgeLabelDialog$(productId: string | Observable<string>) {
        return this.getFridgeLabelUrlForActiveCountry$(productId).pipe(
            take(1),
            switchMap((fridgeLabelUrl) => {
                if (!fridgeLabelUrl) {
                    return EMPTY;
                }
                return this.dialogService.openFullscreen$(
                    FridgeLabelDialogComponent,
                    {
                        data: {
                            fridgeLabelUrl
                        }
                    }
                );
            })
        );
    }

    /**
     * Emits true/false to indicate whether there is a fridge-label available for
     * the active country.
     */
    @MemoizeObservable()
    isFridgeLabelAvailableForActiveCountry$(
        productId: string | Observable<string>
    ) {
        return this.getFridgeLabelUrlForActiveCountry$(productId).pipe(
            map((fridgeLabelUrl) => !!fridgeLabelUrl),
            lazyShareReplay()
        );
    }

    private getFridgeLabelUrlForActiveCountry$(
        productId: string | Observable<string>
    ): Observable<string | undefined> {
        return combineLatest([
            this.l10nService.getActiveCountry$(),
            isString(productId) ? of(productId) : productId
        ]).pipe(
            switchMap(([region, localProductId]) => {
                if (!region) {
                    throw new IllegalStateError(
                        'Failed to retrieve local state, region or productId'
                    );
                }

                if (!FRIDGE_LABEL_COUNTRIES_SET.has(region)) {
                    return of(undefined);
                }

                return this.getDisclaimer$(
                    `${localProductId}.SUMMARY.FRIDGE_LABEL`
                );
            }),
            map((fridgeLabelPath): string | undefined => {
                if (!fridgeLabelPath) {
                    return undefined;
                }
                return `${environment.appConfig.assets.url}/${fridgeLabelPath}`;
            }),
            // check fridge-label-file existence
            switchMap((fridgeLabelUrl) => {
                if (!fridgeLabelUrl) {
                    return of(undefined);
                }
                return this.httpClient.head(fridgeLabelUrl).pipe(
                    map(() => fridgeLabelUrl),
                    catchError(() => of(undefined))
                );
            })
        );
    }

    @MemoizeObservable()
    private getDisclaimerInternal$(): Observable<DisclaimerEntry | null> {
        return combineLatest([
            this.applicationStateService.getLocalState().pipe(
                map((state) => state.settings.activeRegion),
                filter((region) => !!region),
                distinctUntilChanged()
            ),
            this.i18nService.getActiveLang$()
        ]).pipe(
            untilDestroyed(this),
            switchMap(
                ([region, language]): Observable<
                    DisclaimerEntry | null | undefined
                > =>
                    this.httpClient
                        .get<DisclaimerEntry>(
                            `${
                                environment.appConfig.assets.url
                            }/assets/disclaimers/${language.toLowerCase()}/${(
                                getCountryISOCodeByCountryName(region) || region
                            ).toUpperCase()}/disclaimers_${
                                environment.appConfig.dealer.dealerBuild
                                    ? 'dealer'
                                    : 'public'
                            }.json`
                        )
                        .pipe(
                            this.errorHandlerService.applyRetry({
                                skipGlobalErrorNotificationCallback: () => true
                            }),
                            map((disclaimerEntry) => disclaimerEntry ?? null),
                            // reset the previously emitted value to prevent stale data for a different country being emitted
                            startWith(undefined)
                        )
            ),
            shareReplay(1),
            filter(
                (disclaimerEntry): disclaimerEntry is DisclaimerEntry | null =>
                    !isUndefined(disclaimerEntry)
            )
        );
    }
}
