import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ConfidentialDealerFeatureDirective } from './confidential-dealer-feature.directive';
import { DealerFeatureClassDirective } from './dealer-feature-class.directive';
import { DealerFeatureDirective } from './dealer-feature.directive';
import { IfOrderPossibleDirective } from './if-order-possible.directive';

@NgModule({
    declarations: [
        DealerFeatureDirective,
        DealerFeatureClassDirective,
        ConfidentialDealerFeatureDirective,
        IfOrderPossibleDirective
    ],
    imports: [CommonModule],
    exports: [
        DealerFeatureDirective,
        DealerFeatureClassDirective,
        ConfidentialDealerFeatureDirective,
        IfOrderPossibleDirective
    ]
})
export class DealerHelperModule {}
