import { Observable, catchError, iif, of } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    EngineSessionData,
    SessionUrlService
} from '@mhp/aml-ui-shared-services';
import { UiMatDialogConfig, UiMatDialogService } from '@mhp/ui-components';
import { SerializerService } from '@mhp/ui-shared-services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { JSONSchema, StorageMap } from '@ngx-pwa/local-storage';

import { environment } from '../../../environments/environment';
import { ROUTE_CONFIGURATION } from '../../app-route-names';
import { BACKDROP_CLASS_BLURRY } from '../../common/dialog/dialog.constants';
import { REGION_GLOBAL } from '../../settings/region-selector/region-constants';
import { DEFAULT_RENDERING_ADJUSTMENTS } from '../static-renderer/static-renderer-adjustments.constants';
import { StaticRendererService } from '../static-renderer/static-renderer.service';
import {
    RestoreLocalConfigurationSessionDialogComponent,
    RestoreLocalConfigurationSessionDialogData
} from './restore-local-configuration-session-dialog/restore-local-configuration-session-dialog.component';

const STORAGE_PREFIX = 'AML_CONFIG_';

interface PersistedSessionData {
    persistenceKey: string;
    serializedSessionData: string;
}

const sessionDataSchema: JSONSchema = {
    type: 'string'
};

const RESTORE_LOCAL_CONFIGURATION_SESSION_DIALOG_OPTIONS: UiMatDialogConfig = {
    backdropClass: BACKDROP_CLASS_BLURRY,
    panelClass: 'mhp-ui-modal-panel--stretch-buttons',
    width: '800px',
    maxWidth: 'calc(100vw - 40px)'
};

/**
 * Service that tracks the currently active configuration and persists it to
 * local storage.
 * Also provides the option to start the configuration-process, taking into account
 * a possibly persisted configuration to resume on.
 */
@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class ConfigurationSessionLocalPersistenceService {
    constructor(
        private readonly storageMap: StorageMap,
        private readonly serializerService: SerializerService,
        private readonly router: Router,
        private readonly sessionUrlService: SessionUrlService,
        private readonly dialogService: UiMatDialogService,
        private readonly staticRendererService: StaticRendererService
    ) {}

    /**
     * Persist the given session in a local storage.
     * @param sessionData The session-data to be persisted.
     */
    persistSession$(
        sessionData: EngineSessionData
    ): Observable<PersistedSessionData> {
        const toBePersisted: PersistedSessionData = {
            persistenceKey: `${sessionData.country}_${sessionData.config.id}`,
            serializedSessionData:
                this.serializerService.serializeData<EngineSessionData>(
                    sessionData
                )
        };

        return this.storageMap
            .set(
                `${STORAGE_PREFIX}${toBePersisted.persistenceKey}`,
                toBePersisted.serializedSessionData,
                sessionDataSchema
            )
            .pipe(
                map(() => toBePersisted),
                shareReplay()
            );
    }

    /**
     * Clears a given session.
     *
     * @param country The country in question.
     * @param productId The product in question.
     */
    clearSession$(country: string, productId: string): Observable<void> {
        const persistenceKey = `${STORAGE_PREFIX}${country}_${productId}`;
        return this.storageMap.delete(persistenceKey);
    }

    /**
     * Load a previously persisted session for a given country and productId.
     * @param country The country in question.
     * @param productId The product in question.
     */
    loadSession$(
        country: string,
        productId: string
    ): Observable<EngineSessionData | undefined> {
        return this.storageMap
            .get<string | undefined>(
                `${STORAGE_PREFIX}${country}_${productId}`,
                sessionDataSchema
            )
            .pipe(
                map((serializedSessionData) => {
                    if (!serializedSessionData) {
                        return undefined;
                    }
                    return this.serializerService.deserializeData<EngineSessionData>(
                        serializedSessionData
                    );
                })
            );
    }

    /**
     * Start the configuration for a given country, product and configuration,
     * checking for an existing persisted configuration beforehand, giving
     * the user the choice to start from scratch or resume the existing config.
     *
     * @param country The country to work on.
     * @param productId The productId to load
     * @param defaultConfigurationString The optional default-configuration to load
     *                                   in case the user wants to start from scratch.
     */
    startConfigurationWithUserDecision$(
        country: string,
        productId: string,
        defaultConfigurationString: string | undefined
    ): Observable<'NEW' | 'RESUME'> {
        const startNewRoutingCommands = [
            '/',
            REGION_GLOBAL,
            ROUTE_CONFIGURATION,
            productId,
            defaultConfigurationString
        ];

        const forceNewFlow$ = of({
            userDecision: <'NEW' | 'RESUME'>'NEW',
            targetRouteCommands: startNewRoutingCommands
        });

        const interactiveFlow$ = this.loadSession$(country, productId).pipe(
            take(1),
            switchMap((persistedSessionInfo) => {
                if (!persistedSessionInfo) {
                    return of(undefined);
                }
                return this.staticRendererService
                    .getRenderingSrcset$(
                        persistedSessionInfo,
                        undefined,
                        DEFAULT_RENDERING_ADJUSTMENTS
                    )
                    .pipe(
                        map((renderingSrcset) => ({
                            persistedSessionInfo,
                            renderingSrcset
                        }))
                    );
            }),
            switchMap((sessionInfo) => {
                const userDecision$: Observable<'NEW' | 'RESUME'> =
                    !sessionInfo?.renderingSrcset
                        ? of('NEW')
                        : this.dialogService
                              .open$<
                                  RestoreLocalConfigurationSessionDialogComponent,
                                  RestoreLocalConfigurationSessionDialogData
                              >(
                                  RestoreLocalConfigurationSessionDialogComponent,
                                  {
                                      ...RESTORE_LOCAL_CONFIGURATION_SESSION_DIALOG_OPTIONS,
                                      data: {
                                          referenceIodSrcset:
                                              sessionInfo.renderingSrcset
                                      }
                                  }
                              )
                              .pipe(
                                  switchMap(
                                      (componentRef) =>
                                          componentRef.componentInstance
                                              .userDecision
                                  )
                              );

                return userDecision$.pipe(
                    switchMap((userDecision) =>
                        iif(
                            () => !!sessionInfo && userDecision === 'NEW',
                            // clear session
                            this.clearSession$(country, productId).pipe(
                                catchError(() => of(undefined)),
                                map(() => userDecision)
                            ),
                            // just continue
                            of(userDecision)
                        )
                    ),
                    take(1),
                    map((userDecision) => {
                        const targetRouteCommands =
                            userDecision === 'NEW' || !sessionInfo
                                ? startNewRoutingCommands
                                : this.sessionUrlService.buildUrlCommands(
                                      sessionInfo.persistedSessionInfo
                                  );

                        return {
                            userDecision,
                            targetRouteCommands
                        };
                    })
                );
            })
        );

        return iif(
            () =>
                environment.appConfig.featureToggles.enableResumeConfiguration,
            interactiveFlow$,
            forceNewFlow$
        ).pipe(
            switchMap(async (result) => {
                const routingResult = await this.router.navigate(
                    result.targetRouteCommands,
                    {
                        queryParamsHandling: 'merge'
                    }
                );
                if (!routingResult) {
                    throw new Error('Navigation to configuration route failed');
                }
                return result.userDecision;
            })
        );
    }
}
