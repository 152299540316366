import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    UiButtonModule,
    UiIconButtonModule,
    UiIconsModule,
    UiTooltipModule
} from '@mhp/ui-components';

import { ConfigurationAreaActionGroupComponent } from './configuration-area-action-group/configuration-area-action-group.component';
import { ConfigurationAreaActionItemComponent } from './configuration-area-action-item/configuration-area-action-item.component';

@NgModule({
    declarations: [
        ConfigurationAreaActionGroupComponent,
        ConfigurationAreaActionItemComponent
    ],
    exports: [
        ConfigurationAreaActionGroupComponent,
        ConfigurationAreaActionItemComponent
    ],
    imports: [
        CommonModule,
        UiIconButtonModule,
        UiIconsModule,
        UiTooltipModule,
        UiButtonModule
    ]
})
export class ConfigurationAreaActionsModule {}
