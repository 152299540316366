import { isEqual, max, min, range } from 'lodash-es';
import {
    BehaviorSubject,
    Observable,
    combineLatest,
    of,
    switchMap
} from 'rxjs';
import {
    delay,
    distinctUntilChanged,
    filter,
    map,
    startWith,
    take
} from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FinancingType } from '@mhp/aml-shared/data-proxy/financial-services/financing-type.enum';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { ImageSrcset, UiNotificationService } from '@mhp/ui-components';
import { L10nService } from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LabelHelperService } from '../../../i18n/label-helper.service';
import { ConfigurationSummaryService } from '../../configuration-summary/configuration-summary.service';
import { DEFAULT_RENDERING_ADJUSTMENTS } from '../../static-renderer/static-renderer-adjustments.constants';
import { StaticRendererService } from '../../static-renderer/static-renderer.service';
import { FinancialServicesInputService } from '../financial-services-input.service';
import { FinancialServicesSessionService } from '../financial-services-session.service';
import { FinancialServiceOfferUk } from '../financial-services-uk/financial-services-uk.interfaces';
import {
    FinancialServiceOfferInputBase,
    OfferRow
} from '../financial-services.interfaces';

type FinancingTypeNoneSelection = 'NONE';

interface FinanceOptions {
    typeOfFinance: FormControl<FinancingType | FinancingTypeNoneSelection>;
    termInMonths: FormControl<number>;
    // mileage does only apply to PCP
    mileage: FormControl<number | null | undefined>;
    monthlyRate: FormControl<number | null | undefined>;
    cashDeposit: FormControl<number | null | undefined>;
}

// Keep the constants for UK PCP and HP locally to be able to later lazy-load the required parts when needed (region-dependent)
// See https://mhpimmersive.atlassian.net/browse/AMIF-512
const UK_TERMS_PCP = range(24, 49, 6); // 24 - 48, 6 months steps
const UK_TERMS_HP = [...UK_TERMS_PCP, 60]; // PCP + 60 months

const UK_MILES_PER_ANNUM = [6000, 8000, 10000, 12000, 15000, 20000];

@UntilDestroy()
@Component({
    selector: 'mhp-settings-dialog',
    templateUrl: './settings-dialog.component.html',
    styleUrls: ['./settings-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsDialogComponent {
    readonly FinancingType = FinancingType;

    readonly TYPE_OF_FINANCE_NONE: FinancingTypeNoneSelection = 'NONE';

    @ViewChild('monthlyRateInput')
    monthlyRateElementRef: ElementRef<HTMLInputElement>;

    @ViewChild('cashDepositInput')
    cashDepositElementRef: ElementRef<HTMLInputElement>;

    foFormGroup: FormGroup<FinanceOptions>;

    /** holds the state for the currently active edit-mode:
     * if user wants to enter a monthly rate or the cash-deposit he has available
     */
    private readonly activeEditModeSubject = new BehaviorSubject<
        'MONTHLY_RATE' | 'CASH_DEPOSIT' | undefined
    >(undefined);

    private initialSettingsOnDialogOpen:
        | FinancialServiceOfferInputBase
        | undefined;

    constructor(
        private readonly matDialogRef: MatDialogRef<unknown>,
        public readonly labelHelperService: LabelHelperService,
        private readonly staticRendererService: StaticRendererService,
        private readonly financialServicesSessionService: FinancialServicesSessionService<FinancialServiceOfferUk>,
        private readonly financialServicesInputService: FinancialServicesInputService<FinancialServiceOfferInputBase>,
        private readonly summaryService: ConfigurationSummaryService,
        public readonly l10nService: L10nService,
        private readonly uiNotificationService: UiNotificationService
    ) {
        this.initFormGroupWithInitialOrderInput();
        this.initResetToInitialSettingsUponCancel();
    }

    @MemoizeObservable()
    getConfigurationRendering$(): Observable<ImageSrcset | undefined> {
        return this.staticRendererService.getActiveSessionRenderingSrcset$(
            undefined,
            DEFAULT_RENDERING_ADJUSTMENTS
        );
    }

    @MemoizeObservable()
    getTermValues$(): Observable<number[]> {
        return this.getFinancialServicesOfferInput$().pipe(
            map((input) =>
                input.input.typeOfFinance ===
                FinancingType.PERSONAL_CONTRACT_PURCHASE
                    ? UK_TERMS_PCP
                    : UK_TERMS_HP
            )
        );
    }

    @MemoizeObservable()
    getMileageValues$(): Observable<number[] | undefined> {
        return this.getFinancialServicesOfferInput$().pipe(
            map((input) =>
                input.input.typeOfFinance ===
                FinancingType.PERSONAL_CONTRACT_PURCHASE
                    ? UK_MILES_PER_ANNUM
                    : undefined
            )
        );
    }

    @MemoizeObservable()
    getOfferRows$(): Observable<OfferRow[] | undefined> {
        return combineLatest([
            this.summaryService.getPricingSummaryModel$(),
            this.financialServicesSessionService.getFinancialServiceOfferRowRepresentation$()
        ]).pipe(
            map(([pricingSummaryModel, offerRows]): OfferRow[] | undefined => {
                if (!pricingSummaryModel || !offerRows) {
                    return undefined;
                }

                return [
                    {
                        label: 'Cash Price',
                        value: this.formatCurrencyWithFallback(
                            pricingSummaryModel.pricing?.price
                        )
                    },
                    ...offerRows
                ];
            }),
            lazyShareReplay()
        );
    }

    intentApplySettings() {
        if (!this.updateFinancialOfferInput(this.foFormGroup)) {
            return;
        }

        this.matDialogRef.close('OK');
    }

    intentChangeRateCalculation(
        calculationType: 'MONTHLY_RATE' | 'CASH_DEPOSIT'
    ) {
        this.activeEditModeSubject.next(calculationType);

        // focus appropriate input after changes in disabled state have been rendered
        setTimeout(() => {
            if (calculationType === 'MONTHLY_RATE') {
                this.monthlyRateElementRef.nativeElement.focus();
            } else {
                this.cashDepositElementRef.nativeElement.focus();
            }
        }, 0);
    }

    private createFinanceOptionsFormGroup(
        referenceInput: FinancialServiceOfferInputBase
    ): FormGroup<FinanceOptions> {
        const formGroup = new FormGroup<FinanceOptions>({
            typeOfFinance: new FormControl<
                FinancingType | FinancingTypeNoneSelection
            >(referenceInput.typeOfFinance, {
                nonNullable: true
            }),
            termInMonths: new FormControl<number>(referenceInput.termInMonths, {
                nonNullable: true
                // validators are added in #initFormLogic
            }),
            // only valid for PCP
            mileage: new FormControl<number | null | undefined>(
                referenceInput.mileage,
                {
                    nonNullable: false,
                    validators: [
                        // required-validator is added / removed in #initFormLogic
                        Validators.min(min(UK_MILES_PER_ANNUM) as number),
                        Validators.max(max(UK_MILES_PER_ANNUM) as number)
                    ]
                }
            ),
            cashDeposit: new FormControl<number | null | undefined>(
                {
                    value: referenceInput.cashDeposit,
                    disabled: !referenceInput.cashDeposit
                },
                {
                    nonNullable: true,
                    validators: Validators.required
                }
            ),
            monthlyRate: new FormControl<number | null | undefined>(
                {
                    value: referenceInput.monthlyRate,
                    disabled: !referenceInput.monthlyRate
                },
                {
                    nonNullable: true,
                    validators: Validators.required
                }
            )
        });

        // set initial state for active edit mode
        this.activeEditModeSubject.next(
            referenceInput.monthlyRate ? 'MONTHLY_RATE' : 'CASH_DEPOSIT'
        );

        // init form logic
        this.initFormLogic(formGroup);

        return formGroup;
    }

    private updateFinancialOfferInput(
        formGroup: FormGroup<FinanceOptions>
    ): boolean {
        if (!formGroup.valid) {
            return false;
        }

        const updatedInputParameters = formGroup.value;

        this.getFinancialServicesOfferInput$()
            .pipe(
                take(1),
                // only update in case input has changed to current
                filter(
                    (activeInput) =>
                        activeInput.isDefault ||
                        !isEqual(activeInput.input, updatedInputParameters)
                ),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.financialServicesInputService.updateFinancialServiceOfferInput(
                    updatedInputParameters.typeOfFinance === 'NONE'
                        ? undefined
                        : (updatedInputParameters as FinancialServiceOfferInputBase)
                );
            });

        return true;
    }

    private formatCurrencyWithFallback(value: number | undefined): string {
        return value ? this.l10nService.formatCurrency(value) : 'N/A';
    }

    private initFormLogic(formGroup: FormGroup<FinanceOptions>) {
        const typeOfFinanceValueChange$ =
            formGroup.controls.typeOfFinance.valueChanges.pipe(
                startWith(formGroup.controls.typeOfFinance.value),
                untilDestroyed(this)
            );

        // handle select NONE for type of finance
        typeOfFinanceValueChange$.subscribe((value) => {
            // enable / disable other controls
            Object.entries(formGroup.controls).forEach(([key, control]) => {
                if (key === 'typeOfFinance') {
                    return;
                }
                if (value === 'NONE') {
                    control.disable();
                } else {
                    control.enable();
                }
            });

            if (value !== 'NONE') {
                // re-emit the current edit-mode to re-adjust the bound enabled / disabled state
                this.activeEditModeSubject.next(
                    this.activeEditModeSubject.value
                );
            }
        });

        // handle change in PCP vs HP
        // PCP allows selection of mileage, HP disallows it
        typeOfFinanceValueChange$.subscribe((value) => {
            if (
                value === FinancingType.PERSONAL_CONTRACT_PURCHASE &&
                !formGroup.controls.mileage.hasValidator(Validators.required)
            ) {
                // add required validator
                formGroup.controls.mileage.addValidators(Validators.required);
            } else {
                // remove required validator
                formGroup.controls.mileage.removeValidators(
                    Validators.required
                );
            }
        });
        // PCP has different range of term-selection than HP
        typeOfFinanceValueChange$.subscribe((value) => {
            const referenceTermValue =
                value === FinancingType.PERSONAL_CONTRACT_PURCHASE
                    ? UK_TERMS_PCP
                    : UK_TERMS_HP;

            formGroup.controls.termInMonths.setValidators([
                Validators.required,
                Validators.min(min(referenceTermValue) as number),
                Validators.max(max(referenceTermValue) as number)
            ]);

            // check if data is still in range
            formGroup.controls.termInMonths.setValue(
                referenceTermValue.indexOf(
                    formGroup.controls.termInMonths.value
                ) > -1
                    ? formGroup.controls.termInMonths.value
                    : referenceTermValue[0]
            );
        });

        // handle change of active edit mode
        this.activeEditModeSubject
            .pipe(untilDestroyed(this))
            .subscribe((activeEditorMode) => {
                const skipEvents = {
                    emitEvent: false
                };
                if (activeEditorMode === 'MONTHLY_RATE') {
                    formGroup.controls.cashDeposit.disable(skipEvents);
                    formGroup.controls.monthlyRate.enable(skipEvents);
                    formGroup.controls.cashDeposit.setValue(
                        undefined,
                        skipEvents
                    );
                } else {
                    formGroup.controls.monthlyRate.disable(skipEvents);
                    formGroup.controls.cashDeposit.enable(skipEvents);
                    formGroup.controls.monthlyRate.setValue(
                        undefined,
                        skipEvents
                    );
                }
                formGroup.updateValueAndValidity();
            });

        // handle case where offer-data differs from the input-values
        this.financialServicesSessionService
            .getFinancialServiceOffer$()
            .pipe(untilDestroyed(this))
            .subscribe((serviceOffer) => {
                if (!serviceOffer) {
                    return;
                }

                let notification: string | undefined;
                let adjustedToMin: boolean;

                const currentMonthlyRateValue =
                    formGroup.controls.monthlyRate.value;
                const currentCashDepositValue =
                    formGroup.controls.cashDeposit.value;
                const currentMileageValue = formGroup.controls.mileage.value;

                if (
                    this.activeEditModeSubject.value === 'MONTHLY_RATE' &&
                    currentMonthlyRateValue &&
                    currentMonthlyRateValue !== serviceOffer.monthlyRate
                ) {
                    formGroup.controls.monthlyRate.setValue(
                        serviceOffer.monthlyRate,
                        {
                            emitEvent: false
                        }
                    );
                    // FIXME: Translate / use messages provided by backend
                    adjustedToMin =
                        currentMonthlyRateValue < serviceOffer?.monthlyRate;
                    notification = `The regular payment has been adjusted to ${this.l10nService.formatCurrency(
                        serviceOffer.monthlyRate
                    )}, the ${
                        adjustedToMin ? 'minimum' : 'maximum'
                    } allowable.`;
                } else if (
                    this.activeEditModeSubject.value === 'CASH_DEPOSIT' &&
                    currentCashDepositValue &&
                    currentCashDepositValue !== serviceOffer?.totalDeposit
                ) {
                    formGroup.controls.cashDeposit.setValue(
                        serviceOffer.totalDeposit
                    );
                    // FIXME: Translate / use messages provided by backend
                    adjustedToMin =
                        currentCashDepositValue < serviceOffer.totalDeposit;
                    notification = `The deposit has been adjusted to ${this.l10nService.formatCurrency(
                        serviceOffer.totalDeposit
                    )}, the ${
                        adjustedToMin ? 'minimum' : 'maximum'
                    } allowable.`;
                }

                if (
                    currentMileageValue !== serviceOffer?.mileage &&
                    serviceOffer.mileage
                ) {
                    formGroup.controls.mileage.setValue(serviceOffer.mileage);
                    // FIXME: Translate / use messages provided by backend
                    adjustedToMin =
                        !currentMileageValue ||
                        currentMileageValue < serviceOffer?.mileage;
                    notification = `The mileage has been adjusted to ${
                        serviceOffer.mileage
                    }, the ${adjustedToMin ? 'minimum' : 'maximum'} allowable.`;
                }

                if (notification) {
                    this.uiNotificationService.showAutohideNotification(
                        notification,
                        5000
                    );
                }
            });

        // on form changes, update financial offer input
        formGroup.valueChanges
            .pipe(
                // only emit in case form is valid
                filter(() => formGroup.valid),
                switchMap((formValue) =>
                    of(undefined).pipe(
                        // only wait in case we have valid offer input data
                        delay(formValue.typeOfFinance === 'NONE' ? 0 : 1000)
                    )
                ),
                untilDestroyed(this),
                startWith(undefined)
            )
            .subscribe(() => {
                this.updateFinancialOfferInput(formGroup);
            });
    }

    private initFormGroupWithInitialOrderInput() {
        this.getFinancialServicesOfferInput$()
            .pipe(take(1), untilDestroyed(this))
            .subscribe((input) => {
                // store the initial settings that can be restored when cancelling the process
                this.initialSettingsOnDialogOpen = input.isDefault
                    ? undefined
                    : input.input;

                this.foFormGroup = this.createFinanceOptionsFormGroup(
                    input.input
                );
            });
    }

    @MemoizeObservable()
    getFinancialServicesOfferInput$(): Observable<{
        input: FinancialServiceOfferInputBase;
        isDefault: boolean;
    }> {
        return combineLatest([
            this.financialServicesInputService.getFinancialServiceOfferInput$(),
            this.financialServicesInputService.getFinancialServiceOfferDefaultInput$()
        ]).pipe(
            untilDestroyed(this),
            map(([existingInput, defaultInput]) => ({
                input: existingInput ?? defaultInput,
                isDefault: !existingInput
            })),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    private initResetToInitialSettingsUponCancel() {
        this.matDialogRef
            .beforeClosed()
            .pipe(take(1))
            .subscribe((result) => {
                if (result === 'OK') {
                    return;
                }
                // cancelled - restore settings
                this.financialServicesInputService.updateFinancialServiceOfferInput(
                    this.initialSettingsOnDialogOpen
                );
            });
    }
}
