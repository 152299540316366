import { EMPTY, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, pairwise, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Params,
    Router
} from '@angular/router';
import { translate } from '@jsverse/transloco';
import { ReservationData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/reservation-data.js';
import {
    AmlUiSharedState,
    One2oneSessionBookingService
} from '@mhp/aml-ui-shared-services';
import {
    ApplicationStateService,
    ErrorHandlerService
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { selectOne2OneState } from '../state/selectors/dealer-state.selectors';

@Injectable({
    providedIn: 'root'
})
export class One2oneLocationSyncService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<
            LocalApplicationState,
            AmlUiSharedState
        >,
        private readonly sessionBookingService: One2oneSessionBookingService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) {
        this.initClearOne2OneSessionSearchParamsOnSessionEnd();
    }

    extractReservationKey(route: ActivatedRouteSnapshot) {
        return route.queryParams[
            environment.appConfig.one2one.dealerReservationKeyQueryParamName
        ];
    }

    resolveReservationDataFromUrl$(
        route: ActivatedRouteSnapshot
    ): Observable<ReservationData | undefined> {
        const reservationKey = this.extractReservationKey(route);

        if (!reservationKey) {
            return of(undefined);
        }

        // resolve reservation-data
        return this.sessionBookingService.getSession$(reservationKey).pipe(
            this.errorHandlerService.applyRetry({
                messageProviderOnFinalError: () =>
                    translate('ONE_2_ONE.ERRORS.RESERVATION_KEY_NOT_VALID')
            })
        );
    }

    /**
     * When the session-state got cleared, remove the relevant search-parameters
     * from the URL.
     * @private
     */
    private initClearOne2OneSessionSearchParamsOnSessionEnd() {
        this.applicationStateService
            .getLocalState()
            .pipe(
                selectOne2OneState,
                map(
                    (one2oneState) =>
                        one2oneState?.targetSessionData?.mainConnection
                            .connectionKey
                ),
                distinctUntilChanged(),
                pairwise(),
                switchMap(([previous, current]) => {
                    if (!previous || current) {
                        return EMPTY;
                    }
                    // has been cleared so make sure we remove the relevant search-params from the url
                    const queryParamsToBeRemoved: Params = {};
                    [
                        environment.appConfig.crm.salesforce
                            .transactionKeyQueryParamName,
                        environment.appConfig.one2one
                            .dealerReservationKeyQueryParamName
                    ].forEach((searchParamName) => {
                        queryParamsToBeRemoved[searchParamName] = null;
                    });

                    return this.router.navigate([], {
                        relativeTo: this.activatedRoute,
                        queryParams: queryParamsToBeRemoved,
                        queryParamsHandling: 'merge'
                    });
                })
            )
            .subscribe();
    }
}
