import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { UiButtonModule, UiIconsModule } from '@mhp/ui-components';

import { MenuTocComponent } from './menu-toc.component';

@NgModule({
    declarations: [MenuTocComponent],
    imports: [CommonModule, UiButtonModule, MatRippleModule, UiIconsModule],
    exports: [MenuTocComponent]
})
export class MenuTocModule {}
