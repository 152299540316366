import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Output
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MemoizeObservable } from '@mhp/common';
import { ImageSrcset } from '@mhp/ui-components';
import { I18nService } from '@mhp/ui-shared-services';

import { AmlBreakpoints } from '../../../common/breakpoints/AmlBreakpoints';

export interface RestoreLocalConfigurationSessionDialogData {
    referenceIodSrcset: ImageSrcset;
}

@Component({
    selector: 'mhp-restore-local-configuration-session-dialog',
    templateUrl: './restore-local-configuration-session-dialog.component.html',
    styleUrls: ['./restore-local-configuration-session-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestoreLocalConfigurationSessionDialogComponent {
    @Output()
    readonly userDecision = new EventEmitter<'NEW' | 'RESUME'>();

    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        private readonly i18nService: I18nService,
        @Inject(MAT_DIALOG_DATA)
        public readonly data?: RestoreLocalConfigurationSessionDialogData
    ) {}

    intentResume() {
        this.userDecision.emit('RESUME');
    }

    intentStartNew() {
        this.userDecision.emit('NEW');
    }

    @MemoizeObservable()
    useShortCtaLabels$(): Observable<boolean> {
        return combineLatest([
            this.breakpointObserver.observe([
                AmlBreakpoints.HandsetPortrait,
                AmlBreakpoints.TabletPortrait
            ]),
            this.i18nService.getActiveLang$()
        ]).pipe(map(([matcher, lang]) => matcher.matches || lang === 'ja'));
    }
}
