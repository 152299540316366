import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    UiButtonModule,
    UiIconButtonModule,
    UiTooltipModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { SearchTermComponent } from './search-term/search-term.component';
import { SearchComponent } from './search.component';

@NgModule({
    declarations: [SearchComponent, SearchTermComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        UiButtonModule,
        UiIconButtonModule,

        I18nModule,
        UiTooltipModule
    ],
    exports: [SearchComponent, SearchTermComponent]
})
export class SearchModule {}
