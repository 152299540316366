<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    class="pricing-options-dialog"
    *transloco="let t"
    [title]="t('ORDER_MANAGEMENT.FORM_DIALOG.HEADLINE')"
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <p>{{ t('ORDER_MANAGEMENT.FORM_DIALOG.DESCRIPTION') }}</p>
        <ng-container *ngFor="let formGroup of configData.formGroups">
            <ng-container *ngIf="formGroup.formOption">
                <mhp-order-configuration-form-field
                    [formOption]="formGroup.formOption"
                    [orderFormGroup]="orderFormGroup"
                    optionNameToFocus=""
                    [showPricingDNP]="false"
                    [showPricingRRP]="false"
                    (selectionChange)="onSelectionChange($event)"
                    currency=""
                    [isDialog]="true"
                ></mhp-order-configuration-form-field>
            </ng-container>
        </ng-container>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-button
            class="mhp-ui-button--full-width"
            (click)="onCancel()"
        >
            {{ t('COMMON.CANCEL') }}
        </button>
        <button
            mat-flat-button
            class="mhp-ui-button--full-width"
            (click)="intentConfirm()"
        >
            <span>{{ t('COMMON.CONFIRM') }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
