import { Observable, defer, merge, of } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationNavigationArea } from '@mhp/aml-ui-shared-services';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';

import {
    ROUTE_CONFIGURATION,
    ROUTE_CONFIGURATION_SESSION,
    ROUTE_MODEL_SELECT
} from '../app-route-names';

@Injectable({
    providedIn: 'root'
})
export class NavigationStateService {
    constructor(private readonly router: Router) {}

    /**
     * Emit the currently active ApplicationNavigationArea.
     * Note that during navigation, this observable might emit "stale" data
     * as it updates only after the routers NavigationEnd event.
     */
    @MemoizeObservable()
    getActiveApplicationNavigationArea$(): Observable<
        ApplicationNavigationArea | undefined
    > {
        return merge(
            this.router.events.pipe(
                filter(
                    (event): event is NavigationEnd =>
                        event instanceof NavigationEnd
                ),
                map(
                    (navigationEndEvent) => navigationEndEvent.urlAfterRedirects
                )
            ),
            defer(() => of(this.router.url))
        ).pipe(
            map((urlToCheck) => {
                if (
                    new RegExp(`.*/${ROUTE_MODEL_SELECT}/.*`).test(urlToCheck)
                ) {
                    return ApplicationNavigationArea.MODEL_SELECTION;
                }
                if (
                    new RegExp(`.*/${ROUTE_CONFIGURATION}/.*`).test(
                        urlToCheck
                    ) ||
                    new RegExp(`.*/${ROUTE_CONFIGURATION_SESSION}/.*`).test(
                        urlToCheck
                    )
                ) {
                    return ApplicationNavigationArea.CONFIGURATION;
                }
                return undefined;
            }),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }
}
