import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'mhp-section-title',
    templateUrl: './section-title.component.html',
    styleUrls: ['./section-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionTitleComponent {
    @Input()
    showDescription: boolean;

    @Input()
    dataAvailable: boolean;

    @Input()
    showPricingDNP: boolean;

    @Input()
    showPricingRRP: boolean;
}
