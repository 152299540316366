import { Observable, combineLatest, of, switchMap } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ConfigurationCompositeItemModel } from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import { MemoizeObservable } from '@mhp/common';
import { L10nService, StaticAssetService } from '@mhp/ui-shared-services';

import { AmlImagesService } from '../../../../common/images/aml-images.service';
import { ConfigurationSessionImageAssetService } from '../../../assets/configuration-session-image-asset.service';
import { ExtendedUiOptionGroup } from '../../../configuration-model/configuration-interfaces';
import { SearchService } from '../../../search/search.service';
import { ConfigurationSessionInfoService } from '../../../session-info/configuration-session-info.service';
import { StaticRendererService } from '../../../static-renderer/static-renderer.service';
import { ConfigurationCompositeItemMapper } from './configuration-composite-item-mapper';

/**
 * Service that handles mapping of OptionGroup / OptionCollection structure to
 * ConfigurationCompositeItems.
 */
@Injectable()
export class ConfigurationCompositeItemService {
    constructor(
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly basicRendererService: StaticRendererService,
        private readonly l10nService: L10nService,
        private readonly staticAssetService: StaticAssetService,
        private readonly amlImagesService: AmlImagesService,
        private readonly searchService: SearchService,
        private readonly configurationSessionImageAssetService: ConfigurationSessionImageAssetService
    ) {}

    /**
     * Map a given content-aware model to ConfigurationCompositeItemModels.
     */
    mapToConfigurationCompositeItemModels$(
        contentAware$: Observable<ExtendedUiOptionGroup | undefined>
    ): Observable<ConfigurationCompositeItemModel[] | undefined> {
        return combineLatest([
            contentAware$,
            this.configurationSessionInfoService.getActiveConfigurationSessionInfo$()
        ]).pipe(
            map(([contentAware, activeSessionInfo]) => {
                if (!contentAware || !activeSessionInfo) {
                    return undefined;
                }

                const mapper = new ConfigurationCompositeItemMapper(
                    activeSessionInfo.engineData,
                    this.basicRendererService,
                    this.staticAssetService,
                    this.configurationSessionImageAssetService
                );

                return mapper.mapContentAware(contentAware);
            }),
            // take active search into account to highlight matched search-term in child-options
            switchMap((itemModels) => {
                if (!itemModels) {
                    return of(undefined);
                }
                return combineLatest(
                    itemModels.map(
                        (
                            itemModel
                        ): Observable<ConfigurationCompositeItemModel> => {
                            if (!itemModel.options) {
                                return of(itemModel);
                            }

                            // need to create observables for each sub-option
                            return combineLatest(
                                itemModel.options.map((itemOption) =>
                                    this.searchService
                                        .getLabelWithSearchTermHighlighted$(
                                            itemOption.label
                                        )
                                        .pipe(
                                            map((adjustedLabel) => ({
                                                ...itemOption,
                                                labelHtml: adjustedLabel
                                            }))
                                        )
                                )
                            ).pipe(
                                // wait a tick for all search-term adjustments to be available
                                debounceTime(0),
                                map((itemOptions) => {
                                    itemModel.options = itemOptions;
                                    return itemModel;
                                })
                            );
                        }
                    )
                );
            })
        );
    }

    @MemoizeObservable()
    getCompositeItemFallbackImageUrl$() {
        return this.amlImagesService.getModelSpecificFallbackImageUrl$();
    }
}
