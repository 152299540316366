<footer class="footer" *transloco="let t">
    <div class="footer__columns">
        <nav class="footer__navigation">
            <ul class="footer__navigation-list">
                <li class="footer__navigation-list-item">
                    {{ t('COMMON.FOOTER.COPYRIGHT') }}&nbsp;{{ copyrightYear }}
                </li>
                <li class="footer__navigation-list-item">
                    <a
                        class="footer__navigation-link"
                        [href]="getTermsAndConditionsLink$() | async"
                        target="_blank"
                        [trackGA4Event]="{
                            event: 'show_terms_and_conditions_click'
                        }"
                    >
                        {{ t('COMMON.FOOTER.TERMS_AND_CONDITIONS') }}
                    </a>
                </li>
                <li class="footer__navigation-list-item">
                    <a
                        class="footer__navigation-link"
                        [href]="getPrivacyLink$() | async"
                        target="_blank"
                        [trackGA4Event]="{
                            event: 'show_privacy_policy_click'
                        }"
                    >
                        {{ t('COMMON.FOOTER.PRIVACY') }}
                    </a>
                </li>
                <li class="footer__navigation-list-item">
                    <a
                        class="footer__navigation-link"
                        href=""
                        (click)="
                            $event.preventDefault(); intentShowCookieInfo()
                        "
                        [trackGA4Event]="{
                            event: 'show_cookie_info_click'
                        }"
                    >
                        {{ t('COMMON.FOOTER.COOKIES') }}
                    </a>
                </li>
                <li class="footer__navigation-list-item">
                    <a
                        class="footer__navigation-link"
                        href=""
                        (click)="
                            $event.preventDefault(); intentHandleRegionSelect()
                        "
                        [trackGA4Event]="{
                            event: 'select_region_language_click'
                        }"
                    >
                        {{ t('COMMON.FOOTER.MARKET_AND_LANGUAGE') }}
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <section class="footer__disclaimer">
        <p class="footer__disclaimer-paragraph">
            {{ getGenericDisclaimer$() | async }}
        </p>
        <p
            class="footer__disclaimer-paragraph"
            *ngIf="hasFridgeLabel$() | async"
        >
            <a
                class="footer__emission-information-link"
                href
                (click)="intentOpenFridgeLabelDialog(); $event.preventDefault()"
            >
                {{ t('COMMON.FOOTER.SHOW_EMISSION_INFORMATION') }}
            </a>
        </p>
        <p class="footer__disclaimer-paragraph">
            {{ getEmissionFiguresDisclaimer$() | async }}
        </p>
        <p class="footer__disclaimer-paragraph">
            {{ getCountryEmissionParagraphDisclaimer$() | async }}
        </p>
        <p class="footer__disclaimer-paragraph">
            {{ getEmissionParagraphDisclaimer$() | async }}
        </p>
    </section>
    <div class="footer__poweredby">
        <div class="footer__poweredby-text">
            {{ t('COMMON.FOOTER.POWERED_BY') }}
        </div>
        <img
            class="footer__poweredby-logo"
            src="assets/images/model-select/mhp-logo.svg"
            width="90"
            height="22"
            alt="{{ t('COMMON.FOOTER.MHP_LOGO') }}"
        />
    </div>
    <mhp-configuration-area-action-item
        class="footer__action-back-to-top"
        svgIcon="mhp-ui:go-to-top"
        [ngClass]="{
            'footer__action-back-to-top--visible':
                (getBackToTopVisibilityState$() | async) === true
        }"
        [tooltip]="t('ICONS.BACK_TO_TOP_ALT')"
        (itemSelect)="intentBackToTop()"
    >
    </mhp-configuration-area-action-item>
</footer>
