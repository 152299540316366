<ng-container *transloco="let t">
    <mhp-ui-mat-dialog
        *transloco="
            let tAudioVideoSettingsDialog;
            read: 'AUDIO_VIDEO_SETTINGS.DIALOG'
        "
        [title]="tAudioVideoSettingsDialog('TITLE')"
        [showClose]="false"
        class="audio-video-settings-dialog"
    >
        <mhp-ui-mat-dialog-content>
            <div class="audio-video-settings-dialog__video-container">
                <mhp-local-participant-track
                    class="audio-video-settings-dialog__video-container__video"
                >
                </mhp-local-participant-track>
                <div
                    class="audio-video-settings-dialog__video-container__control"
                >
                    <mhp-audio-video-settings-form-control></mhp-audio-video-settings-form-control>
                </div>
            </div>

            <div
                class="audio-video-settings-dialog__error-container"
                *ngIf="showDeviceError$() | async"
            >
                <mhp-ui-icon
                    svgIcon="mhp-ui:warning"
                    [size]="ICON_SIZE.MEDIUM"
                ></mhp-ui-icon>
                <div>
                    {{ tAudioVideoSettingsDialog('DEVICE_ERROR') }}
                </div>
            </div>
        </mhp-ui-mat-dialog-content>
        <mhp-ui-mat-dialog-actions-right>
            <button
                mat-flat-button
                (click)="intentConfirm()"
                [disabled]="(serverCallInProgress$ | async) === true"
                class="audio-video-settings-dialog__action-button mhp-ui-button--full-width"
            >
                {{ t('COMMON.CONFIRM') }}
            </button>
        </mhp-ui-mat-dialog-actions-right>
    </mhp-ui-mat-dialog>
</ng-container>
