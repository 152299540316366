import { cloneDeep } from 'lodash-es';
import { Observable, map } from 'rxjs';

import { ConfigModel } from '@mhp-immersive-exp/contracts/src';
import {
    NestedCode,
    OptionCode,
    OptionCollection,
    OptionGroup,
    OptionList
} from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { AmlOptionMetadata } from '@mhp/aml-shared/configuration/aml-option-metadata';
import { IllegalStateError } from '@mhp/common';
import {
    ConfigurationInfo,
    I18nService,
    isOptionCode,
    isOptionCollection,
    isOptionGroup,
    isOptionList
} from '@mhp/ui-shared-services';

import {
    isCodeAware,
    isExtendedUiNestedCode,
    isExtendedUiOptionList
} from '../services/configuration-helper';
import {
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiNestedCode,
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection,
    ExtendedUiOptionGroup,
    ExtendedUiOptionList
} from './configuration-interfaces';

export class ExtendedUiOptionGroupMapper {
    private static buildPath(path: string[]): string {
        return path.join('-');
    }

    constructor(
        private readonly i18nService: I18nService,
        private readonly modelId: string,
        private readonly productId: string
    ) {}

    mapOptionGroups$(
        configurationInfo: ConfigurationInfo<OptionGroup>
    ): Observable<ExtendedUiOptionGroup[]> {
        const clonedOptionGroups = cloneDeep(configurationInfo.configuration);

        return this.i18nService
            .getActiveLang$()
            .pipe(
                map(() =>
                    clonedOptionGroups.map((optionGroup, index) =>
                        this.mapOptionGroup(optionGroup, index, [], [])
                    )
                )
            );
    }

    private mapOptionGroup(
        optionGroup: OptionGroup,
        index: number,
        path: string[],
        hierarchyPath: ExtendedUiContentAwareConfigurationMetaItem[]
    ): ExtendedUiOptionGroup {
        path.push(`${index}`);

        const mappedContent: (
            | ExtendedUiOptionCode
            | ExtendedUiOptionCollection
            | ExtendedUiOptionGroup
            | ExtendedUiOptionList
        )[] = [];

        const mappedOptionGroup: ExtendedUiOptionGroup = {
            ...optionGroup,
            id: ExtendedUiOptionGroupMapper.buildPath(path),
            visuallyHidden: false,
            content: mappedContent,
            nameTranslated: this.translateCode(
                optionGroup.name,
                optionGroup.name
            ),
            hierarchyPath
        };

        const hierarchyPathWithSelf = [...hierarchyPath, mappedOptionGroup];

        optionGroup.content
            .map((currentContent, localIndex) => {
                if (isOptionGroup(currentContent)) {
                    return this.mapOptionGroup(
                        currentContent,
                        localIndex,
                        [...path],
                        hierarchyPathWithSelf
                    );
                }
                if (isOptionCollection(currentContent)) {
                    return this.mapOptionCollection(
                        currentContent,
                        localIndex,
                        [...path],
                        hierarchyPathWithSelf
                    );
                }
                if (isOptionList(currentContent)) {
                    return this.mapOptionList(
                        currentContent,
                        [...path],
                        hierarchyPathWithSelf
                    );
                }
                if (isOptionCode(currentContent)) {
                    return this.mapOptionCode(
                        currentContent,
                        [...path],
                        hierarchyPathWithSelf
                    );
                }
                throw new IllegalStateError(
                    `Unknown node type`,
                    currentContent
                );
            })
            .forEach((item) => mappedContent.push(item));

        return mappedOptionGroup;
    }

    private mapOptionCollection(
        optionCollection: OptionCollection,
        index: number,
        path: string[],
        hierarchyPath: ExtendedUiContentAwareConfigurationMetaItem[]
    ): ExtendedUiOptionCollection {
        path.push(`${index}`);

        const mappedContent: (
            | ExtendedUiOptionCode
            | ExtendedUiOptionCollection
            | ExtendedUiOptionList
        )[] = [];

        const mappedOptionCollection: ExtendedUiOptionCollection = {
            ...optionCollection,
            id: ExtendedUiOptionGroupMapper.buildPath(path),
            visuallyHidden: false,
            content: mappedContent,
            nameTranslated: this.translateCode(
                optionCollection.name ?? '',
                optionCollection.name ?? ''
            ),
            hierarchyPath
        };

        const hierarchyPathWithSelf = [
            ...hierarchyPath,
            mappedOptionCollection
        ];

        optionCollection.content
            .map((currentContent, localIndex) => {
                if (isOptionCode(currentContent)) {
                    return this.mapOptionCode(
                        currentContent,
                        [...path],
                        hierarchyPathWithSelf
                    );
                }
                if (isOptionCollection(currentContent)) {
                    return this.mapOptionCollection(
                        currentContent,
                        localIndex,
                        [...path],
                        hierarchyPathWithSelf
                    );
                }
                if (isOptionList(currentContent)) {
                    return this.mapOptionList(
                        currentContent,
                        [...path],
                        hierarchyPathWithSelf
                    );
                }
                throw new IllegalStateError(
                    `Unknown node type`,
                    currentContent
                );
            })
            .forEach((item) => mappedContent.push(item));

        return mappedOptionCollection;
    }

    private mapOptionList(
        optionList: OptionList,
        path: string[],
        hierarchyPath: ExtendedUiContentAwareConfigurationMetaItem[]
    ): ExtendedUiOptionList {
        path.push(optionList.code);

        const mappedContent: ExtendedUiNestedCode[] = [];

        const mappedOptionList: ExtendedUiOptionList = {
            ...optionList,
            id: ExtendedUiOptionGroupMapper.buildPath(path),
            visuallyHidden: false,
            content: mappedContent,
            hierarchyPath,
            nameTranslated: this.translateCode(
                optionList.code,
                optionList.name
            ),
            descriptionTranslated: this.translateDescription(optionList.code)
        };

        const hierarchyPathWithSelf = [...hierarchyPath, mappedOptionList];

        optionList.content
            .map((nestedCode) =>
                this.mapNestedCode(nestedCode, [...path], hierarchyPathWithSelf)
            )
            .forEach((item) => mappedContent.push(item));

        return mappedOptionList;
    }

    private mapNestedCode(
        nestedCode: NestedCode,
        path: string[],
        hierarchyPath: ExtendedUiContentAwareConfigurationMetaItem[]
    ): ExtendedUiNestedCode {
        path.push(nestedCode.code);

        const mappedCodes: ExtendedUiOptionCode[] = [];

        const mappedNestedCode: ExtendedUiNestedCode = {
            ...nestedCode,
            id: ExtendedUiOptionGroupMapper.buildPath(path),
            visuallyHidden: false,
            content: mappedCodes,
            hierarchyPath,
            nameTranslated: this.translateCode(
                nestedCode.code,
                nestedCode.name
            ),
            descriptionTranslated: this.translateDescription(nestedCode.code)
        };

        const hierarchyPathWithSelf = [...hierarchyPath, mappedNestedCode];

        nestedCode.content
            .map((optionCode) =>
                this.mapOptionCode(optionCode, [...path], hierarchyPathWithSelf)
            )
            .forEach((item) => mappedCodes.push(item));

        return mappedNestedCode;
    }

    private mapOptionCode(
        optionCode: OptionCode<AmlOptionMetadata>,
        path: string[],
        hierarchyPath: ExtendedUiContentAwareConfigurationMetaItem[]
    ): ExtendedUiOptionCode {
        path.push(optionCode.code);

        const optionPath = ExtendedUiOptionGroupMapper.buildPath(path);

        const codeDotNotation = [
            ...hierarchyPath.map((parent) => {
                if (isCodeAware(parent)) {
                    return parent.code;
                }
                return '';
            }),
            optionCode.code
        ].join('.');

        let configModel: ConfigModel = optionCode.code;
        if (hierarchyPath.length >= 2) {
            const [parentList, parentNestedCode] = hierarchyPath.slice(-2);
            if (
                isExtendedUiOptionList(parentList) &&
                isExtendedUiNestedCode(parentNestedCode)
            ) {
                configModel = {
                    [parentList.code]: {
                        [parentNestedCode.code]: optionCode.code
                    }
                };
            }
        }

        return {
            ...optionCode,
            id: optionPath,
            codeDotNotation,
            configModel,
            hierarchyPath,
            visuallyHidden: false,
            nameTranslated: this.translateCode(
                optionCode.code,
                optionCode.name ?? ''
            ),
            descriptionTranslated: this.translateDescription(
                optionCode.code,
                optionCode.meta?.translationPrefix
            ),
            categoryTranslated: this.translateCategory(optionCode.code)
        };
    }

    /**
     * Looks into CONFIGURATION.${modelId}.${productId}.CODE.${code}, then CONFIGURATION.${modelId}.CODE.${code}, then CONFIGURATION.COMMON.${code}, then uses fallback
     * @param code
     * @param fallback
     * @private
     */
    private translateCode(code: string, fallback: string) {
        return this.i18nService.translateWithFallback(
            `CONFIGURATION.${this.modelId}.${this.productId}.CODE.${code}`,
            this.i18nService.translateWithFallback(
                `CONFIGURATION.${this.modelId}.CODE.${code}`,
                this.i18nService.translateWithFallback(
                    `CONFIGURATION.COMMON.${code}`,
                    fallback
                )
            )
        );
    }

    /**
     * Looks into
     * - CONFIGURATION.${modelId}.${productId}.CODE.${translationPrefix}${code}_DESCRIPTION
     * - CONFIGURATION.${modelId}.CODE.${translationPrefix}${code}_DESCRIPTION
     * - CONFIGURATION.COMMON.CODE.${translationPrefix}${code}_DESCRIPTION
     * - CONFIGURATION.${modelId}.${productId}.CODE.${code}_DESCRIPTION
     * - CONFIGURATION.${modelId}.CODE.${code}_DESCRIPTION
     * - CONFIGURATION.COMMON.CODE.${code}_DESCRIPTION
     * @param code
     * @param translationPrefix an optional translation prefix used when looking up translation
     * @private
     */
    private translateDescription(
        code: string,
        translationPrefix?: string
    ): string | undefined {
        const prefixedKeys = translationPrefix
            ? [
                  `CONFIGURATION.${this.modelId}.${this.productId}.CODE.${translationPrefix}${code}_DESCRIPTION`,
                  `CONFIGURATION.${this.modelId}.CODE.${translationPrefix}${code}_DESCRIPTION`,
                  `CONFIGURATION.COMMON.${translationPrefix}${code}_DESCRIPTION`
              ]
            : [];
        const lookupKeys = [
            ...prefixedKeys,
            `CONFIGURATION.${this.modelId}.${this.productId}.CODE.${code}_DESCRIPTION`,
            `CONFIGURATION.${this.modelId}.CODE.${code}_DESCRIPTION`,
            `CONFIGURATION.COMMON.${code}_DESCRIPTION`
        ];
        for (const lookupKey of lookupKeys) {
            const translation = this.i18nService.translateWithFallback(
                lookupKey,
                undefined
            );
            if (translation) {
                return translation;
            }
        }
        return undefined;
    }

    private translateCategory(code: string) {
        return this.i18nService.translateWithFallback(
            `CONFIGURATION.COMMON.${code}_CATEGORY`,
            undefined
        );
    }
}
