import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    LocalAudioTrack,
    LocalVideoTrack,
    RemoteAudioTrack,
    RemoteVideoTrack
} from 'twilio-video';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { IconSize, UiBaseComponent } from '@mhp/ui-components';

import { environment } from '../../../environments/environment';
import { Nullable } from '../../common/types';
import { VideoChatService } from '../../video-chat/video-chat.service';
import { VideoChatLocation } from '../video-chat-location.interface';

type TrackSource = 'REMOTE' | 'LOCAL';

@Component({
    selector: 'mhp-twilio-participant-track',
    templateUrl: './twilio-participant-track.component.html',
    styleUrls: ['twilio-participant-track.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwilioParticipantTrackComponent extends UiBaseComponent {
    readonly ICON_SIZE = IconSize;

    @Input()
    trackSource: TrackSource = 'LOCAL';

    @Input()
    location: VideoChatLocation = undefined;

    @Input() videoWidth: number;

    /**
     * When using background-mode, keep the audio-track alive but do not bind
     * video.
     */
    @Input()
    useBackgroundMode = false;

    constructor(private readonly videoChatService: VideoChatService) {
        super();
    }

    @MemoizeObservable()
    getTracks$(): Observable<
        | {
              audio: Nullable<RemoteAudioTrack | LocalAudioTrack>;
              video: Nullable<RemoteVideoTrack | LocalVideoTrack>;
          }
        | undefined
    > {
        return combineLatest([this.getAudio$(), this.getVideo$()]).pipe(
            map(([audioTrack, videoTrack]) =>
                !audioTrack && !videoTrack
                    ? undefined
                    : {
                          audio: audioTrack,
                          video: videoTrack
                      }
            )
        );
    }

    @MemoizeObservable()
    getVideo$(): Observable<Nullable<RemoteVideoTrack | LocalVideoTrack>> {
        return combineLatest([
            this.observeProperty<TwilioParticipantTrackComponent, TrackSource>(
                'trackSource',
                true
            ),
            this.observeProperty<TwilioParticipantTrackComponent, boolean>(
                'useBackgroundMode',
                true
            )
        ]).pipe(
            switchMap(([trackSource, useBackgroundMode]) => {
                if (useBackgroundMode) {
                    of(undefined);
                }
                return trackSource === 'REMOTE'
                    ? this.videoChatService.remoteVideoTrack$
                    : this.videoChatService.localVideoTrack$;
            })
        );
    }

    @MemoizeObservable()
    getAudio$(): Observable<Nullable<RemoteAudioTrack | LocalAudioTrack>> {
        return this.observeProperty<
            TwilioParticipantTrackComponent,
            TrackSource
        >('trackSource', true).pipe(
            switchMap((trackSource) =>
                trackSource === 'REMOTE'
                    ? this.videoChatService.remoteAudioTrack$
                    : this.videoChatService.localAudioTrack$
            )
        );
    }

    @MemoizeObservable()
    getRemoteParticipantMuted$(): Observable<boolean | undefined> {
        return this.videoChatService.getRemoteParticipantMuted$();
    }

    @MemoizeObservable()
    getDealerInitials$(): Observable<string | undefined> {
        return this.videoChatService.getDealerName$().pipe(
            map((dealerName) => {
                const split = dealerName.split(' ');
                if (split && split[0]?.length >= 1 && split[1]?.length >= 1) {
                    const initials = `${split[0].charAt(0)}${split[1].charAt(
                        0
                    )}`;
                    return initials;
                }
                return undefined;
            })
        );
    }

    @MemoizeObservable()
    getCustomerInitials$(): Observable<string | undefined> {
        return this.videoChatService.getCustomerInitials$();
    }

    @MemoizeObservable()
    getInitials$(): Observable<string | undefined> {
        /* if (environment.appConfig.dealer.dealerBuild) {
            if (this.trackSource === 'LOCAL') {
                return this.getDealerInitials$();
            }
            if (this.trackSource === 'REMOTE') {
                return this.getCustomerInitials$();
            }
        } else {
            if (this.trackSource === 'LOCAL') {
                return this.getCustomerInitials$();
            }
            if (this.trackSource === 'REMOTE') {
                return this.getDealerInitials$();
            }
        } */
        return (environment.appConfig.dealer.dealerBuild &&
            this.trackSource === 'LOCAL') ||
            (!environment.appConfig.dealer.dealerBuild &&
                this.trackSource === 'REMOTE')
            ? this.getDealerInitials$()
            : this.getCustomerInitials$();
    }
}
