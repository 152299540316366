import {
    Observable,
    combineLatest,
    map,
    pairwise,
    startWith,
    switchMap,
    take
} from 'rxjs';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';
import { translate } from '@jsverse/transloco';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import {
    TRACK_BY_ID,
    UiBaseComponent,
    UiNavigationEntry
} from '@mhp/ui-components';
import { ApplicationStateService, I18nService } from '@mhp/ui-shared-services';

import {
    ROUTE_MODEL_SELECT,
    ROUTE_ORDER_EDIT,
    ROUTE_ORDER_MANAGEMENT
} from '../../app-route-names';
import { SearchState } from '../../common/search/models/search.model';
import { CONFIGURATION_MENU_SIDEBAR_ENTRY_MAX_ITEMS_MOBILE_PORTRAIT } from '../../configuration/configuration-menu/configuration-menu.constants';
import { DEALER_HOOKS_TOKEN, DealerHooks } from '../../dealer';
import { LocalApplicationState } from '../../state';
import { SavedConfiguration } from '../model/order-management.models';
import { OrderManagementService } from '../order-management.service';
import {
    exitSearchState,
    selectActiveOrderSearchTerm,
    selectSearchInputActive,
    selectShowSearchResults,
    setActiveSearchTerm,
    setSearchInputActive
} from '../state';

export interface Fruit {
    name: string;
}
@Component({
    selector: 'mhp-order-overview',
    templateUrl: './order-overview.component.html',
    styleUrls: ['./order-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderOverviewComponent
    extends UiBaseComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    readonly TRACK_BY_ID = TRACK_BY_ID;

    @ViewChildren('theLastList', { read: ElementRef })
    theLastList: QueryList<ElementRef<HTMLElement>>;

    sortBy: string;

    sortOrder: SortDirection;

    savedConfigurations: SavedConfiguration[] = [];

    private readonly pageSize = 20;

    private totalPages: number;

    private currentPage = 1;

    private readonly observer: IntersectionObserver;

    searchState: SearchState = {
        exitSearchState,
        selectActiveConfigurationSearchTerm: selectActiveOrderSearchTerm,
        selectSearchInputActive,
        setActiveSearchTerm,
        setSearchInputActive
    };

    private readonly sidebarItems: () => UiNavigationEntry<string>[] = () => [
        {
            id: 'Add',
            label: translate('ICONS.NEW_ORDER'),
            iconId: 'mhp-ui:plus'
        },
        {
            id: 'Print',
            label: translate('ICONS.PRINT'),
            iconId: 'mhp-ui:print'
        }
    ];

    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        private readonly i18nService: I18nService,
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks,
        private readonly router: Router,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly orderManagementService: OrderManagementService,
        private readonly cdr: ChangeDetectorRef
    ) {
        super();

        this.observer = this.createIntersectionObserver();
    }

    ngOnInit() {
        this.getSavedConfigurationList$();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this.observer.disconnect();
    }

    ngAfterViewInit() {
        this.unsubscribeOnDestroy(
            this.theLastList.changes
                .pipe(
                    map((d) => d.last),
                    startWith(undefined),
                    pairwise()
                )
                .subscribe(([elementRefPrev, elementRefCurrent]) => {
                    if (elementRefPrev)
                        this.observer.unobserve(elementRefPrev.nativeElement);
                    if (elementRefCurrent)
                        this.observer.observe(elementRefCurrent.nativeElement);
                })
        );
    }

    getSavedConfigurationList$() {
        const sortby = this.sortBy ? this.sortBy : 'date';
        const sortorder = this.sortOrder ? this.sortOrder : 'desc';

        // TODO: rewrite to make behavior reactive instead of relying on CD calls
        this.applicationStateService
            .getLocalState()
            .pipe(
                this.searchState.selectActiveConfigurationSearchTerm,
                take(1),
                switchMap((searchTerm) =>
                    this.orderManagementService.getSavedConfigurations$(
                        this.currentPage,
                        this.pageSize,
                        sortby,
                        sortorder,
                        searchTerm
                    )
                )
            )
            .subscribe((savedConfigurationsPage) => {
                this.totalPages = savedConfigurationsPage.totalPages;
                this.savedConfigurations = [
                    ...this.savedConfigurations,
                    ...savedConfigurationsPage.savedConfigurations
                ];
                this.cdr.markForCheck();
            });
    }

    intentExitSearch() {
        this.applicationStateService.dispatch(exitSearchState());
        this.resetCurrentPageForReload();
        this.resetSavedConfigListForReload();
        this.getSavedConfigurationList$();
    }

    intentTriggerSearch() {
        this.resetCurrentPageForReload();
        this.resetSavedConfigListForReload();
        this.getSavedConfigurationList$();
    }

    @MemoizeObservable()
    getActiveSearchTerm$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectActiveOrderSearchTerm);
    }

    @MemoizeObservable()
    isSearchInputActive$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectSearchInputActive);
    }

    @MemoizeObservable()
    isDisplayingSearchResults$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectShowSearchResults);
    }

    navigateToMainPage() {
        this.router.navigateByUrl(`INT/${ROUTE_MODEL_SELECT}`);
    }

    @MemoizeObservable()
    getSidebarItems$(): Observable<UiNavigationEntry<string>[]> {
        return combineLatest([
            this.getAllSidebarItemsAdjusted$(),
            this.breakpointObserver.observe([Breakpoints.HandsetPortrait])
        ]).pipe(
            map(([allSidebarItems, breakpointState]) =>
                allSidebarItems
                    /** On mobile portrait breakpoint, show a maximum no of 6 icons */
                    .filter((item, index) =>
                        breakpointState.matches
                            ? index <
                              CONFIGURATION_MENU_SIDEBAR_ENTRY_MAX_ITEMS_MOBILE_PORTRAIT
                            : true
                    )
            )
        );
    }

    intentSidebarItemSelect(itemId: string) {
        if (
            this.dealerHooksService.handleSidebarItemSelect(
                itemId,
                'ORDER-MANAGEMENT'
            )
        ) {
            return;
        }

        switch (itemId) {
            case 'Add':
                this.intentNewOrder();
                break;
            case 'Print':
                window.print();
                break;
            default:
                break;
        }
    }

    intentNewOrder() {
        this.router.navigateByUrl(
            `INT/${ROUTE_ORDER_MANAGEMENT}/${ROUTE_ORDER_EDIT}`
        );
    }

    loadConfiguration(configurationUrl: string) {
        window.open(configurationUrl, '_blank');
    }

    deleteConfiguration$(savedConfigId: string) {
        return this.orderManagementService
            .deleteSavedConfiguration$(savedConfigId)
            .subscribe({
                next: () => {
                    this.savedConfigurations = this.savedConfigurations.filter(
                        (deleted) => deleted.id !== savedConfigId
                    );
                    this.cdr.markForCheck();
                }
            });
    }

    sortData(sort: Sort) {
        this.sortBy = sort.active;
        this.sortOrder = sort.direction;
        this.resetCurrentPageForReload();
        this.resetSavedConfigListForReload();
        this.getSavedConfigurationList$();
    }

    resetSavedConfigListForReload() {
        this.savedConfigurations = [];
    }

    resetCurrentPageForReload() {
        this.currentPage = 1;
    }

    private createIntersectionObserver() {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        return new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                if (this.currentPage < this.totalPages) {
                    this.currentPage += 1;
                    this.getSavedConfigurationList$();
                }
            }
        }, options);
    }

    @MemoizeObservable()
    private getAllSidebarItemsAdjusted$() {
        return combineLatest([
            this.i18nService.getActiveLang$(),
            this.breakpointObserver.observe([Breakpoints.HandsetPortrait])
        ]).pipe(
            map(([, breakpointState]) =>
                this.dealerHooksService.adjustSidebarItems(
                    this.sidebarItems().filter(
                        (item): item is UiNavigationEntry<string> => !!item
                    ),
                    'ORDER-MANAGEMENT'
                )
            ),
            lazyShareReplay()
        );
    }
}
