import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageSrcset } from '@mhp/ui-components';

import { AmlImagesService } from '../../../../common/images/aml-images.service';
import { EnvironmentSelectionDialogComponent } from '../environment-selection-dialog/environment-selection-dialog.component';
import {
    InteriorEnvironmentGroupModel,
    InteriorEnvironmentModel
} from '../environment-selection.interface';
import { EnvironmentSelectionService } from '../environment-selection.service';

export interface EnvironmentSelectionInfoData {
    environmentGroupModel: InteriorEnvironmentGroupModel;
    environmentModel: InteriorEnvironmentModel;
    interiorImageSrcset: ImageSrcset;
    parentDialogRef: MatDialogRef<EnvironmentSelectionDialogComponent>;
    productId: string;
    readonly?: boolean;
}

@Component({
    selector: 'mhp-environment-selection-info',
    templateUrl: './environment-selection-info.component.html',
    styleUrls: ['./environment-selection-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvironmentSelectionInfoComponent {
    constructor(
        private readonly environmentSelectionService: EnvironmentSelectionService,
        private readonly dialogRef: MatDialogRef<EnvironmentSelectionInfoComponent>,
        public readonly amlImagesService: AmlImagesService,
        @Inject(MAT_DIALOG_DATA)
        public data: EnvironmentSelectionInfoData
    ) {}

    replaceAll(string, occurrance, replacement): string {
        return string.split(occurrance).join(replacement);
    }

    configureEnvironment(environmentModel: InteriorEnvironmentModel) {
        this.environmentSelectionService
            .applyEnvironment$(environmentModel)
            .subscribe(() => {
                this.data.parentDialogRef.close();
                this.dialogRef.close();
            });
    }
}
