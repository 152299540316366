import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { FinancingType } from '@mhp/aml-shared/data-proxy/financial-services/financing-type.enum';
import { MemoizeObservable } from '@mhp/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { FinancialServiceOfferInputBase } from './financial-services.interfaces';

@UntilDestroy()
@Injectable()
export class FinancialServicesInputService<
    FSOI extends FinancialServiceOfferInputBase = FinancialServiceOfferInputBase
> {
    private readonly financialOfferInputSubject = new BehaviorSubject<
        FSOI | undefined
    >(undefined);

    constructor(
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService
    ) {
        this.clearInputOnProductChange();
    }

    /**
     * Returns the input parameters the current financial offer is based on.
     */
    @MemoizeObservable()
    getFinancialServiceOfferInput$(): Observable<FSOI | undefined> {
        return this.financialOfferInputSubject.pipe(
            map((input) => cloneDeep(input))
        );
    }

    /**
     * Returns the default input parameters to be used when starting a new offer.
     */
    @MemoizeObservable()
    getFinancialServiceOfferDefaultInput$(): Observable<FSOI> {
        return of({
            typeOfFinance: FinancingType.PERSONAL_CONTRACT_PURCHASE,
            termInMonths: 36,
            mileage: 10000,
            monthlyRate: 1500
        } as FSOI);
    }

    /**
     * Update the input parameters that should be used to calculate
     * the current financial offering.
     * @param input The updated input parameters to be used for calculation or undefined to clear the current offer.
     */
    updateFinancialServiceOfferInput(input: FSOI | undefined): void {
        this.financialOfferInputSubject.next(input);
    }

    /**
     * Upon change of the productId, clear the active offer input.
     * @private
     */
    private clearInputOnProductChange() {
        this.configurationSessionInfoService
            .getActiveProductId$()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.updateFinancialServiceOfferInput(undefined);
            });
    }
}
