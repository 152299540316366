import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OverflowIndicatorComponent } from './overflow-indicator.component';

@NgModule({
    declarations: [OverflowIndicatorComponent],
    imports: [CommonModule, CdkScrollableModule],
    exports: [OverflowIndicatorComponent, CdkScrollableModule]
})
export class OverflowIndicatorModule {}
