<mhp-configuration-code-layout
    [id]="data.id"
    [selectState]="data.selected"
    [code]="data.code"
    [codeMeta]="data.meta"
    [name]="data.nameTranslated || data.code"
    [subType]="data.subType"
    [thumbnailImage]="getThumbnailImage$() | async"
    [hasAdditionalInfo]="!!data.descriptionTranslated"
    [forceInfoAlwaysVisible]="forceInfoAlwaysVisible"
    [beautyshot]="data.beautyshot"
    [disabled]="false"
    [readonly]="readonly"
    [visuallyHidden]="data.visuallyHidden"
    [debug]="useDebug()"
    (selected)="onNodeSelected($event)"
    (showInfo)="onShowInfo($event)"
    (mouseenter)="onHighlighted(true)"
    (mouseleave)="onHighlighted(false)"
></mhp-configuration-code-layout>
