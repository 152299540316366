import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { enhanceSelector } from '@mhp/ui-shared-services';
import { createFeatureSelector, select } from '@ngrx/store';

import { LocalApplicationState } from '../../../../state/local-application-state.interface';
import { SalesforceState } from '../salesforce-state.interface';

export const selectSalesforceState =
    createFeatureSelector<SalesforceState>('salesforce');

export const selectSalesforceTransactionKey = enhanceSelector<
    LocalApplicationState,
    string | undefined
>(
    pipe(
        select(selectSalesforceState),
        map((state) => state.transactionKey)
    ),
    {
        distinctUntilChanged: true
    }
);
