import { environment } from '../../../../environments/environment';
import { CategoryItemModifier } from './category-mapper.service';

/**
 * Removes the description from the category item if the first hierarchy item is not the INTERIOR_OPTIONS entry.
 * @param categoryItem
 * @param metaItem
 */
export const CATEGORY_DESCRIPTION_REMOVAL_MODIFIER: CategoryItemModifier = (
    categoryItem,
    metaItem
) => {
    if (
        metaItem.hierarchyPath[0]?.name !==
        environment.appConfig.configuration.identifierInteriorOptions
    ) {
        categoryItem.description = undefined;
    }
    return categoryItem;
};
