import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PricingModule } from '../../common/pricing/pricing.module';
import { IfShowConfigurationPricingDirective } from './if-show-configuration-pricing.directive';

@NgModule({
    declarations: [IfShowConfigurationPricingDirective],
    exports: [IfShowConfigurationPricingDirective, PricingModule],
    imports: [CommonModule, PricingModule]
})
export class ConfigurationPricingModule {}
