import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import {
    UiGridListModule,
    UiIconButtonModule,
    UiIconsModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { OverflowIndicatorModule } from '../../common/overflow-indicator';
import { EngineSettingsComponent } from './engine-settings.component';

@NgModule({
    declarations: [EngineSettingsComponent],
    imports: [
        CommonModule,
        OverflowIndicatorModule,
        UiIconsModule,
        MatRippleModule,
        UiGridListModule,
        UiIconButtonModule,
        I18nModule
    ],
    exports: [EngineSettingsComponent]
})
export class EngineSettingsModule {}
