<div class="video-chat__container" *transloco="let t">
    <mhp-configuration-area-action-group class="video-chat__action-group">
        <mhp-configuration-area-action-item
            svgIcon="mhp-ui:enlarge_2"
            class="video-chat__action-open-video-chat-dialog"
            [tooltip]="t('ICONS.VIDEO_CHAT_ALT')"
            (itemSelect)="intentOpenVideoChatDialog()"
        >
        </mhp-configuration-area-action-item>
    </mhp-configuration-area-action-group>
    <mhp-twilio-participant-track
        class="video-chat__twilio-participant-track"
        trackSource="REMOTE"
        location="HEADER_BAR"
        [ngClass]="{
            'video-chat__twilio-participant-track--use-background':
                !renderVideoElements
        }"
        [useBackgroundMode]="!renderVideoElements"
        (click)="intentOpenVideoChatDialog()"
    ></mhp-twilio-participant-track>
    <mhp-twilio-participant-track
        *ngIf="renderVideoElements"
        class="video-chat__twilio-participant-track"
        trackSource="LOCAL"
        location="HEADER_BAR"
        (click)="intentOpenVideoChatDialog()"
    ></mhp-twilio-participant-track>
</div>
