import { switchMap, tap } from 'rxjs';

import {
    ComponentFactoryResolver,
    Inject,
    Injectable,
    ViewContainerRef
} from '@angular/core';
import { UiMatDialogService } from '@mhp/ui-components';
import { gtmGA4Track } from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../environments/environment';
import { DEALER_HOOKS_TOKEN, DealerHooks } from '../../dealer';
import { SubmitToDealerComponent } from '../salesforce/submit-to-dealer/submit-to-dealer.component';

@UntilDestroy()
@Injectable()
export class ConfigurationSubmitService {
    readonly IS_DEALER_BUILD = environment.appConfig.dealer.dealerBuild;

    constructor(
        @Inject(DEALER_HOOKS_TOKEN)
        private readonly dealerHooksService: DealerHooks,
        private readonly dialogService: UiMatDialogService,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly componentFactoryResolver: ComponentFactoryResolver
    ) {}

    /**
     * Open a dialog to handle the intent to submit a configuration.
     * Internally differentiates between public and dealer builds.
     */
    intentSubmitConfiguration() {
        if (this.IS_DEALER_BUILD) {
            this.dealerHooksService
                .openSubmitToSalesforceOrVoeDialog$()
                .pipe(untilDestroyed(this))
                .subscribe();
        } else {
            gtmGA4Track('share_to_dealer_open_click');
            this.dialogService
                .openFullscreen$(SubmitToDealerComponent, {
                    componentFactoryResolver: this.componentFactoryResolver,
                    viewContainerRef: this.viewContainerRef
                })
                .pipe(
                    switchMap((dialogRef) =>
                        dialogRef.beforeClosed().pipe(
                            tap((value) => {
                                if (value !== 'SUBMITTED') {
                                    gtmGA4Track('share_to_dealer_abandonment');
                                }
                            })
                        )
                    ),
                    untilDestroyed(this)
                )
                .subscribe();
        }
    }
}
