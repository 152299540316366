import { Observable } from 'rxjs';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ApplicationStateService } from '@mhp/ui-shared-services';

import { DealerInfo, selectDealerInfo } from '../../dealer';
import { LocalApplicationState } from '../../state';

@Component({
    selector: 'mhp-dealer-header-info',
    templateUrl: './dealer-header-info.component.html',
    styleUrl: './dealer-header-info.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerHeaderInfoComponent {
    readonly dealerInfo = toSignal(this.getDealerInformation$());

    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>
    ) {}

    private getDealerInformation$(): Observable<DealerInfo | undefined> {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectDealerInfo);
    }
}
