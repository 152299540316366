<div class="configuration-composite-items-list">
    <mhp-configuration-composite-item
        *ngFor="
            let compositeItemModel of configurationCompositeItems;
            trackBy: trackById
        "
        [item]="compositeItemModel"
        [imageFallbackUrl]="getCompositeItemFallbackImageUrl$() | async"
        [disabled]="optionSelectionDisabled()"
        [debug]="useDebug()"
        (selected)="intentSelectOption($event)"
        (showInfo)="intentShowInfo($event)"
        (enlargeThumbnail)="intentEnlargeThumbnail($event)"
    ></mhp-configuration-composite-item>
</div>
