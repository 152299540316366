import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ApplicationStateService } from '@mhp/ui-shared-services';

import { LocalApplicationState } from '../../state/local-application-state.interface';
import { ExtendedUiOptionGroup } from '../configuration-model/configuration-interfaces';
import { selectActiveConfigurationSearchTerm } from '../state/selectors/configuration.selectors';
import { ConfigurationFilterService } from './configuration-filter.service';

/**
 * Search service provides filter-functionality for a given set of root-configuration items
 * taking into account the search-criteria available through local application state.
 */
@Injectable({
    providedIn: 'root'
})
export class SearchService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly configurationFilterService: ConfigurationFilterService
    ) {}

    /**
     * Emits the active search term
     */
    getActiveSearchTerm$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectActiveConfigurationSearchTerm);
    }

    /**
     * Apply the currently set search term to the items emitted in the provided stream.
     * @param rootItems$
     */
    getFilteredOptions$(
        rootItems$: Observable<ExtendedUiOptionGroup[] | undefined>
    ): Observable<ExtendedUiOptionGroup[] | undefined> {
        return combineLatest([rootItems$, this.getActiveSearchTerm$()]).pipe(
            map(([rootItems, searchTerm]) => {
                if (!rootItems || !searchTerm) {
                    return rootItems;
                }

                return this.filterItemsByTerm(rootItems, searchTerm);
            })
        );
    }

    /**
     * Wrap the areas of a given label that match the active search term with a <strong>$&</strong> tag
     * @param label
     */
    getLabelWithSearchTermHighlighted$(label: string): Observable<string> {
        return this.applicationStateService.getLocalState().pipe(
            selectActiveConfigurationSearchTerm,
            map((activeSearchTerm) => {
                if (!activeSearchTerm) {
                    return label;
                }

                return label.replace(
                    new RegExp(
                        activeSearchTerm.replace(
                            /[-/\\^$*+?.()|[\]{}]/g,
                            '\\$&'
                        ),
                        'ig'
                    ),
                    '<strong>$&</strong>'
                );
            })
        );
    }

    private filterItemsByTerm(
        rootItems: ExtendedUiOptionGroup[],
        searchTerm: string
    ) {
        return this.configurationFilterService.filterOptionGroups(
            rootItems,
            searchTerm
        );
    }
}
