import { GoogleTagManagerEnvironmentSpec } from '@mhp/ui-shared-services';

import { VisualizationMode } from '../app/configuration';
import { defaultsDeepCustom } from './environment-helper';
import { defaultEnvironmentConfig } from './environment.default';

export const defaultDealerEnvironmentAdjusts = {
    appConfig: {
        dealer: {
            dealerBuild: true,
            forceAuthentication: true
        },
        visualization: {
            defaultVisualizationMode: VisualizationMode.STREAM,
            cinematicDownloadOriginOverride:
                'https://aml-stage-dealer-4q6zszk7ge7yhcs.api-services.astonmartin.com'
        },
        stream: {
            resetTo2dOnConfigurationLeave: false,
            sessionUsageSessionClientValue: 'dealer'
        },
        googleTagManager: {
            trackingId: 'GTM-P4LZZWJ',
            environmentSpec: <GoogleTagManagerEnvironmentSpec | null>{
                gtmAuth: 'RtmwzvwY4dxeT8x8iVsSCw',
                gtmPreview: 'env-268',
                gtmCookiesWin: 'x'
            }
        }
    }
};

export const defaultDealerEnvironmentConfig = defaultsDeepCustom(
    {},
    defaultDealerEnvironmentAdjusts,
    defaultEnvironmentConfig
);
