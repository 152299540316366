<mhp-fullscreen-dialog [wrapInDialogBody]="true">
    <div class="dealer-salesforce-voe" *transloco="let t">
        <div
            class="dealer-salesforce-voe__lane-left"
            *transloco="let tForm; read: 'FORM'"
        >
            <div
                class="dealer-salesforce-voe__current-configuration-container--small"
            >
                <ng-container
                    *ngTemplateOutlet="currentConfiguration"
                ></ng-container>
            </div>

            <h2>
                {{
                    (getActiveFormId$() | async) === 'dealer-salesforce-form'
                        ? t('SUBMIT_TO_SYNERGY.HEADLINE')
                        : t('SUBMIT_TO_VOE.HEADLINE')
                }}
            </h2>

            <!-- tab-group to submit either to salesforce or voe -->
            <mat-tab-group
                #formTabGroup
                mat-stretch-tabs="false"
                [selectedIndex]="activeTabIndex$ | async"
                (selectedIndexChange)="onSelectedTabIndexChange($event)"
            >
                <mat-tab
                    *transloco="let tForm; read: 'SUBMIT_TO_SYNERGY'"
                    [label]="tForm('TAB1')"
                >
                    <mhp-salesforce-form
                        [requireDealerSelect]="false"
                        [requiresConsent]="false"
                        [fixedCountry]="getActiveCountry$() | async"
                        formId="dealer-salesforce-form"
                    ></mhp-salesforce-form>
                </mat-tab>
                <mat-tab
                    *transloco="let tLn; read: 'SUBMIT_TO_LN'"
                    [label]="tLn('SYSTEM')"
                >
                    <section>
                        <p [innerHTML]="tLn('DESCRIPTION')"></p>
                    </section>

                    <section
                        class="aml-form__form-block dealer-salesforce-voe__actions"
                    >
                        <button
                            mat-flat-button
                            class="mhp-ui-button--block mhp-ui-button--highlight"
                            type="submit"
                            (click)="openOrderManagementEditView$()"
                        >
                            <span>{{ t('ICONS.CREATE_ORDER') }}</span>
                        </button>
                        <button
                            mat-flat-button
                            type="submit"
                            class="mhp-ui-button--block mhp-ui-button--highlight"
                            [attr.form]="getActiveFormId$() | async"
                            (click)="saveConfiguration()"
                        >
                            <span>{{ t('ICONS.SAVE_CONFIGURATION') }}</span>
                        </button>
                    </section>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="dealer-salesforce-voe__lane-right">
            <div
                class="dealer-salesforce-voe__current-configuration-container--large"
            >
                <ng-container
                    *ngTemplateOutlet="currentConfiguration"
                ></ng-container>
            </div>

            <section class="dealer-salesforce-voe__next-steps">
                <ng-container
                    *ngIf="
                        (getActiveFormId$() | async) ===
                        'dealer-salesforce-form'
                    "
                >
                    <ng-container
                        *transloco="
                            let tSubmitToSalesforce;
                            read: 'SUBMIT_TO_SYNERGY.FORM'
                        "
                    >
                        <h3>{{ tSubmitToSalesforce('HEADING') }}</h3>
                        <p [innerHTML]="tSubmitToSalesforce('DESCRIPTION')"></p>
                        <div class="dealer-salesforce-voe__actions">
                            <button
                                mat-flat-button
                                class="mhp-ui-button--block mhp-ui-button--full-width mhp-ui-button--highlight"
                                type="submit"
                                [attr.form]="getActiveFormId$() | async"
                                [disabled]="(canSubmit$() | async) === false"
                            >
                                <span>{{
                                    tSubmitToSalesforce('CTA_SUBMIT')
                                }}</span>
                            </button>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container
                    *ngIf="(getActiveFormId$() | async) === 'dealer-voe-form'"
                >
                    <ng-container
                        *transloco="
                            let tSubmitToVoe;
                            read: 'SUBMIT_TO_VOE.FORM'
                        "
                    >
                        <mhp-loading-indicator
                            [loading]="this.serverCallInProgress$ | async"
                            [showLoadingSpinnerWhenLoading]="true"
                        ></mhp-loading-indicator>
                        <h3>{{ tSubmitToVoe('DESCRIPTION') }}</h3>
                        <p [innerHTML]="tSubmitToVoe('DESCRIPTION2')"></p>
                    </ng-container>
                </ng-container>
            </section>
        </div>

        <ng-template #currentConfiguration>
            <section
                class="dealer-salesforce-voe__current-configuration"
                *transloco="let tSubmitDealer; read: 'SUMBIT_TO_DEALER.GENERAL'"
            >
                <div
                    class="dealer-salesforce-voe__current-configuration-description"
                    *transloco="let t"
                >
                    <h3
                        [innerHTML]="
                            t('SUMMARY.CONFIGURATION_SUMMARY.HEADLINE', {
                                modelname: getModelLabel$() | async
                            })
                        "
                    ></h3>
                </div>
                <mhp-static-renderer
                    [renderActiveSessionData]="true"
                    [aspectRatio]="3 / 2"
                    [renderingAdjustments]="DEFAULT_RENDERING_ADJUSTMENTS"
                ></mhp-static-renderer>
            </section>
        </ng-template>
    </div>
</mhp-fullscreen-dialog>
