import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    InjectionToken,
    Input,
    Optional
} from '@angular/core';
import { InfoLayerItem } from '@mhp/aml-ui-shared-services';
import { MemoizeObservable } from '@mhp/common';
import { toObservable } from '@mhp/common/rxjs/rxjs-types';
import { ImageSrcset, UiBaseComponent } from '@mhp/ui-components';

export interface OptionDescriptionProcessor {
    process(description: string): string;
}

export const OPTION_DESCRIPTION_PROCESSOR = new InjectionToken<
    OptionDescriptionProcessor[]
>('OptionDescriptionProcessor');

@Component({
    selector: 'mhp-info-layer',
    templateUrl: './info-layer.component.html',
    styleUrls: ['./info-layer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoLayerComponent extends UiBaseComponent {
    @Input()
    set item(value: InfoLayerItem) {
        if (this.optionDescriptionProcessors) {
            const adjustedDescription = this.optionDescriptionProcessors.reduce(
                (prevDescription, processor) =>
                    processor.process(prevDescription),
                value.description
            );
            this.itemField = {
                ...value,
                description: adjustedDescription
            };
        } else {
            this.itemField = value;
        }

        this.thumbnailUrls$ = toObservable(this.item?.thumbnailUrls);
        this.thumbnailFallbackUrl$ = toObservable(
            this.item?.thumbnailFallbackUrl
        );
    }

    get item(): InfoLayerItem {
        return this.itemField;
    }

    thumbnailUrls$: Observable<(string | ImageSrcset)[]>;

    thumbnailFallbackUrl$: Observable<string | undefined>;

    private itemField: InfoLayerItem;

    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        @Optional()
        @Inject(OPTION_DESCRIPTION_PROCESSOR)
        private readonly optionDescriptionProcessors?: OptionDescriptionProcessor[]
    ) {
        super();
    }

    @MemoizeObservable()
    getThumbnailRatio$(): Observable<number> {
        return combineLatest([
            this.observeProperty<InfoLayerComponent, InfoLayerItem>(
                'item',
                true
            ),
            this.breakpointObserver.observe([
                Breakpoints.HandsetLandscape,
                Breakpoints.TabletLandscape,
                Breakpoints.WebLandscape,
                Breakpoints.Web
            ])
        ]).pipe(
            map(([item, breakpointState]) => {
                if (item.itemType === 'material') {
                    if (!breakpointState.matches) {
                        return 7 / 16;
                    }
                    return 16 / 7;
                }
                if (item.itemType === 'image') {
                    if (!breakpointState.matches) {
                        return 16 / 9;
                    }
                    return 1;
                }
                return 16 / 9;
            })
        );
    }

    @MemoizeObservable()
    getImageFit$(): Observable<'cover' | 'contain'> {
        return this.observeProperty<InfoLayerComponent, InfoLayerItem>(
            'item',
            true
        ).pipe(
            map((item) => {
                if (item.forceImageFit) {
                    return item.forceImageFit;
                }
                return 'cover';
            })
        );
    }
}
