import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UiBaseComponent } from '@mhp/ui-components';

import { PricingOptions } from '../model/order-management.models';

@Component({
    selector: 'mhp-pricing-dialog',
    templateUrl: './pricing-dialog.component.html',
    styleUrls: ['./pricing-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricingDialogComponent extends UiBaseComponent implements OnInit {
    @Input()
    pricingDNPAvailable = false;

    @Input()
    pricingRRPAvailable = false;

    @Input()
    showPricingDNP = false;

    @Input()
    showPricingRRP = false;

    @Output()
    readonly confirm = new EventEmitter<PricingOptions>();

    pricingFormGroup: FormGroup;

    constructor(
        public readonly matDialogRef: MatDialogRef<PricingDialogComponent>
    ) {
        super();
        this.completeOnDestroy(this.confirm);
    }

    ngOnInit() {
        this.initPricingFormGroup();
    }

    getFormControlvalues(): PricingOptions {
        return this.pricingFormGroup.value;
    }

    intentPricingOptions() {
        this.confirm.emit(this.getFormControlvalues());
    }

    onCancel() {
        this.matDialogRef.close();
    }

    private initPricingFormGroup() {
        const pricingForm = {
            showPricingDNP: new FormControl<boolean>(
                this.pricingDNPAvailable && this.showPricingDNP,
                Validators.required
            ),
            showPricingRRP: new FormControl<boolean>(
                this.pricingRRPAvailable && this.showPricingRRP,
                Validators.required
            )
        };
        this.pricingFormGroup = new FormGroup(pricingForm);
    }
}
