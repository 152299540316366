import { isEmpty } from 'lodash-es';
import { EMPTY, Observable, defer, forkJoin, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import {
    MSAL_INTERCEPTOR_CONFIG,
    MsalBroadcastService,
    MsalInterceptorConfiguration,
    MsalService
} from '@azure/msal-angular';
import {
    AccountInfo,
    AuthenticationResult,
    InteractionStatus,
    InteractionType,
    PopupRequest,
    RedirectRequest,
    SilentRequest
} from '@azure/msal-browser';
import { ReservationService } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/streaming/reservation.js';
import { ReservationData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/reservation-data.js';
import { IllegalStateError } from '@mhp/common';
import {
    CommonDialogsService,
    MONKEYWAY_ENVIRONMENT_CONFIG_TOKEN,
    MonkeywayEnvironmentConfig
} from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { ConfigurationSessionInfoService } from '../../configuration/session-info/configuration-session-info.service';

export interface CreateSessionPayload {
    participantsCount?: number; // defaults to 2
    sessionStart: Date;
    description: string;
}

@Injectable()
export class One2oneSessionBookingService {
    constructor(
        @Inject(MONKEYWAY_ENVIRONMENT_CONFIG_TOKEN)
        private readonly config: MonkeywayEnvironmentConfig,
        private readonly msalService: MsalService,
        private readonly msalBroadcastService: MsalBroadcastService,
        private readonly commonDialogsService: CommonDialogsService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        @Inject(MSAL_INTERCEPTOR_CONFIG)
        private readonly interceptorConfig: MsalInterceptorConfiguration
    ) {}

    /**
     * Book a session using the given session information.
     * @param sessionInfo
     */
    bookSession$(
        sessionInfo: CreateSessionPayload
    ): Observable<ReservationData> {
        return this.getReservationService$().pipe(
            switchMap((reservationService) =>
                reservationService.createReservation({
                    participants: sessionInfo.participantsCount,
                    sessionStart: sessionInfo.sessionStart.toISOString(),
                    description: sessionInfo.description
                })
            )
        );
    }

    /**
     * Get ReservationData based on a given reservation id.
     * @param sessionId
     */
    getSession$(sessionId: string): Observable<ReservationData> {
        return defer(() =>
            this.getReservationService$().pipe(
                switchMap((reservationService) =>
                    reservationService.getReservation(sessionId)
                )
            )
        );
    }

    /**
     * Delete a reservation using its identifier.
     * @param reservationId The reservations id.
     */
    deleteSession$(reservationId: string): Observable<string> {
        return new Observable((subscriber) => {
            const subscription = this.getReservationService$()
                .pipe(
                    switchMap((reservationService) =>
                        reservationService.deleteReservation(reservationId)
                    )
                )
                .subscribe({
                    complete: () => {
                        subscriber.next(reservationId);
                        subscriber.complete();
                    },
                    error: (error) => {
                        subscriber.error(error);
                    }
                });

            return () => {
                subscription.unsubscribe();
            };
        });
    }

    /**
     * Delete all sessions. This should only be used for dev-purposes.
     */
    deleteAllSessions$(): Observable<string[]> {
        return this.getReservationService$().pipe(
            switchMap((reservationService) =>
                reservationService.getReservations()
            ),
            switchMap((reservations) => {
                if (isEmpty(reservations)) {
                    return of([]);
                }

                const allDeleteCalls = reservations.map((reservationData) =>
                    this.deleteSession$(reservationData.id)
                );

                return forkJoin(allDeleteCalls);
            })
        );
    }

    private getReservationService$(): Observable<ReservationService> {
        let availableAccount$: Observable<AccountInfo | undefined>;

        const availableAccount = this.getActiveAccount();
        if (availableAccount) {
            availableAccount$ = of(availableAccount);
        } else {
            availableAccount$ = this.msalBroadcastService.inProgress$.pipe(
                filter((status) => status === InteractionStatus.None),
                map(() => this.getActiveAccount())
            );
        }

        return availableAccount$.pipe(
            switchMap((activeAccount) => {
                const scopes = [
                    'openid',
                    environment.appConfig.dealer.clientId
                ];

                const authRequest = {
                    ...this.interceptorConfig.authRequest,
                    account: activeAccount,
                    scopes
                };
                return this.msalService.acquireTokenSilent(authRequest).pipe(
                    catchError((error) =>
                        // TOOD: I18N
                        this.commonDialogsService
                            .openConfirmDialog$(
                                'Authentication required',
                                'Your authentication has expired and needs to be refreshed.',
                                undefined,
                                {
                                    showCancel: false
                                }
                            )
                            .pipe(
                                switchMap(() =>
                                    this.acquireTokenInteractively(
                                        authRequest,
                                        scopes
                                    )
                                )
                            )
                    ),
                    map((result: AuthenticationResult) => {
                        if (!result.idToken) {
                            // notify user about unavailable authentication
                            throw new IllegalStateError('No idToken available');
                        }
                        return result.idToken;
                    }),
                    map(
                        (idToken) =>
                            new ReservationService(this.config, idToken)
                    )
                );
            })
        );
    }

    /**
     * Invoke interaction for the given set of scopes
     * @param authRequest The authRequest to be used
     * @param scopes Array of scopes for the request
     * @returns Result from the interactive request
     */
    private acquireTokenInteractively(
        authRequest:
            | Omit<PopupRequest, 'scopes'>
            | Omit<RedirectRequest, 'scopes'>
            | Omit<SilentRequest, 'scopes'>,
        scopes: string[]
    ): Observable<AuthenticationResult> {
        if (this.interceptorConfig.interactionType === InteractionType.Popup) {
            this.msalService
                .getLogger()
                .verbose(
                    'Interceptor - error acquiring token silently, acquiring by popup'
                );
            return this.msalService.acquireTokenPopup({
                ...authRequest,
                scopes
            });
        }
        this.msalService
            .getLogger()
            .verbose(
                'Interceptor - error acquiring token silently, acquiring by redirect'
            );
        const redirectStartPage = window.location.href;
        this.msalService.acquireTokenRedirect({
            ...authRequest,
            scopes,
            redirectStartPage
        });
        return EMPTY;
    }

    private getActiveAccount() {
        return (
            this.msalService.instance.getActiveAccount() ||
            this.msalService.instance.getAllAccounts()[0]
        );
    }
}
