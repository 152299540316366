import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@mhp/ui-shared-services';

import { FooterModule } from '../../common/footer/footer.module';
import { ConfigurationMainLayoutComponent } from './configuration-main-layout.component';

@NgModule({
    declarations: [ConfigurationMainLayoutComponent],
    exports: [ConfigurationMainLayoutComponent],
    imports: [CommonModule, I18nModule, FooterModule]
})
export class ConfigurationMainLayoutModule {}
