import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output
} from '@angular/core';
import { IconSize, UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-configuration-area-action-item',
    templateUrl: './configuration-area-action-item.component.html',
    styleUrls: ['./configuration-area-action-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationAreaActionItemComponent extends UiBaseComponent {
    @HostBinding('class.configuration-area-action-item--active')
    @Input()
    active: boolean;

    @Input()
    tooltip: string;

    @Input()
    svgIcon?: string;

    @Input()
    buttonText?: string;

    @Input()
    iconSize = IconSize.MEDIUM;

    @Input()
    iconOnly: boolean;

    @Input()
    disabled: boolean;

    @Output()
    readonly itemSelect = new EventEmitter<void>();

    constructor() {
        super();

        this.completeOnDestroy(this.itemSelect);
    }

    intentItemSelect() {
        this.itemSelect.next();
    }
}
