import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconButtonModule, UiNotificationModule } from '@mhp/ui-components';

import { StreamModule } from '../stream/stream.module';
import { ConfigurationStreamActivationService } from './configuration-stream-activation.service';
import { StreamAvailableNotificationComponent } from './stream-available-notification/stream-available-notification.component';

@NgModule({
    declarations: [StreamAvailableNotificationComponent],
    imports: [
        CommonModule,
        StreamModule,
        UiIconButtonModule,
        UiNotificationModule
    ],
    exports: [StreamModule, StreamAvailableNotificationComponent],
    providers: [ConfigurationStreamActivationService]
})
export class ConfigurationStreamModule {
    constructor(
        // eager-init
        private readonly streamActivationService: ConfigurationStreamActivationService
    ) {}
}
