import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import { lazyShareReplay } from '@mhp/common';
import { ApplicationStateService } from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { selectPricingType } from '../../dealer';
import { LocalApplicationState } from '../../state';

/**
 * Service providing information about general availability of pricing-information.
 */
@Injectable({
    providedIn: 'root'
})
export class IfShowPricingSupportService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<
            LocalApplicationState,
            AmlUiSharedState
        >
    ) {}

    /**
     * Emits if pricing is basically available to be displayed, taking into account
     * the choice made by the dealer or the availability of alternative pricing-data.
     * Note that, although pricing may be available, actual pricing-information might
     * be missing.
     */
    isPricingVisible$(): Observable<boolean> {
        const pricingVisibleByPricingType$ = this.applicationStateService
            .getLocalState()
            .pipe(
                selectPricingType,
                map((pricingType) => pricingType !== 'HIDE')
            );

        const pricingVisibleByOneToOneSharedPricing$ =
            this.applicationStateService
                .getEngineState()
                .pipe(
                    map(
                        (state) =>
                            !environment.appConfig.dealer.dealerBuild &&
                            !!state.uiState?.pricingData
                    )
                );

        return combineLatest([
            pricingVisibleByPricingType$,
            pricingVisibleByOneToOneSharedPricing$
        ]).pipe(
            map(
                ([visibleByPricingType, visibleByOneToOneSharedPricing]) =>
                    visibleByPricingType || visibleByOneToOneSharedPricing
            ),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }
}
