import { environment } from '../../../../environments/environment';
import { CategorySourceItemFilter } from './category-mapper.service';

export const combineCategorySourceItemFilters =
    (...filters: CategorySourceItemFilter[]): CategorySourceItemFilter =>
    (item, path) =>
        filters.every((filter) => filter(item, path));

/**
 * Filter out non-relevant top-level categories.
 * @param item
 * @param path
 */
export const topLevelCategoryFilter: CategorySourceItemFilter = (
    item,
    path
) => {
    const topLevelGroupName = (path[0]?.name || item.name || '').toUpperCase();

    // remove service plan and extended warranty
    return !(
        topLevelGroupName ===
        environment.appConfig.configuration.identifierSummary
    );
};

/**
 * Filter out top-level categories.
 * @param includes The categories to be included
 * @param excludes The categories to be excluded
 */
export const topLevelCategoryFilterFactory =
    (includes?: string[], excludes?: string[]): CategorySourceItemFilter =>
    (item, path) => {
        const topLevelGroupName = (
            path[0]?.name ||
            item.name ||
            ''
        ).toUpperCase();

        if (excludes?.includes(topLevelGroupName)) {
            return false;
        }
        return !!includes?.includes(topLevelGroupName);
    };

/**
 * For categories Personalisation and Accessories, restrict nesting path to 2
 * @param item
 * @param path
 */
export const restrictNestingDepthForPersonalisationAndAccessories: CategorySourceItemFilter =
    (item, path) => {
        const topLevelGroupName = (
            path[0]?.name ||
            (item.name ?? '')
        ).toUpperCase();

        // restrict nesting-depth for accessories and personalisation
        if (
            topLevelGroupName ===
                environment.appConfig.configuration.identifierAccessories ||
            topLevelGroupName ===
                environment.appConfig.configuration.identifierPersonalisation
        ) {
            if (path.length >= 2) {
                return false;
            }
        }
        return true;
    };

/**
 * When search is active, restrict the nesting-depth except for some special-categories.
 * @param searchActive Indicator if search is active or not
 * @param defaultMaxNestingDepth The nesting depth when search is inactive.
 * @param searchActiveMaxNestingDepth The nesting depth when search is active.
 */
export const activeSearchFilter: (
    searchActive: boolean,
    defaultMaxNestingDepth?: number,
    searchActiveMaxNestingDepth?: number
) => CategorySourceItemFilter = (
    searchActive: boolean,
    defaultMaxNestingDepth = 3,
    searchActiveMaxNestingDepth = 2
) => {
    const maxDepth = searchActive
        ? searchActiveMaxNestingDepth
        : defaultMaxNestingDepth;
    return (item, path) => {
        const secondaryLevelName = path[1]?.name;
        return (
            path.length < maxDepth ||
            secondaryLevelName ===
                environment.appConfig.configuration
                    .identifierEnvironmentSelection ||
            secondaryLevelName ===
                environment.appConfig.configuration.identifierLiveries ||
            environment.appConfig.configuration.identifierStitchingTypeSelection.indexOf(
                secondaryLevelName
            ) > -1
        );
    };
};
