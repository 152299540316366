import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageSrcset } from '@mhp/ui-components';

@Component({
    selector: 'mhp-environment-selection-turntable',
    templateUrl: './environment-selection-turntable.component.html',
    styleUrls: ['./environment-selection-turntable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvironmentSelectionTurntableComponent {
    percentLoaded = 0;

    private activeStep = 0;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            interiorImageSrcsets: ImageSrcset[];
        },
        private readonly dialogRef: MatDialogRef<EnvironmentSelectionTurntableComponent>,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    intentActionClose() {
        this.dialogRef.close();
    }

    onActiveStepChange(activeStep: number) {
        this.activeStep = activeStep;
    }

    onPreloadStatusChange(percentLoaded: number) {
        this.percentLoaded = percentLoaded;
        this.changeDetectorRef.detectChanges();
    }
}
