<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-dialog-body [noPadding]="true">
    <div class="video-chat-dialog__dialog-body">
        <div class="video-chat-dialog__dialog-body__container">
            <div class="video-chat-dialog__dialog-body__container__section">
                <div
                    class="video-chat-dialog__dialog-body__container__section__frame"
                >
                    <mhp-twilio-participant-track
                        trackSource="REMOTE"
                    ></mhp-twilio-participant-track>
                </div>
                <p
                    class="video-chat-dialog__dialog-body__container__section__participant-name"
                >
                    <ng-container *ngIf="isDealerBuild; else remoteDealer">{{
                        getCustomerName$() | async
                    }}</ng-container>
                    <ng-template #remoteDealer>{{
                        getDealerName$() | async
                    }}</ng-template>
                </p>
            </div>
            <div class="video-chat-dialog__dialog-body__container__section">
                <div
                    class="video-chat-dialog__dialog-body__container__section__frame"
                >
                    <mhp-twilio-participant-track
                        trackSource="LOCAL"
                    ></mhp-twilio-participant-track>
                    <div
                        class="video-chat-dialog__dialog-body__container__section__frame__control"
                    >
                        <mhp-audio-video-settings-form-control></mhp-audio-video-settings-form-control>
                    </div>
                </div>
                <p
                    class="video-chat-dialog__dialog-body__container__section__participant-name"
                >
                    <ng-container *ngIf="isDealerBuild; else localCustomer">{{
                        getDealerName$() | async
                    }}</ng-container>
                    <ng-template #localCustomer>{{
                        getCustomerName$() | async
                    }}</ng-template>
                </p>
            </div>
        </div>
    </div>
</mhp-dialog-body>
