import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    UiFormFieldModule,
    UiIconButtonModule,
    UiSelectModule,
    UiTabsModule,
    UiTooltipModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { OverflowIndicatorModule } from '../../common/overflow-indicator';
import { ConfigurationCategorySelectComponent } from '../configuration-elements/configuration-category-select/configuration-category-select.component';
import { ExpandableCategoryBarComponent } from './expandable-category-bar.component';

@NgModule({
    declarations: [ExpandableCategoryBarComponent],
    imports: [
        CommonModule,
        OverflowIndicatorModule,
        CdkScrollableModule,
        UiTabsModule,
        UiFormFieldModule,
        UiSelectModule,
        UiIconButtonModule,
        I18nModule,
        UiTooltipModule,
        ConfigurationCategorySelectComponent
    ],
    exports: [ExpandableCategoryBarComponent]
})
export class ExpandableCategoryBarModule {}
