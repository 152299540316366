import { UrlMatchResult, UrlSegment } from '@angular/router';

export const configurationRouteMatcher = (
    segments: UrlSegment[]
): UrlMatchResult => {
    if (segments.length > 0) {
        if (segments.length === 1) {
            return {
                consumed: segments,
                posParams: { productId: segments[0] }
            };
        }
        if (segments.length === 2) {
            return {
                consumed: segments,
                posParams: {
                    productId: segments[0],
                    serializedSessionInfo: segments[1]
                }
            };
        }
    }
    return {
        consumed: segments,
        posParams: {}
    };
};
