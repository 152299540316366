<div class="order-management__table-entry" *transloco="let t">
    <div class="order-management__table-form-group-description-left">
        {{ t('ORDER_MANAGEMENT.DETAIL_VIEW.TABLE_HEADLINE_ITEM') }}
    </div>
    <div class="order-management__table-form-group-description-left">
        {{
            this.showDescription
                ? t('ORDER_MANAGEMENT.DETAIL_VIEW.TABLE_HEADLINE_DESCRIPTION')
                : ''
        }}
    </div>
    <div
        *ngIf="this.dataAvailable && this.showPricingDNP"
        class="order-management__table-pricing-col-right"
    >
        {{ t('ORDER_MANAGEMENT.PRICING.DNP') }}
    </div>
    <div
        *ngIf="this.dataAvailable && this.showPricingRRP"
        class="order-management__table-pricing-col-right"
    >
        {{ t('ORDER_MANAGEMENT.PRICING.RRP') }}
    </div>
</div>
