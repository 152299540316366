import { range, values } from 'lodash-es';
import { Observable } from 'rxjs';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Memoize } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';

export const CODE_LENGTH = 10;

const noWhitespaceValidator = (control: UntypedFormControl) => {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
};

@Component({
    selector: 'mhp-load-code-dialog',
    templateUrl: './load-code-dialog.component.html',
    styleUrls: ['./load-code-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadCodeDialogComponent
    extends UiBaseComponent
    implements AfterViewInit
{
    @ViewChildren('codeInput')
    codeElements: QueryList<ElementRef<HTMLInputElement>>;

    @ViewChild('loadCode', { static: false })
    loadCode: MatButton;

    @Output()
    readonly confirm = new EventEmitter<string>();

    readonly codeFormGroup: UntypedFormGroup;

    private ignoreNextInput: boolean;

    private static readonly fieldNameFactory = (index) => `codePos_${index}`;

    readonly trackByIndex = (index) => index;

    constructor() {
        super();

        this.codeFormGroup = this.initCodeFormGroup();

        this.completeOnDestroy(this.confirm);
    }

    ngAfterViewInit() {
        this.codeElements.get(0)?.nativeElement.focus();
    }

    @Memoize()
    getFormControls() {
        return values(this.codeFormGroup.controls);
    }

    handleServerCallInProgress<T>(
        sourceObservable$: Observable<T>
    ): Observable<T> {
        return super.handleServerCallInProgress(sourceObservable$);
    }

    intentLoadCode() {
        this.confirm.next(this.getCodeFromForm());
    }

    onPaste(clipboardEvent: ClipboardEvent) {
        const textData = clipboardEvent.clipboardData?.getData('Text');
        if (!textData) {
            return;
        }
        const codeFragments = textData.split('');
        codeFragments.forEach((fragment, index) => {
            this.getCodeFragmentControl(index)?.setValue(fragment);
        });

        values(this.codeFormGroup.controls).forEach((control) =>
            control.markAllAsTouched()
        );

        if (this.codeFormGroup.valid) {
            this.ignoreNextInput = true;
            setTimeout(() => {
                this.loadCode.focus();
            });
            return;
        }

        // focus first invalid
        const firstInvalidIndex = values(this.codeFormGroup.controls).findIndex(
            (control) => !control.valid
        );
        if (firstInvalidIndex >= 0) {
            this.codeElements.get(firstInvalidIndex)?.nativeElement.focus();
        }
    }

    onInput(index: number) {
        if (!this.getCodeFragmentControl(index)?.value) {
            return;
        }

        if (this.ignoreNextInput) {
            this.ignoreNextInput = false;
            return;
        }

        const nextInputElement = this.codeElements.get(
            index + 1
        )?.nativeElement;
        if (!nextInputElement) {
            if (this.codeFormGroup.valid) {
                this.loadCode.focus();
            }
            return;
        }

        nextInputElement.focus();
    }

    onFocus(index: number) {
        const inputElement = this.codeElements.get(index)?.nativeElement;
        inputElement?.setSelectionRange(0, inputElement.value.length);
    }

    onBackspace(index: number) {
        const previousIndex = index - 1;
        if (
            !this.getCodeFragmentControl(index)?.value?.trim() &&
            previousIndex >= 0
        ) {
            this.codeElements.get(previousIndex)?.nativeElement.focus();
        }
    }

    onArrowMove(index: number, direction: 'L' | 'R') {
        const nextIndex = direction === 'L' ? index - 1 : index + 1;
        if (nextIndex >= 0 && nextIndex < CODE_LENGTH) {
            this.codeElements.get(nextIndex)?.nativeElement.focus();
        }
    }

    private initCodeFormGroup() {
        return new UntypedFormGroup(
            range(0, CODE_LENGTH).reduce((formGroup, index) => {
                formGroup[`codePos_${index}`] = new UntypedFormControl(
                    undefined,
                    [
                        Validators.required,
                        Validators.maxLength(1),
                        noWhitespaceValidator,
                        Validators.pattern(/^[a-zA-Z0-9]$/)
                    ]
                );
                return formGroup;
            }, {})
        );
    }

    private getCodeFromForm() {
        return range(0, CODE_LENGTH)
            .reduce((codeArray, index) => {
                codeArray.push(this.getCodeFragmentControl(index)?.value);
                return codeArray;
            }, <string[]>[])
            .join('');
    }

    private getCodeFragmentControl(index: number): AbstractControl | undefined {
        return this.codeFormGroup.controls[
            LoadCodeDialogComponent.fieldNameFactory(index)
        ];
    }
}
