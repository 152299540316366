import { Observable, merge } from 'rxjs';
import { delay, map, skip, take } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    Inject
} from '@angular/core';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef
} from '@angular/material/snack-bar';
import { lazyShareReplay } from '@mhp/common';
import {
    NotificationData,
    UI_NOTIFICATION_CONFIG,
    UiNotificationComponent,
    UiNotificationConfig
} from '@mhp/ui-components';

export interface StreamAvailableNotificationData extends NotificationData {
    progress$: Observable<number>;
}

@Component({
    selector: 'mhp-stream-available-notification',
    templateUrl: './stream-available-notification.component.html',
    styleUrls: ['./stream-available-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StreamAvailableNotificationComponent extends UiNotificationComponent<StreamAvailableNotificationData> {
    readonly progress$: Observable<number>;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) data: StreamAvailableNotificationData,
        snackBarRef: MatSnackBarRef<StreamAvailableNotificationComponent>,
        @Inject(UI_NOTIFICATION_CONFIG) config: UiNotificationConfig
    ) {
        super(data, snackBarRef, config);

        /* this is a rather hacky workaround for the transition
         * to not initially jump to first 10% but start from 0.
         * Seems to be related to the snackbar animation to pause child-transitions
         * until it's visible which resolves to the 300ms initial wait...
         */
        const sharedProgress$ = this.data.progress$.pipe(lazyShareReplay());
        this.progress$ = merge(
            sharedProgress$.pipe(delay(300), take(1)),
            sharedProgress$.pipe(skip(1))
        ).pipe(map((progress) => progress + 0.1));
    }

    @HostListener('click')
    intentTriggerAction() {
        super.intentTriggerAction();
    }
}
