import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface Context<T> {
    ngVariable: T;
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ngVariable]'
})
export class NgVariableDirective<T = unknown> {
    private context?: Context<T>;

    static ngTemplateContextGuard<T>(
        dir: NgVariableDirective<T>,
        ctx: any
    ): ctx is Context<T> {
        return true;
    }

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<Context<T>>
    ) {}

    @Input()
    set ngVariable(value: T) {
        if (this.context) {
            this.context.ngVariable = value;
        } else {
            this.context = { ngVariable: value };
            this.viewContainerRef.createEmbeddedView(
                this.templateRef,
                this.context
            );
        }
    }
}
