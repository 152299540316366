import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HideForSecretDerivativeDirective } from './hide-for-secret-derivative/hide-for-secret-derivative.directive';

@NgModule({
    declarations: [HideForSecretDerivativeDirective],
    imports: [CommonModule],
    exports: [HideForSecretDerivativeDirective]
})
export class UiProductDataModule {}
