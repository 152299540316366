import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
    LocalAudioTrack,
    LocalVideoTrack,
    RemoteAudioTrack,
    RemoteVideoTrack
} from 'twilio-video';

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    ViewChild
} from '@angular/core';
import { UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-attach-video-track',
    templateUrl: './attach-video-track.component.html',
    styleUrls: ['./attach-video-track.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachVideoTrackComponent
    extends UiBaseComponent
    implements AfterViewInit
{
    @ViewChild('videoElement', { static: false })
    videoElement: ElementRef<HTMLVideoElement>;

    @ViewChild('audioElement', { static: false })
    audioElement: ElementRef<HTMLAudioElement>;

    @Input() videoTrack?: LocalVideoTrack | RemoteVideoTrack;

    @Input() audioTrack?: LocalAudioTrack | RemoteAudioTrack;

    @HostBinding('class.attach-video-track--show-gradient')
    @Input()
    showGradient?: boolean;

    constructor() {
        super();
    }

    ngAfterViewInit() {
        this.initVideoTrackBinding();
        this.initAudioTrackBinding();
    }

    initVideoTrackBinding() {
        this.observeProperty<
            AttachVideoTrackComponent,
            LocalVideoTrack | RemoteVideoTrack | undefined
        >('videoTrack', true)
            .pipe(
                switchMap((videoTrack) => {
                    if (!videoTrack) {
                        return of(undefined);
                    }
                    return new Observable((subscriber) => {
                        videoTrack.attach(this.videoElement.nativeElement);
                        subscriber.next(videoTrack);

                        return () => {
                            // cleanup
                            videoTrack.detach(this.videoElement.nativeElement);
                        };
                    });
                }),
                this.takeUntilDestroy()
            )
            .subscribe();
    }

    initAudioTrackBinding() {
        this.observeProperty<
            AttachVideoTrackComponent,
            LocalAudioTrack | RemoteAudioTrack | undefined
        >('audioTrack', true)
            .pipe(
                switchMap((audioTrack) => {
                    if (!audioTrack) {
                        return of(undefined);
                    }
                    return new Observable((subscriber) => {
                        audioTrack.attach(this.audioElement.nativeElement);
                        subscriber.next(audioTrack);

                        return () => {
                            // cleanup
                            audioTrack.detach(this.audioElement.nativeElement);
                        };
                    });
                }),
                this.takeUntilDestroy()
            )
            .subscribe();
    }
}
