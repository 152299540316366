import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
    PanAction,
    PanChange,
    ZoomAction,
    ZoomChange
} from '@mhp/ui-components';
import { SocketIOService } from '@mhp/ui-shared-services';

export interface PanDelta {
    deltaX: number;
    deltaY: number;
}

export interface ZoomDelta {
    delta: number;
}

@Component({
    selector: 'mhp-configuration-trackpad',
    templateUrl: './configuration-trackpad.component.html',
    styleUrls: ['./configuration-trackpad.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationTrackpadComponent {
    @Input()
    panDampening = 160;

    @Input()
    zoomDampening = 20;

    @Input()
    disabled = false;

    @Input()
    panChangeStrategy?: (panDelta: PanDelta) => void;

    @Input()
    zoomChangeStrategy?: (zoomDelta: ZoomDelta) => void;

    private prevPanelRelativeX = 0;

    private prevPanelRelativeY = 0;

    private scale = 0;

    constructor(private socketio: SocketIOService) {}

    onPanChange(event: PanChange) {
        if (!event.panelRelative) {
            return;
        }

        const currentPanelRelativeX = event.panelRelative.x;
        const currentPanelRelativeY = event.panelRelative.y;

        switch (event.panAction) {
            case PanAction.START:
                this.prevPanelRelativeX = currentPanelRelativeX;
                this.prevPanelRelativeY = currentPanelRelativeY;
                break;
            case PanAction.MOVE: {
                const deltaX =
                    (currentPanelRelativeX - this.prevPanelRelativeX) *
                    this.panDampening;
                const deltaY =
                    (currentPanelRelativeY - this.prevPanelRelativeY) *
                    this.panDampening;

                const panChangeDelta = <PanDelta>{
                    deltaX,
                    deltaY
                };

                if (this.panChangeStrategy) {
                    this.panChangeStrategy(panChangeDelta);
                } else {
                    this.socketio.event('movecamera', {
                        DeltaX: panChangeDelta.deltaX,
                        DeltaY: panChangeDelta.deltaY,
                        DeltaZoom: 0
                    });
                }

                this.prevPanelRelativeX = currentPanelRelativeX;
                this.prevPanelRelativeY = currentPanelRelativeY;
                break;
            }
            default:
                this.prevPanelRelativeX = 0;
                this.prevPanelRelativeY = 0;
        }
    }

    onZoomChange(event: ZoomChange) {
        if (event.zoomAction === ZoomAction.START) {
            this.scale = event.scale;
            return;
        }

        const deltaScale = event.scale - this.scale;

        if (deltaScale !== 0) {
            if (this.zoomChangeStrategy) {
                this.zoomChangeStrategy({
                    delta: deltaScale
                });
            } else {
                this.socketio.event('movecamera', {
                    DeltaX: 0,
                    DeltaY: 0,
                    DeltaZoom: deltaScale
                });
            }
        }

        this.scale = event.scale;
    }

    onWheel(wheelEvent: WheelEvent) {
        wheelEvent.stopPropagation();
        wheelEvent.preventDefault();
        wheelEvent.returnValue = false;
        const deltaScale = wheelEvent.deltaY * -0.01 * this.zoomDampening;

        if (deltaScale !== 0) {
            if (this.zoomChangeStrategy) {
                this.zoomChangeStrategy({
                    delta: deltaScale
                });
            } else {
                this.socketio.event('movecamera', {
                    DeltaX: 0,
                    DeltaY: 0,
                    DeltaZoom: deltaScale
                });
            }
        }
    }
}
