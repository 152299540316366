import { entries, first, isString } from 'lodash-es';

import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';

/**
 * Convert a ConfigModel to dot-notation.
 * @param code The code to be configured.
 */
export const toDotNotation = (code: ConfigModel): string => {
    if (isString(code)) {
        return code;
    }
    const entryFirstLevel = first(entries(code));
    const entrySecondLevel = first(entries(entryFirstLevel?.[1]));
    return `${entryFirstLevel?.[0]}.${entrySecondLevel?.[0]}.${entrySecondLevel?.[1]}`;
};
