import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UiButtonModule } from '@mhp/ui-components';
import {
    GoogleTagManagerModule,
    I18nModule,
    L10nModule
} from '@mhp/ui-shared-services';

import { DealerHelperModule } from '../../../dealer/dealer-helper/dealer-helper.module';
import { ConfigurationPricingModule } from '../../configuration-pricing/configuration-pricing.module';
import { BreadcrumbComponent } from './breadcrumb.component';

@NgModule({
    declarations: [BreadcrumbComponent],
    imports: [
        CommonModule,
        RouterModule,
        UiButtonModule,
        I18nModule,
        L10nModule,
        DealerHelperModule,
        ConfigurationPricingModule,
        GoogleTagManagerModule
    ],
    exports: [BreadcrumbComponent]
})
export class BreadcrumbModule {}
