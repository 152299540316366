import { EMPTY } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';
import { SerializerService } from '@mhp/ui-shared-services';

import {
    CompleteLocationSessionData,
    ConfigurationSessionInfoService
} from '../configuration-session-info.service';

@Component({
    selector: 'mhp-session-serializer-debug',
    templateUrl: './session-info-debug.component.html',
    styleUrls: ['./session-info-debug.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionInfoDebugComponent extends UiBaseComponent {
    @ViewChild('configuration', {
        static: true
    })
    configurationTextAreaRef: ElementRef;

    constructor(
        private serializer: SerializerService,
        private configurationSessionInfoService: ConfigurationSessionInfoService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    @MemoizeObservable()
    getConfigFromUrl$() {
        return this.activatedRoute.params.pipe(
            this.takeUntilDestroy(),
            map((params) => {
                const serializedConfig = params.serializedSessionInfo;

                if (!serializedConfig) {
                    return undefined;
                }

                return this.serializer.deserializeData<CompleteLocationSessionData>(
                    serializedConfig
                );
            })
        );
    }

    applyConfig(value: string) {
        const locationSessionData = <CompleteLocationSessionData>(
            JSON.parse(value)
        );

        this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                take(1),
                switchMap((sessionInfo) => {
                    if (!sessionInfo) {
                        return EMPTY;
                    }
                    return this.configurationSessionInfoService.writeToUrl$({
                        ...locationSessionData,
                        country: sessionInfo?.country
                    });
                })
            )
            .subscribe();
    }
}
