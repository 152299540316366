import { cloneDeep, isBoolean, isEmpty } from 'lodash-es';

import { Injectable } from '@angular/core';

import {
    ExtendedUiConfigurationMetaItem,
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiOptionCode,
    ExtendedUiOptionCollection,
    ExtendedUiOptionGroup,
    ExtendedUiOptionList
} from '../configuration-model/configuration-interfaces';
import {
    isContentAware,
    isExtendedUiOptionCode
} from '../services/configuration-helper';

/**
 * Provides support for filtering configuration-nodes using a given search input.
 */
@Injectable({
    providedIn: 'root'
})
export class ConfigurationFilterService {
    /**
     * Filter the given option groups structure using the given searchString.
     * Does not modify the input array.
     *
     * @param optionsGroups The OptionGroups to be filtered.
     * @param searchString The searchString to be used.
     * @return The filtered OptionGroup array as deep clone of the original structure.
     */
    filterOptionGroups<T extends ExtendedUiContentAwareConfigurationMetaItem>(
        optionsGroups: T[],
        searchString: string
    ): T[] {
        const normalizedSearchString = searchString.toLowerCase();
        const clonedOptionGroups = cloneDeep(optionsGroups);
        return clonedOptionGroups
            .map((contentAware) =>
                this.filterContentAware(contentAware, (node) => {
                    if (isExtendedUiOptionCode(node)) {
                        return this.optionCodeMatchesSearch(
                            node,
                            normalizedSearchString
                        );
                    }
                    return undefined;
                })
            )
            .filter((contentAware): contentAware is T => !!contentAware);
    }

    /**
     * Filter the given option groups structure using the given filterCallback.
     * The filterCallback will only be called for ExtendedUiOptionCode nodes.
     * Does not modify the input array.
     *
     * @param optionsGroups
     * @param filterCallback
     */
    filterOptionGroupsUsingCallback<
        T extends ExtendedUiContentAwareConfigurationMetaItem
    >(
        optionsGroups: T[],
        filterCallback: (optionCode: ExtendedUiOptionCode) => boolean
    ): T[] {
        const clonedOptionGroups = cloneDeep(optionsGroups);
        return clonedOptionGroups
            .map((contentAware) =>
                this.filterContentAware(contentAware, (item) => {
                    if (!isExtendedUiOptionCode(item)) {
                        return undefined;
                    }
                    return filterCallback(item);
                })
            )
            .filter((contentAware): contentAware is T => !!contentAware);
    }

    /**
     * Filter the given option groups structure using the given filterCallback.
     * The filterCallback will be called for all given nodes.
     * Does not modify the input array.
     *
     * @param optionsGroups
     * @param filterCallback
     */
    filterOptionGroupsUsingItemCallback<
        T extends ExtendedUiContentAwareConfigurationMetaItem
    >(
        optionsGroups: T[],
        filterCallback: (item: ExtendedUiConfigurationMetaItem) => boolean
    ): T[] {
        const clonedOptionGroups = cloneDeep(optionsGroups);
        return clonedOptionGroups
            .map((contentAware) =>
                this.filterContentAware(contentAware, filterCallback)
            )
            .filter((contentAware): contentAware is T => !!contentAware);
    }

    private filterContentAware<
        T extends ExtendedUiContentAwareConfigurationMetaItem
    >(
        contentAware: T,
        callback: (item: ExtendedUiConfigurationMetaItem) => boolean | undefined
    ): T | undefined {
        contentAware.content = (<any[]>contentAware.content)
            .map((currentContent) => {
                const callbackResult = callback(currentContent);
                if (!isBoolean(callbackResult) || callbackResult) {
                    if (isContentAware(currentContent)) {
                        return this.filterContentAware(
                            currentContent,
                            callback
                        );
                    }
                    return currentContent;
                }
                return undefined;
            })
            .filter(
                (
                    currentContent
                ): currentContent is
                    | ExtendedUiOptionCode
                    | ExtendedUiOptionCollection
                    | ExtendedUiOptionGroup
                    | ExtendedUiOptionList => {
                    if (!currentContent) {
                        return false;
                    }
                    if (
                        isContentAware(currentContent) &&
                        currentContent.content.length === 0
                    ) {
                        return false;
                    }
                    return true;
                }
            );

        if (isEmpty(contentAware.content)) {
            return undefined;
        }

        return contentAware;
    }

    private optionCodeMatchesSearch(
        optionCode: ExtendedUiOptionCode,
        normalizedSearchString: string
    ): boolean {
        return this.nodeMatchesSearch(optionCode, normalizedSearchString);
    }

    private nodeMatchesSearch(
        node: { code?: string; nameTranslated?: string },
        normalizedSearchString: string
    ) {
        return !!(
            (node.nameTranslated &&
                node.nameTranslated
                    .toLowerCase()
                    .indexOf(normalizedSearchString) > -1) ||
            (node.code &&
                node.code.toLowerCase().indexOf(normalizedSearchString) > -1)
        );
    }
}
