export const CPQ_HEADER_ID = 'MHP';

export const INFOR_LIST_CODES: string[] = [
    'TU',
    'TL',
    'BC',
    'HO',
    'HL',
    'SU',
    'SL',
    'CC',
    'SF',
    'WT',
    'ST',
    'SF',
    'SC',
    'WS'
];
