<div class="configuration-composite-item__thumbnail" *transloco="let t">
    <mhp-ui-lazyload-image
        [imageUrls]="getItemThumbnails$() | async"
        [imageFallbackUrl]="imageFallbackUrl"
        [aspectRatio]="16 / 9"
        [willResize]="true"
        [title]="t('COMMON.ALT_RENDERING_OF', { renderedItem: item.label })"
        (stateChange)="onImageLoadingStateChange($event)"
    ></mhp-ui-lazyload-image>
    <mhp-configuration-area-action-item
        class="configuration-composite-item__action-enlarge"
        [ngClass]="{
            'configuration-composite-item__action-enlarge--image-available':
                (imageLoadingState$ | async) === 'loading-succeeded'
        }"
        svgIcon="mhp-ui:enlarge"
        iconSize="large"
        [matTooltip]="t('ICONS.FULLSCREEN_ALT')"
        (click)="intentEnlargeThumbnail()"
    ></mhp-configuration-area-action-item>
</div>
<div class="configuration-composite-item__bottom-container" *transloco="let t">
    <div class="configuration-composite-item__info-area">
        <h2>
            {{ item.label }}
        </h2>
        <div
            *ngIf="getItemOptions$() | async as itemOptions"
            class="configuration-composite-item__options"
        >
            <div
                *ngFor="let option of itemOptions"
                class="configuration-composite-item__option"
            >
                <mat-radio-button
                    color="primary"
                    labelPosition="after"
                    [name]="option.id"
                    [checked]="option.selected"
                    [disabled]="disabled()"
                    (change)="$event.source.checked = !$event.source.checked"
                    (click)="intentSelectOption(option)"
                    (keyup.enter)="intentSelectOption(option)"
                >
                    <span
                        class="configuration-composite-item__option-label"
                        [matTooltip]="debug ? last(option.id.split('-')) : ''"
                    >
                        <mhp-ui-icon
                            *ngIf="option.meta?.qOption === true"
                            svgIcon="mhp-ui:q-option"
                            size="small"
                            [matTooltip]="t('ICONS.Q_OPTION_ALT')"
                        ></mhp-ui-icon>
                        <span
                            [innerHTML]="option.labelHtml ?? option.label"
                        ></span>
                    </span>
                </mat-radio-button>
                <div
                    *ngIf="option.description"
                    class="configuration-composite-item__option-info"
                >
                    <mhp-ui-icon-button
                        svgIcon="mhp-ui:info"
                        size="large"
                        [label]="
                            t('ICONS.INFO_ON_ALT', {
                                topic: option.label
                            })
                        "
                        (click)="intentShowInfo(option)"
                    ></mhp-ui-icon-button>
                </div>
                <div
                    *ngIf="option.pricing !== undefined"
                    class="configuration-composite-item__option-price"
                >
                    {{ option | effectivePricingAwareFormat }}
                </div>
            </div>
        </div>
    </div>
</div>
