import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiLazyloadImageModule } from '@mhp/ui-components';

import { DialogModule } from '../../dialog/dialog.module';
import { FullscreenImageComponent } from './fullscreen-image/fullscreen-image.component';

@NgModule({
    declarations: [FullscreenImageComponent],
    imports: [CommonModule, DialogModule, UiLazyloadImageModule],
    exports: [FullscreenImageComponent]
})
export class ImagesModule {}
