import { isEqual } from 'lodash-es';
import { Observable, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild
} from '@angular/core';
import { UiBaseComponent } from '@mhp/ui-components';
import {
    ApplicationStateService,
    ConnectionState,
    FeatureAvailabilityService,
    StreamErrorInfo,
    StreamStatus,
    StreamingService
} from '@mhp/ui-shared-services';

import { StreamHandlerService } from '../stream-handler.service';

@Component({
    selector: 'mhp-stream-stage',
    templateUrl: './stream-stage.component.html',
    styleUrls: ['./stream-stage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StreamStageComponent extends UiBaseComponent implements OnInit {
    readonly connectionState$: Observable<ConnectionState | undefined>;

    readonly streamConnectionState$: Observable<StreamStatus>;

    readonly streamErrors$: Observable<StreamErrorInfo | undefined>;

    @ViewChild('streamVideo', {
        read: ElementRef,
        static: true
    })
    streamVideoRef: ElementRef<HTMLVideoElement>;

    @ViewChild('configurationTrackpad', {
        read: ElementRef,
        static: true
    })
    configurationTrackpadRef: ElementRef<HTMLElement>;

    constructor(
        private applicationStateService: ApplicationStateService,
        private streamingService: StreamingService,
        private streamHandlerService: StreamHandlerService,
        private featureAvailabilityService: FeatureAvailabilityService,
        @Inject(DOCUMENT) private readonly document: Document
    ) {
        super();

        this.connectionState$ = this.applicationStateService
            .getLocalSharedState()
            .pipe(map((localShared) => localShared?.connectionState));

        this.streamConnectionState$ = this.streamHandlerService
            .getStreamStatus$()
            .pipe(map((status) => status.status));
        this.streamErrors$ = this.streamHandlerService.getStreamErrors$();
    }

    ngOnInit() {
        this.initStreamBinding();
        this.initMouseBinding();
    }

    isTouchPanelEnabled$(): Observable<boolean> {
        return this.featureAvailabilityService.canUseTouchpad$();
    }

    private initStreamBinding() {
        this.streamHandlerService
            .getStreamInfo$()
            .pipe(this.takeUntilDestroy(), distinctUntilChanged(isEqual))
            .subscribe(async (streamInfo) => {
                const videoPlayer = this.streamVideoRef.nativeElement;

                if (streamInfo?.stream) {
                    videoPlayer.srcObject = streamInfo.stream;
                    try {
                        videoPlayer.muted = true;
                        await videoPlayer.play();
                    } catch (ex) {
                        console.error('Video playback failed', ex);
                    }
                } else {
                    videoPlayer.pause();
                    videoPlayer.srcObject = null;
                }
            });
    }

    private initMouseBinding() {
        const target = this.configurationTrackpadRef.nativeElement;
        fromEvent(target, 'mousedown')
            .pipe(
                tap(() => {
                    target.classList.add(
                        'stream-stage__stream-trackpad--dragging'
                    );
                }),
                switchMap(() => fromEvent(this.document, 'mouseup')),
                tap(() =>
                    target.classList.remove(
                        'stream-stage__stream-trackpad--dragging'
                    )
                ),
                this.takeUntilDestroy()
            )
            .subscribe();
    }
}
