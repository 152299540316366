import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { UiBaseComponent } from '@mhp/ui-components';

@Component({
    selector: 'mhp-search-term',
    templateUrl: './search-term.component.html',
    styleUrls: ['./search-term.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchTermComponent extends UiBaseComponent {
    @Input()
    searchTerm: string;

    @Output()
    readonly clearSearch = new EventEmitter<void>();

    constructor() {
        super();

        this.completeOnDestroy(this.clearSearch);
    }

    intentExitSearch() {
        this.clearSearch.next();
    }
}
