import { Injectable } from '@angular/core';
import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import {
    ErrorHandlerService,
    UiSharedStateService
} from '@mhp/ui-shared-services';

import { NavigationStateService } from '../../navigation/navigation-state.service';

@Injectable({
    providedIn: 'root'
})
export class NavigationSyncService {
    constructor(
        private readonly navigationStateService: NavigationStateService,
        private readonly uiSharedStateService: UiSharedStateService<AmlUiSharedState>,
        private readonly errorHandlerService: ErrorHandlerService
    ) {
        this.initNavigationSync();
    }

    private initNavigationSync() {
        this.navigationStateService
            .getActiveApplicationNavigationArea$()
            .subscribe((activeNavigationArea) => {
                this.uiSharedStateService
                    .updateUiState((uiState) => ({
                        ...uiState,
                        navigationArea: activeNavigationArea
                    }))
                    .pipe(this.errorHandlerService.applyRetry())
                    .subscribe();
            });
    }
}
