<mhp-fullscreen-dialog [wrapInDialogBody]="false">
    <div class="video-playback">
        <mhp-loading-spinner *ngIf="!videoReadyToShow"></mhp-loading-spinner>
        <video
            class="video-playback__video"
            [ngClass]="{
                'video-playback__video--ready': videoReadyToShow
            }"
            #video
            [src]="videoUrl"
            (loadedmetadata)="onLoadedMetadata()"
            (click)="togglePlayback()"
            (ended)="onEnded()"
            (error)="onError($event)"
            autoplay
            playsinline
            muted
            controls
        ></video>
    </div>
</mhp-fullscreen-dialog>
