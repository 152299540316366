import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { EngineSettingsService } from '@mhp/ui-shared-services';

/**
 * Service providing information around VR integration.
 * This one is to be used to obtain information about vr-feature availability without needing to alter vr-settings.
 * There is no need for special dependencies to use this service in contrast to the full-fledged VrService.
 */
@Injectable({
    providedIn: 'root'
})
export class VrInfoService {
    constructor(
        private readonly engineSettingsService: EngineSettingsService
    ) {}

    /**
     * Emits if VR is currently available or not.
     */
    @MemoizeObservable()
    isVrAvailable$(): Observable<boolean> {
        return this.engineSettingsService.getVrModeAvailableState$();
    }

    /**
     * Emits if VR is currently active or not.
     */
    @MemoizeObservable()
    isVrActive$(): Observable<boolean> {
        return this.engineSettingsService.getVrModeActiveState$();
    }
}
