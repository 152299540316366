import { first } from 'lodash-es';
import { Observable, combineLatest, of, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { CameraType } from '@mhp-immersive-exp/contracts/src/camera/camera.interface';
import { MemoizeObservable } from '@mhp/common';
import {
    EngineControlService,
    FeatureAvailabilityService,
    ProductDataService
} from '@mhp/ui-shared-services';

@Injectable()
export class CameraControlService {
    constructor(
        private readonly engineControlService: EngineControlService,
        protected readonly productDataService: ProductDataService,
        private readonly featureAvailabilityService: FeatureAvailabilityService
    ) {}

    /**
     * Set the active camera.
     * Skip applying the camera-change if it's not valid in the current context,
     * e.g. environment is active that is not listed in relatedEnvironments list.
     * @param id Camera id
     * @param options Optional parameter to allow setting additional options
     *                - skipIfUnchanged: If setting the camera should be skipped when unchanged.
     *                - revertToDefaultCameraIfNonExisting: If the camera should be set to the first available camera in case the camera to be set.
     */
    setActiveCamera$(
        id: string,
        options: {
            skipIfUnchanged?: boolean;
            revertToDefaultCameraIfNonExisting?: boolean;
        } = {
            skipIfUnchanged: false,
            revertToDefaultCameraIfNonExisting: false
        }
    ): Observable<string | undefined> {
        return this.getTargetCameraCandidate$(id, options).pipe(
            switchMap((targetCameraCandidate) => {
                if (!targetCameraCandidate) {
                    return of(undefined);
                }

                return this.engineControlService.setActiveCamera(
                    targetCameraCandidate.id,
                    options?.skipIfUnchanged
                );
            })
        );
    }

    /**
     * Reset the active camera.
     */
    intentResetCamera$() {
        return this.engineControlService.resetCamera$();
    }

    @MemoizeObservable()
    canAlterCamera$() {
        return this.featureAvailabilityService.canAlterCamera$();
    }

    /**
     * Emits true/false depending on whether the active camera is the default camera.
     */
    @MemoizeObservable()
    isDefaultCameraActive$() {
        return combineLatest([
            this.engineControlService.getActiveCamera$(),
            this.engineControlService.getDefaultCameraForContext$()
        ]).pipe(
            map(
                ([activeCamera, defaultCamera]) =>
                    activeCamera === defaultCamera
            )
        );
    }

    /**
     * Get the type of the currently active camera.
     */
    getActiveCameraType$(): Observable<CameraType | undefined> {
        return this.engineControlService.getActiveCameraType$();
    }

    /**
     * Get the target-camera candidate for the given cameraId and options.
     *
     * @param id Camera id
     * @param options Optional parameter to allow setting additional options
     *                - revertToDefaultCameraIfNonExisting: If the camera should be set to the first available camera in case the camera to be set.
     */
    protected getTargetCameraCandidate$(
        id: string,
        options: {
            revertToDefaultCameraIfNonExisting?: boolean;
        }
    ) {
        return this.productDataService.getAvailableCameras$().pipe(
            map((availableCameras) => {
                let matchingCamera = availableCameras?.cameras?.find(
                    (camera) => camera.id === id
                );

                if (!matchingCamera) {
                    if (options?.revertToDefaultCameraIfNonExisting) {
                        matchingCamera = first(availableCameras?.cameras);
                    }

                    if (!matchingCamera) {
                        return undefined;
                    }
                }

                return matchingCamera;
            }),
            take(1)
        );
    }
}
