import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ConfigurationAreaActionsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-area-actions';
import {
    UiButtonModule,
    UiIconsModule,
    UiSlideToggleModule,
    UiTooltipModule
} from '@mhp/ui-components';
import { I18nModule, NgVariableModule } from '@mhp/ui-shared-services';

import { AttachVideoTrackComponent } from './attach-video-track/attach-video-track.component';
import { AudioVideoSettingsFormControlComponent } from './audio-video-settings-form-control/audio-video-settings-form-control.component';
import { LocalParticipantTrackComponent } from './local-participant-track/local-participant-track.component';
import { TwilioParticipantTrackComponent } from './twilio-participant-track/twilio-participant-track.component';
import { VideoPlaceholderComponent } from './video-placeholder/video-placeholder.component';

@NgModule({
    declarations: [
        AttachVideoTrackComponent,
        LocalParticipantTrackComponent,
        TwilioParticipantTrackComponent,
        VideoPlaceholderComponent,
        AudioVideoSettingsFormControlComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,

        I18nModule,
        ConfigurationAreaActionsModule,
        NgVariableModule,

        UiTooltipModule,
        UiButtonModule,
        UiSlideToggleModule,
        UiIconsModule
    ],
    exports: [
        LocalParticipantTrackComponent,
        TwilioParticipantTrackComponent,
        AudioVideoSettingsFormControlComponent
    ]
})
export class DisplayVideoTracksModule {}
