import { Observable, combineLatest, of, switchMap } from 'rxjs';
import { debounceTime, map, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { EnvironmentLightingProfileState } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { OptionalObservable, toObservable } from '@mhp/common/rxjs/rxjs-types';
import {
    ApplicationStateService,
    EngineControlService,
    ErrorHandlerService
} from '@mhp/ui-shared-services';
import { EngineStateService } from '@mhp/ui-shared-services/engine/engine-state.service';

import { environment } from '../../../environments/environment';
import { LocalApplicationState } from '../../state';
import { CarFeatureControlService } from '../car-features/car-feature-control.service';
import { setUserEnvironmentLightingStateChoice } from '../state';

@Injectable()
export class EnvironmentControlService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly engineControlService: EngineControlService,
        private readonly engineStateService: EngineStateService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly carFeatureControlService: CarFeatureControlService
    ) {}

    /**
     * Set the given environment to be active.
     * @param environmentId The environmentId to be activated.
     * @param options Optional options available to control behavior
     * - forceTargetMode: The explicit mode to be set.
     */
    setEnvironment$(
        environmentId: string,
        options?: {
            forceTargetMode?: EnvironmentLightingProfileState;
        }
    ): Observable<string> {
        const nightModeDisabled =
            this.isNightModeDisabledForEnvironment(environmentId);
        const dayModeDisabled =
            this.isDayModeDisabledForEnvironment(environmentId);

        let targetDayNightState$: OptionalObservable<
            EnvironmentLightingProfileState | undefined
        > = options?.forceTargetMode;
        if (nightModeDisabled) {
            targetDayNightState$ = EnvironmentLightingProfileState.DAY;
        } else if (dayModeDisabled) {
            targetDayNightState$ = EnvironmentLightingProfileState.NIGHT;
        }

        // take the users active choice into account (if set)
        targetDayNightState$ = toObservable(targetDayNightState$).pipe(
            switchMap((forceTargetLightingProfileState) =>
                forceTargetLightingProfileState
                    ? of(forceTargetLightingProfileState)
                    : this.getUsersEnvironmentLightingProfileStateChoice$().pipe(
                          take(1),
                          map((userChoice) => {
                              if (userChoice) {
                                  return userChoice;
                              }
                              if (
                                  environment.appConfig.configuration.nightModeDefaultForEnvironments?.includes(
                                      environmentId
                                  )
                              ) {
                                  // use the environment default
                                  return EnvironmentLightingProfileState.NIGHT;
                              }
                              return EnvironmentLightingProfileState.DAY;
                          })
                      )
            )
        );

        return toObservable(targetDayNightState$).pipe(
            switchMap((targetDayNightState) => {
                if (targetDayNightState) {
                    return this.applyLightsMatchingForMode$(
                        targetDayNightState,
                        environmentId
                    );
                }
                return of(targetDayNightState);
            }),
            switchMap((targetDayNightState) =>
                this.engineControlService
                    .setActiveEnvironmentId(environmentId, targetDayNightState)
                    .pipe(
                        this.errorHandlerService.applyRetryWithHintsOnError(
                            () =>
                                translate(
                                    'CONFIGURATOR.FEATURES.ENVIRONMENTS.ERRORS.SET_ENVIRONMENT'
                                )
                        )
                    )
            )
        );
    }

    /**
     * Toggle the environment mode or explicitly set it to a target state.
     * @param options Optional options available to control behavior
     * - forceTargetMode: The explicit mode to be set.
     * - userAction: If the initial trigger came from a user-intent
     */
    toggleEnvironmentMode$(options?: {
        forceTargetMode?: EnvironmentLightingProfileState;
        userAction?: boolean;
    }): Observable<EnvironmentLightingProfileState | undefined> {
        return combineLatest([
            options?.forceTargetMode
                ? this.engineControlService.setEnvironmentMode(
                      options.forceTargetMode
                  )
                : this.engineControlService.toggleEnvironmentMode(),
            this.engineStateService.getActiveEnvironment$()
        ]).pipe(
            take(1),
            this.errorHandlerService.applyRetryWithHintsOnError(() =>
                translate(
                    'CONFIGURATOR.FEATURES.DAY_NIGHT.ERRORS.TOGGLE_DAY_NIGHT'
                )
            ),
            // update the user-choice accordingly
            tap(([activeMode, activeEnvironment]) => {
                if (!options?.userAction) {
                    return;
                }
                this.applicationStateService.dispatch(
                    setUserEnvironmentLightingStateChoice({
                        state: activeMode
                    })
                );
            }),
            switchMap(([activeMode, activeEnvironment]) =>
                this.applyLightsMatchingForMode$(
                    activeMode ?? EnvironmentLightingProfileState.DAY,
                    activeEnvironment?.id
                )
            )
        );
    }

    /**
     * Emits if day/night can be toggled for the current context.
     */
    @MemoizeObservable()
    isDayNightToggleDisabled$(): Observable<boolean> {
        return combineLatest([
            this.isNightModeDisabled$(),
            this.isDayModeDisabled$()
        ]).pipe(
            debounceTime(0),
            map(
                ([nightModeDisabled, dayModeDisabled]) =>
                    nightModeDisabled || dayModeDisabled
            )
        );
    }

    /**
     * Emits if night-mode is disabled for the current context.
     */
    @MemoizeObservable()
    isNightModeDisabled$(): Observable<boolean> {
        return this.engineStateService
            .getActiveEnvironmentId$()
            .pipe(
                switchMap((environmentId) =>
                    toObservable(
                        this.isNightModeDisabledForEnvironment(environmentId)
                    )
                )
            );
    }

    /**
     * Emits if day-mode is disabled for the current context.
     */
    @MemoizeObservable()
    isDayModeDisabled$(): Observable<boolean> {
        return this.engineStateService
            .getActiveEnvironmentId$()
            .pipe(
                switchMap((environmentId) =>
                    toObservable(
                        this.isDayModeDisabledForEnvironment(environmentId)
                    )
                )
            );
    }

    @MemoizeObservable()
    getActiveDayNightState$(): Observable<
        EnvironmentLightingProfileState | undefined
    > {
        return combineLatest([
            this.engineStateService.getEnvironmentMode$(),
            this.isDayNightToggleDisabled$()
        ]).pipe(
            map(([envMode, dayNightToggleDisabled]) =>
                dayNightToggleDisabled ? undefined : envMode
            ),
            lazyShareReplay()
        );
    }

    /**
     * Emit the users active choice concerning the current environment lighting profile state.
     */
    getUsersEnvironmentLightingProfileStateChoice$(): Observable<
        EnvironmentLightingProfileState | undefined
    > {
        return this.applicationStateService
            .getLocalState()
            .pipe(
                map(
                    (state) =>
                        state?.configuration
                            ?.userActiveEnvironmentLightingStateChoice
                )
            );
    }

    /**
     * Reset the environment lighting choice made by the user.
     */
    resetUsersEnvironmentLightingProfileStateChoice(): void {
        this.applicationStateService.dispatch(
            setUserEnvironmentLightingStateChoice({
                state: undefined
            })
        );
    }

    /**
     * Toggle lights-state required for the given target mode.
     * @param targetMode The explicit mode to take into account.
     */
    private applyLightsMatchingForMode$(
        targetMode: EnvironmentLightingProfileState,
        environmentId?: string
    ): Observable<EnvironmentLightingProfileState | undefined> {
        let targetLightState: 'ON' | 'OFF' =
            targetMode === EnvironmentLightingProfileState.DAY ? 'OFF' : 'ON';

        if (
            environment.appConfig.configuration.nightModeDisabledForEnvironments?.includes(
                environmentId
            )
        ) {
            targetLightState = 'OFF';
        } else if (
            environment.appConfig.configuration.dayModeDisabledForEnvironments?.includes(
                environmentId
            )
        ) {
            targetLightState = 'ON';
        }

        return this.carFeatureControlService
            .setLightsState$(targetLightState)
            .pipe(map(() => targetMode));
    }

    /**
     * Emits if night-mode is disabled for the given context.
     */
    private isNightModeDisabledForEnvironment(
        environmentId: string | undefined
    ): OptionalObservable<boolean> {
        if (!environmentId) {
            return true;
        }
        return environment.appConfig.configuration.nightModeDisabledForEnvironments.includes(
            environmentId
        );
    }

    /**
     * Emits if day-mode is disabled for the given context.
     */
    private isDayModeDisabledForEnvironment(
        environmentId: string | undefined
    ): OptionalObservable<boolean> {
        if (!environmentId) {
            return true;
        }
        return environment.appConfig.configuration.dayModeDisabledForEnvironments.includes(
            environmentId
        );
    }
}
