import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    UiButtonModule,
    UiCheckboxModule,
    UiFormFieldModule,
    UiIconButtonModule,
    UiIconsModule,
    UiSelectModule,
    UiSlideToggleModule,
    UiTooltipModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { DealerHelperModule } from '../../dealer/dealer-helper/dealer-helper.module';
import { DialogModule } from '../../dialog/dialog.module';
import { HeaderBarModule } from '../../header-bar/header-bar.module';
import { SidebarPanelModule } from '../../menu/sidebar-panel/sidebar-panel.module';
import { SettingsModule } from '../../settings/settings.module';
import { ConfigurationMenuComponent } from './configuration-menu.component';
import { MenuSidebarItemLinksComponent } from './menu-sidebar-item-links/menu-sidebar-item-links.component';
import { MenuTocModule } from './menu-toc/menu-toc.module';

@NgModule({
    declarations: [ConfigurationMenuComponent, MenuSidebarItemLinksComponent],
    imports: [
        CommonModule,
        HeaderBarModule,
        RouterModule,
        I18nModule,
        UiButtonModule,
        UiIconButtonModule,
        SidebarPanelModule,
        SettingsModule,
        MenuTocModule,
        DialogModule,
        I18nModule,
        UiCheckboxModule,
        DealerHelperModule,
        UiSelectModule,
        UiSlideToggleModule,
        UiFormFieldModule,
        UiIconsModule,
        UiTooltipModule,
        A11yModule
    ],
    exports: [ConfigurationMenuComponent]
})
export class ConfigurationMenuModule {}
