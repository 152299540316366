import { Observable, combineLatest, merge } from 'rxjs';
import { debounceTime, delay, map } from 'rxjs/operators';

import { animate, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    Renderer2,
    ViewChild
} from '@angular/core';
import { IllegalStateError, MemoizeObservable } from '@mhp/common';
import { EASE_OUT_EXPO, UiBaseComponent } from '@mhp/ui-components';
import { ApplicationStateService } from '@mhp/ui-shared-services';

import { AmlBreakpoints } from '../../common/breakpoints/AmlBreakpoints';
import {
    RegionAndLanguageSelectionInterceptor,
    RegionAndLanguageSelectionOptions
} from '../../settings/region-selector/region.service';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { buildRegionChangeInterceptor } from '../common/region-change-interceptor';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import {
    selectStageMinimizedState,
    selectStageShrinkedState
} from '../state/selectors/configuration.selectors';

@Component({
    selector: 'mhp-configuration-main-layout',
    templateUrl: './configuration-main-layout.component.html',
    styleUrls: ['./configuration-main-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('deferRemoval', [
            transition('* => void', [
                animate(
                    `200ms ${EASE_OUT_EXPO}`,
                    style({
                        visibility: 'hidden'
                    })
                )
            ])
        ]),
        trigger('toggleInnerStageEngineSettings', [
            transition('void => *', [
                style({
                    height: '0'
                }),
                animate(
                    `200ms ${EASE_OUT_EXPO}`,
                    style({
                        height: '*'
                    })
                )
            ]),
            transition('* => void', [
                animate(
                    `200ms ${EASE_OUT_EXPO}`,
                    style({
                        height: 0
                    })
                )
            ])
        ])
    ]
})
export class ConfigurationMainLayoutComponent
    extends UiBaseComponent
    implements AfterViewInit
{
    readonly regionAndLanguageSelectorInterceptor: RegionAndLanguageSelectionInterceptor;

    @Input()
    renderRaisedConfigurationBar = false;

    @Input()
    showInnerStageSettings = false;

    @Input()
    showFooter = true;

    @Input()
    regionAndLanguageSelectionOptions?: RegionAndLanguageSelectionOptions;

    @ViewChild('stagePlaceholder')
    stagePlaceholderRef: ElementRef<HTMLDivElement>;

    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly renderer2: Renderer2,
        private readonly breakpointObserver: BreakpointObserver
    ) {
        super();

        this.regionAndLanguageSelectorInterceptor =
            buildRegionChangeInterceptor(
                this.productConfigurationSessionService
            );
    }

    ngAfterViewInit() {
        this.initRoundStagePlaceholderHeight();
    }

    @MemoizeObservable()
    isStageShrinked$(): Observable<boolean> {
        return combineLatest([
            this.breakpointObserver.observe(AmlBreakpoints.Portrait),
            this.applicationStateService
                .getLocalState()
                .pipe(selectStageShrinkedState)
        ]).pipe(
            map(
                ([breakpointState, isStageShrinked]) =>
                    !breakpointState.matches && isStageShrinked
            )
        );
    }

    @MemoizeObservable()
    isStageMinimized$(): Observable<boolean> {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectStageMinimizedState);
    }

    private initRoundStagePlaceholderHeight() {
        if (!this.stagePlaceholderRef) {
            throw new IllegalStateError('Missing stagePlaceholderRef');
        }

        const stagePlaceholderElement = this.stagePlaceholderRef.nativeElement;

        new Observable((subscriber) => {
            const resizeObserver = new ResizeObserver(() => {
                subscriber.next();
            });

            resizeObserver.observe(stagePlaceholderElement);

            return () => {
                if (resizeObserver) {
                    resizeObserver.disconnect();
                }
            };
        })
            .pipe(debounceTime(500), this.takeUntilDestroy())
            .subscribe(() => {
                this.renderer2.removeStyle(stagePlaceholderElement, 'height');
                this.renderer2.setStyle(
                    stagePlaceholderElement,
                    'height',
                    `${Math.floor(stagePlaceholderElement.clientHeight)}px`
                );
            });

        merge(this.isStageShrinked$(), this.isStageMinimized$())
            .pipe(delay(0), this.takeUntilDestroy())
            .subscribe(() => {
                // reset placeholder-height
                this.renderer2.removeStyle(stagePlaceholderElement, 'height');
            });
    }
}
