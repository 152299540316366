<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    class="pricing-options-dialog"
    *transloco="let t"
    [title]="t('ORDER_MANAGEMENT.PRICING.DIALOG_HEADLINE')"
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <p>{{ t('ORDER_MANAGEMENT.PRICING.DESCRIPTION') }}</p>
        <form [formGroup]="pricingFormGroup">
            <mat-slide-toggle
                *ngIf="pricingDNPAvailable"
                labelPosition="before"
                color="primary"
                formControlName="showPricingDNP"
                >{{ t('ORDER_MANAGEMENT.PRICING.SHOW_DNP') }}
            </mat-slide-toggle>
            <mat-slide-toggle
                *ngIf="pricingRRPAvailable"
                labelPosition="before"
                color="primary"
                formControlName="showPricingRRP"
            >
                {{ t('ORDER_MANAGEMENT.PRICING.SHOW_RRP') }}
            </mat-slide-toggle>
        </form>
        <p *ngIf="!pricingDNPAvailable && !pricingRRPAvailable">
            {{ t('ORDER_MANAGEMENT.PRICING.NO_PRICING_AVAILABLE') }}
        </p>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-button
            class="mhp-ui-button--full-width"
            (click)="onCancel()"
        >
            {{ t('COMMON.CANCEL') }}
        </button>
        <button
            mat-flat-button
            class="mhp-ui-button--full-width"
            (click)="intentPricingOptions()"
        >
            <span>{{ t('COMMON.CONFIRM') }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
