import { isEqual, pick } from 'lodash-es';
import { Observable, from, fromEvent, of } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    map,
    skip,
    startWith,
    switchMap,
    take
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { ApplicationStateService } from '@mhp/ui-shared-services';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AudioVideoSettings } from '../../common/audio-video-settings/audio-video-settings.interface';
import {
    setAudioDeviceId,
    setAudioSetting,
    setVideoDeviceId,
    setVideoSetting
} from '../../dealer/one2one/state/actions/one2one-state.actions';
import { selectOne2OneState } from '../../dealer/state/selectors/dealer-state.selectors';
import { LocalApplicationState } from '../../state/local-application-state.interface';

/**
 * See https://www.twilio.com/blog/video-chat-app-asp-net-core-3-1-angular-9-twilio
 */
@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    constructor(
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>
    ) {
        this.listenToDeviceIdChanges();
    }

    @MemoizeObservable()
    getAvailableDevices$(): Observable<MediaDeviceInfo[]> {
        return this.getAvailableDevicesWithoutPriorPermissions$().pipe(
            switchMap((allDevices) => {
                if (allDevices.every((device) => device.label)) {
                    return of(allDevices);
                }
                return this.getDevicePermissions$({
                    audio: true,
                    video: true
                }).pipe(
                    switchMap(() =>
                        this.getAvailableDevicesWithoutPriorPermissions$()
                    )
                );
            }),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getDefaultDevice$(
        deviceKind: 'audioinput' | 'videoinput'
    ): Observable<MediaDeviceInfo | undefined> {
        return this.getAvailableDevices$().pipe(
            map((availableDevices) =>
                this.resolveDefaultDeviceId(availableDevices, deviceKind)
            ),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    startDeviceChecks$() {
        return new Observable((subscriber) => {
            const deviceSubscription = this.getAvailableDevices$().subscribe({
                next: (availableDevices) => {
                    const foundVideoInputDevice = availableDevices.find(
                        (device) => device.kind === 'videoinput'
                    );
                    const foundAudioInputDevice = availableDevices.find(
                        (device) => device.kind === 'audioinput'
                    );

                    this.applicationStateService.dispatch(
                        setVideoDeviceId({
                            videoDeviceId: foundVideoInputDevice?.deviceId
                        })
                    );
                    this.applicationStateService.dispatch(
                        setAudioDeviceId({
                            audioDeviceId: foundAudioInputDevice?.deviceId
                        })
                    );
                },
                complete: () => subscriber.complete(),
                error: (error) => {
                    this.applicationStateService.dispatch(
                        setVideoDeviceId({
                            videoDeviceId: undefined
                        })
                    );
                    this.applicationStateService.dispatch(
                        setAudioDeviceId({
                            audioDeviceId: undefined
                        })
                    );

                    subscriber.error(error);
                }
            });

            return () => {
                deviceSubscription.unsubscribe();
            };
        }).pipe(lazyShareReplay());
    }

    @MemoizeObservable()
    hasPermissionError$() {
        return this.startDeviceChecks$().pipe(
            catchError(() => of(true)),
            startWith(false),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    private getAvailableDevicesWithoutPriorPermissions$(): Observable<
        MediaDeviceInfo[]
    > {
        return fromEvent(navigator.mediaDevices, 'devicechange')
            .pipe(startWith(undefined))
            .pipe(
                switchMap(() =>
                    from(navigator.mediaDevices.enumerateDevices())
                ),
                map((devices) =>
                    devices.filter(
                        (device) =>
                            device.kind === 'audioinput' ||
                            device.kind === 'videoinput'
                    )
                ),
                distinctUntilChanged((a, b) =>
                    isEqual(
                        a.map((device) =>
                            pick(device, [
                                'deviceId',
                                'groupId',
                                'kind',
                                'label'
                            ])
                        ),
                        b.map((device) =>
                            pick(device, [
                                'deviceId',
                                'groupId',
                                'kind',
                                'label'
                            ])
                        )
                    )
                )
            );
    }

    private getDevicePermissions$(
        constraints?: MediaStreamConstraints
    ): Observable<boolean> {
        return from(navigator.mediaDevices.getUserMedia(constraints)).pipe(
            switchMap(
                (mediaStream) =>
                    new Observable<MediaStream>((subscriber) => {
                        subscriber.next(mediaStream);

                        return () => {
                            mediaStream
                                .getTracks()
                                .forEach((track) => track.stop());
                        };
                    })
            ),
            take(1),
            map(() => true)
        );
    }

    /**
     * Once device id's are set, update the audio and video settings
     * based on the user's preferences (e.g. target values). If the
     * device id's are undefined, disable the setting
     */
    private listenToDeviceIdChanges() {
        const audioVideoSettings$: Observable<AudioVideoSettings | undefined> =
            this.applicationStateService.getLocalState().pipe(
                selectOne2OneState,
                map((state) => state?.audioVideoSettings),
                skip(1),
                distinctUntilChanged(isEqual),
                lazyShareReplay()
            );

        const audioSettings$: Observable<
            | Pick<AudioVideoSettings, 'targetAudio' | 'audioDeviceId'>
            | undefined
        > = audioVideoSettings$.pipe(
            map((audioVideoSettings) => ({
                targetAudio: audioVideoSettings?.targetAudio,
                audioDeviceId: audioVideoSettings?.audioDeviceId
            })),
            distinctUntilChanged(isEqual)
        );
        const videoSettings$: Observable<
            | Pick<AudioVideoSettings, 'targetVideo' | 'videoDeviceId'>
            | undefined
        > = audioVideoSettings$.pipe(
            map((audioVideoSettings) => ({
                targetVideo: audioVideoSettings?.targetVideo,
                videoDeviceId: audioVideoSettings?.videoDeviceId
            })),
            distinctUntilChanged(isEqual)
        );

        audioSettings$.subscribe((audioSettings) => {
            if (audioSettings?.audioDeviceId) {
                this.applicationStateService.dispatch(
                    setAudioSetting({
                        audio: audioSettings.targetAudio
                    })
                );
            } else {
                this.applicationStateService.dispatch(
                    setAudioSetting({ audio: false })
                );
            }
        });

        videoSettings$.subscribe((videoSettings) => {
            if (videoSettings?.videoDeviceId) {
                this.applicationStateService.dispatch(
                    setVideoSetting({
                        video: videoSettings.targetVideo
                    })
                );
            } else {
                this.applicationStateService.dispatch(
                    setVideoSetting({ video: false })
                );
            }
        });
    }

    private resolveDefaultDeviceId(
        devices: MediaDeviceInfo[],
        deviceKind: 'audioinput' | 'videoinput'
    ): MediaDeviceInfo | undefined {
        let defaultDevice = devices.find(
            (d) => d.deviceId === 'default' && d.kind === deviceKind
        );

        if (defaultDevice) {
            const defaultDeviceGroupId = defaultDevice.groupId;
            defaultDevice = devices.find(
                (d) =>
                    d.groupId === defaultDeviceGroupId &&
                    d.deviceId !== 'default'
            );
        }

        if (defaultDevice) {
            return defaultDevice;
        }

        return devices.find((device) => device.kind === deviceKind);
    }
}
