import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import Swiper, { Autoplay } from 'swiper';

import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent, UiSwiperModule } from '@mhp/ui-components';

import { AmlBreakpoints } from '../../../common/breakpoints/AmlBreakpoints';
import { ImgComparisonElement } from '../configuration-summary/summary-image-provider.service';

UiSwiperModule.registerFeatures(Autoplay);

@Component({
    selector: 'mhp-summary-slider',
    templateUrl: './summary-slider.component.html',
    styleUrls: ['./summary-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummarySliderComponent extends UiBaseComponent {
    @Input()
    imageUrls: ImgComparisonElement[];

    @Input()
    slidesPerView = 1;

    @Input()
    comparisonSliderHandleHidden = false;

    @Input()
    autoplayDelay = 5000;

    @Input()
    autoplayDisabled = false;

    private swiperInstance?: Swiper;

    private readonly userInteractedWithSwiperSubject =
        new BehaviorSubject<boolean>(false);

    private readonly userInteractedWithSwiper$ =
        this.userInteractedWithSwiperSubject
            .asObservable()
            .pipe(distinctUntilChanged());

    constructor(private readonly breakpointObserver: BreakpointObserver) {
        super();
    }

    trackBy(index) {
        return index;
    }

    onSwiperInit(swiperInstance: Swiper) {
        this.swiperInstance = swiperInstance;
        this.initAutoplayDisabledHandling();
    }

    /** Flag the first manual swiper interaction in order to stop autoplay from this time on */
    handleSwiperUserInteraction() {
        this.userInteractedWithSwiperSubject.next(true);
    }

    /** Stop autoplay while hovering over the swiper container */
    handleSwiperHover(hover: boolean) {
        const userInteractedWithSwiper =
            this.userInteractedWithSwiperSubject.getValue();
        if (!userInteractedWithSwiper) {
            this.toggleSwiperAutoplay(hover ? 'stop' : 'start');
        }
    }

    intentPrevSlide() {
        this.swiperInstance?.slidePrev();
        this.handleSwiperUserInteraction();
    }

    intentNextSlide() {
        this.swiperInstance?.slideNext();
        this.handleSwiperUserInteraction();
    }

    @MemoizeObservable()
    useComparisonImageFixedRatio$(): Observable<boolean> {
        return this.breakpointObserver
            .observe([AmlBreakpoints.Portrait])
            .pipe(map((state) => state.matches));
    }

    private initAutoplayDisabledHandling() {
        combineLatest([
            this.observeProperty<SummarySliderComponent, boolean>(
                'autoplayDisabled'
            ),
            this.userInteractedWithSwiper$
        ]).subscribe(([autoplayDisabled, userInteractedWithSwiper]) => {
            if (!autoplayDisabled && !userInteractedWithSwiper) {
                /** If autoplay is enabled AND the user did not interact with the slider yet, start autoplay */
                this.toggleSwiperAutoplay('start');
            } else if (autoplayDisabled || userInteractedWithSwiper) {
                /** If autoplay is disabled or the user did already interact with the slider, stop autoplay. */
                this.toggleSwiperAutoplay('stop');
            }
        });
    }

    private toggleSwiperAutoplay(action: 'start' | 'stop') {
        if (action === 'start') {
            this.swiperInstance?.autoplay.start();
        }
        if (action === 'stop') {
            this.swiperInstance?.autoplay.stop();
        }
    }
}
