<mhp-dialog-header
    [hideTopGradient]="true"
    [closeImplicit]="false"
    (dialogClose)="intentCloseDialog()"
></mhp-dialog-header>
<mhp-ui-mat-dialog
    [title]="t('REQUEST_PDF_DOWNLOAD.HEADLINE')"
    [showClose]="false"
    *transloco="let t"
>
    <mhp-ui-mat-dialog-content>
        <form
            class="request-pdf__form aml-form__form"
            [formGroup]="requestPdfFormGroup"
            *transloco="let tForm; read: 'FORM'"
        >
            <p [innerHTML]="t('REQUEST_PDF_DOWNLOAD.DESCRIPTION')"></p>
            <mat-form-field class="mhp-ui-form-field--full-width">
                <mat-label>{{ tForm('TITLE_LABEL') }}</mat-label>
                <mat-select
                    formControlName="title"
                    [trackGA4Event]="{
                        event: 'form_values_enter',
                        title: '1'
                    }"
                    [trackBlur]="true"
                >
                    <mat-option
                        *ngFor="let title of availableTitles"
                        [value]="title.key"
                        >{{ title.value }}</mat-option
                    >
                </mat-select>
            </mat-form-field>

            <mat-form-field class="mhp-ui-form-field--full-width">
                <mat-label>{{ tForm('FIRSTNAME_LABEL') }}</mat-label>
                <input
                    matInput
                    formControlName="firstName"
                    type="text"
                    [trackGA4Event]="{
                        event: 'form_values_enter',
                        first_name: '1'
                    }"
                    [trackBlur]="true"
                />
            </mat-form-field>

            <mat-form-field class="mhp-ui-form-field--full-width">
                <mat-label>{{ tForm('LASTNAME_LABEL') }}</mat-label>
                <input
                    matInput
                    formControlName="lastName"
                    type="text"
                    [trackGA4Event]="{
                        event: 'form_values_enter',
                        last_name: '1'
                    }"
                    [trackBlur]="true"
                />
            </mat-form-field>

            <!-- Render dealer-related fields only -->
            <ng-container *ngIf="dealerMode">
                <section class="aml-form__form-block">
                    <mat-form-field class="mhp-ui-form-field--full-width">
                        <mat-label>{{
                            t('REQUEST_PDF_DOWNLOAD.DESCRIPTION_LABEL')
                        }}</mat-label>
                        <input
                            matInput
                            formControlName="description"
                            type="text"
                            [trackGA4Event]="{
                                event: 'form_values_enter',
                                description: '1'
                            }"
                            [trackBlur]="true"
                        />
                    </mat-form-field>
                    <div
                        *ngIf="(isIncludePricingAvailable$() | async) === true"
                        class="aml-form__field-group mhp-ui-form-field--full-width"
                    >
                        <mat-checkbox
                            color="primary"
                            formControlName="includePdfPricing"
                            [trackGA4Event]="{
                                event: 'form_values_enter',
                                include_pricing: '1'
                            }"
                            [trackBlur]="true"
                            >{{ tForm('INCLUDE_PRICING_IN_PDF') }}</mat-checkbox
                        >
                    </div>
                </section>

                <mat-form-field class="mhp-ui-form-field--full-width">
                    <mat-label>{{
                        t('REQUEST_PDF_DOWNLOAD.COMMENT_LABEL')
                    }}</mat-label>
                    <textarea
                        matInput
                        formControlName="comment"
                        type="text"
                        [trackGA4Event]="{
                            event: 'form_values_enter',
                            comment: '1'
                        }"
                        [trackBlur]="true"
                    ></textarea>
                </mat-form-field>
            </ng-container>
        </form>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-left>
        <button
            mat-button
            (click)="intentCloseDialog()"
        >
            {{ t('COMMON.CANCEL') }}
        </button>
    </mhp-ui-mat-dialog-actions-left>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            [disabled]="
                (isDownloadInProgress$() | async) === true ||
                !requestPdfFormGroup.valid
            "
            (click)="intentDownload()"
            [trackGA4Event]="{
                event: 'download_personalised_brochure_submit'
            }"
        >
            <span>{{ t('SUMMARY.CONFIGURATION_SUMMARY.DOWNLOAD') }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
