<div class="configuration-options">
    <mhp-configuration-options-list
        [options]="options"
        [optionSelectionDisabled]="optionSelectionDisabled()"
        (optionSelected)="onOptionCodeSelected($event)"
        (optionHighlighted)="onOptionCodeHighlighted($event?.id)"
        (infoRequested)="intentShowInfo($event)"
    ></mhp-configuration-options-list>
    <ng-container
        *ngVariable="
            (getSelectedOrHighlightedOption$() | async)
                ?.subType as optionSubtype
        "
    >
        <div
            class="configuration-options__active-option-info"
            [ngClass]="{
                'configuration-options__active-option-info--wide':
                    !optionSubtype || optionSubtype === 'image'
            }"
        >
            <ng-container
                *ngIf="
                    getSelectedOrHighlightedOption$() | async as selectedOption
                "
            >
                <div
                    class="active-option-thumbnail"
                    *ngIf="
                        getSelectedOrHighlightedOptionForDisplay$()
                            | async as selectedOptionForDisplay
                    "
                >
                    <mhp-configuration-code
                        [data]="selectedOptionForDisplay"
                        [forceInfoAlwaysVisible]="true"
                        [readonly]="true"
                        (infoRequested)="
                            intentShowInfo(selectedOptionForDisplay)
                        "
                    ></mhp-configuration-code>
                </div>
                <div class="active-option-info">
                    <div
                        *ngIf="selectedOption.categoryTranslated"
                        class="active-option-info__option-category"
                    >
                        {{ selectedOption.categoryTranslated }}
                    </div>
                    <div
                        class="active-option-info__option-label"
                        *transloco="let t"
                    >
                        <mhp-ui-icon
                            *ngIf="selectedOption.meta?.qOption"
                            class="active-option-info__option-label-q-option"
                            svgIcon="mhp-ui:q-option"
                            size="medium"
                            [matTooltip]="t('ICONS.Q_OPTION_ALT')"
                        ></mhp-ui-icon>
                        <span
                            [innerHTML]="getSelectedOptionLabel$() | async"
                        ></span>
                    </div>
                    <div
                        *mhpIfShowConfigurationPricing
                        class="active-option-info__price"
                    >
                        {{ selectedOption | effectivePricingAwareFormat }}
                        <!-- TODO: Add translation for monthly rate -->
                        <span
                            *ngIf="
                                getFinancialServiceMonthlyRate$()
                                    | async as monthlyRate
                            "
                            class="active-option-info__price-rate"
                            >({{ monthlyRate | translocoCurrency }} /
                            month)</span
                        >
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
