import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit
} from '@angular/core';
import { ReservationData } from '@mhp-immersive-exp/sdk/streaming/monkeyway/internal/types/reservation-data.js';
import { UiBaseComponent, UiNotificationService } from '@mhp/ui-components';

import { One2oneSessionInfoService } from '../../../../one2one/session-info/one2one-session-info.service';
import { DealerCreateOne2OneSessionData } from '../create-one2one-session/create-one2one-session.component';

@Component({
    selector: 'mhp-one2one-confirm',
    templateUrl: './one2one-confirm.component.html',
    styleUrls: ['./one2one-confirm.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class One2oneConfirmComponent extends UiBaseComponent implements OnInit {
    @Input()
    sessionData: DealerCreateOne2OneSessionData;

    @Input()
    reservationData: ReservationData;

    dealerLink: string;

    customerLink: string;

    constructor(
        private readonly sessionInfoService: One2oneSessionInfoService,
        private readonly notificationService: UiNotificationService
    ) {
        super();
    }

    ngOnInit() {
        this.dealerLink = this.sessionInfoService.getDealerUrlToSession(
            this.reservationData,
            this.sessionData.startConfiguration
        );
        this.customerLink = this.sessionInfoService.getClientUrlToSession(
            this.reservationData
        );

        this.dealerLink = `${this.dealerLink}&transactionKey=15050a05-d00c-474e-b656-d198b13956ec`;
        this.customerLink = `${this.customerLink}?transactionKey=15050a05-d00c-474e-b656-d198b13956ec`;
    }

    onCopyDealerLinkSuccess() {
        this.notificationService.showAutohideNotification(
            'Link to Dealer-Session successfully copied to clipboard'
        );
    }

    onCopyCustomerLinkSuccess() {
        this.notificationService.showAutohideNotification(
            'Link to Customer-Session successfully copied to clipboard'
        );
    }
}
