<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    [title]="t('ONE_2_ONE.START_SESSION_DIALOG.HEADLINE')"
    [showClose]="false"
    *transloco="let t"
>
    <mhp-ui-mat-dialog-content>
        <p
            *ngIf="(canStart$() | async) === true; else waitingForSessionStart"
            [innerHTML]="
                t('ONE_2_ONE.START_SESSION_DIALOG.SESSION_READY', {
                    sessionName: (reservationData$ | async)?.description
                })
            "
        ></p>
        <ng-template #waitingForSessionStart>
            <p
                *ngIf="
                    reservationData$ | async as reservationData;
                    else fetchingReservation
                "
                [innerHTML]="
                    (canStart$() | async) === false
                        ? t('ONE_2_ONE.START_SESSION_DIALOG.SESSION_WAITING', {
                              sessionName: (reservationData$ | async)
                                  ?.description,
                              date:
                                  startDate$
                                  | async
                                  | translocoDate
                                      : {
                                            dateStyle: 'medium',
                                            timeStyle: 'medium'
                                        },
                              minutesUntilStart: getMinutesUntilStart$() | async
                          })
                        : t('ONE_2_ONE.START_SESSION_DIALOG.SESSION_READY', {
                              sessionName: (reservationData$ | async)
                                  ?.description
                          })
                "
            ></p>
        </ng-template>
        <ng-template #fetchingReservation>
            <p>{{ t('ONE_2_ONE.START_SESSION_DIALOG.SESSION_FETCH') }}</p>
        </ng-template>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-left>
        <button mat-button matDialogClose>
            {{ t('COMMON.CANCEL') }}
        </button>
    </mhp-ui-mat-dialog-actions-left>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            [disabled]="(canStart$() | async) === false"
            (click)="intentStartSession()"
        >
            <span>{{ t('ONE_2_ONE.START_SESSION.CTA_START_SESSION') }}</span>
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
