<ng-container *transloco="let t; read: 'CONFIGURATION.METADATA.GROUP'">
    <div class="configuration-group-sublevel__title">
        {{ group?.name | translateFallback: t }}
    </div>
    <div class="configuration-group-sublevel__content">
        <ng-container
            *ngFor="let contentItem of group.content; trackBy: trackById"
        >
            <ng-container *ngIf="contentItem.type === 'Group'">
                <mhp-configuration-group-sublevel
                    [group]="contentItem"
                    [optionSelectionDisabled]="optionSelectionDisabled()"
                    (intentSelect)="intentSelectItem($event)"
                    (showInfo)="intentShowInfo($event)"
                ></mhp-configuration-group-sublevel>
            </ng-container>

            <ng-container *ngIf="contentItem.type === 'Collection'">
                <mhp-configuration-collection
                    [collection]="contentItem"
                    [optionSelectionDisabled]="optionSelectionDisabled()"
                    (intentSelect)="intentSelectItem($event)"
                    (showInfo)="intentShowInfo($event)"
                ></mhp-configuration-collection>
            </ng-container>

            <ng-container *ngIf="contentItem.type === 'List'">
                <mhp-configuration-list
                    [list]="contentItem"
                    [optionSelectionDisabled]="optionSelectionDisabled()"
                    (intentSelect)="intentSelectItem($event)"
                ></mhp-configuration-list>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="getOptions$() | async as options">
            <mhp-configuration-options-list
                [options]="options"
                [useOverflowIndicator]="false"
                [optionSelectionDisabled]="optionSelectionDisabled()"
                (optionSelected)="intentSelectItem($event)"
                (infoRequested)="intentShowInfo($event)"
            ></mhp-configuration-options-list>
        </ng-container>
    </div>
</ng-container>
