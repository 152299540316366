import { last } from 'lodash-es';

import { Injectable } from '@angular/core';
import { ScreenshotSource } from '@mhp/ui-shared-services';

export interface ScreenshotProvider {
    getScreenshotSource(): Promise<ScreenshotSource | undefined>;
}

@Injectable({
    providedIn: 'root'
})
export class ScreenshotRegistryService {
    private providerStack: ScreenshotProvider[] = [];

    private provider?: ScreenshotProvider;

    addScreenshotProvider(provider: ScreenshotProvider): () => void {
        this.providerStack.push(provider);
        this.assignLatestProvider();

        return () => {
            this.providerStack = this.providerStack.filter(
                (providerFromStack) => providerFromStack !== provider
            );
            this.assignLatestProvider();
        };
    }

    async getScreenshotSource(): Promise<ScreenshotSource | undefined> {
        if (!this.provider) {
            return undefined;
        }
        const screenshotSource = await this.provider.getScreenshotSource();

        return screenshotSource;
    }

    private assignLatestProvider() {
        this.provider = last(this.providerStack);
    }
}
