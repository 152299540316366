<div class="stream-stage">
    <div class="stream-stage__stream-layer">
        <video
            class="stream-stage__stream-layer-video"
            #streamVideo
            muted
            playsinline
        ></video>
        <mhp-configuration-trackpad
            class="stream-stage__stream-trackpad"
            [ngClass]="{
                'stream-stage__stream-trackpad--disabled':
                    (isTouchPanelEnabled$() | async) === false
            }"
            #configurationTrackpad
            [disabled]="(isTouchPanelEnabled$() | async) === false"
        ></mhp-configuration-trackpad>
    </div>
</div>
