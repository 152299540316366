import { Injectable, OnDestroy } from '@angular/core';
import { IllegalStateError } from '@mhp/common';

import {
    ScreenshotProvider,
    ScreenshotRegistryService
} from './screenshot-registry.service';

/**
 * Service meant to be used in the context/lifecycle of a component, so provide it in the providers-array of the related component that implements ScreenshotProvider
 * and call #register() to register it.
 */
@Injectable()
export class ScreenshotProviderRegistrarService implements OnDestroy {
    private onDestroyCallback?: () => void;

    constructor(
        private readonly screenshotRegistryService: ScreenshotRegistryService
    ) {}

    ngOnDestroy() {
        if (this.onDestroyCallback) {
            this.onDestroyCallback();
        }
    }

    register(screenshotProvider: ScreenshotProvider) {
        if (this.onDestroyCallback) {
            throw new IllegalStateError(
                'ScreenshotProvider already registered'
            );
        }
        this.onDestroyCallback =
            this.screenshotRegistryService.addScreenshotProvider(
                screenshotProvider
            );
    }
}
