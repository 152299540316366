import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { PostFinancialOptionsBodyDTO } from '@mhp/aml-shared/data-proxy/financial-services/post-financial-options-body-dto.interface';
import { PostFinancialOptionsDTO } from '@mhp/aml-shared/data-proxy/financial-services/post-financial-options-dto.interface';
import { L10nService } from '@mhp/ui-shared-services';

import { environment } from '../../../environments/environment';
import { toDotNotation } from '../configuration-model/configuration-model.helper';
import { ConfigurationNodeLookupService } from '../services/configuration-node-lookup.service';
import {
    FinancialServiceOfferBase,
    FinancialServiceOfferInputBase,
    FinancialServiceProvider,
    OfferRow
} from './financial-services.interfaces';

@Injectable()
export class FinancialServicesService<
    FSO extends FinancialServiceOfferBase = FinancialServiceOfferBase,
    FSOI extends FinancialServiceOfferInputBase = FinancialServiceOfferInputBase
> implements FinancialServiceProvider<FSO, FSOI>
{
    constructor(
        private readonly configurationNodeLookupService: ConfigurationNodeLookupService,
        private readonly l10nService: L10nService,
        private readonly httpClient: HttpClient
    ) {}

    /**
     * Get the currently valid financial service offer for the currently valid configuration.
     * Might be undefined in case no financial service offer is available or if there is
     * no financial service provider registered for the active region.
     * @param productId The productId to fetch the service offer for.
     * @param countryIso The countryIso to fetch the offer for.
     * @param financialServicesOfferInput The parameters to be used for the service offer.
     * @param options The options that are relevant for the service offer.
     */
    getFinancialServiceOffer$(
        productId: string,
        countryIso: string,
        financialServicesOfferInput: FSOI,
        options: ConfigModel[]
    ): Observable<FSO | undefined> {
        return this.httpClient
            .post<PostFinancialOptionsDTO>(
                `${environment.appConfig.dataProxy.url}/api/financial-services/financial-options`,
                <PostFinancialOptionsBodyDTO>{
                    productId,
                    countryISOCode: countryIso,
                    financingType: financialServicesOfferInput.typeOfFinance,
                    term: financialServicesOfferInput.termInMonths,
                    mileage: financialServicesOfferInput.mileage,
                    deposit: financialServicesOfferInput.cashDeposit,
                    monthlyPayment: financialServicesOfferInput.monthlyRate,
                    options
                }
            )
            .pipe(
                map(
                    (response) =>
                        <FSO>{
                            term: response.term,
                            monthlyRate: response.monthlyPayment,
                            totalDeposit: response.deposit,
                            totalAmountOfCredit: response.amountOfCredit,
                            totalChargeForCredit: response.totalChargeForCredit,
                            totalAmountPayable: response.totalAmountPayable,
                            interestRate: response.rateOfInterest / 100,
                            interestRateAPR: response.apr / 100,
                            mileage: response.mileage,
                            options: response.options.reduce(
                                (codeToRateMap, option) =>
                                    codeToRateMap.set(
                                        toDotNotation(option.code),
                                        option.monthlyRate
                                    ),
                                new Map<string, number>()
                            )
                        }
                )
            );
    }

    getFinancialServiceOfferRowRepresentation(
        financialServiceOffer: FSO
    ): OfferRow[] {
        const translateOfferSummary = (
            key: string,
            parameters?: Record<string, unknown>
        ) => translate(`FIN_SERVICES.OFFER_SUMMARY.${key}`, parameters);
        return [
            {
                label: translateOfferSummary('TOTAL_DEPOSIT'),
                value: this.formatCurrencyWithFallback(
                    financialServiceOffer.totalDeposit
                )
            },
            {
                label: translateOfferSummary('MONTHLY_PAYMENTS', {
                    monthCount: financialServiceOffer.term - 1
                }),
                value: this.formatCurrencyWithFallback(
                    financialServiceOffer.monthlyRate
                )
            },
            {
                label: translateOfferSummary('DURATION'),
                value: translateOfferSummary('DURATION_VALUE', {
                    monthCount: financialServiceOffer.term
                }),
                endOfSection: true
            },
            {
                label: translateOfferSummary('AMOUNT_OF_CREDIT'),
                value: this.formatCurrencyWithFallback(
                    financialServiceOffer.totalAmountOfCredit
                )
            },
            {
                label: translateOfferSummary('TOTAL_CHARGE'),
                value: this.formatCurrencyWithFallback(
                    financialServiceOffer.totalChargeForCredit
                )
            },
            {
                label: translateOfferSummary('TOTAL_AMOUNT_PAYABLE'),
                value: this.formatCurrencyWithFallback(
                    financialServiceOffer.totalAmountPayable
                ),
                endOfSection: true
            },
            {
                label: translateOfferSummary('APR'),
                value: this.formatPercentWithFallback(
                    financialServiceOffer.interestRateAPR
                )
            },
            {
                label: translateOfferSummary('RATE_OF_INTEREST'),
                value: this.formatPercentWithFallback(
                    financialServiceOffer.interestRate
                )
            },
            ...(financialServiceOffer.mileage
                ? [
                      {
                          label: translateOfferSummary('ANNUAL_MILEAGE'),
                          value: this.formatNumberWithFallback(
                              financialServiceOffer.mileage
                          ),
                          endOfSection: true
                      }
                  ]
                : [])
        ];
    }

    private formatCurrencyWithFallback(value: number | undefined): string {
        return value ? this.l10nService.formatCurrency(value) : 'N/A';
    }

    private formatNumberWithFallback(value: number | undefined): string {
        return value ? this.l10nService.formatNumber(value) : 'N/A';
    }

    private formatPercentWithFallback(value: number | undefined): string {
        return value ? this.l10nService.formatPercentage(value) : 'N/A';
    }
}
