import { Observable, combineLatest, of } from 'rxjs';
import {
    finalize,
    last,
    map,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AmlUiSharedState, InfoLayerItem } from '@mhp/aml-ui-shared-services';
import { IllegalStateError, lazyShareReplay } from '@mhp/common';
import { ImageSrcset, UiMatDialogService } from '@mhp/ui-components';
import {
    ProductConfigurationService,
    UiSharedStateService
} from '@mhp/ui-shared-services';

import { BACKDROP_CLASS_BLURRY } from '../../common/dialog/dialog.constants';
import { ConfigurationSessionImageAssetService } from '../assets/configuration-session-image-asset.service';
import { ExtendedUiOptionCode } from '../configuration-model/configuration-interfaces';
import { InfoLayerComponent } from './info-layer.component';

export interface InfoLayerServiceConfig {
    // should active items be pushed to the ui-state?
    pushActiveItemToUiSharedState?: boolean;
}

export const INFO_LAYER_SERVICE_CONFIG_TOKEN =
    new InjectionToken<InfoLayerServiceConfig>('InfoLayerServiceConfig');

@Injectable()
export class InfoLayerService {
    constructor(
        private readonly dialogService: UiMatDialogService,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly productConfigurationService: ProductConfigurationService,
        private readonly amlImageAssetService: ConfigurationSessionImageAssetService,
        @Optional()
        private readonly uiSharedStateService?: UiSharedStateService<AmlUiSharedState>,
        @Inject(INFO_LAYER_SERVICE_CONFIG_TOKEN)
        @Optional()
        private readonly config?: InfoLayerServiceConfig
    ) {
        if (config?.pushActiveItemToUiSharedState && !uiSharedStateService) {
            throw new IllegalStateError(
                'When InfoLayerService is configured to push active info-layer elements to shared ui state, UiSharedStateService must be provided, too.'
            );
        }
    }

    /**
     * Show an info-layer for the given option code.
     * @param optionCode
     */
    showInfoLayerForOptionCode$(
        optionCode: ExtendedUiOptionCode
    ): Observable<MatDialogRef<InfoLayerComponent>> {
        return this.mapToInfoLayerItem(optionCode).pipe(
            switchMap((infoLayerItem) => this.showInfoLayer$(infoLayerItem))
        );
    }

    /**
     * Show an info-layer for the given InfoLayerItem.
     * @param infoLayerItem The item to be visualized.
     */
    showInfoLayer$(
        infoLayerItem: InfoLayerItem
    ): Observable<MatDialogRef<InfoLayerComponent>> {
        const dialogOpen$ = this.dialogService
            .open$(InfoLayerComponent, {
                minWidth: '0',
                maxWidth: 'calc(100% - 40px)',
                minHeight: '0',
                maxHeight: 'calc(100% - 160px)',
                backdropClass: BACKDROP_CLASS_BLURRY,
                panelClass: [
                    'mhp-ui-modal-panel--no-padding',
                    'mhp-ui-modal-panel--no-overflow'
                ]
            })
            .pipe(
                tap((dialogRef) => {
                    dialogRef.componentInstance.item = infoLayerItem;
                }),
                lazyShareReplay()
            );

        return combineLatest([
            dialogOpen$,
            this.breakpointObserver.observe([Breakpoints.HandsetPortrait]),
            this.breakpointObserver.observe([
                Breakpoints.HandsetLandscape,
                Breakpoints.TabletPortrait
            ]),
            this.breakpointObserver.observe([
                Breakpoints.TabletLandscape,
                Breakpoints.Web
            ])
        ]).pipe(
            takeUntil(dialogOpen$.pipe(last())),
            map(([dialogRef, smallSize, mediumSize, largeSize]) => {
                if (smallSize.matches) {
                    dialogRef.updateSize('calc(100% - 40px)', 'auto');
                } else if (mediumSize.matches) {
                    dialogRef.updateSize(
                        infoLayerItem.itemType === 'image'
                            ? '100%'
                            : 'calc(70vw)',
                        'auto'
                    );
                } else {
                    dialogRef.updateSize(
                        infoLayerItem.itemType === 'image' ? '1300px' : '700px',
                        'auto'
                    );
                }

                return dialogRef;
            }),
            tap(() => {
                this.handleUpdateItemInUiState(infoLayerItem);
            }),
            finalize(() => {
                this.handleUpdateItemInUiState(undefined);
            })
        );
    }

    private mapToInfoLayerItem(
        optionCode: ExtendedUiOptionCode
    ): Observable<InfoLayerItem> {
        let thumbnailUrl$: Observable<string | ImageSrcset | undefined> =
            of(undefined);

        if (optionCode.subType !== 'text') {
            thumbnailUrl$ =
                this.amlImageAssetService.getOptionThumbnailImageSrc$(
                    optionCode
                );
        }

        return thumbnailUrl$.pipe(
            take(1),
            map((thumbnailUrl) => ({
                label: optionCode.nameTranslated,
                description: optionCode.descriptionTranslated || '',
                itemType: optionCode.subType,
                thumbnailUrls: [thumbnailUrl || ''],
                isQOption: !!optionCode.meta?.qOption,
                hasAdditionalFittingCost:
                    !!optionCode.meta?.hasAdditionalFittingCost
            }))
        );
    }

    private handleUpdateItemInUiState(
        infoLayerItem: InfoLayerItem | undefined
    ) {
        if (!this.config?.pushActiveItemToUiSharedState) {
            return;
        }

        if (!this.uiSharedStateService) {
            throw new IllegalStateError('missing uiSharedStateService');
        }
        this.uiSharedStateService
            .updateUiState((uiState) => {
                uiState.activeInfoLayerItem = infoLayerItem;
                return uiState;
            })
            .subscribe();
    }
}
