import { last } from 'lodash-es';
import { Observable, ReplaySubject, switchMap, throttleTime } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    input
} from '@angular/core';
import { Identifiable } from '@mhp-immersive-exp/contracts/src/generic/identifiable.interface';
import { AmlOptionMetadata } from '@mhp/aml-shared/configuration/aml-option-metadata';
import { EffectivePricingAware } from '@mhp/aml-ui-shared-services';
import { MemoizeObservable } from '@mhp/common';
import { OptionalObservable, toObservable } from '@mhp/common/rxjs/rxjs-types';
import {
    ImageSrcset,
    TRACK_BY_ID,
    UiBaseComponent,
    UiLazyLoadImageLoadingState
} from '@mhp/ui-components';

export interface ConfigurationCompositeItemModel
    extends Identifiable,
        EffectivePricingAware {
    thumbnailUrls: OptionalObservable<(string | ImageSrcset)[]>;
    label: string;
    labelHtml?: string;
    description?: string;
    selected?: boolean;
    disabled?: boolean;
    options?: ConfigurationCompositeItemOptionModel[];
    meta?: AmlOptionMetadata;
}

export interface ConfigurationCompositeItemOptionModel
    extends Omit<ConfigurationCompositeItemModel, 'options'> {
    selected: boolean;
    meta?: AmlOptionMetadata;
}

@Component({
    selector: 'mhp-configuration-composite-item',
    templateUrl: './configuration-composite-item.component.html',
    styleUrls: ['./configuration-composite-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationCompositeItemComponent extends UiBaseComponent {
    readonly trackById = TRACK_BY_ID;
    readonly imageLoadingState$: Observable<UiLazyLoadImageLoadingState>;
    readonly selectedSubject = new EventEmitter<string>();
    protected readonly last = last;

    @Input()
    item: ConfigurationCompositeItemModel;

    @Input()
    imageFallbackUrl?: string;

    @Input()
    debug?: boolean;

    disabled = input(false);

    @Output()
    readonly selected = this.selectedSubject
        .asObservable()
        // throttle to prevent multiple select-events from being emitted in quick succession.
        .pipe(throttleTime(0));

    @Output()
    readonly showInfo =
        new EventEmitter<ConfigurationCompositeItemOptionModel>();

    @Output()
    readonly enlargeThumbnail =
        new EventEmitter<ConfigurationCompositeItemModel>();

    private readonly imageLoadingStateSubject =
        new ReplaySubject<UiLazyLoadImageLoadingState>(1);

    constructor() {
        super();

        this.imageLoadingState$ = this.imageLoadingStateSubject.asObservable();

        this.completeOnDestroy(
            this.selectedSubject,
            this.showInfo,
            this.enlargeThumbnail,
            this.imageLoadingStateSubject
        );
    }

    intentSelectOption(option: ConfigurationCompositeItemOptionModel) {
        this.selectedSubject.next(option.id);
    }

    intentShowInfo(option: ConfigurationCompositeItemOptionModel) {
        this.showInfo.emit(option);
    }

    intentEnlargeThumbnail() {
        this.enlargeThumbnail.emit(this.item);
    }

    @MemoizeObservable()
    getItemOptions$(): Observable<ConfigurationCompositeItemOptionModel[]> {
        return this.observeProperty<
            ConfigurationCompositeItemComponent,
            ConfigurationCompositeItemModel
        >('item', true).pipe(
            map((item) => {
                if (item.options) {
                    return item.options;
                }
                // return self as single option
                return [
                    {
                        ...item,
                        selected: !!item.selected
                    }
                ];
            })
        );
    }

    @MemoizeObservable()
    getItemThumbnails$(): Observable<(string | ImageSrcset)[]> {
        return this.observeProperty<
            ConfigurationCompositeItemComponent,
            ConfigurationCompositeItemModel
        >('item', true).pipe(
            switchMap((item) => {
                return toObservable(item.thumbnailUrls);
            })
        );
    }

    onImageLoadingStateChange(loadingState: UiLazyLoadImageLoadingState) {
        this.imageLoadingStateSubject.next(loadingState);
    }
}
