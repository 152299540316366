import { Injectable } from '@angular/core';
import {
    EngineSettingsService,
    ErrorHandlerService,
    FeatureAvailabilityService
} from '@mhp/ui-shared-services';
import { EngineStateService } from '@mhp/ui-shared-services/engine/engine-state.service';

import { CameraControlService } from '../../../camera-control/camera-control.service';
import {
    BeautyshotAwareComponent,
    CameraStateHandler
} from './camera-state-handler';

/**
 * Factory service that makes creating CameraStateHandler instances that are bound to a given component easier.
 */
@Injectable()
export class CameraStateHandlerFactoryService {
    constructor(
        private featureAvailabilityService: FeatureAvailabilityService,
        private engineStateService: EngineStateService,
        private engineSettingsService: EngineSettingsService,
        private readonly cameraControlService: CameraControlService,
        private errorHandlerService: ErrorHandlerService
    ) {}

    /**
     * Constructs a new CameraStateHandler that is bound to the given component.
     * @param component The component to bind to.
     */
    newCameraStateHandler(component: BeautyshotAwareComponent) {
        return new CameraStateHandler(
            component,
            this.featureAvailabilityService,
            this.engineStateService,
            this.engineSettingsService,
            this.cameraControlService,
            this.errorHandlerService
        );
    }
}
