<ng-container *transloco="let t; read: 'SUMMARY'">
    <mhp-ui-icon-button
        *ngFor="let socialService of getSocialServices$() | async"
        [svgIcon]="'mhp-ui:' + socialService.iconId"
        [disabled]="disabled"
        [matTooltip]="
            t('ALT_SHARE_USING_SERVICE', {
                socialService: socialService.name
            })
        "
        [label]="
            t('ALT_SHARE_USING_SERVICE', {
                socialService: socialService.name
            })
        "
        (click)="intentShare(socialService)"
    ></mhp-ui-icon-button>
</ng-container>
