import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { BeautyshotDefinition } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { lazyShareReplay } from '@mhp/common';
import {
    EngineSettingsService,
    ErrorHandlerService,
    FeatureAvailabilityService,
    RequestReceiverType
} from '@mhp/ui-shared-services';
import { EngineStateService } from '@mhp/ui-shared-services/engine/engine-state.service';

import { CameraControlService } from '../../../camera-control/camera-control.service';

export interface BeautyshotAwareComponent {
    beautyshot?: BeautyshotDefinition;
    observeProperty: <K, T>(
        propertyName: Extract<keyof K, string>,
        startWithCurrentValue
    ) => Observable<T>;
}

/**
 * Helper class to handle camera-active states bound to a component that handles rendering of beautyshot aware config-meta.
 */
export class CameraStateHandler {
    /**
     * Emits the camera that is currently valid based on whether VR is active or not
     */
    readonly contextuallyValidCamera$: Observable<string | undefined>;

    /**
     * Emits the current active state of the camera linked to the data bound to the bound component.
     */
    readonly cameraActive$: Observable<boolean>;

    /**
     * Emits if the camera currently can be toggled or not.
     */
    readonly canToggleCamera$: Observable<boolean>;

    constructor(
        private beautyshotAwareComponent: BeautyshotAwareComponent,
        private featureAvailabilityService: FeatureAvailabilityService,
        private engineStateService: EngineStateService,
        private engineSettingsService: EngineSettingsService,
        private readonly cameraControlService: CameraControlService,
        private errorHandlerService: ErrorHandlerService
    ) {
        this.contextuallyValidCamera$ = combineLatest([
            this.beautyshotAwareComponent.observeProperty<
                BeautyshotAwareComponent,
                BeautyshotDefinition | undefined
            >('beautyshot', true),
            this.engineSettingsService.getVrModeActiveState$()
        ]).pipe(
            map(([beautyshotDefinition, vrModeActive]) => {
                if (!beautyshotDefinition) {
                    return undefined;
                }
                return vrModeActive
                    ? beautyshotDefinition.cameraIdVr
                    : beautyshotDefinition.cameraId;
            }),
            lazyShareReplay()
        );

        this.cameraActive$ = combineLatest([
            this.contextuallyValidCamera$,
            this.engineStateService.getActiveCamera$()
        ]).pipe(
            map(
                ([contextuallyActiveCamera, activeCamera]) =>
                    contextuallyActiveCamera === activeCamera
            ),
            lazyShareReplay()
        );

        this.canToggleCamera$ =
            this.featureAvailabilityService.canAlterCamera$();
    }

    /**
     * Set the active camera to the given id.
     */
    intentSetCamera() {
        this.contextuallyValidCamera$
            .pipe(
                take(1),
                switchMap((cameraId) => {
                    if (!cameraId) {
                        return of(undefined);
                    }
                    return this.cameraControlService
                        .setActiveCamera$(cameraId)
                        .pipe(
                            this.errorHandlerService.applyRetryWithHintsOnError(
                                (error) =>
                                    `Failed setting camera [${cameraId}] as active: ${
                                        (error as Error).message
                                    }`,
                                {
                                    endpointType: RequestReceiverType.ENGINE
                                }
                            )
                        );
                })
            )
            .subscribe();
    }
}
