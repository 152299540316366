import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiLazyloadImageModule } from '@mhp/ui-components';

import { DialogModule } from '../../../dialog/dialog.module';
import { FridgeLabelDialogComponent } from './fridge-label-dialog/fridge-label-dialog.component';

@NgModule({
    declarations: [FridgeLabelDialogComponent],
    imports: [CommonModule, DialogModule, UiLazyloadImageModule],
    exports: [FridgeLabelDialogComponent]
})
export class FridgeLabelModule {}
