import { pick } from 'lodash-es';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    ConfigurationResponsePayload,
    OptionCode
} from '@mhp-immersive-exp/contracts/src';
import { ConfigurationAreaActionsModule } from '@mhp/aml-ui-shared-components/configuration/configuration-area-actions';
import { UiButtonModule, UiTooltipModule } from '@mhp/ui-components';
import {
    ContentAwareConfigurationMetaItem,
    I18nModule,
    PRODUCT_CONFIGURATION_HUB_PROXY_CONFIGURATION,
    ProductConfigurationHubProxyConfiguration,
    ProductConfigurationHubProxyEndpointRegistrar,
    isContentAware,
    isOptionCode
} from '@mhp/ui-shared-services';

import { VideoHeaderContainerModule } from '../../display-video-tracks/video-header-container/video-header-container.module';
import { SessionControlComponent } from './configuration/session-control/session-control.component';
import { SessionManagementModule } from './session-management/session-management.module';
import { StartSessionModule } from './start-session/start-session.module';

const OPTION_CODE_PROPERTIES_WHITELIST: Array<keyof OptionCode> = [
    'type',
    'name',
    'image',
    'code',
    'subType',
    'beautyshot',
    'active',
    'conflicts',
    'sortOrder',
    'selected',
    'description',
    'meta'
];

const configurationResponsePricingFilter = (() => {
    const handleNonContentAware = (item) => {
        if (!isOptionCode(item)) {
            return item;
        }
        return pick(item, OPTION_CODE_PROPERTIES_WHITELIST);
    };

    const handleContentAware = (item: ContentAwareConfigurationMetaItem) => ({
        ...item,
        content: (<any[]>item.content).map((innerItem) => {
            if (!isContentAware(innerItem)) {
                return handleNonContentAware(innerItem);
            }
            return handleContentAware(innerItem);
        })
    });

    return (response: ConfigurationResponsePayload) =>
        // remove pricing-information
        ({
            ...response,
            config: response.config.map(handleContentAware)
        });
})();

@NgModule({
    declarations: [SessionControlComponent],
    imports: [
        CommonModule,

        ConfigurationAreaActionsModule,
        SessionManagementModule,
        StartSessionModule,

        UiTooltipModule,
        UiButtonModule,

        I18nModule,
        VideoHeaderContainerModule
    ],
    providers: [
        {
            provide: PRODUCT_CONFIGURATION_HUB_PROXY_CONFIGURATION,
            useValue: <ProductConfigurationHubProxyConfiguration>{
                // remove sensitive information from proxied getconfiguration response
                getConfigurationResponsePayloadFilter:
                    configurationResponsePricingFilter
            }
        },
        ProductConfigurationHubProxyEndpointRegistrar
    ]
})
export class One2oneModule {
    constructor(
        // eager init
        private readonly productConfigurationHubProxyEndpointRegistrar: ProductConfigurationHubProxyEndpointRegistrar
    ) {}
}
