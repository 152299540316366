import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { OptionGroup } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { ConfigurationInfo, I18nService } from '@mhp/ui-shared-services';

import { ExtendedUiOptionGroup } from './configuration-interfaces';
import { ExtendedUiOptionGroupMapper } from './extended-ui-option-group-mapper';

/**
 * Maps given OptionGroup[] to ExtendedUiOptionGroup[].
 * Performs a deep clone before returning the mapped options.
 */
@Injectable({
    providedIn: 'root'
})
export class ExtendedUiOptionGroupMapperService {
    constructor(private readonly i18nService: I18nService) {}

    /**
     * Map the given OptionGroup[] to ExtendedUiOptionGroup[]
     * @param configurationInfo The option groups to be mapped. Will not modify the input array.
     * @param modelId The modelId to be used when looking up translation keys.
     */
    mapOptionGroups$(
        configurationInfo: ConfigurationInfo<OptionGroup>,
        modelId: string
    ): Observable<ExtendedUiOptionGroup[]> {
        const mapper = new ExtendedUiOptionGroupMapper(
            this.i18nService,
            modelId,
            configurationInfo.productId
        );
        return mapper.mapOptionGroups$(configurationInfo);
    }
}
