import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import {
    ConfigurationCategorySelectComponent,
    ConfigurationElementsModule
} from '@mhp/aml-ui-shared-components/configuration/configuration-elements';
import {
    UiButtonModule,
    UiExpansionPanelModule,
    UiFormFieldModule,
    UiIconButtonModule,
    UiIconsModule,
    UiPreventNestedRippleModule,
    UiSelectModule,
    UiStopClickPropagationModule,
    UiTooltipModule
} from '@mhp/ui-components';
import {
    GoogleTagManagerModule,
    I18nModule,
    L10nModule
} from '@mhp/ui-shared-services';

import { SearchModule } from '../../../common/search/search.module';
import { DealerHelperModule } from '../../../dealer/dealer-helper/dealer-helper.module';
import { ConfigurationPricingModule } from '../../configuration-pricing/configuration-pricing.module';
import { ConfigurationSummaryModule } from '../../configuration-summary/configuration-summary.module';
import { ConfigurationOptionsModule } from '../configuration-options/configuration-options.module';
import { ConfigurationBarCompactComponent } from './configuration-bar-compact.component';
import { ConfigurationCollectionComponent } from './configuration-collection/configuration-collection.component';
import { ConfigurationGroupFirstLevelComponent } from './configuration-group-first-level/configuration-group-first-level.component';
import { ConfigurationGroupSublevelComponent } from './configuration-group-sublevel/configuration-group-sublevel.component';
import { ConfigurationListComponent } from './configuration-list/configuration-list.component';
import { ConfigurationNestedCodeComponent } from './configuration-nested-code/configuration-nested-code.component';

@NgModule({
    declarations: [
        ConfigurationBarCompactComponent,
        ConfigurationGroupFirstLevelComponent,
        ConfigurationGroupSublevelComponent,
        ConfigurationCollectionComponent,
        ConfigurationListComponent,
        ConfigurationNestedCodeComponent
    ],
    exports: [ConfigurationBarCompactComponent],
    imports: [
        CommonModule,
        MatRippleModule,
        ReactiveFormsModule,

        UiIconsModule,
        UiIconButtonModule,
        UiExpansionPanelModule,
        UiStopClickPropagationModule,
        UiPreventNestedRippleModule,
        UiButtonModule,
        UiFormFieldModule,
        UiSelectModule,

        I18nModule,
        L10nModule,

        ConfigurationOptionsModule,
        SearchModule,
        ConfigurationElementsModule,
        ConfigurationSummaryModule,
        DealerHelperModule,
        UiTooltipModule,
        ConfigurationPricingModule,
        GoogleTagManagerModule,
        ConfigurationCategorySelectComponent
    ]
})
export class ConfigurationBarCompactModule {}
