<div [@.disabled]="!initialized" class="expandable-category-bar">
    <div class="expandable-category-bar__entries-container-wrapper">
        <mhp-overflow-indicator
            [prevTemplate]="hasPrev"
            [nextTemplate]="hasNext"
            [scrollableElementRef]="entriesScrollable"
        ></mhp-overflow-indicator>
        <ng-template #hasPrev>
            <div
                class="expandable-category-bar__overflow-indicator expandable-category-bar__overflow-indicator--left"
            ></div>
        </ng-template>
        <ng-template #hasNext>
            <div
                class="expandable-category-bar__overflow-indicator expandable-category-bar__overflow-indicator--right"
            ></div>
        </ng-template>

        <div
            #entriesScrollable
            cdkScrollable
            class="expandable-category-bar__entries-container"
        >
            <ng-container *ngIf="categoryItems; else noItems">
                <div
                    *ngFor="
                        let categoryItem of categoryItems;
                        trackBy: trackById
                    "
                    class="expandable-category-bar__entry-container"
                    [ngClass]="{
                        'expandable-category-bar__entry-container--expanded':
                            categoryItem.selected === true &&
                            !!categoryItem.children
                    }"
                >
                    <ng-container
                        *ngIf="
                            !categoryItem.renderHint ||
                            categoryItem.renderHint === 'flat'
                        "
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                categoryItemFlat;
                                context: {
                                    categoryItem: categoryItem
                                }
                            "
                        ></ng-container>
                    </ng-container>
                    <ng-container
                        *ngIf="categoryItem.renderHint === 'dropdown'"
                    >
                        <ng-container
                            *ngTemplateOutlet="
                                categoryItemDropdown;
                                context: {
                                    categoryItem: categoryItem,
                                    renderLabel: true,
                                    ngClass: {
                                        'expandable-category-bar__toplevel-entry': true,
                                        'expandable-category-bar__toplevel-entry--dropdown': true,
                                        'expandable-category-bar__toplevel-entry--active':
                                            categoryItem.selected,
                                        'expandable-category-bar__toplevel-entry--is-leaf-entry':
                                            !categoryItem.children,
                                        'expandable-category-bar__toplevel-entry--single-option-parent':
                                            categoryItem.hasChangeableSelectionInHierarchy ===
                                            false
                                    }
                                }
                            "
                        ></ng-container>
                    </ng-container>

                    <ng-template
                        #categoryItemFlat
                        let-categoryItem="categoryItem"
                    >
                        <!-- toplevel entry -->
                        <div
                            class="expandable-category-bar__toplevel-entry expandable-category-bar__toplevel-entry--flat"
                            [ngClass]="{
                                'expandable-category-bar__toplevel-entry--active':
                                    categoryItem.selected,
                                'expandable-category-bar__toplevel-entry--is-leaf-entry':
                                    !categoryItem.children,
                                'expandable-category-bar__toplevel-entry--single-option-parent':
                                    categoryItem.hasChangeableSelectionInHierarchy ===
                                    false
                            }"
                        >
                            <nav
                                mat-tab-nav-bar
                                color="primary"
                                [tabPanel]="topLevelTabPanel"
                                [animationDuration]="'0'"
                            >
                                <a
                                    mat-tab-link
                                    [active]="categoryItem.selected"
                                    (click)="onCategorySelected(categoryItem)"
                                >
                                    {{ categoryItem.label }}
                                </a>
                            </nav>
                            <mat-tab-nav-panel
                                #topLevelTabPanel
                            ></mat-tab-nav-panel>
                        </div>
                        <!-- toplevel entries children -->
                        <div
                            *ngIf="
                                categoryItem.selected &&
                                categoryItem.children &&
                                !categoryItem.collapsed
                            "
                            class="expandable-category-bar__sub-entries-container"
                            [ngClass]="{
                                'expandable-category-bar__sub-entries-container--active':
                                    categoryItem.selected
                            }"
                            @smoothExpand
                        >
                            <nav
                                mat-tab-nav-bar
                                color="primary"
                                [tabPanel]="subEntriesTabPanel"
                                [animationDuration]="'250ms'"
                            >
                                <ng-container
                                    *ngFor="
                                        let child of categoryItem.children;
                                        trackBy: trackById
                                    "
                                >
                                    <a
                                        *ngIf="
                                            !child.renderHint ||
                                            child.renderHint === 'flat'
                                        "
                                        class="expandable-category-bar__sub-entry"
                                        [ngClass]="{
                                            'expandable-category-bar__sub-entry--active':
                                                child.selected
                                        }"
                                        mat-tab-link
                                        [active]="child.selected"
                                        (click)="onCategorySelected(child)"
                                    >
                                        {{ child.label }}
                                    </a>

                                    <ng-container
                                        *ngIf="child.renderHint === 'dropdown'"
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="
                                                categoryItemDropdown;
                                                context: {
                                                    categoryItem: child,
                                                    ngClass: {
                                                        'expandable-category-bar__sub-entry': true,
                                                        'expandable-category-bar__sub-entry--dropdown': true,
                                                        'expandable-category-bar__sub-entry--active':
                                                            child.selected,
                                                        'expandable-category-bar__sub-entry--single-option-parent':
                                                            child.hasChangeableSelectionInHierarchy ===
                                                            false
                                                    }
                                                }
                                            "
                                        ></ng-container>
                                    </ng-container>
                                </ng-container>
                            </nav>
                            <mat-tab-nav-panel
                                #subEntriesTabPanel
                            ></mat-tab-nav-panel>
                        </div>
                    </ng-template>

                    <ng-template
                        #categoryItemDropdown
                        let-categoryItem="categoryItem"
                        let-ngClass="ngClass"
                        let-renderLabel="renderLabel"
                    >
                        <div
                            class="expandable-category-bar__entry-dropdown"
                            [ngClass]="ngClass"
                        >
                            <div
                                *ngIf="renderLabel"
                                class="expandable-category-bar__entry-dropdown-label"
                            >
                                {{ categoryItem.label }}
                                <ng-container
                                    *ngIf="categoryLabelExtensionTemplate"
                                >
                                    <ng-container
                                        *ngTemplateOutlet="
                                            categoryLabelExtensionTemplate;
                                            context: {
                                                categoryItem: categoryItem
                                            }
                                        "
                                    >
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div
                                class="expandable-category-bar__entry-dropdown-control"
                            >
                                <mhp-configuration-category-select
                                    [categoryItem]="categoryItem"
                                    [formFieldClass]="
                                        'mhp-ui-form-field--adapt-width-to-content'
                                    "
                                    [disabled]="optionSelectionDisabled()"
                                    (selectCategory)="
                                        onCategorySelected($event)
                                    "
                                    (showInfo)="intentShowInfo($event)"
                                >
                                </mhp-configuration-category-select>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
            <ng-template #noItems>
                <div
                    *ngIf="noItemsTemplate"
                    class="expandable-category-bar__no-categories-container"
                >
                    <ng-container
                        *ngTemplateOutlet="noItemsTemplate"
                    ></ng-container>
                </div>
            </ng-template>
        </div>
    </div>
    <div
        class="expandable-category-bar__navigation"
        *transloco="let t; read: 'ICONS'"
    >
        <mhp-ui-icon-button
            svgIcon="mhp-ui:angle-left"
            class="expandable-category-bar__navigation-action expandable-category-bar__navigation-action--prev"
            [disabled]="!canSelectPrev"
            [matTooltip]="t('PREVIOUS_CATEGORY_ALT')"
            [label]="t('PREVIOUS_CATEGORY_ALT')"
            (click)="intentGoToPrev()"
        ></mhp-ui-icon-button>
        <mhp-ui-icon-button
            svgIcon="mhp-ui:angle-right"
            class="expandable-category-bar__navigation-action expandable-category-bar__navigation-action--next"
            [disabled]="!canSelectNext"
            [matTooltip]="t('NEXT_CATEGORY_ALT')"
            [label]="t('NEXT_CATEGORY_ALT')"
            (click)="intentGoToNext()"
        ></mhp-ui-icon-button>
    </div>
</div>
