import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';
import { UiBaseComponent } from '@mhp/ui-components';

import { environment } from '../../../environments/environment';
import { VideoChatService } from '../../video-chat/video-chat.service';

@Component({
    selector: 'mhp-video-chat-dialog',
    templateUrl: './video-chat-dialog.component.html',
    styleUrls: ['./video-chat-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoChatDialogComponent extends UiBaseComponent {
    readonly isDealerBuild = environment.appConfig.dealer.dealerBuild;

    constructor(private readonly videoChatService: VideoChatService) {
        super();
    }

    @MemoizeObservable()
    getCustomerName$() {
        return this.videoChatService
            .getCustomerName$()
            .pipe(map(({ firstName, lastName }) => `${firstName} ${lastName}`));
    }

    @MemoizeObservable()
    getDealerName$(): Observable<string> {
        return this.videoChatService.getDealerName$();
    }
}
