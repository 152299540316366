import { Subject, fromEvent, map, pairwise, switchMap, takeUntil } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';

import { DestroyRef, ElementRef } from '@angular/core';

/**
 * A drag-handler implementation allowing to register an element and
 * scrolling it horizontally by dragging it to the left and right.
 */
export class HorizontalDragHandler {
    private readonly destroySubject = new Subject<unknown>();

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly document: Document,
        destroyRef: DestroyRef
    ) {
        destroyRef.onDestroy(() => this.destroySubject.next(undefined));

        this.bindMouseMove();
    }

    private bindMouseMove() {
        const target = this.elementRef.nativeElement;
        fromEvent<MouseEvent>(target, 'mousedown')
            .pipe(
                tap((event) => event.preventDefault()),
                map((event) => {
                    return {
                        x: event.clientX,
                        y: event.clientY
                    };
                }),
                switchMap((startingCoordinates) => {
                    return fromEvent<MouseEvent>(target, 'mousemove').pipe(
                        map((e: MouseEvent) => {
                            return {
                                x: e.clientX,
                                y: e.clientY
                            };
                        }),
                        startWith(startingCoordinates),
                        pairwise(),
                        takeUntil(
                            fromEvent<MouseEvent>(
                                this.document,
                                'mouseup'
                            ).pipe(tap((event) => event.preventDefault()))
                        )
                    );
                }),
                takeUntil(this.destroySubject)
            )
            .subscribe((coordinates) => {
                const scrollMovePx = coordinates[1].x - coordinates[0].x;
                target.scrollLeft -= scrollMovePx;
            });
    }
}
