<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-dialog-body [noPadding]="true">
    <ng-container *transloco="let t">
        <div class="environment-info">
            <div class="environment-info__container">
                <div class="environment-info__image">
                    <mhp-ui-lazyload-image
                        class="environment-info__image-src"
                        imageFit="cover"
                        [imageUrl]="data.interiorImageSrcset"
                        [imageFallbackUrl]="
                            amlImagesService.getModelSpecificFallbackImageUrl$()
                                | async
                        "
                        [willResize]="true"
                        [title]="
                            t('INTERIOR_ENV_SELECTION.COMMON.ALT_IMAGE_TEXT')
                        "
                    ></mhp-ui-lazyload-image>
                </div>
                <div class="environment-info__info">
                    <div class="environment-info__info-text">
                        <h1 class="environment-info__headline">
                            {{ data.environmentGroupModel.label }}
                        </h1>
                        <div class="environment-info__description">
                            {{ data.environmentGroupModel.descriptionShort }}
                        </div>
                        <div
                            class="environment-info__description"
                            [innerHtml]="
                                replaceAll(
                                    data.environmentGroupModel.descriptionLong,
                                    '\n',
                                    '<br />'
                                )
                            "
                        ></div>
                    </div>
                    <div class="environment-info__info-action">
                        <button
                            class="environment-info__button mhp-ui-button--full-width"
                            mat-flat-button
                            *ngIf="!data.readonly"
                            (click)="
                                configureEnvironment(data.environmentModel)
                            "
                        >
                            <span>{{
                                t(
                                    'INTERIOR_ENV_SELECTION.COMMON.CTA_START_CUSTOMISATION'
                                )
                            }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</mhp-dialog-body>
