import { finalize, tap } from 'rxjs/operators';

import { Overlay } from '@angular/cdk/overlay';
import {
    ComponentFactoryResolver,
    Inject,
    Injectable,
    InjectionToken,
    Optional,
    ViewContainerRef
} from '@angular/core';
import { AmlUiSharedState } from '@mhp/aml-ui-shared-services';
import { IllegalStateError } from '@mhp/common';
import { UiMatDialogConfig, UiMatDialogService } from '@mhp/ui-components';
import { UiSharedStateService } from '@mhp/ui-shared-services';

import {
    EnvironmentSelectionDialogComponent,
    EnvironmentSelectionDialogData
} from './environment-selection-dialog/environment-selection-dialog.component';

export interface EnvironmentSelectionDialogServiceConfig {
    // should active items be pushed to the ui-state?
    pushStateToUiSharedState?: boolean;
}

export const ENVIRONMENT_SELECTION_DIALOG_SERVICE_CONFIG_TOKEN =
    new InjectionToken<EnvironmentSelectionDialogServiceConfig>(
        'EnvironmentSelectionDialogServiceConfig'
    );

@Injectable()
export class EnvironmentSelectionDialogService {
    constructor(
        private readonly dialogService: UiMatDialogService,
        private readonly overlay: Overlay,
        @Optional()
        private readonly uiSharedStateService?: UiSharedStateService<AmlUiSharedState>,
        @Inject(ENVIRONMENT_SELECTION_DIALOG_SERVICE_CONFIG_TOKEN)
        @Optional()
        private readonly config?: EnvironmentSelectionDialogServiceConfig
    ) {}

    /**
     * Open the environment selection dialog.
     * @param viewContainerRef The viewContainerRef of the calling component.
     * @param componentFactoryResolver The componentFactoryResolver of the calling component.
     * @param dialogConfig Optional config to be provided to the dialog.
     */
    showEnvironmentSelectionDialog$(
        viewContainerRef: ViewContainerRef,
        componentFactoryResolver: ComponentFactoryResolver,
        dialogConfig?: UiMatDialogConfig<EnvironmentSelectionDialogData>
    ) {
        return this.dialogService
            .openFullscreen$(EnvironmentSelectionDialogComponent, {
                scrollStrategy: this.overlay.scrollStrategies.block(),
                ...dialogConfig,
                viewContainerRef,
                componentFactoryResolver
            })
            .pipe(
                tap(() => this.updateUiState(true)),
                finalize(() => this.updateUiState(false))
            );
    }

    private updateUiState(environmentSelectionDialogState: boolean) {
        if (!this.config?.pushStateToUiSharedState) {
            return;
        }

        if (!this.uiSharedStateService) {
            throw new IllegalStateError('missing uiSharedStateService');
        }

        this.uiSharedStateService
            .updateUiState((uiState) => {
                uiState.interiorEnvironmentSelectionState =
                    environmentSelectionDialogState;
                return uiState;
            })
            .subscribe();
    }
}
