import { fromEvent, of } from 'rxjs';
import {
    buffer,
    delay,
    filter,
    map,
    shareReplay,
    switchMap
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { MemoizeObservable } from '@mhp/common';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DebugService {
    @MemoizeObservable()
    getDebugState$() {
        if (!environment.appConfig.configuration.allowDebugPanel) {
            return of(false);
        }
        return fromEvent<KeyboardEvent>(window, 'keyup').pipe(
            buffer(
                fromEvent(window, 'keyup').pipe(
                    switchMap(() => of(undefined).pipe(delay(500)))
                )
            ),
            map((collectedKeyups) =>
                collectedKeyups.map((event) => event.key).join('')
            ),
            filter(
                (typedWord) => typedWord === 'debug' || typedWord === 'nodebug'
            ),
            map((typedWord: 'debug' | 'nodebug') => typedWord === 'debug'),
            shareReplay()
        );
    }
}
