import { isEmpty, isNumber, pick, uniq, zip } from 'lodash-es';
import {
    BehaviorSubject,
    EMPTY,
    Observable,
    Subscription,
    combineLatest,
    map,
    of,
    switchMap,
    take
} from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    withLatestFrom
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import {
    AmlUiSharedState,
    BrandStoreMirrorModeTargetState,
    BrandStoreMirrorOptionsState,
    BrandStoreMirrorState,
    BrandStoreMirroredOption,
    CategoryItem,
    EVENT_MIRROR_MODE_CHANGE_CATEGORY,
    EVENT_MIRROR_MODE_SELECT_OPTION,
    MirrorModeChangeCategoryEventPayload,
    MirrorModeSelectOptionEventPayload,
    ReducedCategoryItem
} from '@mhp/aml-ui-shared-services';
import {
    IllegalStateError,
    MemoizeObservable,
    distinctUntilChangedEquality,
    lazyShareReplay,
    negate
} from '@mhp/common';
import { ImageSrcset } from '@mhp/ui-components';
import {
    ApplicationStateService,
    ErrorHandlerService,
    I18nService,
    ProductDataService,
    SocketIOService,
    UiSharedStateService
} from '@mhp/ui-shared-services';
import { EngineStateService } from '@mhp/ui-shared-services/engine/engine-state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { environment } from '../../../environments/environment';
import { setFocusTargetNode } from '../../configuration';
import { ConfigurationSessionImageAssetService } from '../../configuration/assets/configuration-session-image-asset.service';
import { OptionIdWithVisibilityState } from '../../configuration/configuration-area/configuration-options/configuration-options-list/configuration-options-list.component';
import { ExtendedUiOptionCode } from '../../configuration/configuration-model/configuration-interfaces';
import { ConfigurationNodeLookupService } from '../../configuration/services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../configuration/services/product-configuration-session.service';
import { StaticRendererService } from '../../configuration/static-renderer/static-renderer.service';
import { VrService } from '../../configuration/vr/vr.service';
import { LocalApplicationState } from '../../state';
import { selectEngineUiState } from '../../state/selectors';
import { isBrandStoreExclusiveEnvironment } from '../common/brand-store-environment-helper';
import {
    MirrorModeCategoryChangeHandler,
    MirrorModeCategoryInfoProvider
} from './mirror-mode.interfaces';

const NON_MIRROR_MODE_COMPLIANT_TOP_LEVEL_CATEGORIES = [
    environment.appConfig.configuration.identifierAccessories,
    environment.appConfig.configuration.identifierPersonalisation,
    environment.appConfig.configuration.identifierSummary
];

@UntilDestroy()
@Injectable()
export class MirrorModeSupportService {
    private readonly mirrorModeStateSubject =
        new BehaviorSubject<BrandStoreMirrorModeTargetState>(
            BrandStoreMirrorModeTargetState.MIRROR_OFF
        );

    private readonly mirrorModeState$ = this.mirrorModeStateSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    private readonly interiorMirrorTargetCameraSubject = new BehaviorSubject<
        string | undefined
    >(undefined);

    private categoryChangeHandler?: MirrorModeCategoryChangeHandler;

    private readonly categoryInfoProviderSubject = new BehaviorSubject<
        MirrorModeCategoryInfoProvider | undefined
    >(undefined);

    constructor(
        private readonly amlImageAssetService: ConfigurationSessionImageAssetService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly productDataService: ProductDataService,
        private readonly nodeLookupService: ConfigurationNodeLookupService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly uiSharedStateService: UiSharedStateService<AmlUiSharedState>,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly socketIOService: SocketIOService,
        private readonly staticRendererService: StaticRendererService,
        private readonly engineStateService: EngineStateService,
        private readonly vrService: VrService,
        private readonly i18nService: I18nService
    ) {
        this.disableMirrorMode();

        this.initTemporarilyDisableMirrorModeDependingOnActiveCategories();
        this.initTemporarilyDisableMirrorModeDependingOnDisabledState();
        this.initSelectionIntentEventHandling();
        this.initCategoryChangeEventHandling();
        this.initBroadcastVrState();
        this.initBroadcastCategoryNavigationState();
    }

    /**
     * Toggle the mirror mode on/off.
     * @param targetOnState The optional target state for ON in case the current state is off. Defaults to MirrorModeState.MIRROR_OPTIONS
     */
    toggleMirrorMode(
        targetOnState:
            | BrandStoreMirrorModeTargetState.MIRROR_OPTIONS
            | BrandStoreMirrorModeTargetState.MIRROR_INTERIOR_VIEW = BrandStoreMirrorModeTargetState.MIRROR_OPTIONS
    ) {
        this.setMirrorModeState(
            this.mirrorModeStateSubject.value === targetOnState
                ? BrandStoreMirrorModeTargetState.MIRROR_OFF
                : targetOnState
        );
    }

    /**
     * Enable the mirror mode with the given state.
     * @param targetState The state to enable the mirror mode with
     */
    enableMirrorMode(
        targetState:
            | BrandStoreMirrorModeTargetState.MIRROR_OPTIONS
            | BrandStoreMirrorModeTargetState.MIRROR_INTERIOR_VIEW
    ) {
        this.setMirrorModeState(targetState);
    }

    disableMirrorMode() {
        this.setMirrorModeState(BrandStoreMirrorModeTargetState.MIRROR_OFF);
    }

    /**
     * Emits if the mirror-mode-option (option-mirror and interior-view-mirror)
     * is currently disabled.
     */
    @MemoizeObservable()
    isMirrorModeDisabled$(): Observable<boolean> {
        return combineLatest([
            this.engineStateService.getActiveEnvironment$(),
            this.vrService.isVrActive$()
        ]).pipe(
            map(([activeEnvironment, vrActive]) => {
                // in case vr is active, we do allow toggling mirror-mode all the time
                if (vrActive) {
                    return false;
                }
                // in case there's no active environment, simply assume mirror-mode enabled
                if (!activeEnvironment) {
                    return false;
                }
                // mirror-mode disabled in case we don't have an brandstore exclusive environment
                return !isBrandStoreExclusiveEnvironment(activeEnvironment);
            }),
            lazyShareReplay()
        );
    }

    /**
     * Emits if some kind of mirror mode is currently active.
     */
    @MemoizeObservable()
    isMirrorModeActive$(): Observable<boolean> {
        return this.mirrorModeState$.pipe(
            map((state) => state !== BrandStoreMirrorModeTargetState.MIRROR_OFF)
        );
    }

    @MemoizeObservable()
    isMirrorOptionsModeActive$(): Observable<boolean> {
        return this.mirrorModeState$.pipe(
            map(
                (state) =>
                    state === BrandStoreMirrorModeTargetState.MIRROR_OPTIONS
            )
        );
    }

    @MemoizeObservable()
    isMirrorInteriorModeActive$(): Observable<boolean> {
        return combineLatest([
            this.mirrorModeState$.pipe(
                map(
                    (state) =>
                        state ===
                        BrandStoreMirrorModeTargetState.MIRROR_INTERIOR_VIEW
                )
            ),
            this.isMirrorInteriorModeDisabled$().pipe(negate())
        ]).pipe(map((criteria) => criteria.every(Boolean)));
    }

    @MemoizeObservable()
    isMirrorInteriorModeDisabled$(): Observable<boolean> {
        return combineLatest([
            this.isMirrorModeDisabled$(),
            this.vrService.isVrActive$(),
            this.engineStateService
                .getActiveCameraType$()
                .pipe(map((type) => type === 'Int'))
        ]).pipe(map((criteria) => criteria.some(Boolean)));
    }

    /**
     * Start broadcasting the currently selected options.
     * @param visibleItems$
     */
    initBroadcastActiveOptions(
        visibleItems$: Observable<OptionIdWithVisibilityState[] | undefined>
    ): Subscription {
        // construct a stream providing the items currently visible on the config-bar
        const visibleItemsStream$: Observable<
            | {
                  optionCode: ExtendedUiOptionCode;
                  thumbnailImage: ImageSrcset | string | undefined;
              }[]
            | undefined
        > = combineLatest([
            this.productConfigurationSessionService.getOptionGroups$(),
            // in case vr isn't active, we broadcast the currently visible items on dealer side to the mirror-ui only. With VR being active, all options are sent over to the mirror-ui
            combineLatest([visibleItems$, this.vrService.isVrActive$()]).pipe(
                map(([items, vrActive]) =>
                    vrActive
                        ? items
                        : items?.filter((item) => item.visibilityState)
                )
            )
        ]).pipe(
            map(([optionGroups, visibleItems]) =>
                optionGroups && visibleItems
                    ? this.nodeLookupService.collectNodes<ExtendedUiOptionCode>(
                          (node) =>
                              !!visibleItems?.find(
                                  (item) => node.id === item.id
                              ),
                          optionGroups
                      )
                    : undefined
            ),
            switchMap((items) =>
                items
                    ? combineLatest(
                          items.map((item) =>
                              this.amlImageAssetService.getOptionThumbnailImageSrc$(
                                  item
                              )
                          )
                      ).pipe(
                          map((thumbnailImages) =>
                              zip(items, thumbnailImages).map((zippedItem) => {
                                  if (!zippedItem[0]) {
                                      throw new IllegalStateError(
                                          'OptionCode expected'
                                      );
                                  }
                                  return {
                                      optionCode: zippedItem[0],
                                      thumbnailImage: zippedItem[1]
                                  };
                              })
                          )
                      )
                    : of(undefined)
            )
        );

        // construct stream providing the labels of the currently active category-hierarchy
        const activeCategoryLabels$: Observable<
            | Pick<
                  BrandStoreMirrorOptionsState,
                  | 'topLevelCategoryLabel'
                  | 'firstLevelCategoryLabel'
                  | 'secondLevelCategoryLabel'
              >
            | undefined
        > = combineLatest([
            this.applicationStateService.getState().pipe(
                selectEngineUiState,
                map((engineUiState) => engineUiState?.activeCategoryPath),
                distinctUntilChangedEquality()
            ),
            this.productConfigurationSessionService.getOptionGroups$()
        ]).pipe(
            map(([activeCategoryPath, optionGroups]) => {
                if (!activeCategoryPath || !optionGroups) {
                    return undefined;
                }
                const collectedLabels = uniq(
                    this.nodeLookupService
                        .collectNodes(
                            (node) => activeCategoryPath.indexOf(node.id) > -1,
                            optionGroups
                        )
                        .map((node) => node.nameTranslated)
                );

                if (isEmpty(collectedLabels)) {
                    return undefined;
                }

                return {
                    topLevelCategoryLabel: collectedLabels[0],
                    firstLevelCategoryLabel: collectedLabels[1],
                    secondLevelCategoryLabel: collectedLabels[2]
                };
            })
        );

        return this.isMirrorOptionsModeActive$() // emit items only in case mirror-options mode is active
            .pipe(
                filter(Boolean),
                switchMap(() =>
                    combineLatest([visibleItemsStream$, activeCategoryLabels$])
                ),
                debounceTime(0),
                switchMap(([visibleItems, activeCategoryLabels]) =>
                    this.updateUiState$((currentUiState) => ({
                        ...currentUiState,
                        brandStore: {
                            ...currentUiState.brandStore,
                            mirrorState: <BrandStoreMirrorState>{
                                ...currentUiState.brandStore?.mirrorState,
                                mirrorOptionsState: {
                                    ...activeCategoryLabels,
                                    mirroredOptions: visibleItems?.map(
                                        (
                                            itemWithImageSrcset
                                        ): BrandStoreMirroredOption => ({
                                            id: itemWithImageSrcset.optionCode
                                                .id,
                                            subType:
                                                itemWithImageSrcset.optionCode
                                                    .subType,
                                            thumbnailImage:
                                                itemWithImageSrcset.thumbnailImage,
                                            selected:
                                                itemWithImageSrcset.optionCode
                                                    .selected,
                                            label: itemWithImageSrcset
                                                .optionCode.nameTranslated
                                        })
                                    )
                                }
                            }
                        }
                    }))
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    /**
     * Start broadcasting the interior view.
     */
    initBroadcastInteriorView(): Subscription {
        const targetInteriorCameraId$: Observable<string> = combineLatest(
            this.productDataService.getAvailableCameras$(),
            this.interiorMirrorTargetCameraSubject
        ).pipe(
            map(([cameraInfo, interiorMirrorTargetCamera]) => {
                if (!interiorMirrorTargetCamera) {
                    return (
                        cameraInfo?.defaultInt ??
                        environment.appConfig.brandStore.mirrorMode
                            .interiorMirrorModeCameraFallbackId
                    );
                }
                return interiorMirrorTargetCamera;
            })
        );

        return this.isMirrorInteriorModeActive$()
            .pipe(
                filter(Boolean),
                switchMap(() => targetInteriorCameraId$),
                switchMap((targetInteriorCameraId) =>
                    this.staticRendererService.getActiveSessionRenderingSrcset$(
                        {
                            alternativeResolutions: [3840]
                        },
                        {
                            overrideCamera: targetInteriorCameraId
                        }
                    )
                ),
                switchMap((activeSessionRendering) =>
                    this.updateUiState$((currentUiState) => ({
                        ...currentUiState,
                        brandStore: {
                            ...currentUiState.brandStore,
                            mirrorState: <BrandStoreMirrorState>{
                                ...currentUiState.brandStore?.mirrorState,
                                mirrorInteriorState: {
                                    rendering: activeSessionRendering
                                }
                            }
                        }
                    }))
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    updateInteriorViewTargetCamera(targetCameraId: string) {
        this.interiorMirrorTargetCameraSubject.next(targetCameraId);
    }

    /**
     * Register a category change handler that is able to change the categories if applicable in the current context.
     */
    registerCategoryChangeHandler(
        categoryChangeHandler: MirrorModeCategoryChangeHandler
    ) {
        if (this.categoryChangeHandler) {
            throw new IllegalStateError(
                'categoryChangeHandler already registered'
            );
        }
        this.categoryChangeHandler = categoryChangeHandler;

        return () => {
            this.categoryChangeHandler = undefined;
        };
    }

    /**
     * Register a {@link MirrorModeCategoryInfoProvider} that is able to provide the current category-state.
     */
    registerCategoryInfoProvider(
        categoryInfoProvider: MirrorModeCategoryInfoProvider
    ) {
        if (this.categoryInfoProviderSubject.value) {
            throw new IllegalStateError(
                'categoryInfoProvider already registered'
            );
        }
        this.categoryInfoProviderSubject.next(categoryInfoProvider);

        return () => {
            this.categoryInfoProviderSubject.next(undefined);
        };
    }

    private initTemporarilyDisableMirrorModeDependingOnActiveCategories() {
        combineLatest([
            this.applicationStateService.getState().pipe(
                selectEngineUiState,
                map((engineUiState) => engineUiState?.activeTopLevelCategory)
            ),
            this.mirrorModeState$,
            this.isMirrorModeDisabled$()
        ])
            .pipe(
                debounceTime(0),
                distinctUntilChangedEquality(),
                untilDestroyed(this)
            )
            .subscribe(
                ([
                    activeTopLevelCategory,
                    mirrorModeState,
                    mirrorModeDisabled
                ]) => {
                    if (
                        mirrorModeDisabled ||
                        mirrorModeState !==
                            BrandStoreMirrorModeTargetState.MIRROR_OPTIONS ||
                        !activeTopLevelCategory
                    ) {
                        return;
                    }

                    if (
                        NON_MIRROR_MODE_COMPLIANT_TOP_LEVEL_CATEGORIES.includes(
                            activeTopLevelCategory
                        )
                    ) {
                        this.setMirrorModeState(
                            BrandStoreMirrorModeTargetState.MIRROR_OFF,
                            true
                        );
                    } else {
                        this.setMirrorModeState(
                            BrandStoreMirrorModeTargetState.MIRROR_OPTIONS,
                            false
                        );
                    }
                }
            );
    }

    private initTemporarilyDisableMirrorModeDependingOnDisabledState() {
        combineLatest([this.mirrorModeState$, this.isMirrorModeDisabled$()])
            .pipe(distinctUntilChangedEquality(), untilDestroyed(this))
            .subscribe(([mirrorModeState, isDisabled]) => {
                if (
                    mirrorModeState ===
                    BrandStoreMirrorModeTargetState.MIRROR_OFF
                ) {
                    return;
                }

                if (isDisabled) {
                    this.setMirrorModeState(
                        BrandStoreMirrorModeTargetState.MIRROR_OFF,
                        true
                    );
                } else {
                    this.setMirrorModeState(mirrorModeState, false);
                }
            });
    }

    private setMirrorModeState(
        targetState: BrandStoreMirrorModeTargetState,
        isTransientChange = false
    ) {
        const updateUiState$ = this.updateUiState$((currentUiState) => ({
            ...currentUiState,
            brandStore: {
                ...currentUiState.brandStore,
                mirrorState: <BrandStoreMirrorState>{
                    ...currentUiState.brandStore?.mirrorState,
                    targetState
                }
            }
        }));

        updateUiState$
            .pipe(
                switchMap(() =>
                    this.socketIOService
                        .request<{ enabled: boolean }, unknown>(
                            'setmirrorview',
                            {
                                enabled:
                                    targetState !==
                                    BrandStoreMirrorModeTargetState.MIRROR_OFF
                            }
                        )
                        .pipe(
                            take(1),
                            this.errorHandlerService.applyRetry({
                                messageProviderOnFinalError: () =>
                                    translate(
                                        'BRAND_STORE.ERRORS.FAILED_TOGGLING_MIRROR_MODE'
                                    )
                            })
                        )
                )
            )
            .subscribe(() => {
                if (isTransientChange) {
                    return;
                }
                this.mirrorModeStateSubject.next(targetState);
            });
    }

    private updateUiState$(
        callback: (uiState: AmlUiSharedState) => AmlUiSharedState
    ) {
        return this.uiSharedStateService.updateUiState(callback).pipe(
            this.errorHandlerService.applyRetry({
                isEligibleForRetry: () => true,
                messageProviderOnFinalError: () =>
                    translate('CONFIGURATOR.ERRORS.BROADCAST_SHARED_STATE')
            }),
            catchError(() => EMPTY),
            untilDestroyed(this)
        );
    }

    /**
     * Broadcast the current category-navigation-state to be available for the mirror-panel.
     * @private
     */
    private initBroadcastCategoryNavigationState() {
        this.vrService
            .isVrActive$()
            .pipe(
                // only broadcast in case vr is active
                filter((vrActive) => vrActive),
                switchMap(() =>
                    combineLatest([
                        this.categoryInfoProviderSubject,
                        this.i18nService.selectTranslate$(
                            'BRAND_STORE.VR.MIRROR.CATEGORY_SELECT_HEADER'
                        )
                    ])
                ),
                switchMap(([provider, changeCategoryMenuHeader]) => {
                    // calculate the current and the total available steps for navigating categories
                    if (!provider) {
                        return of(undefined);
                    }
                    return provider.getActiveCategoryInfo$().pipe(
                        map((info) => info.categoryNavigationState),
                        map((navigationState) => {
                            // have to clear the hierarchy-path to avoid circular dependencies when syncing to shared-ui-state
                            const categoryItems = navigationState?.categoryItems
                                ? this.reduceCategoryItems(
                                      navigationState?.categoryItems
                                  )
                                : [];
                            // remove non-relevant categories for VR
                            categoryItems.forEach((item) => {
                                item.children = item.children?.filter(
                                    (childItem) => childItem.isCategorySelector
                                );
                            });
                            return navigationState
                                ? {
                                      ...navigationState,
                                      categoryItems,
                                      categoryMenuHeader:
                                          changeCategoryMenuHeader
                                  }
                                : undefined;
                        })
                    );
                }),
                switchMap((categoryNavigationState) =>
                    this.updateUiState$((uiState) => ({
                        ...uiState,
                        brandStore: {
                            ...uiState.brandStore,
                            mirrorVrState: {
                                ...uiState.brandStore?.mirrorVrState,
                                categoryNavigationState: categoryNavigationState
                                    ? {
                                          ...categoryNavigationState
                                      }
                                    : undefined
                            }
                        }
                    }))
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    /**
     * Initialize listening to EVENT_MIRROR_MODE_SELECT_OPTION events to apply according selection-intent
     * to the local product-data session.
     * @private
     */
    private initSelectionIntentEventHandling() {
        this.socketIOService
            .subscribe<MirrorModeSelectOptionEventPayload>(
                EVENT_MIRROR_MODE_SELECT_OPTION
            )
            .pipe(
                switchMap(({ id }) =>
                    this.productConfigurationSessionService
                        .updateNodeSelection(id)
                        .pipe(
                            catchError((error) => {
                                console.error(
                                    'Failed applying remote option-selection event.',
                                    error
                                );
                                return EMPTY;
                            })
                        )
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    /**
     * Initialize listening to EVENT_MIRROR_MODE_CHANGE_CATEGORY events to apply according category-change.
     * @private
     */
    private initCategoryChangeEventHandling() {
        this.socketIOService
            .subscribe<MirrorModeChangeCategoryEventPayload>(
                EVENT_MIRROR_MODE_CHANGE_CATEGORY
            )
            .pipe(
                withLatestFrom(
                    this.productConfigurationSessionService.getOptionGroups$()
                ),
                untilDestroyed(this)
            )
            .subscribe(([{ direction, id }, optionGroups]) => {
                if (id) {
                    // explicitly activate category
                    this.applicationStateService.dispatch(
                        setFocusTargetNode({
                            id
                        })
                    );
                } else if (isNumber(direction)) {
                    // change category using direction
                    this.categoryChangeHandler?.intentChangeCategory(
                        direction,
                        (categoryItem) => {
                            const matchingNode =
                                this.nodeLookupService.findNodeById(
                                    categoryItem.id,
                                    optionGroups ?? []
                                )?.node;

                            if (!matchingNode) {
                                return true;
                            }

                            return !NON_MIRROR_MODE_COMPLIANT_TOP_LEVEL_CATEGORIES.includes(
                                matchingNode.hierarchyPath[0].name
                            );
                        }
                    );
                }
            });
    }

    /**
     * Broadcast the current vr-state via ui-state so that the screen-ui can react to it.
     * @private
     */
    private initBroadcastVrState() {
        this.vrService
            .isVrActive$()
            .pipe(
                switchMap((vrActive) =>
                    this.updateUiState$((uiState) => ({
                        ...uiState,
                        brandStore: {
                            ...uiState.brandStore,
                            vrActive
                        }
                    }))
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private reduceCategoryItems(
        categoryItems: (CategoryItem | ReducedCategoryItem)[]
    ) {
        return categoryItems.map(
            (item): ReducedCategoryItem => ({
                ...pick(item, 'id', 'selected', 'label', 'isCategorySelector'),
                children: item.children
                    ? this.reduceCategoryItems(item.children)
                    : undefined
            })
        );
    }
}
