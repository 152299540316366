import {
    BehaviorSubject,
    Observable,
    combineLatest,
    debounceTime,
    map
} from 'rxjs';

import {
    animate,
    animateChild,
    group,
    query,
    style,
    transition,
    trigger
} from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { CategoryItem } from '@mhp/aml-ui-shared-services/configuration/categories';
import {
    MemoizeObservable,
    distinctUntilChangedEquality,
    lazyShareReplay
} from '@mhp/common';
import { IconSize, UiBaseComponent } from '@mhp/ui-components';

import {
    combineCategorySourceItemFilters,
    restrictNestingDepthForPersonalisationAndAccessories,
    topLevelCategoryFilter
} from '../../configuration-area/helpers/category-filters';
import { CategoryMapperService } from '../../configuration-area/helpers/category-mapper.service';
import { ProductConfigurationSessionService } from '../../services/product-configuration-session.service';

interface MenuTocModel {
    id: string;
    label: string;
    children?: MenuTocModel[];
    active: boolean;
}

@Component({
    selector: 'mhp-menu-toc',
    templateUrl: './menu-toc.component.html',
    styleUrls: ['./menu-toc.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('slideIn', [
            transition(':enter', [
                group([
                    style({ width: '0', overflow: 'hidden' }),
                    animate('.2s ease-out', style({ width: '*' })),
                    query('@fadeInOut', animateChild())
                ])
            ]),
            transition(':leave', [
                group([
                    query('@fadeInOut', animateChild()),
                    query('@fadeInOut', [style({ position: 'static' })]),
                    style({ overflow: 'hidden' }),
                    animate('.2s ease-out', style({ width: '0' }))
                ])
            ])
        ]),
        trigger('fadeInOut', [
            transition(':enter', [
                style({
                    opacity: '0',
                    filter: 'blur(5px)'
                }),
                animate(
                    '.2s ease-out',
                    style({
                        opacity: '1',
                        filter: 'blur(0)'
                    })
                )
            ]),
            transition(':leave', [
                style({
                    opacity: '1',
                    filter: 'blur(0)',
                    position: 'absolute',
                    left: 0,
                    top: 0
                }),
                animate(
                    '.2s cubic-bezier(0.33, 1, 0.4, 1.1)',
                    style({ opacity: '0', filter: 'blur(5px)' })
                )
            ])
        ])
    ]
})
export class MenuTocComponent extends UiBaseComponent {
    private readonly activeRootCategorySubject = new BehaviorSubject<
        string | undefined
    >(undefined);

    protected readonly IconSize = IconSize;

    rootItemsHovered: boolean;

    subItemsHovered: boolean;

    @Input()
    isFullScreenLayout: boolean;

    @Output()
    readonly selectCategory = new EventEmitter<string>();

    @Output()
    readonly displayBackButton = new EventEmitter();

    constructor(
        private readonly categoryMapperService: CategoryMapperService,
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly breakpointObserver: BreakpointObserver
    ) {
        super();
    }

    @MemoizeObservable()
    getMenuTocModel$(): Observable<MenuTocModel[]> {
        return combineLatest([
            this.getCategories$(),
            this.activeRootCategorySubject
        ]).pipe(
            debounceTime(0),
            distinctUntilChangedEquality(),
            map(([categories, activeRootCategory]) =>
                categories.map((category) =>
                    this.mapCategoryItem(
                        category,
                        activeRootCategory ? [activeRootCategory] : []
                    )
                )
            ),
            lazyShareReplay()
        );
    }

    @MemoizeObservable()
    getCategories$(): Observable<CategoryItem[]> {
        return this.categoryMapperService.getCategoryItems$(
            this.productConfigurationSessionService.getOptionGroups$(),
            combineCategorySourceItemFilters(
                (item, path) => path.length < 2,
                topLevelCategoryFilter,
                restrictNestingDepthForPersonalisationAndAccessories
            ),
            (item) => (item.isCategorySelector ? item : undefined)
        );
    }

    @MemoizeObservable()
    getActiveRootItemModel$() {
        return this.getMenuTocModel$().pipe(
            map((models) => models.find((item) => item.active))
        );
    }

    intentActivateTocItem(menuTocModel: MenuTocModel) {
        this.activeRootCategorySubject.next(menuTocModel.id);
    }

    intentClearActiveItem() {
        this.activeRootCategorySubject.next(undefined);
    }

    intentSelectTocItem(menuTocModel: MenuTocModel) {
        this.intentActivateTocItem(menuTocModel);
        this.displayBackButton.emit();

        if (!menuTocModel.children) {
            this.selectCategory.emit(menuTocModel.id);
        }
    }

    onSubItemsLeft(): void {
        if (!this.isFullScreenLayout) {
            this.intentClearActiveItem();
        }
    }

    private mapCategoryItem(
        item: CategoryItem,
        activeIds: string[]
    ): MenuTocModel {
        return {
            id: item.id,
            label: item.label,
            children: item.children
                ? item.children.map((child) =>
                      this.mapCategoryItem(child, activeIds)
                  )
                : undefined,
            active: activeIds.includes(item.id)
        };
    }
}
