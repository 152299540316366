import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import {
    FormGroupChange,
    OrderConfiguration
} from '../../model/order-management.models';

@Component({
    selector: 'mhp-order-configuration-form',
    templateUrl: './order-configuration-form.component.html',
    styleUrls: ['./order-configuration-form.component.scss']
})
export class OrderConfigurationFormComponent {
    @Input()
    orderFormConfig: OrderConfiguration;

    @Input()
    orderFormGroup: FormGroup;

    @Input()
    showPricingDNP: boolean;

    @Input()
    showPricingRRP: boolean;

    @Input()
    currency: string;

    @Output() selectionChange = new EventEmitter<FormGroupChange>();

    onSelectionChange(formGroupChange: FormGroupChange) {
        this.selectionChange.emit(formGroupChange);
    }
}
