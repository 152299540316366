<mhp-dialog-header [hideTopGradient]="true"></mhp-dialog-header>
<mhp-ui-mat-dialog
    *transloco="let t; read: 'CONFIGURATOR.RESUME_CONFIGURATION.DIALOG'"
    [title]="t('HEADLINE')"
    [showClose]="false"
>
    <mhp-ui-mat-dialog-content>
        <div class="restore-local-configuration-session">
            <div>
                <mhp-ui-lazyload-image
                    [imageUrl]="data.referenceIodSrcset"
                    [willResize]="true"
                    [aspectRatio]="16 / 10"
                ></mhp-ui-lazyload-image>
            </div>
        </div>
    </mhp-ui-mat-dialog-content>
    <mhp-ui-mat-dialog-actions-left>
        <button
            mat-button
            class="mhp-ui-button--full-width"
            (click)="intentStartNew()"
            [trackGA4Event]="{
                event: 'start_new_configuration_click'
            }"
        >
            {{
                t(
                    (useShortCtaLabels$() | async) === true
                        ? 'CTA_START_NEW_SHORT'
                        : 'CTA_START_NEW'
                )
            }}
        </button>
    </mhp-ui-mat-dialog-actions-left>
    <mhp-ui-mat-dialog-actions-right>
        <button
            mat-flat-button
            class="mhp-ui-button--full-width"
            (click)="intentResume()"
            [trackGA4Event]="{
                event: 'resume_previous_configuration_click'
            }"
        >
            {{
                t(
                    (useShortCtaLabels$() | async) === true
                        ? 'CTA_RESUME_SHORT'
                        : 'CTA_RESUME'
                )
            }}
        </button>
    </mhp-ui-mat-dialog-actions-right>
</mhp-ui-mat-dialog>
