import { LazyLoadImageModule } from 'ng-lazyload-image';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import {
    UiButtonModule,
    UiGridListModule,
    UiIconButtonModule,
    UiIconsModule,
    UiLazyloadImageModule,
    UiPreventNestedRippleModule,
    UiRadioModule,
    UiSelectedMarkerModule,
    UiTooltipModule
} from '@mhp/ui-components';
import { I18nModule } from '@mhp/ui-shared-services';

import { PricingFormatModule } from '../../pricing';
import { ConfigurationAreaActionsModule } from '../configuration-area-actions';
import { ConfigurationCodeLayoutComponent } from './configuration-code-layout/configuration-code-layout.component';
import { ConfigurationCompositeItemComponent } from './configuration-composite-item/configuration-composite-item.component';

@NgModule({
    declarations: [
        ConfigurationCodeLayoutComponent,
        ConfigurationCompositeItemComponent
    ],
    imports: [
        CommonModule,
        LazyLoadImageModule,
        MatRippleModule,

        UiSelectedMarkerModule,
        UiPreventNestedRippleModule,
        I18nModule,
        UiButtonModule,
        UiIconButtonModule,
        UiIconsModule,
        UiGridListModule,
        UiLazyloadImageModule,
        UiRadioModule,
        UiTooltipModule,
        ConfigurationAreaActionsModule,
        PricingFormatModule
    ],
    exports: [
        ConfigurationCodeLayoutComponent,
        ConfigurationCompositeItemComponent
    ]
})
export class ConfigurationElementsModule {}
