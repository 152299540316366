import { isEqual } from 'lodash-es';
import isEmpty from 'lodash-es/isEmpty';
import {
    Observable,
    combineLatest,
    finalize,
    map,
    of,
    switchMap,
    take,
    withLatestFrom
} from 'rxjs';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { getDerivativeStaticInfo } from '@mhp/aml-shared/derivate-mapping/derivate-mapping';
import { IllegalStateError, Memoize } from '@mhp/common';
import {
    ApplicationStateService,
    ConfigurationConverterService,
    ErrorHandlerService,
    ProductConfigurationService
} from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
    clearActiveLoadingState,
    setActiveLoadingState
} from '../../../common/loading-indicator/state';
import { AmlProductDataService } from '../../../product-data/aml-product-data-service';
import { LocalApplicationState } from '../../../state';
import {
    ExtendedUiContentAwareConfigurationMetaItem,
    ExtendedUiOptionCode,
    ExtendedUiOptionGroup
} from '../../configuration-model/configuration-interfaces';
import { isExtendedUiOptionCode } from '../../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../../session-info/configuration-session-info.service';
import { InteriorEnvironmentModel } from './environment-selection.interface';

@UntilDestroy()
@Injectable()
export class EnvironmentSelectionService {
    constructor(
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly configurationNodeLookupService: ConfigurationNodeLookupService,
        private readonly productConfigurationService: ProductConfigurationService,
        private readonly productDataService: AmlProductDataService,
        private readonly configurationConverterService: ConfigurationConverterService,
        private readonly errorHandlerService: ErrorHandlerService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>
    ) {}

    /**
     * Emit the sessions current configuration with the given InteriorEnvironmentModel definitions applied.
     * @param environmentModel The InteriorEnvironmentModel to be applied to the current session configuration.
     */
    getCurrentConfigurationWithEnvironmentModelApplied$(
        environmentModel: InteriorEnvironmentModel
    ): Observable<ConfigModel[] | undefined> {
        return this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                switchMap((sessionInfo) => {
                    if (!sessionInfo) {
                        return of(undefined);
                    }

                    const configurationToBeApplied =
                        this.getHaloSpecsForProductId(sessionInfo.productId)[
                            environmentModel.optionCode.code
                        ];

                    if (!configurationToBeApplied) {
                        return of(sessionInfo.engineData.config.options.config);
                    }

                    const configEntriesToPatch = [
                        environmentModel.optionCode.code,
                        ...configurationToBeApplied
                    ];

                    return this.productConfigurationService.patchConfiguration$(
                        sessionInfo.productId,
                        sessionInfo.country,
                        sessionInfo.engineData.config.options.config,
                        configEntriesToPatch
                    );
                })
            );
    }

    /**
     * Emit the current halo-spec configuration with the given InteriorEnvironmentModel definitions applied.
     * @param environmentModel The InteriorEnvironmentModel to be applied to the halo-spec configuration.
     */
    getHaloSpecConfigurationWithEnvironmentModelApplied$(
        environmentModel: InteriorEnvironmentModel
    ): Observable<ConfigModel[] | undefined> {
        return this.productDataService.getActiveProductInfo$().pipe(
            map((activeProductInfo) => activeProductInfo),
            withLatestFrom(
                this.configurationSessionInfoService
                    .getActiveConfigurationSessionInfo$()
                    .pipe(map((sessionInfo) => sessionInfo?.country))
            ),
            map(([activeProductInfo, activeCountry]) => {
                if (!activeProductInfo?.defaultConfig || !activeCountry) {
                    return undefined;
                }
                return {
                    productId: activeProductInfo.id,
                    country: activeCountry,
                    configuration: activeProductInfo.defaultConfig
                };
            }),
            switchMap((haloSpecConfigurationInfo) => {
                if (!haloSpecConfigurationInfo) {
                    return of(undefined);
                }

                const configurationToBeApplied = this.getHaloSpecsForProductId(
                    haloSpecConfigurationInfo.productId
                )[environmentModel.optionCode.code];

                if (!configurationToBeApplied) {
                    return of(haloSpecConfigurationInfo.configuration);
                }

                const configEntriesToPatch = [
                    environmentModel.optionCode.code,
                    ...configurationToBeApplied
                ];

                return this.productConfigurationService.patchConfiguration$(
                    haloSpecConfigurationInfo.productId,
                    haloSpecConfigurationInfo.country,
                    haloSpecConfigurationInfo.configuration,
                    configEntriesToPatch
                );
            }),
            this.errorHandlerService.applyRetry({
                messageProviderOnFinalError: () =>
                    translate('COMMON.ERROR.UNEXPECTED_ERROR')
            })
        );
    }

    applyEnvironment$(environmentModel: InteriorEnvironmentModel) {
        this.applicationStateService.dispatch(
            setActiveLoadingState({
                showLoadingSpinnerWhenLoading: true,
                loading: true
            })
        );
        return combineLatest([
            this.productConfigurationSessionService.getOptionGroups$(),
            this.configurationSessionInfoService.getActiveProductId$()
        ]).pipe(
            take(1),
            switchMap(([optionGroups, productId]) => {
                if (!optionGroups || !productId) {
                    throw new IllegalStateError(
                        'Expected option group and productId'
                    );
                }
                const configModelEntries =
                    this.getHaloSpecsForProductId(productId)[
                        environmentModel.optionCode.code
                    ];

                if (!configModelEntries) {
                    throw new IllegalStateError(
                        `Missing halo-specs for productId ${productId}`
                    );
                }

                let prevIdsToApply: string[] = [];
                const applyRemainingOptions$ = (
                    localOptionGroups: ExtendedUiOptionGroup[] | undefined
                ) => {
                    if (!localOptionGroups) {
                        return of(undefined);
                    }

                    const idsToApply = [
                        ...this.getIdsToApply(
                            configModelEntries,
                            [environmentModel.optionCode.id],
                            localOptionGroups
                        )
                    ];

                    if (isEmpty(idsToApply)) {
                        return of(undefined);
                    }

                    if (isEqual(idsToApply, prevIdsToApply)) {
                        // applying an option doesn't seem to work here...
                        throw new IllegalStateError(
                            `Failed applying option ${idsToApply[0]}`,
                            idsToApply
                        );
                    }

                    prevIdsToApply = idsToApply;

                    // apply node selection
                    return this.productConfigurationSessionService
                        .updateNodeSelection(idsToApply[0])
                        .pipe(
                            switchMap(() =>
                                this.productConfigurationSessionService
                                    .getOptionGroups$()
                                    .pipe(take(1))
                            ),
                            switchMap((updatedOptionGroups) =>
                                applyRemainingOptions$(updatedOptionGroups)
                            )
                        );
                };

                return applyRemainingOptions$(optionGroups);
            }),
            take(1),
            finalize(() =>
                this.applicationStateService.dispatch(clearActiveLoadingState())
            ),
            untilDestroyed(this)
        );
    }

    @Memoize()
    private getHaloSpecsForProductId(productId: string) {
        return getDerivativeStaticInfo(productId).interiorEnvironment.haloSpecs;
    }

    private getIdsToApply(
        codes: readonly ConfigModel[],
        ids: string[],
        contentAware: ExtendedUiContentAwareConfigurationMetaItem[]
    ) {
        return [
            ...this.configurationNodeLookupService.findNodesByConfigModelEntries(
                codes,
                contentAware
            ),
            ...this.configurationNodeLookupService.collectNodes<ExtendedUiOptionCode>(
                (node) => ids.includes(node.id) && isExtendedUiOptionCode(node),
                contentAware
            )
        ]
            .filter((optionCode) => !optionCode.selected)
            .map((optionCode) => optionCode.id);
    }
}
