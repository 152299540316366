import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

/**
 * Stops propagation of given events for the element
 * it is applied to.
 */
@Directive()
export class UiPreventPropagationBase implements OnInit, OnDestroy {
    private readonly stopPropagationListener = (
        event: MouseEvent | TouchEvent
    ) => {
        event.stopPropagation();
    };

    constructor(
        private elementRef: ElementRef<Element>,
        private events: string[]
    ) {}

    ngOnInit() {
        this.events.forEach((event) => {
            this.elementRef.nativeElement.addEventListener(
                event,
                this.stopPropagationListener
            );
        });
    }

    ngOnDestroy() {
        this.events.forEach((event) => {
            this.elementRef.nativeElement.removeEventListener(
                event,
                this.stopPropagationListener
            );
        });
    }
}
