<div class="configuration-bar" *transloco="let t; read: 'CONFIGURATOR'">
    <div
        @toggleNestedView
        [@.disabled]="!initialized"
        class="configuration-bar__nested-view-container"
        *ngIf="revealNestedView"
    >
        <ng-content select="[configurationBarNestedView]"></ng-content>
    </div>
    <div
        class="configuration-bar__tab-group-container"
        [ngClass]="{
            'configuration-bar__tab-group-container--aside': revealNestedView,
            'configuration-bar__tab-group-container--search-active':
                (isSearchInputActive$() | async) === true,
            'configuration-bar__tab-group-container--search-results-active':
                (isDisplayingSearchResults$() | async) === true
        }"
        (click)="onClickTabGroupContainer()"
    >
        <mhp-loading-indicator
            [loading]="!(getCategoryHierarchyItems$() | async)?.length"
            [showLoadingSpinnerWhenLoading]="true"
        ></mhp-loading-indicator>
        <div class="configuration-bar__tab-group-header">
            <div
                #extensionContainerLeft
                class="configuration-bar__tab-group-extension-container configuration-bar__tab-group-extension-container--left"
            >
                <div class="configuration-bar__tab-group-action-menu">
                    <mhp-ui-icon-button
                        *transloco="let t; read: 'COMMON.HEADER'"
                        svgIcon="mhp-ui:menu"
                        [matTooltip]="t('MENU_ALT')"
                        [label]="t('MENU_ALT')"
                        (click)="intentToggleMenu($event)"
                    ></mhp-ui-icon-button>
                </div>

                <div
                    class="configuration-bar__tab-group-search"
                    [ngClass]="{
                        'configuration-bar__tab-group-search--active':
                            (isSearchInputActive$() | async) === true
                    }"
                >
                    <mhp-search [searchState]="searchState"></mhp-search>
                </div>
                <div
                    *ngIf="isDisplayingSearchResults$() | async"
                    class="configuration-bar__tab-group-search-term"
                >
                    <mhp-search-term
                        [searchTerm]="getActiveSearchTerm$() | async"
                        (clearSearch)="intentExitSearch()"
                    >
                    </mhp-search-term>
                </div>
            </div>
            <div
                #extensionContainerCenter
                class="configuration-bar__tab-group-extension-container configuration-bar__tab-group-extension-container--center"
            ></div>
            <div
                #extensionContainerRight
                class="configuration-bar__tab-group-extension-container configuration-bar__tab-group-extension-container--right"
            >
                <ng-content
                    select="[configuration-bar__tab-group-extension-right]"
                ></ng-content>
                <button
                    *ngIf="(isSummaryActive$() | async) === false"
                    class="configuration-bar__summary-cta mhp-ui-button--prominent"
                    mat-flat-button
                    (click)="intentGoToSummary()"
                >
                    <mhp-ui-icon
                        svgIcon="mhp-ui:summary"
                        size="medium"
                    ></mhp-ui-icon>
                    <span>{{ t('SUMMARY') }}</span>
                </button>
            </div>
        </div>
        <mat-tab-group
            #toplevelTabGroup
            *ngIf="
                getCategoryHierarchyItems$() | async as categoryHierarchyItems
            "
            mat-align-tabs="left"
            mat-stretch-tabs="false"
            (selectedTabChange)="onSelectedTabChange($event.index, 'COMPONENT')"
            animationDuration="0ms"
        >
            <ng-container
                *ngFor="
                    let categoryItem of categoryHierarchyItems;
                    trackBy: trackById
                "
            >
                <mat-tab
                    *ngIf="
                        categoryItem.nameInternal !== IDENTIFIER_SUMMARY;
                        else summaryTab
                    "
                >
                    <ng-template mat-tab-label>
                        {{
                            (isDisplayingSearchResults$() | async) === true
                                ? categoryItem.label +
                                  '(' +
                                  (getSearchResultCountForRootGroup$(
                                      categoryItem.id
                                  ) | async) +
                                  ')'
                                : categoryItem.label
                        }}
                        <ng-container *transloco="let t; read: 'ICONS'">
                            <mhp-ui-icon-button
                                *ngIf="
                                    categoryItem.nameInternal ===
                                        CATEGORY_EDITIONS &&
                                    (isEditionActive$() | async) === true
                                "
                                class="configuration-bar__tab-group-label-icon"
                                svgIcon="mhp-ui:info"
                                [matTooltip]="
                                    t('INFO_ON_ALT', {
                                        topic: categoryItem.label
                                    })
                                "
                                [label]="
                                    t('INFO_ON_ALT', {
                                        topic: categoryItem.label
                                    })
                                "
                                [trackGA4Event]="{
                                    event: 'editions_open_info_dialog_click'
                                }"
                                (mousedown)="
                                    $event.stopPropagation();
                                    $event.stopImmediatePropagation()
                                "
                                (click)="
                                    $event.stopPropagation();
                                    $event.stopImmediatePropagation();
                                    intentShowEditionsInfo()
                                "
                            >
                            </mhp-ui-icon-button>
                        </ng-container>
                    </ng-template>
                    <ng-template matTabContent>
                        <ng-container
                            *ngIf="categoryItem.children; else noChildren"
                        >
                            <!-- category-selection -->
                            <mhp-expandable-category-bar
                                [categoryItems]="categoryItem.children"
                                [canSelectNext]="
                                    (getCategoryNavigationState$() | async)
                                        ?.canNavNext
                                "
                                [canSelectPrev]="
                                    (getCategoryNavigationState$() | async)
                                        ?.canNavPrev
                                "
                                [scrollPosition]="
                                    getScrollPosition(categoryItem.id)
                                "
                                [disableScrollIntoView]="revealNestedView"
                                [noItemsTemplate]="noCategoriesMatch"
                                [categoryLabelExtensionTemplate]="
                                    categoryLabelExtension
                                "
                                [optionSelectionDisabled]="updatingOptionSelection()"
                                (selectCategory)="
                                    intentSelectCategory($event, 'user')
                                "
                                (selectNext)="intentStepCategory(1)"
                                (selectPrev)="intentStepCategory(0)"
                                (scrollPositionChange)="
                                    onScrollPositionChange(
                                        categoryItem.id,
                                        $event
                                    )
                                "
                                (showInfo)="
                                    intentShowInfoForCategoryItem($event)
                                "
                            ></mhp-expandable-category-bar>

                            <ng-template
                                #categoryLabelExtension
                                let-categoryItem="categoryItem"
                            >
                                <ng-container *transloco="let t; read: 'ICONS'">
                                    <mhp-ui-icon-button
                                        *ngIf="
                                            categoryItem.nameInternal ===
                                                IDENTIFIER_ENVIRONMENT_SELECTION &&
                                            (isInteriorEnvironmentSelectionEnabled$()
                                                | async) === true
                                        "
                                        class="configuration-bar__interior-environment-selection-cta"
                                        svgIcon="mhp-ui:info"
                                        size="large"
                                        [matTooltip]="
                                            t('INFO_ON_ALT', {
                                                topic: categoryItem.label
                                            })
                                        "
                                        [label]="
                                            t('INFO_ON_ALT', {
                                                topic: categoryItem.label
                                            })
                                        "
                                        (click)="intentShowEnvironmentLayer()"
                                    ></mhp-ui-icon-button>
                                </ng-container>
                            </ng-template>

                            <!-- SPECIAL-BEHAVIOR FOR PERSONALISATION AND ACCESSORIES -->
                            <ng-container
                                *ngIf="
                                    categoryItem.nameInternal ===
                                        CATEGORY_PERSONALISATION ||
                                        categoryItem.nameInternal ===
                                            CATEGORY_ACCESSORIES;
                                    else regularOptions
                                "
                            >
                                <div
                                    #compositeItemsContainer
                                    class="configuration-bar__composite-items-container"
                                >
                                    <mhp-configuration-composite-items-list
                                        [configurationCompositeItems]="
                                            getConfigurationCompositeItemModels$()
                                                | async
                                        "
                                        [optionSelectionDisabled]="updatingOptionSelection()"
                                        (selected)="intentSelectOption($event)"
                                    ></mhp-configuration-composite-items-list>
                                    <mhp-footer
                                        [regionAndLanguageSelectionInterceptor]="
                                            regionAndLanguageSelectorInterceptor
                                        "
                                        [scrollToTopReference]="
                                            compositeItemsContainer
                                        "
                                    ></mhp-footer>
                                </div>
                            </ng-container>

                            <!-- HANDLING OF RENDERING REGULAR OPTIONS -->
                            <ng-template #regularOptions>
                                <!-- OPTIONALLY RENDER AN ALTERNATIVE OPTIONS RENDERING TEMPLATE -->
                                <ng-container
                                    *ngTemplateOutlet="
                                        optionsRenderingTemplate ||
                                            defaultOptionsRenderingTemplate;
                                        context: {
                                            options:
                                                getActiveOptions$() | async,
                                            selectedOption:
                                                getSingleSelectedOptionForActiveCollectionCategory$()
                                                | async,
                                            optionSelectedCallback:
                                                intentSelectOption,
                                            requestOptionInfoCallback:
                                                intentRequestOptionInfo
                                        }
                                    "
                                ></ng-container>

                                <!-- DEFAULT HANDLING OF OPTIONS -->
                                <ng-template #defaultOptionsRenderingTemplate>
                                    <mhp-configuration-options
                                        [options]="getActiveOptions$() | async"
                                        [selectedOption]="
                                            getSingleSelectedOptionForActiveCollectionCategory$()
                                                | async
                                        "
                                        [optionSelectionDisabled]="updatingOptionSelection()"
                                        (optionSelected)="
                                            intentSelectOption($event)
                                        "
                                        (infoRequested)="
                                            intentRequestOptionInfo($event)
                                        "
                                    ></mhp-configuration-options>
                                </ng-template>
                            </ng-template>
                        </ng-container>
                        <ng-template #noChildren>
                            <div class="configuration-bar__empty-search">
                                <h2>
                                    {{
                                        t('SEARCH.NO_RESULTS.HEADLINE', {
                                            category: categoryItem.label,
                                            searchTerm:
                                                getActiveSearchTerm$() | async
                                        })
                                    }}
                                </h2>
                                <p>{{ t('SEARCH.NO_RESULTS.ADVICE') }}</p>
                            </div>
                        </ng-template>
                    </ng-template>
                </mat-tab>

                <ng-template #summaryTab>
                    <mat-tab labelClass="hidden-tab">
                        <ng-template matTabContent>
                            <div class="configuration-bar__summary-container">
                                <mhp-configuration-summary
                                    [renderTurntableCta]="
                                        renderSummaryTurntableCta
                                    "
                                    (optionSelected)="
                                        intentSelectOption($event)
                                    "
                                ></mhp-configuration-summary>
                            </div>
                        </ng-template>
                    </mat-tab>
                </ng-template>
            </ng-container>
        </mat-tab-group>
    </div>

    <ng-template #noCategoriesMatch>
        No items found matching your search for XXX...
    </ng-template>
</div>
